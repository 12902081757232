import {
    ADD_EMPLOYEE_FORM_REQUEST,
    ADD_EMPLOYEE_FORM_SUCCESS,
    ADD_EMPLOYEE_FORM_ERROR,
    EDIT_EMPLOYEE_FORM_REQUEST,
    EDIT_EMPLOYEE_FORM_SUCCESS,
    EDIT_EMPLOYEE_FORM_ERROR,
    GET_EMPLOYEE_FORM_REQUEST,
    GET_EMPLOYEE_FORM_SUCCESS,
    GET_EMPLOYEE_FORM_ERROR,
    DELETE_EMPLOYEE_FORM_ERROR,
    DELETE_EMPLOYEE_FORM_SUCCESS,
    DELETE_EMPLOYEE_FORM_REQUEST,
    CHANGE_PASSWORD_EMPLOYEE_FORM_REQUEST,
    CHANGE_PASSWORD_EMPLOYEE_FORM_SUCCESS,
    CHANGE_PASSWORD_EMPLOYEE_FORM_ERROR,
    OPEN_EMPLOYEE_FORM,
    CLOSE_EMPLOYEE_FORM
} from '../constants/employeeForm'
import { IEmployee } from '../../models/Employee';
import {IActionEmployeeForm} from "../actions/employeeForm";



export interface IEmployeeFormState {
    getLoading: boolean
    getError: string
    data: IEmployee | null
    changeLoading: boolean
    changeError: string
    mode: 'add' | 'edit' | 'delete' | 'changePassword' | null
    modeId: number | null
}


const initialState: IEmployeeFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null,
    modeId: null
};

export default function employeeForm(state = initialState, action: IActionEmployeeForm): IEmployeeFormState {
    switch (action.type) {
        case GET_EMPLOYEE_FORM_REQUEST:
            return {
                ...state,
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_EMPLOYEE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_EMPLOYEE_FORM_ERROR:
            return {
                ...state,
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case ADD_EMPLOYEE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_EMPLOYEE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_EMPLOYEE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case EDIT_EMPLOYEE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case EDIT_EMPLOYEE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case EDIT_EMPLOYEE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case DELETE_EMPLOYEE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case DELETE_EMPLOYEE_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case DELETE_EMPLOYEE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case CHANGE_PASSWORD_EMPLOYEE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case CHANGE_PASSWORD_EMPLOYEE_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case CHANGE_PASSWORD_EMPLOYEE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_EMPLOYEE_FORM:
            const {mode, modeId = null} = action.payload;
            return {
                ...initialState,
                mode,
                modeId
            };
        case CLOSE_EMPLOYEE_FORM:
            return {
                ...initialState
            };

        default:
            return state;
    }
}
