import {IAction} from "./index";
import {ICourierRatioRateConditionForm, ICourierRatioRateConditionResponse} from "../../models/Courier";
import {
    CHANGE_RATIO_RATE,
    CHANGE_RATIO_RATE_ERROR,
    CHANGE_RATIO_RATE_REQUEST,
    CHANGE_RATIO_RATE_SUCCESS,
    DELETE_RATIO_RATE,
    DELETE_RATIO_RATE_ERROR,
    DELETE_RATIO_RATE_REQUEST,
    DELETE_RATIO_RATE_SUCCESS,
    GET_RATIO_RATE,
    GET_RATIO_RATE_ERROR,
    GET_RATIO_RATE_REQUEST,
    GET_RATIO_RATE_SUCCESS
} from "../constants/ratioRate";

export interface IActionRatioRate extends IAction<symbol, any> {

}

export const getRatioRate = (cityId: number) => {
    return {
        type: GET_RATIO_RATE,
        payload: {
            cityId
        }
    }
}

export const getRatioRateRequest = () => {
    return {
        type: GET_RATIO_RATE_REQUEST
    }
}

export const getRatioRateSuccess = (data: ICourierRatioRateConditionResponse[]) => {
    return {
        type: GET_RATIO_RATE_SUCCESS,
        payload: {
            data
        }
    }
}

export const getRatioRateError = (payload: string) => {
    return {
        type: GET_RATIO_RATE_ERROR,
        payload
    }
}

export const changeRatioRate = (form: ICourierRatioRateConditionForm[], id?: number, ) => {
    return {
        type: CHANGE_RATIO_RATE,
        payload: {
            id,
            form
        }
    }
}

export const changeRatioRateRequest = () => {
    return {
        type: CHANGE_RATIO_RATE_REQUEST
    }
}

export const changeRatioRateSuccess = (data: ICourierRatioRateConditionResponse) => {
    return {
        type: CHANGE_RATIO_RATE_SUCCESS,
        payload: {
            data
        }
    }
}

export const changeRatioRateError = (payload: string) => {
    return {
        type: CHANGE_RATIO_RATE_ERROR,
        payload
    }
}

export const deleteRatioRate = (id: number) => {
    return {
        type: DELETE_RATIO_RATE,
        payload: {
            id
        }
    }
}

export const deleteRatioRateRequest = () => {
    return {
        type: DELETE_RATIO_RATE_REQUEST
    }
}

export const deleteRatioRateSuccess = () => {
    return {
        type: DELETE_RATIO_RATE_SUCCESS
    }
}

export const deleteRatioRateError = (payload: string) => {
    return {
        type: DELETE_RATIO_RATE_ERROR,
        payload
    }
}