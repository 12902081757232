import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getDictionaryReportCourierPeriodError,
    getDictionaryReportCourierPeriodRequest,
    getDictionaryReportCourierPeriodSuccess
} from '../actions/dictionaryReportCourierPeriod';
import {GET_DICTIONARY_REPORT_COURIER_PERIOD} from "../constants/dictionaryReportCourierPeriod";
import {IDictionary} from "../../models/Dictionary";
import {DictionaryService} from '../../services/DictionaryService';


function* getDictionaryReportCourierPeriodSaga() {
    try {
        yield put(getDictionaryReportCourierPeriodRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryReportCourierPeriod);

        const response: IDictionary[] = promise.data || [];

        yield put(getDictionaryReportCourierPeriodSuccess(response));


    } catch (error) {
        yield put(getDictionaryReportCourierPeriodError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryReportCourierPeriod() {
    yield takeLatest(GET_DICTIONARY_REPORT_COURIER_PERIOD, getDictionaryReportCourierPeriodSaga);
}
