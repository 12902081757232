import {
    GET_DICTIONARY_COMPANIES_ERROR,
    GET_DICTIONARY_COMPANIES_REQUEST,
    GET_DICTIONARY_COMPANIES_SUCCESS
} from "../constants/dictionaryCompanies";
import {IDictionary} from "../../models/Dictionary";
import {IActionDictionaryCompanies} from "../actions/dictionaryCompanies";


export interface IDictionaryCompaniesState {
    loading: boolean
    error: string
    data: IDictionary[]
}

const initialState: IDictionaryCompaniesState = {
    data: [],
    loading: false,
    error: ''
};

export default function dictionaryCompanies(state = initialState, action: IActionDictionaryCompanies): IDictionaryCompaniesState {
    switch (action.type) {
        case GET_DICTIONARY_COMPANIES_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_DICTIONARY_COMPANIES_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_DICTIONARY_COMPANIES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
