import React, {PureComponent} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.scss";
import {ru} from "date-fns/locale";
import {format, parse} from "date-fns";
import {Input} from 'reactstrap';


const MAX_DATE = new Date(9999, 12, 31);


type DateTimePickerType = {
    name?: string
    value?: string | null
    className?: string
    defaultValue?: string | null
    onChange?: (value: string | null) => void
    disabled?: boolean
    maxDate?: Date | null
    showTimeSelect?: boolean
    showTimeSelectOnly?: boolean
    timeIntervals?: number
    dateFormat?: string
    invalid?: boolean
    placeholder?: string
};

class DateTimePicker extends PureComponent<DateTimePickerType> {
    render() {
        const {
            className, placeholder, name, value, onChange, disabled, maxDate = MAX_DATE, defaultValue = null,
            dateFormat = "dd-MM-yyyy", showTimeSelect = false, showTimeSelectOnly = false, timeIntervals = 15, ...rest
        } = this.props;

        const changeHandler = (value: Date | null) => {
            if (!onChange) {
                return;
            }

            onChange(value ? format(value, dateFormat) : defaultValue);
        };


        return (
            <DatePicker
                selected={value ? parse(value, dateFormat, new Date()) : null}
                onChange={(value) => changeHandler(value)}
                locale={ru}
                name={name}
                disabled={disabled}
                className={className}
                dateFormat={dateFormat}
                placeholderText={placeholder}
                maxDate={maxDate}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeIntervals={timeIntervals}
                timeCaption="Время"
                autoComplete={'off'}
                customInput={<Input {...rest}/>}
            />
        )
    }
}

export default DateTimePicker;
