import React, {PureComponent} from 'react';
import {
    Alert,
    Button,
    Form,
    Spinner,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Card,
    Row,
    Col
} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {matchMessage, requiredMessage} from "../../utils/form-validation";
import {sanitizeForm} from "../../utils/sanitize-form";
import {IAuthChangePasswordRequest} from "../../models/Auth";
import {Link} from "react-router-dom";


const schema = yup.object({
    oldPassword: yup.string().required(requiredMessage()),
    newPassword: yup.string().required(requiredMessage()),
    newPasswordConfirmation: yup.string().required(requiredMessage())
        .oneOf([yup.ref('newPassword'), null], matchMessage())
});

type ProfileChangePasswordType = {
    changePasswordUser: (form: IAuthChangePasswordRequest) => void
    changeLoading: boolean
    changeError: string
}

class ProfileChangePassword extends PureComponent<ProfileChangePasswordType> {
    render() {
        const {changePasswordUser, changeLoading, changeError} = this.props;

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const oldPassword = sanitizeForm(values.oldPassword);
                    const newPassword = sanitizeForm(values.newPassword);

                    const form = {
                        oldPassword,
                        newPassword
                    };

                    changePasswordUser(form)
                }}
                initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    newPasswordConfirmation: ''
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        {changeError &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {changeError}
                        </Alert>)}
                        <div className={'mb-2'}>
                            <Row form>
                                <Col md={'auto'}>
                                    <Link to={'/profile'}><Button color="light">Отменить</Button></Link>
                                </Col>
                                <Col md={'auto'}>
                                    <Button type="submit" color={'primary'} disabled={changeLoading}>
                                        {changeLoading &&
                                        (<Spinner
                                            size="sm"
                                        />)}
                                        Изменить
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <Card body>
                            <Row>
                                <Col md={'4'}>
                                    <FormGroup>
                                        <Label>Старый пароль <span className={'required'}>*</span></Label>
                                        <Input
                                            type="password"
                                            name="oldPassword"
                                            value={values.oldPassword}
                                            onChange={handleChange}
                                            invalid={touched.oldPassword && !!errors.oldPassword}
                                        />
                                        <FormFeedback>{errors.oldPassword}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Новый пароль <span className={'required'}>*</span></Label>
                                        <Input
                                            type="password"
                                            name="newPassword"
                                            value={values.newPassword}
                                            onChange={handleChange}
                                            invalid={touched.newPassword && !!errors.newPassword}
                                        />
                                        <FormFeedback>{errors.newPassword}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Повторите новый пароль <span className={'required'}>*</span></Label>
                                        <Input
                                            type="password"
                                            name="newPasswordConfirmation"
                                            value={values.newPasswordConfirmation}
                                            onChange={handleChange}
                                            invalid={touched.newPasswordConfirmation && !!errors.newPasswordConfirmation}
                                        />
                                        <FormFeedback>{errors.newPasswordConfirmation}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Card>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default ProfileChangePassword;
