import React, {PureComponent} from 'react';
import {Pagination as BPagination, PaginationItem, PaginationLink} from "reactstrap";

type PaginationType = {
    active: number
    total: number
    by: number
    paginateHandler: (number: number) => void
}

class Pagination extends PureComponent<PaginationType & React.HTMLAttributes<HTMLDivElement>> {
    render() {
        const {active, total, by, paginateHandler} = this.props;
        const pages = [];
        for (let page = 1; page <= Math.ceil(total / by); page++) {
            pages.push(page);
        }
        if (total > by) {
            return <BPagination>
                {active !== 1 && <PaginationItem onClick={() => paginateHandler(active - 1)}>
                    <PaginationLink previous/>
                </PaginationItem>}
                {pages.map((page) => (
                    <PaginationItem key={page} active={page === active} onClick={() => paginateHandler(page)}>
                        <PaginationLink>
                            {page}
                        </PaginationLink>
                    </PaginationItem>
                ))}
                {active !== pages.length && <PaginationItem onClick={() => paginateHandler(active + 1)}>
                    <PaginationLink next/>
                </PaginationItem>}
            </BPagination>
        }
        return null;
    }
}


export default Pagination;
