import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import PointNavbar from "./PointNavbar";
import Points from "./Points";
import {accessPointCreate} from "../utils/user-accesses";
import _ from "lodash";


type PointOutputType = {
    accesses?: string[]
};


class PointOutput extends Component<PointOutputType> {

    render() {
        const {accesses} = this.props;

        return (
            <>
                {Array.isArray(accesses) && accesses.includes(accessPointCreate) &&
                <PointNavbar/>}
                <Points/>
            </>
        );
    }
}


const mapStateToProps = (state: AppState) => {
    const {user} = state.auth;
    return {
        accesses: user !== null ? user.accesses : undefined,
        allowedCities: _.get(user, 'allowedCities', [])
    }
};

export default connect(mapStateToProps, null)(PointOutput);
