import {ADD_NOTIFY, REMOVE_NOTIFY} from "../constants/notify";
import {IAction} from "./index";

export interface IActionNotify extends IAction<symbol, any> {

}

export const removeNotify = () => {
    return {
        type: REMOVE_NOTIFY
    };
};

export const addNotify = (message: string) => {
    return {
        type: ADD_NOTIFY,
        payload: message
    };
};
