import React, {Component} from "react";
import {Card} from "reactstrap";
import OrderFilter from "./OrderFilter";
import Orders from "./Orders";
import {accessOrderShow} from "../utils/user-accesses";
import {Redirect} from "react-router-dom";
import {AppState} from "../store/reducers";
import {connect} from "react-redux";

type OrdersOutputType = {
    accesses?: string[]
}

class OrdersOutputContainer extends Component<OrdersOutputType> {
    render() {
        const {accesses} = this.props;
        
        if (!(Array.isArray(accesses) && accesses.includes(accessOrderShow))) {
            return <Redirect to={'/profile'}/>
        }
        
        return (
            <>
                <Card body className={'mb-3'}>
                    <h4 className={'mb-3'}>Фильтр</h4>
                    <OrderFilter/>
                </Card>
                <Orders/>
            </>
        );
    }
}

const mapStateToProps = ({auth}: AppState) => {
    return {
        accesses: auth.user !== null ? auth.user.accesses : undefined
    }
};

export default connect(mapStateToProps, null)(OrdersOutputContainer);
