import {
    GET_COMPANY,
    GET_COMPANY_REQUEST,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_ERROR
} from '../constants/company'
import {IAction} from "./index";
import {ICompany} from "../../models/Company";


export interface IActionCompany extends IAction<symbol, any> {

}


export const getCompanyRequest = () => {
    return {
        type: GET_COMPANY_REQUEST
    }
};

export const getCompanySuccess = (payload: ICompany) => {
    return {
        type: GET_COMPANY_SUCCESS,
        payload
    }
};

export const getCompanyError = (payload: string) => {
    return {
        type: GET_COMPANY_ERROR,
        payload
    }
};

export const getCompany = (id: number, expand?:string) => {
    return {
        type: GET_COMPANY,
        payload:{
            id,
            expand
        }
    };
};

