import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getDictionaryPublishedSchedulesError,
    getDictionaryPublishedSchedulesRequest,
    getDictionaryPublishedSchedulesSuccess
} from '../actions/dictionaryPublishedSchedules';
import {GET_DICTIONARY_PUBLISHED_SCHEDULES} from "../constants/dictionaryPublishedSchedules";
import {ISchedule} from "../../models/Schedule";
import {ScheduleService} from "../../services/ScheduleService";


type getDictionaryPublishedSchedulesType = {
    type: typeof GET_DICTIONARY_PUBLISHED_SCHEDULES,
    payload?:number
};
function* getDictionaryPublishedSchedulesSaga({payload}:getDictionaryPublishedSchedulesType) {
    try {
        yield put(getDictionaryPublishedSchedulesRequest());

        const service = new ScheduleService();

        const promise = yield call(service.getSchedules, 1, payload,true);

        const response: ISchedule[] = promise.data || [];

        yield put(getDictionaryPublishedSchedulesSuccess(response));


    } catch (error) {
        yield put(getDictionaryPublishedSchedulesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryPublishedSchedules() {
    yield takeLatest(GET_DICTIONARY_PUBLISHED_SCHEDULES, getDictionaryPublishedSchedulesSaga);
}
