export const LOGIN = Symbol('LOGIN');
export const LOGIN_REQUEST = Symbol('LOGIN_REQUEST');
export const LOGIN_SUCCESS = Symbol('LOGIN_SUCCESS');
export const LOGIN_ERROR = Symbol('LOGIN_ERROR');

export const GET_USER = Symbol('GET_USER');
export const GET_USER_REQUEST = Symbol('GET_USER_REQUEST');
export const GET_USER_SUCCESS = Symbol('GET_USER_SUCCESS');
export const GET_USER_ERROR = Symbol('GET_USER_ERROR');

export const GET_USER_SILENT = Symbol('GET_USER_SILENT');

export const LOGOUT = Symbol('LOGOUT');
export const LOGOUT_SUCCESS = Symbol('LOGOUT_SUCCESS');


export const CHANGE_PASSWORD_USER = Symbol('CHANGE_PASSWORD_USER');
export const CHANGE_PASSWORD_USER_REQUEST = Symbol('CHANGE_PASSWORD_USER_REQUEST');
export const CHANGE_PASSWORD_USER_SUCCESS = Symbol('CHANGE_PASSWORD_USER_SUCCESS');
export const CHANGE_PASSWORD_USER_ERROR = Symbol('CHANGE_PASSWORD_USER_ERROR');
