import {
    GET_DICTIONARY_POINTS_ERROR,
    GET_DICTIONARY_POINTS_REQUEST,
    GET_DICTIONARY_POINTS_SUCCESS
} from "../constants/dictionaryPoints";
import {IDictionary} from "../../models/Dictionary";
import {IActionDictionaryPoints} from "../actions/dictionaryPoints";


export interface IDictionaryPointsState {
    loading: boolean
    error: string
    data: IDictionary[]
}

const initialState: IDictionaryPointsState = {
    data: [],
    loading: false,
    error: ''
};

export default function dictionaryPoints(state = initialState, action: IActionDictionaryPoints): IDictionaryPointsState {
    switch (action.type) {
        case GET_DICTIONARY_POINTS_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_DICTIONARY_POINTS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_DICTIONARY_POINTS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
