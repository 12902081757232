export const COMPANY_CALCULATION_PERCENT = 1;
export const COMPANY_CALCULATION_ZONES = 2;

export const companyCalculationOptions = [
    {label: 'Процент от стоимости заказа', value: 1},
    {label: 'По зонам доставки', value: 2}
];

export const COMPANY_CONTACT_MOBILE = 1;
export const COMPANY_CONTACT_EMAIL = 2;

export const companyContactOptions = [
    {label: 'Мобильный', value: 1},
    {label: 'Email', value: 2}
];

export const COMPANY_STATUS_BLOCKED = 0;
export const COMPANY_STATUS_TEMPORARILY_ON = 5;
export const COMPANY_STATUS_ACTIVE = 10;

type CompanyStatusesType = {
    [key: string]: string;
};

export const companyStatuses: CompanyStatusesType = {
    0: 'Отключен',
    5: 'Временно выключен',
    10: 'Включен'
};

export const companyStatusOptions = [
    {label:'Отключен',value:0},
    {label:'Временно выключен',value:5},
    {label:'Включен',value:10}
];
