import React, {PureComponent} from 'react';
import {Alert, Button, Form, Spinner, FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {minLengthMessage, requiredMessage} from "../../utils/form-validation";
import {sanitizeForm} from "../../utils/sanitize-form";
import {IEmployeeChangePasswordFormRequest} from "../../models/Employee";


const schema = yup.object({
    newPassword: yup.string().min(6, minLengthMessage(6)).required(requiredMessage())
});

type EmployeeChangePasswordType = {
    changePasswordEmployeeForm: (form: IEmployeeChangePasswordFormRequest) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
}


class EmployeeChangePassword extends PureComponent<EmployeeChangePasswordType> {
    render() {
        const {changePasswordEmployeeForm, changeLoading, changeError, cancelHandler} = this.props;

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const newPassword = sanitizeForm(values.newPassword);

                    const form = {
                        newPassword
                    };
                    changePasswordEmployeeForm(form)
                }}
                initialValues={{
                    newPassword: ''
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>Новый пароль <span className={'required'}>*</span></Label>
                            <Input
                                type="password"
                                name="newPassword"
                                value={values.newPassword}
                                onChange={handleChange}
                                invalid={touched.newPassword && !!errors.newPassword}
                            />
                            <FormFeedback>{errors.newPassword}</FormFeedback>
                        </FormGroup>
                        {changeError &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {changeError}
                        </Alert>)}
                        <div className={'text-right'}>
                            {cancelHandler &&
                            <Button color="light" onClick={cancelHandler} className={'mr-3'}>Отменить</Button>}
                            <Button type="submit" color={'success'} disabled={changeLoading}>
                                {changeLoading &&
                                (<Spinner
                                    size="sm"
                                />)}
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default EmployeeChangePassword;
