import React, {Component} from "react";
import { Redirect } from "react-router-dom";


class ReportsPage extends Component {
    render() {
        return (
            <Redirect to={'/reports/companies'}/>
        );
    }
}

export default ReportsPage;
