import React, {Component} from "react";
import App from '../components/App';
import {sseOrders} from "../store/actions/orders";
import {connect} from "react-redux";
import {IOrderShort} from "../models/Order";
import {SSEService} from "../services/SSEService";
import {sseNotificationsCount} from "../store/actions/notifications";
import {AppState} from "../store/reducers";
import {IAuth} from "../models/Auth";
import {accessOrderShow} from "../utils/user-accesses";
import {determineDefaultPath} from "../utils/navigation";


type AppType = {
    sseOrders: (data: IOrderShort[]) => void
    sseNotificationsCount: (count: number) => void
    user: IAuth | null
}

class AppContainer extends Component<AppType> {
    sse: any;

    componentDidMount() {
        const {user} = this.props;
        user !== null && this.callSSE()
    }

    componentDidUpdate(prevProps: Readonly<AppType>) {
        const {user} = this.props;
        if(prevProps.user !== user){
            user !== null && user.accesses && user.accesses.includes(accessOrderShow) && this.callSSE()
        }
    }

    componentWillUnmount() {
        this.sse.close()
    }

    callSSE = () =>{
        const {sseOrders, sseNotificationsCount} = this.props;

        const service = new SSEService();
        this.sse = new EventSource(service.call());

        this.sse.addEventListener('order', function (e: any) {
                e.data && sseOrders(JSON.parse(e.data));
            }
        );
        this.sse.addEventListener('notification', function (e: any) {
                sseNotificationsCount(Number(e.data))
            }
        );
    };

    render() {
        const {user} = this.props;
        
        /**
         * Раздел, который показывается в начале работы или при переходе на адрес "/".
         * Зависит от прав доступа текущего пользователя.
         *
         * Если объект пользователя user еще не получен, перенаправлять на Заказы.
         * Когда объект user будет получен, PrivateRoute определит наличие доступа и если прав нет, перенаправит на '/',
         * что в свою очередь перенаправит на раздел, определенный determineInitialPath.
         * @see PrivateRoute
         */
        const defaultPath = user
          ? determineDefaultPath(user.accesses)
          : '/orders';
        
        return <App
          defaultPath={defaultPath}
        />
    }
}

const mapStateToProps = ({auth}: AppState) => {
    const {user} = auth;
    return {
        user
    }
};

const mapDispatchToProps = {
    sseOrders,
    sseNotificationsCount
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
