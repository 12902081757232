export const ADD_CITY_FORM = Symbol('ADD_CITY_FORM');
export const ADD_CITY_FORM_REQUEST = Symbol('ADD_CITY_FORM_REQUEST');
export const ADD_CITY_FORM_SUCCESS = Symbol('ADD_CITY_FORM_SUCCESS');
export const ADD_CITY_FORM_ERROR = Symbol('ADD_CITY_FORM_ERROR');
export const EDIT_CITY_FORM = Symbol('EDIT_CITY_FORM');
export const EDIT_CITY_FORM_REQUEST = Symbol('EDIT_CITY_FORM_REQUEST');
export const EDIT_CITY_FORM_SUCCESS = Symbol('EDIT_CITY_FORM_SUCCESS');
export const EDIT_CITY_FORM_ERROR = Symbol('EDIT_CITY_FORM_ERROR');
export const GET_CITY_FORM = Symbol('GET_CITY_FORM');
export const GET_CITY_FORM_REQUEST = Symbol('GET_CITY_FORM_REQUEST');
export const GET_CITY_FORM_SUCCESS = Symbol('GET_CITY_FORM_SUCCESS');
export const GET_CITY_FORM_ERROR = Symbol('GET_CITY_FORM_ERROR');
export const DELETE_CITY_FORM = Symbol('DELETE_CITY_FORM');
export const DELETE_CITY_FORM_REQUEST = Symbol('DELETE_CITY_FORM_REQUEST');
export const DELETE_CITY_FORM_SUCCESS = Symbol('DELETE_CITY_FORM_SUCCESS');
export const DELETE_CITY_FORM_ERROR = Symbol('DELETE_CITY_FORM_ERROR');
export const OPEN_CITY_FORM = Symbol('OPEN_CITY_FORM');
export const CLOSE_CITY_FORM = Symbol('CLOSE_CITY_FORM');

