import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import Empty from "../components/UI/Empty";
import {IDictionary} from "../models/Dictionary";
import {getDictionaryCompanies} from "../store/actions/dictionaryCompanies";
import PaymentNavbar from "./PaymentNavbar";
import Payments from "./Payments";
import {accessPaymentCreate} from "../utils/user-accesses";


type PaymentsOutputType = {
    getDictionaryCompanies: () => void
    dictionaryCompanies: IDictionary[]
    dictionaryCompaniesLoading: boolean
    dictionaryCompaniesError: string
    accesses?:string[]
}


class PaymentsOutputContainer extends Component<PaymentsOutputType> {

    componentDidMount() {
        const {getDictionaryCompanies} = this.props;
        getDictionaryCompanies();
    }

    render() {
        const {
            getDictionaryCompanies, dictionaryCompaniesLoading, dictionaryCompanies, dictionaryCompaniesError,accesses
        } = this.props;

        if (dictionaryCompaniesLoading) {
            return <Loader/>
        }

        if (dictionaryCompaniesError) {
            return <Error
                error={dictionaryCompaniesError}
                refresh={getDictionaryCompanies}
            />
        }

        if (!!dictionaryCompanies.length) {
            return (
                <>
                    {Array.isArray(accesses) && accesses.includes(accessPaymentCreate) &&
                    <div className="mb-2">
                        <PaymentNavbar dictionaryCompanies={dictionaryCompanies}/>
                    </div>}
                    <Payments dictionaryCompanies={dictionaryCompanies}/>
                </>
            )
        }
        return (
            <Empty>
                <h5 className={'mb-0'}>Список пуст</h5>
            </Empty>
        )
    }
}


const mapStateToProps = ({dictionaryCompanies, auth}: AppState) => {
    return {
        accesses: auth.user !== null ? auth.user.accesses : undefined,
        dictionaryCompanies: dictionaryCompanies.data,
        dictionaryCompaniesLoading: dictionaryCompanies.loading,
        dictionaryCompaniesError: dictionaryCompanies.error
    }
};

const mapDispatchToProps = {
    getDictionaryCompanies
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsOutputContainer);
