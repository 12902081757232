import {IAction} from "./index";
import {ICourierChangePointForm} from '../../models/Courier';
import {
    CHANGE_POINT_COURIER_FORM,
    CHANGE_POINT_COURIER_FORM_ERROR,
    CHANGE_POINT_COURIER_FORM_REQUEST,
    CHANGE_POINT_COURIER_FORM_SUCCESS, CLOSE_COURIER_FORM, OPEN_COURIER_FORM
} from "../constants/courierForm";


export interface IActionCourierForm extends IAction<symbol, any> {

}


export const changePointCourierFormRequest = () => {
    return {
        type: CHANGE_POINT_COURIER_FORM_REQUEST
    }
};

export const changePointCourierFormSuccess = () => {
    return {
        type: CHANGE_POINT_COURIER_FORM_SUCCESS
    }
};

export const changePointCourierFormError = (payload: string) => {
    return {
        type: CHANGE_POINT_COURIER_FORM_ERROR,
        payload
    }
};

export const changePointCourierForm = (id: number, form: ICourierChangePointForm) => {
    return {
        type: CHANGE_POINT_COURIER_FORM,
        payload: {id, form}
    };
};


export const openCourierForm = (mode: 'changePoint', modeId?: number) => {
    return {
        type: OPEN_COURIER_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closeCourierForm = () => {
    return {
        type: CLOSE_COURIER_FORM
    };
};
