import {
    GET_REPORT_EMENU_REQUEST,
    GET_REPORT_EMENU_SUCCESS,
    GET_REPORT_EMENU_ERROR
} from "../constants/reportEmenu"
import {IReportEmenu} from "../../models/Report"
import {IActionReportEmenu} from "../actions/reportEmenu";

export interface IReportEmenuState {
    loading: boolean
    error: string,
    data: IReportEmenu[],
    total: number
    by: number
}

const initialState: IReportEmenuState = {
    data: [],
    loading: false,
    error: '',
    total: 0,
    by: 0
}

export default function reportEmenu(state = initialState, action: IActionReportEmenu): IReportEmenuState {
    switch (action.type) {
        case GET_REPORT_EMENU_REQUEST:
            return {
                data: [],
                loading: true,
                error: '',
                total: 0,
                by: 0
            }
        case GET_REPORT_EMENU_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                data,
                loading: false,
                error: '',
                total,
                by
            }
        case GET_REPORT_EMENU_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload,
                total: 0,
                by: 0
            }
        default:
            return state
    }
}