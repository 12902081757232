import {createSelector} from "reselect";
import queryString from 'query-string';

const bonusRateUrlSelector = (props: any) => {
    return props.location.search
};

export const bonusRateCityIdReSelector = createSelector(
    [bonusRateUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.cityId ? +getParams.cityId : undefined
    }
);
