import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {ICompanyContact, ICompanyContactEditFormRequest} from "../models/Company";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {editCompanyContactForm, getCompanyContactForm} from "../store/actions/companyContactForm";
import CompanyContactEdit from "../components/CompanyContactEdit";
import {IDictionary} from "../models/Dictionary";


type CompanyContactEditType = {
    getCompanyContactForm: (id: number) => void
    editCompanyContactForm: (id: number, itemId: number, form: ICompanyContactEditFormRequest) => void
    cancelHandler?: () => void
    id: number
    sectionId: number
    data: ICompanyContact | null
    changeLoading: boolean
    changeError: string
    getLoading: boolean
    getError: string
    contactTypes: IDictionary[]
}


class CompanyContactEditContainer extends Component<CompanyContactEditType> {
    componentDidMount() {
        const {id, getCompanyContactForm} = this.props;
        getCompanyContactForm(id)
    }

    render() {
        const {id, data, getLoading, getError, changeLoading, changeError, getCompanyContactForm, editCompanyContactForm, cancelHandler, sectionId, contactTypes} = this.props;

        if (getLoading) {
            return <Loader/>
        }

        if (getError) {
            return <Error error={getError} refresh={() => getCompanyContactForm(id)}/>
        }
        if (data !== null) {
            return (
                <CompanyContactEdit
                    editCompanyContactForm={(id, form) => editCompanyContactForm(sectionId, id, form)}
                    cancelHandler={cancelHandler}
                    data={data}
                    contactTypes={contactTypes}
                    changeLoading={changeLoading}
                    changeError={changeError}/>
            )
        }
        return null;
    }
}

const mapStateToProps = ({companyContactForm}: AppState) => {
    const {data, getLoading, getError, changeLoading, changeError} = companyContactForm;

    return {
        data,
        getLoading,
        getError,
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    editCompanyContactForm,
    getCompanyContactForm
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyContactEditContainer);
