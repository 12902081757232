import {call, put, takeLatest} from 'redux-saga/effects';
import {getDictionaryOrderStatusesError, getDictionaryOrderStatusesRequest, getDictionaryOrderStatusesSuccess} from '../actions/dictionaryOrderStatuses';
import {IDictionary} from "../../models/Dictionary";
import { DictionaryService } from '../../services/DictionaryService';
import {GET_DICTIONARY_ORDER_STATUSES} from "../constants/dictionaryOrderStatuses";



function* getDictionaryOrderStatusesSaga() {
    try {
        yield put(getDictionaryOrderStatusesRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryOrderStatuses);

        const response:IDictionary[] = promise.data || [];

        yield put(getDictionaryOrderStatusesSuccess(response));


    } catch (error) {
        yield put(getDictionaryOrderStatusesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryOrderStatuses() {
    yield takeLatest(GET_DICTIONARY_ORDER_STATUSES, getDictionaryOrderStatusesSaga);
}
