import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {editPaymentForm, getPaymentForm} from "../store/actions/paymentForm";
import {IPayment, IPaymentEditFormRequest} from "../models/Payment";
import PaymentEdit from "../components/PaymentEdit";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";


type PaymentEditType = {
    id: number
    getPaymentForm: (id: number) => void
    editPaymentForm: (id: number, form: IPaymentEditFormRequest) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
    getLoading: boolean
    getError: string
    data: IPayment | null
}

class PaymentEditContainer extends Component<PaymentEditType> {

    componentDidMount() {
        const {getPaymentForm, id} = this.props;
        getPaymentForm(id)
    }

    render() {
        const {
            id, data, changeLoading, changeError, editPaymentForm, cancelHandler,
            getError, getLoading, getPaymentForm,
        } = this.props;

        if (getLoading) {
            return <Loader/>
        }

        if (getError) {
            return <Error error={getError} refresh={() => getPaymentForm(id)}/>
        }

        if (data !== null) {
            return (
                <PaymentEdit
                    data={data}
                    editPaymentForm={editPaymentForm}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    cancelHandler={cancelHandler}
                />
            )
        }
        return null
    }
}

const mapStateToProps = (state: AppState) => {
    const {data, changeLoading, changeError, getLoading, getError} = state.paymentForm;
    return {
        data,
        changeLoading,
        changeError,
        getLoading,
        getError
    }
};

const mapDispatchToProps = {
    editPaymentForm,
    getPaymentForm
};


export default connect(mapStateToProps, mapDispatchToProps)(PaymentEditContainer);
