import React, {PureComponent} from 'react';
import {Alert, Button, Form, Spinner, FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import Dropdown from "../UI/Dropdown";
import {sanitizeForm} from "../../utils/sanitize-form";
import {IRole, IRoleEditFormRequest} from "../../models/Role";
import {getDictionaryOptions} from "../../utils/dictionaries";
import {IDictionary} from "../../models/Dictionary";


const schema = yup.object({
    name: yup.string().required(requiredMessage()),
    description: yup.string().required(requiredMessage()),
    permissions: yup.array().nullable().required(requiredMessage())
});

type RoleEditType = {
    editRoleForm: (id: string, form: IRoleEditFormRequest) => void
    data: IRole
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
    permissions: IDictionary[]
}

class RoleEdit extends PureComponent<RoleEditType> {
    render() {
        const {editRoleForm, changeLoading, changeError, cancelHandler, permissions, data} = this.props;
        const dictionaryPermissionOptions = getDictionaryOptions(permissions);

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const name = sanitizeForm(values.name);
                    const description = sanitizeForm(values.description);
                    const permissions = sanitizeForm(values.permissions);

                    const form = {
                        name,
                        description,
                        permissions
                    };

                    editRoleForm(data.name, form)
                }}
                initialValues={{
                    name: data.name || '',
                    description: data.description || '',
                    permissions: data.permissions || null
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      dirty
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>Название <span className={'required'}>*</span></Label>
                            <Input
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                invalid={touched.name && !!errors.name}
                            />
                            <FormFeedback>{errors.name}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Описание <span className={'required'}>*</span></Label>
                            <Input
                                type="text"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                invalid={touched.description && !!errors.description}
                            />
                            <FormFeedback>{errors.description}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Права</Label>
                            <Dropdown
                                name={'permissions'}
                                value={values.permissions}
                                options={dictionaryPermissionOptions}
                                isMulti
                                onChange={(value) => setFieldValue('permissions', value)}
                                invalid={touched.permissions && !!errors.permissions}
                            />
                            <FormFeedback>{errors.permissions}</FormFeedback>
                        </FormGroup>
                        {changeError &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {changeError}
                        </Alert>)}
                        <div className={'text-right'}>
                            {cancelHandler &&
                            <Button color="light" onClick={cancelHandler} className={'mr-3'}>Отменить</Button>}
                            <Button type="submit" color={'success'} disabled={changeLoading || !dirty}>
                                {changeLoading &&
                                (<Spinner
                                    size="sm"
                                />)}
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default RoleEdit;
