import {call, put, takeLatest} from 'redux-saga/effects';
import {
    cancelScheduleError,
    cancelScheduleRequest, cancelScheduleSuccess, getSchedule,
    getScheduleError,
    getScheduleRequest,
    getScheduleSuccess, publishScheduleError,
    publishScheduleRequest,
    publishScheduleSuccess
} from "../actions/schedule";
import {ISchedule} from "../../models/Schedule";
import {ScheduleService} from "../../services/ScheduleService";
import {CANCEL_SCHEDULE, GET_SCHEDULE, PUBLISH_SCHEDULE} from "../constants/schedule";


type getScheduleType = {
    type: typeof GET_SCHEDULE,
    payload: number
};

function* getScheduleSaga({payload}: getScheduleType) {
    try {
        yield put(getScheduleRequest());

        const service = new ScheduleService();

        const promise = yield call(service.getSchedule, payload);

        const response: ISchedule = promise.data;

        yield put(getScheduleSuccess(response));

    } catch (error) {
        yield put(getScheduleError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetSchedule() {
    yield takeLatest(GET_SCHEDULE, getScheduleSaga);
}


type publishScheduleType = {
    type: typeof PUBLISH_SCHEDULE,
    payload: number
};

function* publishScheduleSaga({payload}: publishScheduleType) {
    try {
        yield put(publishScheduleRequest());

        const service = new ScheduleService();

        yield call(service.publishSchedule, payload);
        yield put(publishScheduleSuccess());

        yield put({type:GET_SCHEDULE, payload})

    } catch (error) {
        yield put(publishScheduleError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchPublishSchedule() {
    yield takeLatest(PUBLISH_SCHEDULE, publishScheduleSaga);
}


type cancelScheduleType = {
    type: typeof CANCEL_SCHEDULE,
    payload: number
};

function* cancelScheduleSaga({payload}: cancelScheduleType) {
    try {
        yield put(cancelScheduleRequest());

        const service = new ScheduleService();

        yield call(service.cancelSchedule, payload);
        yield put(cancelScheduleSuccess());

        yield put(getSchedule(payload));
    } catch (error) {
        yield put(cancelScheduleError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchCancelSchedule() {
    yield takeLatest(CANCEL_SCHEDULE, cancelScheduleSaga);

}
