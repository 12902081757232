import React, {Component} from "react";
import {IOrder} from "../models/Order";
import {Button, Col, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import CourierLocation from "./CourierLocation";
import {format, parseISO} from "date-fns";
import {AppState} from "../store/reducers";
import {connect} from "react-redux";
import {clearCourierPhone, getCourierPhone} from "../store/actions/courierPhone";
import Icon from "../components/UI/Icon";
import {externalDeliveryServiceLogos} from "../utils/order";

type OrderExternalCourierInfoType = {
  order: IOrder;
  isArchive?: boolean;
} & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type OrderExternalCourierInfoState = {
  isCourierLocationDialog: boolean;
  isExternalStatusHistoryDialog: boolean;
}

class OrderExternalCourierInfo extends Component<OrderExternalCourierInfoType, OrderExternalCourierInfoState> {
  
  state = {
    isCourierLocationDialog: false,
    isExternalStatusHistoryDialog: false,
  }
  
  openCourierLocationDialog = () => {
    this.setState({isCourierLocationDialog: true})
  };
  
  closeCourierLocationDialog = () => {
    this.setState({isCourierLocationDialog: false})
  };
  
  openExternalStatusHistoryDialog = () => {
    this.setState({isExternalStatusHistoryDialog: true})
  };
  
  closeExternalStatusHistoryDialog = () => {
    this.setState({isExternalStatusHistoryDialog: false})
  };
  
  requestCourierPhone = () => {
    const {order: {id}, getCourierPhone} = this.props;
    
    getCourierPhone(id);
  }
  
  courierPhoneExpireTimer: NodeJS.Timeout | null = null;
  
  componentDidUpdate(prevProps: Readonly<OrderExternalCourierInfoType>) {
    if (!prevProps.courierPhone && this.props.courierPhone) {
      if (this.courierPhoneExpireTimer) {
        clearTimeout(this.courierPhoneExpireTimer);
      }
      
      this.courierPhoneExpireTimer = setTimeout(() => {
        this.props.clearCourierPhone();
      }, this.props.courierPhone.ttlSeconds * 1000);
    }
  }
  
  componentWillUnmount() {
    this.props.clearCourierPhone();
    
    if (this.courierPhoneExpireTimer) {
      clearTimeout(this.courierPhoneExpireTimer);
    }
  }
  
  render() {
    const {
      order,
      courierPhone,
      courierPhoneGetLoading,
      isArchive,
    } = this.props;
    const {isCourierLocationDialog, isExternalStatusHistoryDialog} = this.state;
    
    const {
      id,
      externalOrderInfo,
      deliveryService,
    } = order;
    
    if (!externalOrderInfo) {
      return null;
    }
    
    const hasCourierName = !!externalOrderInfo.courierName;
    
    return <Col md={'6'}>
      {hasCourierName && <Row className="mb-1 align-items-center" form>
        {deliveryService && <Col md={'auto'}>
          <img alt={deliveryService} src={externalDeliveryServiceLogos[deliveryService]} height={40} width={40}/>
        </Col>}
        {courierPhoneGetLoading && <Col md={'auto'}><Spinner size={"sm"}/></Col>}
        {courierPhone && !isArchive && <Col className={'m-auto'} md={'auto'}>
            <a href={`tel:${courierPhone.phone}${courierPhone.ext ? `,${courierPhone.ext}` : ''}`}>
                <Icon name={'call'} color={'black'}/>
            </a>
        </Col>}
        <Col>
          <div className={'semi-bold'}>
            {externalOrderInfo.courierName}
          </div>
          {courierPhone && !isArchive && <div className={'gray-500'}>{courierPhone.phone}{courierPhone.ext ? ` (доп. ${courierPhone.ext})` : ''}</div>}
        </Col>
      </Row>}
  
      {hasCourierName && !isArchive && <Button
        className="mt-1 mr-1"
        color={'light'}
        onClick={this.openCourierLocationDialog}>
        Местоположение курьера
      </Button>}
      {hasCourierName && !isArchive && !courierPhone && !courierPhoneGetLoading && <Button
        className="mt-1 mr-1"
        color={'light'}
        onClick={this.requestCourierPhone}>
        Получить телефон
      </Button>}
      <Button
        className="mt-1"
        color={'light'}
        onClick={this.openExternalStatusHistoryDialog}>
        История статусов
      </Button>
  
      {hasCourierName && !isArchive && <Modal isOpen={isCourierLocationDialog} size={'lg'} toggle={this.closeCourierLocationDialog}>
        <ModalHeader toggle={this.closeCourierLocationDialog}>
          Местоположение курьера
        </ModalHeader>
        <ModalBody>
          <CourierLocation
            id={0}
            orderIdForExternalGet={id}
          />
        </ModalBody>
      </Modal>}
  
      <Modal size={'lg'} isOpen={isExternalStatusHistoryDialog} toggle={this.closeExternalStatusHistoryDialog}>
        <ModalHeader toggle={this.closeExternalStatusHistoryDialog}>
          История статусов
        </ModalHeader>
        <ModalBody>
          <ListGroup>
            {externalOrderInfo.history?.map(({status, createdAt}, index) =>
              <ListGroupItem key={index}>
                <Row>
                  <Col md={'auto'}>
                    <span className="gray-500">{format(parseISO(createdAt), 'HH:mm yyyy-MM-dd')}</span>
                  </Col>
                  <Col>
                    {status}
                  </Col>
                </Row>
              </ListGroupItem>)}
          </ListGroup>
        </ModalBody>
      </Modal>
    </Col>;
  }
}

const mapStateToProps = ({courierPhone}: AppState) => {
  return {
    courierPhone: courierPhone.data,
    courierPhoneGetLoading: courierPhone.getLoading,
    courierPhoneGetError: courierPhone.getError,
  }
};

const mapDispatchToProps = {
  getCourierPhone,
  clearCourierPhone,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderExternalCourierInfo);
