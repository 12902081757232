import React, {Component} from "react";
import {connect} from "react-redux";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {IDictionary} from "../models/Dictionary";
import {getDictionaryContactTypes} from "../store/actions/dictionaryContactTypes";
import {AppState} from "../store/reducers";
import CompanyContacts from "./CompanyContacts";
import CompanyContactNavbar from "./CompanyContactNavbar";
import {accessCompanyContactCreate, accessCompanyContactShow} from "../utils/user-accesses";
import Empty from "../components/UI/Empty";


type CompanyContactOutputType = {
    getDictionaryContactTypes: () => void
    data: IDictionary[]
    loading: boolean
    error: string
    id:number
    accesses?: string[]
};


class CompanyContactOutput extends Component<CompanyContactOutputType> {

    componentDidMount() {
        const {getDictionaryContactTypes} = this.props;
        getDictionaryContactTypes()
    }

    render() {
        const {id, data, loading, error, getDictionaryContactTypes, accesses} = this.props;

        if (loading) {
            return <Loader/>
        }

        if (error) {
            return <Error
                error={error}
                refresh={() => getDictionaryContactTypes()}
            />
        }
        if (!!data.length) {

            return (
                <>
                    {Array.isArray(accesses) && accesses.includes(accessCompanyContactCreate) &&
                    <CompanyContactNavbar id={id} contactTypes={data}/>}
                    {Array.isArray(accesses) && accesses.includes(accessCompanyContactShow) &&
                    <CompanyContacts id={id} contactTypes={data}/>}
                </>
            );
        }
        return (
            <Empty>
                <h5 className={'mb-0'}>Список типов контактов пуст</h5>
            </Empty>
        )
    }
}


const mapStateToProps = (state: AppState) => {
    const {data, loading, error} = state.dictionaryContactTypes;
    const {user} = state.auth;
    return {
        data,
        loading,
        error,
        accesses: user !== null ? user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getDictionaryContactTypes

};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContactOutput);
