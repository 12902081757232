import {Api} from "./Api";
import {
    IOrderAddFormRequest,
    IOrderChangeCoordinateRequest,
    IOrderEditFormRequest,
    IOrderRequest
} from "../models/Order";


export class OrderService {
    getOrders(page: number = 1, form?: IOrderRequest) {
        return new Api().call(`/v1/manage/order/search?page=${page}`, {method: 'POST', data: form});
    }

    changeStatusOrders(id: string, status: number) {
        return new Api().call(`/v1/manage/order/${id}/change-status/${status}`, {method: 'GET'});
    }

    getOrder(id: string) {
        return new Api().call(`/v1/manage/order/${id}`, {method: 'GET'});
    }

    addOrder(form: IOrderAddFormRequest) {
        return new Api().call(`/v1/manage/order`, {method: 'POST', data: form});
    }

    editOrder(id: string, form: IOrderEditFormRequest) {
        return new Api().call(`/v1/manage/order/${id}`, {method: 'POST', data: form});
    }

    changeCoordinateOrder(id: string, form: IOrderChangeCoordinateRequest) {
        return new Api().call(`/v1/manage/order/${id}/change-coordinate`, {method: 'POST', data: form});
    }

    getArchiveOrders(page: number = 1, form?: IOrderRequest) {
        return new Api().call(`/v1/manage/order/archive/search?page=${page}`, {method: 'POST', data: form});
    }

    getArchiveOrdersSummary(form?: IOrderRequest) {
        return new Api().call(`/v1/manage/order/archive/summary`, {method: 'POST', data: form})
    }
    
    getOrderLocation(id: string) {
        return new Api().call(`/v1/manage/order/${id}/location`, {method: 'GET'})
    }
    
    getCourierPhone(id: string) {
        return new Api().call(`/v1/manage/order/${id}/phone`, {method: 'GET'})
    }
    
    uploadReturnCheck(id: string, data: FormData) {
    
        const token = localStorage.getItem('auth');
    
        let authorization = {};
    
        if (!!localStorage.getItem('auth')) {
            authorization = {'Authorization': `Bearer ${token}`}
        }
        
        return new Api().call(
          `/v1/manage/order/${id}/upload-return-check`,
          {
              method: 'POST',
              headers: {
                  'Content-type': 'multipart/form-data',
                  ...authorization
              },
              data,
          },
        )
    }
    
    deleteReturnCheck(id: string) {
        return new Api().call(`/v1/manage/order/${id}/delete-return-check`, {method: 'DELETE'})
    }
}
