import React, {PureComponent} from 'react';
import Table from '../UI/Table';
import {ICourierScheduleByID} from "../../models/Courier";

type CourierScheduleByIdType = {
    data: ICourierScheduleByID[]
}

class CourierScheduleById extends PureComponent<CourierScheduleByIdType> {
    render() {
        const {data} = this.props;
        return (
            <Table striped noBorder size={'large'}>
                <tbody>
                <tr>
                    <th>День недели</th>
                    <th>Время работы</th>
                </tr>
                {data.map((data, index) => {
                        return (
                            <CourierScheduleByIdItem
                                key={data.dateItem}
                                data={data}
                                index={index}
                            />
                        )
                    }
                )}
                </tbody>
            </Table>
        );
    }
}

type CourierScheduleByIdItemType = {
    data: ICourierScheduleByID
    index: number
}

class CourierScheduleByIdItem extends PureComponent<CourierScheduleByIdItemType> {
    render() {
        const {data, index} = this.props;
        const {timeStart, timeEnd} = data;

        let day;

        switch (index) {
            case 0:
                day = 'Понедельник';
                break;
            case 1:
                day = 'Вторник';
                break;
            case 2:
                day = 'Среда';
                break;
            case 3:
                day = 'Четверг';
                break;
            case 4:
                day = 'Пятница';
                break;
            case 5:
                day = 'Суббота';
                break;
            case 6:
                day = 'Воскресенье';
                break;
        }

        const time = timeStart && timeEnd ? `${timeStart} - ${timeEnd}`: 'Выходной';

        return (
            <tr>
                <td>
                    {day}
                </td>
                <td>
                    {time}
                </td>
            </tr>
        );
    }
}

export default CourierScheduleById;
