import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Profile from "../components/Profile";
import {compose} from "redux";
import _ from "lodash";


type ProfileType = {
    fio:string
    role?: {
        name: string
        description: string
    }
}

class ProfileContainer extends Component<ProfileType> {
    render() {
        const {fio, role} = this.props;
        return <Profile fio={fio} role={role}/>
    }
}


const mapStateToProps = ({auth}: AppState) => {
    const {user} = auth;
    return {
        fio: _.get(user, 'fio'),
        role: _.get(user, 'role'),
    }
};


export default compose<React.ComponentClass>(connect(mapStateToProps, null))(ProfileContainer);
