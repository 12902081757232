import React, {PureComponent} from "react";
import {IPointZone} from "../models/Point";
import {Map, Polygon, YMaps} from "react-yandex-maps";
import {COURIER_TYPE_AUTO, COURIER_TYPE_BIKE, COURIER_TYPE_MOTORCYCLE, COURIER_TYPE_WALK} from "../utils/couriers";
import {generateColor} from "../utils/generate-color";

type DeliveryZonesType = {
    data: IPointZone[]
}

type DeliveryZonesState = {
    bounds: number[][];
}

class DeliveryZones extends PureComponent<DeliveryZonesType, DeliveryZonesState> {
    
    state: DeliveryZonesState = {
        bounds: [],
    }
    
    /**
     * Вычисление рамок карты, чтобы полигоны отображались с нужным центром и zoom-ом
     */
    componentDidMount() {
        
        const allLatitudes: number[] = [];
        const allLongitudes: number[] = [];
    
        this.props.data
            .filter(({polygon}) => polygon?.length)
            .forEach(({polygon}) => {
                // в тестовых данных широта приходит с 1 индексом, а долгота с 0 индексом
                allLatitudes.push(...polygon.map(p => p[1]));
                allLongitudes.push(...polygon.map(p => p[0]));
            });
    
        const bounds: number[][] = [
            [
                Math.min(...allLatitudes),
                Math.min(...allLongitudes),
            ],
            [
                Math.max(...allLatitudes),
                Math.max(...allLongitudes),
            ]
        ]
        
        this.setState({bounds});
    }
    
    render() {
        const {data} = this.props;
        const {bounds} = this.state;

        return <YMaps>
            <Map
                state={{
                    bounds,
                }}
                modules={["geoObject.addon.balloon"]}
                className={'map'}>

                {data.map((pointZone: IPointZone, index: number) => {

                    const color = generateColor();

                    let zIndex;

                    switch (pointZone.typeId) {
                        case COURIER_TYPE_AUTO:
                            zIndex = 5;
                            break;
                        case COURIER_TYPE_MOTORCYCLE:
                            zIndex = 8;
                            break;
                        case COURIER_TYPE_BIKE:
                            zIndex = 10;
                            break;
                        case COURIER_TYPE_WALK:
                        default:
                            zIndex = 15;
                            break;
                    }

                    let polygon: number[][] = [];

                    pointZone.polygon.forEach((props: number[]) => {
                        polygon = [...polygon, [...props].reverse()]
                    })

                    return <Polygon
                        key={index}
                        geometry={[polygon]}
                        options={{
                            fillColor: `#${color}`,
                            strokeColor: `#${color}`,
                            opacity: 0.5,
                            zIndex
                        }}
                        properties={{
                            balloonContent: `${pointZone.name}`,
                        }}/>
                })}
            </Map>
        </YMaps>;
    }
}

export default DeliveryZones;
