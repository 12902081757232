import {createSelector} from "reselect";
import queryString from 'query-string';
import _ from 'lodash';



const companyBranchesUrlSelector = (props: any) => {
    return props.location.search
};

export const companyBranchesPageReSelector = createSelector(
    [companyBranchesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.page ? +getParams.page : 1
    }
);


export const companyBranchesSortReSelector = createSelector(
    [companyBranchesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['orderBy']);

        let orderBy = {} as {[key:string]: string};
        if(params.orderBy &&  typeof params.orderBy === "string") {
            const orderByArray = params.orderBy.split('_');
            orderBy[orderByArray[0]] = orderByArray[1] + '_' + orderByArray[2];
        }

        const sort = _.pickBy(orderBy, function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(sort) ? {orderBy:sort} : undefined
    }
);

export const companyBranchesFilterReSelector = createSelector(
    [companyBranchesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['cityId', 'pointId', 'address', 'status']);

        const cityId = params.cityId? +params.cityId: undefined;
        const pointId = params.pointId? +params.pointId: undefined;
        const address = params.address? params.address: undefined;
        const status = params.status && typeof params.status === "string" ? JSON.parse(params.status): undefined;

        const filter = _.pickBy({cityId, pointId, address, status}, function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(filter) ? filter : undefined
    }
);
