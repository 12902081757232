import {all} from 'redux-saga/effects';
import {watchLogin, watchGetUser, watchGetUserSilent, watchLogout, watchChangePasswordUser} from "./auth";
import {watchGetPoints} from './points';
import {watchGetPointZones} from "./pointZones";
import {watchAddPointForm, watchDeletePointForm, watchEditPointForm, watchGetPointForm} from './pointForm';
import {
    watchEditPointZoneForm,
    watchGetPointZoneForm,
    watchDeletePointZoneForm,
    watchAddPointZoneForm
} from "./pointZoneForm";
import {watchGetCities} from './cities';
import {watchAddCityForm, watchGetCityForm, watchEditCityForm, watchDeleteCityForm} from './cityForm';
import {watchGetCompanies} from './companies';
import {watchAddCompanyForm, watchEditCompanyForm, watchGetCompanyForm, watchDeleteCompanyForm} from './companyForm';
import {watchGetCompanyContacts} from './companyContacts';
import {
    watchAddCompanyContactForm,
    watchEditCompanyContactForm,
    watchGetCompanyContactForm,
    watchDeleteCompanyContactForm
} from './companyContactForm';
import {watchGetCompanyBranches} from './companyBranches';
import {watchGetCompany} from './company';
import {
    watchAddCompanyBranchForm,
    watchDeleteCompanyBranchForm,
    watchEditCompanyBranchForm,
    watchGetCompanyBranchForm
} from "./companyBranchForm";
import {watchGetEmployees} from './employees';
import {watchGetDictionaryRoles} from "./dictionaryRoles";
import {
    watchAddEmployeeForm,
    watchEditEmployeeForm,
    watchGetEmployeeForm,
    watchDeleteEmployeeForm,
    watchChangePasswordEmployeeForm
} from "./employeeForm";
import {watchGetDictionaryContactTypes} from './dictionaryContactTypes';
import {watchGetRoles} from './roles';
import {watchAddRoleForm, watchDeleteRoleForm, watchEditRoleForm, watchGetRoleForm} from './roleForm';
import {watchGetDictionaryPermissions} from "./dictionaryPermissions";
import {watchGetSchedules} from "./schedules";
import {watchGetSchedule, watchPublishSchedule, watchCancelSchedule} from "./schedule";
import {watchGetScheduleForm, watchAddScheduleForm, watchCopyScheduleForm} from "./scheduleForm";
import {watchGetScheduleDate} from './scheduleDate';
import {
    watchAddScheduleDateForm,
    watchCopyScheduleDateForm,
    watchEditScheduleDateForm,
    watchGetScheduleDateForm
} from './scheduleDateForm';
import {watchGetDictionaryPoints} from "./dictionaryPoints";
import {watchGetDictionaryCouriers} from './dictionaryCouriers';
import {watchGetDictionaryPublishedSchedules} from "./dictionaryPublishedSchedules";
import {watchGetOrders, watchChangeStatusOrders} from "./orders";
import {watchGetDictionaryOrderStatuses} from "./dictionaryOrderStatuses";
import {watchGetOrder} from "./order";
import {watchGetDictionaryCityEagers} from './dictionaryCityEagers';
import {watchAddOrderForm, watchEditOrderForm, watchGetOrderForm, watchChangeCoordinateOrderForm} from './orderForm';
import {watchGetDictionaryCompanies} from "./dictionaryCompanies";
import {watchGetDictionaryCompanyBranches} from './dictionaryCompanyBranches';
import {watchGetCourier, watchEnableStatusCourier, watchDisableStatusCourier} from "./courier";
import {watchChangeStatusArchiveOrders, watchGetArchiveOrders, watchGetArchiveOrdersSummary} from "./archiveOrders";
import {watchGetCouriers} from "./couriers";
import {watchGetCouriersLocations} from "./couriersLocations";
import {watchChangePointCourierForm} from './courierForm';
import {watchGetCourierStatistics} from "./courierStatistics";
import {watchGetCourierPenalties} from "./courierPenalties";
import {watchAddCourierPenaltyForm, watchDeleteCourierPenaltyForm} from "./courierPenaltyForm";
import {watchGetPenalties} from "./penalties";
import {watchGetCourierSchedule} from "./courierSchedule";
import {watchGetCourierScheduleById} from './courierScheduleById';
import {watchDeleteNotification, watchGetNotifications} from "./notifications";
import {watchAddPenaltyForm, watchDeletePenaltyForm, watchEditPenaltyForm, watchGetPenaltyForm} from "./penaltyForm";
import {watchGetReportCompanies} from "./reportCompanies";
import {watchGetDictionaryReportCompanyPeriod} from './dictionaryReportCompanyPeriod';
import {watchGetReportCouriers} from './reportCouriers';
import {watchGetDictionaryReportCourierPeriod} from "./dictionaryReportCourierPeriod";
import {watchGetReportEmenu} from "./reportEmenu";
import {watchGetPayments} from "./payments";
import {watchAddPaymentForm, watchDeletePaymentForm, watchEditPaymentForm, watchGetPaymentForm} from './paymentForm';
import {watchGetFilteredCouriersListSaga} from './couriersFilteredList';
import {watchChangeCourier} from "./courierChange";
import {watchGetCouriersShifts} from "./couriersShifts";
import {watchAddCourierDocuments, watchDeleteCourierDocuments, watchGetCourierDocuments} from "./courierDocuments";
import {watchChangeCourierRate, watchGetCourierRate} from "./courierRate";
import {watchChangeCourierSlot} from "./courierSlot";
import {watchChangeBonusRate, watchGetBonusRate} from "./bonusRate";
import {watchGetSalary, watchGetSalaryTotal, watchPayGroupSalary, watchPaySalary} from "./salary";
import {watchChangeRatioRate, watchDeleteRatioRate, watchGetRatioRate} from "./ratioRate";
import {watchAddEmployeePhotos, watchDeleteEmployeePhotos} from "./employeePhotos";
import {watchGetLocation} from "./location";
import {watchGetCourierPhone} from "./courierPhone";
import {watchAddOrderReturnCheck, watchDeleteOrderReturnCheck} from "./orderReturnCheck";

export default function* sagas() {
    yield all([
        watchGetNotifications(),
        watchDeleteNotification(),

        watchLogin(),
        watchGetUser(),
        watchChangePasswordUser(),
        watchGetUserSilent(),
        watchLogout(),

        watchGetDictionaryRoles(),
        watchGetDictionaryContactTypes(),
        watchGetDictionaryPermissions(),
        watchGetDictionaryPoints(),
        watchGetDictionaryCouriers(),
        watchGetDictionaryPublishedSchedules(),
        watchGetDictionaryOrderStatuses(),
        watchGetDictionaryCityEagers(),
        watchGetDictionaryCompanies(),
        watchGetDictionaryCompanyBranches(),
        watchGetDictionaryReportCompanyPeriod(),
        watchGetDictionaryReportCourierPeriod(),

        watchGetPoints(),
        watchAddPointForm(),
        watchEditPointForm(),
        watchGetPointForm(),
        watchDeletePointForm(),
        watchGetPointZones(),
        watchAddPointZoneForm(),
        watchEditPointZoneForm(),
        watchGetPointZoneForm(),
        watchDeletePointZoneForm(),

        watchGetCities(),
        watchAddCityForm(),
        watchEditCityForm(),
        watchGetCityForm(),
        watchDeleteCityForm(),

        watchGetCompanies(),
        watchGetCompany(),
        watchAddCompanyForm(),
        watchEditCompanyForm(),
        watchGetCompanyForm(),
        watchDeleteCompanyForm(),
        watchGetCompanyContacts(),
        watchAddCompanyContactForm(),
        watchEditCompanyContactForm(),
        watchGetCompanyContactForm(),
        watchDeleteCompanyContactForm(),
        watchGetCompanyBranches(),
        watchAddCompanyBranchForm(),
        watchEditCompanyBranchForm(),
        watchGetCompanyBranchForm(),
        watchDeleteCompanyBranchForm(),

        watchGetEmployees(),
        watchAddEmployeeForm(),
        watchEditEmployeeForm(),
        watchGetEmployeeForm(),
        watchDeleteEmployeeForm(),
        watchChangePasswordEmployeeForm(),

        watchGetRoles(),
        watchAddRoleForm(),
        watchEditRoleForm(),
        watchGetRoleForm(),
        watchDeleteRoleForm(),

        watchGetSchedules(),
        watchGetSchedule(),
        watchPublishSchedule(),
        watchGetScheduleDate(),
        watchCancelSchedule(),
        watchGetScheduleForm(),
        watchAddScheduleForm(),
        watchCopyScheduleForm(),
        watchAddScheduleDateForm(),
        watchEditScheduleDateForm(),
        watchGetScheduleDateForm(),
        watchCopyScheduleDateForm(),

        watchGetOrders(),
        watchChangeStatusOrders(),
        watchGetOrder(),
        watchAddOrderForm(),
        watchEditOrderForm(),
        watchGetOrderForm(),
        watchChangeCoordinateOrderForm(),
        watchGetArchiveOrders(),
        watchGetArchiveOrdersSummary(),
        watchChangeStatusArchiveOrders(),
        watchGetLocation(),
        watchGetCourierPhone(),
        watchAddOrderReturnCheck(),
        watchDeleteOrderReturnCheck(),

        watchGetCourier(),
        watchChangeCourier(),
        watchGetFilteredCouriersListSaga(),
        watchGetCouriers(),
        watchGetCouriersLocations(),
        watchGetCouriersShifts(),
        watchChangeCourierSlot(),
        watchAddCourierDocuments(),
        watchGetCourierDocuments(),
        watchDeleteCourierDocuments(),
        watchAddEmployeePhotos(),
        watchDeleteEmployeePhotos(),
        watchChangePointCourierForm(),
        watchGetCourierStatistics(),
        watchGetCourierPenalties(),
        watchAddCourierPenaltyForm(),
        watchDeleteCourierPenaltyForm(),
        watchGetCourierSchedule(),
        watchGetCourierScheduleById(),
        watchEnableStatusCourier(),
        watchDisableStatusCourier(),
        watchGetCourierRate(),
        watchChangeCourierRate(),
        watchGetBonusRate(),
        watchChangeBonusRate(),
        watchGetSalary(),
        watchGetSalaryTotal(),
        watchPaySalary(),
        watchPayGroupSalary(),
        watchGetRatioRate(),
        watchChangeRatioRate(),
        watchDeleteRatioRate(),

        watchGetPenalties(),
        watchAddPenaltyForm(),
        watchEditPenaltyForm(),
        watchGetPenaltyForm(),
        watchDeletePenaltyForm(),

        watchGetReportCompanies(),
        watchGetReportCouriers(),
        watchGetReportEmenu(),

        watchGetPayments(),
        watchAddPaymentForm(),
        watchEditPaymentForm(),
        watchGetPaymentForm(),
        watchDeletePaymentForm()
    ])
}
