import React, {PureComponent} from 'react';
import {createPortal} from 'react-dom';
import {Toast, ToastHeader} from "reactstrap";
import styles from './Notify.module.scss'


const noticeRoot: any = document.getElementById('root');
const el = document.createElement('div');
el.className = 'notice';


type NotifyPropsType = {
    removeNotify: () => void
    message: string
}


class Notify extends PureComponent<NotifyPropsType> {
    timerHandler: any;

    componentDidMount() {
        noticeRoot.appendChild(el);
        this.setTimer()
    }

    componentDidUpdate(prevProps: Readonly<NotifyPropsType>) {
        if (prevProps.message !== this.props.message) {
            clearTimeout(this.timerHandler);
            this.setTimer()
        }
    }


    componentWillUnmount() {
        noticeRoot.removeChild(el);
        clearTimeout(this.timerHandler);
    }

    setTimer = () => {
        this.timerHandler = setTimeout(() => {
            this.closeHandler()
        }, 4000)
    };

    closeHandler = () => {
        this.props.removeNotify();
    };


    render() {
        const {message} = this.props;

        return createPortal(
            <div className={styles.notice}>
                <Toast>
                    <ToastHeader className={'pb-3 pt-3'} toggle={this.closeHandler}>
                        {message}
                    </ToastHeader>
                </Toast>
            </div>,
            el
        );
    }
}

export default Notify;
