import React, {PureComponent} from 'react';
import {
    Alert,
    Button,
    Form,
    Spinner,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Row,
    Col,
    Card,
    CustomInput
} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {IOrderAddFormRequest} from '../../models/Order';
import Dropdown from "../UI/Dropdown";
import NumberFormat from "react-number-format";
import Table from "../UI/Table";
import _ from "lodash";
import Icon from "../UI/Icon";
import {OrderPaymentOptions, ORDER_PAYMENT_CASH, timeForReadyOptions} from "../../utils/order";
import DateTimePicker from "../UI/DateTimePicker";
import {IAuth} from "../../models/Auth";
import {sanitizeForm} from "../../utils/sanitize-form";
import {getCityOptions} from "../../utils/cities";
import FormatPrice from "../UI/FormatPrice";
import {IDictionary} from "../../models/Dictionary";
import {getDictionaryOptions} from "../../utils/dictionaries";
import Error from "../UI/Error";
import Loader from "../UI/Loader";
import {minLengthMessage, requiredMessage} from "../../utils/form-validation";


const schema = yup.object({
    customerName: yup.string().required(requiredMessage()),
    phone: yup.string().required(requiredMessage()),
    cityId: yup.number().nullable().required(requiredMessage()),
    orderItems: yup.array().required(requiredMessage()).of(
        yup.object().shape({
            name: yup.string().required(requiredMessage()),
            quantity: yup.number().min(1, minLengthMessage(1)).required(requiredMessage()),
            price: yup.number().min(0, minLengthMessage(1)).required(requiredMessage())
        })
    ),
    deliveryPrice: yup.number(),
    correctionalPrice: yup.number(),
    paymentTypeId: yup.number().nullable().required(requiredMessage()),
    customerAmount: yup.string(),
    deliveryDateTime: yup.string(),
    timeForReady: yup.number().required(requiredMessage()),
    branchId: yup.number().required(requiredMessage()),
    street: yup.string().required(requiredMessage()),
    house: yup.string().required(requiredMessage()),
    flat: yup.string(),
    comments: yup.string()
});


type OrderAddPropsType = {
    getDictionaryCompanyBranches: (companyId: number, cityId: number) => void
    addOrderForm: (form: IOrderAddFormRequest) => void
    changeLoading: boolean
    changeError: string
    dictionaryCompanyBranches: IDictionary[]
    dictionaryCompanyBranchesLoading: boolean
    dictionaryCompanyBranchesError: string
    companyId: number
}

type OrderAddStateType = {
    isDeliveryDateTime: boolean
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

type OrderItemType = { name: string, quantity: number | string, price: number | string }

class OrderAdd extends PureComponent<OrderAddPropsType & CitiesType, OrderAddStateType> {

    state = {
        isDeliveryDateTime: false
    };

    changeDeliveryDateTimeHandler = () => {
        this.setState(({isDeliveryDateTime}) => ({
            isDeliveryDateTime: !isDeliveryDateTime
        }))
    };

    render() {
        const {
            addOrderForm, changeLoading, changeError, allowedCities, getDictionaryCompanyBranches,
            dictionaryCompanyBranches, dictionaryCompanyBranchesLoading, dictionaryCompanyBranchesError, companyId
        } = this.props;

        const dictionaryCompanyBranchOptions = getDictionaryOptions(dictionaryCompanyBranches);

        const {isDeliveryDateTime} = this.state;

        const cityOptions = getCityOptions(allowedCities);



        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const customerName = sanitizeForm(values.customerName);
                    const phone = sanitizeForm(values.phone);
                    const cityId = sanitizeForm(values.cityId);
                    const orderItems = sanitizeForm(values.orderItems.map((o: OrderItemType) => {
                        const name = sanitizeForm(o.name);
                        const quantity = sanitizeForm(o.quantity);
                        const price = sanitizeForm(o.price);
                        return {name, quantity, price}
                    }));
                    const deliveryPrice = sanitizeForm(values.deliveryPrice);
                    const correctionalPrice = sanitizeForm(values.correctionalPrice);
                    const paymentTypeId = sanitizeForm(values.paymentTypeId);
                    const customerAmount = sanitizeForm(values.customerAmount);
                    const deliveryDateTime = sanitizeForm(values.deliveryDateTime);
                    const timeForReady = sanitizeForm(values.timeForReady);
                    const branchId = sanitizeForm(values.branchId);
                    const street = sanitizeForm(values.street);
                    const house = sanitizeForm(values.house);
                    const flat = sanitizeForm(values.flat);
                    const comments = sanitizeForm(values.comments);

                    const form = {
                        customerName,
                        phone,
                        cityId,
                        orderItems,
                        deliveryPrice,
                        correctionalPrice,
                        paymentTypeId,
                        customerAmount,
                        deliveryDateTime,
                        timeForReady,
                        branchId,
                        street,
                        house,
                        flat,
                        comments
                    };

                    addOrderForm(form)
                }}
                initialValues={{
                    customerName: '',
                    phone: '',
                    cityId: null,
                    orderItems: [{name: '', quantity: '', price: ''}],
                    deliveryPrice: 0,
                    correctionalPrice: 0,
                    paymentTypeId: null,
                    customerAmount: '',
                    deliveryDateTime: '',
                    timeForReady: timeForReadyOptions[0].value,
                    branchId: null,
                    street: '',
                    house: '',
                    flat: '',
                    comments: ''
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => {
                    const addOrderItems = () => {
                        setFieldValue('orderItems', [...values.orderItems, {name: '', quantity: '', price: ''}])
                    };
                    const changeOrderItems = (index: number, orderItems: OrderItemType) => {
                        setFieldValue('orderItems', [
                            ...values.orderItems.slice(0, index),
                            orderItems,
                            ...values.orderItems.slice(index + 1)
                        ])
                    };
                    const removeOrderItems = (index: number) => {
                        setFieldValue('orderItems', [
                            ...values.orderItems.slice(0, index),
                            ...values.orderItems.slice(index + 1)
                        ]);
                    };

                    const orderItemsTotal = values.orderItems.reduce((total: number, value: OrderItemType) => {
                        return total + (+value.price * +value.quantity)
                    }, 0);

                    const totalPrice = (orderItemsTotal + (values.deliveryPrice || 0) + (values.correctionalPrice || 0)) || 0;

                    const isNegativeTotalPrice = totalPrice < 0;

                    if (cityOptions.length === 1 && values.cityId === null){
                        setFieldValue('cityId', cityOptions[0].value);
                        getDictionaryCompanyBranches(companyId, cityOptions[0].value);
                    }

                    return (
                        <Form onSubmit={handleSubmit}>
                            {isNegativeTotalPrice && (
                                <Alert color={'danger'}>
                                    Итоговая сумма должна быть положительным числом
                                </Alert>
                            )}
                            <Card body>
                                <Row>
                                    <Col md={'4'}>
                                        <FormGroup>
                                            <Label>Имя клиента <span className={'required'}>*</span></Label>
                                            <Input
                                                type="text"
                                                name="customerName"
                                                value={values.customerName}
                                                onChange={handleChange}
                                                invalid={touched.customerName && !!errors.customerName}
                                            />
                                            <FormFeedback>{errors.customerName}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={'4'}>
                                        <FormGroup>
                                            <Label>Номер телефона <span className={'required'}>*</span></Label>
                                            <NumberFormat
                                                name={`phone`}
                                                format={'+7 (###) ###-##-##'}
                                                mask={'_'}
                                                allowNegative={false}
                                                decimalSeparator={false}
                                                value={values.phone.substr(1)}
                                                onValueChange={({value}) => setFieldValue('phone', '7' + value)}
                                                invalid={touched.phone && !!errors.phone}
                                                customInput={Input}
                                            />
                                            <FormFeedback>{errors.phone}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={'4'}>
                                        <FormGroup>
                                            <Label>Город <span className={'required'}>*</span></Label>
                                            <Dropdown
                                                name={'cityId'}
                                                value={values.cityId}
                                                options={cityOptions}
                                                onChange={(value) => {
                                                    setFieldValue('cityId', value);
                                                    getDictionaryCompanyBranches(companyId, value);
                                                }}
                                                invalid={touched.cityId && !!errors.cityId}
                                            />
                                            <FormFeedback>{errors.cityId}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={'8'}>
                                        <Table className={'mb-2'}>
                                            <thead>
                                            <tr>
                                                <th>
                                                    Название товара <span className={'required'}>*</span>
                                                </th>
                                                <th>
                                                    Количество <span className={'required'}>*</span>
                                                </th>
                                                <th>
                                                    Цена <span className={'required'}>*</span>
                                                </th>
                                                <th>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {values.orderItems.map((item: OrderItemType, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{width: '50%'}}>
                                                            <Input
                                                                type="text"
                                                                name={`orderItems[${index}].name`}
                                                                value={_.get(values, `orderItems[${index}].name`)}
                                                                onChange={(e) => changeOrderItems(index, {
                                                                    ...item,
                                                                    name: e.target.value
                                                                })}
                                                                invalid={_.has(touched, `orderItems[${index}].name`) && _.has(errors, `orderItems[${index}].name`)}
                                                            />
                                                        </td>
                                                        <td style={{width: '25%'}}>
                                                            <NumberFormat
                                                                name={`orderItems[${index}].quantity`}
                                                                allowNegative={false}
                                                                decimalSeparator={false}
                                                                value={_.get(values, `orderItems[${index}].quantity`)}
                                                                onValueChange={({floatValue}) => changeOrderItems(index, {
                                                                    ...item,
                                                                    quantity: floatValue
                                                                })}
                                                                invalid={_.has(touched, `orderItems[${index}].quantity`) && _.has(errors, `orderItems[${index}].quantity`)}
                                                                customInput={Input}
                                                            />
                                                        </td>
                                                        <td style={{width: '25%'}}>
                                                            <NumberFormat
                                                                name={`orderItems[${index}].price`}
                                                                allowNegative={false}
                                                                decimalSeparator={false}
                                                                value={_.get(values, `orderItems[${index}].price`)}
                                                                onValueChange={({floatValue}) => changeOrderItems(index, {
                                                                    ...item,
                                                                    price: floatValue
                                                                })}
                                                                invalid={_.has(touched, `orderItems[${index}].price`) && _.has(errors, `orderItems[${index}].price`)}
                                                                customInput={Input}
                                                            />
                                                        </td>
                                                        <td style={{width: 58}}>
                                                            <Button
                                                                color={'outline-light'}
                                                                size={'sm'}
                                                                disabled={values.orderItems.length === 1}
                                                                onClick={() => removeOrderItems(index)}>
                                                                <Icon name={'delete'} color={'gray-500'}/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </Table>
                                        <Button
                                            color={'light'}
                                            className={'mb-4'}
                                            onClick={addOrderItems}>
                                            Добавить товар
                                        </Button>
                                        <Row>
                                            <Col md={'6'}>
                                                <FormGroup>
                                                    <Label>Тип оплаты <span className={'required'}>*</span></Label>
                                                    <Dropdown
                                                        name={'paymentTypeId'}
                                                        value={values.paymentTypeId}
                                                        options={OrderPaymentOptions}
                                                        onChange={(value) => {
                                                            setFieldValue('paymentTypeId', value);
                                                            setFieldValue('customerAmount', '');
                                                        }}
                                                        invalid={touched.paymentTypeId && !!errors.paymentTypeId}
                                                    />
                                                    <FormFeedback>{errors.paymentTypeId}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            {values.paymentTypeId === ORDER_PAYMENT_CASH && (
                                                <Col md={'6'}>
                                                    <FormGroup>
                                                        <Label>Сдача с</Label>
                                                        <Input
                                                            type="text"
                                                            name="customerAmount"
                                                            value={values.customerAmount}
                                                            onChange={handleChange}
                                                            invalid={touched.customerAmount && !!errors.customerAmount}
                                                        />
                                                        <FormFeedback>{errors.customerAmount}</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                            )}
                                        </Row>
                                        <h4 className={'mb-3'}>Доставка</h4>
                                        <FormGroup>
                                            <CustomInput
                                                type="switch"
                                                id="isDeliveryDateTime"
                                                checked={!isDeliveryDateTime}
                                                label={"Доставить как можно скорее"}
                                                onChange={() => {
                                                    setFieldValue('deliveryDateTime', '');
                                                    this.changeDeliveryDateTimeHandler();
                                                }}
                                            />
                                        </FormGroup>
                                        <Row>
                                            {isDeliveryDateTime && (
                                                <Col md={'6'}>
                                                    <FormGroup>
                                                        <Label>Время доставки заказа</Label>
                                                        <DateTimePicker
                                                            name={`deliveryDateTime`}
                                                            value={values.deliveryDateTime}
                                                            onChange={(value) => setFieldValue('deliveryDateTime', value)}
                                                            dateFormat={'yyyy-MM-dd HH:mm'}
                                                            showTimeSelect
                                                            invalid={touched.deliveryDateTime && !!errors.deliveryDateTime}
                                                        />
                                                        <FormFeedback>{errors.deliveryDateTime}</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                            )}
                                            <Col md={'6'}>
                                                <FormGroup>
                                                    <Label>Время подготовки заказа <span
                                                        className={'required'}>*</span></Label>
                                                    <Dropdown
                                                        name={'timeForReady'}
                                                        value={values.timeForReady}
                                                        options={timeForReadyOptions}
                                                        onChange={(value) => setFieldValue('timeForReady', value)}
                                                        invalid={touched.timeForReady && !!errors.timeForReady}
                                                    />
                                                    <FormFeedback>{errors.timeForReady}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {values.cityId && (
                                            <Row>
                                                <Col md={'6'}>
                                                    {dictionaryCompanyBranchesLoading && (
                                                        <Loader/>
                                                    )}
                                                    {dictionaryCompanyBranchesError && (
                                                        <Error error={dictionaryCompanyBranchesError} refresh={() => {
                                                            const cityId = values.cityId;
                                                            cityId && getDictionaryCompanyBranches(companyId, cityId)
                                                        }}/>
                                                    )}
                                                    {!!dictionaryCompanyBranches.length && (
                                                        <FormGroup>
                                                            <Label>Забрать заказ из <span
                                                                className={'required'}>*</span></Label>
                                                            <Dropdown
                                                                name={'branchId'}
                                                                value={values.branchId}
                                                                options={dictionaryCompanyBranchOptions}
                                                                onChange={(value) => setFieldValue('branchId', value)}
                                                                invalid={touched.branchId && !!errors.branchId}
                                                            />
                                                            <FormFeedback>{errors.branchId}</FormFeedback>
                                                        </FormGroup>
                                                    )}
                                                    {!dictionaryCompanyBranches.length && !dictionaryCompanyBranchesLoading && !dictionaryCompanyBranchesError && (
                                                        <Alert color={'info'}>
                                                            Нет ни одного филиала
                                                        </Alert>
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col md={'6'}>
                                                <FormGroup>
                                                    <Label>Улица <span
                                                        className={'required'}>*</span></Label>
                                                    <Input
                                                        type="text"
                                                        name="street"
                                                        value={values.street}
                                                        onChange={handleChange}
                                                        invalid={touched.street && !!errors.street}
                                                    />
                                                    <FormFeedback>{errors.street}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={'3'}>
                                                <FormGroup>
                                                    <Label>Дом <span
                                                        className={'required'}>*</span></Label>
                                                    <Input
                                                        type="text"
                                                        name="house"
                                                        value={values.house}
                                                        onChange={handleChange}
                                                        invalid={touched.house && !!errors.house}
                                                    />
                                                    <FormFeedback>{errors.house}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={'3'}>
                                                <FormGroup>
                                                    <Label>Квартира</Label>
                                                    <Input
                                                        type="text"
                                                        name="flat"
                                                        value={values.flat}
                                                        onChange={handleChange}
                                                        invalid={touched.flat && !!errors.flat}
                                                    />
                                                    <FormFeedback>{errors.flat}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <Label>Комментарий</Label>
                                            <Input
                                                tag={'textarea'}
                                                type="text"
                                                name="comments"
                                                value={values.comments}
                                                onChange={handleChange}
                                                invalid={touched.comments && !!errors.comments}
                                            />
                                            <FormFeedback>{errors.comments}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={'4'}>
                                        <div className="mb-2">
                                            <Row className="align-items-center">
                                                <Col>Стоимость доставки</Col>
                                                <Col md={'auto'}>
                                                    <NumberFormat
                                                        name={'deliveryPrice'}
                                                        allowNegative={false}
                                                        decimalSeparator={false}
                                                        value={values.deliveryPrice}
                                                        onValueChange={({floatValue}) => setFieldValue('deliveryPrice', floatValue)}
                                                        invalid={touched.deliveryPrice && !!errors.deliveryPrice}
                                                        customInput={Input}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="mb-2">
                                            <Row className="align-items-center">
                                                <Col>Корректировка</Col>
                                                <Col md={'auto'}>
                                                    <NumberFormat
                                                        name={'correctionalPrice'}
                                                        decimalSeparator={false}
                                                        value={values.correctionalPrice}
                                                        onValueChange={({floatValue}) => setFieldValue('correctionalPrice', floatValue)}
                                                        invalid={touched.correctionalPrice && !!errors.correctionalPrice}
                                                        customInput={Input}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className="align-items-center">
                                            <Col><b>Итого</b></Col>
                                            <Col md={'auto'}>
                                                <b>
                                                    <FormatPrice value={totalPrice}/>
                                                </b>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {changeError &&
                                (<Alert className={'mt-3'} color={'danger'}>
                                    {changeError}
                                </Alert>)}
                                <div>
                                    <Button type="submit" color={'primary'}
                                            disabled={changeLoading || isNegativeTotalPrice}>
                                        {changeLoading &&
                                        (<Spinner
                                            size="sm"
                                        />)}
                                        Сформировать заказ
                                    </Button>
                                </div>
                            </Card>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default OrderAdd;
