import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {IOrderChangeCoordinateRequest} from "../models/Order";
import OrderChangeCoordinate from "../components/OrderChangeCoordinate";
import {changeCoordinateOrderForm, closeOrderForm} from "../store/actions/orderForm";


type OrderChangeCoordinateType = {
    id: string
    closeOrderForm: () => void
    changeCoordinateOrderForm: (id: string, form: IOrderChangeCoordinateRequest) => void
    changeLoading: boolean
    changeError: string
    data: {
        lat?: number
        long?: number
    }
    address: string
}

class OrderChangeCoordinateContainer extends Component<OrderChangeCoordinateType> {

    render() {
        const {
            id, changeLoading, changeError, changeCoordinateOrderForm, closeOrderForm, data, address
        } = this.props;

        return (
            <OrderChangeCoordinate
                id={id}
                cancelHandler={closeOrderForm}
                changeCoordinateOrderForm={changeCoordinateOrderForm}
                changeLoading={changeLoading}
                changeError={changeError}
                address={address}
                data={data}
            />
        )
    }
}


const mapStateToProps = ({orderForm}: AppState) => {
    return {
        changeLoading: orderForm.changeLoading,
        changeError: orderForm.changeError
    }
};

const mapDispatchToProps = {
    changeCoordinateOrderForm,
    closeOrderForm
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderChangeCoordinateContainer);
