import React, {PureComponent} from 'react';
import Dropdown from "../UI/Dropdown";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from 'formik';
import {IDictionary} from "../../models/Dictionary";
import {getDictionary, getDictionaryOptions} from "../../utils/dictionaries";
import _ from 'lodash';
import {IOrderRequest} from "../../models/Order";


type OrderFilterType = {
    filter?:IOrderRequest
    setFilterHandler: (form:IOrderRequest) => void
    dictionaryCityEagers: IDictionary[]
    dictionaryOrderStatuses: IDictionary[]
}


class OrderFilter extends PureComponent<OrderFilterType> {
    render() {
        const {filter, dictionaryCityEagers, dictionaryOrderStatuses, setFilterHandler} = this.props;

        const resetValues = {
            id: '',
            externalId: '',
            cityId: null,
            companyId: null,
            pointId: null,
            status: null,
            paymentTypeId: null,
            companyName: ''
        };

        const initialValues = {...resetValues, ...filter};

        const cityOptions = getDictionaryOptions(dictionaryCityEagers);
        const statusOptions = getDictionaryOptions(dictionaryOrderStatuses.filter(({key})=> key < 100 && key > 0));

        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => {
                    const form = _.pickBy(values, function (value) {
                        return !(value === undefined || value === null || value === '');
                    });
                    setFilterHandler(form)
                }}
            >
                {({setFieldValue, values, handleChange, dirty, handleSubmit, resetForm}) => {
                    const cityId = values.cityId;

                    const city = cityId !== null ? getDictionary(dictionaryCityEagers, cityId as number) : undefined;

                    const points = _.get(city, 'points', []) as { [key: string]: string }[];
                    const pointOptions =  getDictionaryOptions(points);

                    const companies = _.get(city, 'companies', []) as { [key: string]: string }[];
                    const companyOptions = getDictionaryOptions(companies);

                    const isReset = _.isEmpty(_.pickBy(initialValues, function (value) {
                        return !(value === undefined || value === null || value === '');
                    }));

                    const resetFormHandler = () =>{
                        resetForm(resetValues);
                        setFilterHandler({})
                    };


                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Номер заказа</Label>
                                        <Input
                                            type="text"
                                            name="id"
                                            value={values.id}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Внешний номер заказа</Label>
                                        <Input
                                            type="text"
                                            name="externalId"
                                            value={values.externalId}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Город</Label>
                                        <Dropdown
                                            name={'cityId'}
                                            value={values.cityId}
                                            options={cityOptions}
                                            onChange={(value) => {
                                                setFieldValue('pointId', null);
                                                setFieldValue('companyId', null);
                                                setFieldValue('cityId', value);
                                            }}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Контрагент</Label>
                                        <Dropdown
                                            name={'companyId'}
                                            value={values.companyId}
                                            isDisabled={values.cityId === null}
                                            options={companyOptions}
                                            onChange={(value) => setFieldValue('companyId', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Район</Label>
                                        <Dropdown
                                            name={'pointId'}
                                            value={values.pointId}
                                            isDisabled={values.cityId === null}
                                            options={pointOptions}
                                            onChange={(value) => setFieldValue('pointId', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Статус заказа</Label>
                                        <Dropdown
                                            name={'status'}
                                            value={values.status}
                                            options={statusOptions}
                                            onChange={(value) => setFieldValue('status', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Тип оплаты</Label>
                                        <Dropdown
                                            name={'paymentTypeId'}
                                            value={values.paymentTypeId}
                                            options={[
                                                {
                                                    label: "Наличными",
                                                    value: 1
                                                },
                                                {
                                                    label: "POS терминал",
                                                    value: 2
                                                },
                                                {
                                                    label: "Оплаченный заказ",
                                                    value: 3
                                                }
                                            ]}
                                            onChange={(value) => setFieldValue('paymentTypeId', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Название заведения</Label>
                                        <Input
                                            type="text"
                                            name="companyName"
                                            value={values.companyName}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form className="justify-content-center">
                                <Col md={'auto'}>
                                    <Button
                                        type={'submit'}
                                        color={'primary'}
                                        disabled={!dirty}
                                    >
                                        Применить
                                    </Button>
                                </Col>
                                <Col md={'auto'}>
                                    <Button
                                        onClick={resetFormHandler}
                                        disabled={isReset}
                                        color={'light'}
                                    >
                                        Сбросить
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default OrderFilter;
