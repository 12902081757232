import React, {PureComponent} from 'react';
import {Button, Form, FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import _ from 'lodash';


const schema = yup.object({
    data: yup.string().required('Формат не верный')
});

type PointZonesSettingsFormType = {
    getData: (form: { name: string, polygon: number[][] }[]) => void
};


class PointZonesSettingsForm extends PureComponent<PointZonesSettingsFormType> {
    render() {
        const {getData} = this.props;
        return (
            <Formik
                validationSchema={schema}
                onSubmit={({data}) => {
                    const request = JSON.parse(data);
                    const response = request.features.map(({properties, geometry}: any) => {
                        return {name: properties.description, polygon: geometry.coordinates[0]}
                    });

                   !!response.length && getData(response);
                }}
                initialValues={{
                    data: ''
                }}
            >
                {({
                      handleSubmit,
                      setFieldValue,
                      values,
                      errors
                  }) => {
                    const validateData = (e: React.ChangeEvent<HTMLInputElement>) => {
                        try {
                            const request = JSON.parse(e.target.value);
                            if (_.has(request, 'features')) {
                                setFieldValue('data', e.target.value)
                            } else {
                                throw new Error();
                            }
                        } catch (e) {
                            setFieldValue('data', '');
                        }
                    };
                    return (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Данные из сервиса Яндекс Карты <span className={'required'}>*</span></Label>
                                <Input
                                    type={'textarea'}
                                    name="data"
                                    placeholder={'JSON массив из яндекс карт'}
                                    value={values.data}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateData(e)}
                                    invalid={!!errors.data}
                                    style={{height: 120}}
                                />
                                <FormFeedback>{errors.data}</FormFeedback>
                            </FormGroup>
                            <Button type="submit" color={'primary'}>
                                Загрузить
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}


export default PointZonesSettingsForm;
