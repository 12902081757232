import {call, put, takeLatest} from 'redux-saga/effects';
import {removeUrlParams} from "../../utils/history";
import {GET_COMPANIES} from "../constants/companies";
import {getCompaniesError, getCompaniesRequest, getCompaniesSuccess} from "../actions/companies";
import {CompanyService} from "../../services/CompanyService";
import {ICompanyFilterRequest, ICompanySortRequest, ICompany} from "../../models/Company";
import {Pagination} from "../../services/Api";


type getCompaniesType = {
    type:typeof GET_COMPANIES,
    payload:{
        page?:number,
        form?: ICompanyFilterRequest & ICompanySortRequest
    }
}

function* getCompaniesSaga({payload}:getCompaniesType) {
    try {
        yield put(getCompaniesRequest());

        const service = new CompanyService();


        const promise = yield call(service.getCompanies, payload.page,payload.form);

        const data:ICompany[] = promise.data || [];
        const pagination = new Pagination(promise.headers).getData();

        yield put(getCompaniesSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
        yield put(getCompaniesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCompanies() {
    yield takeLatest(GET_COMPANIES, getCompaniesSaga);
}
