import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {getPointZones} from "../store/actions/pointZones";
import PointZones from "../components/PointZones";
import {Button, Modal, ModalHeader, ModalBody} from "reactstrap";
import PointZoneEdit from "./PointZoneEdit";
import PointZoneDelete from "./PointZoneDelete";
import {IPointZone} from "../models/Point";
import Empty from "../components/UI/Empty";
import {Link} from "react-router-dom";
import {accessPointEdit} from "../utils/user-accesses";
import {closePointZoneForm, openPointZoneForm} from "../store/actions/pointZoneForm";
import DeliveryZones from "./DeliveryZones";


type PointZonesType = {
    getPointZones: (id: number) => void
    closePointZoneForm: () => void
    openPointZoneForm: (mode: 'edit' | 'delete', modeId: number) => void
    data: IPointZone[]
    loading: boolean
    error: string
    mode: 'add' | 'edit' | 'delete' | null
    modeId: number | null
    id: number
    accesses?: string[]
}

class PointZonesContainer extends Component<PointZonesType> {

    state = {
        isOpenDeliveryZonesMap: false
    }

    componentDidMount() {
        const {getPointZones, id} = this.props;
        getPointZones(id)
    }

    editHandler = (id:number) =>{
        const {openPointZoneForm} = this.props;
        openPointZoneForm('edit', id)
    };

    deleteHandler = (id:number) =>{
        const {openPointZoneForm} = this.props;
        openPointZoneForm('delete', id)
    };

    changeDeliveryZonesMapStateHandler = () => {

        const {isOpenDeliveryZonesMap} = this.state;

        this.setState(() => ({
            isOpenDeliveryZonesMap: !isOpenDeliveryZonesMap
        }));
    }

    render() {

        const {
            id, data, loading, error, getPointZones, accesses, mode, modeId, closePointZoneForm
        } = this.props;

        const {isOpenDeliveryZonesMap} = this.state;

        if (loading) {
            return <Loader/>
        }
        if (error) {
            return <Error error={error} refresh={() => getPointZones(id)}/>
        }
        if (!!data.length) {
            return (
                <>
                    {Array.isArray(accesses) && accesses.includes(accessPointEdit) &&
                    <Link to={`/points/${id}/settings`}><Button color={'primary'} className={'mb-2'}>Настроить
                        зоны</Button></Link>}

                    <Button
                        color={'primary'}
                        onClick={this.changeDeliveryZonesMapStateHandler}
                        className={'ml-2 mb-2'}>
                        Показать на карте
                    </Button>

                    <PointZones
                        data={data}
                        accesses={accesses}
                        editHandler={this.editHandler}
                        deleteHandler={this.deleteHandler}
                    />

                    {modeId !== null && <Modal isOpen={mode === 'edit'} toggle={closePointZoneForm}>
                        <ModalHeader toggle={closePointZoneForm}>
                            Редактировать зону доставки
                        </ModalHeader>
                        <ModalBody>
                            <PointZoneEdit id={id} formId={modeId} cancelHandler={closePointZoneForm}/>
                        </ModalBody>
                    </Modal>}

                    {modeId !== null && <Modal isOpen={mode === 'delete'} toggle={closePointZoneForm}>
                        <ModalHeader toggle={closePointZoneForm}>
                            Удалить зону доставки?
                        </ModalHeader>
                        <ModalBody>
                            <PointZoneDelete id={id} formId={modeId} cancelHandler={closePointZoneForm}/>
                        </ModalBody>
                    </Modal>}

                    <Modal isOpen={isOpenDeliveryZonesMap} size={'xl'} toggle={this.changeDeliveryZonesMapStateHandler}>
                        <ModalHeader toggle={this.changeDeliveryZonesMapStateHandler}>
                            Зоны доставки
                        </ModalHeader>
                        <ModalBody>
                            <DeliveryZones data={data}/>
                        </ModalBody>
                    </Modal>
                </>
            )
        }
        return (
            <>
                {Array.isArray(accesses) && accesses.includes(accessPointEdit) &&
                <Link to={`/points/${id}/settings`}><Button color={'primary'} className={'mb-2'}>Настроить зоны</Button></Link>}
                <Empty>
                    <h3>Список зоны доставки пуст</h3>
                    <p className={'mb-0'}>Чтобы добавить новую зону, нажмите кнопку «Настроить зоны»</p>
                </Empty>
            </>
        )
    }
}


const mapStateToProps = ({pointZones, pointZoneForm, auth}: AppState) => {
    const {data, loading, error} = pointZones;
    const {mode, modeId} = pointZoneForm;
    const {user} = auth;
    return {
        accesses: user !== null ? user.accesses : undefined,
        data,
        loading,
        error,
        mode,
        modeId
    }
};

const mapDispatchToProps = {
    getPointZones,
    closePointZoneForm,
    openPointZoneForm
};


export default connect(mapStateToProps, mapDispatchToProps)(PointZonesContainer);
