import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import {RouteComponentProps} from "react-router-dom";
import Layout from "../../containers/Layout";
import {Nav, NavItem, NavLink, TabContent, TabPane, Card} from "reactstrap";
import CompanyContactOutput from "../../containers/CompanyContactOutput";
import CompanyBranchOutput from "../../containers/CompanyBranchOutput";
import classNames from "classnames";


type RouteParamsType = { id: string }

class CompanyViewPage extends Component<RouteComponentProps<RouteParamsType>, { activeTab: string }> {

    state = {
        activeTab: 'branches'
    };

    changeTab = (activeTab: string) => {
        this.setState({activeTab})
    };

    render() {
        const {match} = this.props;
        const {activeTab} = this.state;
        const id = +match.params.id;

        return (
            <Layout>
                <Title>Контрагент</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Контрагенты', url: `/companies`},
                    {name: 'Контрагент', url: `/companies/${id}`}
                ]}/>
                <Nav tabs className={'borderNone'}>
                    <NavItem>
                        <NavLink
                            className={classNames('pointer', activeTab === 'branches' && 'active')}
                            onClick={() => this.changeTab('branches')}
                        >
                            Филиалы
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames('pointer', activeTab === 'contacts' && 'active')}
                            onClick={() => this.changeTab('contacts')}
                        >
                            Контакты
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="branches">
                        <Card body>
                            <CompanyBranchOutput id={id}/>
                        </Card>
                    </TabPane>
                    <TabPane tabId="contacts">
                        <Card body>
                            <CompanyContactOutput id={id}/>
                        </Card>
                    </TabPane>
                </TabContent>
            </Layout>
        );
    }
}


export default CompanyViewPage;

