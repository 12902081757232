import {
    GET_POINT_ZONES,
    GET_POINT_ZONES_REQUEST,
    GET_POINT_ZONES_SUCCESS,
    GET_POINT_ZONES_ERROR
} from '../constants/pointZones'
import {IAction} from "./index";
import { IPointZone } from '../../models/Point';


export interface IActionPointZones extends IAction<symbol,any> {

}

export const getPointZonesRequest = () => {
    return {
        type: GET_POINT_ZONES_REQUEST
    }
};

export const getPointZonesSuccess = (payload: IPointZone[]) => {
    return {
        type: GET_POINT_ZONES_SUCCESS,
        payload
    }
};

export const getPointZonesError = (payload: string) => {
    return {
        type: GET_POINT_ZONES_ERROR,
        payload
    }
};

export const getPointZones = (id:number) => {
    return {
        type: GET_POINT_ZONES,
        payload: id
    };
};
