import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import ReportCompanies from "../../containers/ReportCompanies";


class ReportCompaniesPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Сводка - Контрагенты</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Сводка - контрагенты', url: '/reports/companies'}
                ]}/>
                <ReportCompanies/>
            </Layout>
        );
    }
}

export default ReportCompaniesPage;
