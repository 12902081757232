export const GET_SCHEDULE = Symbol('GET_SCHEDULE');
export const GET_SCHEDULE_REQUEST = Symbol('GET_SCHEDULE_REQUEST');
export const GET_SCHEDULE_SUCCESS = Symbol('GET_SCHEDULE_SUCCESS');
export const GET_SCHEDULE_ERROR = Symbol('GET_SCHEDULE_ERROR');
export const PUBLISH_SCHEDULE = Symbol('PUBLISH_SCHEDULE');
export const PUBLISH_SCHEDULE_REQUEST = Symbol('PUBLISH_SCHEDULE_REQUEST');
export const PUBLISH_SCHEDULE_SUCCESS = Symbol('PUBLISH_SCHEDULE_SUCCESS');
export const PUBLISH_SCHEDULE_ERROR = Symbol('PUBLISH_SCHEDULE_ERROR');
export const CANCEL_SCHEDULE = Symbol('CANCEL_SCHEDULE');
export const CANCEL_SCHEDULE_REQUEST = Symbol('CANCEL_SCHEDULE_REQUEST');
export const CANCEL_SCHEDULE_SUCCESS = Symbol('CANCEL_SCHEDULE_SUCCESS');
export const CANCEL_SCHEDULE_ERROR = Symbol('CANCEL_SCHEDULE_ERROR');

