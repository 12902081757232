import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {Alert, Button, Spinner} from "reactstrap";
import {deleteRoleForm} from "../store/actions/roleForm";


type CityDeleteType = {
    deleteRoleForm: (id: string) => void
    cancelHandler?: () => void
    id: string
    changeLoading: boolean
    changeError: string
}

class RoleDeleteContainer extends Component<CityDeleteType> {

    render() {
        const {id, changeLoading, changeError, cancelHandler, deleteRoleForm} = this.props;

        return (
            <>
                {changeError &&
                (<Alert className={'mt-3'} color={'danger'}>
                    {changeError}
                </Alert>)}
                <div className={'text-right'}>
                    {cancelHandler &&
                    <Button onClick={cancelHandler} color={'primary'} className={'mr-3'}>Не удалять</Button>}
                    <Button onClick={() => deleteRoleForm(id)} color={'danger'} disabled={changeLoading}>
                        {changeLoading &&
                        (<Spinner

                            size="sm"
                        />)}
                        Да, удалить
                    </Button>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.roleForm;

    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    deleteRoleForm
};


export default connect(mapStateToProps, mapDispatchToProps)(RoleDeleteContainer);
