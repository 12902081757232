import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {editCompanyBranchForm, getCompanyBranchForm} from "../store/actions/companyBranchForm";
import {ICompanyBranch, ICompanyBranchAddFormRequest} from "../models/Company";
import {IAuth} from "../models/Auth";
import {IPoint} from "../models/Point";
import {getPoints} from "../store/actions/points";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import CompanyBranchEdit from "../components/CompanyBranchEdit";
import Empty from "../components/UI/Empty";
import _ from "lodash";


type CompanyBranchEditType = {
    getCompanyBranchForm: (id: number) => void
    editCompanyBranchForm: (id: number, form: ICompanyBranchAddFormRequest) => void
    getPoints: () => void
    id: number
    data: ICompanyBranch | null
    getLoading: boolean
    getError: string
    changeLoading: boolean
    changeError: string
    points: IPoint[]
    pointsLoading: boolean
    pointsError: string
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class CompanyBranchEditContainer extends Component<CompanyBranchEditType & CitiesType> {

    componentDidMount() {
        const {id, getCompanyBranchForm, getPoints} = this.props;
        getCompanyBranchForm(id);
        getPoints()
    }

    render() {
        const {id, data, getLoading, getError, getPoints, getCompanyBranchForm, changeLoading, changeError, editCompanyBranchForm, allowedCities, points, pointsLoading, pointsError} = this.props;

        if (getLoading || pointsLoading) {
            return <Loader/>
        }

        if (getError) {
            return <Error error={getError} refresh={() => getCompanyBranchForm(id)}/>
        }

        if (pointsError) {
            return <Error error={getError} refresh={() => getPoints()}/>
        }

        if (data !== null && !!points.length) {
            return (
                <CompanyBranchEdit
                    data={data}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    editCompanyBranchForm={editCompanyBranchForm}
                    allowedCities={allowedCities}
                    points={points}
                />
            )
        }

        return (
            <Empty>
                <h3>Список отправных точек пуст</h3>
                <p className={'mb-0'}>Добавьте отправные точки</p>
            </Empty>
        )
    }
}

const mapStateToProps = ({points,auth,companyBranchForm}: AppState) => {
    return {
        points: points.data,
        pointsLoading: points.loading,
        pointsError: points.error,
        data: companyBranchForm.data,
        getLoading: companyBranchForm.getLoading,
        getError: companyBranchForm.getError,
        changeLoading: companyBranchForm.changeLoading,
        changeError: companyBranchForm.changeError,
        allowedCities: _.get(auth.user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    editCompanyBranchForm,
    getCompanyBranchForm,
    getPoints
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyBranchEditContainer);
