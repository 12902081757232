import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import PickCity from "../../containers/PickCity";
import Layout from "../../containers/Layout";
import PointOutput from "../../containers/PointOutput";


class PointsPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Отправные точки</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Отправные точки', url: '/points'}
                ]}/>
                <div className={'mb-3'}>
                    <PickCity/>
                </div>
                <PointOutput/>
            </Layout>
        );
    }
}

export default PointsPage;
