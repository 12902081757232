import {Api} from "./Api";
import {
    ICourierBonusRateResponse,
    ICourierPenaltiesFilterRequest,
    ICourierPenaltyAddFromRequest, ICourierRate, ICourierRatioRateConditionForm,
    ICourierStatisticsFilterRequest
} from "../models/Courier";
import {ISlotChange, ISlotFilter} from "../models/Slot";


export class CourierService {
    getCourier(id: number, expand?: string) {
        const params = expand ? `?expand=${expand}` : ``;
        return new Api().call(`/v1/manage/courier/${id}${params}`, {method: 'GET'});
    }

    getCouriers(cityId: number) {
        return new Api().call(`/v1/manage/courier/online?cityId=${cityId}`, {method: 'GET'});
    }

    getCouriersLocations(cityId: number) {
        return new Api().call(`/v1/manage/courier/location/${cityId}`, {method: 'GET'});
    }

    changePointCourier(id: number, form: any) {
        return new Api().call(`/v1/manage/courier/${id}/location`, {method: 'POST', data: form});
    }

    getCourierStatistics(id: number, form: ICourierStatisticsFilterRequest) {
        return new Api().call(`/v1/manage/courier/${id}/statistics`, {method: 'POST', data: form});
    }

    getCourierPenalties(id: number, form: ICourierPenaltiesFilterRequest) {
        return new Api().call(`/v1/manage/courier/${id}/penalty`, {method: 'POST', data: form});
    }

    addCourierPenalty(id: number, form: ICourierPenaltyAddFromRequest) {
        return new Api().call(`/v1/manage/courier/${id}/penalty/create`, {method: 'POST', data: form});
    }

    deleteCourierPenalty(id: number) {
        return new Api().call(`/v1/manage/courier/penalty/${id}`, {method: 'DELETE'});
    }

    getCourierSchedule(id: number) {
        return new Api().call(`/v1/manage/courier/${id}/schedule`, {method: 'GET'});
    }

    getCourierScheduleById(sectionId: number, id: number) {
        return new Api().call(`/v1/manage/courier/${sectionId}/schedule/${id}`, {method: 'GET'});
    }

    enableStatusCourier(id: number) {
        return new Api().call(`/v1/manage/courier/${id}/slot-start`, {method: 'GET'});
    }

    disableStatusCourier(id: number) {
        return new Api().call(`/v1/manage/courier/${id}/slot-stop`, {method: 'GET'});
    }

    getFilteredCouriersList(expand?: string, body = {}) {
        const params = expand ? `?expand=${expand}` : ``;
        return new Api().call(`/v1/manage/courier/search${params}`, {method: 'POST', data: body});
    }

    changeCourier(id: number, courierId: string, reason: string) {
        return new Api().call(`/v1/manage/order/${id}/change-courier`, {method: 'POST', data: {courierId, reason}});
    }

    getCouriersSlot(page: number = 1, params: {expand?: string, form?: ISlotFilter}) {
        const expand = params.expand ? `&expand=${params.expand}` : ``;
        return new Api().call(`/v1/manage/slot/search?page=${page}${expand}`, {method: 'POST', data: params.form});
    }

    changeCourierSlot(id: number, form: ISlotChange) {
        return new Api().call(`/v1/manage/slot/${id}`, {method: 'POST', data: form});
    }

    getCourierRate(cityId: number) {
        return new Api().call(`/v1/manage/courier-rate/${cityId}`, {method: 'GET'});
    }

    changeCourierRate(cityId: number, form: ICourierRate[] ) {
        return new Api().call(`/v1/manage/courier-rate/${cityId}`, {method: 'POST', data: form});
    }

    getCourierBonusRate(cityId: number) {
        return new Api().call(`/v1/manage/courier-bonus-rate/${cityId}`, {method: 'GET'});
    }

    changeCourierBonusRate(cityId: number, typeId: number, form: ICourierBonusRateResponse[]) {
        return new Api().call(`/v1/manage/courier-bonus-rate/${cityId}?typeId=${typeId}`, {method: 'POST', data: form});
    }

    getCourierRatioRate(cityId: number) {
        return new Api().call(`/v1/manage/courier-ratio-rate/${cityId}`, {method: 'GET'});
    }

    changeCourierRatioRate(form: ICourierRatioRateConditionForm, id?: number) {
        const rateId = id ? "/" + id : "";
        return new Api().call(`/v1/manage/courier-ratio-rate${rateId}`, {method: 'POST', data: form});
    }

    deleteCourierRatioRate(id: number) {
        return new Api().call(`/v1/manage/courier-ratio-rate/${id}`, {method: 'DELETE'});
    }
}
