import { combineReducers } from 'redux';

import auth from './auth';
import points from "./points";
import pointZones from "./pointZones";
import pointForm from "./pointForm";
import pointZoneForm from "./pointZoneForm";
import cities from "./cities";
import cityForm from "./cityForm";
import companies from "./companies";
import companyForm from "./companyForm";
import companyContacts from "./companyContacts";
import companyContactForm from "./companyContactForm";
import companyBranches from "./companyBranches";
import companyBranchForm from "./companyBranchForm";
import company from "./company";
import employees from "./employees";
import dictionaryRoles from "./dictionaryRoles";
import employeeForm from "./employeeForm";
import dictionaryContactTypes from "./dictionaryContactTypes";
import roles from "./roles";
import roleForm from "./roleForm";
import dictionaryPermissions from "./dictionaryPermissions";
import schedules from "./schedules";
import schedule from "./schedule";
import scheduleForm from "./scheduleForm";
import scheduleDate from "./scheduleDate";
import scheduleDateForm from "./scheduleDateForm";
import dictionaryPoints from "./dictionaryPoints";
import dictionaryCouriers from "./dictionaryCouriers";
import dictionaryPublishedSchedules from "./dictionaryPublishedSchedules";
import orders from "./orders";
import dictionaryOrderStatuses from "./dictionaryOrderStatuses";
import order from "./order";
import dictionaryCityEagers from "./dictionaryCityEagers";
import orderForm from "./orderForm";
import dictionaryCompanies from "./dictionaryCompanies";
import dictionaryCompanyBranches from "./dictionaryCompanyBranches";
import notify from "./notify";
import courier from "./courier";
import archiveOrders from "./archiveOrders";
import couriers from "./couriers";
import couriersLocations from "./couriersLocations";
import courierDocumentsAdd from "./courierDocumentsAdd";
import courierDocumentsDelete from "./courierDocumentsDelete";
import courierDocumentsGet from "./courierDocumentsGet";
import courierForm from "./courierForm";
import courierStatistics from "./courierStatistics";
import courierPenalties from "./courierPenalties";
import courierPenaltyForm from "./courierPenaltyForm";
import penalties from "./penalties";
import courierSchedule from "./courierShedule";
import courierScheduleById from "./courierScheduleById";
import notifications from "./notifications";
import penaltyForm from "./penaltyForm";
import reportCompanies from "./reportCompanies";
import dictionaryReportCompanyPeriod from "./dictionaryReportCompanyPeriod";
import reportCouriers from "./reportCouriers";
import dictionaryReportCourierPeriod from "./dictionaryReportCourierPeriod";
import reportEmenu from "./reportEmenu"
import payments from "./payments";
import paymentForm from "./paymentForm";
import archiveOrdersSummary from "./archiveOrdersSummary";
import courierChange from "./courierChange";
import couriersFilteredList from "./couriersFilteredList"
import couriersShifts from "./couriersShifts";
import courierRate from "./courierRate";
import courierRateChange from "./courierRateChange";
import courierSlot from "./courierSlot";
import bonusRate from "./bonusRate";
import bonusRateChange from "./bonusRateChange";
import ratioRate from "./ratioRate";
import ratioRateChange from "./ratioRateChange";
import ratioRateDelete from "./ratioRateDelete";
import salary from "./salary";
import salaryTotal from "./salaryTotal";
import salaryPay from "./salaryPay";
import employeePhotosAdd from "./employeePhotosAdd";
import employeePhotosDelete from "./employeePhotosDelete";
import location from "./location";
import courierPhone from "./courierPhone";
import salaryPayGroup from "./salaryPayGroup";
import orderReturnCheckAdd from "./orderReturnCheckAdd";
import orderReturnCheckDelete from "./orderReturnCheckDelete";

/**
 * Комбайн редюсеров
 */
export const rootReducer = combineReducers({
  notify,
  notifications,
  auth,
  bonusRate,
  bonusRateChange,
  dictionaryRoles,
  dictionaryContactTypes,
  dictionaryPermissions,
  dictionaryPoints,
  dictionaryCouriers,
  dictionaryPublishedSchedules,
  dictionaryOrderStatuses,
  dictionaryCityEagers,
  dictionaryCompanies,
  dictionaryCompanyBranches,
  dictionaryReportCompanyPeriod,
  dictionaryReportCourierPeriod,
  points,
  pointZones,
  pointForm,
  pointZoneForm,
  cities,
  cityForm,
  companies,
  company,
  companyForm,
  companyContacts,
  companyContactForm,
  companyBranches,
  companyBranchForm,
  employees,
  employeeForm,
  employeePhotosAdd,
  employeePhotosDelete,
  roles,
  roleForm,
  schedules,
  schedule,
  scheduleForm,
  scheduleDate,
  scheduleDateForm,
  orders,
  order,
  orderForm,
  archiveOrders,
  archiveOrdersSummary,
  orderReturnCheckAdd,
  orderReturnCheckDelete,
  courier,
  courierChange,
  courierSlot,
  couriersFilteredList,
  couriers,
  couriersShifts,
  couriersLocations,
  courierDocumentsAdd,
  courierDocumentsDelete,
  courierDocumentsGet,
  courierForm,
  courierStatistics,
  courierPenalties,
  courierPenaltyForm,
  courierRate,
  courierRateChange,
  penalties,
  penaltyForm,
  courierSchedule,
  courierScheduleById,
  reportCompanies,
  reportCouriers,
  reportEmenu,
  salary,
  salaryTotal,
  salaryPay,
  salaryPayGroup,
  ratioRate,
  ratioRateChange,
  ratioRateDelete,
  payments,
  paymentForm,
  location,
  courierPhone,
});
export type AppState = ReturnType<typeof rootReducer>;
