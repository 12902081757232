import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {IAuth} from "../models/Auth";
import queryString from "query-string";
import {RouteComponentProps, withRouter} from "react-router";
import {compose} from "redux";
import {pointsCityIdReSelector} from "../store/reselectors/points";
import PickCity from "../components/PickCity";
import _ from "lodash";


type PickCityType = {
    cityId?: number
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class PickCityContainer extends Component<CitiesType & PickCityType & RouteComponentProps> {

    setCityIdHandler = (cityId:number) =>{
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, cityId, page:null},{skipNull:true});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    render() {
        const {allowedCities, cityId} = this.props;

        return <PickCity
            setCityHandler={this.setCityIdHandler}
            cityId={cityId}
            allowedCities={allowedCities}/>
    }
}

const mapStateToProps = (state: AppState,props:RouteComponentProps) => {
    const {user} = state.auth;
    return {
        allowedCities: _.get(user, 'allowedCities', []),
        cityId:pointsCityIdReSelector(props)
    }
};


export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, null))(PickCityContainer);
