import {IActionCourier} from "../actions/courier";
import {
    GET_COURIER_ERROR,
    GET_COURIER_REQUEST,
    GET_COURIER_SUCCESS
} from "../constants/courier";
import {ICourier} from "../../models/Courier";



export interface ICourierState {
    getLoading: boolean
    getError: string
    data: ICourier | null
}

const initialState: ICourierState = {
    data: null,
    getLoading: false,
    getError: ''
};

export default function courier(state = initialState, action: IActionCourier): ICourierState {
    switch (action.type) {
        case GET_COURIER_REQUEST:
            return {
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_COURIER_SUCCESS:
            return {
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_COURIER_ERROR:
            return {
                data: null,
                getLoading: false,
                getError: action.payload
            };
        default:
            return state;
    }
}
