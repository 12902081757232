import {
    GET_DICTIONARY_REPORT_COMPANY_PERIOD,
    GET_DICTIONARY_REPORT_COMPANY_PERIOD_REQUEST,
    GET_DICTIONARY_REPORT_COMPANY_PERIOD_SUCCESS,
    GET_DICTIONARY_REPORT_COMPANY_PERIOD_ERROR
} from '../constants/dictionaryReportCompanyPeriod'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryReportCompanyPeriod extends IAction<symbol, any> {

}

export const getDictionaryReportCompanyPeriodRequest = () => {
    return {
        type: GET_DICTIONARY_REPORT_COMPANY_PERIOD_REQUEST
    }
};

export const getDictionaryReportCompanyPeriodSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_REPORT_COMPANY_PERIOD_SUCCESS,
        payload
    }
};

export const getDictionaryReportCompanyPeriodError = (payload: string) => {
    return {
        type: GET_DICTIONARY_REPORT_COMPANY_PERIOD_ERROR,
        payload
    }
};

export const getDictionaryReportCompanyPeriod = () => {
    return {
        type: GET_DICTIONARY_REPORT_COMPANY_PERIOD
    };
};

