import React, {PureComponent} from 'react';
import {
    Alert,
    Button,
    Form,
    Spinner,
    FormGroup,
    Label,
    Input,
    FormFeedback
} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage, typeMessage} from "../../utils/form-validation";
import Dropdown from "../UI/Dropdown";
import {sanitizeForm} from "../../utils/sanitize-form";
import {ICompanyContactAddFormRequest} from "../../models/Company";
import Table from '../UI/Table';
import {COMPANY_CONTACT_EMAIL, COMPANY_CONTACT_MOBILE, companyContactOptions} from '../../utils/companies';
import Icon from "../UI/Icon";
import _ from 'lodash';
import NumberFormat from "react-number-format";
import {IDictionary} from "../../models/Dictionary";
import {getDictionaryOptions} from "../../utils/dictionaries";


const schema = yup.object({
    name: yup.string().required(requiredMessage()),
    typeId: yup.number().nullable().required(requiredMessage()),
    data: yup.array().required(requiredMessage()).of(
        yup.object().required(requiredMessage()).shape({
            typeId: yup.number().required(requiredMessage()),
            value: yup.string().required(requiredMessage()).when('typeId', {
                is: (typeId) => typeId === COMPANY_CONTACT_EMAIL,
                then: yup.string().email(typeMessage()).required(requiredMessage())
            })
        })
    ),
});

type ItemType = { typeId: number | null, value: string }

type CompanyContactAddType = {
    addCompanyContactForm: (form: ICompanyContactAddFormRequest) => void
    cancelHandler?: () => void
    changeLoading: boolean
    changeError: string
    contactTypes:IDictionary[]
}

class CompanyContactAdd extends PureComponent<CompanyContactAddType> {

    render() {
        const {addCompanyContactForm, changeLoading, changeError, cancelHandler, contactTypes} = this.props;

        const contactTypeOptions = getDictionaryOptions(contactTypes);

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const name = sanitizeForm(values.name);
                    const typeId = sanitizeForm(values.typeId);
                    const data = sanitizeForm(values.data.map(({typeId, value}) => {
                        return {typeId, value}
                    }));

                    const form = {
                        name,
                        typeId,
                        data
                    };

                    addCompanyContactForm(form)
                }}
                initialValues={{
                    name: '',
                    typeId: null,
                    data: [{typeId: null, value: ''}]
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      setFieldValue,
                      touched
                  }) => {
                    const addItems = () => {
                        setFieldValue('data', [...values.data, {
                            typeId: null,
                            value: ''
                        }])
                    };
                    const changeItems = (index: number, item: ItemType) => {
                        setFieldValue('data', [
                            ...values.data.slice(0, index),
                            item,
                            ...values.data.slice(index + 1)
                        ])
                    };
                    const removeItems = (index: number) => {
                        setFieldValue('data', [
                            ...values.data.slice(0, index),
                            ...values.data.slice(index + 1)
                        ]);
                    };
                    return (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Должность <span className={'required'}>*</span></Label>
                                <Dropdown
                                    name={'typeId'}
                                    value={values.typeId}
                                    options={contactTypeOptions}
                                    onChange={(value) => setFieldValue('typeId', value)}
                                    invalid={touched.typeId && !!errors.typeId}
                                />
                                <FormFeedback>{errors.typeId}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>ФИО <span className={'required'}>*</span></Label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    invalid={touched.name && !!errors.name}
                                />
                                <FormFeedback>{errors.name}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Способы <span className={'required'}>*</span></Label>
                                    <Table className={'mb-2'}>
                                        <tbody>
                                        {values.data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{width: '50%'}}>
                                                        <Dropdown
                                                            name={`data[${index}].typeId`}
                                                            value={_.get(values, `data[${index}].typeId`)}
                                                            options={companyContactOptions}
                                                            onChange={(typeId) => {
                                                                changeItems(index, {
                                                                    typeId,
                                                                    value: ''
                                                                });
                                                            }}
                                                            invalid={_.has(touched, `data[${index}].typeId`) && _.has(errors, `data[${index}].typeId`)}
                                                        />
                                                    </td>
                                                    <td style={{width: '50%'}}>
                                                        <Input
                                                            tag={item.typeId === COMPANY_CONTACT_MOBILE ? NumberFormat : ''}
                                                            format={item.typeId === COMPANY_CONTACT_MOBILE ? '+7 (###) ###-##-##' : ''}
                                                            mask={item.typeId === COMPANY_CONTACT_MOBILE ? '_' : ''}
                                                            type="text"
                                                            name={`data[${index}].value`}
                                                            value={_.get(values, `data[${index}].value`)}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                changeItems(index, {
                                                                    ...item,
                                                                    value: e.target.value
                                                                });
                                                            }}
                                                            invalid={_.has(touched, `data[${index}].value`) && _.has(errors, `data[${index}].value`)}
                                                        />
                                                    </td>
                                                    <td style={{width: 58}}>
                                                        <Button
                                                            color={'outline-light'}
                                                            size={'sm'}
                                                            disabled={values.data.length === 1}
                                                            onClick={() => removeItems(index)}>
                                                            <Icon name={'delete'} color={'gray-500'}/>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                <Button
                                    className={'mt-2'}
                                    color={'light'}
                                    onClick={addItems}>
                                    Добавить
                                </Button>
                            </FormGroup>
                            {changeError &&
                            (<Alert color={'danger'}>
                                {changeError}
                            </Alert>)}

                            <div className={'text-right'}>
                                {cancelHandler &&
                                <Button color="light" onClick={cancelHandler}
                                        className={'mr-3'}>Отменить</Button>}
                                <Button type="submit" color={'primary'} disabled={changeLoading}>
                                    {changeLoading &&
                                    (<Spinner
                                        size="sm"
                                    />)}
                                    Создать
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default CompanyContactAdd;
