import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {
    closePenaltyForm,
    openPenaltyForm,
} from "../store/actions/penaltyForm";
import PenaltyAdd from "./PenaltyAdd";


type PenaltyNavbarType = {
    closePenaltyForm: () => void
    openPenaltyForm: (mode:'add') => void
    mode: 'add' | 'edit' | 'delete' | null
}

class PenaltyNavbarContainer extends Component<PenaltyNavbarType> {

    render() {
        const {
            closePenaltyForm,
            mode,
            openPenaltyForm
        } = this.props;

        return (
            <div className={'mb-2'}>
                <Button
                    color={'primary'}
                    onClick={() => openPenaltyForm('add')}>
                    Добавить штраф
                </Button>

                <Modal isOpen={mode === 'add'} toggle={closePenaltyForm}>
                    <ModalHeader toggle={closePenaltyForm}>
                        Новый штраф
                    </ModalHeader>
                    <ModalBody>
                        <PenaltyAdd cancelHandler={closePenaltyForm}/>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = (state: AppState) => {
    const {mode} = state.penaltyForm;

    return {
        mode
    }
};

const mapDispatchToProps = {
    closePenaltyForm,
    openPenaltyForm
};

export default connect(mapStateToProps, mapDispatchToProps)(PenaltyNavbarContainer);
