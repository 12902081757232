import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addPenaltyFormRequest,
    addPenaltyFormSuccess,
    addPenaltyFormError,
    editPenaltyFormRequest,
    editPenaltyFormSuccess,
    editPenaltyFormError,
    getPenaltyFormRequest,
    getPenaltyFormSuccess,
    getPenaltyFormError,
    deletePenaltyFormSuccess,
    deletePenaltyFormRequest,
    deletePenaltyFormError,
    closePenaltyForm
} from "../actions/penaltyForm";
import {ADD_PENALTY_FORM, DELETE_PENALTY_FORM, EDIT_PENALTY_FORM, GET_PENALTY_FORM} from "../constants/penaltyForm";
import {getPenalties} from "../actions/penalties";
import {IPenalty, IPenaltyAddFormRequest, IPenaltyEditFormRequest} from "../../models/Penalty";
import {PenaltyService} from "../../services/PenaltyService";
import _ from "lodash";
import {getUrlParams, removeUrlParams} from "../../utils/history";




function* addPenaltyFormSaga({payload}: { type: typeof ADD_PENALTY_FORM, payload: IPenaltyAddFormRequest }) {
    try {
        yield put(addPenaltyFormRequest());

        const service = new PenaltyService();
        const promise = yield call(service.addPenalty, payload);

        const response:IPenalty = promise.data;

        yield put(addPenaltyFormSuccess(response));

        yield put(closePenaltyForm());

        _.isEmpty(getUrlParams()) ? yield put(getPenalties()): removeUrlParams();

    } catch (error) {
        yield put(addPenaltyFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddPenaltyForm() {
    yield takeLatest(ADD_PENALTY_FORM, addPenaltyFormSaga);
}


type editPenaltyFormType = {
    type: typeof EDIT_PENALTY_FORM,
    payload: {
        id: number,
        form: IPenaltyEditFormRequest
    }
}

function* editPenaltyFormSaga({payload}: editPenaltyFormType) {
    try {
        yield put(editPenaltyFormRequest());

        const service = new PenaltyService();
        const promise = yield call(service.editPenalty, payload.id, payload.form);

        const response:IPenalty = promise.data;

        yield put(editPenaltyFormSuccess(response));

        yield put(closePenaltyForm());

        _.isEmpty(getUrlParams()) ? yield put(getPenalties()): removeUrlParams();

    } catch (error) {
        yield put(editPenaltyFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditPenaltyForm() {
    yield takeLatest(EDIT_PENALTY_FORM, editPenaltyFormSaga);
}

function* getPenaltyFormSaga({payload}: { type: typeof GET_PENALTY_FORM, payload: number }) {
    try {
        yield put(getPenaltyFormRequest());

        const service = new PenaltyService();
        const promise = yield call(service.getPenalty, payload);

        const response:IPenalty = promise.data;

        yield put(getPenaltyFormSuccess(response));

    } catch (error) {
        yield put(getPenaltyFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetPenaltyForm() {
    yield takeLatest(GET_PENALTY_FORM, getPenaltyFormSaga);
}

function* deletePenaltyFormSaga({payload}: { type: typeof DELETE_PENALTY_FORM, payload: number }) {
    try {
        yield put(deletePenaltyFormRequest());

        const service = new PenaltyService();
        yield call(service.deletePenalty, payload);
        yield put(deletePenaltyFormSuccess());

        yield put(closePenaltyForm());

        _.isEmpty(getUrlParams()) ? yield put(getPenalties()): removeUrlParams();

    } catch (error) {
        yield put(deletePenaltyFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeletePenaltyForm() {
    yield takeLatest(DELETE_PENALTY_FORM, deletePenaltyFormSaga);
}
