import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import configureStore from './store';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import App from './containers/App';
import axios from "axios";
import {GET_USER_SILENT, LOGOUT} from "./store/constants/auth";


const store = configureStore();


axios.interceptors.response.use(function (response) {
    if (!localStorage.getItem('hash')) {
        localStorage.setItem('hash', response.headers['user-modified-hash']);
    }
    if (response.headers['user-modified-hash'] !== localStorage.getItem('hash')) {
        store.dispatch({type: GET_USER_SILENT});
        localStorage.setItem('hash', response.headers['user-modified-hash']);
    }
    return response;
}, function (error) {
    if (401 === error.response.status) {
        store.dispatch({type: LOGOUT});
        return Promise.reject(error)
    } else {
        return Promise.reject(error);
    }
});


const render = (Component: React.ElementType) => {
    ReactDOM.render(
        <Provider store={store}>
            <Component/>
        </Provider>,
        document.getElementById('root')
    );
};

render(App);


serviceWorker.unregister();
