import {IActionCourierSlot} from "../actions/courierShift";
import {
    CHANGE_COURIER_SLOT_ERROR,
    CHANGE_COURIER_SLOT_REQUEST,
    CHANGE_COURIER_SLOT_SUCCESS
} from "../constants/courierSlot";

export interface ICourierSlotState {
    loading: boolean
    error: string
    success: boolean
}

const initialState = {
    loading: false,
    error: '',
    success: false
}

export default function courierSlot(state = initialState, action: IActionCourierSlot): ICourierSlotState {
    switch (action.type) {
        case CHANGE_COURIER_SLOT_REQUEST:
            return {
                loading: true,
                error: '',
                success: false
            };
        case CHANGE_COURIER_SLOT_SUCCESS:
            return {
                loading: false,
                error: '',
                success: true
            };
        case CHANGE_COURIER_SLOT_ERROR:
            return {
                loading: false,
                error: action.payload,
                success: false
            };
        default:
            return state;
    }
}