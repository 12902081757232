import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {accessCompanyBranchShow, accessCompanyBranchCreate} from "../utils/user-accesses";
import CompanyBranches from "./CompanyBranches";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";


type CompanyBranchOutputType = {
    id: number
    accesses?: string[]
};


class CompanyBranchOutput extends Component<CompanyBranchOutputType> {

    render() {
        const {id, accesses} = this.props;

        return (
            <>
                {Array.isArray(accesses) && accesses.includes(accessCompanyBranchCreate) &&
                <Link to={`/companies/${id}/branches/add`}>
                    <Button color={'primary'} className={'mb-2'}>Добавить филиал</Button>
                </Link>}
                {Array.isArray(accesses) && accesses.includes(accessCompanyBranchShow) &&
                <CompanyBranches id={id}/>}
            </>
        );
    }
}


const mapStateToProps = (state: AppState) => {
    const {user} = state.auth;
    return {
        accesses: user !== null ? user.accesses : undefined
    }
};

export default connect(mapStateToProps, null)(CompanyBranchOutput);
