import {IAction} from "./index";
import {ICourierPenalty, ICourierPenaltyAddFromRequest} from '../../models/Courier';
import {
    ADD_COURIER_PENALTY_FORM,
    ADD_COURIER_PENALTY_FORM_ERROR,
    ADD_COURIER_PENALTY_FORM_REQUEST,
    ADD_COURIER_PENALTY_FORM_SUCCESS,
    CLOSE_COURIER_PENALTY_FORM, DELETE_COURIER_PENALTY_FORM, DELETE_COURIER_PENALTY_FORM_ERROR,
    DELETE_COURIER_PENALTY_FORM_REQUEST, DELETE_COURIER_PENALTY_FORM_SUCCESS,
    OPEN_COURIER_PENALTY_FORM
} from "../constants/courierPenaltyForm";


export interface IActionCourierPenaltyForm extends IAction<symbol, any> {

}


export const addCourierPenaltyFormRequest = () => {
    return {
        type: ADD_COURIER_PENALTY_FORM_REQUEST
    }
};

export const addCourierPenaltyFormSuccess = (payload:ICourierPenalty) => {
    return {
        type: ADD_COURIER_PENALTY_FORM_SUCCESS,
        payload
    }
};

export const addCourierPenaltyFormError = (payload: string) => {
    return {
        type: ADD_COURIER_PENALTY_FORM_ERROR,
        payload
    }
};

export const addCourierPenaltyForm = (id: number, form: ICourierPenaltyAddFromRequest) => {
    return {
        type: ADD_COURIER_PENALTY_FORM,
        payload: {id, form}
    };
};


export const deleteCourierPenaltyFormRequest = () => {
    return {
        type: DELETE_COURIER_PENALTY_FORM_REQUEST
    }
};

export const deleteCourierPenaltyFormSuccess = () => {
    return {
        type: DELETE_COURIER_PENALTY_FORM_SUCCESS
    }
};

export const deleteCourierPenaltyFormError = (payload: string) => {
    return {
        type: DELETE_COURIER_PENALTY_FORM_ERROR,
        payload
    }
};

export const deleteCourierPenaltyForm = (sectionId: number, id:number) => {
    return {
        type: DELETE_COURIER_PENALTY_FORM,
        payload:{
            sectionId,
            id
        }
    };
};


export const openCourierPenaltyForm = (mode: 'add' | 'delete', modeId?: number) => {
    return {
        type: OPEN_COURIER_PENALTY_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closeCourierPenaltyForm = () => {
    return {
        type: CLOSE_COURIER_PENALTY_FORM
    };
};
