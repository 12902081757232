import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {getCompanyForm, editCompanyForm} from "../store/actions/companyForm";
import {ICompany, ICompanyEditFormRequest} from "../models/Company";
import {IAuth} from "../models/Auth";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import CompanyEdit from "../components/CompanyEdit";
import _ from "lodash";


type CompanyEditType = {
    getCompanyForm: (id: number) => void
    editCompanyForm: (id: number, form: ICompanyEditFormRequest) => void
    id: number
    data: ICompany | null
    changeLoading: boolean
    changeError: string
    getLoading: boolean
    getError: string
}
type CitiesType = Pick<IAuth, 'allowedCities'>;

class CompanyEditContainer extends Component<CompanyEditType & CitiesType> {

    componentDidMount() {
        const {id, getCompanyForm} = this.props;
        getCompanyForm(id)
    }


    render() {
        const {id, data, getLoading, getError, changeLoading, changeError, getCompanyForm, editCompanyForm, allowedCities} = this.props;

        if (getLoading) {
            return <Loader/>
        }

        if (getError) {
            return <Error error={getError} refresh={() => getCompanyForm(id)}/>
        }
        if (data !== null) {
            return (
                <CompanyEdit
                    editCompanyForm={editCompanyForm}
                    allowedCities={allowedCities}
                    data={data}
                    changeLoading={changeLoading}
                    changeError={changeError}/>
            )
        }
        return null;
    }
}

const mapStateToProps = (state: AppState) => {
    const {data, getLoading, getError, changeLoading, changeError} = state.companyForm;
    const {user} = state.auth;

    return {
        data,
        getLoading,
        getError,
        changeLoading,
        changeError,
        allowedCities: _.get(user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    editCompanyForm,
    getCompanyForm
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditContainer);
