import {
    GET_DICTIONARY_CONTACT_TYPES,
    GET_DICTIONARY_CONTACT_TYPES_REQUEST,
    GET_DICTIONARY_CONTACT_TYPES_SUCCESS,
    GET_DICTIONARY_CONTACT_TYPES_ERROR
} from '../constants/dictionaryContactTypes'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryContactTypes extends IAction<symbol, any> {

}

export const getDictionaryContactTypesRequest = () => {
    return {
        type: GET_DICTIONARY_CONTACT_TYPES_REQUEST
    }
};

export const getDictionaryContactTypesSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_CONTACT_TYPES_SUCCESS,
        payload
    }
};

export const getDictionaryContactTypesError = (payload: string) => {
    return {
        type: GET_DICTIONARY_CONTACT_TYPES_ERROR,
        payload
    }
};

export const getDictionaryContactTypes = () => {
    return {
        type: GET_DICTIONARY_CONTACT_TYPES
    };
};

