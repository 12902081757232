import {
    GET_DICTIONARY_PERMISSIONS,
    GET_DICTIONARY_PERMISSIONS_REQUEST,
    GET_DICTIONARY_PERMISSIONS_SUCCESS,
    GET_DICTIONARY_PERMISSIONS_ERROR
} from '../constants/dictionaryPermissions'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryPermissions extends IAction<symbol, any> {

}

export const getDictionaryPermissionsRequest = () => {
    return {
        type: GET_DICTIONARY_PERMISSIONS_REQUEST
    }
};

export const getDictionaryPermissionsSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_PERMISSIONS_SUCCESS,
        payload
    }
};

export const getDictionaryPermissionsError = (payload: string) => {
    return {
        type: GET_DICTIONARY_PERMISSIONS_ERROR,
        payload
    }
};

export const getDictionaryPermissions = () => {
    return {
        type: GET_DICTIONARY_PERMISSIONS
    };
};

