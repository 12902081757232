import React, {Component} from "react";
import {Card} from "reactstrap";
import ArchiveOrderSummary from "../components/ArchiveOrdersSummary";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {getArchiveOrdersSummary} from "../store/actions/archiveOrders";
import {AppState} from "../store/reducers";
import {IOrderArchiveRequest, IOrderArchiveSummary} from "../models/Order";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {archiveOrdersFilterReSelector} from "../store/reselectors/orders";
import _ from "lodash";

type ArchiveOrdersSummaryType = {
    getArchiveOrdersSummary: (form?: IOrderArchiveRequest) => void
    data: IOrderArchiveSummary
    loading: boolean
    error: string
    filter?: IOrderArchiveRequest
}

class ArchiveOrdersSummaryContainer extends Component<ArchiveOrdersSummaryType & RouteComponentProps> {

    componentDidMount() {
        const {getArchiveOrdersSummary, filter} = this.props;
        getArchiveOrdersSummary(!_.isEmpty({...filter}) ? {...filter,} : undefined)
    }

    componentDidUpdate(prevProps: Readonly<ArchiveOrdersSummaryType & RouteComponentProps>) {
        const {getArchiveOrdersSummary, filter} = this.props;
        if (!_.isEqual(prevProps.filter, filter)) {
            getArchiveOrdersSummary(!_.isEmpty({...filter}) ? {...filter,} : undefined)
        }
    }

    fetchHandler() {
        const {getArchiveOrdersSummary} = this.props;
        getArchiveOrdersSummary()
    }

    render() {
        const {data, loading, error} = this.props

        if (loading) {
            return <Loader/>
        }

        if (error) {
            return <Error error={error} refresh={this.fetchHandler}/>
        }

        return (
            <Card body className={"mb-3"}>
                <ArchiveOrderSummary data={data}/>
            </Card>
        )
    }
}

const mapStateToProps = ({archiveOrdersSummary}: AppState, props: RouteComponentProps) => {
    const {data, loading, error} = archiveOrdersSummary;
    return {
        data,
        loading,
        error,
        filter: archiveOrdersFilterReSelector(props)
    }
}

const mapDispatchToProps = {
    getArchiveOrdersSummary
}

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(ArchiveOrdersSummaryContainer);
