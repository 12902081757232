import React, {Component} from "react";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {getSalary, getSalaryTotal, payGroupSalary, paySalary} from "../store/actions/salary";
import Error from "../components/UI/Error";
import Empty from "../components/UI/Empty";
import Loader from "../components/UI/Loader";
import Pagination from "../components/UI/Pagination";
import {
    salaryCityIdReSelector,
    salaryFilterReSelector,
    salaryIsGroupReSelector,
    salaryPageReSelector
} from "../store/reselectors/salary";
import {IAuth} from "../models/Auth";
import {Redirect} from "react-router-dom";
import _ from "lodash";
import queryString from "query-string";
import {
    ISalaryFilterFormExtended,
    ISalaryGroupResponse,
    ISalaryResponse,
} from "../models/Salary";
import SalaryList from "../components/SalaryList";
import SalaryGroupList from "../components/SalaryGroupList";

type SalaryType = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

type CitiesType = Pick<IAuth, 'allowedCities'>;

class SalaryContainer extends Component<SalaryType & CitiesType & RouteComponentProps> {

    componentDidMount() {
        this.getSalaryHandler();
        window.addEventListener('scroll', this.windowOnScrollHandler);
        window.addEventListener('resize', this.changeTableHeaderCellWidthHandler);

        const navToggleButton = document.getElementById("sidebar-toggle");
        if (navToggleButton) navToggleButton.addEventListener('click', this.onClickNavToggleButtonHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.windowOnScrollHandler);
        window.removeEventListener('resize', this.changeTableHeaderCellWidthHandler);

        const navToggleButton = document.getElementById("sidebar-toggle");
        if (navToggleButton) navToggleButton.removeEventListener('click', this.onClickNavToggleButtonHandler);
    }

    componentDidUpdate(prevProps: Readonly<SalaryType & CitiesType>, prevState: Readonly<{}>) {
        const {page, cityId, filter, salaryPayData, group, salaryPayGroupSuccess} = this.props;

        if (
          prevProps.page !== page
          || prevProps.cityId !== cityId
          || !_.isEqual(prevProps.filter, filter)
          || prevProps.salaryPayData !== salaryPayData
          || prevProps.group !== group
        ) {
            this.getSalaryHandler();
            return;
        }
    
        /**
         * После успешной групповой выплаты обновить список
         */
        if (salaryPayGroupSuccess && prevProps.salaryPayGroupSuccess !== salaryPayGroupSuccess) {
            this.getSalaryHandler();
        }
    }

    onClickNavToggleButtonHandler = () => {
        const tbody = document.getElementsByTagName("tbody")[0];
        const tableYOffset = tbody.getBoundingClientRect().top

        if (tableYOffset <= 0) {
            setTimeout(() => {
                this.fixTableHeaderHandler();
            }, 200)
        }
    }

    windowOnScrollHandler = () => {
        const thead = document.getElementsByTagName("thead")[0];
        const table = document.getElementsByTagName("table")[0];

        if (thead) {
            const tableYOffset = table.getBoundingClientRect().top

            /**
             * Фиксация thead если высота он table до верхней части окна <= 0px
             */
            if (tableYOffset <= 0) {
                table.style.cssText = `
                    position: relative;
                    top: -${thead.getBoundingClientRect().height}px;
                `;
                this.fixTableHeaderHandler();
            } else {
                table.style.cssText = `
                    position: inherits;
                    top: 0;
                `;
                thead.style.cssText = `position: inherit;`;
            }
        }
    }

    /**
     * Фиксация thead
     */
    fixTableHeaderHandler = () => {
        const thead = document.getElementsByTagName("thead")[0];
        thead.style.cssText = `
            left: ${document.getElementsByTagName("table")[0].getBoundingClientRect().left}px;
            position: fixed;
            top: 0;
            width: ${document.getElementsByTagName("table")[0].getBoundingClientRect().width}px;
            z-index: 2;
            `;
        this.changeTableHeaderCellWidthHandler();
    }

    /**
     * Установка ширины ячеек thead
     */
    changeTableHeaderCellWidthHandler = () => {
        const thead = document.getElementsByTagName("thead")[0];
        const tbody = document.getElementsByTagName("tbody")[0];
        
        if (!thead || !tbody) {
            return;
        }
        
        const tableYOffset = tbody.getBoundingClientRect().top
        const tableDataCellWidthArray: number[] = [];

        /**
         * Запись в массив ширины ячеек таблицы
         */
        if (tbody.childNodes[0]) {
            tbody.childNodes[0].childNodes.forEach((td: ChildNode) => {
                const tableDataCell = td as HTMLTableDataCellElement;
                tableDataCellWidthArray.push(tableDataCell.getBoundingClientRect().width);
            })
        }

        thead.childNodes.forEach((tr: ChildNode) => {
            if (tr.childNodes.length > 0) {
                tr.childNodes.forEach((th: ChildNode, index: number) => {
                    const tableHeaderCell = th as HTMLTableHeaderCellElement;

                    /**
                     * Установка ширины в ячейки thead из массива
                     */
                    if (tableYOffset <= 0) {
                        tableHeaderCell.style.cssText = `
                            min-width: ${tableDataCellWidthArray[index]}px;
                            width: ${tableDataCellWidthArray[index]}px;
                            max-width: ${tableDataCellWidthArray[index]}px;
                            `;
                    } else {
                        tableHeaderCell.style.cssText = `
                            min-width: auto;
                            width: ${tableDataCellWidthArray[index]}px;
                            max-width: auto;
                            `;
                    }
                })
            }
        })
    }

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    getSalaryHandler = () => {
        const {getSalary, getSalaryTotal, page, cityId, filter, group} = this.props;

        const form: ISalaryFilterFormExtended = {...filter};

        if (form && form.cityId) {
            delete form.cityId;
        }

        cityId && getSalary(page, cityId, form, group);
        cityId && getSalaryTotal(cityId, form);
    }

    render() {
        const {
            data,
            loading,
            error,
            totalData,
            totalLoading,
            totalError,
            page,
            by,
            total,
            cityId,
            allowedCities,
            paySalary,
            payGroupSalary,
            group,
        } = this.props;

        if (!cityId) {
            return <Redirect to={`/salary?cityId=${allowedCities[0].id}`}/>
        }

        if (loading || totalLoading) {
            return <Loader/>
        }

        if (!data.length && !error) {
            return <Empty>
                <h3>Список выплат пуст</h3>
            </Empty>
        }
        
        if (!totalData) {
            return null;
        }

        if (error || totalError) {
            return (
              <Error error={error} refresh={() => {
                  this.getSalaryHandler();
              }}/>
            )
        }

        return (
            <>
                <div className={"mb-3"}>
                    {group
                        ? <SalaryGroupList
                              data={data as ISalaryGroupResponse[]}
                              totalData={totalData}
                              payGroupSalary={payGroupSalary}
                          />
                        : <SalaryList
                              data={data as ISalaryResponse[]}
                              totalData={totalData}
                              paySalary={paySalary}
                          />}
                    <Pagination
                      active={page}
                      by={by}
                      total={total}
                      paginateHandler={this.paginateHandler}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = ({salary, salaryTotal, salaryPay, salaryPayGroup, auth}: AppState, props: RouteComponentProps) => {
    const {data, loading, error, total, by} = salary;

    const salaryPayData = salaryPay.data;
    const salaryPayGroupSuccess = salaryPayGroup.success;
    
    const totalData = salaryTotal.data;
    const totalLoading = salaryTotal.loading;
    const totalError = salaryTotal.error;

    const {user} = auth;

    return {
        data,
        loading,
        error,
        cityId: salaryCityIdReSelector(props),
        page: salaryPageReSelector(props),
        total,
        by,
        totalData,
        totalLoading,
        totalError,
        salaryPayData,
        filter: salaryFilterReSelector(props),
        allowedCities: _.get(user, 'allowedCities', []),
        group: salaryIsGroupReSelector(props),
        salaryPayGroupSuccess,
    }
}

const mapDispatchToProps = {
    getSalary,
    getSalaryTotal,
    paySalary,
    payGroupSalary,
}

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(SalaryContainer);
