import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {addCompanyContactForm} from "../store/actions/companyContactForm";
import {ICompanyContactAddFormRequest} from "../models/Company";
import CompanyContactAdd from "../components/CompanyContactAdd";
import {IDictionary} from "../models/Dictionary";


type CompanyContactAddType = {
    addCompanyContactForm: (id: number, form: ICompanyContactAddFormRequest) => void
    cancelHandler: () => void
    id: number
    changeLoading: boolean
    changeError: string
    contactTypes: IDictionary[]
}

class CompanyContactAddContainer extends Component<CompanyContactAddType> {

    render() {
        const {id, changeLoading, changeError, addCompanyContactForm, cancelHandler, contactTypes} = this.props;
        return (
            <CompanyContactAdd
                addCompanyContactForm={(form) => addCompanyContactForm(id, form)}
                changeLoading={changeLoading}
                contactTypes={contactTypes}
                cancelHandler={cancelHandler}
                changeError={changeError}
            />
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.companyContactForm;

    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    addCompanyContactForm
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyContactAddContainer);
