import {ADD_NOTIFY, REMOVE_NOTIFY} from '../constants/notify';
import {IActionNotify} from "../actions/notify";


export interface INotifyState {
    message: string
}

const initialState: INotifyState = {
    message: ''
};

export default function notify(state = initialState, action: IActionNotify): INotifyState {
    switch (action.type) {
        case ADD_NOTIFY:
            return {
                message: action.payload
            };
        case REMOVE_NOTIFY:
            return {
                message: ''
            };
        default:
            return state;
    }
}
