import {createSelector} from "reselect";
import queryString from 'query-string';
import _ from 'lodash';


const citiesUrlSelector = (props: any) => {
    return props.location.search
};

export const citiesSortReSelector = createSelector(
    [citiesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['orderBy']);

        let orderBy = {} as {[key:string]: string};
        if(params.orderBy &&  typeof params.orderBy === "string") {
            const orderByArray = params.orderBy.split('_');
            orderBy[orderByArray[0]] = orderByArray[1] + '_' + orderByArray[2];
        }

        const sort = _.pickBy(orderBy, function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(sort) ? {orderBy:sort} : undefined
    }
);

export const citiesFilterReSelector = createSelector(
    [citiesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['id', 'name', 'timezone', 'status', 'deliveryService']);

        const id = params.id ? +params.id : undefined;
        const name = params.name;
        const timezone = params.timezone ? +params.timezone : undefined;
        const status = params.status && typeof params.status === "string" ? JSON.parse(params.status): undefined;
        const deliveryService = params.deliveryService;

        const filter = _.pickBy({id, name, timezone, status, deliveryService}, function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(filter) ? filter : undefined
    }
);

export const citiesPageReSelector = createSelector(
    [citiesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.page ? +getParams.page : 1
    }
);

