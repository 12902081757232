import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {ICompanyContact} from "../models/Company";
import {getCompanyContacts} from "../store/actions/companyContacts";
import CompanyContacts from "../components/CompanyContacts";
import Empty from "../components/UI/Empty";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import CompanyContactEdit from "./CompanyContactEdit";
import CompanyContactDelete from "./CompanyContactDelete";
import {IDictionary} from "../models/Dictionary";
import {closeCompanyContactForm, openCompanyContactForm} from "../store/actions/companyContactForm";


type CompanyContactsType = {
    getCompanyContacts: (id: number) => void
    closeCompanyContactForm: () => void
    openCompanyContactForm: (mode: 'edit' | 'delete', modeId: number) => void
    id: number
    data: ICompanyContact[]
    loading: boolean
    error: string
    accesses?: string[]
    contactTypes: IDictionary[]
    mode: 'add' | 'edit' | 'delete' | null
    modeId: number | null
}


class CompanyContactsContainer extends Component<CompanyContactsType> {

    componentDidMount() {
        const {id, getCompanyContacts} = this.props;
        getCompanyContacts(id)
    }

    editHandler = (id:number) =>{
        const {openCompanyContactForm} = this.props;
        openCompanyContactForm('edit', id)
    };

    deleteHandler = (id:number) =>{
        const {openCompanyContactForm} = this.props;
        openCompanyContactForm('delete', id)
    };

    render() {
        const {
            id, data, loading, error, getCompanyContacts, accesses, contactTypes,
            modeId, mode, closeCompanyContactForm
        } = this.props;


        if (loading) {
            return <Loader/>
        }

        if (error) {
            return <Error error={error} refresh={() => getCompanyContacts(id)}/>
        }

        if (!!data.length) {
            return (
                <>
                    <CompanyContacts
                        editHandler={this.editHandler}
                        deleteHandler={this.deleteHandler}
                        accesses={accesses}
                        contactTypes={contactTypes}
                        data={data}/>

                    {modeId !== null && <Modal isOpen={mode === 'edit'} toggle={closeCompanyContactForm}>
                        <ModalHeader toggle={closeCompanyContactForm}>
                            Редактирование контакта
                        </ModalHeader>
                        <ModalBody>
                            <CompanyContactEdit
                                id={modeId}
                                sectionId={id}
                                cancelHandler={closeCompanyContactForm}
                                contactTypes={contactTypes}
                            />
                        </ModalBody>
                    </Modal>}

                    {modeId !== null && <Modal isOpen={mode === 'delete'} toggle={closeCompanyContactForm}>
                        <ModalHeader toggle={closeCompanyContactForm}>
                            Удалить контакт?
                        </ModalHeader>
                        <ModalBody>
                            <CompanyContactDelete id={modeId} sectionId={id}
                                                  cancelHandler={closeCompanyContactForm}/>
                        </ModalBody>
                    </Modal>}
                </>
            )
        }

        return (
            <Empty>
                <h3>Список контактов пуст</h3>
                <p className={'mb-0'}>Чтобы добавить контакт, нажмите кнопку «Добавить контакт»</p>
            </Empty>
        )
    }
}


const mapStateToProps = (state: AppState) => {
    const {data, loading, error} = state.companyContacts;
    const {mode, modeId} = state.companyContactForm;
    const {user} = state.auth;

    return {
        data,
        loading,
        error,
        mode,
        modeId,
        accesses: user !== null ? user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getCompanyContacts,
    closeCompanyContactForm,
    openCompanyContactForm
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContactsContainer);
