import {GET_CITIES, GET_CITIES_REQUEST, GET_CITIES_SUCCESS, GET_CITIES_ERROR} from '../constants/cities'
import {IAction} from "./index";
import {ICity, ICityRequest} from '../../models/City';


export interface IActionCities extends IAction<symbol, any> {

}

export const getCitiesRequest = () => {
    return {
        type: GET_CITIES_REQUEST
    }
};

export const getCitiesSuccess = (data: ICity[], total:number, by:number) => {
    return {
        type: GET_CITIES_SUCCESS,
        payload:{
            data,
            total,
            by
        }
    }
};

export const getCitiesError = (payload: string) => {
    return {
        type: GET_CITIES_ERROR,
        payload
    }
};

export const getCities = (page?:number, form?: ICityRequest) => {
    return {
        type: GET_CITIES,
        payload:{
            page,
            form
        }
    };
};
