import {GET_COMPANY_BRANCHES_ERROR, GET_COMPANY_BRANCHES_REQUEST, GET_COMPANY_BRANCHES_SUCCESS} from "../constants/companyBranches";
import {ICompanyBranch} from "../../models/Company";
import {IActionCompanyBranches} from "../actions/companyBranches";


export interface ICompanyBranchesState {
    loading: boolean
    error: string
    data: ICompanyBranch[]
    total: number
    by: number
}

const initialState: ICompanyBranchesState = {
    data: [],
    loading: false,
    error: '',
    total: 0,
    by: 0
};

export default function companyBranches(state = initialState, action: IActionCompanyBranches): ICompanyBranchesState {
    switch (action.type) {
        case GET_COMPANY_BRANCHES_REQUEST:
            return {
                data: [],
                loading: true,
                error: '',
                total: 0,
                by: 0
            };
        case GET_COMPANY_BRANCHES_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                data,
                loading: false,
                error: '',
                total,
                by
            };
        case GET_COMPANY_BRANCHES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload,
                total: 0,
                by: 0
            };
        default:
            return state;
    }
}
