import {IActionCourierRate} from "../actions/courierRate";
import {
    CHANGE_COURIER_RATE_ERROR,
    CHANGE_COURIER_RATE_REQUEST,
    CHANGE_COURIER_RATE_SUCCESS
} from "../constants/courierRate";

export interface ICourierRateState {
    loading: boolean,
    error: string
}

const initialState: ICourierRateState = {
    loading: false,
    error: ''
}

export default function courierRateChange(state = initialState, action: IActionCourierRate): ICourierRateState {
    switch (action.type) {
        case CHANGE_COURIER_RATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case CHANGE_COURIER_RATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: ''
            }
        case CHANGE_COURIER_RATE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}