import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {
    closeCityForm,
    openCityForm,
} from "../store/actions/cityForm";
import CityAdd from "./CityAdd";


type CityNavbarType = {
    closeCityForm: () => void
    openCityForm: (mode:'add') => void
    mode: 'add' | 'edit' | 'delete' | null
}

class CityNavbarContainer extends Component<CityNavbarType> {

    render() {
        const {
            closeCityForm,
            mode,
            openCityForm
        } = this.props;

        return (
            <div className={'mb-2'}>
                <Button
                    color={'primary'}
                    onClick={() => openCityForm('add')}>
                    Добавить город
                </Button>

                <Modal isOpen={mode === 'add'} toggle={closeCityForm}>
                    <ModalHeader toggle={closeCityForm}>
                        Новый город
                    </ModalHeader>
                    <ModalBody>
                        <CityAdd cancelHandler={closeCityForm}/>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = (state: AppState) => {
    const {mode} = state.cityForm;

    return {
        mode
    }
};

const mapDispatchToProps = {
    closeCityForm,
    openCityForm
};

export default connect(mapStateToProps, mapDispatchToProps)(CityNavbarContainer);
