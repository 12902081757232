import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import Notify from "../components/Notify";
import {AppState} from "../store/reducers";
import {removeNotify} from "../store/actions/notify";


type NotifyType = {
    removeNotify: () => void
    message: string
}

class NotifyContainer extends PureComponent<NotifyType> {
    render() {
        const {message, removeNotify} = this.props;
        if (message) {
            return (
                <Notify message={message} removeNotify={removeNotify}/>
            )
        }
        return null
    }
}


const mapStateToProps = ({notify}: AppState) => {
    const {message} = notify;
    return {
        message
    }
};

const mapDispatchToProps = {
    removeNotify
};

export default connect(mapStateToProps, mapDispatchToProps)(NotifyContainer);
