import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {addEmployeeForm} from "../store/actions/employeeForm";
import {IEmployeeAddFormRequest} from "../models/Employee";
import EmployeeAdd from "../components/EmployeeAdd";
import {IAuth} from "../models/Auth";
import {IDictionary} from "../models/Dictionary";
import _ from "lodash";
import {getDictionaryCompanies} from "../store/actions/dictionaryCompanies";


type EmployeeAddType = {
    addEmployeeForm: (form: IEmployeeAddFormRequest) => void
    getDictionaryCompanies: (cityId: number) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
    roles: IDictionary[]
    dictionaryCompanies: IDictionary[]
    dictionaryCompaniesLoading: boolean
    dictionaryCompaniesError: string
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class EmployeeAddContainer extends Component<EmployeeAddType & CitiesType> {

    render() {
        const {changeLoading, changeError, addEmployeeForm, cancelHandler, allowedCities, roles, getDictionaryCompanies,
            dictionaryCompanies, dictionaryCompaniesLoading, dictionaryCompaniesError} = this.props;

        return (
            <EmployeeAdd
                addEmployeeForm={addEmployeeForm}
                changeLoading={changeLoading}
                changeError={changeError}
                cancelHandler={cancelHandler}
                allowedCities={allowedCities}
                roles={roles}
                getDictionaryCompanies={getDictionaryCompanies}
                dictionaryCompanies={dictionaryCompanies}
                dictionaryCompaniesLoading={dictionaryCompaniesLoading}
                dictionaryCompaniesError={dictionaryCompaniesError}
            />
        )

    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.employeeForm;
    const {user} = state.auth;
    const {data, loading, error} = state.dictionaryCompanies;
    return {
        dictionaryCompanies: data,
        dictionaryCompaniesLoading: loading,
        dictionaryCompaniesError: error,
        changeLoading,
        changeError,
        allowedCities: _.get(user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    addEmployeeForm,
    getDictionaryCompanies
};


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAddContainer);
