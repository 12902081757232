import {
    GET_REPORT_COURIERS,
    GET_REPORT_COURIERS_REQUEST,
    GET_REPORT_COURIERS_SUCCESS,
    GET_REPORT_COURIERS_ERROR
} from '../constants/reportCouriers'
import {IAction} from "./index";
import {IReportCourier, IReportCourierFilterRequest, IReportCourierSortRequest} from "../../models/Report";

export interface IActionReportCouriers extends IAction<symbol, any> {

}

export const getReportCouriersRequest = () => {
    return {
        type: GET_REPORT_COURIERS_REQUEST
    }
};

export const getReportCouriersSuccess = (data: IReportCourier[], total: number, by: number) => {
    return {
        type: GET_REPORT_COURIERS_SUCCESS,
        payload: {
            data,
            total,
            by
        }
    }
};

export const getReportCouriersError = (payload: string) => {
    return {
        type: GET_REPORT_COURIERS_ERROR,
        payload
    }
};

export const getReportCouriers = (page?: number, form?: IReportCourierFilterRequest & IReportCourierSortRequest) => {
    return {
        type: GET_REPORT_COURIERS,
        payload: {
            page,
            form
        }
    };
};
