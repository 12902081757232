import {createSelector} from "reselect";
import queryString from 'query-string';
import _ from 'lodash';


const notificationsUrlSelector = (props: any) => {
    return props.location.search
};

export const notificationsFilterReSelector = createSelector(
    [notificationsUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['id', 'orderId', 'cityId', 'status']);

        const id = params.id ? +params.id : undefined;
        const orderId = params.orderId;
        const cityId = params.cityId ? +params.cityId : undefined;
        const status = params.status && typeof params.status === "string" ? JSON.parse(params.status): undefined;

        const filter = _.pickBy({id, orderId, cityId, status}, function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(filter) ? filter : undefined
    }
);


export const notificationsSortReSelector = createSelector(
    [notificationsUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['orderBy']);

        let orderBy = {} as {[key:string]: string};
        if(params.orderBy &&  typeof params.orderBy === "string") {
            const orderByArray = params.orderBy.split('_');
            orderBy[orderByArray[0]] = orderByArray[1] + '_' + orderByArray[2];
        }

        const sort = _.pickBy(orderBy, function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(sort) ? {orderBy:sort} : undefined
    }
);

export const notificationPageReSelector = createSelector(
    [notificationsUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.page ? +getParams.page : 1
    }
)