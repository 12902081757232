import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getCourier,
    getCourierError,
    getCourierRequest,
    getCourierSuccess
} from "../actions/courier";
import {ICourier, ICourierResponse} from "../../models/Courier";
import {CourierService} from "../../services/CourierService";
import {DISABLE_STATUS_COURIER, ENABLE_STATUS_COURIER, GET_COURIER} from "../constants/courier";
import {addNotify} from "../actions/notify";


type getCourierType = {
    type: typeof GET_COURIER,
    payload: {
        id: number,
        expand?: string
    }
};

function* getCourierSaga({payload}: getCourierType) {
    try {
        yield put(getCourierRequest());

        const service = new CourierService();

        const promise = yield call(service.getCourier, payload.id, payload.expand);
        const response: ICourierResponse = promise.data;

        const transform = response.location ? {
            ...response,
            location: {...response.location, lat: +response.location.lat, long: +response.location.long}
        } : response;

        yield put(getCourierSuccess(transform as ICourier));

    } catch (error) {
        yield put(getCourierError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCourier() {
    yield takeLatest(GET_COURIER, getCourierSaga);
}


type enableStatusCourierType = {
    type: typeof ENABLE_STATUS_COURIER,
    payload: number
};

function* enableStatusCourierSaga({payload}: enableStatusCourierType) {
    try {
        const service = new CourierService();
        yield call(service.enableStatusCourier, payload);

        yield put(getCourier(payload));

    } catch (error) {
        yield put(addNotify(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEnableStatusCourier() {
    yield takeLatest(ENABLE_STATUS_COURIER, enableStatusCourierSaga);
}


type disableStatusCourierType = {
    type: typeof DISABLE_STATUS_COURIER,
    payload: number
};

function* disableStatusCourierSaga({payload}: disableStatusCourierType) {
    try {
        const service = new CourierService();
        yield call(service.disableStatusCourier, payload);
        yield put(getCourier(payload));

    } catch (error) {
        yield put(addNotify(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDisableStatusCourier() {
    yield takeLatest(DISABLE_STATUS_COURIER, disableStatusCourierSaga);
}
