import {call, put, takeLatest} from 'redux-saga/effects';
import {CompanyService} from "../../services/CompanyService";
import {ICompanyContact} from "../../models/Company";
import {getCompanyContactsRequest, getCompanyContactsSuccess, getCompanyContactsError} from "../actions/companyContacts";
import {GET_COMPANY_CONTACTS} from "../constants/companyContacts";



function* getCompanyContactsSaga({payload}: {type: typeof GET_COMPANY_CONTACTS, payload: number}) {
    try {
        yield put(getCompanyContactsRequest());

        const service = new CompanyService();

        const promise = yield call(service.getCompanyContacts, payload);

        const responseData:ICompanyContact[] = promise.data;

        yield put(getCompanyContactsSuccess(responseData));

    } catch (error) {
        yield put(getCompanyContactsError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCompanyContacts() {
    yield takeLatest(GET_COMPANY_CONTACTS, getCompanyContactsSaga);
}
