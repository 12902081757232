import React, {HTMLAttributes, PureComponent} from 'react';
import styles from './Avatar.module.scss'
import Icon from "../Icon";
import classNames from "classnames";


type AvatarType = {
    icon?: string;
    src?: string;
    alt?: string;
    onClick?: HTMLAttributes<HTMLDivElement>['onClick'];
    className?: string;
}

class Avatar extends PureComponent<AvatarType> {
    render() {
        const {icon, src, alt = '', onClick, className} = this.props;
        
        return (
            <div className={classNames(styles.avatar, className)} onClick={onClick}>
                {icon && <Icon name={icon}/>}
                {src && <img src={src} alt={alt}/>}
                {!icon && !src && <div className={styles['avatar-alt']}>{alt}</div>}
            </div>
        )
    }
}


export default Avatar;
