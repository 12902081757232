import {
    ADD_POINT_FORM,
    ADD_POINT_FORM_REQUEST,
    ADD_POINT_FORM_SUCCESS,
    ADD_POINT_FORM_ERROR,
    EDIT_POINT_FORM_REQUEST,
    EDIT_POINT_FORM_SUCCESS,
    EDIT_POINT_FORM_ERROR,
    EDIT_POINT_FORM,
    GET_POINT_FORM,
    GET_POINT_FORM_REQUEST,
    GET_POINT_FORM_SUCCESS,
    GET_POINT_FORM_ERROR,
    DELETE_POINT_FORM_REQUEST,
    DELETE_POINT_FORM_SUCCESS,
    DELETE_POINT_FORM_ERROR,
    DELETE_POINT_FORM, OPEN_POINT_FORM, CLOSE_POINT_FORM
} from '../constants/pointForm'
import {IAction} from "./index";
import {IPointEditFormRequest, IPointAddFormRequest} from '../../models/Point';
import {IPoint} from '../../models/Point';




export interface IActionPointForm extends IAction<symbol, any> {

}


export const addPointFormRequest = () => {
    return {
        type: ADD_POINT_FORM_REQUEST
    }
};

export const addPointFormSuccess = (payload: IPoint) => {
    return {
        type: ADD_POINT_FORM_SUCCESS,
        payload
    }
};

export const addPointFormError = (payload: string) => {
    return {
        type: ADD_POINT_FORM_ERROR,
        payload
    }
};

export const addPointForm = (payload: IPointAddFormRequest) => {
    return {
        type: ADD_POINT_FORM,
        payload
    };
};

export const editPointFormRequest = () => {
    return {
        type: EDIT_POINT_FORM_REQUEST
    }
};

export const editPointFormSuccess = (payload: IPoint) => {
    return {
        type: EDIT_POINT_FORM_SUCCESS,
        payload
    }
};

export const editPointFormError = (payload: string) => {
    return {
        type: EDIT_POINT_FORM_ERROR,
        payload
    }
};

export const editPointForm = (id: number, form: IPointEditFormRequest) => {
    return {
        type: EDIT_POINT_FORM,
        payload: {id, form}
    };
};

export const getPointFormRequest = () => {
    return {
        type: GET_POINT_FORM_REQUEST
    }
};

export const getPointFormSuccess = (payload: IPoint) => {
    return {
        type: GET_POINT_FORM_SUCCESS,
        payload
    }
};

export const getPointFormError = (payload: string) => {
    return {
        type: GET_POINT_FORM_ERROR,
        payload
    }
};

export const getPointForm = (payload: number) => {
    return {
        type: GET_POINT_FORM,
        payload
    };
};

export const deletePointFormRequest = () => {
    return {
        type: DELETE_POINT_FORM_REQUEST
    }
};

export const deletePointFormSuccess = () => {
    return {
        type: DELETE_POINT_FORM_SUCCESS
    }
};

export const deletePointFormError = (payload: string) => {
    return {
        type: DELETE_POINT_FORM_ERROR,
        payload
    }
};

export const deletePointForm = (payload: number) => {
    return {
        type: DELETE_POINT_FORM,
        payload
    };
};

export const openPointForm = (mode: 'add' | 'edit' | 'delete', modeId?: number) => {
    return {
        type: OPEN_POINT_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closePointForm = () => {
    return {
        type: CLOSE_POINT_FORM
    };
};
