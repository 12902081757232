import React, {PureComponent} from 'react';
import {
    Alert,
    Button,
    Form,
    Spinner,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import Dropdown from "../UI/Dropdown";
import {sanitizeForm} from "../../utils/sanitize-form";
import {ICompanyAddFormRequest} from "../../models/Company";
import {Link} from "react-router-dom";
import DateTimePicker from "../UI/DateTimePicker";
import Table from '../UI/Table';
import {COMPANY_CALCULATION_PERCENT, companyCalculationOptions, companyStatusOptions} from '../../utils/companies';
import NumberFormat from "react-number-format";
import {IAuth} from "../../models/Auth";
import _ from "lodash";
import {getCityOptions} from "../../utils/cities";
import Icon from "../UI/Icon";
import {dateConvert} from "../../utils/date-convert";


const schema = yup.object({
    name: yup.string().required(requiredMessage()),
    status: yup.number().nullable().required(requiredMessage()),
    legalName: yup.string(),
    bankDetails: yup.string(),
    legalAddress: yup.string(),
    postalAddress: yup.string(),
    actualAddress: yup.string(),
    contract: yup.string(),
    settings: yup.array().required(requiredMessage()).of(
        yup.object().shape({
            cityId: yup.number().required(requiredMessage()),
            typeId: yup.number().required(requiredMessage()),
            percent: yup.number().nullable().when('typeId', {
                is: (typeId) => typeId === COMPANY_CALCULATION_PERCENT,
                then: yup.number().max(50, requiredMessage()).required(requiredMessage()),
            })
        })
    ),
});

type SettingType = { cityId: number | null, typeId: number | null, percent?: number }

type CompanyAddType = {
    addCompanyForm: (form: ICompanyAddFormRequest) => void
    changeLoading: boolean
    changeError: string
}

type CitiesType = Pick<IAuth, 'allowedCities'>;


class CompanyAdd extends PureComponent<CompanyAddType & CitiesType> {


    render() {
        const {addCompanyForm, changeLoading, changeError, allowedCities} = this.props;
        const cityOptions = getCityOptions(allowedCities);

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const name = sanitizeForm(values.name);
                    const status = sanitizeForm(values.status);
                    const legalName = sanitizeForm(values.legalName);
                    const bankDetails = sanitizeForm(values.bankDetails);
                    const legalAddress = sanitizeForm(values.legalAddress);
                    const postalAddress = sanitizeForm(values.postalAddress);
                    const actualAddress = sanitizeForm(values.actualAddress);
                    const contract = sanitizeForm(values.contract);
                    const contractDate = sanitizeForm(values.contractDate);
                    const settings = sanitizeForm(values.settings.map((o: SettingType) => {
                        const cityId = sanitizeForm(o.cityId);
                        const typeId = sanitizeForm(o.typeId);
                        const percent = sanitizeForm(o.percent);
                        return {cityId, typeId, percent}
                    }));

                    const form = {
                        name,
                        status,
                        legalName,
                        bankDetails,
                        legalAddress,
                        postalAddress,
                        actualAddress,
                        contract,
                        contractDate: contractDate ?
                            dateConvert(contractDate, 'dd-MM-yyyy', 'yyyy-MM-dd')
                            : null,
                        settings
                    };

                    addCompanyForm(form)
                }}
                initialValues={{
                    name: '',
                    status: null,
                    legalName: '',
                    bankDetails: '',
                    legalAddress: '',
                    postalAddress: '',
                    actualAddress: '',
                    contract: '',
                    contractDate: '',
                    settings: [{cityId: null, typeId: null}]
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => {
                    const addSettings = () => {
                        setFieldValue('settings', [...values.settings, {cityId: null, typeId: null}])
                    };
                    const changeSettings = (index: number, setting: SettingType) => {
                        setFieldValue('settings', [
                            ...values.settings.slice(0, index),
                            setting,
                            ...values.settings.slice(index + 1)
                        ])
                    };
                    const removeSettings = (index: number) => {
                        setFieldValue('settings', [
                            ...values.settings.slice(0, index),
                            ...values.settings.slice(index + 1)
                        ]);
                    };
                    return (
                        <>
                            <Link to={`/companies`}><Button color={'light'} className={'mb-2'}>Отменить</Button></Link>
                            <Card>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={'4'}>
                                                <FormGroup>
                                                    <Label>Статус <span className={'required'}>*</span></Label>
                                                    <Dropdown
                                                        name={'status'}
                                                        value={values.status}
                                                        options={companyStatusOptions}
                                                        onChange={(value) => setFieldValue('status', value)}
                                                        invalid={touched.status && !!errors.status}
                                                    />
                                                    <FormFeedback>{errors.status}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={'4'}>
                                                <FormGroup>
                                                    <Label>Название <span className={'required'}>*</span></Label>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        invalid={touched.name && !!errors.name}
                                                    />
                                                    <FormFeedback>{errors.name}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={'4'}>
                                                <FormGroup>
                                                    <Label>Юридическое название</Label>
                                                    <Input
                                                        type="text"
                                                        name="legalName"
                                                        value={values.legalName}
                                                        onChange={handleChange}
                                                        invalid={touched.legalName && !!errors.legalName}
                                                    />
                                                    <FormFeedback>{errors.legalName}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={'4'}>
                                                <FormGroup>
                                                    <Label>Физический адрес</Label>
                                                    <Input
                                                        type="text"
                                                        name="actualAddress"
                                                        value={values.actualAddress}
                                                        onChange={handleChange}
                                                        invalid={touched.actualAddress && !!errors.actualAddress}
                                                    />
                                                    <FormFeedback>{errors.actualAddress}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={'4'}>
                                                <FormGroup>
                                                    <Label>Юридический адрес</Label>
                                                    <Input
                                                        type="text"
                                                        name="legalAddress"
                                                        value={values.legalAddress}
                                                        onChange={handleChange}
                                                        invalid={touched.legalAddress && !!errors.legalAddress}
                                                    />
                                                    <FormFeedback>{errors.legalAddress}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={'4'}>
                                                <FormGroup>
                                                    <Label>Адрес почтовых уведомлений</Label>
                                                    <Input
                                                        type="text"
                                                        name="postalAddress"
                                                        value={values.postalAddress}
                                                        onChange={handleChange}
                                                        invalid={touched.postalAddress && !!errors.postalAddress}
                                                    />
                                                    <FormFeedback>{errors.postalAddress}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={'4'}>
                                                <FormGroup>
                                                    <Label>Реквизиты</Label>
                                                    <Input
                                                        type="textarea"
                                                        name="bankDetails"
                                                        value={values.bankDetails}
                                                        onChange={handleChange}
                                                        invalid={touched.bankDetails && !!errors.bankDetails}
                                                    />
                                                    <FormFeedback>{errors.bankDetails}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={'4'}>
                                                <FormGroup>
                                                    <Label>№ договора</Label>
                                                    <Input
                                                        type="text"
                                                        name="contract"
                                                        value={values.contract}
                                                        onChange={handleChange}
                                                        invalid={touched.contract && !!errors.contract}
                                                    />
                                                    <FormFeedback>{errors.contract}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={'4'}>
                                                <FormGroup>
                                                    <Label>Дата договора</Label>
                                                    <DateTimePicker
                                                        name={'contractDate'}
                                                        value={values.contractDate}
                                                        onChange={value => setFieldValue('contractDate', value)}
                                                        invalid={touched.contractDate && !!errors.contractDate}/>
                                                    <FormFeedback>{errors.contractDate}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Table className={'mb-2'}>
                                            <thead>
                                            <tr>
                                                <th>
                                                    Город <span className={'required'}>*</span>
                                                </th>
                                                <th>
                                                    Метод расчета <span className={'required'}>*</span>
                                                </th>
                                                <th>
                                                    Процент(не более 50%)
                                                </th>
                                                <th>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {values.settings.map((item: SettingType, index) => {
                                                const currentCityIds = values.settings.map(({cityId}: SettingType) => cityId);
                                                const restCitiesOptions = cityOptions.filter(({value}) => !currentCityIds.includes(value) || item.cityId === value);

                                                return (
                                                    <tr key={index}>
                                                        <td style={{width: '33%'}}>
                                                            <Dropdown
                                                                name={`settings[${index}].cityId`}
                                                                value={_.get(values, `settings[${index}].cityId`)}
                                                                options={restCitiesOptions}
                                                                onChange={(cityId) => {
                                                                    changeSettings(index, {
                                                                        ...item,
                                                                        cityId
                                                                    });
                                                                }}
                                                                invalid={_.has(touched, `settings[${index}].cityId`) && _.has(errors, `settings[${index}].cityId`)}
                                                            />
                                                        </td>
                                                        <td style={{width: '33%'}}>
                                                            <Dropdown
                                                                name={`settings[${index}].typeId`}
                                                                value={_.get(values, `settings[${index}].typeId`)}
                                                                options={companyCalculationOptions}
                                                                onChange={(typeId) => {
                                                                    changeSettings(index, {
                                                                        ...item,
                                                                        typeId
                                                                    });
                                                                }}
                                                                invalid={_.has(touched, `settings[${index}].typeId`) && _.has(errors, `settings[${index}].typeId`)}
                                                            />
                                                        </td>
                                                        <td style={{width: '33%'}}>
                                                            <NumberFormat
                                                                name={`settings[${index}].percent`}
                                                                allowNegative={false}
                                                                value={_.get(values, `settings[${index}].percent`)}
                                                                disabled={item.typeId !== COMPANY_CALCULATION_PERCENT}
                                                                onValueChange={({floatValue}) => {
                                                                    changeSettings(index, {
                                                                            ...item,
                                                                        percent: floatValue
                                                                    });
                                                                }}
                                                                invalid={_.has(touched, `settings[${index}].percent`) && _.has(errors, `settings[${index}].percent`)}
                                                                customInput={Input}
                                                            />
                                                        </td>
                                                        <td style={{width: 58}}>
                                                            <Button
                                                                color={'outline-light'}
                                                                size={'sm'}
                                                                disabled={values.settings.length === 1}
                                                                onClick={() => removeSettings(index)}>
                                                                <Icon name={'delete'} color={'gray-500'}/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </Table>

                                        <Button
                                            color={'light'}
                                            className={'mb-4'}
                                            onClick={addSettings}>
                                            Добавить город
                                        </Button>


                                        {changeError &&
                                        (<Alert color={'danger'}>
                                            {changeError}
                                        </Alert>)}

                                        <div>
                                            <Button
                                                type="submit"
                                                color={'primary'}
                                                disabled={changeLoading}>
                                                {changeLoading &&
                                                (<Spinner
                                                    size="sm"
                                                />)}
                                                Создать
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </>
                    )
                }}
            </Formik>
        )
    }
}

export default CompanyAdd;
