import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {editCityForm, getCityForm} from "../store/actions/cityForm";
import {ICity, ICityEditFormRequest} from "../models/City";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import CityEdit from "../components/CityEdit";


type CityEditType = {
    getCityForm: (id: number) => void
    editCityForm: (id: number, form: ICityEditFormRequest) => void
    cancelHandler?: () => void
    data: ICity | null
    id: number
    changeLoading:boolean
    changeError:string
    getLoading:boolean
    getError:string
}


class CityEditContainer extends Component<CityEditType> {

    componentDidMount() {
        const {id, getCityForm} = this.props;
        getCityForm(id);
    }


    render() {
        const {data, getLoading, getError, changeLoading, changeError, editCityForm, getCityForm, id, cancelHandler} = this.props;

        if(getLoading){
            return <Loader/>
        }

        if(getError){
            return <Error error={getError} refresh={() => getCityForm(id)}/>
        }
        if(data !== null) {
            return (
                <CityEdit
                    editCityForm={editCityForm}
                    data={data}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    cancelHandler={cancelHandler}/>
            )
        }
        return null;
    }
}

const mapStateToProps = (state: AppState) => {
    const {data, getLoading, getError, changeLoading, changeError} = state.cityForm;

    return {
        data,
        getLoading,
        getError,
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    getCityForm,
    editCityForm
};


export default connect(mapStateToProps, mapDispatchToProps)(CityEditContainer);
