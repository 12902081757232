import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addRoleFormRequest,
    addRoleFormSuccess,
    addRoleFormError,
    closeRoleForm,
    editRoleFormRequest,
    editRoleFormSuccess,
    editRoleFormError,
    getRoleFormRequest,
    getRoleFormSuccess,
    getRoleFormError, deleteRoleFormRequest, deleteRoleFormSuccess,  deleteRoleFormError
} from "../actions/roleForm";
import {ADD_ROLE_FORM, DELETE_ROLE_FORM, EDIT_ROLE_FORM, GET_ROLE_FORM} from "../constants/roleForm";
import {IRole, IRoleAddFormRequest, IRoleEditFormRequest} from '../../models/Role';
import {RoleService} from "../../services/RoleService";
import {getRoles} from "../actions/roles";



function* addRoleFormSaga({payload}: { type: typeof ADD_ROLE_FORM, payload: IRoleAddFormRequest }) {
    try {
        yield put(addRoleFormRequest());

        const service = new RoleService();
        const promise = yield call(service.addRole, payload);

        const response: IRole = promise.data;

        yield put(addRoleFormSuccess(response));

        yield put(closeRoleForm());

        yield put(getRoles());

    } catch (error) {
        yield put(addRoleFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddRoleForm() {
    yield takeLatest(ADD_ROLE_FORM, addRoleFormSaga);
}


type editRoleFormType = {
    type: typeof EDIT_ROLE_FORM,
    payload: {
        id: string,
        form: IRoleEditFormRequest
    }
}

function* editRoleFormSaga({payload}: editRoleFormType) {
    try {
        yield put(editRoleFormRequest());

        const service = new RoleService();
        const promise = yield call(service.editRole, payload.id, payload.form);

        const response:IRole = promise.data;

        yield put(editRoleFormSuccess(response));

        yield put(closeRoleForm());

        yield put(getRoles());

    } catch (error) {
        yield put(editRoleFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditRoleForm() {
    yield takeLatest(EDIT_ROLE_FORM, editRoleFormSaga);
}

function* getRoleFormSaga({payload}: { type: typeof GET_ROLE_FORM, payload: string }) {
    try {
        yield put(getRoleFormRequest());

        const service = new RoleService();
        const promise = yield call(service.getRole, payload);

        const response:IRole = promise.data;

        yield put(getRoleFormSuccess(response));

    } catch (error) {
        yield put(getRoleFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetRoleForm() {
    yield takeLatest(GET_ROLE_FORM, getRoleFormSaga);
}

function* deleteRoleFormSaga({payload}: { type: typeof DELETE_ROLE_FORM, payload: string }) {
    try {
        yield put(deleteRoleFormRequest());

        const service = new RoleService();
        yield call(service.deleteRole, payload);
        yield put(deleteRoleFormSuccess());

        yield put(closeRoleForm());

        yield put(getRoles());

    } catch (error) {
        yield put(deleteRoleFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeleteRoleForm() {
    yield takeLatest(DELETE_ROLE_FORM, deleteRoleFormSaga);
}
