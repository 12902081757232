import {
    GET_COURIER_PENALTIES_ERROR,
    GET_COURIER_PENALTIES_REQUEST,
    GET_COURIER_PENALTIES_SUCCESS
} from "../constants/courierPenalties";
import {ICourierPenaltiesFilter, ICourierPenalty} from "../../models/Courier";
import {IActionCourierPenalties} from "../actions/courierPenalties";


export interface ICourierPenaltiesState {
    loading: boolean
    filter: ICourierPenaltiesFilter | null,
    error: string
    data: ICourierPenalty[]
}

const initialState: ICourierPenaltiesState = {
    data: [],
    filter:  null,
    loading: false,
    error: ''
};

export default function courierPenalties(state = initialState, action: IActionCourierPenalties): ICourierPenaltiesState {
    switch (action.type) {
        case GET_COURIER_PENALTIES_REQUEST:
            return {
                filter:null,
                data: [],
                loading: true,
                error: ''
            };
        case GET_COURIER_PENALTIES_SUCCESS:
            const {filter, data} = action.payload;
            return {
                filter,
                data,
                loading: false,
                error: ''
            };
        case GET_COURIER_PENALTIES_ERROR:
            return {
                filter:null,
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
