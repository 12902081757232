import {createSelector} from "reselect";
import queryString from 'query-string';
import _ from 'lodash';


const reportCompaniesUrlSelector = (props: any) => {
    return props.location.search
};

export const reportCompaniesSortReSelector = createSelector(
    [reportCompaniesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['orderBy']);

        let orderBy = {} as {[key:string]: string};
        if(params.orderBy &&  typeof params.orderBy === "string") {
            const orderByArray = params.orderBy.split('_');
            orderBy[orderByArray[0]] = orderByArray[1] + '_' + orderByArray[2];
        }

        const sort = _.pickBy(orderBy, function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(sort) ? {orderBy:sort} : undefined
    }
);

export const reportCompaniesFilterReSelector = createSelector(
    [reportCompaniesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['periodId', 'companyId', 'calcTypeId']);

        const periodId = params.periodId ? +params.periodId : undefined;
        const companyId = params.companyId ? +params.companyId : undefined;
        const calcTypeId = params.calcTypeId ? +params.calcTypeId : undefined;

        const filter = _.pickBy({periodId, companyId, calcTypeId}, function(value) {
            return !(value === undefined || value === null);
        });

        return !_.isEmpty(filter) ? filter : undefined
    }
);

export const reportCompaniesPageReSelector = createSelector(
    [reportCompaniesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.page ? +getParams.page : 1
    }
);

