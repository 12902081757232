import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getDictionaryPermissionsError,
    getDictionaryPermissionsRequest,
    getDictionaryPermissionsSuccess
} from '../actions/dictionaryPermissions';
import {GET_DICTIONARY_PERMISSIONS} from "../constants/dictionaryPermissions";
import {IDictionary} from "../../models/Dictionary";
import {DictionaryService} from '../../services/DictionaryService';


function* getDictionaryPermissionsSaga() {
    try {
        yield put(getDictionaryPermissionsRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryPermissions);

        const response: IDictionary[] = promise.data || [];

        yield put(getDictionaryPermissionsSuccess(response));


    } catch (error) {
        yield put(getDictionaryPermissionsError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryPermissions() {
    yield takeLatest(GET_DICTIONARY_PERMISSIONS, getDictionaryPermissionsSaga);
}
