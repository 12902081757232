import {
    GET_DICTIONARY_CITY_EAGERS_ERROR,
    GET_DICTIONARY_CITY_EAGERS_REQUEST,
    GET_DICTIONARY_CITY_EAGERS_SUCCESS
} from "../constants/dictionaryCityEagers";
import {IDictionary} from "../../models/Dictionary";
import {IActionDictionaryCityEagers} from "../actions/dictionaryCityEagers";


export interface IDictionaryCityEagersState {
    loading: boolean
    error: string
    data: IDictionary[]
}

const initialState: IDictionaryCityEagersState = {
    data: [],
    loading: false,
    error: ''
};

export default function dictionaryCityEagers(state = initialState, action: IActionDictionaryCityEagers): IDictionaryCityEagersState {
    switch (action.type) {
        case GET_DICTIONARY_CITY_EAGERS_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_DICTIONARY_CITY_EAGERS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_DICTIONARY_CITY_EAGERS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
