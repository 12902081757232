import React, {Component} from "react";
import CouriersShiftsFilter from "../components/CouriersShiftsFilter";
import {MapState} from "react-yandex-maps";
import {getDictionaryCouriers} from "../store/actions/dictionaryCouriers";
import {connect} from "react-redux";
import {IDictionary} from "../models/Dictionary";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {RouteComponentProps, withRouter} from "react-router";
import queryString from "query-string";
import {compose} from "redux";
import {couriersShiftsFilterReSelector} from "../store/reselectors/couriersShifts";
import {ISlotFilter} from "../models/Slot";
import {getDictionaryCityEagers} from "../store/actions/dictionaryCityEagers";

type CouriersShiftsFilterContainerType = {
    filter: ISlotFilter
    getDictionaryCityEagers: () => void
    getDictionaryCouriers: () => void
    dictionaryCityEagers: IDictionary[]
    dictionaryCityEagersLoading: boolean
    dictionaryCityEagersError: string
    dictionaryCouriers: IDictionary[]
    dictionaryCouriersLoading: boolean
    dictionaryCouriersError: string
}

class CouriersShiftsFilterContainer extends Component<CouriersShiftsFilterContainerType & RouteComponentProps> {

    componentDidMount() {
        const {getDictionaryCityEagers, getDictionaryCouriers} = this.props
        getDictionaryCityEagers();
        getDictionaryCouriers()
    }

    setFilterHandler = (form: ISlotFilter) => {
        const {history} = this.props
        const nextQueryParams = queryString.stringify(form)
        history.push(`${history.location.pathname}?${nextQueryParams}`)
    }

    render() {

        const {getDictionaryCouriers, dictionaryCityEagers, dictionaryCityEagersLoading, dictionaryCouriers, dictionaryCouriersLoading, dictionaryCouriersError, filter} = this.props

        if (dictionaryCityEagersLoading || dictionaryCouriersLoading) {
            return <Loader/>
        }

        if (dictionaryCouriersError) return <Error error={dictionaryCouriersError} refresh={getDictionaryCouriers}/>

        if (!!dictionaryCityEagers.length && !!dictionaryCouriers.length) {
            return (
                <CouriersShiftsFilter
                    filter={filter}
                    dictionaryCityEagers={dictionaryCityEagers}
                    dictionaryCouriers={dictionaryCouriers}
                    setFilterHandler={this.setFilterHandler}
                />
            )
        }
        return null
    }
}

const mapStateToProps = ({dictionaryCityEagers, dictionaryCouriers}: MapState, props: RouteComponentProps) => {
    return {
        filter: couriersShiftsFilterReSelector(props),
        dictionaryCityEagers: dictionaryCityEagers.data,
        dictionaryCityEagersLoading: dictionaryCityEagers.loading,
        dictionaryCityEagersError: dictionaryCityEagers.error,
        dictionaryCouriers: dictionaryCouriers.data,
        dictionaryCouriersLoading: dictionaryCouriers.loading,
        dictionaryCouriersError: dictionaryCouriers.error
    }
}

const mapDispatchToProps = {
    getDictionaryCouriers,
    getDictionaryCityEagers,
}

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(CouriersShiftsFilterContainer);