import React, {Component} from "react";
import PickCity from "./PickCity";
import {RouteComponentProps, withRouter} from "react-router";
import {compose} from "redux";
import {connect} from "react-redux";
import {courierRateCityIdReSelector} from "../store/reselectors/courierRate";
import {AppState} from "../store/reducers";
import {changeCourierRate, getCourierRate} from "../store/actions/courierRate";
import {Button, Input, Spinner} from "reactstrap";
import Loader from "../components/UI/Loader";
import Empty from "../components/UI/Empty";
import Error from "../components/UI/Error";
import {Form, Formik} from "formik";
import NumberFormat from "react-number-format";
import {ICourierRate, ICourierRateForm} from "../models/Courier";
import Table from "../components/UI/Table";
import _ from "lodash";
import {IAuth} from "../models/Auth";
import {Redirect} from "react-router-dom";
import {employeeTypeOptions} from "../utils/employees";
import {COURIER_TYPE_AUTO, CourierRateTypes} from "../utils/couriers";

type CourierRateType = {
    getCourierRate: (cityId: number) => void,
    changeCourierRate: (cityId: number, form: ICourierRateForm[]) => void,
    cityId?: number,
    data: ICourierRate[],
    loading: boolean,
    error: string,
    changeLoading: boolean
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class CourierRate extends Component<CourierRateType & CitiesType> {

    componentDidMount() {
        const {getCourierRate, cityId} = this.props;

        if (typeof cityId === 'number') {
            getCourierRate(cityId);
        }
    }

    componentDidUpdate(prevProps: Readonly<CourierRateType & CitiesType>, prevState: Readonly<{}>) {
        const {getCourierRate, cityId} = this.props;

        if (typeof cityId === 'number' && prevProps.cityId !== cityId) {
            getCourierRate(cityId);
        }
    }

    render() {
        const {data, loading, error, getCourierRate, changeCourierRate, cityId, changeLoading, allowedCities} = this.props;

        if (loading) return <Loader/>

        if (!cityId) {
            return <Redirect to={`/settings/courier-rate?cityId=${allowedCities[0].id}`}/>
        }
    
        /**
         * Сохранить индекс в data для объектов, потому что они будут разделены по типу тарифа
         */
        const indexedData = data.map((rate, index) => ({
            ...rate,
            _index: index,
        }))

        return (
            <>
                <div className={'mb-2'}>
                    <PickCity/>
                </div>
                <Formik
                    onSubmit={(({data}) => {
                        const form = data.map(({typeId, rateTypeId, orderPrice, hourPrice, bonusForSticker}: ICourierRate) => {
                            return {
                                typeId,
                                rateTypeId,
                                orderPrice: parseFloat(String(orderPrice)),
                                hourPrice: parseFloat(String(hourPrice)),
                                bonusForSticker: typeId === COURIER_TYPE_AUTO ? parseFloat(String(bonusForSticker)) : null
                            }
                        })


                        changeCourierRate(cityId, form);
                    })}
                    initialValues={{
                        data: indexedData,
                    }}
                >
                    {({handleSubmit, handleChange, values, dirty}) => {
                        
                        const renderRatesTable = (rates: typeof indexedData) => {
                            return <Table striped noBorder size={'large'}>
                                <tbody>
                                <tr>
                                    <th style={{width: '25%'}}>Тип курьера</th>
                                    <th style={{width: '25%'}}>Ставка за заказ</th>
                                    <th style={{width: '25%'}}>Ставка за час</th>
                                    <th style={{width: '25%'}}>Надбавка за наклейки</th>
                                </tr>
                                {rates.map((item) => {
                                      return (
                                        <tr key={item._index}>
                                            <td style={{width: '25%'}}>
                                                {employeeTypeOptions.find(x => x.value === item.typeId)?.label}
                                            </td>
                                            <td style={{width: '25%'}}>
                                                <NumberFormat
                                                  name={`data[${item._index}].orderPrice`}
                                                  value={values.data[item._index].orderPrice}
                                                  onChange={handleChange}
                                                  customInput={Input}
                                                  allowNegative={false}
                                                  disabled={changeLoading}
                                                  required={true}
                                                />
                                            </td>
                                            <td style={{width: '25%'}}>
                                                <NumberFormat
                                                  name={`data[${item._index}].hourPrice`}
                                                  value={values.data[item._index].hourPrice}
                                                  onChange={handleChange}
                                                  customInput={Input}
                                                  allowNegative={false}
                                                  disabled={changeLoading}
                                                  required={true}
                                                />
                                            </td>
                                            <td style={{width: '25%'}}>
                                                <NumberFormat
                                                  name={`data[${item._index}].bonusForSticker`}
                                                  value={values.data[item._index].bonusForSticker!}
                                                  onChange={handleChange}
                                                  customInput={Input}
                                                  allowNegative={false}
                                                  disabled={item.typeId !== COURIER_TYPE_AUTO || changeLoading}
                                                  required={item.typeId === COURIER_TYPE_AUTO}
                                                />
                                            </td>
                                        </tr>
                                      )
                                  }
                                )}
                                </tbody>
                            </Table>
                        }
                        
                        const oldTypeRates = indexedData.filter(({rateTypeId}) => rateTypeId === CourierRateTypes.Old);
                        const newTypeRates = indexedData.filter(({rateTypeId}) => rateTypeId === CourierRateTypes.New);
                        
                        return <Form onSubmit={handleSubmit}>
                            <h4 className='mt-3 mb-2'>Новые тарифы</h4>
                            {renderRatesTable(newTypeRates)}
                            <h4 className='mt-3 mb-2'>Старые тарифы</h4>
                            {renderRatesTable(oldTypeRates)}
                            <div className={'text-right mt-2'}>
                                <Button type="submit" color={'primary'}
                                        disabled={changeLoading || !dirty}>Сохранить {changeLoading &&
                                (<Spinner
                                    size="sm"
                                />)}</Button>
                            </div>
                        </Form>
                    }}
                </Formik>
                {error && <Error
                    error={error}
                    refresh={() => getCourierRate(cityId)}
                />}
                {!data.length && !loading && !error &&
                <Empty>
                    <h5 className={'mb-0'}>Список курьерских ставок пуст</h5>
                </Empty>}
            </>
        );
    }
}

const mapStateToProps = ({courierRate, courierRateChange, auth}: AppState, props: RouteComponentProps) => {
    const {data, loading, error} = courierRate;
    const changeLoading = courierRateChange.loading;
    const {user} = auth;
    return {
        data,
        cityId: courierRateCityIdReSelector(props),
        loading,
        error,
        changeLoading,
        allowedCities: _.get(user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    getCourierRate,
    changeCourierRate
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(CourierRate);
