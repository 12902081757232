import {
    ADD_ORDER_FORM,
    ADD_ORDER_FORM_REQUEST,
    ADD_ORDER_FORM_SUCCESS,
    ADD_ORDER_FORM_ERROR,
    EDIT_ORDER_FORM_REQUEST,
    EDIT_ORDER_FORM_SUCCESS,
    EDIT_ORDER_FORM_ERROR,
    EDIT_ORDER_FORM,
    GET_ORDER_FORM,
    GET_ORDER_FORM_REQUEST,
    GET_ORDER_FORM_SUCCESS,
    GET_ORDER_FORM_ERROR,
    CHANGE_COORDINATE_ORDER_FORM_REQUEST,
    CHANGE_COORDINATE_ORDER_FORM_SUCCESS,
    CHANGE_COORDINATE_ORDER_FORM_ERROR, CHANGE_COORDINATE_ORDER_FORM, OPEN_ORDER_FORM, CLOSE_ORDER_FORM
} from '../constants/orderForm'
import {IAction} from "./index";
import {IOrder, IOrderAddFormRequest, IOrderChangeCoordinateRequest, IOrderEditFormRequest} from "../../models/Order";


export interface IActionOrderForm extends IAction<symbol, any> {

}


export const addOrderFormRequest = () => {
    return {
        type: ADD_ORDER_FORM_REQUEST
    }
};

export const addOrderFormSuccess = (payload: IOrder) => {
    return {
        type: ADD_ORDER_FORM_SUCCESS,
        payload
    }
};

export const addOrderFormError = (payload: string) => {
    return {
        type: ADD_ORDER_FORM_ERROR,
        payload
    }
};

export const addOrderForm = (payload: IOrderAddFormRequest) => {
    return {
        type: ADD_ORDER_FORM,
        payload
    };
};

export const editOrderFormRequest = () => {
    return {
        type: EDIT_ORDER_FORM_REQUEST
    }
};

export const editOrderFormSuccess = (payload: IOrder) => {
    return {
        type: EDIT_ORDER_FORM_SUCCESS,
        payload
    }
};

export const editOrderFormError = (payload: string) => {
    return {
        type: EDIT_ORDER_FORM_ERROR,
        payload
    }
};

export const editOrderForm = (id: string, form: IOrderEditFormRequest) => {
    return {
        type: EDIT_ORDER_FORM,
        payload: {id, form}
    };
};

export const getOrderFormRequest = () => {
    return {
        type: GET_ORDER_FORM_REQUEST
    }
};

export const getOrderFormSuccess = (payload: IOrder) => {
    return {
        type: GET_ORDER_FORM_SUCCESS,
        payload
    }
};

export const getOrderFormError = (payload: string) => {
    return {
        type: GET_ORDER_FORM_ERROR,
        payload
    }
};

export const getOrderForm = (payload: string) => {
    return {
        type: GET_ORDER_FORM,
        payload
    };
};


export const changeCoordinateOrderFormRequest = () => {
    return {
        type: CHANGE_COORDINATE_ORDER_FORM_REQUEST
    }
};

export const changeCoordinateOrderFormSuccess = (payload: IOrder) => {
    return {
        type: CHANGE_COORDINATE_ORDER_FORM_SUCCESS,
        payload
    }
};

export const changeCoordinateOrderFormError = (payload: string) => {
    return {
        type: CHANGE_COORDINATE_ORDER_FORM_ERROR,
        payload
    }
};

export const changeCoordinateOrderForm = (id: string, form: IOrderChangeCoordinateRequest) => {
    return {
        type: CHANGE_COORDINATE_ORDER_FORM,
        payload: {
            id,
            form
        }
    };
};

export const openOrderForm = (payload: 'coordinate') => {
    return {
        type: OPEN_ORDER_FORM,
        payload
    };
};

export const closeOrderForm = () => {
    return {
        type: CLOSE_ORDER_FORM
    };
};
