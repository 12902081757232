import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import ReportCouriers from "../../containers/ReportCouriers";


class ReportCouriersPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Сводка - Курьеры</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Сводка - курьеры', url: '/reports/couriers'}
                ]}/>
                <ReportCouriers/>
            </Layout>
        );
    }
}

export default ReportCouriersPage;
