import React, {Component, PureComponent} from 'react';
import {Formik} from 'formik';
import {Col, Input, Row} from "reactstrap";
import styles from './TableGrid.module.scss'
import Dropdown from "../Dropdown";
import NumberFormat from "react-number-format";
import Icon from '../Icon';
import DateTimePicker from "../DateTimePicker";
import _ from 'lodash';
import classNames from "classnames";
import {dateConvert} from "../../../utils/date-convert";

type TableGridColumnType = {
    label: string | JSX.Element,
    key?: string,
    filter?: {
        type: string,
        options?: { label: any; value: any }[]
        isMulti?: true
        isDoubleField?: boolean
        startPostFix?: string
        endPostFix?: string
        handler: (value: { [key: string]: any }) => void
    },
    sort?: {
        handler: (value: { [key: string]: any }) => void
    },
    width?: number | string
    render?: (value: any) => void
}

type TableGridGroupColumnType = {
    label: string,
    colspan?: number | undefined
}

type TableGridFooterColumnType = {
    value?: string
    key?: string
    hidden?: boolean
}

type TableGridOptions = {
    /**
     * Также отображать столбцы внизу
     * (если есть столбцы группировки, то они отображаются под обычными, а не сверху)
     */
    alsoShowColumnsInFooter?: boolean;
}

type TableGridType = {
    sourceData: any[];
    headerGroupColumns?: TableGridGroupColumnType[];
    columns: TableGridColumnType[];
    filterData?: any;
    sortData?: any;
    footerColumns?: TableGridFooterColumnType[];
    hasBorder?: boolean;
    footerData?: any;
    options?: TableGridOptions;
};


class TableGrid extends Component<TableGridType & React.HTMLAttributes<HTMLDivElement>> {

    shouldComponentUpdate(nextProps: Readonly<TableGridType & React.HTMLAttributes<HTMLDivElement>>) {
        return !_.isEqual(nextProps.sourceData, this.props.sourceData)
    }

    render() {
        const {
            sourceData,
            columns,
            filterData,
            sortData,
            headerGroupColumns,
            hasBorder,
            footerColumns,
            footerData,
            options,
        } = this.props;
        
        return (
            <table className={styles.table}>
                <thead>
                <TableGroupColumns headerGroupColumns={headerGroupColumns} hasBorder={hasBorder}/>
                <TableSort hasBorder={hasBorder} sortData={sortData} columns={columns}/>
                <TableFilter filterData={filterData} columns={columns}/>
                </thead>
                <tbody>
                {sourceData.map((data: any, index: number) => {
                        return (
                            <TableItem
                                hasBorder={hasBorder}
                                key={index}
                                data={data}
                                columns={columns}
                            />
                        )
                    }
                )}
                {!!footerColumns &&
                <TableFooter sourceData={sourceData} footerData={footerData} hasBorder={hasBorder} footerColumns={footerColumns}/>}
                </tbody>
                {!!options?.alsoShowColumnsInFooter && <tfoot>
                    <TableSort hasBorder={hasBorder} sortData={sortData} columns={columns}/>
                    <TableGroupColumns headerGroupColumns={headerGroupColumns} hasBorder={hasBorder}/>
                </tfoot>}
            </table>
        );
    }
}

type TableFooterType = {
    sourceData: any[],
    hasBorder?: boolean,
    footerColumns: any,
    footerData?: any
}

type tableCellParamsType = {
    value: string
    fontWeight: string
    color: string
}

class TableFooter extends PureComponent<TableFooterType> {
    render() {

        const {sourceData, hasBorder, footerColumns, footerData} = this.props;

        return (
            <tr>
                {footerColumns.map((column: { value?: string, key: string, hidden?: boolean }, index: number) => {

                    const tableCellParams: tableCellParamsType = {
                        value: "",
                        fontWeight: "font-weight-bold",
                        color: ""
                    }

                    if (footerData){
                        tableCellParams.value = footerData[column.key]
                    } else {
                        const reducedData = _.omit(sourceData.reduce((acc, n) => (Object.entries(n).forEach(([k, v]) => acc[k] = (acc[k] || 0) + v), acc), {}), ["id"]);

                        if (column.key === "profit") {
                            if (reducedData[column.key] > 0) {
                                tableCellParams.color = "#29A745";
                            } else {
                                tableCellParams.color = "#DC3545";
                            }
                        }

                        tableCellParams.value = reducedData[column.key];
                    }

                    if (column.value){
                        tableCellParams.value = column.value;
                    }

                    if (column.hidden){
                        tableCellParams.value = "";
                    }

                    return <td key={index} style={{color: tableCellParams.color}}
                               className={classNames(hasBorder && styles["table-border"], tableCellParams.fontWeight)}>{tableCellParams.value}</td>
                })}
            </tr>
        )
    }
}

type TableGroupColumnsType = {
    headerGroupColumns?: TableGridGroupColumnType[],
    hasBorder?: boolean
}

class TableGroupColumns extends PureComponent<TableGroupColumnsType> {

    render() {

        const {headerGroupColumns, hasBorder} = this.props;

        return (
            <tr>
                {headerGroupColumns && headerGroupColumns.map(({label, colspan}, index) => {
                    return (
                        <th key={index} className={classNames(hasBorder && styles["table-border"])} colSpan={colspan}
                            style={{textAlign: "center"}}>
                            {label}
                        </th>
                    )
                })}
            </tr>
        )
    }
}

type TableGridItemType = {
    data: any,
    columns: TableGridColumnType[],
    hasBorder?: boolean
};

class TableItem extends PureComponent<TableGridItemType> {
    render() {
        const {data, columns, hasBorder} = this.props;

        return (

            <tr>
                {columns.map(({key, width, render, label}, index: number) => {
                    return (
                        <td key={index}
                            className={classNames(hasBorder && styles["table-border"])}
                            style={{width}}>
                            {render ? render(data) : key && data[key]}
                        </td>
                    )
                })}
            </tr>
        );
    }
}

type TableFilterType = {
    filterData?: any,
    columns: TableGridColumnType[]
};

class TableFilter extends PureComponent<TableFilterType> {
    render() {
        const {filterData, columns} = this.props;

        const initialValues = {} as { [key: string]: any };

        const initType = {
            'number': '',
            'text': '',
            'select': null,
            'date': null
        } as { [key: string]: any };

        columns.forEach(({key, filter}) => {
            if (key && filter) {
                if (filter.isDoubleField && filter.startPostFix && filter.endPostFix) {
                    initialValues[key + filter.startPostFix] = filterData && filterData.hasOwnProperty(key + filter.startPostFix)
                        ? filterData[key + filter.startPostFix]
                        : initType[filter.type];

                    /**
                     * Приведение даты начала из формата 'yyyy-MM-dd' к 'dd-MM-yyyy'
                     **/
                    initialValues[key + filter.startPostFix] = initialValues[key + filter.startPostFix] && dateConvert(
                        initialValues[key + filter.startPostFix], 'yyyy-MM-dd', 'dd-MM-yyyy');

                    initialValues[key + filter.endPostFix] = filterData && filterData.hasOwnProperty(key + filter.endPostFix)
                        ? filterData[key + filter.endPostFix]
                        : initType[filter.type]

                    /**
                     * Приведение даты конца из формата 'yyyy-MM-dd' к 'dd-MM-yyyy'
                     **/
                    initialValues[key + filter.endPostFix] = initialValues[key + filter.endPostFix] && dateConvert(
                        initialValues[key + filter.endPostFix], 'yyyy-MM-dd', 'dd-MM-yyyy');
                }

                initialValues[key] = filterData && filterData.hasOwnProperty(key) ? filterData[key] : initType[filter.type]

                if (filter.type === 'date') {
                    initialValues[key] = initialValues[key] &&
                        dateConvert(initialValues[key], 'yyyy-MM-dd', 'dd-MM-yyyy');
                }
            }
        });

        if (!columns.filter(({filter}) => filter).length) {
            return null
        }

        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={() => {
                }}
            >
                {({setFieldValue, values, handleChange}) => {
                    return (
                        <tr>
                            {columns.map(({key, filter, width}, index: number) => {
                                    return (
                                        <td key={index} style={{width}}>
                                            {filter && filter.type && key && (
                                                <>
                                                    {filter.type === 'number' && key && (
                                                        <NumberFormat
                                                            name={key}
                                                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                setFieldValue(key, (e.target as HTMLInputElement).value);
                                                                if (e.key === 'Enter') {
                                                                    filter.handler({[key]: (e.target as HTMLInputElement).value})
                                                                }
                                                            }}
                                                            value={values[key]}
                                                            allowNegative={false}
                                                            decimalSeparator={false}
                                                            customInput={Input}
                                                        />
                                                    )}
                                                    {filter.type === 'select' && filter.options && key && (
                                                        <Dropdown
                                                            name={key}
                                                            value={values[key]}
                                                            options={filter.options}
                                                            isMulti={filter.isMulti || false}
                                                            onChange={(value) => {
                                                                setFieldValue(key, value);
                                                                filter.handler({[key]: value})
                                                            }}
                                                            isClearable
                                                        />
                                                    )}
                                                    {filter.type === 'text' && (
                                                        <Input
                                                            type="text"
                                                            name={key}
                                                            value={values[key]}
                                                            onChange={handleChange}
                                                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                setFieldValue(key, (e.target as HTMLInputElement).value);
                                                                if (e.key === 'Enter') {
                                                                    filter.handler({[key]: (e.target as HTMLInputElement).value})
                                                                }
                                                            }}/>
                                                    )}
                                                    {filter.type === 'date' && filter.isDoubleField && (
                                                        <Row form>
                                                            <Col>

                                                                <DateTimePicker
                                                                    name={key}
                                                                    value={values[key + filter.startPostFix]}
                                                                    onChange={value => {
                                                                        setFieldValue(key + filter.startPostFix, value);
                                                                        filter.handler({[key + filter.startPostFix]: (
                                                                            value &&
                                                                                dateConvert(value, 'dd-MM-yyyy', 'yyyy-MM-dd')
                                                                            )})
                                                                    }}/>
                                                            </Col>
                                                            <Col md={'auto'}>
                                                                <div className={'mt-1 mb-1'}>–</div>
                                                            </Col>
                                                            <Col>
                                                                <DateTimePicker
                                                                    name={key}
                                                                    value={values[key + filter.endPostFix]}
                                                                    onChange={value => {
                                                                        setFieldValue(key + filter.endPostFix, value);
                                                                        filter.handler({[key + filter.endPostFix]: (
                                                                                value &&
                                                                                dateConvert(value, 'dd-MM-yyyy', 'yyyy-MM-dd')
                                                                            )})
                                                                    }}/>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {filter.type === 'date' && !filter.isDoubleField && (
                                                        <DateTimePicker
                                                            name={key}
                                                            value={values[key]}
                                                            onChange={value => {
                                                                setFieldValue(key, value);
                                                                filter.handler({[key]: (
                                                                        value && dateConvert(value, 'dd-MM-yyyy', 'yyyy-MM-dd')
                                                                    )})
                                                            }}/>
                                                    )
                                                    }
                                                </>
                                            )}
                                        </td>
                                    )
                                }
                            )}
                        </tr>
                    )
                }}
            </Formik>
        );
    }
}

type TableSortType = {
    sortData?: any,
    columns: TableGridColumnType[],
    hasBorder?: boolean
};

class TableSort extends PureComponent<TableSortType> {


    render() {
        const {sortData, columns, hasBorder} = this.props;
        return (
            <tr>
                {columns.map(({label, key, sort, width}, index: number) => {
                    if (key && sort) {
                        const value = sortData && sortData[key];

                        const sortHandler = () => {
                            sort.handler({[key]: value === 'SORT_ASC' ? 'SORT_DESC' : 'SORT_ASC'})
                        };

                        return (
                            <th
                                key={index}
                                style={{width}}
                                className={styles['table-th--sortable']}
                                onClick={sortHandler}>
                                <div className={'d-inline-flex align-items-center'} style={{minHeight: 24}}>
                                    {label}
                                    {value === 'SORT_ASC' && <Icon name={'arrow_drop_up'}/>}
                                    {value === 'SORT_DESC' && <Icon name={'arrow_drop_down'}/>}
                                </div>
                            </th>
                        )
                    }
                    return <th className={classNames(hasBorder && styles["table-border"])} key={index}>
                        {label}
                    </th>
                })}
            </tr>
        );
    }
}

export default TableGrid;

