import {ICourierPhone} from "../../models/Order";
import {
  CLEAR_COURIER_PHONE,
  GET_COURIER_PHONE_ERROR,
  GET_COURIER_PHONE_REQUEST,
  GET_COURIER_PHONE_SUCCESS
} from "../constants/courierPhone";
import {IActionCourierPhone} from "../actions/courierPhone";

export interface ICourierPhoneState {
  data: ICourierPhone | null;
  getLoading: boolean;
  getError: string;
}

const initialState: ICourierPhoneState = {
  data: null,
  getLoading: false,
  getError: '',
}

export default function courierPhone(
  state = initialState,
  action: IActionCourierPhone,
): ICourierPhoneState {
  switch (action.type) {
    case GET_COURIER_PHONE_REQUEST:
      return {
        data: null,
        getLoading: true,
        getError: '',
      };
    case GET_COURIER_PHONE_SUCCESS:
      return {
        data: action.payload,
        getLoading: false,
        getError: ''
      };
    case GET_COURIER_PHONE_ERROR:
      return {
        data: null,
        getLoading: false,
        getError: action.payload
      };
    case CLEAR_COURIER_PHONE:
      return {
        ...initialState,
      }
    default:
      return state;
  }
}
