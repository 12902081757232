import React, {PureComponent} from 'react';
import {ISchedule} from "../../models/Schedule";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Col,
    CustomInput,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane
} from "reactstrap";
import {Link} from "react-router-dom";
import {getScheduleWeek} from "../../utils/schedules";
import classNames from 'classnames';
import ScheduleDate from "../../containers/ScheduleDate";
import ScheduleDateAdd from "../../containers/ScheduleDateAdd";
import ScheduleDateEdit from '../../containers/ScheduleDateEdit';
import ConfirmDialog from "../ConfirmDialog";
import {accessScheduleCreate, accessSchedulePublish} from "../../utils/user-accesses";


interface ScheduleType {
    data: ISchedule
    mode: 'add' | 'edit' | null
    publishSchedule: (id: number) => void
    cancelSchedule: (id: number) => void
    closeScheduleDateForm: () => void
    publishLoading: boolean
    publishError: string
    cancelLoading: boolean
    cancelError: string
    accesses?: string[]
}

class Schedule extends PureComponent<ScheduleType, { isDraft: boolean, activeDate: string, isClearDialog: boolean, isPublishDialog: boolean }> {
    state = {
        isDraft: false,
        activeDate: '',
        isClearDialog: false,
        isPublishDialog: false
    };

    toggleDraftHandler = () => {
        this.setState(({isDraft}) => ({
            isDraft: !isDraft
        }))
    };

    changeActiveDateHandler = (activeDate: string) => {
        const {closeScheduleDateForm} = this.props;
        this.setState({activeDate}, () => {
            closeScheduleDateForm();
        })
    };

    openClearDialogHandler = () => {
        this.setState({isClearDialog: true})
    };

    closeClearDialogHandler = () => {
        this.setState({isClearDialog: false})
    };

    openPublishDialogHandler = () => {
        this.setState({isPublishDialog: true})
    };

    closePublishDialogHandler = () => {
        this.setState({isPublishDialog: false})
    };

    render() {
        const {
            data, publishSchedule, publishLoading, publishError,
            mode, cancelLoading, cancelError, cancelSchedule, accesses
        } = this.props;
        const {id, isPublished, dateStart, cityId, confirmCouriers, courierByDays} = data;

        const scheduleWeek = getScheduleWeek(dateStart);

        const {isDraft, activeDate, isClearDialog, isPublishDialog} = this.state;

        const currentDate = activeDate || dateStart;
        /** текущее состояние переключателя черновика/публикации */
        const currentIsDraft = !isDraft && isPublished;

        return (
            <>
                <div className={'mb-3'}>
                    <Row className="align-items-center">
                        <Col md={'auto'}>
                            <ButtonGroup>
                                <Link to={`/schedules?cityId=${cityId}`}>
                                    <Button color={'light'}>
                                        Назад
                                    </Button>
                                </Link>
                                {Array.isArray(accesses) && accesses.includes(accessSchedulePublish) && (
                                    <>
                                        <Button color={'primary'} disabled={publishLoading}
                                                onClick={this.openPublishDialogHandler}>
                                            {publishLoading &&
                                            (<Spinner
                                                size="sm"
                                            />)}
                                            Опубликовать
                                        </Button>
                                        <ConfirmDialog
                                            isOpen={isPublishDialog}
                                            closeHandler={this.closePublishDialogHandler}
                                            actionHandler={() => {
                                                this.closePublishDialogHandler();
                                                publishSchedule(id);
                                            }}
                                            title={'Опубликовать изменения в расписании?'}
                                            desc={'Все изменения опубликуются и станут доступны курьерам.'}
                                            actionButtonText={'Опубликовать'}
                                            cancelButtonText={'Не публиковать'}
                                        />
                                    </>)}
                                {Array.isArray(accesses) && accesses.includes(accessScheduleCreate) && (
                                    <>
                                        <Button disabled={cancelLoading} color={'danger'}
                                                onClick={this.openClearDialogHandler}>
                                            {cancelLoading &&
                                            (<Spinner
                                                size="sm"
                                            />)}
                                            Сбросить
                                        </Button>
                                        <ConfirmDialog
                                            isOpen={isClearDialog}
                                            closeHandler={this.closeClearDialogHandler}
                                            actionHandler={() => {
                                                this.closeClearDialogHandler();
                                                cancelSchedule(id);
                                            }}
                                            title={'Отменить изменения и удалить черновик?'}
                                            desc={'Все изменения в графиках будут удалены - опубликованные данные останутся без изменений.'}
                                            actionButtonColor={'danger'}
                                            actionButtonText={'Да, удалить'}
                                            cancelButtonText={'Нет, оставить'}
                                        />
                                    </>)}
                            </ButtonGroup>
                        </Col>
                        <Col md={'auto'}>
                            <Row form className="align-items-center">
                                <Col md={'auto'}>
                                    <span className={isPublished ? 'black' : 'gray-400'}>В публикации</span>
                                </Col>
                                {mode === null && isPublished && Array.isArray(accesses) && accesses.includes(accessScheduleCreate) && (
                                    <Col md={'auto'}>
                                        <CustomInput
                                            type="switch"
                                            id="isDraft"
                                            checked={!currentIsDraft}
                                            label={'Черновик'}
                                            onChange={() => this.toggleDraftHandler()}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </div>
                {publishError && (
                    <Alert color={'danger'} className={'mb-3'}>{publishError}</Alert>
                )}
                {cancelError && (
                    <Alert color={'danger'} className={'mb-3'}>{cancelError}</Alert>
                )}
                <Nav tabs className={'borderNone'}>
                    {Object.keys(scheduleWeek).map((day) => {
                        const totalCouriersPerDay = courierByDays.find((currentDay) => currentDay.date === scheduleWeek[day]);
                        return (<NavItem key={scheduleWeek[day]}>
                            <NavLink
                                style={{height: '100%'}}
                                className={classNames('pointer', currentDate === scheduleWeek[day] && 'active')}
                                onClick={() => this.changeActiveDateHandler(scheduleWeek[day])}
                            >
                                <>
                                    <span>
                                        {day}
                                    </span>
                                    <br/>
                                    {totalCouriersPerDay && (
                                        <div className={'d-flex'}>
                                            <strong className={'ml-auto mr-auto'}>
                                                <span>
                                                    {totalCouriersPerDay.countAll}&nbsp;
                                                </span>/
                                                <span className={'text-success'}>
                                                    &nbsp;{totalCouriersPerDay.countConfirmed}&nbsp;
                                                </span>/
                                                <span className={'text-danger'}>
                                                    &nbsp;{totalCouriersPerDay.countAll - totalCouriersPerDay.countConfirmed}
                                                </span>
                                            </strong>
                                        </div>
                                    )}
                                </>
                            </NavLink>
                        </NavItem>)
                    })}
                </Nav>
                <TabContent activeTab={currentDate}>
                    {Object.keys(scheduleWeek).map((day) => (
                        <TabPane key={scheduleWeek[day]} tabId={scheduleWeek[day]}>
                            {currentDate === scheduleWeek[day] &&
                            <Card body>
                                {mode === 'add' && (
                                    <ScheduleDateAdd
                                        id={id}
                                        date={currentDate}
                                        cityId={cityId}
                                    />
                                )}
                                {mode === 'edit' && (
                                    <ScheduleDateEdit
                                        id={id}
                                        date={currentDate}
                                        cityId={cityId}
                                    />
                                )}
                                {mode === null &&
                                <ScheduleDate
                                    id={id}
                                    date={currentDate}
                                    scheduleWeek={scheduleWeek}
                                    isActive={currentIsDraft}
                                    cityId={cityId}
                                    confirmCouriers={confirmCouriers}
                                />}
                            </Card>}
                        </TabPane>
                    ))}
                </TabContent>
            </>
        )
    }
}

export default Schedule;
