import * as types from "../constants/employeePhotos"
import {IAction} from "../actions";

export interface deleteEmployeePhotosState {
  data: {
    id: string;
    documentId: string;
  } | null;
  loading: boolean;
  error: string;
}

const initialState: deleteEmployeePhotosState = {
  data: null,
  loading: false,
  error: '',
}

export default function employeePhotosDelete(
  state = initialState,
  action: IAction<symbol, any>,
): deleteEmployeePhotosState {
  switch (action.type) {
    case types.DELETE_EMPLOYEE_PHOTOS_REQUEST:
      return {
        data: null,
        loading: true,
        error: ''
      }
    case types.DELETE_EMPLOYEE_PHOTOS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
        error: ''
      }
    case types.DELETE_EMPLOYEE_PHOTOS_ERROR:
      return {
        data: null,
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}
