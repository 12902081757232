import {call, put, takeLatest} from 'redux-saga/effects';
import {GET_PAYMENTS} from "../constants/payments";
import {getPaymentsError, getPaymentsRequest, getPaymentsSuccess} from '../actions/payments';
import {IPayment, IPaymentFilterRequest, IPaymentSortRequest} from "../../models/Payment";
import {PaymentService} from "../../services/PaymentService";


type getPaymentsType = {
    type: typeof GET_PAYMENTS,
    payload?: IPaymentFilterRequest & IPaymentSortRequest
}

function* getPaymentsSaga({payload}:getPaymentsType) {
    try {
        yield put(getPaymentsRequest());

        const service = new PaymentService();
        const promise = yield call(service.getPayments, payload);
        const response:IPayment[] = promise.data || [];

        yield put(getPaymentsSuccess(response));

    } catch (error) {
        yield put(getPaymentsError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetPayments() {
    yield takeLatest(GET_PAYMENTS, getPaymentsSaga);
}
