import {Api} from "./Api";
import {
    IReportCompanyFilterRequest,
    IReportCompanySortRequest,
    IReportCourierFilterRequest,
    IReportCourierSortRequest,
    IReportEmenuFilterForm,
} from "../models/Report";


export class ReportService {
    getReportCompanies(page:number = 1, form?:IReportCompanyFilterRequest & IReportCompanySortRequest) {
        return new Api().call(`/v1/manage/report/partner?page=${page}`, {method: 'POST', data:form});
    }
    getReportCouriers(page:number = 1, form?:IReportCourierFilterRequest & IReportCourierSortRequest) {
        return new Api().call(`/v1/manage/report/courier?page=${page}`, {method: 'POST', data:form});
    }
    getReportEmenu(cityId: number | null = null, page = 1, form?: IReportEmenuFilterForm) {
        return new Api().call(`/v1/manage/report/emenu?page=${page}${cityId ? `&cityId=${cityId}` : ''}`, {method: 'POST', data:form})
    }
}
