import {
    GET_REPORT_EMENU,
    GET_REPORT_EMENU_REQUEST,
    GET_REPORT_EMENU_SUCCESS,
    GET_REPORT_EMENU_ERROR
} from "../constants/reportEmenu"
import {IAction} from "./index";
import {IReportEmenu, IReportEmenuFilterForm} from "../../models/Report";

export interface IActionReportEmenu extends IAction<symbol, any> {

}

export const getReportEmenuRequest = () => {
    return {
        type: GET_REPORT_EMENU_REQUEST
    }
}

export const getReportEmenuSuccess = (data: IReportEmenu[], total: number, by: number) => {
    return {
        type: GET_REPORT_EMENU_SUCCESS,
        payload: {
            data,
            total,
            by
        }
    }
}

export const getReportEmenuError = (payload: string) => {
    return {
        type: GET_REPORT_EMENU_ERROR,
        payload
    }
}

export const getReportEmenu = (cityId: number | null = null, page?: number, form?: IReportEmenuFilterForm) => {
    return {
        type: GET_REPORT_EMENU,
        payload: {
            page,
            form,
            cityId
        }
    }
}
