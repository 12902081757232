import React, {PureComponent} from 'react';
import Dropdown from "../UI/Dropdown";
import {Alert, Button, Col, Form, Row, Spinner} from "reactstrap";
import {Formik} from 'formik';
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import {format, parse} from "date-fns";
import {ru} from "date-fns/locale";
import {ISchedule, IScheduleCopyFormRequest} from "../../models/Schedule";


const schema = yup.object({
    id: yup.number().required(requiredMessage())
});

type ScheduleCopyType = {
    copyScheduleForm: (id:number, form: IScheduleCopyFormRequest) => void
    copyLoading:boolean
    copyError:string
    dictionaryPublishedSchedules:ISchedule[]
    date:IScheduleCopyFormRequest
}

class ScheduleCopy extends PureComponent<ScheduleCopyType> {
    render() {
        const {copyScheduleForm, copyLoading, copyError, dictionaryPublishedSchedules, date} = this.props;

        const dictionaryPublishedScheduleOptions = dictionaryPublishedSchedules.map(({id, dateStart, dateEnd})=>{
            const label = format(parse(dateStart, 'yyyy-MM-dd', new Date()), 'd MMMM', {locale: ru}) + ' - ' + format(parse(dateEnd, 'yyyy-MM-dd', new Date()), 'd MMMM', {locale: ru});
            return {label, value:id}
        });

        return (
            <Formik
                validationSchema={schema}
                initialValues={{id: dictionaryPublishedScheduleOptions[0].value}}
                onSubmit={(values) => {
                    copyScheduleForm(values.id, date);
                }}
            >
                {({setFieldValue, values, handleSubmit, touched, errors}) => (
                    <Form onSubmit={handleSubmit}>
                        <Row form>
                            <Col>
                                <Dropdown
                                    name={'id'}
                                    value={values.id}
                                    options={dictionaryPublishedScheduleOptions}
                                    placeholder={'Выберите день недели'}
                                    onChange={(value) => setFieldValue('id', value)}
                                    invalid={touched.id && !!errors.id}
                                />
                            </Col>
                            <Col md={'auto'}>
                                <Button type={'submit'} color={'primary'} disabled={copyLoading}>
                                    {copyLoading &&
                                    (<Spinner
                                        size="sm"
                                    />)}
                                    Копировать
                                </Button>
                            </Col>
                        </Row>
                        {copyError &&
                        (<Alert color={'danger'} className={'mt-3'}>
                            {copyError}
                        </Alert>)}
                    </Form>
                )}
            </Formik>
        )
    }
}

export default ScheduleCopy;
