import React, {Component} from "react";
import PickCity from "./PickCity";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {changeBonusRate, getBonusRate} from "../store/actions/bonusRate";
import {IAuth} from "../models/Auth";
import Loader from "../components/UI/Loader";
import {Button, Card, Input, Spinner} from "reactstrap";
import Table from "../components/UI/Table";
import {employeeTypeOptions} from "../utils/employees";
import {FieldArray, Form, Formik} from "formik";
import NumberFormat from "react-number-format";
import Icon from "../components/UI/Icon";
import {bonusRateCityIdReSelector} from "../store/reselectors/bonusRate";
import _ from "lodash";
import {Redirect} from "react-router-dom";
import Error from "../components/UI/Error";
import Empty from "../components/UI/Empty";
import {ICourierBonusRate, ICourierBonusRateResponse} from "../models/Courier";

type BonusRateType = {
    getBonusRate: (cityId: number) => void
    changeBonusRate: (form: {cityId: number, typeId: number, rates: ICourierBonusRateResponse[]}) => void
    data: ICourierBonusRate[]
    loading: boolean
    error: string
    changeLoading: boolean
    changeTypeId: number | null
    cityId?: number,
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class BonusRateContainer extends Component<BonusRateType & CitiesType> {
    componentDidMount() {
        const {getBonusRate, cityId} = this.props;
    
        if (typeof cityId === 'number') {
            getBonusRate(cityId);
        }
    }

    componentDidUpdate(prevProps: Readonly<BonusRateType & CitiesType>, prevState: Readonly<{}>) {
        const {getBonusRate, cityId} = this.props;

        if (typeof cityId === 'number' && prevProps.cityId !== cityId) {
            getBonusRate(cityId);
        }
    }

    render() {
        const {data, loading, error, getBonusRate, changeBonusRate, changeLoading, changeTypeId, allowedCities, cityId} = this.props;

        if (loading) return <Loader/>

        if (!cityId) {
            return <Redirect to={`/settings/bonus-rate?cityId=${allowedCities[0].id}`}/>
        }

        return (
            <>
                <div className={'mb-2'}>
                    <PickCity/>
                </div>
                <Card body>
                    {data.length > 0 && data.map((props: ICourierBonusRate) => {
                        const {typeId} = props;
                        const courierType = employeeTypeOptions.find(x => x.value === props.typeId)?.label;
                        const isChanging = typeId === changeTypeId && changeLoading;
                        return (
                            <Formik
                                key={typeId}
                                onSubmit={(values) => {
                                    const form = {
                                        cityId,
                                        typeId: values.typeId,
                                        rates: values.rates.map(({minQuantityOrder, price}: ICourierBonusRateResponse) => {
                                            return {
                                                minQuantityOrder: parseFloat(String(minQuantityOrder)),
                                                price: parseFloat(String(price))
                                            }
                                        })
                                    }

                                    changeBonusRate(form);
                                }}
                                initialValues={props}
                            >
                                {({handleSubmit, handleChange, values, dirty}) => (
                                    <Form onSubmit={handleSubmit}>
                                        <FieldArray name={'rates'}>
                                            {({remove, push}) => (
                                                <div>
                                                    <h5 className={'mb-1 mt-4'}>{courierType}</h5>
                                                    <Table striped size={'large'}>
                                                        <tbody>
                                                        <tr>
                                                            <th style={{width: '47.5%'}}>Количество заказов</th>
                                                            <th style={{width: '47.5%'}}>Бонусы</th>
                                                            <th style={{width: '5%'}}/>
                                                        </tr>
                                                        {values.rates.map((props: ICourierBonusRateResponse, index: number) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <NumberFormat
                                                                        name={`rates.[${index}].minQuantityOrder`}
                                                                        value={props.minQuantityOrder}
                                                                        onChange={handleChange}
                                                                        customInput={Input}
                                                                        allowNegative={false}
                                                                        disabled={isChanging}
                                                                        required={true}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <NumberFormat
                                                                        name={`rates.[${index}].price`}
                                                                        value={props.price}
                                                                        onChange={handleChange}
                                                                        customInput={Input}
                                                                        allowNegative={false}
                                                                        disabled={isChanging}
                                                                        required={true}
                                                                    />
                                                                </td>
                                                                <td className={'text-right'}>
                                                                    <Button color={'outline-light'} size={'sm'}
                                                                            onClick={() => remove(index)}
                                                                            disabled={isChanging}
                                                                    >
                                                                        <Icon name={'delete'} color={'gray-500'}/>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </Table>
                                                    <div className={'mt-2 text-right'}>
                                                        <Button
                                                            onClick={() => push({minQuantityOrder: 0, price: 0})}
                                                            color={'light'}
                                                            disabled={isChanging}
                                                        >
                                                            Добавить
                                                        </Button>
                                                        <Button
                                                            className={'ml-2'}
                                                            color={'primary'}
                                                            type={"submit"}
                                                            disabled={isChanging || !dirty}
                                                        >
                                                            {isChanging &&
                                                            (<Spinner
                                                                size="sm"
                                                            />)}
                                                            Сохранить
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </FieldArray>
                                    </Form>
                                )}
                            </Formik>
                        )
                    })}
                    {error && <Error
                        error={error}
                        refresh={() => getBonusRate(cityId)}
                    />}
                    {!data.length && !loading && !error &&
                    <Empty>
                        <h5 className={'mb-0'}>Список бонусных сеток пуст</h5>
                    </Empty>}
                </Card>
            </>
        )
    }
}

const mapStateToProps = ({bonusRate, bonusRateChange, auth}: AppState, props: RouteComponentProps) => {
    const {data, loading, error} = bonusRate;

    const changeLoading = bonusRateChange.loading;
    const changeTypeId = bonusRateChange.data;
    const {user} = auth;

    return {
        data,
        cityId: bonusRateCityIdReSelector(props),
        loading,
        error,
        changeLoading,
        changeTypeId,
        allowedCities: _.get(user, 'allowedCities', [])
    }
}

const mapDispatchToProps = {
    getBonusRate,
    changeBonusRate
}

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(BonusRateContainer);
