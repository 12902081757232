import {
    GET_DICTIONARY_CITY_EAGERS_REQUEST,
    GET_DICTIONARY_CITY_EAGERS_SUCCESS,
    GET_DICTIONARY_CITY_EAGERS_ERROR,
    GET_DICTIONARY_CITY_EAGERS
} from '../constants/dictionaryCityEagers'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryCityEagers extends IAction<symbol, any> {

}

export const getDictionaryCityEagersRequest = () => {
    return {
        type: GET_DICTIONARY_CITY_EAGERS_REQUEST
    }
};

export const getDictionaryCityEagersSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_CITY_EAGERS_SUCCESS,
        payload
    }
};

export const getDictionaryCityEagersError = (payload: string) => {
    return {
        type: GET_DICTIONARY_CITY_EAGERS_ERROR,
        payload
    }
};

export const getDictionaryCityEagers = () => {
    return {
        type: GET_DICTIONARY_CITY_EAGERS
    };
};

