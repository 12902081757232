import {GET_PENALTIES, GET_PENALTIES_REQUEST, GET_PENALTIES_SUCCESS, GET_PENALTIES_ERROR} from '../constants/penalties'
import {IAction} from "./index";
import {IPenalty, IPenaltyFilterRequest, IPenaltySortRequest} from "../../models/Penalty";


export interface IActionPenalties extends IAction<symbol, any> {

}

export const getPenaltiesRequest = () => {
    return {
        type: GET_PENALTIES_REQUEST
    }
};

export const getPenaltiesSuccess = (payload: IPenalty[]) => {
    return {
        type: GET_PENALTIES_SUCCESS,
        payload
    }
};

export const getPenaltiesError = (payload: string) => {
    return {
        type: GET_PENALTIES_ERROR,
        payload
    }
};

export const getPenalties = (payload?: IPenaltyFilterRequest & IPenaltySortRequest) => {
    return {
        type: GET_PENALTIES,
        payload
    };
};
