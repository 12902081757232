import {createSelector} from "reselect";
import {AppState} from "../reducers";
import _ from "lodash";


const scheduleDateSelector = (state: AppState) => {
    return state.scheduleDate.data
};

export const scheduleDateReSelector = createSelector(
    [scheduleDateSelector],
    (data) => {
        return _.groupBy(data, 'pointId')
    }
);
