import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import Pagination from "../components/UI/Pagination";
import queryString from "query-string";
import _ from "lodash";
import TableGrid from "../components/UI/TableGrid";
import {
    companyStatusOptions,
    COMPANY_STATUS_BLOCKED,
    COMPANY_STATUS_TEMPORARILY_ON,
    COMPANY_STATUS_ACTIVE,
    companyStatuses
} from "../utils/companies";
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import Icon from "../components/UI/Icon";
import {getCompanies} from "../store/actions/companies";
import {ICompany, ICompanyFilterRequest, ICompanySortRequest} from "../models/Company";
import {
    companiesFilterReSelector,
    companiesSortReSelector,
    companiesPageReSelector
} from "../store/reselectors/companies";
import {closeCompanyForm, openCompanyForm} from "../store/actions/companyForm";
import CompanyDelete from "./CompanyDelete";
import Empty from "../components/UI/Empty";
import {
    accessCompanyBranchShow,
    accessCompanyContactShow,
    accessCompanyDelete,
    accessCompanyEdit
} from "../utils/user-accesses";
import {format, parseISO} from "date-fns";


type CompaniesType = {
    getCompanies: (page?: number, form?: ICompanyFilterRequest & ICompanySortRequest) => void
    openCompanyForm: (mode: 'delete', modeId: number) => void
    closeCompanyForm: () => void
    page: number
    data: ICompany[]
    total: number
    by: number
    loading: boolean
    error: string
    filter?: ICompanyFilterRequest,
    sort?: ICompanySortRequest
    accesses?: string[]
    mode: 'delete' | null
    modeId: number | null
}


class CompaniesContainer extends Component<CompaniesType & RouteComponentProps> {

    componentDidMount() {
        const {getCompanies, page, filter, sort} = this.props;
        getCompanies(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
    }

    componentDidUpdate(prevProps: Readonly<CompaniesType & RouteComponentProps>) {
        const {page, filter, sort, getCompanies} = this.props;
        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter) || !_.isEqual(prevProps.sort, sort)) {
            getCompanies(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
        }
    }

    changeHandler = <T extends any>(form: T) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const params = _.pick(queryParams, ['id', 'name', 'status', 'legalName', 'contract', 'contractDateStart', 'contractDateFinish', 'orderBy']);

        const filter = _.pickBy({...params, ...form}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        const nextQueryParams = queryString.stringify(filter);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    filterHandler = (form: ICompanyFilterRequest) => {
        this.changeHandler<ICompanyFilterRequest>(form)
    };

    sortHandler = (form: ICompanySortRequest) => {
        const orderBy = form ? Object.keys(form)[0] + '_' + Object.values(form)[0] : undefined;
        this.changeHandler<{ [key: string]: string | undefined }>({orderBy})
    };

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    render() {
        const {
            data, loading, error, page, total, by, getCompanies, filter,
            sort, mode, openCompanyForm, closeCompanyForm, accesses, history, modeId
        } = this.props;

        const filterData = filter;
        const sortData = sort && sort.orderBy;

        const editButtonWidth = Array.isArray(accesses) && accesses.includes(accessCompanyEdit) ? 42 : 0;
        const deleteButtonWidth = Array.isArray(accesses) && accesses.includes(accessCompanyDelete) ? 42 : 0;
        const showButtonWidth = Array.isArray(accesses) && (accesses.includes(accessCompanyContactShow) || accesses.includes(accessCompanyBranchShow)) ? 42 : 0;

        return (
            <>
                <div className={'mb-3'}>
                    <TableGrid
                        sourceData={data}
                        filterData={filterData}
                        sortData={sortData}
                        columns={[
                            {
                                label: 'ID',
                                key: 'id',
                                filter: {
                                    type: 'number',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                            },
                            {
                                label: 'Название',
                                key: 'name',
                                filter: {
                                    type: 'text',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                            },
                            {
                                label: 'Юридическое название',
                                key: 'legalName',
                                filter: {
                                    type: 'text',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                width: '15%'
                            },
                            {
                                label: '№ договора',
                                key: 'contract',
                                filter: {
                                    type: 'text',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                width: '15%'
                            },
                            {
                                label: 'Дата договора',
                                key: 'contractDate',
                                filter: {
                                    type: 'date',
                                    isDoubleField: true,
                                    startPostFix: 'Start',
                                    endPostFix: 'Finish',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                render: (item: ICompany) => {
                                    const {contractDate} = item;
                                    return contractDate && format(parseISO(contractDate), 'dd-MM-yyyy')
                                },
                                width: '25%'
                            },
                            {
                                label: 'Статус',
                                key: 'status',
                                filter: {
                                    type: 'select',
                                    options: companyStatusOptions,
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                render: (item: ICompany) => {
                                    switch (item.status) {
                                        case COMPANY_STATUS_BLOCKED:
                                            return (
                                                <div
                                                    className={'semi-bold red'}>{companyStatuses[COMPANY_STATUS_BLOCKED]}</div>
                                            );
                                        case COMPANY_STATUS_TEMPORARILY_ON:
                                            return (
                                                <div
                                                    className={'semi-bold blue'}>{companyStatuses[COMPANY_STATUS_TEMPORARILY_ON]}</div>
                                            );
                                        case COMPANY_STATUS_ACTIVE:
                                            return (
                                                <div
                                                    className={'semi-bold green'}>{companyStatuses[COMPANY_STATUS_ACTIVE]}</div>
                                            );

                                    }
                                },
                                width: '15%'
                            },
                            {
                                label: 'Действия',
                                render: (item: ICompany) => {
                                    return (
                                        <ButtonGroup>
                                            {Array.isArray(accesses) && accesses.includes(accessCompanyEdit) &&
                                            <Button
                                                onClick={() => item && item.id && history.push(`/companies/edit/${item.id}`)}
                                                size={'sm'} color={'outline-light'}>
                                                <Icon name={'edit'} color={'gray-500'}/>
                                            </Button>}
                                            {Array.isArray(accesses) && (accesses.includes(accessCompanyContactShow) || accesses.includes(accessCompanyBranchShow)) &&
                                            <Button
                                                onClick={() => item && item.id && history.push(`/companies/${item.id}`)}
                                                size={'sm'} color={'outline-light'}>
                                                <Icon name={' remove_red_eye'} color={'gray-500'}/>
                                            </Button>}
                                            {Array.isArray(accesses) && accesses.includes(accessCompanyDelete) &&
                                            <Button
                                                onClick={() => item && item.id && openCompanyForm('delete', item.id)}
                                                size={'sm'} color={'outline-light'}>
                                                <Icon name={'delete'} color={'gray-500'}/>
                                            </Button>}
                                        </ButtonGroup>
                                    )
                                },
                                width: 24 + editButtonWidth + deleteButtonWidth + showButtonWidth
                            }
                        ]}
                    />
                    {loading && <Loader/>}
                    {error && <Error error={error}
                                     refresh={() => getCompanies(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)}/>}
                    {!data.length && !loading && !error && <Empty>
                        <h3>Список контрагентов пуст</h3>
                        <p className={'mb-0'}>Чтобы добавить контрагента, нажмите кнопку «Добавить контрагента»</p>
                    </Empty>}
                </div>
                <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>

                {modeId !== null && <Modal isOpen={mode === 'delete'} toggle={closeCompanyForm}>
                    <ModalHeader toggle={closeCompanyForm}>
                        Удалить контрагента?
                    </ModalHeader>
                    <ModalBody>
                        <CompanyDelete id={modeId} cancelHandler={closeCompanyForm}/>
                    </ModalBody>
                </Modal>}

            </>
        )
    }
}


const mapStateToProps = (state: AppState, props: RouteComponentProps) => {
    const {data, total, by, loading, error} = state.companies;
    const {mode, modeId} = state.companyForm;
    const {user} = state.auth;

    return {
        data,
        filter: companiesFilterReSelector(props),
        sort: companiesSortReSelector(props),
        page: companiesPageReSelector(props),
        loading,
        error,
        total,
        by,
        mode,
        modeId,
        accesses: user !== null ? user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getCompanies,
    openCompanyForm,
    closeCompanyForm
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(CompaniesContainer);
