import React, {PureComponent} from 'react';
import {Row, Col, Table} from "reactstrap";
import {ICourierStatistics} from "../../models/Courier";


type CourierStatisticsType = {
    data: ICourierStatistics
}

class CourierStatistics extends PureComponent<CourierStatisticsType> {
    render() {
        const {data} = this.props;
        const {countSuccessOrders, workHours, orderAmount, bonusAmount, hoursAmount, penaltyAmount, totalAmount, distance} = data;

        const km = ((distance || 0) / 1000).toFixed(1) + " км";

        return (
            <>
                <Row>
                    <Col md={'auto'}>
                        <div className={'gray-500'}>
                            Выполненные заказы
                        </div>
                        {countSuccessOrders}
                    </Col>
                    <Col md={'auto'}>
                        <div className={'gray-500'}>
                            Отработанные часы
                        </div>
                        {workHours}
                    </Col>
                    <Col md={'auto'}>
                        <div className={'gray-500'}>
                            Пройденная дистанция
                        </div>
                        {km}
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col md={'auto'} lg={'3'}>
                        <Table bordered={false}>
                            <tbody>
                            <tr>
                                <td>
                                    Заказы
                                </td>
                                <td>
                                    {orderAmount} ₸
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Рабочие часы
                                </td>
                                <td>
                                    {hoursAmount} ₸
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Дневные бонусы
                                </td>
                                <td>
                                    {bonusAmount} ₸
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Штрафы
                                </td>
                                <td>
                                    {penaltyAmount} ₸
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ВСЕГО
                                </td>
                                <td>
                                    {totalAmount} ₸
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        )
    }
}

export default CourierStatistics;
