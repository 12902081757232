import {
    CHANGE_COURIER_RATE, CHANGE_COURIER_RATE_ERROR,
    CHANGE_COURIER_RATE_REQUEST, CHANGE_COURIER_RATE_SUCCESS,
    GET_COURIER_RATE,
    GET_COURIER_RATE_ERROR,
    GET_COURIER_RATE_REQUEST,
    GET_COURIER_RATE_SUCCESS
} from "../constants/courierRate";
import {ICourierRate, ICourierRateForm} from "../../models/Courier";
import {IAction} from "./index";

export interface IActionCourierRate extends IAction<symbol, any> {

}

export const getCourierRate = (cityId: number) => {
    return {
        type: GET_COURIER_RATE,
        payload: {cityId}
    }
}

export const getCourierRateRequest = () => {
    return {
        type: GET_COURIER_RATE_REQUEST
    }
}

export const getCourierRateSuccess = (data: ICourierRate[]) => {
    return {
        type: GET_COURIER_RATE_SUCCESS,
        payload: {data}
    }
}

export const getCourierRateError = (payload: string) => {
    return {
        type: GET_COURIER_RATE_ERROR,
        payload
    }
}
export const changeCourierRate = (cityId: number, form: ICourierRateForm[]) => {
    return {
        type: CHANGE_COURIER_RATE,
        payload: {cityId, form}
    }
}

export const changeCourierRateRequest = () => {
    return {
        type: CHANGE_COURIER_RATE_REQUEST
    }
}

export const changeCourierRateSuccess = () => {
    return {
        type: CHANGE_COURIER_RATE_SUCCESS
    }
}

export const changeCourierRateError = (payload: string) => {
    return {
        type: CHANGE_COURIER_RATE_ERROR,
        payload
    }
}
