import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import queryString from "query-string";
import {deleteNotification, getNotifications} from "../store/actions/notifications";
import _ from "lodash";
import Empty from "../components/UI/Empty";
import {
    notificationPageReSelector,
    notificationsFilterReSelector,
    notificationsSortReSelector
} from "../store/reselectors/notififcations";
import {INotification, INotificationFilterRequest, INotificationSortRequest} from "../models/Notifications";
import TableGrid from "../components/UI/TableGrid";
import {Button} from "reactstrap";
import {format, parseISO} from "date-fns";
import {getCityOptions, getCityValue} from "../utils/cities";
import {IAuth} from "../models/Auth";
import {Link} from "react-router-dom";
import Pagination from "../components/UI/Pagination";


type NotificationsType = {
    getNotifications: (page?: number, form?: INotificationFilterRequest) => void
    deleteNotification: (id: number) => void
    data: INotification[]
    page: number
    total: number
    by: number
    loading: boolean
    error: string
    filter?: INotificationFilterRequest
    sort?: INotificationSortRequest
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class NotificationsContainer extends Component<NotificationsType & CitiesType & RouteComponentProps> {

    componentDidMount() {
        const {getNotifications, filter, sort, page} = this.props;
        getNotifications(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
    }

    componentDidUpdate(prevProps: Readonly<NotificationsType & RouteComponentProps>) {
        const {filter, getNotifications, sort, page} = this.props;
        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter) || !_.isEqual(prevProps.sort, sort)) {
            getNotifications(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
        }
    }

    changeHandler = <T extends any>(form: T) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const params = _.pick(queryParams, ['id', 'description', 'courierId', 'orderId', 'cityId', 'status', 'orderBy']);

        const filter = _.pickBy({...params, ...form}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        const nextQueryParams = queryString.stringify(filter);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    filterHandler = (form: INotificationFilterRequest) => {
        this.changeHandler<INotificationFilterRequest>(form)
    };

    sortHandler = (form: INotificationSortRequest) => {
        const orderBy = form ? Object.keys(form)[0] + '_' + Object.values(form)[0] : undefined;
        this.changeHandler<{ [key: string]: string | undefined }>({orderBy})
    };

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    }

    render() {
        const {
            data, loading, error, getNotifications, filter, sort, allowedCities, deleteNotification,
            total, by, page
        } = this.props;
        const cityOptions = getCityOptions(allowedCities);

        const filterData = filter;
        const sortData = sort && sort.orderBy;
        return (
            <>
                <div className={"mb-3"}>
                    <TableGrid
                        sourceData={data}
                        sortData={sortData}
                        filterData={filterData}
                        columns={[
                            {
                                label: 'ID',
                                key: 'id',
                                filter: {
                                    type: 'number',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                width: 86
                            },
                            {
                                label: 'Описание',
                                key: 'description',
                                sort: {
                                    handler: this.sortHandler
                                },
                            },
                            {
                                label: 'ID заказа',
                                key: 'orderId',
                                filter: {
                                    type: 'number',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                render: ({orderId}: INotification) => {
                                    return `№ ${orderId}`
                                },
                                width: 120
                            },
                            {
                                label: 'Курьер',
                                key: 'courierName',
                                sort: {
                                    handler: this.sortHandler
                                },
                                render: ({courierId, courierName}: INotification) => {
                                    return <Link to={`/couriers/${courierId}`} target={'_blank'}>{courierName}</Link>
                                }
                            },
                            {
                                label: 'Город',
                                key: 'cityId',
                                filter: {
                                    type: 'select',
                                    options: cityOptions,
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                render: ({cityId}: INotification) => {
                                    return getCityValue(allowedCities, cityId)
                                },
                                width: 138
                            },
                            {
                                label: 'Дата',
                                key: 'createdAt',
                                render: ({createdAt}: INotification) => {
                                    return createdAt && format(parseISO(createdAt), 'dd-MM-yyyy HH:mm')
                                },
                                width: 140
                            },
                            {
                                label: 'Статус',
                                key: 'status',
                                filter: {
                                    type: 'select',
                                    options: [
                                        {label: 'Активно', value: true},
                                        {label: 'Не активно', value: false}
                                    ],
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                render: ({status}: INotification) => {
                                    return status ? <div className={'semi-bold green'}>Активно</div> :
                                        <div className={'semi-bold red'}>Не активно</div>
                                },
                                width: 138
                            },
                            {
                                label: '',
                                render: ({id, status}: INotification) => {
                                    return <Button disabled={!status} className={!status ? 'hidden' : ''}
                                                   onClick={() => deleteNotification(id)} color={'light'}>
                                        Скрыть
                                    </Button>
                                },
                                width: 86
                            }
                        ]}
                    />
                </div>
                {loading && <Loader/>}
                {error && <Error
                    error={error}
                    refresh={() => getNotifications(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)}
                />}
                {!data.length && !loading && !error &&
                <Empty>
                    <h5 className={'mb-0'}>Список уведомлений пуст</h5>
                </Empty>}
                <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>
            </>
        )
    }
}


const mapStateToProps = ({notifications, auth}: AppState, props: RouteComponentProps) => {
    const {data, loading, error, total, by} = notifications;
    const {user} = auth;
    return {
        data,
        filter: notificationsFilterReSelector(props),
        sort: notificationsSortReSelector(props),
        page: notificationPageReSelector(props),
        loading,
        error,
        total,
        by,
        allowedCities: _.get(user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    getNotifications,
    deleteNotification
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(NotificationsContainer);
