import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import styles from './Breadcrumbs.module.scss';
import classNames from 'classnames'


type BreadcrumbType = {
    name: string,
    url:string
};

type BreadcrumbsType = {
    links: BreadcrumbType[]
    className?: string
};

class Breadcrumbs extends PureComponent<BreadcrumbsType> {
    render() {
        const {links, className} = this.props;
        return (
            <ul className={classNames(styles.breadcrumbs, 'mb-4', className)}>
                {Array.isArray(links) && links.map(({name, url}) => (
                    <BreadcrumbsItem key={name + url} name={name} url={url}/>
                ))}
            </ul>
        );
    }
}


class BreadcrumbsItem extends PureComponent<BreadcrumbType> {
    render() {
        const {name, url} = this.props;
        return (
            <li>
                <Link to={url}>{name}</Link>
            </li>
        );
    }
}

export default Breadcrumbs;
