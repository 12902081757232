import {call, put, takeLatest} from 'redux-saga/effects';
import {removeUrlParams} from "../../utils/history";
import {getReportCompaniesError, getReportCompaniesRequest, getReportCompaniesSuccess} from '../actions/reportCompanies';
import {Pagination} from "../../services/Api";
import {GET_REPORT_COMPANIES} from "../constants/reportCompanies";
import {IReportCompany, IReportCompanyFilterRequest, IReportCompanySortRequest} from "../../models/Report";
import {ReportService} from "../../services/ReportService";



type getReportCompaniesType = {
    type: typeof GET_REPORT_COMPANIES,
    payload:{
        page?:number,
        form?: IReportCompanyFilterRequest & IReportCompanySortRequest
    }
}

function* getReportCompaniesSaga({payload}:getReportCompaniesType) {
    try {
        yield put(getReportCompaniesRequest());

        const service = new ReportService();

        const promise = yield call(service.getReportCompanies, payload.page, payload.form);

        const data:IReportCompany[] = promise.data || [];
        const pagination = new Pagination(promise.headers).getData();

        yield put(getReportCompaniesSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
        yield put(getReportCompaniesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetReportCompanies() {
    yield takeLatest(GET_REPORT_COMPANIES, getReportCompaniesSaga);
}
