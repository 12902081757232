import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {
    closePaymentForm,
    openPaymentForm,
} from "../store/actions/paymentForm";
import PaymentAdd from "./PaymentAdd";
import {IDictionary} from "../models/Dictionary";


type PaymentNavbarType = {
    closePaymentForm: () => void
    openPaymentForm: (mode:'add') => void
    mode: 'add' | 'edit' | 'delete' | null
    dictionaryCompanies:IDictionary[]
}

class PaymentNavbarContainer extends Component<PaymentNavbarType> {

    render() {
        const {
            closePaymentForm,
            mode,
            openPaymentForm,
            dictionaryCompanies
        } = this.props;

        return (
            <div className={'mb-2'}>
                <Button
                    color={'primary'}
                    onClick={() => openPaymentForm('add')}>
                    Добавить оплату
                </Button>

                <Modal isOpen={mode === 'add'} toggle={closePaymentForm}>
                    <ModalHeader toggle={closePaymentForm}>
                        Новая оплата
                    </ModalHeader>
                    <ModalBody>
                        <PaymentAdd dictionaryCompanies={dictionaryCompanies} cancelHandler={closePaymentForm}/>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = (state: AppState) => {
    const {mode} = state.paymentForm;

    return {
        mode
    }
};

const mapDispatchToProps = {
    closePaymentForm,
    openPaymentForm
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentNavbarContainer);
