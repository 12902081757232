import {
    GET_ARCHIVE_ORDERS_SUMMARY_ERROR,
    GET_ARCHIVE_ORDERS_SUMMARY_REQUEST,
    GET_ARCHIVE_ORDERS_SUMMARY_SUCCESS
} from "../constants/archiveOrders";
import {IActionArchiveOrdersSummary} from "../actions/archiveOrders";
import {IOrderArchiveSummary} from "../../models/Order";

export interface IArchiveOrdersSummaryState {
    loading: boolean,
    error: string,
    data: IOrderArchiveSummary[]
}

const initialState: IArchiveOrdersSummaryState = {
    data: [],
    loading: false,
    error: ''
}

export default function archiveOrdersSummary(state = initialState, action: IActionArchiveOrdersSummary): IArchiveOrdersSummaryState {
    switch (action.type) {
        case GET_ARCHIVE_ORDERS_SUMMARY_REQUEST:
            return{
                data: [],
                loading: true,
                error: ''
            };
        case GET_ARCHIVE_ORDERS_SUMMARY_SUCCESS:
            const {data} = action.payload
            return {
                data,
                loading: false,
                error: ''
            };
        case GET_ARCHIVE_ORDERS_SUMMARY_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}