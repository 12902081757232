import {call, put, takeLatest} from 'redux-saga/effects';
import {getDictionaryContactTypesError, getDictionaryContactTypesRequest, getDictionaryContactTypesSuccess} from '../actions/dictionaryContactTypes';
import {GET_DICTIONARY_CONTACT_TYPES} from "../constants/dictionaryContactTypes";
import {IDictionary} from "../../models/Dictionary";
import { DictionaryService } from '../../services/DictionaryService';



function* getDictionaryContactTypesSaga() {
    try {
        yield put(getDictionaryContactTypesRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryContactTypes);

        const response:IDictionary[] = promise.data || [];

        yield put(getDictionaryContactTypesSuccess(response));


    } catch (error) {
        yield put(getDictionaryContactTypesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryContactTypes() {
    yield takeLatest(GET_DICTIONARY_CONTACT_TYPES, getDictionaryContactTypesSaga);
}
