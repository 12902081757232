import {createSelector} from "reselect";
import queryString from 'query-string';
import _ from 'lodash';


const companiesUrlSelector = (props: any) => {
    return props.location.search
};

export const companiesSortReSelector = createSelector(
    [companiesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['orderBy']);

        let orderBy = {} as {[key:string]: string};
        if(params.orderBy &&  typeof params.orderBy === "string") {
            const orderByArray = params.orderBy.split('_');
            orderBy[orderByArray[0]] = orderByArray[1] + '_' + orderByArray[2];
        }

        const sort = _.pickBy(orderBy, function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(sort) ? {orderBy:sort} : undefined
    }
);

export const companiesFilterReSelector = createSelector(
    [companiesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['id', 'name', 'status', 'legalName', 'contract', 'contractDateStart', 'contractDateFinish']);

        const id = params.id? +params.id : undefined;
        const status = params.status? +params.status : undefined;

        const filter = _.pickBy({...params, id, status}, function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(filter) ? filter : undefined
    }
);

export const companiesPageReSelector = createSelector(
    [companiesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.page ? +getParams.page : 1
    }
);

