import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import Empty from "../components/UI/Empty";
import {Redirect} from "react-router-dom";
import Schedules from "../components/Schedules";
import {getSchedules} from "../store/actions/schedules";
import {IAuth} from "../models/Auth";
import {ISchedule} from "../models/Schedule";
import Pagination from "../components/UI/Pagination";
import queryString from "query-string";
import {schedulesCityIdReSelector, schedulesPageReSelector} from "../store/reselectors/schedules";
import _ from "lodash";


type SchedulesType = {
    getSchedules: (page?: number, cityId?: number) => void
    data: ISchedule[]
    loading: boolean
    error: string
    cityId?: number
    page: number
    by: number
    total: number
}
type CitiesType = Pick<IAuth, 'allowedCities'>;

class SchedulesContainer extends Component<SchedulesType & CitiesType & RouteComponentProps> {

    componentDidMount() {
        const {cityId, getSchedules, page} = this.props;
        getSchedules(page, cityId)
    }

    componentDidUpdate(prevProps: Readonly<SchedulesType & RouteComponentProps>) {
        const {cityId, getSchedules, page} = this.props;
        if (prevProps.cityId !== cityId || prevProps.page !== page) {
            getSchedules(page, cityId)
        }
    }

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    linkHandler = (id:number)=>{
        const {history
        } = this.props;
        history.push(`/schedules/${id}`)
    };

    render() {
        const {
            data, loading, error, cityId, allowedCities, getSchedules, page, by, total
        } = this.props;

        if (loading) {
            return <Loader/>
        }
        if (error) {
            return <Error error={error} refresh={() => getSchedules(page, cityId)}/>
        }
        if (!cityId) {
            return <Redirect to={`/schedules?cityId=${allowedCities[0].id}`}/>
        }

        if (!!data.length) {
            return (
                <>
                    <div className={'mb-3'}>
                        <Schedules
                            data={data}
                            linkHandler={this.linkHandler}
                        />
                    </div>
                    <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>
                </>
            )
        }

        return (
            <Empty>
                <h3>Список расписаний пуст</h3>
                <p className={'mb-0'}>Чтобы добавить новое раписание, нажмите кнопку «Добавить новое расписание»</p>
            </Empty>
        )
    }
}


const mapStateToProps = ({schedules, auth}: AppState, props: RouteComponentProps) => {
    const {data, loading, error, by, total} = schedules;
    const {user} = auth;

    return {
        data,
        cityId: schedulesCityIdReSelector(props),
        page: schedulesPageReSelector(props),
        loading,
        error,
        by,
        total,
        allowedCities: _.get(user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    getSchedules

};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(SchedulesContainer);
