import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import Pagination from "../components/UI/Pagination";
import queryString from "query-string";
import _ from "lodash";
import TableGrid from "../components/UI/TableGrid";
import {statusOptions} from "../utils/statuses";
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import Icon from "../components/UI/Icon";
import {getCompanyBranches} from "../store/actions/companyBranches";
import {ICompanyBranch, ICompanyBranchFilterRequest, ICompanyBranchSortRequest, ICompany} from "../models/Company";
import {
    companyBranchesPageReSelector,
    companyBranchesSortReSelector,
    companyBranchesFilterReSelector
} from "../store/reselectors/companyBranches";
import Empty from "../components/UI/Empty";
import {getCompany} from "../store/actions/company";
import {IAuth} from "../models/Auth";
import {closeCompanyBranchForm, openCompanyBranchForm} from "../store/actions/companyBranchForm";
import CompanyBranchDelete from "./CompanyBranchDelete";
import {
    accessCompanyBranchDelete,
    accessCompanyBranchEdit
} from "../utils/user-accesses";
import {getCityValue, getCityOptions} from "../utils/cities";


type CompanyBranchesType = {
    getCompany: (id: number, expand: string) => void
    getCompanyBranches: (id: number, page?: number, form?: ICompanyBranchFilterRequest & ICompanyBranchSortRequest) => void
    closeCompanyBranchForm: () => void
    openCompanyBranchForm: (mode: 'delete', modeId: number) => void
    id: number
    page: number
    data: ICompanyBranch[]
    total: number
    by: number
    loading: boolean
    error: string
    filter?: ICompanyBranchFilterRequest
    sort?: ICompanyBranchSortRequest
    pointsLoading: boolean
    pointsError: string
    mode: 'delete' | null
    modeId: number | null
    accesses?: string[]
}

type PointsType = Pick<ICompany, 'points'>;
type CitiesType = Pick<IAuth, 'allowedCities'>;

class CompanyBranchesContainer extends Component<CompanyBranchesType & RouteComponentProps & PointsType & CitiesType> {

    componentDidMount() {
        const {id, getCompanyBranches, getCompany, page, filter, sort} = this.props;

        getCompany(id, 'points');
        getCompanyBranches(id, page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined);
    }

    componentDidUpdate(prevProps: Readonly<CompanyBranchesType & RouteComponentProps & PointsType & CitiesType>) {
        const {id, page, filter, sort, getCompanyBranches} = this.props;
        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter) || !_.isEqual(prevProps.sort, sort)) {
            getCompanyBranches(id, page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
        }
    }

    changeHandler = <T extends any>(form: T) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const params = _.pick(queryParams, ['cityId', 'pointId', 'address', 'status', 'orderBy']);

        const filter = _.pickBy({...params, ...form}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        const nextQueryParams = queryString.stringify(filter);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    filterHandler = (form: ICompanyBranchFilterRequest) => {
        this.changeHandler<ICompanyBranchFilterRequest>(form)
    };

    sortHandler = (form: ICompanyBranchSortRequest) => {
        const orderBy = form ? Object.keys(form)[0] + '_' + Object.values(form)[0] : undefined;
        this.changeHandler<{ [key: string]: string | undefined }>({orderBy})
    };

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    render() {
        const {
            id, data, loading, error, page, total, by, getCompanyBranches, filter,
            sort, points, pointsLoading, pointsError, allowedCities, getCompany, mode,
            modeId, closeCompanyBranchForm, openCompanyBranchForm, accesses, history
        } = this.props;

        const filterData = filter;
        const sortData = sort && sort.orderBy;

        const pointOptions = Array.isArray(points) ? points.map(({name, id}) => ({label: name, value: id})) : [];

        const cityOptions = getCityOptions(allowedCities)

        const editButtonWidth = Array.isArray(accesses) && accesses.includes(accessCompanyBranchEdit) ? 42 : 0;
        const deleteButtonWidth = Array.isArray(accesses) && accesses.includes(accessCompanyBranchDelete) ? 42 : 0;

        if (pointsLoading) {
            return <Loader/>
        }

        if (pointsError) {
            return <Error
                error={pointsError}
                refresh={() => getCompany(id, 'points')}
            />
        }
        if (Array.isArray(points) && !!points.length) {
            return (
                <>
                    <div className={'mb-3'}>
                        <TableGrid
                            sourceData={data}
                            filterData={filterData}
                            sortData={sortData}
                            columns={[
                                {
                                    label: 'Город',
                                    key: 'cityId',
                                    filter: {
                                        type: 'select',
                                        options: cityOptions,
                                        handler: this.filterHandler
                                    },
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                    width: '15%',
                                    render: (item: ICompanyBranch) => {
                                        return getCityValue(allowedCities, item.cityId)
                                    },
                                },
                                {
                                    label: 'Отправная точка',
                                    key: 'pointId',
                                    filter: {
                                        type: 'select',
                                        options: pointOptions,
                                        handler: this.filterHandler
                                    },
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                    width: '15%',
                                    render: (item: ICompanyBranch) => {
                                        const point = points && points.find(({id}) => item.pointId === id);

                                        if (point) {
                                            return point.name
                                        }
                                    },
                                },
                                {
                                    label: 'Адрес',
                                    key: 'address',
                                    filter: {
                                        type: 'text',
                                        handler: this.filterHandler
                                    },
                                    sort: {
                                        handler: this.sortHandler
                                    }
                                },
                                {
                                    label: 'Статус',
                                    key: 'status',
                                    filter: {
                                        type: 'select',
                                        options: statusOptions,
                                        handler: this.filterHandler
                                    },
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                    render: (item: ICompanyBranch) => {
                                        return item.status ? <div className={'semi-bold green'}>Включен</div> :
                                            <div className={'semi-bold red'}>Отключен</div>
                                    },
                                    width: '15%'
                                },
                                {
                                    label: 'Действия',
                                    render: (item: ICompanyBranch) => {
                                        return (
                                            <ButtonGroup>
                                                {Array.isArray(accesses) && accesses.includes(accessCompanyBranchEdit) &&
                                                <Button
                                                    onClick={() => item && item.id && history.push(`/companies/${id}/branches/edit/${item.id}`)}
                                                    size={'sm'} color={'outline-light'}>
                                                    <Icon name={'edit'} color={'gray-500'}/>
                                                </Button>}
                                                {Array.isArray(accesses) && accesses.includes(accessCompanyBranchDelete) &&
                                                <Button
                                                    onClick={() => item && item.id && openCompanyBranchForm('delete', item.id)}
                                                    size={'sm'} color={'outline-light'}>
                                                    <Icon name={'delete'} color={'gray-500'}/>
                                                </Button>}
                                            </ButtonGroup>
                                        )
                                    },
                                    width: 24 + editButtonWidth + deleteButtonWidth
                                }
                            ]}
                        />
                        {loading && <Loader/>}
                        {error && <Error error={error}
                                         refresh={() => getCompanyBranches(id, page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)}/>}
                        {!data.length && !loading && !error && <Empty>
                            <h3>Список филиалов пуст</h3>
                            <p className={'mb-0'}>Чтобы добавить филиал, нажмите кнопку «Добавить филиал»</p>
                        </Empty>}
                    </div>
                    <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>

                    {modeId !== null && <Modal isOpen={mode==='delete'} toggle={closeCompanyBranchForm}>
                        <ModalHeader toggle={closeCompanyBranchForm}>
                            Удалить контрагент?
                        </ModalHeader>
                        <ModalBody>
                            <CompanyBranchDelete id={modeId} sectionId={id}
                                                 cancelHandler={closeCompanyBranchForm}/>
                        </ModalBody>
                    </Modal>}
                </>
            )
        }
        return (
            <Empty>
                <h3>Список отправных точек пуст</h3>
                <p className={'mb-0'}>Чтобы добавить филиалы добавьте отправные точки </p>
            </Empty>
        )
    }
}


const mapStateToProps = ({companyBranches, companyBranchForm, company, auth}: AppState, props: RouteComponentProps) => {
    return {
        data: companyBranches.data,
        filter: companyBranchesFilterReSelector(props),
        sort: companyBranchesSortReSelector(props),
        page: companyBranchesPageReSelector(props),
        loading: companyBranches.loading,
        error: companyBranches.error,
        total: companyBranches.total,
        by: companyBranches.by,
        allowedCities: _.get(auth.user, 'allowedCities', []),
        points: company.data !== null ? company.data.points : undefined,
        pointsLoading: company.loading,
        pointsError: company.error,
        mode: companyBranchForm.mode,
        modeId: companyBranchForm.modeId,
        accesses: auth.user !== null ? auth.user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getCompanyBranches,
    getCompany,
    closeCompanyBranchForm,
    openCompanyBranchForm

};

export default compose<React.ComponentClass<{ id: number }>>(withRouter, connect(mapStateToProps, mapDispatchToProps))(CompanyBranchesContainer);
