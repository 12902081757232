import * as types from "../constants/courierDocuments";
import {IAction} from "./index";
import {IEmployee} from "../../models/Employee";

export interface IActionCourierDocumentsAdd extends IAction<symbol, any> {

}

export interface IActionCourierDocumentsDelete extends IAction<symbol, any> {

}

export interface IActionCourierDocumentsGet extends IAction<symbol, any> {

}

export const addCourierDocumentsRequest = () => {
    return {
        type: types.ADD_COURIER_DOCUMENTS_REQUEST
    }
}

export const addCourierDocumentsSuccess = () => {
    return {
        type: types.ADD_COURIER_DOCUMENTS_SUCCESS
    }
}

export const addCourierDocumentsError = (payload: string) => {
    return {
        type: types.ADD_COURIER_DOCUMENTS_ERROR,
        payload
    }
}

export const addCourierDocuments = (id: number, data: FormData) => {
    return {
        type: types.ADD_COURIER_DOCUMENTS,
        payload: {
            id,
            data
        }
    }
}

export const deleteCourierDocumentsRequest = () => {
    return {
        type: types.DELETE_COURIER_DOCUMENTS_REQUEST
    }
}

export const deleteCourierDocumentsSuccess = () => {
    return {
        type: types.DELETE_COURIER_DOCUMENTS_SUCCESS
    }
}

export const deleteCourierDocumentsError = (payload: string) => {
    return {
        type: types.DELETE_COURIER_DOCUMENTS_ERROR,
        payload
    }
}

export const deleteCourierDocuments = (id: number, documentId: number) => {
    return {
        type: types.DELETE_COURIER_DOCUMENTS,
        payload: {
            id,
            documentId
        }
    }
}

export const getCourierDocumentsRequest = () => {
    return {
        type: types.GET_COURIER_DOCUMENTS_REQUEST
    }
}

export const getCourierDocumentsSuccess = (payload: IEmployee) => {
    return {
        type: types.GET_COURIER_DOCUMENTS_SUCCESS,
        payload
    }
}

export const getCourierDocumentsError = (payload: string) => {
    return {
        type: types.GET_COURIER_DOCUMENTS_ERROR,
        payload
    }
}

export const getCourierDocuments = (id: number, expand: string) => {
    return {
        type: types.GET_COURIER_DOCUMENTS,
        payload: {
            id,
            expand
        }
    }
}