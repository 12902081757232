import {
    CLEAR_ORDER,
    GET_ORDER_ERROR,
    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS
} from "../constants/order";
import {IActionOrder} from "../actions/order";
import {IOrder, IOrderShort} from "../../models/Order";
import {CHANGE_STATUS_ORDERS_SUCCESS, SSE_ORDERS} from "../constants/orders";


export interface IOrderState {
    getLoading: boolean
    getError: string
    data: IOrder | null

}

const initialState: IOrderState = {
    data: null,
    getLoading: false,
    getError: ''
};

export default function order(state = initialState, action: IActionOrder): IOrderState {
    switch (action.type) {
        case GET_ORDER_REQUEST:
            return {
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_ORDER_SUCCESS:
            return {
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_ORDER_ERROR:
            return {
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case CLEAR_ORDER:
            return {
                ...initialState
            };
        /**
         * При обновлении списка заказов, если в данный момент активен (показывается) какой-либо заказ,
         * найти его в списке, чтобы обновить в показе тоже
         */
        case SSE_ORDERS:
        case CHANGE_STATUS_ORDERS_SUCCESS: {
            if (!state.data) {
                return state;
            }
            
            const activeOrderNewestUpdate = (action.payload as IOrderShort[])
              .find(({id}) => id === state.data?.id);
            
            if (!activeOrderNewestUpdate) {
                return state;
            }
    
            /**
             * Преобразовать поля с различными типами в Order и OrderShort
             */
            const addedFields = {
                ...activeOrderNewestUpdate,
                cityId: Number(activeOrderNewestUpdate.cityId),
                paymentTypeId: activeOrderNewestUpdate.paymentTypeId as IOrder["paymentTypeId"],
            };
            
            return {
                ...state,
                data: {
                    ...state.data,
                    ...addedFields,
                }
            }
        }
        default:
            return state;
    }
}
