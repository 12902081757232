import * as types from "../constants/employeePhotos";

export const addEmployeePhotosRequest = () => {
  return {
    type: types.ADD_EMPLOYEE_PHOTOS_REQUEST,
  }
}

export const addEmployeePhotoSuccess = () => {
  return {
    type: types.ADD_EMPLOYEE_PHOTOS_SUCCESS,
  }
}

export const addEmployeePhotosError = (payload: string) => {
  return {
    type: types.ADD_EMPLOYEE_PHOTOS_ERROR,
    payload,
  }
}

export const addEmployeePhotos = (id: number, data: FormData) => {
  return {
    type: types.ADD_EMPLOYEE_PHOTOS,
    payload: {
      id,
      data,
    }
  }
}

export const deleteEmployeePhotosRequest = () => {
  return {
    type: types.DELETE_EMPLOYEE_PHOTOS_REQUEST,
  }
}

export const deleteEmployeePhotosSuccess = () => {
  return {
    type: types.DELETE_EMPLOYEE_PHOTOS_SUCCESS,
  }
}

export const deleteEmployeePhotosError = (payload: string) => {
  return {
    type: types.DELETE_EMPLOYEE_PHOTOS_ERROR,
    payload
  }
}

export const deleteEmployeePhotos = (id: number) => {
  return {
    type: types.DELETE_EMPLOYEE_PHOTOS,
    payload: {
      id,
    }
  }
}
