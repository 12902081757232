import {Api} from "./Api";
import {IRoleAddFormRequest, IRoleEditFormRequest} from "../models/Role";


export class RoleService {
    getRoles() {
        return new Api().call(`/v1/manage/role`, {method: 'GET'});
    }

    addRole(form: IRoleAddFormRequest) {
        return new Api().call(`/v1/manage/role`, {method: 'POST', data: form});
    }

    editRole(id: string, form: IRoleEditFormRequest) {
        return new Api().call(`/v1/manage/role/${id}`, {method: 'POST', data: form});
    }

    getRole(id: string) {
        return new Api().call(`/v1/manage/role/${id}`, {method: 'GET'});
    }

    deleteRole(id: string) {
        return new Api().call(`/v1/manage/role/${id}`, {method: 'DELETE'});
    }
}
