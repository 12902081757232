import {
    GET_POINT_ZONES_ERROR,
    GET_POINT_ZONES_REQUEST,
    GET_POINT_ZONES_SUCCESS
} from "../constants/pointZones";
import {IActionPointZones} from "../actions/pointZones";
import {IPointZone} from "../../models/Point";


export interface IPointZonesState {
    loading: boolean
    error: string
    data: IPointZone[]
}

const initialState: IPointZonesState = {
    data: [],
    loading: false,
    error: ''
};

export default function pointZones(state = initialState, action: IActionPointZones): IPointZonesState {
    switch (action.type) {
        case GET_POINT_ZONES_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_POINT_ZONES_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_POINT_ZONES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
