import {createStore, applyMiddleware} from 'redux';
import {rootReducer} from './reducers';
import sagas from './sagas'
import createSagaMiddleware from 'redux-saga';



/**
 * Конфигурация стора
 * @returns {*}
 */
export default function configureStore() {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
      rootReducer,
      applyMiddleware(sagaMiddleware)
    );
    sagaMiddleware.run(sagas);

    return store;
}


