import React, {Component} from "react";
import {connect} from "react-redux";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {IDictionary} from "../models/Dictionary";
import {getDictionaryRoles} from "../store/actions/dictionaryRoles";
import Employees from "./Employees";
import {AppState} from "../store/reducers";
import EmployeeNavbar from "./EmployeeNavbar";
import {accessEmployeeCreate} from "../utils/user-accesses";


type EmployeeOutputType = {
    getDictionaryRoles: () => void
    data: IDictionary[]
    loading: boolean
    error: string
    accesses?: string[]
};


class EmployeeOutput extends Component<EmployeeOutputType> {

    componentDidMount() {
        const {getDictionaryRoles} = this.props;
        getDictionaryRoles()
    }

    render() {
        const {data, loading, error, getDictionaryRoles, accesses} = this.props;

        if (loading) {
            return <Loader/>
        }

        if (error) {
            return <Error
                error={error}
                refresh={() => getDictionaryRoles()}
            />
        }

        if (!!data.length) {
            return (
                <>
                    {Array.isArray(accesses) && accesses.includes(accessEmployeeCreate) &&
                    <EmployeeNavbar roles={data}/>}
                    <Employees roles={data}/>
                </>
            );
        }
        return null
    }
}


const mapStateToProps = (state: AppState) => {
    const {data, loading, error} = state.dictionaryRoles;
    const {user} = state.auth;
    return {
        data,
        loading,
        error,
        accesses: user !== null ? user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getDictionaryRoles
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeOutput);
