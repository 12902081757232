import {createSelector} from "reselect";
import queryString from 'query-string';


const schedulesUrlSelector = (props: any) => {
    return props.location.search
};

export const schedulesPageReSelector = createSelector(
    [schedulesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.page ? +getParams.page : 1
    }
);

export const schedulesCityIdReSelector = createSelector(
    [schedulesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.cityId ? +getParams.cityId : undefined
    }
);
