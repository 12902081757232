import React, {Component} from "react";
import {ICourierRatioRateConditionForm, ICourierRatioRateConditionResponse} from "../models/Courier";
import {Form, Formik} from "formik";
import {Button, Col, FormFeedback, FormGroup, Input, Label, Row, Spinner} from "reactstrap";
import NumberFormat from "react-number-format";
import {IAuth} from "../models/Auth";
import {IDictionary} from "../models/Dictionary";
import {getDictionaryOptions} from "../utils/dictionaries";
import Dropdown from "../components/UI/Dropdown";
import DateTimePicker from "../components/UI/DateTimePicker";
import {employeeTypeOptions} from "../utils/employees";
import * as yup from "yup";
import {requiredMessage} from "../utils/form-validation";
import {dateConvert} from "../utils/date-convert";

const schema = yup.object({
    ratio: yup.number().nullable().required(requiredMessage()),
    priority: yup.number().required(requiredMessage()),
    cityId: yup.number().nullable().required(requiredMessage()),
    typeId: yup.number().nullable(),
    time: yup.object().shape({
        timeStart: yup.string()
            .when("timeEnd", {
                is: (timeEnd) => timeEnd !== null,
                then: yup.string().nullable().required(requiredMessage()),
                otherwise: yup.string().nullable()
            }),
        timeEnd: yup.string()
            .when("timeStart", {
                is: (timeStart) => timeStart !== null,
                then: yup.string().nullable().required(requiredMessage()),
                otherwise: yup.string().nullable()
            }),
    }, [['timeStart', 'timeEnd']]),
    date: yup.object().shape({
        dateStart: yup.string()
            .when("dateEnd", {
                is: (dateEnd) => dateEnd !== null,
                then: yup.string().nullable().required(requiredMessage()),
                otherwise: yup.string().nullable()
            }),
        dateEnd: yup.string()
            .when("dateStart", {
                is: (dateStart) => dateStart !== null,
                then: yup.string().nullable().required(requiredMessage()),
                otherwise: yup.string().nullable()
            }),
    }, [['dateStart', 'dateEnd']]),
    days: yup.array().nullable(),
    status: yup.boolean().nullable().required(requiredMessage())
})

type RatioRateModalType = {
    changeRatioRate: (form: ICourierRatioRateConditionForm, id?: number) => void
    data: ICourierRatioRateConditionResponse | null
    dictionaryCityEagers: IDictionary[]
    closeModalHandler: () => void
    changeLoading: boolean
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class RatioRateModalContainer extends Component<RatioRateModalType & CitiesType> {
    render() {
        const {data, dictionaryCityEagers, changeRatioRate, closeModalHandler, changeLoading} = this.props;
        const initialValues = {
            ratio: data?.ratio || undefined,
            priority: data?.priority || 0,
            cityId: data?.cityId || null,
            typeId: data?.typeId || null,
            time: {
                timeStart: data?.timeStart ? data.timeStart.slice(0, -3) : null,
                timeEnd: data?.timeEnd ? data.timeEnd.slice(0, -3) : null,
            },
            date: {
                dateStart: data?.dateStart ?
                    dateConvert(data?.dateStart, 'yyyy-MM-dd', 'dd-MM-yyyy')
                    : null,
                dateEnd: data?.dateEnd ?
                    dateConvert(data?.dateEnd, 'yyyy-MM-dd', 'dd-MM-yyyy')
                    : null,
            },
            days: data?.days || null,
            status: data?.status || false,
        }

        const cityOptions = getDictionaryOptions(dictionaryCityEagers);

        const courierOptions = employeeTypeOptions;

        const daysOfWeekList = [
            {label: 'Воскресенье', value: 0},
            {label: 'Понедельник', value: 1},
            {label: 'Вторник', value: 2},
            {label: 'Среда', value: 3},
            {label: 'Четверг', value: 4},
            {label: 'Пятница', value: 5},
            {label: 'Суббота', value: 6},
        ]

        return (

            <Formik
                validationSchema={schema}
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => {

                    const form = {
                        ...values,
                        ratio: values.ratio ? values.ratio : null,
                        timeStart: values.time.timeStart ? values.time.timeStart + ":00" : null,
                        timeEnd: values.time.timeEnd ? values.time.timeEnd + ":00" : null,
                        dateStart: values.date.dateStart ?
                            dateConvert(values.date.dateStart, 'dd-MM-yyyy', 'yyyy-MM-dd')
                            : null,
                        dateEnd: values.date.dateEnd ?
                            dateConvert(values.date.dateEnd, 'dd-MM-yyyy', 'yyyy-MM-dd')
                            : null
                    };

                    delete form.date;
                    delete form.time;

                    if (data && data.id) {
                        changeRatioRate(form, data.id)
                    } else {
                        changeRatioRate(form);
                    }

                    if (!changeLoading) {
                        setTimeout(function () {
                            closeModalHandler();
                        }, 300)
                    }
                }}
            >
                {({setFieldValue, values, handleSubmit, errors, touched}) => {

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row form>
                                <Col md={'12'}>
                                    <FormGroup>
                                        <Label>Город <span className={'required'}>*</span></Label>
                                        <Dropdown
                                            name={'cityId'}
                                            value={values.cityId}
                                            options={cityOptions}
                                            onChange={(value) => {
                                                setFieldValue('cityId', value);
                                            }}
                                            isClearable
                                            invalid={touched.cityId && !!errors.cityId}
                                        />
                                        <FormFeedback>{errors.cityId}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={'12'}>
                                    <FormGroup>
                                        <Label>Курьер</Label>
                                        <Dropdown
                                            name={'typeId'}
                                            value={values.typeId}
                                            options={courierOptions}
                                            onChange={(value) => setFieldValue('typeId', value)}
                                            isClearable
                                            invalid={touched.typeId && !!errors.typeId}
                                        />
                                        <FormFeedback>{errors.typeId}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={'6'}>
                                    <FormGroup>
                                        <Label>Коэффициент <span className={'required'}>*</span></Label>
                                        <NumberFormat
                                            name={`ratio`}
                                            isAllowed={(values) => {
                                                const {formattedValue, floatValue} = values;
                                                return formattedValue === "" || floatValue <= 10;
                                            }}
                                            value={values.ratio}
                                            onValueChange={({value}) => setFieldValue('ratio', value)}
                                            customInput={Input}
                                            required={true}
                                            valid={touched.ratio && !!errors.ratio}
                                        />
                                        <FormFeedback>{errors.ratio}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={'6'}>
                                    <FormGroup>
                                        <Label>Приоритет <span className={'required'}>*</span></Label>
                                        <NumberFormat
                                            name={`priority`}
                                            allowNegative={false}
                                            value={values.priority}
                                            onValueChange={({value}) => setFieldValue('priority', value)}
                                            customInput={Input}
                                            required={true}
                                            valid={touched.priority && !!errors.priority}
                                        />
                                        <FormFeedback>{errors.priority}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={'6'}>
                                    <FormGroup>
                                        <Label>Дата начала</Label>
                                        <DateTimePicker
                                            name={`date.dateStart`}
                                            placeholder={'с'}
                                            value={values.date.dateStart}
                                            onChange={(value) => {
                                                setFieldValue('date.dateStart', value)
                                            }}
                                            invalid={!!errors.date?.dateStart}
                                        />
                                        <FormFeedback>{errors.date?.dateStart}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={'6'}>
                                    <FormGroup>
                                        <Label>Дата конца</Label>
                                        <DateTimePicker
                                            name={`date.dateEnd`}
                                            placeholder={'по'}
                                            value={values.date.dateEnd}
                                            onChange={(value) => {
                                                setFieldValue('date.dateEnd', value)
                                            }}
                                            invalid={!!errors.date?.dateEnd}
                                        />
                                        <FormFeedback>{errors.date?.dateEnd}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={'6'}>
                                    <FormGroup>
                                        <Label>Время начала</Label>
                                        <DateTimePicker
                                            name={`time.timeStart`}
                                            value={values.time.timeStart}
                                            onChange={(value) => {
                                                setFieldValue('time.timeStart', value)
                                            }}
                                            dateFormat={'HH:mm'}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            invalid={!!errors.time?.timeStart}
                                        />
                                        <FormFeedback>{errors.time?.timeStart}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={'6'}>
                                    <FormGroup>
                                        <Label>Время конца</Label>
                                        <DateTimePicker
                                            name={`timeEnd`}
                                            value={values.time.timeEnd}
                                            onChange={(value) => {
                                                setFieldValue('time.timeEnd', value)
                                            }}
                                            dateFormat={'HH:mm'}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            invalid={!!errors.time?.timeEnd}
                                        />
                                        <FormFeedback>{errors.time?.timeEnd}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={'12'}>
                                    <FormGroup>
                                        <Label>Дни недели</Label>
                                        <Dropdown
                                            name={'days'}
                                            value={values.days}
                                            options={daysOfWeekList}
                                            onChange={(value) => setFieldValue('days', value)}
                                            isClearable
                                            isMulti={true}
                                            valid={touched.days && !!errors.days}
                                        />
                                        <FormFeedback>{errors.days}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={'12'}>
                                    <FormGroup>
                                        <Label>Статус <span className={'required'}>*</span></Label>
                                        <Dropdown
                                            name={'status'}
                                            value={values.status}
                                            options={[
                                                {
                                                    label: 'Включен', value: true
                                                },
                                                {
                                                    label: 'Выключен', value: false
                                                }
                                            ]}
                                            onChange={(value) => setFieldValue('status', value)}
                                            required={true}
                                            valid={touched.status && !!errors.status}
                                        />
                                        <FormFeedback>{errors.status}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col className={'text-right'} md={'auto'}>
                                <Button type={'submit'} color={'primary'} disabled={changeLoading}>
                                    {changeLoading &&
                                    (<Spinner
                                        size="sm"
                                    />)}Сохранить</Button>
                                <Button className={'ml-2'} color={'light'} onClick={closeModalHandler}>Отмена</Button>
                            </Col>
                        </Form>
                    )
                }}
            </Formik>
        );
    }
}

export default RatioRateModalContainer;