import {
    GET_DICTIONARY_COMPANY_BRANCHES,
    GET_DICTIONARY_COMPANY_BRANCHES_REQUEST,
    GET_DICTIONARY_COMPANY_BRANCHES_SUCCESS,
    GET_DICTIONARY_COMPANY_BRANCHES_ERROR
} from '../constants/dictionaryCompanyBranches'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryCompanyBranches extends IAction<symbol, any> {

}

export const getDictionaryCompanyBranchesRequest = () => {
    return {
        type: GET_DICTIONARY_COMPANY_BRANCHES_REQUEST
    }
};

export const getDictionaryCompanyBranchesSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_COMPANY_BRANCHES_SUCCESS,
        payload
    }
};

export const getDictionaryCompanyBranchesError = (payload: string) => {
    return {
        type: GET_DICTIONARY_COMPANY_BRANCHES_ERROR,
        payload
    }
};

export const getDictionaryCompanyBranches = (companyId:number, cityId:number) => {
    return {
        type: GET_DICTIONARY_COMPANY_BRANCHES,
        payload:{
            companyId,
            cityId
        }
    };
};

