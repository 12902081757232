import {call, put, takeLatest} from 'redux-saga/effects';
import {removeUrlParams} from "../../utils/history";
import {getReportCouriersError, getReportCouriersRequest, getReportCouriersSuccess} from '../actions/reportCouriers';
import {Pagination} from "../../services/Api";
import {GET_REPORT_COURIERS} from "../constants/reportCouriers";
import {ReportService} from "../../services/ReportService";
import {IReportCourier, IReportCourierFilterRequest, IReportCourierSortRequest} from "../../models/Report";



type getReportCouriersType = {
    type: typeof GET_REPORT_COURIERS,
    payload:{
        page?:number,
        form?: IReportCourierFilterRequest & IReportCourierSortRequest
    }
}

function* getReportCouriersSaga({payload}:getReportCouriersType) {
    try {
        yield put(getReportCouriersRequest());

        const service = new ReportService();

        const promise = yield call(service.getReportCouriers, payload.page, payload.form);

        const data:IReportCourier[] = promise.data || [];
        const pagination = new Pagination(promise.headers).getData();

        yield put(getReportCouriersSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
        yield put(getReportCouriersError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetReportCouriers() {
    yield takeLatest(GET_REPORT_COURIERS, getReportCouriersSaga);
}
