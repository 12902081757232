import {GET_COMPANIES, GET_COMPANIES_REQUEST, GET_COMPANIES_SUCCESS, GET_COMPANIES_ERROR} from '../constants/companies'
import {IAction} from "./index";
import {ICompany, ICompanyFilterRequest, ICompanySortRequest} from "../../models/Company";


export interface IActionCompanies extends IAction<symbol, any> {

}

export const getCompaniesRequest = () => {
    return {
        type: GET_COMPANIES_REQUEST
    }
};

export const getCompaniesSuccess = (data: ICompany[], total:number, by:number) => {
    return {
        type: GET_COMPANIES_SUCCESS,
        payload:{
            data,
            total,
            by
        }
    }
};

export const getCompaniesError = (payload: string) => {
    return {
        type: GET_COMPANIES_ERROR,
        payload
    }
};

export const getCompanies = (page?: number, form?: ICompanyFilterRequest & ICompanySortRequest) => {
    return {
        type: GET_COMPANIES,
        payload:{
            page,
            form
        }
    };
};

