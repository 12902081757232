import React, {Component} from "react";
import {connect} from "react-redux";
import {getUser} from "../store/actions/auth";
import {AppState} from "../store/reducers";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import Layout from "../components/Layout";
import {IAuth} from "../models/Auth";

type AppType = {
    getUser: () => void
    loading:boolean
    error:string
    user:IAuth | null
    isAuthenticated:boolean
    count:number
}

class LayoutContainer extends Component<AppType> {

    componentDidMount() {
        this.props.user === null && this.props.getUser()
    }

    render() {
        const {user, children, loading, error, getUser, count} = this.props;

        if (loading) {
            return <Loader/>
        }
        if (error) {
            return <Error error={error} refresh={getUser}/>
        }
        if (user !== null) {
            return <Layout user={user} count={count}>{children}</Layout>
        }

        return null
    }
}


const mapStateToProps = ({auth, notifications}: AppState) => {
    const {loading, error, user, isAuthenticated} = auth;
    const {count} = notifications;

    return {
        loading,
        error,
        user,
        isAuthenticated,
        count
    }
};

const mapDispatchToProps = {
    getUser
};


export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
