import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {Alert, Button, Card, Spinner} from "reactstrap";
import {addScheduleForm} from "../store/actions/scheduleForm";
import {IScheduleAddFormRequest} from "../models/Schedule";


type ScheduleAddType = {
    addScheduleForm: (form: IScheduleAddFormRequest) => void
    cityId: number
    addLoading: boolean
    addError: string
    date: { dateStart: string, dateEnd: string }
}

class ScheduleAddContainer extends Component<ScheduleAddType> {

    addScheduleHandler = () => {
        const {addScheduleForm, cityId, date} = this.props;

        const form = {
            cityId,
            ...date
        };
        addScheduleForm(form);
    };

    render() {
        const {addLoading, addError} = this.props;
        return (
            <Card body style={{height: '100%'}}>
                <div className={'text-center'}>
                    <p className={'mb-2'}>Создайте новое расписание с чистого листа</p>
                    <Button color={'primary'} disabled={addLoading}
                            onClick={this.addScheduleHandler}>
                        {addLoading &&
                        (<Spinner
                            size="sm"
                        />)}
                        Добавить новое расписание
                    </Button>
                    {addError &&
                    (<Alert className={'mt-3'} color={'danger'}>
                        {addError}
                    </Alert>)}
                </div>
            </Card>
        )
    }
}


const mapStateToProps = ({scheduleForm}: AppState) => {
    return {
        addLoading: scheduleForm.addLoading,
        addError: scheduleForm.addError
    }
};

const mapDispatchToProps = {
    addScheduleForm
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAddContainer);
