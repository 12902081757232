import {
    GET_ORDERS,
    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_ERROR,
    CHANGE_STATUS_ORDERS, CHANGE_STATUS_ORDERS_SUCCESS, SSE_ORDERS, TOGGLE_ACTIVE_ORDERS
} from '../constants/orders'
import {IAction} from "./index";
import {IOrderRequest, IOrderShort} from "../../models/Order";
import {GET_ARCHIVE_ORDERS} from "../constants/archiveOrders";



export interface IActionOrders extends IAction<symbol, any> {

}

export const getOrdersRequest = () => {
    return {
        type: GET_ORDERS_REQUEST
    }
};

export const getOrdersSuccess =  (data: IOrderShort[], total:number, by:number) => {
    return {
        type: GET_ORDERS_SUCCESS,
        payload : {
            data,
            total,
            by
        }
    }
};

export const getOrdersError = (payload: string) => {
    return {
        type: GET_ORDERS_ERROR,
        payload
    }
};

export const getOrders = (page?: number, form?: IOrderRequest) => {
    return {
        type: GET_ORDERS,
        payload:{
            page,
            form
        }
    };
};

export const changeStatusOrdersSuccess = (payload: IOrderShort) => {
    return {
        type: CHANGE_STATUS_ORDERS_SUCCESS,
        payload
    }
};

export const changeStatusOrders = (id: string, status: number) => {
    return {
        type: CHANGE_STATUS_ORDERS,
        payload:{
            id,
            status
        }
    };
};


export const sseOrders = (payload: IOrderShort[]) => {
    return {
        type: SSE_ORDERS,
        payload
    };
};

export const toggleActiveIdOrders = (payload: string) => {
    return {
        type: TOGGLE_ACTIVE_ORDERS,
        payload
    };
};
