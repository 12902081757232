export const ADD_ORDER_FORM = Symbol('ADD_ORDER_FORM');
export const ADD_ORDER_FORM_REQUEST = Symbol('ADD_ORDER_FORM_REQUEST');
export const ADD_ORDER_FORM_SUCCESS = Symbol('ADD_ORDER_FORM_SUCCESS');
export const ADD_ORDER_FORM_ERROR = Symbol('ADD_ORDER_FORM_ERROR');
export const EDIT_ORDER_FORM = Symbol('EDIT_ORDER_FORM');
export const EDIT_ORDER_FORM_REQUEST = Symbol('EDIT_ORDER_FORM_REQUEST');
export const EDIT_ORDER_FORM_SUCCESS = Symbol('EDIT_ORDER_FORM_SUCCESS');
export const EDIT_ORDER_FORM_ERROR = Symbol('EDIT_ORDER_FORM_ERROR');
export const GET_ORDER_FORM = Symbol('GET_ORDER_FORM');
export const GET_ORDER_FORM_REQUEST = Symbol('GET_ORDER_FORM_REQUEST');
export const GET_ORDER_FORM_SUCCESS = Symbol('GET_ORDER_FORM_SUCCESS');
export const GET_ORDER_FORM_ERROR = Symbol('GET_ORDER_FORM_ERROR');
export const CHANGE_COORDINATE_ORDER_FORM = Symbol('CHANGE_COORDINATE_ORDER_FORM');
export const CHANGE_COORDINATE_ORDER_FORM_REQUEST = Symbol('CHANGE_COORDINATE_ORDER_FORM_REQUEST');
export const CHANGE_COORDINATE_ORDER_FORM_SUCCESS = Symbol('CHANGE_COORDINATE_ORDER_FORM_SUCCESS');
export const CHANGE_COORDINATE_ORDER_FORM_ERROR = Symbol('CHANGE_COORDINATE_ORDER_FORM_ERROR');
export const OPEN_ORDER_FORM = Symbol('OPEN_ORDER_FORM');
export const CLOSE_ORDER_FORM = Symbol('CLOSE_ORDER_FORM');


