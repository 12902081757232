import React, {PureComponent} from 'react';
import styles from './Layout.module.scss';
import classNames from 'classnames';
import Sidebar from '../Sidebar';
import Content from "../Content";
import Header from "../Header";
import Notify from "../../containers/Notify";
import {IAuth} from "../../models/Auth";



type LayoutStateType = {
    isSidebar: boolean
};
type LayoutPropsType = {
    count:number,
    user: IAuth | null
};
class Layout extends PureComponent<LayoutPropsType, LayoutStateType> {

    state = {
        isSidebar: typeof localStorage.getItem('sidebar') === "string" ? JSON.parse(localStorage.getItem('sidebar') as string): true
    };

    toggleSidebar = () => {
        this.setState(({isSidebar}) => ({
            isSidebar: !isSidebar
        }), () => {
            localStorage.setItem('sidebar', this.state.isSidebar.toString())
        });
    };

    render() {
        const {children, count, user} = this.props;
        const {isSidebar} = this.state;

        return (
            <div className={classNames(styles.layout)}>
                <Notify/>
                <Sidebar isSidebar={isSidebar} toggleSidebar={this.toggleSidebar}/>
                <Content isSidebar={isSidebar}>
                    <Header user={user} count={count}/>
                    {children}
                </Content>
            </div>
        );
    }
}

export default Layout;
