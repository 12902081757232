import {
    GET_DICTIONARY_REPORT_COURIER_PERIOD,
    GET_DICTIONARY_REPORT_COURIER_PERIOD_REQUEST,
    GET_DICTIONARY_REPORT_COURIER_PERIOD_SUCCESS,
    GET_DICTIONARY_REPORT_COURIER_PERIOD_ERROR
} from '../constants/dictionaryReportCourierPeriod'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryReportCourierPeriod extends IAction<symbol, any> {

}

export const getDictionaryReportCourierPeriodRequest = () => {
    return {
        type: GET_DICTIONARY_REPORT_COURIER_PERIOD_REQUEST
    }
};

export const getDictionaryReportCourierPeriodSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_REPORT_COURIER_PERIOD_SUCCESS,
        payload
    }
};

export const getDictionaryReportCourierPeriodError = (payload: string) => {
    return {
        type: GET_DICTIONARY_REPORT_COURIER_PERIOD_ERROR,
        payload
    }
};

export const getDictionaryReportCourierPeriod = () => {
    return {
        type: GET_DICTIONARY_REPORT_COURIER_PERIOD
    };
};

