import React, {ChangeEvent, Component} from "react";
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from "reactstrap";
import {addCourierDocuments, deleteCourierDocuments, getCourierDocuments} from "../store/actions/courierDocuments";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {IEmployee, IEmployeeDocs} from "../models/Employee";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import Icon from "../components/UI/Icon";

type CourierDocumentsContainerType = {
    id: number
    addCourierDocuments: (id: number, data: FormData) => void
    getCourierDocuments: (id: number, expand: string) => void
    deleteCourierDocuments: (id: number, documentId: number) => void
    changeLoading: boolean
    isLoaded: boolean
    addCourierDocumentError?: string
    data: IEmployee | null
    loading: boolean
    error?: string
}

class CourierDocumentsContainer extends Component<CourierDocumentsContainerType> {

    state = {
        isAddDocumentDialog: false,
        title: "",
        file: "",
        isShowDocumentDialog: false,
        document: {
            url: "",
            title: ""
        },
        isDeleteDocumentDialog: false,
        deletedDocument: {
            id: parseInt(''),
            documentId: parseInt('')
        }
    }

    componentDidMount() {
        const {getCourierDocuments, id} = this.props;

        getCourierDocuments(id, 'docs')
    }

    openAddDocumentDialog = () => {
        this.setState({
            isAddDocumentDialog: true
        })

    }

    closeAddDocumentDialog = () => {
        this.setState({
            isAddDocumentDialog: false,
            title: "",
            file: ""
        })
    }

    changeDocumentTitleHandler = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            title: event.target.value
        })
    }

    changeDocumentFileHandler = (event: ChangeEvent<HTMLInputElement>) => {

        this.setState({
            file: event.target.value
        })
    }

    addDocumentHandler = async (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        const {id, addCourierDocuments, getCourierDocuments} = this.props

        const data = new FormData(event.target)

        addCourierDocuments(id, data)

        this.setState({
            isAddDocumentDialog: false,
            title: "",
            file: ""
        })

        setTimeout(function () {
            getCourierDocuments(id, 'docs')
        }, 1000)
    }

    openShowDocumentDialog = (url: string, title: string) => {
        this.setState({
            isShowDocumentDialog: true,
            document: {
                url,
                title
            }
        })
    }

    closeShowDocumentDialog = () => {
        this.setState({
            isShowDocumentDialog: false
        })
    }

    openDeleteDocumentDialog = (id: number, documentId: number) => {
        this.setState({
            isDeleteDocumentDialog: true,
            deletedDocument: {
                id,
                documentId
            }
        })
    }

    closeDeleteDocumentDialog = () => {
        this.setState({
            isDeleteDocumentDialog: false
        })
    }

    deleteDocumentHandler = () => {

        const {deleteCourierDocuments, getCourierDocuments} = this.props
        const {id, documentId} = this.state.deletedDocument

        deleteCourierDocuments(id, documentId)

        this.setState({
            isDeleteDocumentDialog: false
        })

        setTimeout(function () {
            getCourierDocuments(id, 'docs')
        }, 1000)

    }

    render() {

        const {changeLoading, data, loading, error, id, getCourierDocuments} = this.props
        const {isAddDocumentDialog, title, file, isShowDocumentDialog, document, isDeleteDocumentDialog} = this.state;

        if (loading) return <Loader/>

        if (error) return <Error error={error} refresh={() => getCourierDocuments(id, 'docs')}/>

        return (
            <Table className={"mt-2"}>
                <tbody>
                {data && data.docs?.map((document: IEmployeeDocs, index: number) => {
                    const backgroundColor = index % 2 !== 0 ? "#F2F2F2" : '#FFFFFF'
                    return (
                        <tr
                            style={{
                                backgroundColor: backgroundColor
                            }}
                            key={index}>
                            <td style={{width: 48}}>
                                <img
                                    className={"border rounded"}
                                    src={document.url}
                                    style={{
                                        width: 48,
                                        height: 48
                                    }}
                                    alt={`Изображение ${document.title}`}/>
                            </td>
                            <td style={{width: '95%'}}>
                                <div style={{position: "relative", right: 32, top: 12}}>{document.title}</div>
                            </td>
                            <td style={{width: '5%'}}>
                                <ButtonGroup className={"mt-2"}>
                                    <Button onClick={() => this.openShowDocumentDialog(document.url, document.title)}
                                            size={'sm'} color={'outline-light'}>
                                        <Icon name={' remove_red_eye'}
                                              color={'gray-500'}/>
                                    </Button>
                                    <Button
                                        onClick={() => this.openDeleteDocumentDialog(document.userId, document.id)}
                                        size={'sm'}
                                        color={'outline-light'}>
                                        <Icon name={'delete'}
                                              color={'gray-500'}/>
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    )
                })
                }
                </tbody>
                <Button className={"mt-2"} color={"light"} onClick={this.openAddDocumentDialog}>Добавить</Button>
                <Modal size={'lg'} isOpen={isAddDocumentDialog} toggle={this.closeAddDocumentDialog}>
                    <Form onSubmit={this.addDocumentHandler.bind(this)}>
                        <ModalHeader toggle={this.closeAddDocumentDialog}>
                            Добавить документ
                        </ModalHeader>
                        <ModalBody>
                            <Col md={'4'}>
                                <FormGroup>
                                    <Label>Название документа</Label>
                                    <Input
                                        type={"text"}
                                        name={"title"}
                                        placeholder={"Удостоверение личности"}
                                        value={title}
                                        onChange={this.changeDocumentTitleHandler}
                                        required={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={'4'}>
                                <Input
                                    id={"file"}
                                    type={"file"}
                                    accept={'image/x-png,image/jpeg'}
                                    name={"file"}
                                    value={file}
                                    onChange={this.changeDocumentFileHandler}
                                />
                            </Col>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonGroup>
                                <Button disabled={changeLoading} onClick={this.closeAddDocumentDialog}
                                        color={"light"}>Отменить</Button>{' '}
                                <Button disabled={changeLoading} type={"submit"} color={"primary"}>Сохранить</Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </Form>
                </Modal>
                <Modal size={'lg'} isOpen={isShowDocumentDialog} toggle={this.closeShowDocumentDialog}>
                    <ModalHeader toggle={this.closeShowDocumentDialog}>{document.title}</ModalHeader>
                    <ModalBody className={"d-flex justify-content-center"}><img
                        style={{maxWidth: "100%", maxHeight: "100%"}} src={document.url}/></ModalBody>
                </Modal>
                <Modal size={'md'} isOpen={isDeleteDocumentDialog} toggle={this.closeDeleteDocumentDialog}>
                    <ModalHeader toggle={this.closeDeleteDocumentDialog}>Удалить документ</ModalHeader>
                    <ModalFooter style={{borderTop: 'none'}}>
                        <ButtonGroup>
                            <Button onClick={this.closeDeleteDocumentDialog}
                                    color={"light"}>Отменить</Button>{' '}
                            <Button onClick={this.deleteDocumentHandler} color={"primary"}>Удалить</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </Table>
        )
    }
}

const mapStateToProps = ({courierDocumentsAdd, courierDocumentsGet}: AppState) => {

    return {
        data: courierDocumentsGet.data,
        loading: courierDocumentsGet.loading,
        error: courierDocumentsGet.error,
        changeLoading: courierDocumentsAdd.loading,
        isLoaded: courierDocumentsAdd.isLoaded,
        addCourierDocumentError: courierDocumentsAdd.error
    }
}

const mapDispatchToProps = {
    addCourierDocuments,
    getCourierDocuments,
    deleteCourierDocuments
}

export default connect(mapStateToProps, mapDispatchToProps)(CourierDocumentsContainer);