import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import styles from "./ArchiveOrdersSummary.module.scss"
import classNames from "classnames";
import {RouteComponentProps} from "react-router";
import {IOrderArchiveSummary} from "../../models/Order";
import FormatPrice from "../UI/FormatPrice";

type ArchiveOrderSummaryType = {
    data: IOrderArchiveSummary
}

class ArchiveOrderSummary extends Component<ArchiveOrderSummaryType, RouteComponentProps> {
    render() {
        const {data} = this.props

        return (
            <Row className={"mx-n2"}>
                <Col className={"px-2"}>
                    <div className={classNames(styles["label"], "mt-1")}>Общее кол-во заказов</div>
                    <div className={styles["value"]}>{data.totalCount}</div>
                </Col>
                <Col className={"px-2"}>
                    <div className={classNames(styles["label"], "mt-1")}>Выполненных заказов</div>
                    <div className={styles["value"]}>{data.successCount}</div>
                </Col>
                <Col className={"px-2"}>
                    <div className={classNames(styles["label"], "mt-1")}>Отмененных заказов</div>
                    <div className={styles["value"]}>{data.cancelCount}</div>
                </Col>
                <Col className={"px-2"}>
                  <div className={classNames(styles["label"], "mt-1")}>Кол-во возвратных чеков</div>
                  <div className={styles["value"]}>{data.returnCheckCount}</div>
                </Col>
                <Col className={"px-2"}>
                  <div className={classNames(styles["label"], "mt-1")}>Сумма возвратных чеков</div>
                  <div className={styles["value"]}><FormatPrice value={data.returnCheckSumItems ?? 0}/></div>
                </Col>
                <Col className={"px-2"}>
                  <div className={classNames(styles["label"], "mt-1")}>Ср. время обработки</div>
                  <div className={styles["value"]}>{data.avgTimeForAccepted ?? 0} мин.</div>
                </Col>
                <Col className={"px-2"}>
                  <div className={classNames(styles["label"], "mt-1")}>Ср. время готовки</div>
                  <div className={styles["value"]}>{data.avgTimeForReady ?? 0} мин.</div>
                </Col>
                <Col className={"px-2"}>
                  <div className={classNames(styles["label"], "mt-1")}>Ср. время доставки</div>
                  <div className={styles["value"]}>{data.avgTimeForDelivery ?? 0} мин.</div>
                </Col>
            </Row>
        )
    }
}

export default ArchiveOrderSummary
