import * as types from "../constants/courierDocuments"
import {IActionCourierDocumentsDelete} from "../actions/courierDocuments";

export interface deleteCourierDocumentsState {
    data: {
        id: string,
        documentId: string
    } | null,
    loading: boolean
    error: string
}

const initialState: deleteCourierDocumentsState = {
    data: null,
    loading: false,
    error: ''
}

export default function courierDocumentsDelete(state = initialState, action: IActionCourierDocumentsDelete): deleteCourierDocumentsState {
    switch (action.type) {
        case types.DELETE_COURIER_DOCUMENTS_REQUEST:
            return {
                data: null,
                loading: true,
                error: ''
            }
        case types.DELETE_COURIER_DOCUMENTS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            }
        case types.DELETE_COURIER_DOCUMENTS_ERROR:
            return {
                data: null,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}