import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import queryString from "query-string";
import {getPayments} from "../store/actions/payments";
import {IPayment, IPaymentFilterRequest, IPaymentSortRequest} from "../models/Payment";
import _ from "lodash";
import TableGrid from "../components/UI/TableGrid";
import Empty from "../components/UI/Empty";
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import Icon from "../components/UI/Icon";
import {paymentsFilterReSelector, paymentsSortReSelector} from "../store/reselectors/payments";
import {IDictionary} from "../models/Dictionary";
import {getDictionaryOptions} from "../utils/dictionaries";
import {closePaymentForm, openPaymentForm} from "../store/actions/paymentForm";
import {paymentOptions} from "../utils/payments";
import PaymentEdit from "./PaymentEdit";
import PaymentDelete from "./PaymentDelete";
import {accessPaymentDelete, accessPaymentEdit} from "../utils/user-accesses";
import {format, parseISO} from "date-fns";


type PaymentsType = {
    getPayments: (form?: IPaymentFilterRequest & IPaymentSortRequest) => void
    data: IPayment[]
    loading: boolean
    error: string
    filter?: IPaymentFilterRequest
    sort?: IPaymentSortRequest
    dictionaryCompanies: IDictionary[]
    mode: 'add' | 'edit' | 'delete' | null,
    modeId: number | null
    closePaymentForm: () => void
    openPaymentForm: (mode: 'edit' | 'delete', modeId: number) => void
    accesses?:string[]
}


class PaymentsContainer extends Component<PaymentsType & RouteComponentProps> {

    componentDidMount() {
        const {getPayments, filter, sort} = this.props;
        getPayments(!_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
    }

    componentDidUpdate(prevProps: Readonly<PaymentsType & RouteComponentProps>) {
        const {filter, sort, getPayments} = this.props;
        if (!_.isEqual(prevProps.filter, filter) || !_.isEqual(prevProps.sort, sort)) {
            getPayments(!_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
        }
    }

    changeHandler = <T extends any>(form: T) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const params = _.pick(queryParams, ['companyId', 'methodId', 'payedAt', 'orderBy']);

        const filter = _.pickBy({...params, ...form}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        const nextQueryParams = queryString.stringify(filter);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    filterHandler = (form: IPaymentFilterRequest) => {
        this.changeHandler<IPaymentFilterRequest>(form)
    };

    sortHandler = (form: IPaymentSortRequest) => {
        const orderBy = form ? Object.keys(form)[0] + '_' + Object.values(form)[0] : undefined;
        this.changeHandler<{ [key: string]: string | undefined }>({orderBy})
    };

    render() {
        const {
            data, loading, error, getPayments, filter, sort,
            dictionaryCompanies, modeId, mode, openPaymentForm, closePaymentForm, accesses
        } = this.props;

        const filterData = filter;
        const sortData = sort && sort.orderBy;
        const companyOptions = getDictionaryOptions(dictionaryCompanies);

        return (
            <>
                <TableGrid
                    sourceData={data}
                    filterData={filterData}
                    sortData={sortData}
                    columns={[
                        {
                            label: 'ID',
                            key: 'id',
                            sort: {
                                handler: this.sortHandler
                            },
                            width: 100
                        },
                        {
                            label: 'Название компании',
                            key: 'companyId',
                            filter: {
                                type: 'select',
                                options: companyOptions,
                                handler: this.filterHandler
                            },
                            sort: {
                                handler: this.sortHandler
                            },
                            render: ({companyName}: IPayment) => {
                                return companyName
                            },
                            width: 240
                        },
                        {
                            label: 'Метод оплаты',
                            key: 'methodId',
                            filter: {
                                type: 'select',
                                options: paymentOptions,
                                handler: this.filterHandler
                            },
                            sort: {
                                handler: this.sortHandler
                            },
                            render: ({methodId}: IPayment) => {
                                let method;
                                switch (methodId) {
                                    case 1:
                                        method = 'Наличными';
                                        break;
                                    case 2:
                                        method = 'Банковским переводом';
                                        break;
                                    case 3:
                                        method = 'Kaspi';
                                        break;
                                }
                                return method
                            },
                            width: 220
                        },
                        {
                            label: 'Сумма',
                            key: 'amount',
                            sort: {
                                handler: this.sortHandler
                            },
                            width: 160
                        },
                        {
                            label: 'Дата оплаты',
                            key: 'payedAt',
                            filter: {
                                type: 'date',
                                handler: this.filterHandler
                            },
                            sort: {
                                handler: this.sortHandler
                            },
                            width: 160,
                            render: ({payedAt}: IPayment) => {
                                return payedAt && format(parseISO(payedAt), 'dd-MM-yyyy')
                            }
                        },
                        {
                            label: 'Комментарий',
                            key: 'comment'
                        },
                        {
                            label: 'Действия',
                            render: ({id}: IPayment) => {
                                return (
                                    <ButtonGroup>
                                        {Array.isArray(accesses) && accesses.includes(accessPaymentEdit) &&
                                        <Button onClick={() => openPaymentForm('edit', id)}
                                                size={'sm'} color={'outline-light'}>
                                            <Icon name={'edit'} color={'gray-500'}/>
                                        </Button>}
                                        {Array.isArray(accesses) && accesses.includes(accessPaymentDelete) &&
                                        <Button onClick={() => openPaymentForm('delete', id)}
                                                size={'sm'} color={'outline-light'}>
                                            <Icon name={'delete'} color={'gray-500'}/>
                                        </Button>}
                                    </ButtonGroup>
                                )
                            },
                            width: 100
                        }
                    ]}
                />
                {loading && <Loader/>}
                {error && <Error
                    error={error}
                    refresh={() => getPayments(!_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)}
                />}
                {!data.length && !loading && !error &&
                <Empty>
                    <h5 className={'mb-0'}>Список пуст</h5>
                </Empty>}

                {modeId !== null && <Modal isOpen={mode === 'edit'} toggle={closePaymentForm}>
                    <ModalHeader toggle={closePaymentForm}>
                        Редактирование оплаты
                    </ModalHeader>
                    <ModalBody>
                        <PaymentEdit id={modeId} cancelHandler={closePaymentForm}/>
                    </ModalBody>
                </Modal>}
                {modeId !== null && <Modal isOpen={mode === 'delete'} toggle={closePaymentForm}>
                    <ModalHeader toggle={closePaymentForm}>
                        Удалить оплату?
                    </ModalHeader>
                    <ModalBody>
                        <PaymentDelete id={modeId} cancelHandler={closePaymentForm}/>
                    </ModalBody>
                </Modal>}
            </>
        )
    }
}


const mapStateToProps = ({payments, paymentForm, auth}: AppState, props: RouteComponentProps) => {
    const {data, loading, error} = payments;
    const {mode, modeId} = paymentForm;
    const {user} = auth;

    return {
        data,
        filter: paymentsFilterReSelector(props),
        sort: paymentsSortReSelector(props),
        loading,
        error,
        mode,
        modeId,
        accesses: user !== null ? user.accesses : undefined,
    }
};

const mapDispatchToProps = {
    getPayments,
    openPaymentForm,
    closePaymentForm
};

export default compose<React.ComponentClass<{ dictionaryCompanies: IDictionary[] }>>(withRouter, connect(mapStateToProps, mapDispatchToProps))(PaymentsContainer);
