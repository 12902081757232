import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {IRole, IRoleEditFormRequest} from "../models/Role";
import {editRoleForm, getRoleForm} from "../store/actions/roleForm";
import {getDictionaryPermissions} from "../store/actions/dictionaryPermissions";
import {IDictionary} from "../models/Dictionary";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import RoleEdit from "../components/RoleEdit";


type RoleEditType = {
    getRoleForm: (id:string) => void
    editRoleForm: (id:string, form: IRoleEditFormRequest) => void
    getDictionaryPermissions: () => void
    data: IRole | null
    getLoading: boolean
    getError: string
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
    id: string
    permissions: IDictionary[]
    permissionsLoading: boolean
    permissionsError: string
}


class RoleEditContainer extends Component<RoleEditType> {

    componentDidMount() {
        const {getDictionaryPermissions, getRoleForm, id} = this.props;
        getRoleForm(id);
        getDictionaryPermissions()
    }

    render() {
        const {changeLoading, changeError, editRoleForm, cancelHandler, permissions, permissionsLoading, permissionsError,
            getDictionaryPermissions, data, getLoading, getError, getRoleForm, id
        } = this.props;

        if (permissionsLoading || getLoading) {
            return <Loader/>
        }

        if (permissionsError) {
            return <Error error={permissionsError} refresh={getDictionaryPermissions}/>
        }

        if (getError) {
            return <Error error={getError} refresh={() => getRoleForm(id)}/>
        }

        if (!!permissions.length && data !== null) {
            return (
                <RoleEdit
                    editRoleForm={editRoleForm}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    data={data}
                    permissions={permissions}
                    cancelHandler={cancelHandler}
                />
            )
        }
        return null
    }
}

const mapStateToProps = ({roleForm, dictionaryPermissions}: AppState) => {
    return {
        data: roleForm.data,
        getLoading: roleForm.getLoading,
        getError: roleForm.getError,
        changeLoading: roleForm.changeLoading,
        changeError: roleForm.changeError,
        permissions: dictionaryPermissions.data,
        permissionsLoading: dictionaryPermissions.loading,
        permissionsError: dictionaryPermissions.error
    }
};

const mapDispatchToProps = {
    getRoleForm,
    editRoleForm,
    getDictionaryPermissions
};


export default connect(mapStateToProps, mapDispatchToProps)(RoleEditContainer);
