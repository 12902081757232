export const ADD_SCHEDULE_DATE_FORM = Symbol('ADD_SCHEDULE_DATE_FORM');
export const ADD_SCHEDULE_DATE_FORM_REQUEST = Symbol('ADD_SCHEDULE_DATE_FORM_REQUEST');
export const ADD_SCHEDULE_DATE_FORM_SUCCESS = Symbol('ADD_SCHEDULE_DATE_FORM_SUCCESS');
export const ADD_SCHEDULE_DATE_FORM_ERROR = Symbol('ADD_SCHEDULE_DATE_FORM_ERROR');
export const EDIT_SCHEDULE_DATE_FORM = Symbol('EDIT_SCHEDULE_DATE_FORM');
export const EDIT_SCHEDULE_DATE_FORM_REQUEST = Symbol('EDIT_SCHEDULE_DATE_FORM_REQUEST');
export const EDIT_SCHEDULE_DATE_FORM_SUCCESS = Symbol('EDIT_SCHEDULE_DATE_FORM_SUCCESS');
export const EDIT_SCHEDULE_DATE_FORM_ERROR = Symbol('EDIT_SCHEDULE_DATE_FORM_ERROR');
export const GET_SCHEDULE_DATE_FORM = Symbol('GET_SCHEDULE_DATE_FORM');
export const GET_SCHEDULE_DATE_FORM_REQUEST = Symbol('GET_SCHEDULE_DATE_FORM_REQUEST');
export const GET_SCHEDULE_DATE_FORM_SUCCESS = Symbol('GET_SCHEDULE_DATE_FORM_SUCCESS');
export const GET_SCHEDULE_DATE_FORM_ERROR = Symbol('GET_SCHEDULE_DATE_FORM_ERROR');
export const COPY_SCHEDULE_DATE_FORM = Symbol('COPY_SCHEDULE_DATE_FORM');
export const COPY_SCHEDULE_DATE_FORM_REQUEST = Symbol('COPY_SCHEDULE_DATE_FORM_REQUEST');
export const COPY_SCHEDULE_DATE_FORM_SUCCESS = Symbol('COPY_SCHEDULE_DATE_FORM_SUCCESS');
export const COPY_SCHEDULE_DATE_FORM_ERROR = Symbol('COPY_SCHEDULE_DATE_FORM_ERROR');
export const OPEN_SCHEDULE_DATE_FORM = Symbol('OPEN_SCHEDULE_DATE_FORM');
export const CLOSE_SCHEDULE_DATE_FORM = Symbol('CLOSE_SCHEDULE_DATE_FORM');


