import React, {Component} from "react";
import {AppState} from "../store/reducers";
import {connect} from "react-redux";
import PickCity from "./PickCity";
import {Card} from "reactstrap";
import SalaryFilter from "./SalaryFilter";
import Salary from "./Salary";

type SalaryOutputType = {
    accesses?: string[]
}

class SalaryOutput extends Component<SalaryOutputType> {
    render() {

        return (
            <>
                <div className={'mb-2'}>
                    <PickCity/>
                </div>
                <Card body className={'mb-3'}>
                    <h4 className={'mb-3'}>Фильтр</h4>
                    <SalaryFilter/>
                </Card>
                <Salary/>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    const {user} = state.auth;
    return {
        accesses: user !== null ? user.accesses : undefined,
    }
};

export default connect(mapStateToProps, null)(SalaryOutput);