import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {getCourierScheduleById} from "../store/actions/courierScheduleById";
import Empty from "../components/UI/Empty";
import CourierScheduleById from "../components/CourierScheduleById";
import {ICourierScheduleByID} from "../models/Courier";


type CourierScheduleByIdType = {
    getCourierScheduleById: (sectionId: number, id: number) => void
    data: ICourierScheduleByID[]
    id: number
    sectionId: number
    loading: boolean
    error: string
}

class CourierScheduleByIdContainer extends Component<CourierScheduleByIdType> {

    componentDidMount() {
        const {id, sectionId, getCourierScheduleById} = this.props;
        getCourierScheduleById(sectionId, id);
    }

    componentDidUpdate(prevProps: Readonly<CourierScheduleByIdType>) {
        const {id, sectionId, getCourierScheduleById} = this.props;
        if (prevProps.id !== id) {
            getCourierScheduleById(sectionId, id);
        }
    }

    render() {
        const {data, loading, error, getCourierScheduleById, id, sectionId} = this.props;

        if (loading) {
            return <Loader/>
        }

        if (error) {
            return <Error error={error} refresh={() => getCourierScheduleById(sectionId, id)}/>
        }
        if (!!data.length) {
            return (
                <CourierScheduleById
                    data={data}
                />
            )
        }
        return (
            <Empty>
                <h5 className={'mb-0'}>Список расписаний пуст</h5>
            </Empty>
        )
    }
}

const mapStateToProps = ({courierScheduleById}: AppState) => {
    const {data, loading, error} = courierScheduleById;
    return {
        data,
        loading,
        error
    }
};

const mapDispatchToProps = {
    getCourierScheduleById
};


export default connect(mapStateToProps, mapDispatchToProps)(CourierScheduleByIdContainer);
