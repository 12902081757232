import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import Cities from "../../containers/Cities";
import CityNavbar from "../../containers/CityNavbar";


class DirectoryCitiesPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Справочник - Города</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Справочник - города', url: '/directories/cities'}
                ]}/>
                <CityNavbar/>
                <Cities/>
            </Layout>
        );
    }
}

export default DirectoryCitiesPage;
