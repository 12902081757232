import {GET_EMPLOYEES_ERROR, GET_EMPLOYEES_REQUEST, GET_EMPLOYEES_SUCCESS} from "../constants/employees";
import { IEmployee } from "../../models/Employee";
import {IActionEmployees} from "../actions/employees";


export interface IEmployeesState {
    loading: boolean
    error: string
    data: IEmployee[]
    total: number
    by: number
}

const initialState: IEmployeesState = {
    data: [],
    loading: false,
    error: '',
    total: 0,
    by: 0
};

export default function employees(state = initialState, action: IActionEmployees): IEmployeesState {
    switch (action.type) {
        case GET_EMPLOYEES_REQUEST:
            return {
                data: [],
                loading: true,
                error: '',
                total: 0,
                by: 0
            };
        case GET_EMPLOYEES_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                data,
                loading: false,
                error: '',
                total,
                by
            };
        case GET_EMPLOYEES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload,
                total: 0,
                by: 0
            };
        default:
            return state;
    }
}
