import {GET_DICTIONARY_ROLES_ERROR, GET_DICTIONARY_ROLES_REQUEST, GET_DICTIONARY_ROLES_SUCCESS} from "../constants/dictionaryRoles";
import {IDictionary} from "../../models/Dictionary";
import {IActionDictionaryRoles} from "../actions/dictionaryRoles";


export interface IDictionaryRolesState {
    loading: boolean
    error: string
    data: IDictionary[]
}

const initialState: IDictionaryRolesState = {
    data: [],
    loading: false,
    error: ''
};

export default function dictionaryRoles(state = initialState, action: IActionDictionaryRoles): IDictionaryRolesState {
    switch (action.type) {
        case GET_DICTIONARY_ROLES_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_DICTIONARY_ROLES_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_DICTIONARY_ROLES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
