import {
    COURIER_TYPE_AUTO,
    COURIER_TYPE_BIKE,
    COURIER_TYPE_MOTORCYCLE,
    COURIER_TYPE_WALK,
    CourierRateTypes
} from "./couriers";
import {IEmployeeFilterRequest, IEmployeeSortRequest} from "../models/Employee";
import _ from "lodash";

export const EMPLOYEE_STATUS_BLOCKED = 0;
export const EMPLOYEE_STATUS_ACTIVE = 10;

type EmployeeStatusesType = {
    [key: string]: string;
};

export const EmployeeStatuses: EmployeeStatusesType = {
    [EMPLOYEE_STATUS_BLOCKED]: 'Заблокирован',
    [EMPLOYEE_STATUS_ACTIVE]: 'Активный'
};

export const employeeStatusOptions = [
    {label: 'Заблокирован', value: EMPLOYEE_STATUS_BLOCKED},
    {label: 'Активный', value: EMPLOYEE_STATUS_ACTIVE}
];

export const employeeTypeOptions = [
    {label: 'Пеший курьер', value: COURIER_TYPE_WALK},
    {label: 'Велокурьер', value: COURIER_TYPE_BIKE},
    {label: 'Мотокурьер', value: COURIER_TYPE_MOTORCYCLE},
    {label: 'Автокурьер', value: COURIER_TYPE_AUTO}
];

export const employeeRateTypeOptions = [
    {label: 'Старый тариф', value: CourierRateTypes.Old},
    {label: 'Новый тариф', value: CourierRateTypes.New},
]

export const employeeLegalFormIdOptions = [
    {label: 'ИП', value: 2},
    {label: 'ГПХ', value: 1},
    {label: 'ГПХ (без пенсионных вычетов)', value: 3},
]

type EmployeesRequestForm = IEmployeeFilterRequest & IEmployeeSortRequest & {
    city?: IEmployeeFilterRequest['cities'];
}

/**
 * Собрать форму запроса списка сотрудников
 */
export const getEmployeesRequestForm = (
  filter: IEmployeeFilterRequest | undefined,
  sort: IEmployeeSortRequest | undefined
): EmployeesRequestForm | undefined => {
    const form: EmployeesRequestForm = {...filter, ...sort};
    
    if (form.cities) {
        form.city = form.cities;
        delete form.cities;
    }
    
    return !_.isEmpty(form) ? form : undefined
}

export const EMPLOYEE_ROLE_COURIER = 'courier';
export const EMPLOYEE_ROLE_PARTNER = 'partner';
