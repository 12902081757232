import {
    ADD_ORDER_FORM_REQUEST,
    ADD_ORDER_FORM_SUCCESS,
    ADD_ORDER_FORM_ERROR,
    EDIT_ORDER_FORM_REQUEST,
    EDIT_ORDER_FORM_SUCCESS,
    EDIT_ORDER_FORM_ERROR,
    GET_ORDER_FORM_REQUEST,
    GET_ORDER_FORM_SUCCESS,
    GET_ORDER_FORM_ERROR,
    OPEN_ORDER_FORM,
    CLOSE_ORDER_FORM,
    CHANGE_COORDINATE_ORDER_FORM_REQUEST,
    CHANGE_COORDINATE_ORDER_FORM_SUCCESS,
    CHANGE_COORDINATE_ORDER_FORM_ERROR
} from '../constants/orderForm'
import {IOrder} from "../../models/Order";
import {IActionOrderForm} from "../actions/orderForm";



export interface IOrderFormState {
    getLoading: boolean
    getError: string
    data: IOrder | null
    changeLoading: boolean
    changeError: string
    mode: 'coordinate' | null
}

const initialState: IOrderFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode:null
};

export default function orderForm(state = initialState, action: IActionOrderForm): IOrderFormState {
    switch (action.type) {
        case GET_ORDER_FORM_REQUEST:
            return {
                data: null,
                getLoading: true,
                getError: '',
                changeLoading: false,
                changeError: '',
                mode:null
            };
        case GET_ORDER_FORM_SUCCESS:
            return {
                data: action.payload,
                getLoading: false,
                getError: '',
                changeLoading: false,
                changeError: '',
                mode:null
            };
        case GET_ORDER_FORM_ERROR:
            return {
                data: null,
                getLoading: false,
                getError: action.payload,
                changeLoading: false,
                changeError: '',
                mode:null
            };
        case ADD_ORDER_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_ORDER_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_ORDER_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case EDIT_ORDER_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case EDIT_ORDER_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case EDIT_ORDER_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case CHANGE_COORDINATE_ORDER_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case CHANGE_COORDINATE_ORDER_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case CHANGE_COORDINATE_ORDER_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_ORDER_FORM:
            return {
                ...initialState,
                mode: action.payload
            };
        case CLOSE_ORDER_FORM:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
