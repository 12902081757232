import * as types from "../constants/courierDocuments"
import {IActionCourierDocumentsAdd} from "../actions/courierDocuments";

export interface addCourierDocumentsState {
    isLoaded: boolean
    loading: boolean
    error: string
}

const initialState: addCourierDocumentsState = {
    isLoaded: false,
    loading: false,
    error: ''
}

export default function courierDocumentsAdd(state = initialState, action: IActionCourierDocumentsAdd): addCourierDocumentsState {
    switch (action.type) {
        case types.ADD_COURIER_DOCUMENTS_REQUEST:
            return {
                loading: true,
                error: '',
                isLoaded: false
            }
        case types.ADD_COURIER_DOCUMENTS_SUCCESS:
            return {
                loading: false,
                error: '',
                isLoaded: true
            }
        case types.ADD_COURIER_DOCUMENTS_ERROR:
            return {
                loading: false,
                error: action.payload,
                isLoaded: false
            }
        default:
            return state;
    }
}