import {call, put, takeLatest} from 'redux-saga/effects';
import {
    changeStatusArchiveOrdersSuccess,
    getArchiveOrdersError,
    getArchiveOrdersRequest,
    getArchiveOrdersSuccess,
    getArchiveOrdersSummaryError,
    getArchiveOrdersSummaryRequest,
    getArchiveOrdersSummarySuccess
} from '../actions/archiveOrders';
import {CHANGE_STATUS_ARCHIVE_ORDERS, GET_ARCHIVE_ORDERS, GET_ARCHIVE_ORDERS_SUMMARY} from "../constants/archiveOrders";
import {OrderService} from "../../services/OrderService";
import {IOrderArchiveSummary, IOrderRequest, IOrderShort} from "../../models/Order";
import {addNotify} from "../actions/notify";
import {ICompany, ICompanyFilterRequest, ICompanySortRequest} from "../../models/Company";
import {Pagination} from "../../services/Api";
import {getCompaniesSuccess} from "../actions/companies";
import {removeUrlParams} from "../../utils/history";
import {IReportCourier} from "../../models/Report";



type getArchiveOrdersType = {
    type: typeof GET_ARCHIVE_ORDERS,
    payload:{
        page?:number,
        form?: IOrderRequest
    }
}

function* getArchiveOrdersSaga({payload}:getArchiveOrdersType) {
    try {
        yield put(getArchiveOrdersRequest());

        const service = new OrderService();

        const promise = yield call(service.getArchiveOrders, payload.page,payload.form);

        const data:IOrderShort[] = promise.data || [];
        const pagination = new Pagination(promise.headers).getData();
        yield put(getArchiveOrdersSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
        yield put(getArchiveOrdersError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetArchiveOrders() {
    yield takeLatest(GET_ARCHIVE_ORDERS, getArchiveOrdersSaga);
}

type getArchiveOrdersSummaryType = {
    type: typeof GET_ARCHIVE_ORDERS_SUMMARY,
    payload:{
        form?: IOrderRequest
    }
}

function* getArchiveOrdersSummarySaga({payload}: getArchiveOrdersSummaryType) {
    try {

        yield put(getArchiveOrdersSummaryRequest())

        const service = new OrderService();

        const promise = yield  call(service.getArchiveOrdersSummary, payload.form)

        const data: IOrderArchiveSummary[] = promise.data

        yield put(getArchiveOrdersSummarySuccess(data))

    } catch (error) {
        yield put(getArchiveOrdersSummaryError(error.response.data.message || 'Ошибка'))
    }
}

export function* watchGetArchiveOrdersSummary() {
    yield takeLatest(GET_ARCHIVE_ORDERS_SUMMARY, getArchiveOrdersSummarySaga)
}

type changeStatusArchiveOrdersType = {
    type: typeof CHANGE_STATUS_ARCHIVE_ORDERS,
    payload:{
        id: string
        status: number
    }
}

function* changeStatusArchiveOrdersSaga({payload}:changeStatusArchiveOrdersType) {
    try {
        const service = new OrderService();
        const promise = yield call(service.changeStatusOrders, payload.id, payload.status);
        const response:IOrderShort = promise.data;
        yield put(changeStatusArchiveOrdersSuccess(response));

    } catch (error) {
        yield put(addNotify(error.response.data.message || 'Ошибка'));
    }
}

export function* watchChangeStatusArchiveOrders() {
    yield takeLatest(CHANGE_STATUS_ARCHIVE_ORDERS, changeStatusArchiveOrdersSaga);
}

