import React, {Component} from "react";
import Layout from "../../containers/Layout";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import RateManagement from "../../containers/CourierRate";

class CourierRatePage extends Component{
    render() {
        return (
            <Layout>
                <Title>Настройки - Управление ставками</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Настройки - Управление ставками', url: '/settings/courier-rate'}
                ]}/>
                <RateManagement/>
            </Layout>
        );
    }
}

export default CourierRatePage;