import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import PaymentsOutput from "../../containers/PaymentsOutput";


class PaymentsPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Оплаты</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Оплаты', url: '/payments'}
                ]}/>
                <PaymentsOutput/>
            </Layout>
        );
    }
}

export default PaymentsPage;
