import {call, put, takeLatest} from 'redux-saga/effects';
import {getDictionaryPointsError, getDictionaryPointsRequest, getDictionaryPointsSuccess} from '../actions/dictionaryPoints';
import {GET_DICTIONARY_POINTS} from "../constants/dictionaryPoints";
import {IDictionary} from "../../models/Dictionary";
import { DictionaryService } from '../../services/DictionaryService';



function* getDictionaryPointsSaga({payload}:{type: typeof GET_DICTIONARY_POINTS, payload:number}) {
    try {
        yield put(getDictionaryPointsRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryPoints, payload);

        const response:IDictionary[] = promise.data || [];

        yield put(getDictionaryPointsSuccess(response));


    } catch (error) {
        yield put(getDictionaryPointsError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryPoints() {
    yield takeLatest(GET_DICTIONARY_POINTS, getDictionaryPointsSaga);
}
