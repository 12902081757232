import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getCompanyRequest,
    getCompanySuccess,
    getCompanyError
} from "../actions/company";
import {ICompany} from '../../models/Company';
import {CompanyService} from "../../services/CompanyService";
import { GET_COMPANY } from '../constants/company';


type getCompanyType = {
    type: typeof GET_COMPANY,
    payload: {
        id:number,
        expand?:string
    }
}

function* getCompanySaga({payload}: getCompanyType) {
    try {
        yield put(getCompanyRequest());

        const service = new CompanyService();
        const promise = yield call(service.getCompany, payload.id, payload.expand);

        const response:ICompany = promise.data;

        yield put(getCompanySuccess(response));


    } catch (error) {
        yield put(getCompanyError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCompany() {
    yield takeLatest(GET_COMPANY, getCompanySaga);
}
