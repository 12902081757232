import {
    GET_COURIER_SCHEDULE_BY_ID_ERROR,
    GET_COURIER_SCHEDULE_BY_ID_REQUEST,
    GET_COURIER_SCHEDULE_BY_ID_SUCCESS
} from "../constants/courierScheduleById";
import {IActionCourierScheduleById} from "../actions/courierScheduleById";
import {ICourierScheduleByID} from "../../models/Courier";


export interface ICourierScheduleByIdState {
    loading: boolean
    error: string
    data: ICourierScheduleByID[]
}

const initialState: ICourierScheduleByIdState = {
    data: [],
    loading: false,
    error: ''
};

export default function courierScheduleById(state = initialState, action: IActionCourierScheduleById): ICourierScheduleByIdState {
    switch (action.type) {
        case GET_COURIER_SCHEDULE_BY_ID_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_COURIER_SCHEDULE_BY_ID_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_COURIER_SCHEDULE_BY_ID_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
