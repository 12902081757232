export const ADD_COURIER_DOCUMENTS = Symbol('ADD_COURIER_DOCUMENTS');
export const ADD_COURIER_DOCUMENTS_REQUEST = Symbol('ADD_COURIER_DOCUMENTS_REQUEST');
export const ADD_COURIER_DOCUMENTS_SUCCESS = Symbol('ADD_COURIER_DOCUMENTS_SUCCESS');
export const ADD_COURIER_DOCUMENTS_ERROR = Symbol('ADD_COURIER_DOCUMENTS_ERROR');
export const DELETE_COURIER_DOCUMENTS = Symbol('DELETE_COURIER_DOCUMENTS');
export const DELETE_COURIER_DOCUMENTS_REQUEST = Symbol('DELETE_COURIER_DOCUMENTS_REQUEST');
export const DELETE_COURIER_DOCUMENTS_SUCCESS = Symbol('DELETE_COURIER_DOCUMENTS_SUCCESS');
export const DELETE_COURIER_DOCUMENTS_ERROR = Symbol('DELETE_COURIER_DOCUMENTS_ERROR');
export const GET_COURIER_DOCUMENTS = Symbol('GET_COURIER_DOCUMENTS');
export const GET_COURIER_DOCUMENTS_REQUEST = Symbol('GET_COURIER_DOCUMENTS_REQUEST');
export const GET_COURIER_DOCUMENTS_SUCCESS = Symbol('GET_COURIER_DOCUMENTS_SUCCESS');
export const GET_COURIER_DOCUMENTS_ERROR = Symbol('GET_COURIER_DOCUMENTS_ERROR');