import {GET_REPORT_EMENU} from "../constants/reportEmenu";
import {IReportEmenu, IReportEmenuFilterForm} from "../../models/Report";
import {call, put, takeLatest} from 'redux-saga/effects';
import {getReportEmenuError, getReportEmenuRequest, getReportEmenuSuccess} from "../actions/reportEmenu";
import {ReportService} from "../../services/ReportService";
import {Pagination} from "../../services/Api";
import {removeUrlParams} from "../../utils/history";

type getReportEmenuType = {
    type: typeof GET_REPORT_EMENU;
    payload: {
        cityId?: number | null;
        page?: number;
        form?: IReportEmenuFilterForm;
    }
}

function* getReportEmenuSaga({payload}:getReportEmenuType) {
    try {
        yield put(getReportEmenuRequest())

        const service = new ReportService();

        const promise = yield call(service.getReportEmenu, payload.cityId, payload.page, payload.form)

        const data:IReportEmenu[] = promise.data || [];
        const pagination = new Pagination(promise.headers).getData();

        yield put(getReportEmenuSuccess(data, pagination.total, pagination.by))

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
        yield put(getReportEmenuError(error.response.data.message || 'Ошибка'))
    }
}

export function* watchGetReportEmenu() {
    yield takeLatest(GET_REPORT_EMENU, getReportEmenuSaga);
}
