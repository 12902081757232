import {createSelector} from "reselect";
import queryString from 'query-string';

const ratioRateUrlSelector = (props: any) => {
    return props.location.search
};

export const ratioRateCityIdReSelector = createSelector(
    [ratioRateUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.cityId ? +getParams.cityId : undefined
    }
);
