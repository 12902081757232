import React, {Component} from 'react';
import {Card, Col, FormGroup, Label, Row} from "reactstrap";
import {RouteComponentProps, withRouter} from "react-router";
import {compose} from "redux";
import {connect} from "react-redux";
import {generateWeekOptions} from "../utils/schedules";
import {AppState} from "../store/reducers";
import {schedulesCityIdReSelector} from "../store/reselectors/schedules";
import ScheduleCopy from "./ScheduleCopy";
import ScheduleAdd from "./ScheduleAdd";
import Dropdown from "../components/UI/Dropdown";



type ScheduleNavbarType = {
    cityId?: number
}

type dateType = { dateStart: string, dateEnd: string };

const dateOptions = generateWeekOptions();

class ScheduleNavbar extends Component<ScheduleNavbarType & RouteComponentProps, { date: dateType }> {
    state = {
        date: dateOptions[0].value
    };

    changeDate = (date: dateType) => {
        this.setState({date})
    };

    render() {
        const {cityId} = this.props;
        const {date} = this.state;

        return (
            <Card body>
                <Row>
                    <Col md={'3'}>
                        <FormGroup>
                            <Label>Новое расписание на неделю</Label>
                            <Dropdown
                                name={'date'}
                                value={date}
                                options={dateOptions}
                                onChange={(value) => this.changeDate(value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={'6'}>
                        {cityId && <ScheduleAdd cityId={cityId} date={date}/>}
                    </Col>
                    <Col md={'6'}>
                        <ScheduleCopy date={date} cityId={cityId}/>
                    </Col>
                </Row>
            </Card>
        )
    }
}

const mapStateToProps = (state: AppState, props: RouteComponentProps) => {
    return {
        cityId: schedulesCityIdReSelector(props)
    }
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, null))(ScheduleNavbar);
