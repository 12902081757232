import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {IScheduleDate, IScheduleDateEditFormRequest} from "../models/Schedule";
import ScheduleDateEdit from "../components/ScheduleDateEdit";
import {
    editScheduleDateForm,
    closeScheduleDateForm,
    getScheduleDateForm
} from "../store/actions/scheduleDateForm";
import {IDictionary} from "../models/Dictionary";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {getDictionaryCouriers} from "../store/actions/dictionaryCouriers";
import {getDictionaryPoints} from "../store/actions/dictionaryPoints";
import Empty from "../components/UI/Empty";


type ScheduleDateEditType = {
    getScheduleDateForm: (id: number, date: string) => void
    editScheduleDateForm: (id: number, date: string, form: IScheduleDateEditFormRequest[]) => void
    getDictionaryPoints: (cityId: number) => void
    getDictionaryCouriers: (cityId: number) => void
    closeScheduleDateForm: () => void
    data: IScheduleDate[]
    getLoading: boolean
    getError: string
    changeLoading: boolean
    changeError: string
    id: number
    date: string
    cityId: number
    points: IDictionary[]
    pointsLoading: boolean
    pointsError: string
    couriers: IDictionary[]
    couriersLoading: boolean
    couriersError: string
}


class ScheduleDateEditContainer extends Component<ScheduleDateEditType> {

    componentDidMount() {
        const {getDictionaryPoints, getDictionaryCouriers, cityId, getScheduleDateForm, id, date} = this.props;

        getScheduleDateForm(id, date);
        getDictionaryPoints(cityId);
        getDictionaryCouriers(cityId);
    }


    render() {
        const {
            changeLoading, changeError, editScheduleDateForm, id, date, points, couriers,
            pointsLoading, pointsError, couriersLoading, couriersError, getDictionaryPoints, cityId, getDictionaryCouriers,
            closeScheduleDateForm, getLoading, getError,data, getScheduleDateForm
        } = this.props;

        if (getLoading || pointsLoading || couriersLoading) {
            return <Loader/>
        }

        if (getError) {
            return <Error error={getError} refresh={() => getScheduleDateForm(id, date)}/>
        }

        if (pointsError) {
            return <Error error={pointsError} refresh={() => getDictionaryPoints(cityId)}/>
        }

        if (couriersError) {
            return <Error error={couriersError} refresh={() => getDictionaryCouriers(cityId)}/>
        }

        if (!!points.length && !!couriers.length && !!data.length) {
            return (
                <ScheduleDateEdit
                    data={data}
                    editScheduleDateForm={(form) => editScheduleDateForm(id, date, form)}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    points={points}
                    couriers={couriers}
                    cancelHandler={closeScheduleDateForm}
                />
            )
        }
        return (
            <Empty>
                {!points.length &&
                <>
                    <h3>Список отправных точек пуст</h3>
                    <p className={'mb-0'}>Добавьте отправные точки</p>
                </>}
                {!couriers.length &&
                <>
                    <h3>Список курьеров пуст</h3>
                    <p className={'mb-0'}>Добавьте курьеров</p>
                </>}
            </Empty>
        )
    }
}

const mapStateToProps = ({scheduleDateForm, dictionaryPoints, dictionaryCouriers}: AppState) => {
    return {
        data: scheduleDateForm.data,
        getLoading: scheduleDateForm.getLoading,
        getError: scheduleDateForm.getError,
        changeLoading: scheduleDateForm.changeLoading,
        changeError: scheduleDateForm.changeError,
        points: dictionaryPoints.data,
        pointsLoading: dictionaryPoints.loading,
        pointsError: dictionaryPoints.error,
        couriers: dictionaryCouriers.data,
        couriersLoading: dictionaryCouriers.loading,
        couriersError: dictionaryCouriers.error
    }
};

const mapDispatchToProps = {
    getScheduleDateForm,
    editScheduleDateForm,
    getDictionaryCouriers,
    getDictionaryPoints,
    closeScheduleDateForm
};


export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDateEditContainer);
