import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import {Card} from "reactstrap";
import ArchiveOrders from "../../containers/ArchiveOrders";
import ArchiveOrderFilter from "../../containers/ArchiveOrderFilter";
import ArchiveOrdersSummary from "../../containers/ArchiveOrdersSummaryContainer";


class ArchiveOrdersPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Архив заказов</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Архив заказов', url: '/archive-orders'}
                ]}/>
                <Card body className={'mb-3'}>
                    <h4 className={'mb-3'}>Фильтр</h4>
                    <ArchiveOrderFilter/>
                </Card>
                <ArchiveOrdersSummary/>
                <ArchiveOrders/>
            </Layout>
        );
    }
}

export default ArchiveOrdersPage;
