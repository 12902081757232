import {createBrowserHistory} from 'history';
import queryString from "query-string";

export const customHistory = createBrowserHistory();


export const addUrlParams = <T extends any>(params: T) => {
    const queryParams = getUrlParams();
    const nextQueryParams = queryString.stringify({...queryParams, ...params});
    customHistory.push(`${customHistory.location.pathname}?${nextQueryParams}`);
};

export const getUrlParams = () => {
    return queryString.parse(customHistory.location.search);
};

export const removeUrlParams = (params?: string[]) => {
    const queryParams = getUrlParams();
    if (params) {
        params.forEach((key) => {
            delete queryParams[key]
        })
    } else {
        Object.keys(queryParams).forEach((key) => {
            delete queryParams[key]
        })
    }
    const nextQueryParams = queryString.stringify(queryParams);
    customHistory.push(`${customHistory.location.pathname}?${nextQueryParams}`);
};
