import {
    GET_SCHEDULE_DATE_ERROR,
    GET_SCHEDULE_DATE_REQUEST,
    GET_SCHEDULE_DATE_SUCCESS
} from "../constants/scheduleDate";
import {IScheduleDate} from "../../models/Schedule";
import {IActionScheduleDate} from "../actions/scheduleDate";


export interface IScheduleDateState {
    loading: boolean
    error: string
    data: IScheduleDate[]
}

const initialState: IScheduleDateState = {
    data: [],
    loading: false,
    error: ''
};

export default function scheduleDate(state = initialState, action: IActionScheduleDate): IScheduleDateState {
    switch (action.type) {
        case GET_SCHEDULE_DATE_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_SCHEDULE_DATE_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_SCHEDULE_DATE_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
