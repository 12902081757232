import {call, put, takeLatest, select} from 'redux-saga/effects';
import {
    changePointCourierFormRequest,
    changePointCourierFormSuccess,
    changePointCourierFormError,
    closeCourierForm
} from "../actions/courierForm";
import {CHANGE_POINT_COURIER_FORM} from "../constants/courierForm";
import {CourierService} from "../../services/CourierService";
import {ICourierChangePointForm} from "../../models/Courier";
import _ from "lodash";
import {getUrlParams} from "../../utils/history";
import {getCouriers} from "../actions/couriers";


type changePointCourierFormType = {
    type: typeof CHANGE_POINT_COURIER_FORM,
    payload: {
        id: number,
        form: ICourierChangePointForm
    }
}

function* changePointCourierFormSaga({payload}: changePointCourierFormType) {
    try {
        yield put(changePointCourierFormRequest());

        const service = new CourierService();
        yield call(service.changePointCourier, payload.id, payload.form);

        yield put(changePointCourierFormSuccess());

        yield put(closeCourierForm());

        const {user} = yield select();
        const params = getUrlParams();
        const cityId = params.cityId ? +params.cityId : _.get(user, 'allowedCities', [])[0];
        yield put(getCouriers(cityId));

    } catch (error) {
        yield put(changePointCourierFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchChangePointCourierForm() {
    yield takeLatest(CHANGE_POINT_COURIER_FORM, changePointCourierFormSaga);
}
