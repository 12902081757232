import {call, put, takeLatest} from 'redux-saga/effects';
import {PointService} from '../../services/PointService';
import {GET_POINT_ZONES} from "../constants/pointZones";
import {getPointZonesError, getPointZonesSuccess, getPointZonesRequest} from '../actions/pointZones';
import {IPointZone, Point} from "../../models/Point";



function* getPointZonesSaga({payload}: { type: typeof GET_POINT_ZONES, payload: number }) {
    try {
        yield put(getPointZonesRequest());

        const service = new PointService();
        const promise = yield call(service.getPointZones, payload);

        const response: IPointZone[] = new Point(promise.data).getZones();

        yield put(getPointZonesSuccess(response));

    } catch (error) {
        yield put(getPointZonesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetPointZones() {
    yield takeLatest(GET_POINT_ZONES, getPointZonesSaga);
}
