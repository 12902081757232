import {Api} from "./Api";
import {
    IScheduleAddFormRequest, IScheduleCopyFormRequest,
    IScheduleDateAddFormRequest,
    IScheduleDateCopyFormRequest,
    IScheduleDateEditFormRequest
} from "../models/Schedule";


export class ScheduleService {
    getSchedules(page: number = 1, cityId?: number, isPublished?: boolean) {
        const form = {cityId, isPublished};
        return new Api().call(`/v1/manage/schedule/search?page=${page}`, {method: 'POST', data: form});
    }

    getSchedule(id: number) {
        return new Api().call(`/v1/manage/schedule/${id}`, {method: 'GET'});
    }

    addSchedule(form: IScheduleAddFormRequest) {
        return new Api().call(`/v1/manage/schedule`, {method: 'POST', data: form});
    }

    copySchedule(id: number, form: IScheduleCopyFormRequest) {
        return new Api().call(`/v1/manage/schedule/${id}/copy`, {method: 'POST', data: form});
    }

    publishSchedule(id: number) {
        return new Api().call(`/v1/manage/schedule/${id}/publish`, {method: 'GET'});
    }

    cancelSchedule(id: number) {
        return new Api().call(`/v1/manage/schedule/${id}/clear`, {method: 'POST'});
    }

    getScheduleDate(id: number, date: string, isActive: boolean = false) {
        return new Api().call(`/v1/manage/schedule/${id}/day/${date}?isActive=${isActive}`, {method: 'GET'});
    }

    addScheduleDate(id: number, date: string, form: IScheduleDateAddFormRequest[]) {
        return new Api().call(`/v1/manage/schedule/${id}/day/${date}`, {method: 'POST', data: form});
    }

    editScheduleDate(id: number, date: string, form: IScheduleDateEditFormRequest[]) {
        return new Api().call(`/v1/manage/schedule/${id}/day/${date}`, {method: 'POST', data: form});
    }

    copyScheduleDate(id: number, form: IScheduleDateCopyFormRequest) {
        return new Api().call(`/v1/manage/schedule/${id}/day/copy`, {method: 'POST', data: form});
    }
}
