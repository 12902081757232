import React, {PureComponent} from 'react';
import {ICourier, ICourierFull} from "../../models/Courier";
import Table from '../UI/Table';
import _ from 'lodash';
import {Button, ButtonGroup, Card, Col, Row} from "reactstrap";
import Icon from "../UI/Icon";
import Avatar from "../UI/Avatar";
import {courierTypeIcons} from "../../utils/couriers";
import {accessCourierLocationEdit} from "../../utils/user-accesses";
import Tile from "../UI/Tile";
import {Link} from "react-router-dom";


interface CouriersType {
    linkHandler: (id: number) => void
    editHandler: (id: number, pointId: number) => void
    courierTrackDialogHandler: (id: number) => void
    data: ICourierFull[]
    accesses?:string[]
}

class Couriers extends PureComponent<CouriersType> {

    render() {
        const {data, linkHandler, editHandler, courierTrackDialogHandler, accesses} = this.props;
        const dataCount = data.length - 1;
        return (
            <Card body>
                {data.map((data, index) => {
                        return (
                            <CouriersList
                                key={data.point.id}
                                isLast={index === dataCount}
                                data={data}
                                linkHandler={linkHandler}
                                editHandler={editHandler}
                                courierTrackDialogHandler={courierTrackDialogHandler}
                                accesses={accesses}
                            />
                        )
                    }
                )}
            </Card>
        )
    }
}

type CouriersListType = {
    data: ICourierFull
    isLast: boolean
    linkHandler: (id: number) => void
    editHandler: (id: number, pointId: number) => void
    courierTrackDialogHandler: (id: number) => void
    accesses?:string[]
}

class CouriersList extends PureComponent<CouriersListType> {
    render() {
        const {data, linkHandler, editHandler, isLast, accesses, courierTrackDialogHandler} = this.props;
        
        const pointCouriersCount = data.couriers.length;
        const pointEnabledCouriersCount = data.couriers.filter(({slot}) => slot?.status).length;
        const pointDisabledCouriersCount = pointCouriersCount - pointEnabledCouriersCount;
        
        return (
            <>
                <h5 className={'mb-1'}>{data.point.name}</h5>
                <div className="d-flex mb-1">
                    <Tile
                      label={"Всего"}
                      value={pointCouriersCount}
                      color={"secondary"}
                    />
                    <Tile
                      label={"Активных"}
                      value={pointEnabledCouriersCount}
                      color={"success"}
                    />
                    <Tile
                      label={"Неактивных"}
                      value={pointDisabledCouriersCount}
                      color={"danger"}
                    />
                </div>
                <Table striped noBorder size={'large'} className={!isLast ? 'mb-4' : ''}>
                    <tbody>
                    <tr>
                        <th style={{width: '33%'}}>Курьер</th>
                        <th style={{width: '33%'}}>Статус</th>
                        <th style={{width: '33%'}}>Статус по заказам</th>
                        {Array.isArray(accesses) && accesses.includes(accessCourierLocationEdit) &&
                        <th style={{width: 100}}>

                        </th>}
                    </tr>
                    {data.couriers.map((items) => {
                            return (
                                <CouriersListItem
                                    key={data.point.id}
                                    pointId={data.point.id}
                                    data={items}
                                    linkHandler={linkHandler}
                                    editHandler={editHandler}
                                    courierTrackDialogHandler={courierTrackDialogHandler}
                                    accesses={accesses}
                                />
                            )
                        }
                    )}
                    </tbody>
                </Table>
            </>
        );
    }
}

type CouriersListItemType = {
    data: ICourier
    linkHandler: (id: number) => void
    editHandler: (id: number, pointId: number) => void
    courierTrackDialogHandler: (id: number) => void
    pointId: number
    accesses?:string[]
}

class CouriersListItem extends PureComponent<CouriersListItemType> {
    render() {
        const {data, linkHandler, editHandler, courierTrackDialogHandler, pointId, accesses} = this.props;
        const {id, phone, fio, slot, orderStatusName = 'Свободен', courierTypeId} = data;
    
        const icon = typeof courierTypeId === "number"
          ? courierTypeIcons[courierTypeId]
          : undefined;

        return (
            <tr>
                <td style={{width: '33%'}}>
                    <Row form className="align-items-center">
                        {courierTypeId && <Col md={'auto'}>
                            <Avatar
                                icon={icon}
                            />
                        </Col>}
                        <Col md={'auto'}>
                            <Link to={`/couriers/${id}`} target={"_blank"} className={"semi-bold"}>{fio}</Link>
                        </Col>
                        <Col md={'auto'}>
                            <a href={`tel:+${phone}`}>
                                <Icon name={'call'} color={'black'}/>
                            </a>
                        </Col>
                    </Row>
                </td>
                <td style={{width: '33%'}}>
                    {_.get(slot, 'status', false) ? <div className={'semi-bold green'}>Активен</div> :
                        <div className={'semi-bold red'}>Не активен</div>}
                </td>
                <td style={{width: '33%'}}>
                    {orderStatusName}
                </td>
                <td style={{width: 100}}>
                    <ButtonGroup>
                        {Array.isArray(accesses) && accesses.includes(accessCourierLocationEdit) &&
                        <Button size={'sm'} color={'outline-light'} onClick={() => editHandler(id, pointId)}>
                            <Icon name={'my_location'} color={'gray-500'}/>
                        </Button>}
                        <Button size={'sm'} color={'outline-light'} onClick={() => linkHandler(id)}>
                            <Icon name={'remove_red_eye'} color={'gray-500'}/>
                        </Button>
                        <Button size={'sm'} color={'outline-light'} onClick={() => courierTrackDialogHandler(id)}
                            title={"Местоположение курьера"}
                        >
                            <Icon name={'map'} color={'gray-500'}/>
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
        );
    }
}

export default Couriers;
