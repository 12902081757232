import React, {PureComponent} from 'react';
import styles from './Dropdown.module.scss';
import classNames from 'classnames';
import Select, {components, Props } from "react-select";
import Icon from "../Icon";


/**
 * Компонент - селект
 */

type OptionType = { label: string; value: any}

interface IDropdown extends Props<OptionType>{
    invalid?:boolean
    value?: any
    defaultValue?: any
    onChange?: (value: any) => void
    options: OptionType[]
}

class Dropdown extends PureComponent<IDropdown> {

    getValue = (value?: any) => {
        const {options} = this.props;

        if (!options.length || value === null) {
            return null;
        }
        if (Array.isArray(value) && options) {
            return options.filter((o) => value.includes(o.value))
        }
        return options.find((o) => o.value === value)
    };

    onChange = (option?: any) => {
        const {onChange} = this.props;
        if (!onChange) {
            return undefined;
        }
        if (option === null) {
            return onChange(null)
        }
        if (Array.isArray(option)) {
            return onChange( option.map(({value}) => value))
        }
        return option && onChange(option.value)
    };

    render() {
        const {defaultValue, name, value, options, components, isDisabled, isMulti, isSearchable = true, isClearable = false, placeholder = "", invalid} = this.props;

        return (
            <Select
                className={classNames(styles.select, invalid && 'is-invalid')}
                isDisabled={isDisabled}
                invalid={invalid}
                name={name}
                defaultValue={defaultValue}
                value={this.getValue(value)}
                onChange={this.onChange}
                options={options}
                placeholder={placeholder}
                isMulti={isMulti}
                isSearchable={isSearchable}
                closeMenuOnSelect={!isMulti}
                isClearable={isClearable}
                menuPlacement="auto"
                components={{
                    Control,
                    Placeholder,
                    IndicatorsContainer,
                    IndicatorSeparator,
                    ClearIndicator,
                    ValueContainer,
                    MultiValue,
                    MultiValueLabel,
                    MultiValueRemove,
                    DropdownIndicator,
                    NoOptionsMessage,
                    Menu,
                    MenuList,
                    Option,
                    Group,
                    GroupHeading,
                    ...components
                }}
            />
        );
    }
}


const Control = (props: any) => {
    const {
        isDisabled,
        menuIsOpen,
        isFocused,
        children,
        innerProps,
        selectProps,
        innerRef,
    } = props;

    return (
        <div
            className={classNames(
                styles['select__control'],
                isDisabled && styles['select__control--disabled'],
                isFocused && styles['select__control--focused'],
                menuIsOpen && styles['select__control--opened'],
                selectProps.invalid && styles['select__control--error']
            )}
            {...innerProps}
            ref={innerRef}
        >
            {children}
        </div>
    );
};

const Placeholder = (props: any) => {
    const {
        children,
        innerProps,
    } = props;

    return (
        <div
            className={classNames(
                styles['select__placeholder']
            )}
            {...innerProps}
        >
            {children}
        </div>
    );
};

const IndicatorsContainer = (props: any) => {
    const {
        children,
        selectProps
    } = props;

    return (
        <div
            className={classNames(
                styles['select__indicatorsContainer'],
                selectProps.invalid && styles['select__indicatorsContainer--error']
            )}
        >
            {children}
        </div>
    );
};

const IndicatorSeparator = (props: any) => {
    const {
        children,
        hasValue,
        innerProps,
    } = props;

    return (
        <div
            className={classNames(
                styles['select__indicatorSeparator'],
                hasValue && styles['select__indicatorSeparator--opened']
            )}
            {...innerProps}
        >
            {children}
        </div>
    );
};

const ClearIndicator = (props: any) => {
    const {
        hasValue,
        innerProps: {ref, ...restInnerProps},
    } = props;

    return (
        <div
            className={classNames(
                styles['select__clearIndicator'],
                hasValue && styles['select__clearIndicator--opened']
            )}
            {...restInnerProps}
            ref={ref}
        >
            <Icon name={'close'} size={'sm'}/>
        </div>
    );
};

const ValueContainer = (props: any) => {
    const {
        children,
    } = props;

    return (
        <div
            className={classNames(
                styles['select__valueContainer']
            )}
        >
            {children}
        </div>
    );
};

const MultiValue = (props: any) => {
    const {
        selectProps,
        innerProps: {...restInnerProps},
        removeProps,
        children,
        components
    } = props;

    return (
        <div
            className={classNames(
                styles['select__multiValue'],
                selectProps.invalid && styles['select__multiValue--error']
            )}
            {...restInnerProps}
            {...removeProps}
        >
            <components.Label>
                {children}
            </components.Label>
            <components.Remove/>
        </div>
    );
};

const MultiValueLabel = (props: any) => {
    const {
        innerProps: {...restInnerProps},
        children,
    } = props;

    return (
        <div
            className={classNames(
                styles['select__multiValueLabel']
            )}
            {...restInnerProps}
        >
            {children}
        </div>
    );
};

const MultiValueRemove = (props: any) => {
    const {
        innerProps: {...restInnerProps},
    } = props;

    return (
        <div
            className={classNames(
                styles['select__multiValueRemove']
            )}
            {...restInnerProps}
        >
            <Icon name={'close'} size={'sm'}/>
        </div>
    );
};

const DropdownIndicator = (props: any) => {
    const {
        innerProps: {...restInnerProps},
        isDisabled,
        selectProps: {menuIsOpen, invalid}
    } = props;

    return (
        <div
            className={classNames(
                styles['select__dropdownIndicator'],
                menuIsOpen && styles['select__dropdownIndicator--opened'],
                isDisabled && styles['select__dropdownIndicator--disabled'],
                invalid && styles['select__dropdownIndicator--error']
            )}
            {...restInnerProps}
        >
            <Icon name={'arrow_drop_down'}/>
        </div>
    );
};

const NoOptionsMessage = (props: any) => {
    return (
        <components.NoOptionsMessage
            {...props}
            className={classNames(styles['select__noOptionsMessage'])}>
            Совпадений не найдено
        </components.NoOptionsMessage>
    );
};


const Menu = (props: any) => {
    const {
        innerProps: {...restInnerProps},
        innerRef,
        placement,
        children
    } = props;

    return (
        <div
            className={classNames(
                styles['select__menu']
            )}
            style={{
                top: placement === 'bottom' ? 'auto' : 'auto',
                bottom: placement === 'bottom' ? 'auto' : '36px'
            }}
            {...restInnerProps}
            ref={innerRef}
        >
            {children}
        </div>
    );
};

const MenuList = (props: any) => {
    const {
        innerProps: {...restInnerProps},
        innerRef,
        children
    } = props;
    return (
        <div
            className={classNames(
                styles['select__menuList']
            )}
            style={{
                maxHeight:240
            }}
            {...restInnerProps}
            ref={innerRef}
        >
            {children}
        </div>
    );
};


export const Option = (props: any) => {
    const {
        innerProps: {...restInnerProps},
        innerRef,
        isSelected,
        isFocused,
        children
    } = props;

    return (
        <div
            className={classNames(
                styles['select__option'],
                (isSelected || isFocused) && styles['select__option--selected']
            )}
            {...restInnerProps}
            ref={innerRef}
        >
            {children}
        </div>
    );
};

const Group = (props: any) => {
    return (
        <components.Group {...props} className={styles['select__group']}/>
    );
};

export const GroupHeading = (props: any) => {
    const {
        children
    } = props;

    return (
        <div
            className={classNames(
                styles['select__groupHeading']
            )}
        >
            {children}
        </div>
    );
};


export default Dropdown;
