import {IActionCouriersShifts} from "../actions/couriersShifts";
import {
    GET_COURIERS_SHIFTS_ERROR,
    GET_COURIERS_SHIFTS_REQUEST,
    GET_COURIERS_SHIFTS_SUCCESS
} from "../constants/couriersShifts"

export interface ICouriersShiftsState {
    loading: boolean
    error: string
    data: any
    total: number
    by: number
}

const initialState = {
    data: [],
    loading: false,
    error: '',
    total: 0,
    by: 0
}

export default function couriersShifts(state = initialState, action: IActionCouriersShifts): ICouriersShiftsState {
    switch (action.type) {
        case GET_COURIERS_SHIFTS_REQUEST:
            return {
                data: [],
                loading: true,
                error: '',
                total: 0,
                by: 0
            }
        case GET_COURIERS_SHIFTS_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                data,
                loading: false,
                error: '',
                total,
                by
            };
        case GET_COURIERS_SHIFTS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload,
                total: 0,
                by: 0
            };
        default:
            return state;
    }
}