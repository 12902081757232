import {Api} from "./Api";
import {IAuthChangePasswordRequest, IAuthRequest} from "../models/Auth";


export class AuthService {

    login(data: IAuthRequest) {
        return new Api().call('/v1/auth/login', {method: 'POST', data});
    }

    getUser() {
        return new Api().call('/v1/user', {method: 'GET'});
    }

    changePasswordUser(form: IAuthChangePasswordRequest) {
        return new Api().call('/v1/user/change-password', {method: 'POST', data:form});
    }
}
