import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import CompanyAdd from "../../containers/CompanyAdd";


class CompanyAddPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Новый контрагент</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Контрагенты', url: '/companies'},
                    {name: 'Новый контрагент', url: '/companies/add'}
                ]}/>
                <CompanyAdd/>
            </Layout>
        );
    }
}

export default CompanyAddPage;
