export const GET_SALARY = Symbol('GET_SALARY');
export const GET_SALARY_REQUEST = Symbol('GET_SALARY_REQUEST');
export const GET_SALARY_SUCCESS = Symbol('GET_SALARY_SUCCESS');
export const GET_SALARY_ERROR = Symbol('GET_SALARY_ERROR');

export const GET_SALARY_TOTAL = Symbol('GET_SALARY_TOTAL');
export const GET_SALARY_TOTAL_REQUEST = Symbol('GET_SALARY_TOTAL_REQUEST');
export const GET_SALARY_TOTAL_SUCCESS = Symbol('GET_SALARY_TOTAL_SUCCESS');
export const GET_SALARY_TOTAL_ERROR = Symbol('GET_SALARY_TOTAL_ERROR');

export const PAY_SALARY = Symbol('PAY_SALARY');
export const PAY_SALARY_REQUEST = Symbol('PAY_SALARY_REQUEST');
export const PAY_SALARY_SUCCESS = Symbol('PAY_SALARY_SUCCESS');
export const PAY_SALARY_ERROR = Symbol('PAY_SALARY_ERROR');

export const PAY_GROUP_SALARY = Symbol('PAY_GROUP_SALARY');
export const PAY_GROUP_SALARY_REQUEST = Symbol('PAY_GROUP_SALARY_REQUEST');
export const PAY_GROUP_SALARY_SUCCESS = Symbol('PAY_GROUP_SALARY_SUCCESS');
export const PAY_GROUP_SALARY_ERROR = Symbol('PAY_GROUP_SALARY_ERROR');
