import {
  accessCompanyShow,
  accessCourierShow,
  accessCourierSlotManage,
  accessDirectoryCities,
  accessDirectoryPenalties,
  accessDirectoryRoles,
  accessEmployeeShow, accessNotificationManage,
  accessOrderShow, accessPaymentShow, accessPointShow, accessReportCompanyManage, accessReportCourierManage,
  accessReportEmenuView,
  accessSalaryManage,
  accessScheduleShow, courierRateManage
} from "./user-accesses";

/**
 * Определить изначальный путь, который открывается при входе или при переходе на адрес "/".
 * @param accesses - зависит от прав пользователя
 */
export const determineDefaultPath = (
  accesses: string[] = [],
): string => {
  
  /**
   * /profile доступен для всех пользователей
   */
  if (!accesses.length) {
    return '/profile';
  }
  
  for (const {path, requirement} of possibleDefaultPathObjects) {
    if (accesses.includes(requirement)) {
      return path;
    }
  }
  
  return '/profile';
}

type PossibleDefaultPathObject = {
  path: string;
  requirement: string;
}

/**
 * Возможные изначальные пути:
 * Заказы, Расписание, Выплаты, Отчет по заказам, Сотрудники.
 * Далее порядок не имеет значения.
 */
const possibleDefaultPathObjects: PossibleDefaultPathObject[] = [
  {path: '/orders', requirement: accessOrderShow},
  {path: '/schedules', requirement: accessScheduleShow},
  {path: '/salary', requirement: accessSalaryManage},
  {path: '/reports/emenu', requirement: accessReportEmenuView},
  {path: '/employees', requirement: accessEmployeeShow},
  {path: '/companies', requirement: accessCompanyShow},
  {path: '/couriers', requirement: accessCourierShow},
  {path: '/couriers-shifts', requirement: accessCourierSlotManage},
  {path: '/reports/companies', requirement: accessReportCompanyManage},
  {path: '/reports/couriers', requirement: accessReportCourierManage},
  {path: '/payments', requirement: accessPaymentShow},
  {path: '/points', requirement: accessPointShow},
  {path: '/directories/cities', requirement: accessDirectoryCities},
  {path: '/directories/roles', requirement: accessDirectoryRoles},
  {path: '/directories/penalties', requirement: accessDirectoryPenalties},
  {path: '/settings/courier-rate', requirement: courierRateManage},
  {path: '/notifications', requirement: accessNotificationManage},
]
