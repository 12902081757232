import {
    ADD_CITY_FORM_REQUEST,
    ADD_CITY_FORM_SUCCESS,
    ADD_CITY_FORM_ERROR,
    EDIT_CITY_FORM_REQUEST,
    EDIT_CITY_FORM_SUCCESS,
    EDIT_CITY_FORM_ERROR,
    GET_CITY_FORM_REQUEST,
    GET_CITY_FORM_SUCCESS,
    GET_CITY_FORM_ERROR,
    DELETE_CITY_FORM_ERROR,
    DELETE_CITY_FORM_SUCCESS,
    DELETE_CITY_FORM_REQUEST,
    OPEN_CITY_FORM,
    CLOSE_CITY_FORM
} from '../constants/cityForm'
import {ICity} from '../../models/City';
import {IActionCityForm} from '../actions/cityForm';


export interface ICityFormState {
    getLoading: boolean
    getError: string
    data: ICity | null
    changeLoading: boolean
    changeError: string
    mode: 'add' | 'edit' | 'delete' | null
    modeId: number | null
}

const initialState: ICityFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null,
    modeId: null
};

export default function cityForm(state = initialState, action: IActionCityForm): ICityFormState {
    switch (action.type) {
        case GET_CITY_FORM_REQUEST:
            return {
                ...state,
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_CITY_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_CITY_FORM_ERROR:
            return {
                ...state,
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case ADD_CITY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_CITY_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_CITY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case EDIT_CITY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case EDIT_CITY_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case EDIT_CITY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case DELETE_CITY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case DELETE_CITY_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case DELETE_CITY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_CITY_FORM:
            const {mode, modeId = null} = action.payload;
            return {
                ...initialState,
                mode,
                modeId
            };
        case CLOSE_CITY_FORM:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
