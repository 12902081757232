import {
    ADD_SCHEDULE_DATE_FORM_REQUEST,
    ADD_SCHEDULE_DATE_FORM_SUCCESS,
    ADD_SCHEDULE_DATE_FORM_ERROR,
    EDIT_SCHEDULE_DATE_FORM_REQUEST,
    EDIT_SCHEDULE_DATE_FORM_SUCCESS,
    EDIT_SCHEDULE_DATE_FORM_ERROR,
    GET_SCHEDULE_DATE_FORM_REQUEST,
    GET_SCHEDULE_DATE_FORM_SUCCESS,
    GET_SCHEDULE_DATE_FORM_ERROR,
    OPEN_SCHEDULE_DATE_FORM,
    CLOSE_SCHEDULE_DATE_FORM,
    COPY_SCHEDULE_DATE_FORM_REQUEST,
    COPY_SCHEDULE_DATE_FORM_SUCCESS,
    COPY_SCHEDULE_DATE_FORM_ERROR
} from '../constants/scheduleDateForm'
import { IScheduleDate } from '../../models/Schedule';
import {IActionScheduleDateForm} from "../actions/scheduleDateForm";



export interface IScheduleDateFormState {
    getLoading: boolean
    getError: string
    data: IScheduleDate[]
    changeLoading: boolean
    changeError: string
    mode: 'add' | 'edit' | null
}


const initialState: IScheduleDateFormState = {
    data: [],
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null
};

export default function scheduleDateForm(state = initialState, action: IActionScheduleDateForm): IScheduleDateFormState {
    switch (action.type) {
        case GET_SCHEDULE_DATE_FORM_REQUEST:
            return {
                ...state,
                data: [],
                getLoading: true,
                getError: ''
            };
        case GET_SCHEDULE_DATE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_SCHEDULE_DATE_FORM_ERROR:
            return {
                ...state,
                data: [],
                getLoading: false,
                getError: action.payload
            };
        case ADD_SCHEDULE_DATE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_SCHEDULE_DATE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_SCHEDULE_DATE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case EDIT_SCHEDULE_DATE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case EDIT_SCHEDULE_DATE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case EDIT_SCHEDULE_DATE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case COPY_SCHEDULE_DATE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case COPY_SCHEDULE_DATE_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case COPY_SCHEDULE_DATE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_SCHEDULE_DATE_FORM:
            return {
                ...initialState,
                mode: action.payload
            };
        case CLOSE_SCHEDULE_DATE_FORM:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
