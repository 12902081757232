import {
    CHANGE_BONUS_RATE, CHANGE_BONUS_RATE_ERROR, CHANGE_BONUS_RATE_REQUEST, CHANGE_BONUS_RATE_SUCCESS,
    GET_BONUS_RATE,
    GET_BONUS_RATE_ERROR,
    GET_BONUS_RATE_REQUEST,
    GET_BONUS_RATE_SUCCESS
} from "../constants/bonusRate";
import {IAction} from "./index";
import {ICourierBonusRate, ICourierBonusRateResponse} from "../../models/Courier";

export interface IActionBonusRate extends IAction<symbol, any> {

}

export const getBonusRate = (cityId: string) => {
    return {
        type: GET_BONUS_RATE,
        payload: {cityId}
    }
}

export const getBonusRateRequest = () => {
    return {
        type: GET_BONUS_RATE_REQUEST
    }
}

export const getBonusRateSuccess = (data: ICourierBonusRate[]) => {
    return {
        type: GET_BONUS_RATE_SUCCESS,
        payload: {data}
    }
}

export const getBonusRateError = (payload: string) => {
    return {
        type: GET_BONUS_RATE_ERROR,
        payload
    }
}

export const changeBonusRate = (form: {cityId: number, typeId: number, rates: ICourierBonusRateResponse[]}) => {
    return {
        type: CHANGE_BONUS_RATE,
        payload: form
    }
}

export const changeBonusRateRequest = (typeId: number) => {
    return {
        type: CHANGE_BONUS_RATE_REQUEST,
        payload: {typeId}
    }
}

export const changeBonusRateSuccess = () => {
    return {
        type: CHANGE_BONUS_RATE_SUCCESS
    }
}

export const changeBonusRateError = (payload: string) => {
    return {
        type: CHANGE_BONUS_RATE_ERROR,
        payload
    }
}


