import {call, put, takeLatest} from 'redux-saga/effects';
import {
    changeStatusOrdersSuccess,
    getOrdersError,
    getOrdersRequest,
    getOrdersSuccess
} from '../actions/orders';
import {CHANGE_STATUS_ORDERS, GET_ORDERS} from "../constants/orders";
import {OrderService} from "../../services/OrderService";
import {IOrderRequest, IOrderShort} from "../../models/Order";
import {addNotify} from "../actions/notify";
import {IReportCourierFilterRequest, IReportCourierSortRequest} from "../../models/Report";
import {Pagination} from "../../services/Api";
import {removeUrlParams} from "../../utils/history";



type getOrdersType = {
    type: typeof GET_ORDERS,
    payload: {
        page?:number,
        form?: IOrderRequest
    }
}

function* getOrdersSaga({payload}:getOrdersType) {
    try {
        yield put(getOrdersRequest());

        const service = new OrderService();

        const promise = yield call(service.getOrders, payload.page, payload.form);

        const data:IOrderShort[] = promise.data || [];
        const pagination = new Pagination(promise.headers).getData();

        yield put(getOrdersSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
        console.log(error)
        yield put(getOrdersError(error.response?.data.message || 'Ошибка'));
    }
}

export function* watchGetOrders() {
    yield takeLatest(GET_ORDERS, getOrdersSaga);
}


type changeStatusOrdersType = {
    type: typeof CHANGE_STATUS_ORDERS,
    payload:{
        id: string
        status: number
    }
}

function* changeStatusOrdersSaga({payload}:changeStatusOrdersType) {
    try {
        const service = new OrderService();
        const promise = yield call(service.changeStatusOrders, payload.id, payload.status);
        const response:IOrderShort = promise.data;
        yield put(changeStatusOrdersSuccess(response));

    } catch (error) {
        yield put(addNotify(error.response.data.message || 'Ошибка'));
    }
}

export function* watchChangeStatusOrders() {
    yield takeLatest(CHANGE_STATUS_ORDERS, changeStatusOrdersSaga);
}

