import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addScheduleDateFormRequest,
    addScheduleDateFormSuccess,
    addScheduleDateFormError,
    closeScheduleDateForm,
    editScheduleDateFormRequest,
    editScheduleDateFormSuccess,
    editScheduleDateFormError,
    getScheduleDateFormRequest,
    getScheduleDateFormSuccess,
    getScheduleDateFormError,
    copyScheduleDateFormRequest, copyScheduleDateFormSuccess, copyScheduleDateFormError
} from "../actions/scheduleDateForm";
import {
    ADD_SCHEDULE_DATE_FORM,
    COPY_SCHEDULE_DATE_FORM,
    EDIT_SCHEDULE_DATE_FORM,
    GET_SCHEDULE_DATE_FORM
} from "../constants/scheduleDateForm";
import {
    IScheduleDate,
    IScheduleDateAddFormRequest,
    IScheduleDateCopyFormRequest,
    IScheduleDateEditFormRequest
} from '../../models/Schedule';
import {ScheduleService} from "../../services/ScheduleService";
import {getScheduleDate} from "../actions/scheduleDate";



type addScheduleDateFormType = {
    type: typeof ADD_SCHEDULE_DATE_FORM,
    payload: {
        id: number,
        date: string,
        form: IScheduleDateAddFormRequest[]
    }
}
function* addScheduleDateFormSaga({payload}: addScheduleDateFormType) {
    try {
        yield put(addScheduleDateFormRequest());

        const service = new ScheduleService();
        const promise = yield call(service.addScheduleDate, payload.id, payload.date, payload.form);

        const response:IScheduleDate = promise.data;

        yield put(addScheduleDateFormSuccess(response));

        yield put(closeScheduleDateForm());

    } catch (error) {
        yield put(addScheduleDateFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddScheduleDateForm() {
    yield takeLatest(ADD_SCHEDULE_DATE_FORM, addScheduleDateFormSaga);
}


type editScheduleDateFormType = {
    type: typeof EDIT_SCHEDULE_DATE_FORM,
    payload: {
        id: number,
        date: string,
        form: IScheduleDateEditFormRequest[]
    }
}

function* editScheduleDateFormSaga({payload}: editScheduleDateFormType) {
    try {
        yield put(editScheduleDateFormRequest());

        const service = new ScheduleService();
        const promise = yield call(service.editScheduleDate, payload.id, payload.date, payload.form);

        const response:IScheduleDate = promise.data;

        yield put(editScheduleDateFormSuccess(response));

        yield put(closeScheduleDateForm());

    } catch (error) {
        yield put(editScheduleDateFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditScheduleDateForm() {
    yield takeLatest(EDIT_SCHEDULE_DATE_FORM, editScheduleDateFormSaga);
}

type getScheduleDateFormType = {
    type: typeof GET_SCHEDULE_DATE_FORM,
    payload:{
        id: number,
        date: string
    }
}

function* getScheduleDateFormSaga({payload}: getScheduleDateFormType) {
    try {
        yield put(getScheduleDateFormRequest());

        const service = new ScheduleService();
        const promise = yield call(service.getScheduleDate, payload.id, payload.date);

        const response:IScheduleDate = promise.data;

        yield put(getScheduleDateFormSuccess(response));

    } catch (error) {
        yield put(getScheduleDateFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetScheduleDateForm() {
    yield takeLatest(GET_SCHEDULE_DATE_FORM, getScheduleDateFormSaga);
}


type copyScheduleDateFormType = {
    type: typeof COPY_SCHEDULE_DATE_FORM,
    payload:{
        id: number,
        form: IScheduleDateCopyFormRequest
    }
}

function* copyScheduleDateFormSaga({payload}: copyScheduleDateFormType) {
    try {
        yield put(copyScheduleDateFormRequest());

        const service = new ScheduleService();
        yield call(service.copyScheduleDate, payload.id, payload.form);

        yield put(copyScheduleDateFormSuccess());

        yield put(getScheduleDate(payload.id, payload.form.dateTo, false));

    } catch (error) {
        yield put(copyScheduleDateFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchCopyScheduleDateForm() {
    yield takeLatest(COPY_SCHEDULE_DATE_FORM, copyScheduleDateFormSaga);
}
