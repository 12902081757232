import React, {Component} from "react"
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {getReportEmenu} from "../store/actions/reportEmenu"
import {IReportEmenu, IReportEmenuFilterExtendedForm} from "../models/Report";
import {AppState} from "../store/reducers";
import _ from "lodash";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import Empty from "../components/UI/Empty";
import TableGrid from "../components/UI/TableGrid";
import queryString from "query-string";
import {customHistory} from "../utils/history"
import {reportEmenuFilterReSelector} from "../store/reselectors/reportEmenu";
import ReportEmenuFilter from "./ReportEmenuFilter";
import {format, parseISO} from "date-fns";
import Icon from "../components/UI/Icon";
import {day} from "../store/constants/reportEmenu";
import {ICityShortResponse} from "../models/City";
import yandexLogo from "../assets/yandex.svg";

type ReportEmenuType = {
    page: number;
} & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class ReportEmenuContainer extends Component <ReportEmenuType, RouteComponentProps> {

    putInitialFilterInQuery = () => {
        const nextQueryParams = queryString.stringify({
            month: Number(format(new Date(), "MM")),
            year: Number(format(new Date(), "yyyy")),
        });
    
        customHistory.push(`${customHistory.location.pathname}?${nextQueryParams}`);
    }
    
    componentDidMount() {
        const {getReportEmenu, page, filter} = this.props;

        if (!filter) {
            this.putInitialFilterInQuery();
            return;
        }
        
        const {
            cityId,
            ...rest
        } = filter;
        
        getReportEmenu(cityId, page, !_.isEmpty(rest) ? rest : undefined)
    }

    componentDidUpdate(prevProps: Readonly<ReportEmenuType & RouteComponentProps>) {
        const {page, filter, getReportEmenu} = this.props;
        
        if (!filter || _.isEmpty(filter)) {
            this.putInitialFilterInQuery();
            return;
        }

        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter)) {
            const {
                cityId,
                ...rest
            } = filter;
            
            getReportEmenu(cityId, page, !_.isEmpty(rest) ? rest : undefined)
        }
    }

    changeHandler = (form: IReportEmenuFilterExtendedForm) => {

        const filterParams = {
            year: form.year,
            month: typeof form.month === "number" ? form.month + 1 : null,
            cityId: form.cityId,
        };

        const filter = _.pickBy(filterParams, function (value) {
            return !(value === undefined || value === null);
        });

        const nextQueryParams = queryString.stringify(filter);
        customHistory.push(`${customHistory.location.pathname}?${nextQueryParams}`);
    };

    filterHandler = (form: IReportEmenuFilterExtendedForm) => {
        this.changeHandler(form)
    }

    render() {
        const {data, loading, error, filter, getReportEmenu, allowedCities} = this.props

        const getParams = queryString.parse(customHistory.location.search);

        const filterData = filter && {
            dateItem: Number(getParams.month) - 1,
            countOrders: Number(getParams.year)
        };
    
        /**
         * paymentPenalties обычно содержит позитивные значения.
         * Чтобы отображать Штрафы в футере со знаком минус, нужно изначально передавать негативные значения
         */
        const modifiedForDisplayData = data.map(i => ({
            ...i,
            paymentPenalties: i.paymentPenalties > 0 ? -i.paymentPenalties : i.paymentPenalties,
            paymentForExternal: typeof i.paymentForExternal === "number" ? i.paymentForExternal : 0,
        }))

        if (error) {
            const refresh = () => {
                if (filter) {
                    getReportEmenu(filter.cityId)
                }
            }
            
            return <Error error={error} refresh={refresh}/>
        }

        return (
            <>
                <div className={'mb-3'}>
                    <ReportEmenuFilter
                        params={getParams}
                        handler={this.filterHandler}
                        allowedCities={allowedCities}
                    />
                    {loading ? <Loader/> :
                        !!data.length ?
                            <div className={'table-responsive'}>
                            <TableGrid
                                sourceData={modifiedForDisplayData}
                                hasBorder={true}
                                filterData={filterData}
                                headerGroupColumns={[
                                    {
                                        label: "",
                                        colspan: 1
                                    },
                                    {
                                        label: "",
                                        colspan: 1
                                    },
                                    {
                                        label: "Доставка",
                                        colspan: 3
                                    },
                                    {
                                        label: "Заказы",
                                        colspan: 2
                                    },
                                    {
                                        label: "",
                                        colspan: 1
                                    },
                                    {
                                        label: "",
                                        colspan: 1
                                    },
                                    {
                                        label: "Курьер на смене",
                                        colspan: 4
                                    },
                                    {
                                        label: "Заказы по зонам",
                                        colspan: 5
                                    },
                                    {
                                        label: "Выполнено",
                                        colspan: 5
                                    },
                                    {
                                        label: "Оплата курьерам",
                                        colspan: 6
                                    },
                                    {
                                        label: "",
                                        colspan: 1
                                    }
                                ]}
                                columns={[
                                    {
                                        label: "Дата",
                                        key: "dateItem",
                                        width: 150,
                                        render: (item: IReportEmenu) => {
                                            if (item.dateItem) {
                                                const dayNumber = new Date(item.dateItem).getDay()
                                                const dayTitle: string = day[dayNumber];
                                                const result: string = `
                                                ${item.dateItem && format(parseISO(item.dateItem), 'dd-MM-yyyy')} (${dayTitle})`
                                                const color = (dayNumber === 0 || dayNumber === 6) ? "#DC3545" : "#1A1B1C";
                                                const fontWeight = (dayNumber === 0 || dayNumber === 6) ? 800 : 400;

                                                return (
                                                    <div style={{color, fontWeight}}>{result}</div>
                                                )
                                            }
                                        }
                                    },
                                    {
                                        label: "Заказов",
                                        key: "countOrders"
                                    },
                                    {
                                        label: "Платная",
                                        key: "countPaidDelivery"
                                    },
                                    {
                                        label: "Бесплатная",
                                        key: "countFreeDelivery"
                                    },
                                    {
                                        label: "Комиссия",
                                        key: "incomeDelivery"
                                    },
                                    {
                                        label: 'Сумма',
                                        key: 'sumOrders'
                                    },
                                    {
                                        label: 'Комиссия',
                                        key: 'incomeOrders'
                                    },
                                    {
                                        label: 'Эквайринг',
                                        key: 'acquiringCommission'
                                    },
                                    {
                                        label: 'Доход',
                                        key: 'income'
                                    },
                                    {
                                        label: <Icon name={"directions_walk"}/>,
                                        key: 'countWalkingCouriers',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"directions_bike"}/>,
                                        key: 'countBicycleCouriers',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"two_wheeler"}/>,
                                        key: 'countBikeCouriers',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"directions_car"}/>,
                                        key: 'countAutoCouriers',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"directions_walk"}/>,
                                        key: 'countOrdersInsideWalkingDz',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"directions_bike"}/>,
                                        key: 'countOrdersInsideBicycleDz',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"two_wheeler"}/>,
                                        key: 'countOrdersInsideBikeDz',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"directions_car"}/>,
                                        key: 'countOrdersInsideAutoDz',
                                        width: 35
                                    },
                                    {
                                        label: <img alt={"yandex"} src={yandexLogo} height={24}/>,
                                        key: 'countOrdersInsideExternalDz',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"directions_walk"}/>,
                                        key: 'countOrdersByWalkingCouriers',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"directions_bike"}/>,
                                        key: 'countOrdersByBicycleCouriers',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"two_wheeler"}/>,
                                        key: 'countOrdersByBikeCouriers',
                                        width: 35
                                    },
                                    {
                                        label: <Icon name={"directions_car"}/>,
                                        key: 'countOrdersByAutoCouriers',
                                        width: 35
                                    },
                                    {
                                        label: <img alt={"yandex"} src={yandexLogo} height={24}/>,
                                        key: 'countOrdersByExternal',
                                        width: 35
                                    },
                                    {
                                        label: 'Итого',
                                        key: 'payment'
                                    },
                                    {
                                        label: 'Заказы',
                                        key: 'paymentForOrders'
                                    },
                                    {
                                        label: 'Часы',
                                        key: 'paymentForHours'
                                    },
                                    {
                                        label: 'Бонусы',
                                        key: 'paymentBonuses'
                                    },
                                    {
                                        label: 'Штрафы',
                                        key: 'paymentPenalties',
                                    },
                                    {
                                        label: <img alt={"yandex"} src={yandexLogo} height={24}/>,
                                        key: 'paymentForExternal',
                                    },
                                    {
                                        label: 'Прибыль',
                                        key: 'profit',
                                        render: (item: IReportEmenu) => {
                                            const color = item.profit > 0 ? "#29A745" : "#DC3545";
                                            return <div style={{color, fontWeight: 800}}>{item.profit}</div>
                                        }
                                    },
                                ]}
                                footerColumns={[
                                    {
                                        value: "Итого",
                                        key: "dateItem"
                                    },
                                    {
                                        key: "countOrders"
                                    },
                                    {
                                        key: "countPaidDelivery"
                                    },
                                    {
                                        key: "countFreeDelivery"
                                    },
                                    {
                                        key: "incomeDelivery"
                                    },
                                    {
                                        key: 'sumOrders'
                                    },
                                    {
                                        key: 'incomeOrders'
                                    },
                                    {
                                        key: 'acquiringCommission'
                                    },
                                    {
                                        key: 'income'
                                    },
                                    {
                                        hidden: true,
                                        key: 'countWalkingCouriers',
                                    },
                                    {
                                        hidden: true,
                                        key: 'countBicycleCouriers',
                                    },
                                    {
                                        hidden: true,
                                        key: 'countBikeCouriers',
                                    },
                                    {
                                        hidden: true,
                                        key: 'countAutoCouriers',
                                    },
                                    {
                                        key: 'countOrdersInsideWalkingDz',
                                    },
                                    {
                                        key: 'countOrdersInsideBicycleDz',
                                    },
                                    {
                                        key: 'countOrdersInsideBikeDz',
                                    },
                                    {
                                        key: 'countOrdersInsideAutoDz',
                                    },
                                    {
                                        key: 'countOrdersInsideExternalDz',
                                    },
                                    {
                                        key: 'countOrdersByWalkingCouriers',
                                    },
                                    {
                                        key: 'countOrdersByBicycleCouriers',
                                    },
                                    {
                                        key: 'countOrdersByBikeCouriers',
                                    },
                                    {
                                        key: 'countOrdersByAutoCouriers',
                                    },
                                    {
                                        key: 'countOrdersByExternal',
                                    },
                                    {
                                        key: 'payment'
                                    },
                                    {
                                        key: 'paymentForOrders'
                                    },
                                    {
                                        key: 'paymentForHours'
                                    },
                                    {
                                        key: 'paymentBonuses'
                                    },
                                    {
                                        key: 'paymentPenalties'
                                    },
                                    {
                                        key: 'paymentForExternal'
                                    },
                                    {
                                        key: 'profit'
                                    },
                                ]}
                                options={{
                                    alsoShowColumnsInFooter: true,
                                }}
                            />
                            </div>
                            :
                            <Empty>
                                <h5 className={'mb-0'}>Список пуст</h5>
                            </Empty>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = ({reportEmenu, auth: {user}}: AppState, props: RouteComponentProps) => {
    const {data, total, by, loading, error} = reportEmenu;

    return {
        data,
        filter: reportEmenuFilterReSelector(props),
        loading,
        error,
        total,
        by,
        allowedCities: _.get(user, 'allowedCities', [] as ICityShortResponse[])
    }
}

const mapDispatchToProps = {
    getReportEmenu
}

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(ReportEmenuContainer)
