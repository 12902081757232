import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addCompanyFormError,
    addCompanyFormRequest,
    addCompanyFormSuccess,
    editCompanyFormRequest,
    editCompanyFormSuccess,
    editCompanyFormError,
    getCompanyFormRequest,
    getCompanyFormSuccess,
    getCompanyFormError,
    deleteCompanyFormRequest,
    deleteCompanyFormSuccess,
    deleteCompanyFormError,
    closeCompanyForm
} from "../actions/companyForm";
import {ADD_COMPANY_FORM, DELETE_COMPANY_FORM, EDIT_COMPANY_FORM, GET_COMPANY_FORM} from "../constants/companyForm";
import {ICompanyAddFormRequest, ICompany, ICompanyEditFormRequest} from '../../models/Company';
import {CompanyService} from "../../services/CompanyService";
import {customHistory, removeUrlParams} from "../../utils/history";
import {getCompanies} from "../actions/companies";


function* addCompanyFormSaga({payload}: { type: typeof ADD_COMPANY_FORM, payload: ICompanyAddFormRequest }) {
    try {
        yield put(addCompanyFormRequest());

        const service = new CompanyService();
        const promise = yield call(service.addCompany, payload);

        const response:ICompany = promise.data;

        yield put(addCompanyFormSuccess(response));

        customHistory.goBack()

    } catch (error) {
        yield put(addCompanyFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddCompanyForm() {
    yield takeLatest(ADD_COMPANY_FORM, addCompanyFormSaga);
}

type editCompanyFormType = {
    type: typeof EDIT_COMPANY_FORM,
    payload: {
        id: number,
        form: ICompanyEditFormRequest
    }
}

function* editCompanyFormSaga({payload}: editCompanyFormType) {
    try {
        yield put(editCompanyFormRequest());

        const service = new CompanyService();
        const promise = yield call(service.editCompany, payload.id, payload.form);

        const response:ICompany = promise.data;

        yield put(editCompanyFormSuccess(response));

        customHistory.goBack()

    } catch (error) {
        yield put(editCompanyFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditCompanyForm() {
    yield takeLatest(EDIT_COMPANY_FORM, editCompanyFormSaga);
}

type getCompanyFormType = {
    type: typeof GET_COMPANY_FORM,
    payload: number
}

function* getCompanyFormSaga({payload}: getCompanyFormType) {
    try {
        yield put(getCompanyFormRequest());

        const service = new CompanyService();
        const promise = yield call(service.getCompany, payload);

        const response:ICompany = promise.data;

        yield put(getCompanyFormSuccess(response));

    } catch (error) {
        yield put(getCompanyFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCompanyForm() {
    yield takeLatest(GET_COMPANY_FORM, getCompanyFormSaga);
}

function* deleteCompanyFormSaga({payload}: { type: typeof DELETE_COMPANY_FORM, payload: number }) {
    try {
        yield put(deleteCompanyFormRequest());

        const service = new CompanyService();
        yield call(service.deleteCompany, payload);
        yield put(deleteCompanyFormSuccess());

        yield put(closeCompanyForm());

        yield put(getCompanies());
        removeUrlParams(['page'])

    } catch (error) {
        yield put(deleteCompanyFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeleteCompanyForm() {
    yield takeLatest(DELETE_COMPANY_FORM, deleteCompanyFormSaga);
}
