import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import {RouteComponentProps} from "react-router-dom";
import Layout from "../../containers/Layout";
import CompanyEdit from "../../containers/CompanyEdit";



type RouteParamsType = { id: string }

class CompanyEditPage extends Component<RouteComponentProps<RouteParamsType>> {
    render() {
        const {match} = this.props;
        const id = +match.params.id;

        return (
            <Layout>
                <Title>Редактирование контрагента</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Контрагенты', url: `/companies`},
                    {name: 'Редактирование контрагента', url: `/companies/edit/${id}`}
                ]}/>
                <CompanyEdit id={id}/>
            </Layout>
        );
    }
}

export default CompanyEditPage;
