import {
    GET_FILTERED_COURIERS_LIST_ERROR,
    GET_FILTERED_COURIERS_LIST_REQUEST,
    GET_FILTERED_COURIERS_LIST_SUCCESS
} from "../constants/courierChange";
import {IActionCouriersFilteredList} from "../actions/couriersFilteredList";
import {ICourier} from "../../models/Courier";

export interface ICourierChangeState {
    data: ICourier[]
    loading: boolean
    error: string
}

const initialState: ICourierChangeState = {
    data: [],
    loading: false,
    error: ''
}

export default function couriersFilteredList(state = initialState, action: IActionCouriersFilteredList): ICourierChangeState {
    switch (action.type) {
        case GET_FILTERED_COURIERS_LIST_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_FILTERED_COURIERS_LIST_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_FILTERED_COURIERS_LIST_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}