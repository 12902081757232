import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import Notifications from "../../containers/Notifications";


class NotificationsPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Уведомления</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Уведомления', url: `/notifications`}
                ]}/>
                <Notifications/>
            </Layout>
        );
    }
}

export default NotificationsPage;
