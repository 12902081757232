import {DELETE_RATIO_RATE_ERROR, DELETE_RATIO_RATE_REQUEST, DELETE_RATIO_RATE_SUCCESS} from "../constants/ratioRate";
import {IActionRatioRate} from "../actions/ratioRate";

export interface IRatioRateDeleteState {
    loading: boolean
    error: string
}

const initialState: IRatioRateDeleteState = {
    loading: false,
    error: ''
}

export default function ratioRateDelete(state = initialState, action: IActionRatioRate): IRatioRateDeleteState {
    switch (action.type) {
        case DELETE_RATIO_RATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case DELETE_RATIO_RATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: ''
            };
        case DELETE_RATIO_RATE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}