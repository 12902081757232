import {GET_CITIES_ERROR, GET_CITIES_REQUEST, GET_CITIES_SUCCESS} from "../constants/cities";
import {IActionCities} from "../actions/cities";
import { ICity } from "../../models/City";


export interface ICitiesState {
    loading: boolean
    error: string
    data: ICity[]
    total: number
    by: number
}

const initialState: ICitiesState = {
    data: [],
    loading: false,
    error: '',
    total: 0,
    by: 0
};

export default function cities(state = initialState, action: IActionCities): ICitiesState {
    switch (action.type) {
        case GET_CITIES_REQUEST:
            return {
                data: [],
                loading: true,
                error: '',
                total: 0,
                by: 0
            };
        case GET_CITIES_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                data,
                loading: false,
                error: '',
                total,
                by
            };
        case GET_CITIES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload,
                total: 0,
                by: 0
            };
        default:
            return state;
    }
}
