import React, {PureComponent} from 'react';
import Table from '../UI/Table';
import {
    Button,
    ButtonGroup,
    Col,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import Avatar from "../UI/Avatar";
import FormatPrice from "../UI/FormatPrice";
import Icon from "../UI/Icon";
import {Link} from "react-router-dom";
import {IOrder, IOrderOldCouriers} from "../../models/Order";
import {ORDER_PAYMENT_PAID, OrderPayments} from "../../utils/order";
import {format, parseISO} from "date-fns";
import OrderChangeCoordinate from "../../containers/OrderChangeCoordinate";
import _ from 'lodash';
import CourierLocation from "../../containers/CourierLocation";
import {accessOrderChangeCoordinate, accessOrderChangeCourier, accessOrderEdit} from "../../utils/user-accesses";
import {courierTypeIcons, getCourierName} from "../../utils/couriers";
import {employeeTypeOptions} from "../../utils/employees";
import {Form, Formik} from "formik";
import CourierChange from "../CourierChange";
import {changeCourier} from "../../store/actions/courierChange";
import {connect} from "react-redux";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import OrderExternalCourierInfo from '../../containers/OrderExternalCourierInfo';

interface OrderType {
    data: IOrder
    openOrderForm: (mode: 'coordinate') => void
    closeOrderForm: () => void
    mode: 'coordinate' | null
    accesses?: string[]
    isArchive?: boolean
    changeCourier: (props: { courierId: string, id: string, reason: string}) => void
}

class Order extends PureComponent<OrderType> {
    state = {
        isStatusHistoriesDialog: false,
        isCourierLocationDialog: false,
        isChangeCourierDialog: false,
    };

    openStatusHistoriesDialog = () => {
        this.setState({isStatusHistoriesDialog: true})
    };

    closeStatusHistoriesDialog = () => {
        this.setState({isStatusHistoriesDialog: false})
    };

    openCourierLocationDialog = () => {
        this.setState({isCourierLocationDialog: true})
    };

    closeCourierLocationDialog = () => {
        this.setState({isCourierLocationDialog: false})
    };

    openChangeCourierDialog = () => {
        this.setState({isChangeCourierDialog: true})
    }

    closeChangeCourierDialog = () => {
        this.setState({isChangeCourierDialog: false})
    }

    render() {
        const {data, openOrderForm, mode, closeOrderForm, accesses, isArchive, changeCourier} = this.props;
        const {
            isStatusHistoriesDialog,
            isCourierLocationDialog,
            isChangeCourierDialog,
        } = this.state;
        
        const {
            id,
            customerName,
            phone,
            comments,
            orderItems,
            sumTotal,
            paymentTypeId,
            deliveryPrice = 0,
            customerAmount,
            addressTo,
            point,
            addressFrom,
            branchId,
            companyId,
            companyName,
            courier,
            statusesHistory,
            correctionalPrice = 0,
            sumItems,
            serviceFee,
            oldCouriers,
            cityId,
            isExternalCourier,
            notCall,
        } = data;
    
        const icon = typeof courier?.courierTypeId === "number"
          ? courierTypeIcons[courier.courierTypeId]
          : 'directions_run';

        const courierType: {
            label: string
            value: number
        } | undefined = employeeTypeOptions.find(test => test.value === courier?.courierTypeId)

        let currentAddress = `${addressTo.street}, д.${addressTo.house}`;

        if (addressTo.flat) {
            currentAddress += `, кв.${addressTo.flat}`;
        }
        if (addressTo.addressMore) {
            currentAddress += addressTo.addressMore;
        }

        const orderItemsRender = orderItems.map(({name, quantity, price}, index) => {
            return (
                <tr key={index}>
                    <td>
                        {name}
                    </td>
                    <td>
                        {quantity}
                    </td>
                    <td>
                        <FormatPrice value={price}/>
                    </td>
                </tr>
            )
        });

        const statusesHistoryRender = Array.isArray(statusesHistory) && statusesHistory.map(({
            message,
            userName,
            createdAt
        }, index) => {
            return (
                <ListGroupItem key={index}>
                    <Row>
                        <Col md={'auto'}>
                            <span className="gray-500">{format(parseISO(createdAt), 'HH:mm yyyy-MM-dd')}</span>
                        </Col>
                        <Col md={'auto'}>
                            {userName}
                        </Col>
                        <Col>
                            {message}
                        </Col>
                    </Row>
                </ListGroupItem>
            )
        });
        
        let courierInfoRender: JSX.Element | null = courier ?
            <Col md={'6'}>
                <Row form>
                    <Col md={'auto'}>
                        <Avatar icon={icon}/>
                    </Col>
                    {courier.phone && <Col className={'m-auto'} md={'auto'}>
                        <a href={`tel:+${courier.phone}`}>
                            <Icon name={'call'} color={'black'}/>
                        </a>
                    </Col>}
                    <Col>
                        <div className={'gray-500'}>{courierType?.label}</div>
                        <div className={'semi-bold'}>
                            <Link to={`/couriers/${courier.id}`}>
                                {getCourierName(courier, {skipMiddle: true})}
                            </Link>
                        </div>
                    </Col>
                </Row>
          
                <ButtonGroup className={'mt-2'}>
                    <Button
                      color={'light'}
                      onClick={this.openCourierLocationDialog}>
                        Местоположение
                        курьера
                    </Button>
            
                    {Array.isArray(accesses) && accesses.includes(accessOrderChangeCourier) && !isArchive &&
                      <Button
                        className={"ml-1"}
                        color={'light'}
                        onClick={this.openChangeCourierDialog}>
                          Сменить курьера
                      </Button>}
                </ButtonGroup>
          
                {oldCouriers && oldCouriers.length > 0 && <div className={"mt-2 gray-500"}> Предыдущие курьеры
                    {oldCouriers.map((params: IOrderOldCouriers, index: number) =>
                      <div key={index} className={"gray-500"}>
                          Курьер #{index + 1} <Link
                        to={`/couriers/${params.courier?.id}`} target={"_blank"} className={"semi-bold"}>{params.courier?.fio}</Link>
                          {params.reason && <span> {params.reason}</span>}
                      </div>)
                    }
                </div>}
    
                <Modal isOpen={isCourierLocationDialog} size={'lg'} toggle={this.closeCourierLocationDialog}>
                    <ModalHeader toggle={this.closeCourierLocationDialog}>
                        Местоположение курьера
                    </ModalHeader>
                    <ModalBody>
                        {courier.id || typeof courier.id === "number"
                          ? <CourierLocation
                                id={courier.id}
                            />
                          : "Ошибка получения местоположения курьера"}
                    </ModalBody>
                </Modal>
            </Col>
          : <div className="ml-1">
              <Button
                color={'light'}
                onClick={this.openChangeCourierDialog}>
                Установить курьера
              </Button>
            </div>

        if (isExternalCourier) {
            courierInfoRender = <OrderExternalCourierInfo
                order={data}
                isArchive={isArchive}
            />;
        }
        
        return (
            <div>
                <Row>
                    <Col md={'4'}>
                        <div className={'mb-3'}>
                            <ButtonGroup>
                                {Array.isArray(accesses) && accesses.includes(accessOrderEdit) && !isArchive &&
                                <Link to={`/orders/edit/${id}`}>
                                    <Button color={'light'} className={'mr-1'}>
                                        Редактировать заказ
                                    </Button>
                                </Link>}
                                {Array.isArray(statusesHistory) && (
                                    <>
                                        <Button color={'light'}
                                                onClick={this.openStatusHistoriesDialog}>
                                            История операций
                                        </Button>
                                        <Modal size={'lg'} isOpen={isStatusHistoriesDialog}
                                               toggle={this.closeStatusHistoriesDialog}>
                                            <ModalHeader toggle={this.closeStatusHistoriesDialog}>
                                                История операций
                                            </ModalHeader>
                                            <ModalBody>
                                                <ListGroup>
                                                    {statusesHistoryRender}
                                                </ListGroup>
                                            </ModalBody>
                                        </Modal>
                                    </>)}
                            </ButtonGroup>
                        </div>
                        <div className={'mb-3'}>
                            <Row form className="align-items-center">
                                <Col md={'auto'}>
                                    <Avatar icon={'account_circle'}/>
                                </Col>
                                <Col md={'auto'}>
                                    <a href={`tel:+${phone}`}>
                                        <Icon name={'call'} color={'black'}/>
                                    </a>
                                </Col>
                                <Col>
                                    <div className={'gray-500'}>{customerName}</div>
                                    <div className={'semi-bold'}>
                                        <span>+{phone}</span>
                                        {notCall && <span className='red'>&nbsp;(Не звонить)</span>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={'mb-3'}>
                            <Row form className="align-items-center">
                                <Col md={'auto'}>
                                    <Avatar icon={'place'}/>
                                </Col>
                                <Col>
                                    <div className={'semi-bold'}>
                                        {currentAddress}
                                    </div>
                                </Col>
                            </Row>
                            {Array.isArray(accesses) && accesses.includes(accessOrderChangeCoordinate) &&
                            <Button
                                className={'mt-2'}
                                color={'light'}
                                onClick={() => openOrderForm('coordinate')}
                            >
                                Изменить координаты
                            </Button>}
                            <Modal isOpen={mode === 'coordinate'} size={'lg'} toggle={closeOrderForm}>
                                <ModalHeader toggle={closeOrderForm}>
                                    Изменить координаты
                                </ModalHeader>
                                <ModalBody>
                                    <OrderChangeCoordinate
                                        id={id}
                                        data={{lat: _.get(addressTo, 'lat'), long: _.get(addressTo, 'long')}}
                                        address={currentAddress}
                                    />
                                </ModalBody>
                            </Modal>
                        </div>
                        {comments && (
                            <>
                                <div className={'gray-500'}>Комментарий</div>
                                <div className={'semi-bold'}>
                                    {comments}
                                </div>
                            </>
                        )}
                    </Col>
                    <Col md={'8'}>
                        <div className={'mb-3'}>
                            <Row>
                                <Col md={'6'}>
                                    {point && <div className={'mb-3'}>
                                        <Row form>
                                            <Col md={'auto'}>
                                                <Avatar icon={'my_location'}/>
                                            </Col>
                                            <Col>
                                                <div className={'gray-500'}>Город / Район</div>
                                                <div className={'semi-bold'}>{point.name}</div>
                                            </Col>
                                        </Row>
                                    </div>}
                                    <Row form>
                                        <Col md={'auto'}>
                                            <Avatar icon={'storefront'}/>
                                        </Col>
                                        {addressFrom.phone && <Col className={'m-auto'} md={'auto'}>
                                            <a href={`tel:+${addressFrom.phone}`}>
                                                <Icon name={'call'} color={'black'}/>
                                            </a>
                                        </Col>}
                                        <Col>
                                            <div className={'gray-500'}>
                                                {branchId ?
                                                    <Link to={`/companies/${companyId}/branches/edit/${branchId}`}>
                                                        Филиал - {companyName}
                                                    </Link> : <span>Филиал - {companyName}</span>}
                                            </div>
                                            <div className={'semi-bold'}>
                                                {addressFrom.address}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                {courierInfoRender}

                                <Modal isOpen={isChangeCourierDialog} size={'md'}
                                       toggle={this.closeChangeCourierDialog}>
                                    <Formik
                                        validationSchema={yup.object().shape({
                                            courierId: yup.string().nullable().required(),
                                            reason: yup.string()
                                                .required(requiredMessage())
                                                .ensure()
                                        })}
                                        initialValues={{
                                            courierId: null,
                                            reason: ""
                                        }}
                                        onSubmit={(values) => {
                                            changeCourier({courierId: values.courierId || "", id, reason: values.reason})
                                            this.closeChangeCourierDialog()
                                        }}
                                    >
                                        {({handleSubmit, handleChange, isValid, dirty, errors, touched}) => (
                                            <Form onSubmit={handleSubmit}>
                                                <ModalHeader toggle={this.closeChangeCourierDialog}>
                                                    Изменение курьера
                                                </ModalHeader>
                                                <ModalBody>
                                                    <CourierChange
                                                        handleChange={handleChange}
                                                        hasErrorMessage={touched.reason && !!errors.reason}
                                                        cityId={cityId}
                                                    />
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button onClick={this.closeChangeCourierDialog}
                                                            color="light">Отменить</Button>{' '}
                                                    <Button disabled={!dirty && !isValid} type="submit"
                                                            color="primary">Подтвердить</Button>
                                                </ModalFooter>
                                            </Form>
                                        )}
                                    </Formik>
                                </Modal>


                            </Row>
                        </div>
                        <Table striped>
                            <tbody>
                            <tr>
                                <th>Корзина</th>
                                <th>Количество</th>
                                <th style={{minWidth: 75}}>Цена</th>
                            </tr>
                            {orderItemsRender}
                            {correctionalPrice !== 0 && <tr>
                                <td>
                                    Корректировка
                                </td>
                                <td>
                                </td>
                                <td>
                                    <FormatPrice value={correctionalPrice}/>
                                </td>
                            </tr>}
                            {sumItems && <tr>
                                <td>
                                    Сумма заказа
                                </td>
                                <td>
                                </td>
                                <td>
                                    <b><FormatPrice value={sumItems}/></b>
                                </td>
                            </tr>}
                            {!!serviceFee && <tr>
                                <td>
                                    Сервисный сбор
                                </td>
                                <td>
                                </td>
                                <td>
                                    <FormatPrice value={serviceFee}/>
                                </td>
                            </tr>}
                            <tr>
                                <td>
                                    Сумма доставки
                                </td>
                                <td>
                                </td>
                                <td>
                                    <FormatPrice value={deliveryPrice || 0}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Итого</b>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <b><FormatPrice value={sumTotal}/></b>
                                </td>
                            </tr>
                            {customerAmount && <tr>
                                <td>
                                    Сдача с
                                </td>
                                <td>
                                </td>
                                <td>
                                    <FormatPrice value={customerAmount}/>
                                </td>
                            </tr>}
                            <tr>
                                <td>
                                    {paymentTypeId === ORDER_PAYMENT_PAID ?
                                        (
                                            <span className="green">
                                        <Icon name={'check_circle'}/>
                                        <span className={'v-a-middle'}>Оплаченный заказ</span>
                                        </span>
                                        ) :
                                        (
                                            <span>{OrderPayments[paymentTypeId]}</span>
                                        )}
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapDispatchToProps = {
    changeCourier,
}

export default connect(null, mapDispatchToProps)(Order);
