import React, {PureComponent} from 'react';
import {Card, CardBody} from "reactstrap";


class Empty extends PureComponent<React.HTMLAttributes<HTMLDivElement>> {
    render() {
        const {children} = this.props;
        return (
            <Card className={'mb-2 mt-2'}>
                <CardBody>
                    <div className="text-center">
                        {children}
                    </div>
                </CardBody>
            </Card>
        )
    }
}


export default Empty;
