import {
    CHANGE_STATUS_ORDERS_SUCCESS,
    GET_ORDERS_ERROR,
    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS, SSE_ORDERS, TOGGLE_ACTIVE_ORDERS
} from "../constants/orders";
import {IActionOrders} from "../actions/orders";
import {IOrderShort} from "../../models/Order";
import _ from "lodash";


const changeData = (items: IOrderShort[], item: IOrderShort) => {
    const index = items.findIndex(({id}) => id === item.id);

    if(!(item.status > 0 && item.status < 100)){
        return [
            ...items.slice(0, index),
            ...items.slice(index + 1)
        ]
    }
    return [
        ...items.slice(0, index),
        item,
        ...items.slice(index + 1)
    ]
};

const sseData = (currentData: IOrderShort[], respondedData: IOrderShort[]) => {
    return _.orderBy(_.unionBy(respondedData, currentData, 'id').filter(({status}) => status > 0 && status < 100), ['id'], ['desc']);
};


export interface IOrdersState {
    loading: boolean
    error: string
    data: IOrderShort[]
    activeId: string
    total: number
    by: number
}

const initialState: IOrdersState = {
    data: [],
    loading: false,
    error: '',
    activeId: '',
    total: 0,
    by: 0
};

export default function orders(state = initialState, action: IActionOrders): IOrdersState {
    switch (action.type) {
        case GET_ORDERS_REQUEST:
            return {
                data: [],
                loading: true,
                error: '',
                activeId: '',
                total: 0,
                by: 0
            };
        case GET_ORDERS_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                data,
                loading: false,
                error: '',
                activeId: '',
                total,
                by
            };
        case GET_ORDERS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload,
                activeId: '',
                total: 0,
                by: 0
            };
        case SSE_ORDERS:
            return {
                ...state,
                data: sseData(state.data, action.payload),
                // activeId: ''
                // раньше при обновлении заказов очищался id активного заказа,
                // но из-за этого закрывались модальные окна и сворачивался заказ
            };
        case CHANGE_STATUS_ORDERS_SUCCESS:
            return {
                ...state,
                data: changeData(state.data, action.payload),
                // activeId: ''
            };
        case TOGGLE_ACTIVE_ORDERS:
            return {
                ...state,
                activeId: state.activeId !== action.payload ? action.payload : ''
            };
        default:
            return state;
    }
}
