import React, {PureComponent} from 'react';
import {IPoint} from "../../models/Point";
import Icon from '../UI/Icon';
import {ButtonGroup, Button} from "reactstrap";
import Table from '../UI/Table';
import {accessPointEdit, accessPointDelete} from "../../utils/user-accesses";


interface PointsType {
    editHandler: (id: number) => void
    deleteHandler: (id: number) => void
    linkHandler: (id: number) => void
    data: IPoint[]
    accesses?: string[]
}

class Points extends PureComponent<PointsType> {
    render() {
        const {data, linkHandler, editHandler, deleteHandler, accesses} = this.props;
        return (
            <Table>
                <tbody>
                {data.map((data) => {
                        return (
                            <PointsItem
                                key={data.id}
                                data={data}
                                linkHandler={linkHandler}
                                editHandler={editHandler}
                                deleteHandler={deleteHandler}
                                accesses={accesses}
                            />
                        )
                    }
                )}
                </tbody>
            </Table>
        )
    }
}

type PointsItemType = {
    data: IPoint
    accesses?: string[]
    linkHandler: (id: number) => void
    editHandler: (id: number) => void
    deleteHandler: (id: number) => void
}

class PointsItem extends PureComponent<PointsItemType> {

    editHandler = () =>{
        const {data, editHandler} = this.props;
        const {id} = data;
        editHandler(id)
    };

    deleteHandler = () =>{
        const {data, deleteHandler} = this.props;
        const {id} = data;
        deleteHandler(id)
    };

    linkHandler = () =>{
        const {data, linkHandler} = this.props;
        const {id} = data;
        linkHandler(id)
    };

    render() {
        const {data, accesses} = this.props;
        const {name, description, status} = data;
        const editButtonWidth = Array.isArray(accesses) && accesses.includes(accessPointEdit) ? 42 : 0;
        const deleteButtonWidth = Array.isArray(accesses) && accesses.includes(accessPointDelete) ? 42 : 0;
        return (
            <tr onClick={this.linkHandler} className={'pointer'}>
                <td>
                    <b className="d-block">{name}</b>
                    {description}
                </td>
                <td>
                    <div className={'gray-500'}>
                        Статус
                    </div>
                    {status ? <div className={'semi-bold green'}>Включен</div> :
                        <div className={'semi-bold red'}>Отключен</div>}
                </td>
                <td style={{width: 24 + editButtonWidth + deleteButtonWidth}} onClick={(e) => e.stopPropagation()}>
                    <ButtonGroup>
                        {Array.isArray(accesses) && accesses.includes(accessPointEdit) &&
                        <Button size={'sm'} color={'outline-light'} onClick={this.editHandler}>
                            <Icon name={'edit'} color={'gray-500'}/>
                        </Button>}
                        {Array.isArray(accesses) && accesses.includes(accessPointDelete) &&
                        <Button size={'sm'} color={'outline-light'} onClick={this.deleteHandler}>
                            <Icon name={'delete'} color={'gray-500'}/>
                        </Button>}
                    </ButtonGroup>
                </td>
            </tr>
        );
    }
}

export default Points;
