import React, {PureComponent} from 'react';
import {ICourier} from "../../models/Courier";
import {
    Button,
    ButtonGroup,
    Card,
    Col,
    CustomInput,
    FormGroup, Label, Modal, ModalBody, ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import {Link} from "react-router-dom";
import Avatar from "../UI/Avatar";
import _ from 'lodash';
import classNames from "classnames";
import DateTimePicker from "../UI/DateTimePicker";
import CourierStatistics from "../../containers/CourierStatistics";
import {format} from "date-fns";
import CourierPenalties from "../../containers/CourierPenalties";
import CourierPenaltyAdd from "../../containers/CourierPenaltyAdd";
import CourierSchedule from '../../containers/CourierSchedule';
import Icon from "../UI/Icon";
import {courierTypeIcons} from "../../utils/couriers";
import {accessCourierPenaltyManage, accessCourierPenaltyShow, accessCourierSlotManage} from "../../utils/user-accesses";
import CourierDocuments from "../../containers/CourierDocuments";
import {dateConvert} from "../../utils/date-convert";


type CourierType = {
    data: ICourier
    enableStatusCourier: (id: number) => void
    disableStatusCourier: (id: number) => void
    openCourierPenaltyForm: (mode: 'add') => void
    closeCourierPenaltyForm: () => void
    mode: 'add' | 'delete' | null
    accesses?: string[]
}

type CourierStatisticType = {
    dateStart: string,
    dateEnd: string
}

class Courier extends PureComponent<CourierType> {
    state = {
        activeTab: 'statistics',
        statistics: {
            dateStart: format(new Date().setDate(new Date().getDate() - 7), "yyyy-MM-dd"),
            dateEnd: format(new Date(), "yyyy-MM-dd")
        }
    };

    changeStatisticHandle = (statistics: CourierStatisticType) => {
        this.setState({statistics})
    };

    changeTab = (activeTab: string) => {
        this.setState({activeTab})
    };

    render() {
        const {activeTab, statistics} = this.state;
        const {data, mode, openCourierPenaltyForm, closeCourierPenaltyForm, enableStatusCourier, disableStatusCourier, accesses} = this.props;
        const {id, cityId, phone, fio, slot, orderStatusName, courierTypeId} = data;
        const status = _.get(slot, 'status', false);

        const icon = typeof courierTypeId === "number"
          ? courierTypeIcons[courierTypeId]
          : undefined;

        return (
            <>
                <div className={'mb-2'}>
                    <Row className="align-items-center">
                        <Col md={'auto'}>
                            <ButtonGroup>
                                <Link to={`/couriers`}><Button color={'light'}>Назад</Button></Link>
                                <Link to={`/archive-orders/?courierId=${id}&cityId=${cityId}`}>
                                    <Button color={'light'}>
                                        Посмотреть архив заказов
                                    </Button>
                                </Link>
                                {Array.isArray(accesses) && accesses.includes(accessCourierPenaltyManage) && <Button
                                    color={'light'}
                                    onClick={() => openCourierPenaltyForm('add')}
                                >
                                    Оштрафовать
                                </Button>}
                                <Modal isOpen={mode === 'add'} toggle={closeCourierPenaltyForm}>
                                    <ModalHeader toggle={closeCourierPenaltyForm}>
                                        Применение штрафа
                                    </ModalHeader>
                                    <ModalBody>
                                        <CourierPenaltyAdd id={id}/>
                                    </ModalBody>
                                </Modal>
                            </ButtonGroup>
                        </Col>
                        <Col md={'auto'}>
                            <CustomInput
                                type="switch"
                                id="isActive"
                                label={`Курьер ${status ? 'активен' : 'не активен'}`}
                                checked={status}
                                disabled={!(Array.isArray(accesses) && accesses.includes(accessCourierSlotManage))}
                                onChange={() => !status ? enableStatusCourier(id) : disableStatusCourier(id)}
                            />
                        </Col>
                    </Row>
                </div>
                <Card body className={'mb-3'}>
                    <Row form className="align-items-center">
                        {courierTypeId && <Col md={'auto'}>
                            <Avatar
                                icon={icon}
                            />
                        </Col>}
                        <Col md={'auto'}>
                            <b>{fio}</b>
                            <div>
                                {status ?
                                    <span className={'green'}>Активен</span> :
                                    <span className={'red'}>Не активен</span>
                                }
                                {orderStatusName && <span className={'gray-500'}>
                                   <span className={'ml-1'}>•</span> {orderStatusName}
                              </span>}
                            </div>
                        </Col>
                        <Col md={'auto'}>
                            <a href={`tel:+${phone}`}>
                                <Icon name={'call'} color={'black'}/>
                            </a>
                        </Col>
                    </Row>
                </Card>
                <Nav tabs className={'borderNone'}>
                    <NavItem>
                        <NavLink
                            className={classNames('pointer', activeTab === 'statistics' && 'active')}
                            onClick={() => this.changeTab('statistics')}
                        >
                            Статистика
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames('pointer', activeTab === 'schedules' && 'active')}
                            onClick={() => this.changeTab('schedules')}
                        >
                            Расписание
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames('pointer', activeTab === 'documents' && 'active')}
                            onClick={() => this.changeTab('documents')}
                        >
                            Документы
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="statistics">
                        <Card body>
                            <FormGroup>
                                <Label>Период</Label>
                                <Row form>
                                    <Col md={'auto'}>
                                        <DateTimePicker
                                            name={`statistics.dateStart`}
                                            value={dateConvert(_.get(statistics, `dateStart`), 'yyyy-MM-dd', 'dd-MM-yyyy')}
                                            onChange={(dateStart) => dateStart !== null && this.changeStatisticHandle({
                                                ...statistics,
                                                dateStart: dateConvert(dateStart, 'dd-MM-yyyy', 'yyyy-MM-dd')
                                            })}
                                        />
                                    </Col>
                                    <Col md={'auto'}>
                                        <DateTimePicker
                                            name={`statistic.dateEnd`}
                                            value={dateConvert(_.get(statistics, `dateEnd`), 'yyyy-MM-dd', 'dd-MM-yyyy')}
                                            onChange={(dateEnd) => dateEnd !== null && this.changeStatisticHandle({
                                                ...statistics,
                                                dateEnd: dateConvert(dateEnd, 'dd-MM-yyyy', 'yyyy-MM-dd')
                                            })}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div className={'mb-4'}>
                                <CourierStatistics id={id} filter={statistics}/>
                            </div>
                            {Array.isArray(accesses) && accesses.includes(accessCourierPenaltyShow) && (
                                <>
                                    <h4>Примененные штрафы</h4>
                                    <CourierPenalties id={id} filter={statistics}/>
                                </>
                            )}
                        </Card>
                    </TabPane>
                    <TabPane tabId="schedules">
                        <Card body>
                            <CourierSchedule id={id}/>
                        </Card>
                    </TabPane>
                    <TabPane tabId="documents">
                        <Card body>
                            <CourierDocuments id={id}/>
                        </Card>
                    </TabPane>
                </TabContent>
            </>
        );
    }
}

export default Courier;
