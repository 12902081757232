import {
    GET_DICTIONARY_PERMISSIONS_ERROR,
    GET_DICTIONARY_PERMISSIONS_REQUEST,
    GET_DICTIONARY_PERMISSIONS_SUCCESS
} from "../constants/dictionaryPermissions";
import {IDictionary} from "../../models/Dictionary";
import {IActionDictionaryPermissions} from "../actions/dictionaryPermissions";


export interface IDictionaryPermissionsState {
    loading: boolean
    error: string
    data: IDictionary[]
}

const initialState: IDictionaryPermissionsState = {
    data: [],
    loading: false,
    error: ''
};

export default function dictionaryPermissions(state = initialState, action: IActionDictionaryPermissions): IDictionaryPermissionsState {
    switch (action.type) {
        case GET_DICTIONARY_PERMISSIONS_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_DICTIONARY_PERMISSIONS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_DICTIONARY_PERMISSIONS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
