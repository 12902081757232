import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {
    addCourierPenaltyForm,
    closeCourierPenaltyForm,
    deleteCourierPenaltyForm
} from "../store/actions/courierPenaltyForm";
import {getPenalties} from "../store/actions/penalties";
import {Alert, Button, Spinner} from "reactstrap";


type CourierPenaltyDeleteType = {
    deleteCourierPenaltyForm: (sectionId: number, id:number) => void
    closeCourierPenaltyForm: () => void
    changeLoading: boolean
    changeError: string
    id: number
    sectionId: number
}

class CourierPenaltyDeleteContainer extends Component<CourierPenaltyDeleteType> {
    render() {
        const {changeLoading, changeError, closeCourierPenaltyForm, deleteCourierPenaltyForm, id, sectionId} = this.props;

        return (
            <>
                {changeError &&
                (<Alert className={'mt-3'} color={'danger'}>
                    {changeError}
                </Alert>)}
                <div className={'text-right'}>
                    {closeCourierPenaltyForm &&
                    <Button onClick={closeCourierPenaltyForm} color={'primary'} className={'mr-3'}>Закрыть</Button>}
                    <Button onClick={() => deleteCourierPenaltyForm(sectionId, id)} color={'danger'} disabled={changeLoading}>
                        {changeLoading &&
                        (<Spinner
                            size="sm"
                        />)}
                        Отменить штраф
                    </Button>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({courierPenaltyForm}: AppState) => {
    const {changeLoading, changeError} = courierPenaltyForm;
    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    deleteCourierPenaltyForm,
    addCourierPenaltyForm,
    closeCourierPenaltyForm,
    getPenalties
};


export default connect(mapStateToProps, mapDispatchToProps)(CourierPenaltyDeleteContainer);
