import {ICourierRatioRateConditionResponse} from "../../models/Courier";
import {IActionRatioRate} from "../actions/ratioRate";
import {GET_RATIO_RATE_ERROR, GET_RATIO_RATE_REQUEST, GET_RATIO_RATE_SUCCESS} from "../constants/ratioRate";

export interface IRatioRateState {
    data: ICourierRatioRateConditionResponse[]
    loading: boolean
    error: string
}

const initialState: IRatioRateState = {
    data: [],
    loading: false,
    error: ''
}

export default function ratioRate(state = initialState, action: IActionRatioRate): IRatioRateState {
    switch (action.type) {
        case GET_RATIO_RATE_REQUEST:
            return {
                ...state,
                data: [],
                loading: true,
                error: ''
            };
        case GET_RATIO_RATE_SUCCESS:
            const {data} = action.payload;
            return {
                ...state,
                data,
                loading: false,
                error: ''
            }
        case GET_RATIO_RATE_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}