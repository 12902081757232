export const GET_NOTIFICATIONS = Symbol('GET_NOTIFICATIONS');
export const GET_NOTIFICATIONS_REQUEST = Symbol('GET_NOTIFICATIONS_REQUEST');
export const GET_NOTIFICATIONS_SUCCESS = Symbol('GET_NOTIFICATIONS_SUCCESS');
export const GET_NOTIFICATIONS_ERROR = Symbol('GET_NOTIFICATIONS_ERROR');

export const SSE_NOTIFICATIONS_COUNT = Symbol('GET_NOTIFICATIONS_COUNT');

export const DELETE_NOTIFICATION_SUCCESS = Symbol('DELETE_NOTIFICATION_SUCCESS');
export const DELETE_NOTIFICATION = Symbol('GET_NOTIFICATIONS_COUNT');

