import React from "react";
import TableGrid from "../UI/TableGrid";
import {ISalaryResponse, ISalarySummaryResponse} from "../../models/Salary";
import {format, parseISO} from "date-fns";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import Icon from "../UI/Icon";
import ConfirmDialog from "../ConfirmDialog";

type SalaryListType = {
    data: ISalaryResponse[];
    totalData: ISalarySummaryResponse;
    paySalary: (id: number) => void;
}

type SalaryListState = {
    isSalaryPayConfirm: boolean;
    salaryId: number | null;
}

class SalaryList extends React.Component<SalaryListType, SalaryListState> {
  
    state: SalaryListState = {
        isSalaryPayConfirm: false,
        salaryId: null,
    }
  
    openPaySalaryConfirm = (id: number) => {
        this.setState({isSalaryPayConfirm: true, salaryId: id});
    }
    
    closePaySalaryConfirm = () => {
        this.setState({isSalaryPayConfirm: false});
    }
  
    paySalaryHandler = () => {
        const {paySalary} = this.props;
        const {salaryId} = this.state;
        
        setTimeout(function () {
          if (salaryId) {
            paySalary(salaryId);
          }
        }, 400)
        
        this.closePaySalaryConfirm();
    }
  
    render() {
      const {data, totalData} = this.props;
      const {isSalaryPayConfirm} = this.state;
      
      return <>
          <TableGrid
            hasBorder
            className={'border-bottom-0'}
            sourceData={data}
            footerData={totalData}
            columns={[
              {
                label: 'Дата',
                key: 'workDate',
                width: 100,
                render: (item: ISalaryResponse) => {
                  const {workDate} = item;
                  return workDate && format(parseISO(workDate), 'dd-MM-yyyy')
                }
              },
              {
                label: 'Курьер',
                key: 'courier',
                render: (item: ISalaryResponse) => {
                  return <Link to={`/couriers/${item.courier.id}`}
                               target={'_blank'}>{item.courier.fio}</Link>
                },
                width: 150
              },
              {
                label: 'Часы',
                key: 'hoursAmount',
                width: 80
              },
              {
                label: 'Заказы',
                key: 'orderAmount',
                width: 80
              },
              {
                label: 'Ежедневные бонусы',
                key: 'dailyBonus',
                width: 120
              },
              {
                label: 'Штрафы',
                key: 'penalty',
                width: 80
              },
              {
                label: 'Итого (дневной заработок курьера)',
                key: 'totalAmount',
                width: 200
              },
              {
                label: 'Удержание',
                key: 'holding',
                width: 100
              },
              {
                label: 'Платная доставка',
                key: 'paidDelivery',
                width: 100
              },
              {
                label: 'Выплата',
                key: 'payouts',
                width: 100
              },
              {
                label: '',
                key: 'isPaidOut',
                render: (item: ISalaryResponse) => {
                  return (
                    <div className={"d-flex"}>
                      <Button className={"ml-auto mr-auto"}
                              size={'sm'}
                              disabled={item.isPaidOut}
                              color={item.isPaidOut ? 'success' : 'danger'}
                              title={item.isPaidOut ? 'Деньги выданы' : 'Выдача денег курьеру'}
                              onClick={() => this.openPaySalaryConfirm(item.id)}
                      >
                        <Icon name={'done'} color={'white'}/>
                      </Button>
                    </div>
                  )
                },
                width: 20
              }
            ]}
            footerColumns={[
              {
                key: 'workDate',
                value: 'Итого'
              },
              {
                key: 'courier',
                hidden: true
              },
              {
                key: 'hoursAmount'
              },
              {
                key: 'orderAmount'
              },
              {
                key: 'dailyBonus'
              },
              {
                key: 'penalty'
              },
              {
                key: 'totalAmount'
              },
              {
                key: 'holding'
              },
              {
                key: 'paidDelivery'
              },
              {
                key: 'payouts'
              },
              {
                key: 'isPaidOut',
                hidden: true
              }
            ]}
          />
  
        <ConfirmDialog
          isOpen={isSalaryPayConfirm}
          closeHandler={this.closePaySalaryConfirm}
          actionHandler={this.paySalaryHandler}
          title={"Выплатить деньги курьеру?"}
          actionButtonText={"Выплатить"}
          cancelButtonText={"Отмена"}
        />
      </>;
    }
}

export default SalaryList;
