import React, {PureComponent} from 'react';
import {Alert, Button, Form, Spinner, FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import {IPaymentAddFormRequest} from '../../models/Payment';
import {sanitizeForm} from "../../utils/sanitize-form";
import NumberFormat from "react-number-format";
import Dropdown from "../UI/Dropdown";
import DateTimePicker from "../UI/DateTimePicker";
import {paymentOptions} from "../../utils/payments";
import {IDictionary} from "../../models/Dictionary";
import {getDictionaryOptions} from "../../utils/dictionaries";



const schema = yup.object({
    companyId: yup.number().nullable().required(requiredMessage()),
    methodId: yup.number().nullable().required(requiredMessage()),
    amount: yup.number().min(1, requiredMessage()).required(requiredMessage()),
    payedAt: yup.string().required(requiredMessage()),
    comment: yup.string()
});

type PaymentAddType = {
    addPaymentForm: (id:number, form: IPaymentAddFormRequest) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
    dictionaryCompanies:IDictionary[]
}

class PaymentAdd extends PureComponent<PaymentAddType> {
    render() {
        const {addPaymentForm, changeLoading, changeError, cancelHandler, dictionaryCompanies} = this.props;
        const companyOptions = getDictionaryOptions(dictionaryCompanies);
        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const methodId = sanitizeForm(values.methodId);
                    const amount = sanitizeForm(values.amount);
                    const payedAt = sanitizeForm(values.payedAt);
                    const comment = sanitizeForm(values.comment);
                    const companyId = sanitizeForm(values.companyId);

                    const form = {
                        methodId,
                        amount,
                        payedAt,
                        comment
                    };

                    addPaymentForm(companyId, form)
                }}
                initialValues={{
                    companyId: null,
                    methodId: null,
                    amount: 0,
                    payedAt: null,
                    comment: ''
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>Название компании <span className={'required'}>*</span></Label>
                            <Dropdown
                                name={'companyId'}
                                value={values.companyId}
                                options={companyOptions}
                                onChange={(value) => setFieldValue('companyId', value)}
                                invalid={touched.companyId && !!errors.companyId}
                            />
                            <FormFeedback>{errors.companyId}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Метод оплаты <span className={'required'}>*</span></Label>
                            <Dropdown
                                name={'methodId'}
                                value={values.methodId}
                                options={paymentOptions}
                                onChange={(value) => setFieldValue('methodId', value)}
                                invalid={touched.methodId && !!errors.methodId}
                            />
                            <FormFeedback>{errors.methodId}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Сумма <span className={'required'}>*</span></Label>
                            <NumberFormat
                                name={'amount'}
                                allowNegative={false}
                                decimalSeparator={false}
                                value={values.amount}
                                onValueChange={({floatValue}) => setFieldValue('amount', floatValue)}
                                invalid={touched.amount && !!errors.amount}
                                customInput={Input}
                            />
                            <FormFeedback>{errors.amount}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Дата <span className={'required'}>*</span></Label>
                            <DateTimePicker
                                name={'payedAt'}
                                value={values.payedAt}
                                onChange={payedAt => setFieldValue('payedAt', payedAt)}
                                invalid={touched.payedAt && !!errors.payedAt}
                            />
                            <FormFeedback>{errors.payedAt}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Комментарий</Label>
                            <Input
                                tag="textarea"
                                type="text"
                                name="comment"
                                value={values.comment}
                                onChange={handleChange}
                                invalid={touched.comment && !!errors.comment}
                            />
                            <FormFeedback>{errors.comment}</FormFeedback>
                        </FormGroup>
                        {changeError &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {changeError}
                        </Alert>)}
                        <div className={'text-right'}>
                            {cancelHandler &&
                            <Button color="light" onClick={cancelHandler} className={'mr-3'}>Отменить</Button>}
                            <Button type="submit" color={'primary'} disabled={changeLoading}>
                                {changeLoading &&
                                (<Spinner
                                    size="sm"
                                />)}
                                Создать
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default PaymentAdd;
