import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {addCompanyForm} from "../store/actions/companyForm";
import {ICompanyAddFormRequest} from "../models/Company";
import CompanyAdd from "../components/CompanyAdd";
import {IAuth} from "../models/Auth";
import _ from "lodash";


type CompanyAddType = {
    addCompanyForm: (form: ICompanyAddFormRequest) => void
    changeLoading: boolean
    changeError: string
}
type CitiesType = Pick<IAuth, 'allowedCities'>;

class CompanyAddContainer extends Component<CompanyAddType & CitiesType> {

    render() {
        const {changeLoading, changeError, addCompanyForm, allowedCities} = this.props;

        return (
            <CompanyAdd
                addCompanyForm={addCompanyForm}
                changeLoading={changeLoading}
                changeError={changeError}
                allowedCities={allowedCities}
            />
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.companyForm;
    const {user} = state.auth;

    return {
        changeLoading,
        changeError,
        allowedCities: _.get(user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    addCompanyForm
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyAddContainer);
