import {
    GET_SCHEDULES_ERROR,
    GET_SCHEDULES_REQUEST,
    GET_SCHEDULES_SUCCESS
} from "../constants/schedules";
import {ISchedule} from "../../models/Schedule";
import {IActionSchedules} from "../actions/schedules";


export interface ISchedulesState {
    loading: boolean
    error: string
    data: ISchedule[]
    total: number
    by: number
}

const initialState: ISchedulesState = {
    data: [],
    loading: false,
    error: '',
    total: 0,
    by: 0
};

export default function schedules(state = initialState, action: IActionSchedules): ISchedulesState {
    switch (action.type) {
        case GET_SCHEDULES_REQUEST:
            return {
                data: [],
                loading: true,
                error: '',
                total: 0,
                by: 0
            };
        case GET_SCHEDULES_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                data,
                loading: false,
                error: '',
                total,
                by
            };
        case GET_SCHEDULES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload,
                total: 0,
                by: 0
            };
        default:
            return state;
    }
}
