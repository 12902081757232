import {IAction} from "./index";
import {ICourierPhone} from "../../models/Order";
import {
  CLEAR_COURIER_PHONE,
  GET_COURIER_PHONE,
  GET_COURIER_PHONE_ERROR,
  GET_COURIER_PHONE_REQUEST,
  GET_COURIER_PHONE_SUCCESS
} from "../constants/courierPhone";

export interface IActionCourierPhone extends IAction<symbol, any> {}

export const getCourierPhoneRequest = () => {
  return {
    type: GET_COURIER_PHONE_REQUEST,
  }
}

export const getCourierPhoneSuccess = (payload: ICourierPhone) => {
  return {
    type: GET_COURIER_PHONE_SUCCESS,
    payload,
  }
}

export const getCourierPhoneError = (payload: string) => {
  return {
    type: GET_COURIER_PHONE_ERROR,
    payload,
  }
}

export const getCourierPhone = (id: string) => {
  return {
    type: GET_COURIER_PHONE,
    payload: {
      id,
    }
  }
}

export const clearCourierPhone = () => {
  return {
    type: CLEAR_COURIER_PHONE,
  }
}
