import {call, put, takeLatest} from 'redux-saga/effects';
import {OrderService} from "../../services/OrderService";
import {ICourierPhone} from "../../models/Order";
import {getCourierPhoneError, getCourierPhoneRequest, getCourierPhoneSuccess} from "../actions/courierPhone";
import {GET_COURIER_PHONE} from "../constants/courierPhone";
import {addNotify} from "../actions/notify";

type getLocationType = {
  type: typeof GET_COURIER_PHONE,
  payload: {
    id: string;
  }
}

function* getCourierPhoneSaga({payload}: getLocationType) {
  try {
    yield put(getCourierPhoneRequest());
    
    const service = new OrderService();
    
    const promise = yield call(service.getCourierPhone, payload.id);
    const response: ICourierPhone = promise.data;
    
    yield put(getCourierPhoneSuccess(response));
  } catch (error) {
    yield put(getCourierPhoneError(error?.payload?.data?.message || "Ошибка"));
    yield put(addNotify(error?.response?.data?.message || 'Ошибка'));
  }
}

export function* watchGetCourierPhone() {
  yield takeLatest(GET_COURIER_PHONE, getCourierPhoneSaga);
}
