import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getCourierScheduleByIdError,
    getCourierScheduleByIdRequest,
    getCourierScheduleByIdSuccess
} from "../actions/courierScheduleById";
import {CourierService} from "../../services/CourierService";
import {GET_COURIER_SCHEDULE_BY_ID} from "../constants/courierScheduleById";
import {ICourierScheduleByID} from "../../models/Courier";


type getCourierScheduleByIdType = {
    type: typeof GET_COURIER_SCHEDULE_BY_ID,
    payload:{
        sectionId:number
        id:number
    }
};

function* getCourierScheduleByIdSaga({payload}: getCourierScheduleByIdType) {
    try {
        yield put(getCourierScheduleByIdRequest());

        const service = new CourierService();

        const promise = yield call(service.getCourierScheduleById, payload.sectionId, payload.id);

        const response: ICourierScheduleByID[] = promise.data;

        yield put(getCourierScheduleByIdSuccess(response));

    } catch (error) {
        yield put(getCourierScheduleByIdError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCourierScheduleById() {
    yield takeLatest(GET_COURIER_SCHEDULE_BY_ID, getCourierScheduleByIdSaga);
}

