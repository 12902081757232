import {endOfWeek, format, isMonday, parse} from "date-fns";
import {ru} from "date-fns/locale";

export const DAY = 24 * 60 * 60 * 1000;
export const WEEK = 7 * DAY;


const generateWeek = () => {
    const start = isMonday(new Date()) ? new Date() : endOfWeek(new Date(), {weekStartsOn: 2});
    const end = new Date(start.getTime() + WEEK - DAY);

    const weeks: {
        dateStart: string
        dateEnd: string
    }[] = [{
        dateStart: format(start, 'yyyy-MM-dd'),
        dateEnd: format(end, 'yyyy-MM-dd')
    }];

    for (let i = 0; i < 3; i++) {
        const week = {
            dateStart: format(new Date(end.getTime() + ((i * WEEK) + DAY)), 'yyyy-MM-dd'),
            dateEnd: format(new Date(end.getTime() + ((i + 1) * WEEK)), 'yyyy-MM-dd')
        };

        weeks.push(week);
    }

    return weeks;
};


export const generateWeekOptions = () => {
    const weeks = generateWeek();
    return weeks.map(({dateStart, dateEnd}) => {
        const label = format(parse(dateStart, 'yyyy-MM-dd', new Date()), 'd MMMM', {locale: ru}) + ' - ' + format(parse(dateEnd, 'yyyy-MM-dd', new Date()), 'd MMMM', {locale: ru});

        const value = {
            dateStart,
            dateEnd
        };

        return {
            label,
            value
        }
    });
};

export const getScheduleWeek = (start: string): { [key: string]: string } => {
    const getDay = (i: number) => {
        const startToDate = parse(start, 'yyyy-MM-dd', new Date());
        return format(new Date(startToDate.getTime() + ((i - 1) * DAY)), 'yyyy-MM-dd');
    };

    return {
        'Понедельник': getDay(1),
        'Вторник': getDay(2),
        'Среда': getDay(3),
        'Четверг': getDay(4),
        'Пятница': getDay(5),
        'Суббота': getDay(6),
        'Воскресенье': getDay(7),
    };
};

/**
 * Номера смен
 */
export const SCHEDULE_SHIFT_NUMBERS = [1, 2, 3];

/**
 * Опции смен
 */
export const shiftOptions = SCHEDULE_SHIFT_NUMBERS
    .map(value => ({value, label: `Смена ${value}`}))
