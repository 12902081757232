import {
    GET_COURIER_STATISTICS_ERROR,
    GET_COURIER_STATISTICS_REQUEST,
    GET_COURIER_STATISTICS_SUCCESS
} from "../constants/courierStatistics";
import {ICourierStatistics} from "../../models/Courier";
import {IActionCourierStatistics} from "../actions/courierStatistics";


export interface ICourierStatisticsState {
    loading: boolean
    error: string
    data: ICourierStatistics | null
}

const initialState: ICourierStatisticsState = {
    data: null,
    loading: false,
    error: ''
};

export default function courierStatistics(state = initialState, action: IActionCourierStatistics): ICourierStatisticsState {
    switch (action.type) {
        case GET_COURIER_STATISTICS_REQUEST:
            return {
                data: null,
                loading: true,
                error: ''
            };
        case GET_COURIER_STATISTICS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_COURIER_STATISTICS_ERROR:
            return {
                data: null,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
