import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import CourierPenalties from "../components/CourierPenalties";
import {ICourierPenalty, ICourierPenaltiesFilterRequest} from "../models/Courier";
import {getCourierPenalties} from "../store/actions/courierPenalties";
import _ from "lodash";
import Empty from "../components/UI/Empty";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {closeCourierPenaltyForm, openCourierPenaltyForm} from "../store/actions/courierPenaltyForm";
import CourierPenaltyDelete from "./CourierPenaltyDelete";


type CourierPenaltiesType = {
    getCourierPenalties: (id: number, form: ICourierPenaltiesFilterRequest) => void
    data: ICourierPenalty[]
    id: number
    loading: boolean
    error: string,
    filter: ICourierPenaltiesFilterRequest
    openCourierPenaltyForm: (mode: 'add' | 'delete', modeId?: number) => void
    closeCourierPenaltyForm: () => void
    mode: 'add' | 'delete' | null
    modeId: number | null
    accesses?:string[]
}

class CourierPenaltiesContainer extends Component<CourierPenaltiesType> {

    componentDidMount() {
        const {id, getCourierPenalties, filter} = this.props;
        getCourierPenalties(id, filter);
    }

    componentDidUpdate(prevProps: Readonly<CourierPenaltiesType>) {
        const {id, getCourierPenalties, filter} = this.props;
        if (!_.isEqual(prevProps.filter, filter)) {
            getCourierPenalties(id, filter);
        }
    }

    deleteHandler = (id: number) => {
        const {openCourierPenaltyForm} = this.props;
        openCourierPenaltyForm('delete', id)
    };

    render() {
        const {data, loading, error, getCourierPenalties, id, filter, mode, modeId, closeCourierPenaltyForm, accesses} = this.props;

        if (loading) {
            return <Loader/>
        }

        if (error) {
            return <Error error={error} refresh={() => getCourierPenalties(id, filter)}/>
        }
        if (!!data.length) {
            return (
                <>
                    <CourierPenalties
                        data={data}
                        deleteHandler={this.deleteHandler}
                        accesses={accesses}
                    />
                    {modeId !== null &&
                    <Modal isOpen={mode === 'delete'} toggle={closeCourierPenaltyForm}>
                        <ModalHeader toggle={closeCourierPenaltyForm}>
                            Отменить штраф?
                        </ModalHeader>
                        <ModalBody>
                            {modeId !== null && <CourierPenaltyDelete
                                sectionId={id}
                                id={modeId}
                            />}
                        </ModalBody>
                    </Modal>}
                </>
            )
        }
        return (
            <Empty>
                <h5 className={'mb-0'}>Список штрафов пуст</h5>
            </Empty>
        )
    }
}

const mapStateToProps = ({courierPenalties, courierPenaltyForm, auth}: AppState) => {
    const {data, loading, error} = courierPenalties;
    const {mode, modeId} = courierPenaltyForm;
    const {user} = auth;
    return {
        data,
        loading,
        error,
        mode,
        modeId,
        accesses: user !== null ? user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getCourierPenalties,
    openCourierPenaltyForm,
    closeCourierPenaltyForm
};


export default connect(mapStateToProps, mapDispatchToProps)(CourierPenaltiesContainer);
