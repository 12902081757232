import React, {PureComponent} from 'react';
import {Alert, Button, Form, Spinner, FormGroup, Label, FormFeedback} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import Dropdown from "../UI/Dropdown";
import {sanitizeForm} from "../../utils/sanitize-form";
import {IDictionary} from "../../models/Dictionary";
import {getDictionaryOptions} from "../../utils/dictionaries";
import _ from 'lodash';
import {ICourierChangePointForm} from "../../models/Courier";


const schema = yup.object({
    pointId: yup.number().nullable().required(requiredMessage())
});

type CourierChangePointType = {
    changePointCourierForm: (id: number, form: ICourierChangePointForm) => void
    id: number
    pointId: number
    points: IDictionary[]
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
}

class CourierChangePoint extends PureComponent<CourierChangePointType> {
    render() {
        const {changePointCourierForm, changeLoading, changeError, cancelHandler, id, points, pointId} = this.props;

        const pointOptions = getDictionaryOptions(points);
        const point = points.find(({key}) => key === pointId) as IDictionary;

        return (
            <>
                <div className={'mb-3'}>
                    <div className={'gray-600'}>Текущий район</div>
                    {_.get(point, 'value')}
                </div>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => {
                        const pointId = sanitizeForm(values.pointId);
                        const form = {
                            pointId
                        };

                        changePointCourierForm(id, form)
                    }}
                    initialValues={{
                        pointId: null
                    }}
                >
                    {({
                          handleSubmit,
                          values,
                          touched,
                          errors,
                          setFieldValue
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Новый район <span className={'required'}>*</span></Label>
                                <Dropdown
                                    name={'pointId'}
                                    value={values.pointId}
                                    options={pointOptions}
                                    onChange={(value) => setFieldValue('pointId', value)}
                                    invalid={touched.pointId && !!errors.pointId}
                                />
                                <FormFeedback>{errors.pointId}</FormFeedback>
                            </FormGroup>
                            {changeError &&
                            (<Alert className={'mt-3'} color={'danger'}>
                                {changeError}
                            </Alert>)}
                            <div className={'text-right'}>
                                {cancelHandler &&
                                <Button color="light" onClick={cancelHandler} className={'mr-3'}>Отменить</Button>}
                                <Button type="submit" color={'primary'} disabled={changeLoading}>
                                    {changeLoading &&
                                    (<Spinner
                                        size="sm"
                                    />)}
                                    Переназначить район
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }
}

export default CourierChangePoint;
