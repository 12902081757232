import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import {RouteComponentProps} from "react-router-dom";
import Layout from "../../containers/Layout";
import PointZones from "../../containers/PointZones";



type RouteParamsType = { id: string }

class PointZonesPage extends Component<RouteComponentProps<RouteParamsType>> {
    render() {
        const {match} = this.props;
        const id = +match.params.id;

        return (
            <Layout>
                <Title>Зоны доставки</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Отправные точки', url: `/points`},
                    {name: 'Зоны доставки', url: `/points/${id}`}
                ]}/>
                <PointZones id={id}/>
            </Layout>
        );
    }
}

export default PointZonesPage;
