export const GET_ARCHIVE_ORDERS = Symbol('GET_ARCHIVE_ORDERS');
export const GET_ARCHIVE_ORDERS_REQUEST = Symbol('GET_ARCHIVE_ORDERS_REQUEST');
export const GET_ARCHIVE_ORDERS_SUCCESS = Symbol('GET_ARCHIVE_ORDERS_SUCCESS');
export const GET_ARCHIVE_ORDERS_ERROR = Symbol('GET_ARCHIVE_ORDERS_ERROR');

export const GET_ARCHIVE_ORDERS_SUMMARY = Symbol('GET_ARCHIVE_ORDERS_SUMMARY');
export const GET_ARCHIVE_ORDERS_SUMMARY_REQUEST = Symbol('GET_ARCHIVE_ORDERS_SUMMARY_REQUEST');
export const GET_ARCHIVE_ORDERS_SUMMARY_SUCCESS = Symbol('GET_ARCHIVE_ORDERS_SUMMARY_SUCCESS');
export const GET_ARCHIVE_ORDERS_SUMMARY_ERROR = Symbol('GET_ARCHIVE_ORDERS_SUMMARY_ERROR');

export const CHANGE_STATUS_ARCHIVE_ORDERS = Symbol('CHANGE_STATUS_ARCHIVE_ORDERS');
export const CHANGE_STATUS_ARCHIVE_ORDERS_SUCCESS = Symbol('CHANGE_STATUS_ARCHIVE_ORDERS_SUCCESS');

export const TOGGLE_ACTIVE_ARCHIVE_ORDERS = Symbol('TOGGLE_ACTIVE_ARCHIVE_ORDERS');

export const REPLACE_IN_ARCHIVE_ORDERS = Symbol('REPLACE_IN_ARCHIVE_ORDERS');
