import {GET_POINTS_ERROR, GET_POINTS_REQUEST, GET_POINTS_SUCCESS} from "../constants/points";
import {IActionPoints} from "../actions/points";
import {IPoint} from "../../models/Point";


export interface IPointsState {
    loading: boolean
    error: string
    data: IPoint[]
}

const initialState: IPointsState = {
    data: [],
    loading: false,
    error: ''
};

export default function points(state = initialState, action: IActionPoints): IPointsState {
    switch (action.type) {
        case GET_POINTS_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_POINTS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_POINTS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
