import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import Empty from "../components/UI/Empty";
import {IAuth} from "../models/Auth";
import _ from "lodash";
import {ICourier, ICourierFilter} from "../models/Courier";
import {couriersCityIdReSelector} from "../store/reselectors/couriers";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import {Button} from "reactstrap";
import {
    courierTypeYMapsBalloonContents,
    courierTypeYMapsIcons
} from "../utils/couriers";
import {getFilteredCouriersList} from "../store/actions/couriersFilteredList";

type CouriersLocationsType = {
    getFilteredCouriersList: (props?: { expand?: string, body?: ICourierFilter }) => void
    cancelHandler?: () => void
    cityId: number
    data: ICourier[]
    loading: boolean
    error: string
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class CouriersLocationsContainer extends PureComponent<CouriersLocationsType & CitiesType & RouteComponentProps> {

    state = {
        couriers: [],
        bounds: [],
        intervalCall: setInterval(async () => {
            this.getCouriers();
        }, 30000)
    }

    getCouriers() {
        const {getFilteredCouriersList, cityId, allowedCities} = this.props;

        getFilteredCouriersList({
            expand: 'location,lastActiveOrder',
            body: {
                cityId: cityId || allowedCities[0].id,
                activeSlot: true
            }
        })
    }

    componentDidMount() {
        const {intervalCall, couriers} = this.state;
        if (!couriers.length){
            this.getCouriers();
        }

        const interval = intervalCall;
    }

    componentDidUpdate(prevProps: Readonly<CouriersLocationsType & CitiesType & RouteComponentProps>, prevState: Readonly<{}>) {
        const {data} = this.props;
        const {couriers} = this.state;
        if (data.length > 0){
            if (!couriers.length){
                let array: ICourier[] = [];
                data.forEach((props: ICourier) => {
                    props.location && array.push(props);
                })

                const bounds: number[][] = [
                    [
                        Math.min.apply(Math, array.map(function(props: ICourier) { return props.location!.lat; })),
                        Math.min.apply(Math, array.map(function(props: ICourier) { return props.location!.long }))
                    ],
                    [
                        Math.max.apply(Math, array.map(function(props: ICourier) { return props.location!.lat; })),
                        Math.max.apply(Math, array.map(function(props: ICourier) { return props.location!.long }))
                    ]
                ]

                this.setState({bounds});
            }

            if (data !== couriers){
                this.setState({couriers: data})
            }
        }
    }

    render() {
        const {cancelHandler, error} = this.props;
        const {intervalCall, couriers, bounds} = this.state;

        const cancelButtonClick = () => {
            if (cancelHandler){
                cancelHandler();
            }

            clearInterval(intervalCall);
        }

        if (error) return <Error error={error} refresh={() => this.getCouriers()}/>

        if (!couriers.length) return <>
            <Empty>
                <h5 className={'mb-0'}>Список курьеров пуст</h5>
            </Empty>
                <div className={'text-right'}>
                    <Button
                        className={'mb-2 mr-2'}
                        color={'light'}
                        onClick={cancelButtonClick}>
                        Выйти</Button>
                </div>
        </>

        return (
            <>
                <div style={{height: '90vh'}}>
                        <YMaps>
                            <Map
                                height={'100%'}
                                width={'100%'}
                                state={{
                                    bounds
                                }}>
                                {couriers.map((props: ICourier, index) => {

                                    let courierParams = {
                                        icon: typeof props.courierTypeId === 'number' ? courierTypeYMapsIcons[props.courierTypeId] : '',
                                        balloonContent: typeof props.courierTypeId === 'number' ? courierTypeYMapsBalloonContents[props.courierTypeId] : '',
                                        color: 'green',
                                        courierStatus: 'свободен'
                                    };

                                    if (props.orderStatus !== null && props.orderStatus !== undefined && props.orderStatus > 1){
                                        courierParams = {
                                            ...courierParams,
                                            color: 'red',
                                            courierStatus: props.orderStatusName!
                                        }
                                    }

                                    if (!props.location) return;

                                    return (
                                        <Placemark
                                            key={index}
                                            geometry={[props.location?.lat, props.location?.long]}
                                            properties={
                                                {
                                                    balloonContent: `
                                                         <a href="/couriers/${props.id}">${props.fio}</a> ${courierParams.balloonContent}
                                                        <br/>
                                                        <a href="tel:+${props.phone}">+${props.phone}</a>
                                                        <br/>
                                                        <div class=${courierParams.color}><span>●</span> ${courierParams.courierStatus}</div>`
                                                }}
                                            options={{
                                                preset: `islands#${courierParams.color}${courierParams.icon}Icon`,
                                            }}
                                            modules={['geoObject.addon.balloon']}
                                        />)
                                })}
                            </Map>
                        </YMaps>
                    </div>
                    <div className={'position-absolute p-3'} style={{right: '20px', bottom: '20px', background: '#fff'}}>
                        {cancelHandler &&
                        <Button color="light" onClick={cancelButtonClick} className={'mr-3'}>Закрыть</Button>}
                        <Button color={'primary'} onClick={() => this.getCouriers()}>
                            Обновить данные
                        </Button>
                    </div>
            </>
        );
    }
}


const mapStateToProps = ({couriersFilteredList, auth}: AppState, props: RouteComponentProps) => {
    const {data, loading, error} = couriersFilteredList;
    const {user} = auth;

    return {
        allowedCities: _.get(user, 'allowedCities', []),
        data,
        cityId: couriersCityIdReSelector(props),
        loading,
        error
    }
};

const mapDispatchToProps = {
    getFilteredCouriersList
};

export default compose<React.ComponentClass<{cancelHandler?:()=>void}>>(withRouter, connect(mapStateToProps, mapDispatchToProps))(CouriersLocationsContainer);
