import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {addCityForm} from "../store/actions/cityForm";
import {ICityAddFormRequest} from "../models/City";
import CityAdd from "../components/CityAdd";


type CityAddType = {
    addCityForm: (form: ICityAddFormRequest) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
}


class CityAddContainer extends Component<CityAddType> {

    render() {
        const {changeLoading, changeError, addCityForm, cancelHandler} = this.props;

        return (
            <CityAdd
                addCityForm={addCityForm}
                changeLoading={changeLoading}
                changeError={changeError}
                cancelHandler={cancelHandler}
            />
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.cityForm;
    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    addCityForm
};


export default connect(mapStateToProps, mapDispatchToProps)(CityAddContainer);
