import {ISchedule} from "../../models/Schedule";
import {
    ADD_SCHEDULE_FORM_ERROR,
    ADD_SCHEDULE_FORM_REQUEST,
    ADD_SCHEDULE_FORM_SUCCESS,
    COPY_SCHEDULE_FORM_ERROR,
    COPY_SCHEDULE_FORM_REQUEST,
    COPY_SCHEDULE_FORM_SUCCESS,
    GET_SCHEDULE_FORM_ERROR,
    GET_SCHEDULE_FORM_REQUEST,
    GET_SCHEDULE_FORM_SUCCESS
} from "../constants/scheduleForm";
import {IActionScheduleForm} from "../actions/scheduleForm";


export interface IScheduleFormState {
    getLoading: boolean
    getError: string
    data: ISchedule | null
    addLoading: boolean
    addError: string
    copyLoading: boolean
    copyError: string
}

const initialState: IScheduleFormState = {
    data: null,
    getLoading: false,
    getError: '',
    addLoading: false,
    addError: '',
    copyLoading: false,
    copyError: ''
};

export default function scheduleForm(state = initialState, action: IActionScheduleForm): IScheduleFormState {
    switch (action.type) {
        case GET_SCHEDULE_FORM_REQUEST:
            return {
                ...state,
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_SCHEDULE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_SCHEDULE_FORM_ERROR:
            return {
                ...state,
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case ADD_SCHEDULE_FORM_REQUEST:
            return {
                ...state,
                addLoading: true,
                addError: ''
            };
        case ADD_SCHEDULE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                addLoading: false,
                addError: ''
            };
        case ADD_SCHEDULE_FORM_ERROR:
            return {
                ...state,
                addLoading: false,
                addError: action.payload
            };
        case COPY_SCHEDULE_FORM_REQUEST:
            return {
                ...state,
                copyLoading: true,
                copyError: ''
            };
        case COPY_SCHEDULE_FORM_SUCCESS:
            return {
                ...state,
                copyLoading: false,
                copyError: ''
            };
        case COPY_SCHEDULE_FORM_ERROR:
            return {
                ...state,
                copyLoading: false,
                copyError: action.payload
            };
        default:
            return state;
    }
}
