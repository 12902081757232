export const GET_REPORT_EMENU = Symbol('GET_REPORT_EMENU')
export const GET_REPORT_EMENU_REQUEST = Symbol('GET_REPORT_EMENU_REQUEST')
export const GET_REPORT_EMENU_SUCCESS = Symbol('GET_REPORT_EMENU_SUCCESS')
export const GET_REPORT_EMENU_ERROR = Symbol('GET_REPORT_EMENU_ERROR')

export const day = [
    "ВС",
    "ПН",
    "ВТ",
    "СР",
    "ЧТ",
    "ПТ",
    "СБ"
]

const month = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь"
]

export const monthOptions:any = []

const year = new Date().getFullYear()

for (let monthNumber = 0; monthNumber <= 11; monthNumber++) {
    monthOptions.push(
        {
            label: month[monthNumber],
            value: monthNumber
        }
    )
}

export const yearsOptions:any = []

for (let yearElement = 2020; yearElement <= year; yearElement++){
    yearsOptions.push({
        label: yearElement,
        value: yearElement
    })
}
