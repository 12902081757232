import React, {Component} from "react";
import {connect} from "react-redux";
import {getPoints} from "../store/actions/points";
import {AppState} from "../store/reducers";
import Points from "../components/Points";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import PointEdit from "./PointEdit";
import PointDelete from "./PointDelete";
import {
    closePointForm,
    openPointForm
} from "../store/actions/pointForm";
import {IPoint} from "../models/Point";
import Empty from "../components/UI/Empty";
import {Redirect} from "react-router-dom";
import {IAuth} from "../models/Auth";
import {pointsCityIdReSelector} from "../store/reselectors/points";
import _ from "lodash";


type PointsType = {
    getPoints: (cityId?: number) => void
    openPointForm: (mode: 'edit' | 'delete', modeId: number) => void
    closePointForm: () => void
    cityId?: number
    data: IPoint[]
    loading: boolean
    error: string
    mode: 'add' | 'edit' | 'delete' | null
    modeId: number | null
    accesses?: string[]
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class PointsContainer extends Component<PointsType & CitiesType & RouteComponentProps> {

    componentDidMount() {
        const {getPoints, cityId} = this.props;
        getPoints(cityId)
    }

    componentDidUpdate(prevProps: Readonly<PointsType & CitiesType & RouteComponentProps>) {
        const {cityId, getPoints} = this.props;
        if (prevProps.cityId !== cityId) {
            getPoints(cityId)
        }
    }

    editHandler = (id:number) =>{
        const {openPointForm} = this.props;
        openPointForm('edit', id)
    };

    deleteHandler = (id:number) =>{
        const {openPointForm} = this.props;
        openPointForm('delete', id)
    };

    linkHandler = (id:number) =>{
        const {history} = this.props;
        history.push(`/points/${id}`)
    };

    render() {
        const {
            data, loading, error, cityId, accesses, allowedCities, modeId,mode,closePointForm, getPoints
        } = this.props;

        if (loading) {
            return <Loader/>
        }
        if (error) {
            return <Error error={error} refresh={() => getPoints(cityId)}/>
        }
        if (!cityId) {
            return <Redirect to={`/points?cityId=${allowedCities[0].id}`}/>
        }

        if (!!data.length) {
            return (
                <>
                    <div className={'mb-3'}>
                        <Points
                            accesses={accesses}
                            data={data}
                            linkHandler={this.linkHandler}
                            editHandler={this.editHandler}
                            deleteHandler={this.deleteHandler}
                        />
                    </div>
                    {modeId !== null && <Modal size={'lg'} isOpen={mode === 'edit'} toggle={closePointForm}>
                        <ModalHeader toggle={closePointForm}>
                            Редактирование отправной точки
                        </ModalHeader>
                        <ModalBody>
                            <PointEdit id={modeId} cancelHandler={closePointForm}/>
                        </ModalBody>
                    </Modal>}
                    {modeId !== null && <Modal isOpen={mode === 'delete'} toggle={closePointForm}>
                        <ModalHeader toggle={closePointForm}>
                            Удалить отправную точку?
                        </ModalHeader>
                        <ModalBody>
                            <PointDelete id={modeId} cancelHandler={closePointForm}/>
                        </ModalBody>
                    </Modal>}
                </>
            )
        }

        return (
            <Empty>
                <h3>Список районов пуст</h3>
                <p className={'mb-0'}>Чтобы добавить новый район для города, нажмите кнопку «Добавить отправную
                    точку»</p>
            </Empty>
        )
    }
}


const mapStateToProps = ({points, pointForm, auth}: AppState, props: RouteComponentProps) => {
    const {data, loading, error} = points;
    const {mode, modeId} = pointForm;
    const {user} = auth;

    return {
        accesses: user !== null ? user.accesses : undefined,
        allowedCities: _.get(user, 'allowedCities', []),
        data,
        cityId: pointsCityIdReSelector(props),
        loading,
        error,
        mode,
        modeId
    }
};

const mapDispatchToProps = {
    getPoints,
    closePointForm,
    openPointForm
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(PointsContainer);
