export const requiredMessage = (): string => {
    return "Поле обязательное";
};

export const typeMessage = (): string => {
    return `Неверный формат`;
};

export const minLengthMessage = (count: number): string => {
    return `Поле должно содержать не менее ${count} символов`;
};

export const maxLengthMessage = (count: number): string => {
    return `Поле должно содержать не более ${count} символов`;
};

export const positiveNumberMessage = (): string => {
    return `Поле должно содержать положительные числа`;
};

export const matchMessage = (): string => {
    return `Не совпадают поля`;
};
