import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {addPointForm} from "../store/actions/pointForm";
import PointAdd from "../components/PointAdd";
import {IPointAddFormRequest} from "../models/Point";
import {IAuth} from "../models/Auth";
import _ from "lodash";


type PointAddType = {
    addPointForm: (form: IPointAddFormRequest) => void
    cityId?: number
    changeLoading: boolean
    changeError: string
    cancelHandler?:()=>void
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class PointAddContainer extends Component<PointAddType & CitiesType> {

    render() {
        const {changeLoading, changeError, addPointForm, allowedCities, cityId,cancelHandler} = this.props;

        return (
            <PointAdd
                addPointForm={addPointForm}
                cityId={cityId}
                changeLoading={changeLoading}
                changeError={changeError}
                allowedCities={allowedCities}
                cancelHandler={cancelHandler}
            />
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.pointForm;
    const {user} = state.auth;
    return {
        changeLoading,
        changeError,
        allowedCities: _.get(user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    addPointForm
};


export default connect(mapStateToProps, mapDispatchToProps)(PointAddContainer);
