import {
    GET_COURIERS_LOCATIONS_ERROR,
    GET_COURIERS_LOCATIONS_REQUEST,
    GET_COURIERS_LOCATIONS_SUCCESS
} from "../constants/couriersLocations";
import {ICourier} from "../../models/Courier";
import {IActionCouriersLocations} from "../actions/couriersLocations";


export interface ICouriersLocationsState {
    loading: boolean
    error: string
    data: ICourier[]
}

const initialState: ICouriersLocationsState = {
    data: [],
    loading: false,
    error: ''
};

export default function couriersLocations(state = initialState, action: IActionCouriersLocations): ICouriersLocationsState {
    switch (action.type) {
        case GET_COURIERS_LOCATIONS_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_COURIERS_LOCATIONS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_COURIERS_LOCATIONS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
