export const GET_SCHEDULE_FORM = Symbol('GET_SCHEDULE_FORM');
export const GET_SCHEDULE_FORM_REQUEST = Symbol('GET_SCHEDULE_FORM_REQUEST');
export const GET_SCHEDULE_FORM_SUCCESS = Symbol('GET_SCHEDULE_FORM_SUCCESS');
export const GET_SCHEDULE_FORM_ERROR = Symbol('GET_SCHEDULE_FORM_ERROR');
export const ADD_SCHEDULE_FORM = Symbol('ADD_SCHEDULE_FORM');
export const ADD_SCHEDULE_FORM_REQUEST = Symbol('ADD_SCHEDULE_FORM_REQUEST');
export const ADD_SCHEDULE_FORM_SUCCESS = Symbol('ADD_SCHEDULE_FORM_SUCCESS');
export const ADD_SCHEDULE_FORM_ERROR = Symbol('ADD_SCHEDULE_FORM_ERROR');
export const COPY_SCHEDULE_FORM = Symbol('COPY_SCHEDULE_FORM');
export const COPY_SCHEDULE_FORM_REQUEST = Symbol('COPY_SCHEDULE_FORM_REQUEST');
export const COPY_SCHEDULE_FORM_SUCCESS = Symbol('COPY_SCHEDULE_FORM_SUCCESS');
export const COPY_SCHEDULE_FORM_ERROR = Symbol('COPY_SCHEDULE_FORM_ERROR');

