import {CHANGE_COURIER_RATE, GET_COURIER_RATE} from "../constants/courierRate";
import {
    changeCourierRateError,
    changeCourierRateRequest, changeCourierRateSuccess,
    getCourierRateError,
    getCourierRateRequest,
    getCourierRateSuccess
} from "../actions/courierRate";
import {call, put, takeLatest} from 'redux-saga/effects';
import {CourierService} from "../../services/CourierService";
import {ICourierRate, ICourierRateForm} from "../../models/Courier";

type getCourierRateType = {
    type: typeof GET_COURIER_RATE,
    payload: {
        cityId: number
    }
}

function* getCourierRateSaga({payload}: getCourierRateType) {
    try {
        yield put(getCourierRateRequest());

        const service = new CourierService();

        const promise = yield call(service.getCourierRate, payload.cityId);

        const data: ICourierRate[] = promise.data || [];

        yield put(getCourierRateSuccess(data));

    } catch (error) {
        yield put(getCourierRateError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCourierRate() {
    yield takeLatest(GET_COURIER_RATE, getCourierRateSaga);
}

type changeCourierRateType = {
    type: typeof CHANGE_COURIER_RATE,
    payload: {
        cityId: number,
        form: ICourierRateForm[]
    }
}

function* changeCourierRateSaga({payload} : changeCourierRateType) {
    try {
        yield put(changeCourierRateRequest());

        const service = new CourierService();

        const promise = yield call(service.changeCourierRate, payload.cityId, payload.form);

        yield put(changeCourierRateSuccess());
    } catch (error) {
        yield put(changeCourierRateError(error.responce.data.message || "Ошибка"));
    }
}

export function* watchChangeCourierRate() {
    yield takeLatest(CHANGE_COURIER_RATE, changeCourierRateSaga);
}
