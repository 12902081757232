import {
    ADD_EMPLOYEE_FORM,
    ADD_EMPLOYEE_FORM_REQUEST,
    ADD_EMPLOYEE_FORM_SUCCESS,
    ADD_EMPLOYEE_FORM_ERROR,
    EDIT_EMPLOYEE_FORM_REQUEST,
    EDIT_EMPLOYEE_FORM_SUCCESS,
    EDIT_EMPLOYEE_FORM_ERROR,
    EDIT_EMPLOYEE_FORM,
    GET_EMPLOYEE_FORM,
    GET_EMPLOYEE_FORM_REQUEST,
    GET_EMPLOYEE_FORM_SUCCESS,
    GET_EMPLOYEE_FORM_ERROR,
    DELETE_EMPLOYEE_FORM_REQUEST,
    DELETE_EMPLOYEE_FORM_SUCCESS,
    DELETE_EMPLOYEE_FORM_ERROR,
    DELETE_EMPLOYEE_FORM,
    CHANGE_PASSWORD_EMPLOYEE_FORM_REQUEST,
    CHANGE_PASSWORD_EMPLOYEE_FORM_ERROR,
    CHANGE_PASSWORD_EMPLOYEE_FORM_SUCCESS,
    CHANGE_PASSWORD_EMPLOYEE_FORM,
    OPEN_EMPLOYEE_FORM,
    CLOSE_EMPLOYEE_FORM
} from '../constants/employeeForm'
import {IAction} from "./index";
import {
    IEmployee,
    IEmployeeAddFormRequest,
    IEmployeeChangePasswordFormRequest,
    IEmployeeEditFormRequest
} from '../../models/Employee';


export interface IActionEmployeeForm extends IAction<symbol, any> {

}


export const addEmployeeFormRequest = () => {
    return {
        type: ADD_EMPLOYEE_FORM_REQUEST
    }
};

export const addEmployeeFormSuccess = (payload: IEmployee) => {
    return {
        type: ADD_EMPLOYEE_FORM_SUCCESS,
        payload
    }
};

export const addEmployeeFormError = (payload: string) => {
    return {
        type: ADD_EMPLOYEE_FORM_ERROR,
        payload
    }
};

export const addEmployeeForm = (payload: IEmployeeAddFormRequest) => {
    return {
        type: ADD_EMPLOYEE_FORM,
        payload
    };
};

export const editEmployeeFormRequest = () => {
    return {
        type: EDIT_EMPLOYEE_FORM_REQUEST
    }
};

export const editEmployeeFormSuccess = (payload: IEmployee) => {
    return {
        type: EDIT_EMPLOYEE_FORM_SUCCESS,
        payload
    }
};

export const editEmployeeFormError = (payload: string) => {
    return {
        type: EDIT_EMPLOYEE_FORM_ERROR,
        payload
    }
};

export const editEmployeeForm = (id: number, form: IEmployeeEditFormRequest) => {
    return {
        type: EDIT_EMPLOYEE_FORM,
        payload: {id, form}
    };
};

export const getEmployeeFormRequest = () => {
    return {
        type: GET_EMPLOYEE_FORM_REQUEST
    }
};

export const getEmployeeFormSuccess = (payload: IEmployee) => {
    return {
        type: GET_EMPLOYEE_FORM_SUCCESS,
        payload
    }
};

export const getEmployeeFormError = (payload: string) => {
    return {
        type: GET_EMPLOYEE_FORM_ERROR,
        payload
    }
};

export const getEmployeeForm = (payload: number) => {
    return {
        type: GET_EMPLOYEE_FORM,
        payload
    };
};

export const deleteEmployeeFormRequest = () => {
    return {
        type: DELETE_EMPLOYEE_FORM_REQUEST
    }
};

export const deleteEmployeeFormSuccess = () => {
    return {
        type: DELETE_EMPLOYEE_FORM_SUCCESS
    }
};

export const deleteEmployeeFormError = (payload: string) => {
    return {
        type: DELETE_EMPLOYEE_FORM_ERROR,
        payload
    }
};

export const deleteEmployeeForm = (payload: number) => {
    return {
        type: DELETE_EMPLOYEE_FORM,
        payload
    };
};

export const changePasswordEmployeeFormRequest = () => {
    return {
        type: CHANGE_PASSWORD_EMPLOYEE_FORM_REQUEST
    }
};

export const changePasswordEmployeeFormSuccess = (payload: IEmployee) => {
    return {
        type: CHANGE_PASSWORD_EMPLOYEE_FORM_SUCCESS,
        payload
    }
};

export const changePasswordEmployeeFormError = (payload: string) => {
    return {
        type: CHANGE_PASSWORD_EMPLOYEE_FORM_ERROR,
        payload
    }
};

export const changePasswordEmployeeForm = (id: number, form: IEmployeeChangePasswordFormRequest) => {
    return {
        type: CHANGE_PASSWORD_EMPLOYEE_FORM,
        payload: {
            id, form
        }
    };
};

export const openEmployeeForm = (mode: 'add' | 'edit' | 'delete' | 'changePassword', modeId?: number) => {
    return {
        type: OPEN_EMPLOYEE_FORM,
        payload: {
            mode,
            modeId
        }
    };
};

export const closeEmployeeForm = () => {
    return {
        type: CLOSE_EMPLOYEE_FORM
    };
};


