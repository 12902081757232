import {
    ADD_COURIER_PENALTY_FORM_REQUEST,
    ADD_COURIER_PENALTY_FORM_SUCCESS,
    ADD_COURIER_PENALTY_FORM_ERROR,
    CLOSE_COURIER_PENALTY_FORM,
    OPEN_COURIER_PENALTY_FORM,
    DELETE_COURIER_PENALTY_FORM_REQUEST, DELETE_COURIER_PENALTY_FORM_ERROR, DELETE_COURIER_PENALTY_FORM_SUCCESS
} from '../constants/courierPenaltyForm'
import {ICourierPenalty} from "../../models/Courier";
import {IActionCourierPenaltyForm} from "../actions/courierPenaltyForm";


export interface ICourierPenaltyFormState {
    getLoading: boolean
    getError: string
    data: ICourierPenalty | null
    changeLoading: boolean
    changeError: string
    mode: 'add' | 'delete' | null
    modeId: number | null
}

const initialState: ICourierPenaltyFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null,
    modeId: null
};

export default function courierPenaltyForm(state = initialState, action: IActionCourierPenaltyForm): ICourierPenaltyFormState {
    switch (action.type) {
        case ADD_COURIER_PENALTY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_COURIER_PENALTY_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_COURIER_PENALTY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case DELETE_COURIER_PENALTY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case DELETE_COURIER_PENALTY_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case DELETE_COURIER_PENALTY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_COURIER_PENALTY_FORM:
            const {mode, modeId = null} = action.payload;
            return {
                ...initialState,
                mode,
                modeId
            };
        case CLOSE_COURIER_PENALTY_FORM:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
