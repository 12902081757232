import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {Card, Col, Row} from "reactstrap";
import ScheduleCopy from "../components/ScheduleCopy";
import {copyScheduleForm} from "../store/actions/scheduleForm";
import {ISchedule, IScheduleCopyFormRequest} from "../models/Schedule";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {
    getDictionaryPublishedSchedules
} from "../store/actions/dictionaryPublishedSchedules";


type ScheduleCopyType = {
    getDictionaryPublishedSchedules: (cityId?: number) => void
    copyScheduleForm: (id: number, form: IScheduleCopyFormRequest) => void
    cityId?: number
    copyLoading: boolean
    copyError: string
    date: { dateStart: string, dateEnd: string }
    dictionaryPublishedSchedules: ISchedule[]
    dictionaryPublishedSchedulesLoading: boolean
    dictionaryPublishedSchedulesError: string
}

class ScheduleCopyContainer extends Component<ScheduleCopyType> {
    
    /**
     * При изменении города получить новый список недель для копирования.
     * Здесь, так как компонент выбора города PickCity общий для нескольких разделов, там нет логики для конкретного раздела.
     * @see PickCity
     * При монтировании условие тоже true, т.е. componentDidUpdate работает и как componentDidMount.
     */
    componentDidUpdate(prevProps: Readonly<ScheduleCopyType>) {
        const {getDictionaryPublishedSchedules, cityId} = this.props;
        
        if (cityId !== prevProps.cityId) {
            getDictionaryPublishedSchedules(cityId);
        }
    }
    
    render() {
        const {
            cityId, copyError, copyLoading, copyScheduleForm, date,
            dictionaryPublishedSchedules, dictionaryPublishedSchedulesLoading,
            dictionaryPublishedSchedulesError, getDictionaryPublishedSchedules
        } = this.props;

        if (dictionaryPublishedSchedulesLoading) {
            return <Loader/>
        }

        if (dictionaryPublishedSchedulesError) {
            return <Error error={dictionaryPublishedSchedulesError}
                          refresh={() => getDictionaryPublishedSchedules(cityId)}/>
        }

        if (!!dictionaryPublishedSchedules.length) {
            return (
                <Card body style={{height: '100%'}}>
                    <div className={'text-center'}>
                        <p className={'mb-2'}>Создайте расписание путем копирования — выберите
                            неделю</p>
                        <Row className="justify-content-center">
                            <Col md={'9'}>
                                <ScheduleCopy
                                    date={date}
                                    copyScheduleForm={copyScheduleForm}
                                    copyLoading={copyLoading}
                                    copyError={copyError}
                                    dictionaryPublishedSchedules={dictionaryPublishedSchedules}
                                />
                            </Col>
                        </Row>
                    </div>
                </Card>
            )
        }
        return null
    }
}


const mapStateToProps = ({scheduleForm, dictionaryPublishedSchedules}: AppState) => {
    return {
        copyLoading: scheduleForm.copyLoading,
        copyError: scheduleForm.copyError,
        dictionaryPublishedSchedules: dictionaryPublishedSchedules.data,
        dictionaryPublishedSchedulesLoading: dictionaryPublishedSchedules.loading,
        dictionaryPublishedSchedulesError: dictionaryPublishedSchedules.error
    }
};

const mapDispatchToProps = {
    copyScheduleForm,
    getDictionaryPublishedSchedules
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCopyContainer);
