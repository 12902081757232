import * as types from "../constants/courierSlot";
import {ISlotChange} from "../../models/Slot";
import {IAction} from "./index";

export interface IActionCourierSlot extends IAction<symbol, any> {

}

export const changeCourierSlotRequest = () => {
    return {
        type: types.CHANGE_COURIER_SLOT_REQUEST
    }
}

export const changeCourierSlotSuccess = () => {
    return {
        type: types.CHANGE_COURIER_SLOT_SUCCESS
    }
}

export const changeCourierSlotError = (payload: string) => {
    return {
        type: types.CHANGE_COURIER_SLOT_ERROR,
        payload
    }
}

export const changeCourierSlot = (id: string, form: ISlotChange) => {
    return {
        type: types.CHANGE_COURIER_SLOT,
        payload: {
            id,
            form
        }
    }
}