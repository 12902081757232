import {
    GET_DICTIONARY_PUBLISHED_SCHEDULES,
    GET_DICTIONARY_PUBLISHED_SCHEDULES_REQUEST,
    GET_DICTIONARY_PUBLISHED_SCHEDULES_SUCCESS,
    GET_DICTIONARY_PUBLISHED_SCHEDULES_ERROR
} from '../constants/dictionaryPublishedSchedules'
import {IAction} from "./index";
import { ISchedule } from '../../models/Schedule';


export interface IActionDictionaryPublishedSchedules extends IAction<symbol, any> {

}

export const getDictionaryPublishedSchedulesRequest = () => {
    return {
        type: GET_DICTIONARY_PUBLISHED_SCHEDULES_REQUEST
    }
};

export const getDictionaryPublishedSchedulesSuccess = (payload: ISchedule[]) => {
    return {
        type: GET_DICTIONARY_PUBLISHED_SCHEDULES_SUCCESS,
        payload
    }
};

export const getDictionaryPublishedSchedulesError = (payload: string) => {
    return {
        type: GET_DICTIONARY_PUBLISHED_SCHEDULES_ERROR,
        payload
    }
};

export const getDictionaryPublishedSchedules = (payload?:number) => {
    return {
        type: GET_DICTIONARY_PUBLISHED_SCHEDULES,
        payload
    };
};


