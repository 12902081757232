import {
    GET_COURIER_SCHEDULE_ERROR,
    GET_COURIER_SCHEDULE_REQUEST,
    GET_COURIER_SCHEDULE_SUCCESS
} from "../constants/courierSchedule";
import {IActionCourierSchedule} from "../actions/courierSchedule";
import {ICourierSchedule} from "../../models/Courier";



export interface ICourierScheduleState {
    loading: boolean
    error: string
    data: ICourierSchedule[]
}

const initialState: ICourierScheduleState = {
    data: [],
    loading: false,
    error: ''
};

export default function courierSchedule(state = initialState, action: IActionCourierSchedule): ICourierScheduleState {
    switch (action.type) {
        case GET_COURIER_SCHEDULE_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_COURIER_SCHEDULE_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_COURIER_SCHEDULE_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
