import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addEmployeeFormRequest,
    addEmployeeFormSuccess,
    addEmployeeFormError,
    closeEmployeeForm,
    editEmployeeFormRequest,
    editEmployeeFormSuccess,
    editEmployeeFormError,
    getEmployeeFormRequest,
    getEmployeeFormSuccess,
    getEmployeeFormError,
    deleteEmployeeFormRequest,
    deleteEmployeeFormSuccess,
    deleteEmployeeFormError,
    changePasswordEmployeeFormRequest,
    changePasswordEmployeeFormError,
    changePasswordEmployeeFormSuccess
} from "../actions/employeeForm";
import {
    ADD_EMPLOYEE_FORM, CHANGE_PASSWORD_EMPLOYEE_FORM,
    DELETE_EMPLOYEE_FORM,
    EDIT_EMPLOYEE_FORM,
    GET_EMPLOYEE_FORM
} from "../constants/employeeForm";
import {
    IEmployee,
    IEmployeeAddFormRequest,
    IEmployeeChangePasswordFormRequest,
    IEmployeeEditFormRequest
} from '../../models/Employee';
import {EmployeeService} from "../../services/EmployeeService";
import {getEmployees} from "../actions/employees";
import {customHistory, getUrlParams} from "../../utils/history";
import { getDictionaryCompanies } from '../actions/dictionaryCompanies';
import {employeesFilterCombiner, employeesSortCombiner} from "../reselectors/employees";
import {getEmployeesRequestForm} from "../../utils/employees";



function* addEmployeeFormSaga({payload}: { type: typeof ADD_EMPLOYEE_FORM, payload: IEmployeeAddFormRequest }) {
    try {
        yield put(addEmployeeFormRequest());

        const service = new EmployeeService();
        const promise = yield call(service.addEmployee, payload);

        const response: IEmployee = promise.data;

        yield put(addEmployeeFormSuccess(response));

        yield put(closeEmployeeForm());
    
        const params = getUrlParams();
        const page = params.page
          ? Number(params.page)
          : 1;
    
        const filter = employeesFilterCombiner(customHistory.location.search);
        const sort = employeesSortCombiner(customHistory.location.search);
    
        yield put(getEmployees(page, getEmployeesRequestForm(filter, sort)));

    } catch (error) {
        yield put(addEmployeeFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddEmployeeForm() {
    yield takeLatest(ADD_EMPLOYEE_FORM, addEmployeeFormSaga);
}


type editEmployeeFormType = {
    type: typeof EDIT_EMPLOYEE_FORM,
    payload: {
        id: number,
        form: IEmployeeEditFormRequest
    }
}

function* editEmployeeFormSaga({payload}: editEmployeeFormType) {
    try {
        yield put(editEmployeeFormRequest());

        const service = new EmployeeService();
        const promise = yield call(service.editEmployee, payload.id, payload.form);

        const response: IEmployee = promise.data;

        yield put(editEmployeeFormSuccess(response));

        yield put(closeEmployeeForm());
    
        const params = getUrlParams();
        const page = params.page
          ? Number(params.page)
          : 1;
    
        const filter = employeesFilterCombiner(customHistory.location.search);
        const sort = employeesSortCombiner(customHistory.location.search);
    
        yield put(getEmployees(page, getEmployeesRequestForm(filter, sort)));

    } catch (error) {
        yield put(editEmployeeFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditEmployeeForm() {
    yield takeLatest(EDIT_EMPLOYEE_FORM, editEmployeeFormSaga);
}

function* getEmployeeFormSaga({payload}: { type: typeof GET_EMPLOYEE_FORM, payload: number }) {
    try {
        yield put(getEmployeeFormRequest());

        const service = new EmployeeService();
        const promise = yield call(service.getEmployee, payload);

        const response: IEmployee = promise.data;

        if(response.cities && !!response.cities.length) {
            yield put(getDictionaryCompanies(response.cities[0]))
        }

        yield put(getEmployeeFormSuccess(response));

    } catch (error) {
        yield put(getEmployeeFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetEmployeeForm() {
    yield takeLatest(GET_EMPLOYEE_FORM, getEmployeeFormSaga);
}

function* deleteEmployeeFormSaga({payload}: { type: typeof DELETE_EMPLOYEE_FORM, payload: number }) {
    try {
        yield put(deleteEmployeeFormRequest());

        const service = new EmployeeService();
        yield call(service.deleteEmployee, payload);
        yield put(deleteEmployeeFormSuccess());

        yield put(closeEmployeeForm());
    
        const params = getUrlParams();
        const page = params.page
          ? Number(params.page)
          : 1;
    
        const filter = employeesFilterCombiner(customHistory.location.search);
        const sort = employeesSortCombiner(customHistory.location.search);
    
        yield put(getEmployees(page, getEmployeesRequestForm(filter, sort)));

    } catch (error) {
        yield put(deleteEmployeeFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeleteEmployeeForm() {
    yield takeLatest(DELETE_EMPLOYEE_FORM, deleteEmployeeFormSaga);
}

type changePasswordEmployeeFormType = {
    type: typeof CHANGE_PASSWORD_EMPLOYEE_FORM,
    payload: {
        id: number,
        form: IEmployeeChangePasswordFormRequest
    }
}

function* changePasswordEmployeeFormSaga({payload}: changePasswordEmployeeFormType) {
    try {
        yield put(changePasswordEmployeeFormRequest());

        const service = new EmployeeService();
        const promise = yield call(service.changePasswordEmployee, payload.id, payload.form);

        const response: IEmployee = promise.data;

        yield put(changePasswordEmployeeFormSuccess(response));

        yield put(closeEmployeeForm());
    
        const params = getUrlParams();
        const page = params.page
          ? Number(params.page)
          : 1;
    
        const filter = employeesFilterCombiner(customHistory.location.search);
        const sort = employeesSortCombiner(customHistory.location.search);
    
        yield put(getEmployees(page, getEmployeesRequestForm(filter, sort)));

    } catch (error) {
        yield put(changePasswordEmployeeFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchChangePasswordEmployeeForm() {
    yield takeLatest(CHANGE_PASSWORD_EMPLOYEE_FORM, changePasswordEmployeeFormSaga);
}
