import React, {Component} from "react";
import {connect} from "react-redux";
import ProfileChangePassword from "../components/ProfileChangePassword";
import {compose} from "redux";
import {changePasswordUser} from "../store/actions/auth";
import {IAuthChangePasswordRequest} from "../models/Auth";
import {AppState} from "../store/reducers";


type ProfileChangePasswordType = {
    changePasswordUser: (form: IAuthChangePasswordRequest) => void,
    changeLoading: boolean
    changeError: string
}

class ProfileChangePasswordContainer extends Component<ProfileChangePasswordType> {
    render() {
        const {changePasswordUser, changeLoading, changeError} = this.props;
        return <ProfileChangePassword
            changeLoading={changeLoading}
            changeError={changeError}
            changePasswordUser={changePasswordUser}
        />
    }
}

const mapStateToProps = ({auth}: AppState) => {
    const {changeLoading, changeError} = auth;
    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    changePasswordUser
};


export default compose<React.ComponentClass>(connect(mapStateToProps, mapDispatchToProps))(ProfileChangePasswordContainer);
