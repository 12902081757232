import {call, put, takeLatest} from 'redux-saga/effects';
import {getDictionaryCouriersError, getDictionaryCouriersRequest, getDictionaryCouriersSuccess} from '../actions/dictionaryCouriers';
import {GET_DICTIONARY_COURIERS} from "../constants/dictionaryCouriers";
import {IDictionary} from "../../models/Dictionary";
import { DictionaryService } from '../../services/DictionaryService';



function* getDictionaryCouriersSaga({payload}:{type: typeof GET_DICTIONARY_COURIERS, payload?:number}) {
    try {
        yield put(getDictionaryCouriersRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryCouriers, payload);

        const response:IDictionary[] = promise.data || [];

        yield put(getDictionaryCouriersSuccess(response));


    } catch (error) {
        yield put(getDictionaryCouriersError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryCouriers() {
    yield takeLatest(GET_DICTIONARY_COURIERS, getDictionaryCouriersSaga);
}
