import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getDictionaryReportCompanyPeriodError,
    getDictionaryReportCompanyPeriodRequest,
    getDictionaryReportCompanyPeriodSuccess
} from '../actions/dictionaryReportCompanyPeriod';
import {GET_DICTIONARY_REPORT_COMPANY_PERIOD} from "../constants/dictionaryReportCompanyPeriod";
import {IDictionary} from "../../models/Dictionary";
import {DictionaryService} from '../../services/DictionaryService';


function* getDictionaryReportCompanyPeriodSaga() {
    try {
        yield put(getDictionaryReportCompanyPeriodRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryReportCompanyPeriod);

        const response: IDictionary[] = promise.data || [];

        yield put(getDictionaryReportCompanyPeriodSuccess(response));


    } catch (error) {
        yield put(getDictionaryReportCompanyPeriodError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryReportCompanyPeriod() {
    yield takeLatest(GET_DICTIONARY_REPORT_COMPANY_PERIOD, getDictionaryReportCompanyPeriodSaga);
}
