import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import {Button, Card, CardBody} from "reactstrap";
import Layout from "../../containers/Layout";
import PointZonesSettings from "../../containers/PointZonesSettings";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";


type RouteParamsType = { id: string }

class PointZonesSettingsPage extends Component<RouteComponentProps<RouteParamsType>> {
    render() {
        const {match} = this.props;
        const id = +match.params.id;

        return (
            <Layout>
                <Title>Настройка зон доставки</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Отправные точки', url: `/points`},
                    {name: 'Зоны доставки', url: `/points/${id}`},
                    {name: 'Настройка зон доставки', url: '/'}
                ]}/>
                <Link to={`/points/${id}`}><Button color={'light'} className={'mb-2'}>Назад</Button></Link>
                <Card>
                    <CardBody>
                        <PointZonesSettings id={id}/>
                    </CardBody>
                </Card>
            </Layout>
        );
    }
}

export default PointZonesSettingsPage;
