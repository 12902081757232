import {call, put, takeLatest} from 'redux-saga/effects';
import {getDictionaryCityEagersError, getDictionaryCityEagersRequest, getDictionaryCityEagersSuccess} from '../actions/dictionaryCityEagers';
import {GET_DICTIONARY_CITY_EAGERS} from "../constants/dictionaryCityEagers";
import {IDictionary} from "../../models/Dictionary";
import { DictionaryService } from '../../services/DictionaryService';



function* getDictionaryCityEagersSaga() {
    try {
        yield put(getDictionaryCityEagersRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryCityEagers);

        const response:IDictionary[] = promise.data || [];

        yield put(getDictionaryCityEagersSuccess(response));


    } catch (error) {
        yield put(getDictionaryCityEagersError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryCityEagers() {
    yield takeLatest(GET_DICTIONARY_CITY_EAGERS, getDictionaryCityEagersSaga);
}
