import {Api} from "./Api";
import {
    IPenaltyAddFormRequest,
    IPenaltyEditFormRequest,
    IPenaltyFilterRequest,
    IPenaltySortRequest
} from "../models/Penalty";


export class PenaltyService {
    getPenalties(form?: IPenaltyFilterRequest & IPenaltySortRequest) {
        return new Api().call(`/v1/manage/penalty/search`, {method: 'POST', data: form});
    }
    addPenalty(form: IPenaltyAddFormRequest) {
        return new Api().call(`/v1/manage/penalty`, {method: 'POST', data: form});
    }
    editPenalty(id:number, form: IPenaltyEditFormRequest) {
        return new Api().call(`/v1/manage/penalty/${id}`, {method: 'POST', data: form});
    }
    getPenalty(id:number) {
        return new Api().call(`/v1/manage/penalty/${id}`, {method: 'GET'});
    }
    deletePenalty(id:number) {
        return new Api().call(`/v1/manage/penalty/${id}`, {method: 'DELETE'});
    }
}
