type CityType = {
    [key: string]: any
}

export const getCityOptions = (cities: CityType[]) => {
    return !!cities.length ? cities.map(({id, name}) => ({label: name, value: id})) : []
};

export const getCityValue = (cities: CityType[], id: number | number[]) => {
    if (!cities.length || typeof id === 'undefined' || id === null) {
        return undefined
    }

    if (Array.isArray(id)) {
        return id.map((i) => {
            const o = cities.find((o) => o.id === i);
            return o && o.name
        }).join(', ');
    }

    const o = cities.find((o) => o.id === id);
    return o && o.name

};
