import {
    ADD_COMPANY_FORM,
    ADD_COMPANY_FORM_REQUEST,
    ADD_COMPANY_FORM_SUCCESS,
    ADD_COMPANY_FORM_ERROR,
    EDIT_COMPANY_FORM_REQUEST,
    EDIT_COMPANY_FORM_SUCCESS,
    EDIT_COMPANY_FORM_ERROR,
    EDIT_COMPANY_FORM,
    GET_COMPANY_FORM,
    GET_COMPANY_FORM_REQUEST,
    GET_COMPANY_FORM_SUCCESS,
    GET_COMPANY_FORM_ERROR,
    DELETE_COMPANY_FORM_REQUEST,
    DELETE_COMPANY_FORM_SUCCESS,
    DELETE_COMPANY_FORM_ERROR,
    DELETE_COMPANY_FORM,
    OPEN_COMPANY_FORM,
    CLOSE_COMPANY_FORM
} from '../constants/companyForm'
import {IAction} from "./index";
import {ICompany, ICompanyAddFormRequest, ICompanyEditFormRequest} from "../../models/Company";



export interface IActionCompanyForm extends IAction<symbol, any> {

}

export const addCompanyFormRequest = () => {
    return {
        type: ADD_COMPANY_FORM_REQUEST
    }
};

export const addCompanyFormSuccess = (payload: ICompany) => {
    return {
        type: ADD_COMPANY_FORM_SUCCESS,
        payload
    }
};

export const addCompanyFormError = (payload: string) => {
    return {
        type: ADD_COMPANY_FORM_ERROR,
        payload
    }
};

export const addCompanyForm = (payload: ICompanyAddFormRequest) => {
    return {
        type: ADD_COMPANY_FORM,
        payload
    };
};

export const editCompanyFormRequest = () => {
    return {
        type: EDIT_COMPANY_FORM_REQUEST
    }
};

export const editCompanyFormSuccess = (payload: ICompany) => {
    return {
        type: EDIT_COMPANY_FORM_SUCCESS,
        payload
    }
};

export const editCompanyFormError = (payload: string) => {
    return {
        type: EDIT_COMPANY_FORM_ERROR,
        payload
    }
};

export const editCompanyForm = (id: number, form: ICompanyEditFormRequest) => {
    return {
        type: EDIT_COMPANY_FORM,
        payload: {id, form}
    };
};

export const getCompanyFormRequest = () => {
    return {
        type: GET_COMPANY_FORM_REQUEST
    }
};

export const getCompanyFormSuccess = (payload: ICompany) => {
    return {
        type: GET_COMPANY_FORM_SUCCESS,
        payload
    }
};

export const getCompanyFormError = (payload: string) => {
    return {
        type: GET_COMPANY_FORM_ERROR,
        payload
    }
};

export const getCompanyForm = (payload: number) => {
    return {
        type: GET_COMPANY_FORM,
        payload
    };
};


export const deleteCompanyFormRequest = () => {
    return {
        type: DELETE_COMPANY_FORM_REQUEST
    }
};

export const deleteCompanyFormSuccess = () => {
    return {
        type: DELETE_COMPANY_FORM_SUCCESS
    }
};

export const deleteCompanyFormError = (payload: string) => {
    return {
        type: DELETE_COMPANY_FORM_ERROR,
        payload
    }
};

export const deleteCompanyForm = (payload: number) => {
    return {
        type: DELETE_COMPANY_FORM,
        payload
    };
};

export const openCompanyForm = (mode: 'add' | 'edit' | 'delete', modeId?: number) => {
    return {
        type: OPEN_COMPANY_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closeCompanyForm = () => {
    return {
        type: CLOSE_COMPANY_FORM
    };
};

