import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {addPenaltyForm} from "../store/actions/penaltyForm";
import {IPenaltyAddFormRequest} from "../models/Penalty";
import PenaltyAdd from "../components/PenaltyAdd";


type PenaltyAddType = {
    addPenaltyForm: (form: IPenaltyAddFormRequest) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
}


class PenaltyAddContainer extends Component<PenaltyAddType> {

    render() {
        const {changeLoading, changeError, addPenaltyForm, cancelHandler} = this.props;

        return (
            <PenaltyAdd
                addPenaltyForm={addPenaltyForm}
                changeLoading={changeLoading}
                changeError={changeError}
                cancelHandler={cancelHandler}
            />
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.cityForm;
    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    addPenaltyForm
};


export default connect(mapStateToProps, mapDispatchToProps)(PenaltyAddContainer);
