import React, {PureComponent} from 'react';
import {Alert, Button} from "reactstrap";


type ErrorType = {
    error: string
    refresh: () => void
}

class Error extends PureComponent<ErrorType> {
    render() {
        const {error, refresh} = this.props;
        return (
            <div className={'mt-2 mb-2'}>
                <Alert color={'danger'} className={'mb-2'}>
                    {error}
                </Alert>
                <Button color={'success'} onClick={refresh}>Обновить</Button>
            </div>
        )
    }
}


export default Error;
