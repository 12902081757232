import {
    GET_DICTIONARY_POINTS,
    GET_DICTIONARY_POINTS_REQUEST,
    GET_DICTIONARY_POINTS_SUCCESS,
    GET_DICTIONARY_POINTS_ERROR
} from '../constants/dictionaryPoints'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryPoints extends IAction<symbol, any> {

}

export const getDictionaryPointsRequest = () => {
    return {
        type: GET_DICTIONARY_POINTS_REQUEST
    }
};

export const getDictionaryPointsSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_POINTS_SUCCESS,
        payload
    }
};

export const getDictionaryPointsError = (payload: string) => {
    return {
        type: GET_DICTIONARY_POINTS_ERROR,
        payload
    }
};

export const getDictionaryPoints = (payload:number) => {
    return {
        type: GET_DICTIONARY_POINTS,
        payload
    };
};



