export const ADD_PENALTY_FORM = Symbol('ADD_PENALTY_FORM');
export const ADD_PENALTY_FORM_REQUEST = Symbol('ADD_PENALTY_FORM_REQUEST');
export const ADD_PENALTY_FORM_SUCCESS = Symbol('ADD_PENALTY_FORM_SUCCESS');
export const ADD_PENALTY_FORM_ERROR = Symbol('ADD_PENALTY_FORM_ERROR');
export const EDIT_PENALTY_FORM = Symbol('EDIT_PENALTY_FORM');
export const EDIT_PENALTY_FORM_REQUEST = Symbol('EDIT_PENALTY_FORM_REQUEST');
export const EDIT_PENALTY_FORM_SUCCESS = Symbol('EDIT_PENALTY_FORM_SUCCESS');
export const EDIT_PENALTY_FORM_ERROR = Symbol('EDIT_PENALTY_FORM_ERROR');
export const GET_PENALTY_FORM = Symbol('GET_PENALTY_FORM');
export const GET_PENALTY_FORM_REQUEST = Symbol('GET_PENALTY_FORM_REQUEST');
export const GET_PENALTY_FORM_SUCCESS = Symbol('GET_PENALTY_FORM_SUCCESS');
export const GET_PENALTY_FORM_ERROR = Symbol('GET_PENALTY_FORM_ERROR');
export const DELETE_PENALTY_FORM = Symbol('DELETE_PENALTY_FORM');
export const DELETE_PENALTY_FORM_REQUEST = Symbol('DELETE_PENALTY_FORM_REQUEST');
export const DELETE_PENALTY_FORM_SUCCESS = Symbol('DELETE_PENALTY_FORM_SUCCESS');
export const DELETE_PENALTY_FORM_ERROR = Symbol('DELETE_PENALTY_FORM_ERROR');
export const OPEN_PENALTY_FORM = Symbol('OPEN_PENALTY_FORM');
export const CLOSE_PENALTY_FORM = Symbol('CLOSE_PENALTY_FORM');

