import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getCourierScheduleError,
    getCourierScheduleRequest,
    getCourierScheduleSuccess
} from "../actions/courierSchedule";
import {CourierService} from "../../services/CourierService";
import {GET_COURIER_SCHEDULE} from "../constants/courierSchedule";
import {ICourierSchedule} from "../../models/Courier";



type getCourierScheduleType = {
    type: typeof GET_COURIER_SCHEDULE,
    payload:number
};

function* getCourierScheduleSaga({payload}: getCourierScheduleType) {
    try {
        yield put(getCourierScheduleRequest());

        const service = new CourierService();

        const promise = yield call(service.getCourierSchedule, payload);

        const response: ICourierSchedule[] = promise.data;

        yield put(getCourierScheduleSuccess(response));

    } catch (error) {
        yield put(getCourierScheduleError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCourierSchedule() {
    yield takeLatest(GET_COURIER_SCHEDULE, getCourierScheduleSaga);
}

