import React, {Component} from "react";
import {getDictionaryCouriers} from "../store/actions/dictionaryCouriers";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {MapState} from "react-yandex-maps";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {IDictionary} from "../models/Dictionary";
import SalaryFilter from "../components/SalaryFilter";
import queryString from "query-string";
import {salaryCityIdReSelector, salaryFilterReSelector, salaryIsGroupReSelector} from "../store/reselectors/salary";
import {ISalaryFilterFormExtended} from "../models/Salary";

type SalaryFilterContainerType = {
    getDictionaryCouriers: (cityId: number) => void;
} & ReturnType<typeof mapStateToProps>

class SalaryFilterContainer extends Component<SalaryFilterContainerType & RouteComponentProps> {

    componentDidMount() {
        const {getDictionaryCouriers, cityId} = this.props;
        
        if (typeof cityId === "number") {
            getDictionaryCouriers(cityId);
        }
    }
    
    /**
     * При смене городов переполучить список курьеров
     */
    componentDidUpdate(prevProps: Readonly<SalaryFilterContainerType & RouteComponentProps>, prevState: Readonly<{}>) {
        const {getDictionaryCouriers, cityId} = this.props;
        
        if (typeof cityId === "number" && prevProps.cityId !== cityId) {
            getDictionaryCouriers(cityId);
        }
    }
    
    setFilterHandler = (form: ISalaryFilterFormExtended) => {
        const {history} = this.props;
        const nextQueryParams = queryString.stringify(form);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    }

    render() {

        const {
            getDictionaryCouriers,
            dictionaryCouriers,
            dictionaryCouriersLoading,
            dictionaryCouriersError,
            filter,
            cityId,
            group,
        } = this.props

        if (dictionaryCouriersLoading) {
            return <Loader/>
        }

        if (dictionaryCouriersError) {
            return <Error error={dictionaryCouriersError} refresh={() => {
                if (cityId) {
                    getDictionaryCouriers(cityId);
                }
            }}/>
        }

        return (
            <>
                <SalaryFilter
                    dictionaryCouriers={dictionaryCouriers}
                    setFilterHandler={this.setFilterHandler}
                    filter={filter || {}}
                    group={group}
                />
            </>
        );
    }
}

const mapStateToProps = ({dictionaryCouriers}: MapState, props: RouteComponentProps) => {
    return {
        cityId: salaryCityIdReSelector(props),
        filter: salaryFilterReSelector(props),
        group: salaryIsGroupReSelector(props),
        dictionaryCouriers: dictionaryCouriers.data as IDictionary[],
        dictionaryCouriersLoading: dictionaryCouriers.loading,
        dictionaryCouriersError: dictionaryCouriers.error
    }
}

const mapDispatchToProps = {
    getDictionaryCouriers,
}

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(SalaryFilterContainer);
