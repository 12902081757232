import {call, put, takeLatest} from "redux-saga/effects";
import {DELETE_ORDER_RETURN_CHECK, ADD_ORDER_RETURN_CHECK} from "../constants/orderReturnCheck";
import {
  deleteOrderReturnCheckError,
  deleteOrderReturnCheckRequest, deleteOrderReturnCheckSuccess,
  addOrderReturnCheckError,
  addOrderReturnCheckRequest,
  addOrderReturnCheckSuccess
} from "../actions/orderReturnCheck";
import {OrderService} from "../../services/OrderService";
import {replaceInArchiveOrders} from "../actions/archiveOrders";
import {AxiosResponse} from "axios";
import {IOrderShort} from "../../models/Order";

type addOrderReturnCheckType = {
  type: typeof ADD_ORDER_RETURN_CHECK;
  payload: {
    id: string;
    data: FormData;
  };
}

function* addOrderReturnCheckSaga({payload}: addOrderReturnCheckType) {
  try {
    yield put(addOrderReturnCheckRequest());
    
    const service = new OrderService();
    
    const promise: AxiosResponse<IOrderShort> = yield call(service.uploadReturnCheck, payload.id, payload.data);
    
    yield put(addOrderReturnCheckSuccess());
    
    yield put(replaceInArchiveOrders(promise.data));
    
  } catch (error) {
    yield put(addOrderReturnCheckError(error.response?.data?.message || 'Ошибка'))
  }
}

export function* watchAddOrderReturnCheck() {
  yield takeLatest(ADD_ORDER_RETURN_CHECK, addOrderReturnCheckSaga);
}

type deleteOrderReturnCheckType = {
  type: typeof DELETE_ORDER_RETURN_CHECK;
  payload: {
    id: string;
  };
}

function* deleteOrderReturnCheckSaga({payload}: deleteOrderReturnCheckType) {
  try {
    yield put(deleteOrderReturnCheckRequest());
    
    const service = new OrderService();
  
    const promise: AxiosResponse<IOrderShort> = yield call(service.deleteReturnCheck, payload.id);
    
    yield put(deleteOrderReturnCheckSuccess());
  
    yield put(replaceInArchiveOrders(promise.data));
    
  } catch (error) {
    yield put(deleteOrderReturnCheckError(error.response?.data?.message || 'Ошибка'))
  }
}

export function* watchDeleteOrderReturnCheck() {
  yield takeLatest(DELETE_ORDER_RETURN_CHECK, deleteOrderReturnCheckSaga);
}
