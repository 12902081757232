import {GET_COMPANY_CONTACTS_ERROR, GET_COMPANY_CONTACTS_REQUEST, GET_COMPANY_CONTACTS_SUCCESS} from "../constants/companyContacts";
import {ICompanyContact} from "../../models/Company";
import { IActionCompanyContacts } from "../actions/companyContacts";


export interface ICompanyContactsState {
    loading: boolean
    error: string
    data: ICompanyContact[]
}

const initialState: ICompanyContactsState = {
    data: [],
    loading: false,
    error: ''
};

export default function companyContacts(state = initialState, action: IActionCompanyContacts): ICompanyContactsState {
    switch (action.type) {
        case GET_COMPANY_CONTACTS_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_COMPANY_CONTACTS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_COMPANY_CONTACTS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
