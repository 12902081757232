import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {addPaymentForm} from "../store/actions/paymentForm";
import {IPaymentAddFormRequest} from "../models/Payment";
import PaymentAdd from "../components/PaymentAdd";
import {IDictionary} from "../models/Dictionary";


type PaymentAddType = {
    addPaymentForm: (id:number, form: IPaymentAddFormRequest) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
    dictionaryCompanies:IDictionary[]
}


class PaymentAddContainer extends Component<PaymentAddType> {

    render() {
        const {changeLoading, changeError, addPaymentForm, cancelHandler, dictionaryCompanies} = this.props;

        return (
            <PaymentAdd
                addPaymentForm={addPaymentForm}
                changeLoading={changeLoading}
                changeError={changeError}
                cancelHandler={cancelHandler}
                dictionaryCompanies={dictionaryCompanies}
            />
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.paymentForm;
    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    addPaymentForm
};


export default connect(mapStateToProps, mapDispatchToProps)(PaymentAddContainer);
