import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import Penalties from "../../containers/Penalties";
import PenaltyNavbar from "../../containers/PenaltyNavbar";


class DirectoryPenaltiesPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Справочник - Штрафы</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Справочник - штрафы', url: '/directories/penalties'}
                ]}/>
                <div className={'mb-2'}>
                    <PenaltyNavbar/>
                </div>
                <Penalties/>
            </Layout>
        );
    }
}

export default DirectoryPenaltiesPage;
