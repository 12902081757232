import React, {PureComponent} from "react";
import {Formik} from "formik";
import * as yup from 'yup'
import {requiredMessage} from '../../utils/form-validation'
import {IAuthRequest} from "../../models/Auth";
import { Redirect } from "react-router-dom";
import {Form, FormFeedback, FormGroup, Input, Label, Button, Spinner, Alert} from "reactstrap";
import NumberFormat from "react-number-format";


const schema = yup.object({
    phone: yup.string().required(requiredMessage()),
    password: yup.string().required(requiredMessage())
});

type LoginType = {
    login: (props:IAuthRequest) => void
    loading:boolean,
    error:string,
    isAuthenticated:boolean
}


class Login extends PureComponent<LoginType> {
    render() {
        const {login, loading, error, isAuthenticated} = this.props;

        if (isAuthenticated) {
            return <Redirect to="/"/>
        }

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    login(values)
                }}
                initialValues={{
                    phone: '',
                    password: ''
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>Телефон <span className={'required'}>*</span></Label>
                            <Input
                                tag={NumberFormat}
                                format={'+7 (###) ###-##-##'}
                                mask={'_' }
                                type="text"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                invalid={touched.phone && !!errors.phone}
                            />
                            <FormFeedback>{errors.phone}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Пароль <span className={'required'}>*</span></Label>
                            <Input
                                type="password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                invalid={touched.password && !!errors.password}
                            />
                            <FormFeedback>{errors.password}</FormFeedback>
                        </FormGroup>
                        <div className={'text-center'}>
                            <Button color={'primary'} type="submit" disabled={loading}>
                                {loading &&
                                (<Spinner


                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />)}
                                Войти
                            </Button>
                        </div>
                        {error &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {error}
                        </Alert>)}
                    </Form>
                )}
            </Formik>
        );
    }
}

export default Login;
