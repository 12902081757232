import {
    GET_DICTIONARY_COMPANIES,
    GET_DICTIONARY_COMPANIES_REQUEST,
    GET_DICTIONARY_COMPANIES_SUCCESS,
    GET_DICTIONARY_COMPANIES_ERROR
} from '../constants/dictionaryCompanies'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryCompanies extends IAction<symbol, any> {

}

export const getDictionaryCompaniesRequest = () => {
    return {
        type: GET_DICTIONARY_COMPANIES_REQUEST
    }
};

export const getDictionaryCompaniesSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_COMPANIES_SUCCESS,
        payload
    }
};

export const getDictionaryCompaniesError = (payload: string) => {
    return {
        type: GET_DICTIONARY_COMPANIES_ERROR,
        payload
    }
};

export const getDictionaryCompanies = (payload?:number) => {
    return {
        type: GET_DICTIONARY_COMPANIES,
        payload
    };
};

