import {GET_SALARY, GET_SALARY_TOTAL, PAY_GROUP_SALARY, PAY_SALARY} from "../constants/salary";
import {
    getSalaryError,
    getSalaryRequest,
    getSalarySuccess,
    getSalaryTotalError,
    getSalaryTotalRequest,
    getSalaryTotalSuccess,
    payGroupSalaryError,
    payGroupSalaryRequest, payGroupSalarySuccess,
    paySalaryError,
    paySalaryRequest,
    paySalarySuccess
} from "../actions/salary";
import {call, put, takeLatest} from 'redux-saga/effects';
import {Pagination} from "../../services/Api";
import {SalaryService} from "../../services/SalaryService";
import {ISalaryFilterForm, ISalaryGroupResponse, ISalaryResponse, ISalarySummaryResponse} from "../../models/Salary";

type getSalaryType = {
    type: typeof GET_SALARY
    payload: {
        page?: number;
        cityId: number;
        form?: ISalaryFilterForm;
        group?: boolean;
    }
}


function* getSalarySaga({payload}: getSalaryType) {

    try {
        yield put(getSalaryRequest());

        const service = new SalaryService();

        const promise = yield call(service.getSalary, payload.cityId, payload.page, payload.form, payload.group);

        const data: ISalaryResponse[] | ISalaryGroupResponse[] = promise.data || [];

        const pagination = new Pagination(promise.headers).getData();

        yield put(getSalarySuccess(data, pagination.total, pagination.by));
    } catch (error) {
        yield put(getSalaryError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetSalary() {
    yield takeLatest(GET_SALARY, getSalarySaga);
}

type getSalaryTotalType = {
    type: typeof GET_SALARY_TOTAL
    payload: {
        cityId: number
        form?: ISalaryFilterForm
    }
}

function* getSalaryTotalSaga({payload}: getSalaryTotalType) {
    try {
        yield put(getSalaryTotalRequest());

        const service = new SalaryService();

        const promise = yield call(service.getSalaryTotal, payload.cityId, payload.form);

        const data: ISalarySummaryResponse = promise.data || [];

        yield put(getSalaryTotalSuccess(data))
    } catch (error) {
        yield put(getSalaryTotalError(error.response.data.message || 'Ошибка'))
    }
}

export function* watchGetSalaryTotal() {
    yield takeLatest(GET_SALARY_TOTAL, getSalaryTotalSaga);
}

type paySalaryType = {
    type: typeof PAY_SALARY
    payload: {
        id: number
    }
}

function* paySalarySaga({payload}: paySalaryType) {
    try {
        yield put(paySalaryRequest());

        const service = new SalaryService();

        const promise = yield call(service.paySalary, payload.id);

        const data: ISalaryResponse = promise.data || [];

        yield put(paySalarySuccess(data));
    } catch (error) {
        yield put(paySalaryError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchPaySalary() {
    yield takeLatest(PAY_SALARY, paySalarySaga);
}

type payGroupSalaryType = {
    type: typeof PAY_GROUP_SALARY;
    payload: {
        ids: number[];
    }
}

function* payGroupSalarySaga({payload}: payGroupSalaryType) {
    try {
        yield put(payGroupSalaryRequest());
        
        const service = new SalaryService();
        
        yield call(service.payGroupSalary, payload.ids);
        
        yield put(payGroupSalarySuccess());
    } catch (error) {
        yield put(payGroupSalaryError(error?.response?.data?.message || 'Ошибка'))
    }
}

export function* watchPayGroupSalary() {
    yield takeLatest(PAY_GROUP_SALARY, payGroupSalarySaga);
}
