import React, {PureComponent} from 'react';
import {Alert, Button, Form, Spinner, CustomInput, FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import {ICityAddFormRequest} from '../../models/City';
import Dropdown from "../UI/Dropdown";
import {timezoneOptions} from "../../utils/timezones";
import {sanitizeForm} from "../../utils/sanitize-form";
import {externalDeliveryServiceOptions} from "../../utils/order";


const schema = yup.object({
    name: yup.string().required(requiredMessage()),
    timezone: yup.number().nullable().required(requiredMessage()),
    status: yup.boolean().required(requiredMessage()),
    deliveryService: yup.string().nullable().required(requiredMessage()),
});

type CityAddType = {
    addCityForm: (form: ICityAddFormRequest) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
}

class CityAdd extends PureComponent<CityAddType> {
    render() {
        const {addCityForm, changeLoading, changeError, cancelHandler} = this.props;

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const name = sanitizeForm(values.name);
                    const timezone = sanitizeForm(values.timezone);
                    const status = sanitizeForm(values.status);
                    const deliveryService = sanitizeForm(values.deliveryService);
                    
                    const form: ICityAddFormRequest = {
                        name,
                        timezone,
                        status,
                        deliveryService,
                    };

                    addCityForm(form)
                }}
                initialValues={{
                    name: '',
                    timezone: null,
                    status: false,
                    deliveryService: null,
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <CustomInput
                                type="switch"
                                id="status"
                                label={values.status ? "Включен" : "Выключен"}
                                onChange={handleChange}
                                name={'status'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Название <span className={'required'}>*</span></Label>
                            <Input
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                invalid={touched.name && !!errors.name}
                            />
                            <FormFeedback>{errors.name}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Таймзона <span className={'required'}>*</span></Label>
                            <Dropdown
                                name={'cityId'}
                                value={values.timezone}
                                options={timezoneOptions}
                                onChange={(value) => setFieldValue('timezone', value)}
                                invalid={touched.timezone && !!errors.timezone}
                            />
                            <FormFeedback>{errors.timezone}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Внешняя доставка <span className={'required'}>*</span></Label>
                            <Dropdown
                                name={'deliveryService'}
                                value={values.deliveryService}
                                options={externalDeliveryServiceOptions}
                                onChange={(value) => setFieldValue('deliveryService', value)}
                                invalid={touched.deliveryService && !!errors.deliveryService}
                            />
                            <FormFeedback>{errors.deliveryService}</FormFeedback>
                        </FormGroup>
                        {changeError &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {changeError}
                        </Alert>)}
                        <div className={'text-right'}>
                            {cancelHandler &&
                            <Button color="light" onClick={cancelHandler} className={'mr-3'}>Отменить</Button>}
                            <Button type="submit" color={'primary'} disabled={changeLoading}>
                                {changeLoading &&
                                (<Spinner
                                    size="sm"
                                />)}
                                Создать
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default CityAdd;
