import {GET_DICTIONARY_ORDER_STATUSES_ERROR, GET_DICTIONARY_ORDER_STATUSES_REQUEST, GET_DICTIONARY_ORDER_STATUSES_SUCCESS} from "../constants/dictionaryOrderStatuses";
import {IDictionary} from "../../models/Dictionary";
import {IActionDictionaryOrderStatuses} from "../actions/dictionaryOrderStatuses";


export interface IDictionaryOrderStatusesState {
    loading: boolean
    error: string
    data: IDictionary[]
}

const initialState: IDictionaryOrderStatusesState = {
    data: [],
    loading: false,
    error: ''
};

export default function dictionaryOrderStatuses(state = initialState, action: IActionDictionaryOrderStatuses): IDictionaryOrderStatusesState {
    switch (action.type) {
        case GET_DICTIONARY_ORDER_STATUSES_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_DICTIONARY_ORDER_STATUSES_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_DICTIONARY_ORDER_STATUSES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
