import {
    ADD_COMPANY_FORM_REQUEST,
    ADD_COMPANY_FORM_SUCCESS,
    ADD_COMPANY_FORM_ERROR,
    EDIT_COMPANY_FORM_REQUEST,
    EDIT_COMPANY_FORM_SUCCESS,
    EDIT_COMPANY_FORM_ERROR,
    GET_COMPANY_FORM_REQUEST,
    GET_COMPANY_FORM_SUCCESS,
    GET_COMPANY_FORM_ERROR,
    DELETE_COMPANY_FORM_ERROR,
    DELETE_COMPANY_FORM_SUCCESS,
    DELETE_COMPANY_FORM_REQUEST, OPEN_COMPANY_FORM, CLOSE_COMPANY_FORM
} from '../constants/companyForm'
import { ICompany } from '../../models/Company';
import { IActionCompanyForm } from '../actions/companyForm';



export interface ICompanyFormState {
    getLoading: boolean
    getError: string
    data: ICompany | null
    changeLoading: boolean
    changeError: string
    mode: 'delete' | null
    modeId: number | null
}


const initialState: ICompanyFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null,
    modeId: null
};

export default function companyForm(state = initialState, action: IActionCompanyForm): ICompanyFormState {
    switch (action.type) {
        case GET_COMPANY_FORM_REQUEST:
            return {
                ...state,
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_COMPANY_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_COMPANY_FORM_ERROR:
            return {
                ...state,
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case ADD_COMPANY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_COMPANY_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_COMPANY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case EDIT_COMPANY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case EDIT_COMPANY_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case EDIT_COMPANY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case DELETE_COMPANY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case DELETE_COMPANY_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case DELETE_COMPANY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_COMPANY_FORM:
            const {mode, modeId = null} = action.payload;
            return {
                ...initialState,
                mode,
                modeId
            };
        case CLOSE_COMPANY_FORM:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
