import {call, put, takeLatest} from 'redux-saga/effects';
import {removeUrlParams} from "../../utils/history";
import {getEmployeesError, getEmployeesRequest, getEmployeesSuccess} from '../actions/employees';
import {Pagination} from "../../services/Api";
import {GET_EMPLOYEES} from "../constants/employees";
import {IEmployee, IEmployeeRequest} from "../../models/Employee";
import {EmployeeService} from "../../services/EmployeeService";



type getEmployeesType = {
    type: typeof GET_EMPLOYEES,
    payload:{
        page?:number
        form?:IEmployeeRequest
    }
}

function* getEmployeesSaga({payload}:getEmployeesType) {
    try {
        yield put(getEmployeesRequest());

        const service = new EmployeeService();

        const promise = yield call(service.getEmployees, payload.page, payload.form);

        const data:IEmployee[] = promise.data || [];
        const pagination = new Pagination(promise.headers).getData();

        yield put(getEmployeesSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
        yield put(getEmployeesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetEmployees() {
    yield takeLatest(GET_EMPLOYEES, getEmployeesSaga);
}
