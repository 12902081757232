import {
    ADD_SCHEDULE_DATE_FORM,
    ADD_SCHEDULE_DATE_FORM_REQUEST,
    ADD_SCHEDULE_DATE_FORM_SUCCESS,
    ADD_SCHEDULE_DATE_FORM_ERROR,
    EDIT_SCHEDULE_DATE_FORM_REQUEST,
    EDIT_SCHEDULE_DATE_FORM_SUCCESS,
    EDIT_SCHEDULE_DATE_FORM_ERROR,
    EDIT_SCHEDULE_DATE_FORM,
    GET_SCHEDULE_DATE_FORM,
    GET_SCHEDULE_DATE_FORM_REQUEST,
    GET_SCHEDULE_DATE_FORM_SUCCESS,
    GET_SCHEDULE_DATE_FORM_ERROR,
    CLOSE_SCHEDULE_DATE_FORM,
    OPEN_SCHEDULE_DATE_FORM,
    COPY_SCHEDULE_DATE_FORM_REQUEST,
    COPY_SCHEDULE_DATE_FORM_SUCCESS,
    COPY_SCHEDULE_DATE_FORM_ERROR, COPY_SCHEDULE_DATE_FORM
} from '../constants/scheduleDateForm'
import {IAction} from "./index";
import {
    IScheduleDate,
    IScheduleDateAddFormRequest,
    IScheduleDateEditFormRequest,
    IScheduleDateCopyFormRequest
} from '../../models/Schedule';


export interface IActionScheduleDateForm extends IAction<symbol, any> {

}

export const addScheduleDateFormRequest = () => {
    return {
        type: ADD_SCHEDULE_DATE_FORM_REQUEST
    }
};

export const addScheduleDateFormSuccess = (payload: IScheduleDate) => {
    return {
        type: ADD_SCHEDULE_DATE_FORM_SUCCESS,
        payload
    }
};

export const addScheduleDateFormError = (payload: string) => {
    return {
        type: ADD_SCHEDULE_DATE_FORM_ERROR,
        payload
    }
};

export const addScheduleDateForm = (id: number, date: string, form: IScheduleDateAddFormRequest[]) => {
    return {
        type: ADD_SCHEDULE_DATE_FORM,
        payload: {
            id,
            date,
            form
        }
    };
};

export const editScheduleDateFormRequest = () => {
    return {
        type: EDIT_SCHEDULE_DATE_FORM_REQUEST
    }
};

export const editScheduleDateFormSuccess = (payload: IScheduleDate) => {
    return {
        type: EDIT_SCHEDULE_DATE_FORM_SUCCESS,
        payload
    }
};

export const editScheduleDateFormError = (payload: string) => {
    return {
        type: EDIT_SCHEDULE_DATE_FORM_ERROR,
        payload
    }
};

export const editScheduleDateForm = (id: number, date: string, form: IScheduleDateEditFormRequest[]) => {
    return {
        type: EDIT_SCHEDULE_DATE_FORM,
        payload: {
            id,
            date,
            form
        }
    };
};

export const getScheduleDateFormRequest = () => {
    return {
        type: GET_SCHEDULE_DATE_FORM_REQUEST
    }
};

export const getScheduleDateFormSuccess = (payload: IScheduleDate) => {
    return {
        type: GET_SCHEDULE_DATE_FORM_SUCCESS,
        payload
    }
};

export const getScheduleDateFormError = (payload: string) => {
    return {
        type: GET_SCHEDULE_DATE_FORM_ERROR,
        payload
    }
};

export const getScheduleDateForm = (id: number, date: string) => {
    return {
        type: GET_SCHEDULE_DATE_FORM,
        payload: {
            id,
            date
        }
    };
};

export const copyScheduleDateFormRequest = () => {
    return {
        type: COPY_SCHEDULE_DATE_FORM_REQUEST
    }
};

export const copyScheduleDateFormSuccess = () => {
    return {
        type: COPY_SCHEDULE_DATE_FORM_SUCCESS
    }
};

export const copyScheduleDateFormError = (payload: string) => {
    return {
        type: COPY_SCHEDULE_DATE_FORM_ERROR,
        payload
    }
};

export const copyScheduleDateForm = (id: number, form: IScheduleDateCopyFormRequest) => {
    return {
        type: COPY_SCHEDULE_DATE_FORM,
        payload: {
            id,
            form
        }
    };
};

export const openScheduleDateForm = (payload: 'add' | 'edit') => {
    return {
        type: OPEN_SCHEDULE_DATE_FORM,
        payload
    };
};

export const closeScheduleDateForm = () => {
    return {
        type: CLOSE_SCHEDULE_DATE_FORM
    };
};
