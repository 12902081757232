import {
    GET_COURIER_STATISTICS,
    GET_COURIER_STATISTICS_REQUEST,
    GET_COURIER_STATISTICS_SUCCESS,
    GET_COURIER_STATISTICS_ERROR
} from '../constants/courierStatistics'
import {IAction} from "./index";
import {ICourierStatistics, ICourierStatisticsFilterRequest} from "../../models/Courier";


export interface IActionCourierStatistics extends IAction<symbol, any> {

}

export const getCourierStatisticsRequest = () => {
    return {
        type: GET_COURIER_STATISTICS_REQUEST
    }
};

export const getCourierStatisticsSuccess = (payload: ICourierStatistics) => {
    return {
        type: GET_COURIER_STATISTICS_SUCCESS,
        payload
    }
};

export const getCourierStatisticsError = (payload: string) => {
    return {
        type: GET_COURIER_STATISTICS_ERROR,
        payload
    }
};

export const getCourierStatistics = (id: number, form: ICourierStatisticsFilterRequest) => {
    return {
        type: GET_COURIER_STATISTICS,
        payload: {
            id,
            form
        }
    };
};
