import React, {Component} from "react";
import Layout from "../../containers/Layout";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import RatioRateContainer from "../../containers/RatioRate";

class RatioRatePage extends Component<any, any>{
    render() {
        return (
            <Layout>
                <Title>Настройки - Повышенные ставки</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Настройки - Повышенные ставки', url: '/settings/ratio-rate'}
                ]}/>
                <RatioRateContainer/>
            </Layout>
        );
    }
}

export default RatioRatePage;