import {
    EDIT_POINT_ZONE_FORM_REQUEST,
    EDIT_POINT_ZONE_FORM_SUCCESS,
    EDIT_POINT_ZONE_FORM_ERROR,
    GET_POINT_ZONE_FORM_REQUEST,
    GET_POINT_ZONE_FORM_SUCCESS,
    GET_POINT_ZONE_FORM_ERROR,
    GET_JSON_POINT_ZONE_FORM,
    ADD_POINT_ZONE_FORM_ERROR,
    ADD_POINT_ZONE_FORM_SUCCESS,
    ADD_POINT_ZONE_FORM_REQUEST,
    SET_JSON_POINT_ZONE_FORM,
    DELETE_POINT_ZONE_FORM_REQUEST,
    DELETE_POINT_ZONE_FORM_SUCCESS,
    DELETE_POINT_ZONE_FORM_ERROR, DELETE_JSON_POINT_ZONE_FORM, OPEN_POINT_ZONE_FORM, CLOSE_POINT_ZONE_FORM
} from '../constants/pointZoneForm'
import {IActionPointZoneForm} from "../actions/pointZoneForm";
import {IPointZone} from "../../models/Point";



export interface IPointZoneFormState {
    getLoading: boolean
    getError: string
    data: IPointZone | null
    changeLoading: boolean
    changeError: string
    mode: 'add' | 'edit' | 'delete' | null
    modeId: number | null
    jsonData: { name: string, polygon: number[][] }[]
    jsonActiveData: { name: string, polygon: number[][] } | null
}

const initialState: IPointZoneFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null,
    modeId: null,
    jsonData: [],
    jsonActiveData: null
};

export default function pointZoneForm(state = initialState, action: IActionPointZoneForm): IPointZoneFormState {
    switch (action.type) {
        case GET_POINT_ZONE_FORM_REQUEST:
            return {
                ...state,
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_POINT_ZONE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_POINT_ZONE_FORM_ERROR:
            return {
                ...state,
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case ADD_POINT_ZONE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_POINT_ZONE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_POINT_ZONE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case EDIT_POINT_ZONE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case EDIT_POINT_ZONE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case EDIT_POINT_ZONE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case DELETE_POINT_ZONE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case DELETE_POINT_ZONE_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case DELETE_POINT_ZONE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case GET_JSON_POINT_ZONE_FORM:
            return {
                ...state,
                jsonData: action.payload
            };
        case SET_JSON_POINT_ZONE_FORM:
            return {
                ...state,
                jsonActiveData: action.payload
            };
        case DELETE_JSON_POINT_ZONE_FORM:
            return {
                ...state,
                jsonData: !!state.jsonData.length ? state.jsonData.filter((o) => {
                    return state.jsonActiveData !== null && o.name !== state.jsonActiveData.name;
                }) : [],
                jsonActiveData: null
            };
        case OPEN_POINT_ZONE_FORM:
            const {mode, modeId = null} = action.payload;
            return {
                ...initialState,
                jsonData:state.jsonData,
                jsonActiveData:state.jsonActiveData,
                mode,
                modeId
            };
        case CLOSE_POINT_ZONE_FORM:
            return {
                ...initialState,
                jsonData:state.jsonData,
                jsonActiveData:state.jsonActiveData,
            };
        default:
            return state;
    }
}
