import yandexLogo from "../assets/yandex.svg";
import inDriveLogo from "../assets/indrive.svg";

export const ORDER_PAYMENT_CASH = 1;
export const ORDER_PAYMENT_POS_TERMINAL = 2;
export const ORDER_PAYMENT_PAID = 3;

type OrderPaymentsType = {
    [key: string]: string;
};

export const OrderPayments: OrderPaymentsType = {
    1: 'Наличными',
    2: 'POS терминал',
    3: 'Оплаченный заказ'
};

export const OrderPaymentOptions = [
    {label: 'Наличными', value: 1},
    {label: 'POS терминал', value: 2},
    {label: 'Оплаченный заказ', value: 3}
];


export const ORDER_STATUS_NEW = 10;

const _timeForReadyOptions = [];
for (let i = 1; i < 25; i++) {
    _timeForReadyOptions.push({label: `${i * 5} минут`, value: i * 5})
}

export const timeForReadyOptions = _timeForReadyOptions;

export enum ExternalDeliveryServices {
    Yandex = 'YANDEX',
    InDrive = 'INDRIVE',
}

export const externalDeliveryServiceNames: Record<ExternalDeliveryServices, string> = {
    [ExternalDeliveryServices.Yandex]: 'Yandex',
    [ExternalDeliveryServices.InDrive]: 'InDrive',
}

export const externalDeliveryServiceLogos: Record<ExternalDeliveryServices, string> = {
    [ExternalDeliveryServices.Yandex]: yandexLogo,
    [ExternalDeliveryServices.InDrive]: inDriveLogo,
}

export const externalDeliveryServiceOptions =
  Object.entries(externalDeliveryServiceNames)
    .map(([value, label]) => ({value, label}))
