import {
    GET_COURIER,
    GET_COURIER_REQUEST,
    GET_COURIER_SUCCESS,
    GET_COURIER_ERROR,
    ENABLE_STATUS_COURIER,
    DISABLE_STATUS_COURIER
} from '../constants/courier'
import {IAction} from "./index";
import {ICourier} from "../../models/Courier";


export interface IActionCourier extends IAction<symbol, any> {

}

export const getCourierRequest = () => {
    return {
        type: GET_COURIER_REQUEST
    }
};

export const getCourierSuccess = (payload: ICourier) => {
    return {
        type: GET_COURIER_SUCCESS,
        payload
    }
};

export const getCourierError = (payload: string) => {
    return {
        type: GET_COURIER_ERROR,
        payload
    }
};

export const getCourier = (id: number, expand?: string) => {
    return {
        type: GET_COURIER,
        payload: {
            id,
            expand
        }
    };
};

export const enableStatusCourier = (payload: number) => {
    return {
        type: ENABLE_STATUS_COURIER,
        payload
    };
};

export const disableStatusCourier = (payload: number) => {
    return {
        type: DISABLE_STATUS_COURIER,
        payload
    };
};
