import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getCourierStatisticsError,
    getCourierStatisticsRequest,
    getCourierStatisticsSuccess
} from "../actions/courierStatistics";
import {ICourierStatistics, ICourierStatisticsFilterRequest} from "../../models/Courier";
import {CourierService} from "../../services/CourierService";
import {GET_COURIER_STATISTICS} from "../constants/courierStatistics";


type getCourierStatisticsType = {
    type: typeof GET_COURIER_STATISTICS,
    payload: {
        id:number
        form:ICourierStatisticsFilterRequest
    }
};

function* getCourierStatisticsSaga({payload}: getCourierStatisticsType) {
    try {
        yield put(getCourierStatisticsRequest());

        const service = new CourierService();

        const promise = yield call(service.getCourierStatistics, payload.id, payload.form);

        const response: ICourierStatistics = promise.data;

        yield put(getCourierStatisticsSuccess(response));

    } catch (error) {
        yield put(getCourierStatisticsError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCourierStatistics() {
    yield takeLatest(GET_COURIER_STATISTICS, getCourierStatisticsSaga);
}

