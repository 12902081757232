export const accessPointEdit = 'point_update';
export const accessPointCreate = 'point_create';
export const accessPointDelete = 'point_delete';
export const accessPointShow = 'point_view';

export const accessDirectoryCities = 'city_manage';
export const accessDirectoryRoles = 'role_manage';
export const accessDirectoryPenalties = 'penalty_manage';

export const accessCompanyEdit = 'company_update';
export const accessCompanyCreate = 'company_create';
export const accessCompanyDelete = 'company_delete';
export const accessCompanyShow = 'company_view';
export const accessCompanyContactEdit = 'company_contact_update';
export const accessCompanyContactCreate = 'company_contact_create';
export const accessCompanyContactDelete = 'company_contact_delete';
export const accessCompanyContactShow = 'company_contact_view';
export const accessCompanyBranchEdit = 'company_branch_update';
export const accessCompanyBranchCreate = 'company_branch_create';
export const accessCompanyBranchDelete = 'company_branch_delete';
export const accessCompanyBranchShow = 'company_branch_view';

export const accessEmployeeEdit = 'employee_update';
export const accessEmployeeCreate = 'employee_create';
export const accessEmployeeDelete = 'employee_delete';
export const accessEmployeeShow = 'employee_view';
export const accessEmployeeChangePassword = 'employee_change_password';

export const accessScheduleCreate = 'schedule_create';
export const accessSchedulePublish = 'schedule_publish';
export const accessScheduleShow = 'schedule_view';

export const accessOrderShow = 'order_view';
export const accessOrderCreate = 'order_create';
export const accessOrderEdit = 'order_update';
export const accessOrderChangeStatus = 'order_change_status';
export const accessOrderChangeCoordinate = 'order_change_coordinate';
export const accessOrderChangeCourier = 'order_change_courier'
export const accessOrderCheckUpload = 'order_return_check_upload';
export const accessOrderCheckDelete = 'order_return_check_delete';

export const accessCourierShow = 'courier_view';
export const accessCourierLocationEdit = 'courier_location_update';
export const accessCourierLocationShow = 'courier_location_view';
export const accessCourierPenaltyShow = 'courier_penalty';
export const accessCourierPenaltyManage = 'courier_penalty_manage';
export const accessCourierSlotManage = 'courier_slot_manage';

export const accessNotificationManage = 'notification_manage';

export const accessReportCompanyManage = 'report_partner_view';
export const accessReportCourierManage = 'report_courier_view';
export const accessReportEmenuView = 'report_emenu_view';

export const accessPaymentShow = 'pay_view';
export const accessPaymentCreate = 'pay_create';
export const accessPaymentEdit = 'pay_update';
export const accessPaymentDelete = 'pay_delete';

export const courierRateManage = 'courier_rate_manage';

export const accessSalaryManage = 'salary_manage';
