import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import {changeStatusOrders, getOrders, toggleActiveIdOrders} from "../store/actions/orders";
import _ from "lodash";
import {ordersFilterReSelector} from "../store/reselectors/orders";
import Orders from "../components/Orders";
import Empty from "../components/UI/Empty";
import {getDictionaryOrderStatuses} from "../store/actions/dictionaryOrderStatuses";
import Pagination from "../components/UI/Pagination";
import queryString from "query-string";
import {reportCouriersPageReSelector} from "../store/reselectors/reportCouriers";
import {accessOrderCreate} from "../utils/user-accesses";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import Tile from "../components/UI/Tile";

type OrdersType = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type OrderTile = {
    id: number
    color: string
    label: string
    value: number
}

type MapStateType = {
    orderTiles: OrderTile[]
}

class OrdersContainer extends Component<OrdersType & RouteComponentProps> {

    state: MapStateType = {
        orderTiles: []
    }

    componentDidMount() {
        const {getOrders, page, filter, getDictionaryOrderStatuses} = this.props;
        getDictionaryOrderStatuses();
        getOrders(page, !_.isEmpty({...filter}) ? {...filter,} : undefined);
    }

    componentDidUpdate(prevProps: Readonly<OrdersType & RouteComponentProps>) {
        const {page, filter, getOrders, data, dictionaryOrderStatuses} = this.props;

        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter)) {
            getOrders(page, !_.isEmpty({...filter}) ? {...filter,} : undefined)
        }

        if (
            (prevProps.data !== data || !_.isEqual(prevProps.data, data)) ||
            (prevProps.dictionaryOrderStatuses !== dictionaryOrderStatuses || !_.isEqual(prevProps.dictionaryOrderStatuses, dictionaryOrderStatuses)
            )) {
            this.getStatusTilesHandler();
        }
    }

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    fetchHandler = () => {
        const {page, getOrders, filter} = this.props;
        getOrders(page, !_.isEmpty(filter) ? filter : undefined)
    };

    getStatusTilesHandler = () => {
        const {data, dictionaryOrderStatuses} = this.props;

        const orderTiles: OrderTile[] = [];

        dictionaryOrderStatuses.forEach((dictionaryOrderStatus) => {
            const {key, color, value} = dictionaryOrderStatus;

            orderTiles.push({
                id: key,
                color,
                label: value.replace("Курьер", ""),
                value: 0
            })
        });

        if (data.length > 0) {
            data.forEach((data) => {
                const orderTile = orderTiles.find((x: OrderTile) => x.id === data.status);
                if (orderTile) {
                    orderTile.value = orderTile.value + 1;
                }
            })
        }

        this.setState({
            orderTiles: orderTiles.filter((x: OrderTile) => x.value > 0)
        });
    }

    render() {
        const {
            data,
            page,
            total,
            by,
            loading,
            error,
            dictionaryOrderStatuses,
            dictionaryOrderStatusesLoading,
            dictionaryOrderStatusesError,
            getDictionaryOrderStatuses,
            changeStatusOrders,
            toggleActiveIdOrders,
            activeId,
            accesses,
            allowedCities,
        } = this.props;

        const {orderTiles} = this.state;

        if (loading || dictionaryOrderStatusesLoading) {
            return <Loader/>
        }

        if (dictionaryOrderStatusesError) {
            return <Error error={dictionaryOrderStatusesError} refresh={getDictionaryOrderStatuses}/>
        }

        if (error) {
            return <Error error={error} refresh={this.fetchHandler}/>
        }

        if (!!data.length && !!dictionaryOrderStatuses.length) {
            return (
                <div className={'mb-3'}>
                    {!!orderTiles.length &&
                    <div className={'d-flex mb-3'}>
                        {orderTiles.map((orderTile) => (
                            <Tile label={orderTile.label} value={orderTile.value} color={orderTile.color} key={orderTile.id} />
                        ))}
                    </div>
                    }
                    {Array.isArray(accesses) && accesses.includes(accessOrderCreate) &&
                    <Link to={'/orders/add'}>
                        <Button
                            color={'primary'}
                            className={'mb-2'}
                        >
                            Добавить заказ
                        </Button>
                    </Link>}
                    <Orders
                        accesses={accesses}
                        changeStatus={changeStatusOrders}
                        data={data}
                        activeId={activeId}
                        toggleActiveId={toggleActiveIdOrders}
                        dictionaryOrderStatuses={dictionaryOrderStatuses}
                        allowedCities={allowedCities}
                    />
                    <br/>
                    <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>
                </div>
            )
        }
        return (
            <div className={'mb-3'}>
                {Array.isArray(accesses) && accesses.includes(accessOrderCreate) &&
                    <Link to={'/orders/add'}>
                        <Button
                            color={'primary'}
                            className={'mb-2'}
                        >
                            Добавить заказ
                        </Button>
                    </Link>}
                <Empty>
                    <h3>Список заказов пуст</h3>
                    <p className={'mb-0'}>Измените фильтр или добавьте новый заказ</p>
                </Empty>
            </div>
        )
    }
}


const mapStateToProps = ({orders, dictionaryOrderStatuses, auth}: AppState, props: RouteComponentProps) => {
    const {data, total, by, loading, error, activeId} = orders;
    return {
        page: reportCouriersPageReSelector(props),
        data,
        total,
        by,
        loading,
        error,
        activeId,
        filter: ordersFilterReSelector(props),
        dictionaryOrderStatuses: dictionaryOrderStatuses.data,
        dictionaryOrderStatusesLoading: dictionaryOrderStatuses.loading,
        dictionaryOrderStatusesError: dictionaryOrderStatuses.error,
        accesses: auth.user !== null ? auth.user.accesses : undefined,
        allowedCities: auth.user?.allowedCities || [],
    }
};

const mapDispatchToProps = {
    getOrders,
    getDictionaryOrderStatuses,
    changeStatusOrders,
    toggleActiveIdOrders
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrdersContainer);
