import {call, put, takeLatest} from 'redux-saga/effects';
import {GET_LOCATION} from "../constants/location";
import {getLocationError, getLocationRequest, getLocationSuccess} from "../actions/location";
import {OrderService} from "../../services/OrderService";
import {ILocation} from "../../models/Order";

type getLocationType = {
  type: typeof GET_LOCATION,
  payload: {
    id: string;
  }
}

function* getLocationSaga({payload}: getLocationType) {
  try {
    yield put(getLocationRequest());
    
    const service = new OrderService();
    
    const promise = yield call(service.getOrderLocation, payload.id);
    const response: ILocation = promise.data;
    
    yield put(getLocationSuccess(response));
  } catch (error) {
    yield put(getLocationError(error?.payload?.data?.message || "Ошибка"));
  }
}

export function* watchGetLocation() {
  yield takeLatest(GET_LOCATION, getLocationSaga);
}
