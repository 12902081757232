import {call, put, takeLatest} from 'redux-saga/effects';
import {GET_POINTS} from "../constants/points";
import {PointService} from '../../services/PointService';
import {getPointsError, getPointsRequest, getPointsSuccess} from "../actions/points";
import {IPoint} from "../../models/Point";


type getPointsType = {
    type: typeof GET_POINTS,
    payload?: number
};

function* getPointsSaga({payload}:getPointsType) {
    try {
        yield put(getPointsRequest());

        const service = new PointService();

        const promise = yield call(service.getPoints, payload);

        const response: IPoint[] = promise.data || [];

        yield put(getPointsSuccess(response));

    } catch (error) {
        yield put(getPointsError(error.response.data.message || 'Ошибка'));
    }
}



export function* watchGetPoints() {
    yield takeLatest(GET_POINTS, getPointsSaga);
}
