import {put, call, takeLatest} from "redux-saga/effects";
import {CourierService} from "../../services/CourierService";
import {CHANGE_COURIER} from "../constants/courierChange";
import {changeCourierError, changeCourierRequest, changeCourierSuccess} from "../actions/courierChange";

type changeCourierSagaType = {
    type: typeof CHANGE_COURIER
    payload: {
        courierId: string
        id: number
        reason: string
    }
}

function* changeCourierSaga({payload}: changeCourierSagaType) {
    try {
        yield put(changeCourierRequest())

        const service = new CourierService()

        yield call(service.changeCourier, payload.id, payload.courierId, payload.reason)

        yield put(changeCourierSuccess())

    } catch (error) {
        yield put(changeCourierError(error))
    }
}

export function* watchChangeCourier() {
    yield takeLatest(CHANGE_COURIER, changeCourierSaga)
}