import React, {PureComponent} from 'react';
import Table from '../UI/Table';
import Dropdown from "../UI/Dropdown";
import {Button, Col, Row} from 'reactstrap';
import {IPointZone} from '../../models/Point';


type PointZonesSettingsListType = {
    jsonData: { name: string, polygon: number[][] }[]
    data?: IPointZone[]
    editHandler: (id: number, form: { name: string, polygon: number[][] }) => void
    addHandler: (form: { name: string, polygon: number[][] }) => void
}

class PointZonesSettingsList extends PureComponent<PointZonesSettingsListType> {
    render() {
        const {data, jsonData, editHandler, addHandler} = this.props;

        return (
            <Table>
                <tbody>
                <tr>
                    <th>Название зоны из Яндекс Карт</th>
                    <th>Зона доставки</th>
                </tr>
                {jsonData.map((o: { name: string, polygon: number[][] }) => (
                        <PointZonesSettingsListItem
                            key={o.name}
                            data={data}
                            jsonData={o}
                            editHandler={editHandler}
                            addHandler={addHandler}
                        />
                    )
                )}
                </tbody>
            </Table>
        )
    }
}

type PointZonesSettingsListItemType = {
    jsonData: { name: string, polygon: number[][] }
    data?: IPointZone[]
    editHandler: (id: number, form: { name: string, polygon: number[][] }) => void
    addHandler: (form: { name: string, polygon: number[][] }) => void
}

class PointZonesSettingsListItem extends PureComponent<PointZonesSettingsListItemType> {
    state = {
        id: null
    };

    render() {
        const {data, jsonData: {name, polygon}, editHandler, addHandler} = this.props;
        const {id} = this.state;
        const pointZonesOptions = Array.isArray(data) ? data.map(({id, name}) => ({
            label: name,
            value: id
        })) : [];

        return (
            <tr>
                <td>
                    {name}
                </td>
                <td>
                    <Row  form className={'align-items-center'}>
                        <Col>
                            <Dropdown
                                name={`zone-${name}`}
                                value={id}
                                options={[{label: 'Создать', value: 0}, ...pointZonesOptions]}
                                placeholder={'Выберите из списка'}
                                onChange={(id) => this.setState({id})}
                            />
                        </Col>
                        <Col md="auto">
                            <Button color={'primary'} onClick={() => id === 0 ? addHandler({name, polygon}) : editHandler(id!, {
                                name, polygon
                            })} disabled={id === null}>Применить</Button>
                        </Col>
                    </Row>
                </td>
            </tr>
        );
    }
}

export default PointZonesSettingsList;
