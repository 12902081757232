import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {
    closeRoleForm,
    openRoleForm,
} from "../store/actions/roleForm";
import RoleAdd from "./RoleAdd";


type RoleNavbarType = {
    closeRoleForm: () => void
    openRoleForm: (mode: 'add') => void
    mode: 'add' | 'edit' | 'delete' | null
}


class RoleNavbarContainer extends Component<RoleNavbarType> {

    render() {
        const {
            closeRoleForm,
            mode,
            openRoleForm
        } = this.props;

        return (
            <div className={'mb-2'}>
                <Button
                    color={'primary'}
                    onClick={() => openRoleForm('add')}>
                    Добавить роль
                </Button>

                <Modal isOpen={mode === 'add'} toggle={closeRoleForm}>
                    <ModalHeader toggle={closeRoleForm}>
                        Новая роль
                    </ModalHeader>
                    <ModalBody>
                        <RoleAdd cancelHandler={closeRoleForm}/>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = (state: AppState) => {
    const {mode} = state.roleForm;

    return {
        mode
    }
};

const mapDispatchToProps = {
    closeRoleForm,
    openRoleForm
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleNavbarContainer);
