import React, {PureComponent} from 'react';


class Title extends PureComponent {
    render() {
        const {children} = this.props;
        return (
            <div className={'mb-2'}>
                <h2 className={'m-b-8'}>{children}</h2>
            </div>
        );
    }
}

export default Title;
