import React, {PureComponent} from 'react';
import {Alert, Button, CustomInput, Form, FormGroup, Input, Label, Spinner, FormFeedback} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {minLengthMessage, requiredMessage} from "../../utils/form-validation";
import {IPointZoneAddFormRequest} from '../../models/Point';
import Dropdown from "../UI/Dropdown";
import NumberFormat from "react-number-format";
import {sanitizeForm} from "../../utils/sanitize-form";
import {POINT_ZONE_TYPE_AUTO, pointZoneTypeOptions} from "../../utils/point-zones";

const schema = yup.object({
    name: yup.string().required(requiredMessage()),
    typeId: yup.number().nullable().required(requiredMessage()),
    description: yup.string(),
    status: yup.boolean().required(requiredMessage()),
    deliveryTime: yup.number().min(5, minLengthMessage(5)).required(requiredMessage()),
    deliveryPrice: yup.number().when('typeId', {
        is: (typeId) => typeId === POINT_ZONE_TYPE_AUTO,
        then: yup.number().min(1, minLengthMessage(5)).required(requiredMessage())
    }),
});

type PointAddZoneType = {
    addPointZoneForm: (form: IPointZoneAddFormRequest) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
    polygon:number[][]
}


class PointZoneAdd extends PureComponent<PointAddZoneType> {
    render() {
        const {polygon, addPointZoneForm, changeLoading, changeError, cancelHandler} = this.props;

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const name = sanitizeForm(values.name);
                    const description = sanitizeForm(values.description);
                    const typeId = sanitizeForm(values.typeId);
                    const status = sanitizeForm(values.status);
                    const deliveryTime = sanitizeForm(values.deliveryTime);
                    const deliveryPrice = sanitizeForm(values.deliveryPrice);
                    const allowExternalCourier = sanitizeForm(values.allowExternalCourier);
                    const courierBonus = sanitizeForm(values.courierBonus);
                  
                    const form = {
                        name,
                        description,
                        typeId,
                        status,
                        deliveryTime,
                        deliveryPrice,
                        polygon,
                        allowExternalCourier,
                        courierBonus,
                    };

                    addPointZoneForm(form);
                }}
                initialValues={{
                    name: '',
                    description: '',
                    status: false,
                    typeId: 2,
                    deliveryTime: 0,
                    deliveryPrice: 0,
                    polygon: polygon,
                    allowExternalCourier: false,
                    courierBonus: 0,
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      touched,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <CustomInput
                                type="switch"
                                id={'status'}
                                label={values.status ? "Включен" : "Выключен"}
                                onChange={handleChange}
                                name={'status'}
                                checked={values.status}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Название <span className={'required'}>*</span></Label>
                            <Input
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                invalid={touched.name && !!errors.name}
                            />
                            <FormFeedback>{errors.name}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Тип зоны <span className={'required'}>*</span></Label>
                            <Dropdown
                                name={'typeId'}
                                value={values.typeId}
                                options={pointZoneTypeOptions}
                                onChange={(value) => setFieldValue('typeId', value)}
                                invalid={touched.typeId && !!errors.typeId}
                            />
                            <FormFeedback>{errors.typeId}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Максимальное время доставки <span className={'required'}>*</span></Label>
                            <NumberFormat
                                name="deliveryTime"
                                onValueChange={({floatValue}) => setFieldValue('deliveryTime', floatValue)}
                                value={values.deliveryTime}
                                allowNegative={false}
                                decimalSeparator={false}
                                invalid={touched.deliveryTime && !!errors.deliveryTime}
                                customInput={Input}
                            />
                            <FormFeedback>{errors.deliveryTime}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Сумма доставки <span className={'required'}>*</span></Label>
                            <NumberFormat
                                name="deliveryPrice"
                                onValueChange={({floatValue}) => setFieldValue('deliveryPrice', floatValue)}
                                value={values.deliveryPrice}
                                allowNegative={false}
                                decimalSeparator={false}
                                invalid={touched.deliveryPrice && !!errors.deliveryPrice}
                                customInput={Input}
                            />
                            <FormFeedback>{errors.deliveryPrice}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Надбавка курьеру</Label>
                            <NumberFormat
                                name="courierBonus"
                                onValueChange={({floatValue}) => setFieldValue('courierBonus', floatValue)}
                                value={values.courierBonus}
                                allowNegative={false}
                                decimalSeparator={false}
                                invalid={touched.courierBonus && !!errors.courierBonus}
                                customInput={Input}
                            />
                            <FormFeedback>{errors.courierBonus}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <CustomInput
                            type="switch"
                            id={'allowExternalCourier'}
                            label={"Разрешить передачу заказов внешним курьерам"}
                            onChange={handleChange}
                            name={'allowExternalCourier'}
                            checked={values.allowExternalCourier}
                          />
                        </FormGroup>
                        <FormGroup>
                            <Label>Описание</Label>
                            <Input
                                type="textarea"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                invalid={touched.description && !!errors.description}
                            />
                            <FormFeedback>{errors.description}</FormFeedback>
                        </FormGroup>
                        {changeError &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {changeError}
                        </Alert>)}
                        <div className="text-right">
                            {cancelHandler &&
                            <Button color="light" className={'mr-3'} onClick={cancelHandler}>Отменить</Button>}
                            <Button type="submit" color={'success'} disabled={changeLoading}>
                                {changeLoading && <Spinner size="sm"/>}
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default PointZoneAdd;
