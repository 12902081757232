import {
    GET_SCHEDULES,
    GET_SCHEDULES_REQUEST,
    GET_SCHEDULES_SUCCESS,
    GET_SCHEDULES_ERROR
} from '../constants/schedules'
import {IAction} from "./index";
import {ISchedule} from "../../models/Schedule";


export interface IActionSchedules extends IAction<symbol, any> {

}

export const getSchedulesRequest = () => {
    return {
        type: GET_SCHEDULES_REQUEST
    }
};

export const getSchedulesSuccess = (data: ISchedule[], total: number, by: number) => {
    return {
        type: GET_SCHEDULES_SUCCESS,
        payload: {
            data,
            total,
            by
        }
    }
};

export const getSchedulesError = (payload: string) => {
    return {
        type: GET_SCHEDULES_ERROR,
        payload
    }
};

export const getSchedules = (page?: number, cityId?: number) => {
    return {
        type: GET_SCHEDULES,
        payload: {
            page,
            cityId
        }
    };
};
