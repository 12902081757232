import React, {PureComponent} from 'react';
import styles from './Header.module.scss';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Icon from "../UI/Icon";
import classNames from 'classnames';
import {Link} from "react-router-dom";
import CouriersLocations from "../../containers/CouriersLocations";
import {IAuth} from "../../models/Auth";
import {accessCourierLocationShow, accessNotificationManage, accessOrderChangeStatus} from "../../utils/user-accesses";


type HeaderType = {
    count: number,
    user: IAuth | null
}

class Header extends PureComponent<HeaderType> {
    state = {
        isDialog: false
    };

    openDialog = () => {
        this.setState({isDialog: true})
    };

    closeDialog = () => {
        this.setState({isDialog: false})
    };

    render() {
        const {isDialog} = this.state;
        const {count, user} = this.props;
        return (
            <header>
                <div className="row justify-content-end">
                    <div className="col-auto">
                        {user && Array.isArray(user.accesses) && user.accesses.includes(accessCourierLocationShow) &&
                            <Button color={'light'} className={'mr-2'} onClick={() => this.openDialog()}>
                                <Icon name={'map'}/>
                            </Button>
                        }
                        {user && Array.isArray(user.accesses) && user.accesses.includes(accessNotificationManage) &&
                            <Link to={'/notifications'}>
                                <Button color={'light'} className={classNames(styles.notifications, 'pl-2 pr-2')}>
                                    <Icon name={'notifications'}/>
                                    {count > 0 && <span className={styles['notifications__count']}>{count}</span>}
                                </Button>
                            </Link>
                        }
                    </div>
                </div>

                <Modal isOpen={isDialog} size={'xl'} style={{maxWidth: '1600px', width: '80%'}}>
                    <ModalBody className={'p-0'}>
                        <CouriersLocations
                            cancelHandler={this.closeDialog}
                        />
                    </ModalBody>
                </Modal>
            </header>
        );
    }
}

export default Header;
