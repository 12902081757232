import {
    GET_DICTIONARY_REPORT_COMPANY_PERIOD_ERROR,
    GET_DICTIONARY_REPORT_COMPANY_PERIOD_REQUEST,
    GET_DICTIONARY_REPORT_COMPANY_PERIOD_SUCCESS
} from "../constants/dictionaryReportCompanyPeriod";
import {IDictionary} from "../../models/Dictionary";
import {IActionDictionaryReportCompanyPeriod} from "../actions/dictionaryReportCompanyPeriod";


export interface IDictionaryReportCompanyPeriodState {
    loading: boolean
    error: string
    data: IDictionary[]
}

const initialState: IDictionaryReportCompanyPeriodState = {
    data: [],
    loading: false,
    error: ''
};

export default function dictionaryReportCompanyPeriod(state = initialState, action: IActionDictionaryReportCompanyPeriod): IDictionaryReportCompanyPeriodState {
    switch (action.type) {
        case GET_DICTIONARY_REPORT_COMPANY_PERIOD_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_DICTIONARY_REPORT_COMPANY_PERIOD_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_DICTIONARY_REPORT_COMPANY_PERIOD_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
