import React, {Component} from "react";
import {accessCourierSlotManage} from "../utils/user-accesses";
import {Redirect} from "react-router-dom";
import {AppState} from "../store/reducers";
import {connect} from "react-redux";
import {Card} from "reactstrap";
import CouriersShifts from "./CouriersShifts";
import CouriersShiftsFilter from "./CouriersShiftsFilter";

type CouriersShiftsOutputType = {
    accesses?: string[]
}

class CouriersShiftsOutputContainer extends Component<CouriersShiftsOutputType> {
    render() {
        const {accesses} = this.props;

        if (!(Array.isArray(accesses) && accesses.includes(accessCourierSlotManage))) {
            return <Redirect to={'/profile'}/>
        }

        return (
            <>
                <Card body className={"mb-3"}>
                    <h4 className={"mb-3"}>Фильтр</h4>
                    <CouriersShiftsFilter/>
                </Card>
                <CouriersShifts/>
            </>
        )
    }
}

const mapStateToProps = ({auth}: AppState) => {
    return {
        accesses: auth.user !== null ? auth.user.accesses : undefined
    }
};

export default connect(mapStateToProps, null)(CouriersShiftsOutputContainer);