import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getCourierPenaltiesError,
    getCourierPenaltiesRequest,
    getCourierPenaltiesSuccess
} from "../actions/courierPenalties";
import {ICourierPenalty, ICourierPenaltiesFilterRequest} from "../../models/Courier";
import {CourierService} from "../../services/CourierService";
import {GET_COURIER_PENALTIES} from "../constants/courierPenalties";



type getCourierPenaltiesType = {
    type: typeof GET_COURIER_PENALTIES,
    payload: {
        id:number
        form:ICourierPenaltiesFilterRequest
    }
};

function* getCourierPenaltiesSaga({payload}: getCourierPenaltiesType) {
    try {
        yield put(getCourierPenaltiesRequest());

        const service = new CourierService();

        const promise = yield call(service.getCourierPenalties, payload.id, payload.form);

        const response: ICourierPenalty[] = promise.data;

        yield put(getCourierPenaltiesSuccess(response, payload.form));

    } catch (error) {
        yield put(getCourierPenaltiesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCourierPenalties() {
    yield takeLatest(GET_COURIER_PENALTIES, getCourierPenaltiesSaga);
}

