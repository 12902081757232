import * as types from "../constants/orderReturnCheck";

export const addOrderReturnCheckRequest = () => {
  return {
    type: types.ADD_ORDER_RETURN_CHECK_REQUEST,
  }
}

export const addOrderReturnCheckSuccess = () => {
  return {
    type: types.ADD_ORDER_RETURN_CHECK_SUCCESS,
  }
}

export const addOrderReturnCheckError = (payload: string) => {
  return {
    type: types.ADD_ORDER_RETURN_CHECK_ERROR,
    payload,
  }
}

export const addOrderReturnCheck = (id: string, data: FormData) => {
  return {
    type: types.ADD_ORDER_RETURN_CHECK,
    payload: {
      id,
      data,
    }
  }
}

export const deleteOrderReturnCheckRequest = () => {
  return {
    type: types.DELETE_ORDER_RETURN_CHECK_REQUEST,
  }
}

export const deleteOrderReturnCheckSuccess = () => {
  return {
    type: types.DELETE_ORDER_RETURN_CHECK_SUCCESS,
  }
}

export const deleteOrderReturnCheckError = (payload: string) => {
  return {
    type: types.DELETE_ORDER_RETURN_CHECK_ERROR,
    payload
  }
}

export const deleteOrderReturnCheck = (id: string) => {
  return {
    type: types.DELETE_ORDER_RETURN_CHECK,
    payload: {
      id,
    }
  }
}
