import {createSelector} from "reselect";
import queryString from 'query-string';

const courierRateUrlSelector = (props: any) => {
    return props.location.search
};

export const courierRateCityIdReSelector = createSelector(
    [courierRateUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.cityId ? +getParams.cityId : undefined
    }
);
