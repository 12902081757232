import {
    EDIT_POINT_ZONE_FORM_REQUEST,
    EDIT_POINT_ZONE_FORM_SUCCESS,
    EDIT_POINT_ZONE_FORM_ERROR,
    EDIT_POINT_ZONE_FORM,
    GET_POINT_ZONE_FORM,
    GET_POINT_ZONE_FORM_REQUEST,
    GET_POINT_ZONE_FORM_SUCCESS,
    GET_POINT_ZONE_FORM_ERROR,
    DELETE_POINT_ZONE_FORM_REQUEST,
    DELETE_POINT_ZONE_FORM_SUCCESS,
    DELETE_POINT_ZONE_FORM_ERROR,
    DELETE_POINT_ZONE_FORM,
    GET_JSON_POINT_ZONE_FORM,
    ADD_POINT_ZONE_FORM,
    ADD_POINT_ZONE_FORM_ERROR,
    ADD_POINT_ZONE_FORM_SUCCESS,
    ADD_POINT_ZONE_FORM_REQUEST,
    SET_JSON_POINT_ZONE_FORM,
    DELETE_JSON_POINT_ZONE_FORM,
    OPEN_POINT_ZONE_FORM,
    CLOSE_POINT_ZONE_FORM
} from '../constants/pointZoneForm'
import {IAction} from "./index";
import {IPointZone, IPointZoneAddFormRequest, IPointZoneEditFormRequest} from '../../models/Point';


export interface IActionPointZoneForm extends IAction<symbol, any> {

}

export const addPointZoneFormRequest = () => {
    return {
        type: ADD_POINT_ZONE_FORM_REQUEST
    }
};

export const addPointZoneFormSuccess = (payload: IPointZone) => {
    return {
        type: ADD_POINT_ZONE_FORM_SUCCESS,
        payload
    }
};

export const addPointZoneFormError = (payload: string) => {
    return {
        type: ADD_POINT_ZONE_FORM_ERROR,
        payload
    }
};

export const addPointZoneForm = (id: number, form: IPointZoneAddFormRequest) => {
    return {
        type: ADD_POINT_ZONE_FORM,
        payload: {id, form}
    };
};

export const editPointZoneFormRequest = () => {
    return {
        type: EDIT_POINT_ZONE_FORM_REQUEST
    }
};

export const editPointZoneFormSuccess = (payload: IPointZone) => {
    return {
        type: EDIT_POINT_ZONE_FORM_SUCCESS,
        payload
    }
};

export const editPointZoneFormError = (payload: string) => {
    return {
        type: EDIT_POINT_ZONE_FORM_ERROR,
        payload
    }
};

export const editPointZoneForm = (id:number, formId: number, form: IPointZoneEditFormRequest) => {
    return {
        type: EDIT_POINT_ZONE_FORM,
        payload: {id, formId, form}
    };
};

export const getPointZoneFormRequest = () => {
    return {
        type: GET_POINT_ZONE_FORM_REQUEST
    }
};

export const getPointZoneFormSuccess = (payload: IPointZone) => {
    return {
        type: GET_POINT_ZONE_FORM_SUCCESS,
        payload
    }
};

export const getPointZoneFormError = (payload: string) => {
    return {
        type: GET_POINT_ZONE_FORM_ERROR,
        payload
    }
};

export const getPointZoneForm = (payload: number) => {
    return {
        type: GET_POINT_ZONE_FORM,
        payload
    };
};

export const deletePointZoneFormRequest = () => {
    return {
        type: DELETE_POINT_ZONE_FORM_REQUEST
    }
};

export const deletePointZoneFormSuccess = () => {
    return {
        type: DELETE_POINT_ZONE_FORM_SUCCESS
    }
};

export const deletePointZoneFormError = (payload: string) => {
    return {
        type: DELETE_POINT_ZONE_FORM_ERROR,
        payload
    }
};

export const deletePointZoneForm = (id: number, formId:number) => {
    return {
        type: DELETE_POINT_ZONE_FORM,
        payload:{id,formId}
    };
};

export const openPointZoneForm = (mode: 'add' | 'edit' | 'delete', modeId?: number) => {
    return {
        type: OPEN_POINT_ZONE_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closePointZoneForm = () => {
    return {
        type: CLOSE_POINT_ZONE_FORM
    };
};

export const getJsonPointZoneForm = (payload: { name: string, polygon: number[][]}[]) => {
    return {
        type: GET_JSON_POINT_ZONE_FORM,
        payload
    };
};

export const setJsonPointZoneForm = (payload: { name: string, polygon: number[][] }) => {
    return {
        type: SET_JSON_POINT_ZONE_FORM,
        payload
    };
};

export const deleteJsonPointZoneForm = () => {
    return {
        type: DELETE_JSON_POINT_ZONE_FORM
    };
};

