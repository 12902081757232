import {call, put, takeLatest} from 'redux-saga/effects';
import {getScheduleDateError, getScheduleDateRequest, getScheduleDateSuccess} from "../actions/scheduleDate";
import {ScheduleService} from "../../services/ScheduleService";
import {GET_SCHEDULE_DATE} from "../constants/scheduleDate";
import {IScheduleDate} from "../../models/Schedule";


type getScheduleDateType = {
    type: typeof GET_SCHEDULE_DATE,
    payload: {
        id:number
        date:string
        isActive:boolean
    }
};

function* getScheduleDateSaga({payload}:getScheduleDateType) {
    try {
        yield put(getScheduleDateRequest());

        const service = new ScheduleService();

        const promise = yield call(service.getScheduleDate,payload.id, payload.date, payload.isActive);

        const response: IScheduleDate[] = promise.data || [];

        yield put(getScheduleDateSuccess(response));

    } catch (error) {
        yield put(getScheduleDateError(error.response.data.message || 'Ошибка'));
    }
}



export function* watchGetScheduleDate() {
    yield takeLatest(GET_SCHEDULE_DATE, getScheduleDateSaga);
}
