import {
    ADD_PENALTY_FORM_REQUEST,
    ADD_PENALTY_FORM_SUCCESS,
    ADD_PENALTY_FORM_ERROR,
    EDIT_PENALTY_FORM_REQUEST,
    EDIT_PENALTY_FORM_SUCCESS,
    EDIT_PENALTY_FORM_ERROR,
    GET_PENALTY_FORM_REQUEST,
    GET_PENALTY_FORM_SUCCESS,
    GET_PENALTY_FORM_ERROR,
    DELETE_PENALTY_FORM_ERROR,
    DELETE_PENALTY_FORM_SUCCESS,
    DELETE_PENALTY_FORM_REQUEST,
    OPEN_PENALTY_FORM,
    CLOSE_PENALTY_FORM
} from '../constants/penaltyForm'
import {IPenalty} from '../../models/Penalty';
import {IActionPenaltyForm} from '../actions/penaltyForm';


export interface IPenaltyFormState {
    getLoading: boolean
    getError: string
    data: IPenalty | null
    changeLoading: boolean
    changeError: string
    mode: 'add' | 'edit' | 'delete' | null
    modeId: number | null
}

const initialState: IPenaltyFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null,
    modeId: null
};

export default function penaltyForm(state = initialState, action: IActionPenaltyForm): IPenaltyFormState {
    switch (action.type) {
        case GET_PENALTY_FORM_REQUEST:
            return {
                ...state,
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_PENALTY_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_PENALTY_FORM_ERROR:
            return {
                ...state,
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case ADD_PENALTY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_PENALTY_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_PENALTY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case EDIT_PENALTY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case EDIT_PENALTY_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case EDIT_PENALTY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case DELETE_PENALTY_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case DELETE_PENALTY_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case DELETE_PENALTY_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_PENALTY_FORM:
            const {mode, modeId = null} = action.payload;
            return {
                ...initialState,
                mode,
                modeId
            };
        case CLOSE_PENALTY_FORM:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
