import * as types from "../constants/orderReturnCheck";
import {IAction} from "../actions";

export interface uploadOrderReturnCheckState {
  isLoaded: boolean;
  loading: boolean;
  error: string;
}

const initialState: uploadOrderReturnCheckState = {
  isLoaded: false,
  loading: false,
  error: '',
}

export default function orderReturnCheckAdd(
  state = initialState,
  action: IAction<symbol, any>,
): uploadOrderReturnCheckState {
  switch (action.type) {
    case types.ADD_ORDER_RETURN_CHECK_REQUEST:
      return {
        loading: true,
        error: '',
        isLoaded: false,
      }
    case types.ADD_ORDER_RETURN_CHECK_SUCCESS:
      return {
        loading: false,
        error: '',
        isLoaded: true,
      }
    case types.ADD_ORDER_RETURN_CHECK_ERROR:
      return {
        loading: false,
        error: action.payload,
        isLoaded: false,
      }
    default:
      return state;
  }
}
