export const ADD_EMPLOYEE_FORM = Symbol('ADD_EMPLOYEE_FORM');
export const ADD_EMPLOYEE_FORM_REQUEST = Symbol('ADD_EMPLOYEE_FORM_REQUEST');
export const ADD_EMPLOYEE_FORM_SUCCESS = Symbol('ADD_EMPLOYEE_FORM_SUCCESS');
export const ADD_EMPLOYEE_FORM_ERROR = Symbol('ADD_EMPLOYEE_FORM_ERROR');
export const EDIT_EMPLOYEE_FORM = Symbol('EDIT_EMPLOYEE_FORM');
export const EDIT_EMPLOYEE_FORM_REQUEST = Symbol('EDIT_EMPLOYEE_FORM_REQUEST');
export const EDIT_EMPLOYEE_FORM_SUCCESS = Symbol('EDIT_EMPLOYEE_FORM_SUCCESS');
export const EDIT_EMPLOYEE_FORM_ERROR = Symbol('EDIT_EMPLOYEE_FORM_ERROR');
export const GET_EMPLOYEE_FORM = Symbol('GET_EMPLOYEE_FORM');
export const GET_EMPLOYEE_FORM_REQUEST = Symbol('GET_EMPLOYEE_FORM_REQUEST');
export const GET_EMPLOYEE_FORM_SUCCESS = Symbol('GET_EMPLOYEE_FORM_SUCCESS');
export const GET_EMPLOYEE_FORM_ERROR = Symbol('GET_EMPLOYEE_FORM_ERROR');
export const DELETE_EMPLOYEE_FORM = Symbol('DELETE_EMPLOYEE_FORM');
export const DELETE_EMPLOYEE_FORM_REQUEST = Symbol('DELETE_EMPLOYEE_FORM_REQUEST');
export const DELETE_EMPLOYEE_FORM_SUCCESS = Symbol('DELETE_EMPLOYEE_FORM_SUCCESS');
export const DELETE_EMPLOYEE_FORM_ERROR = Symbol('DELETE_EMPLOYEE_FORM_ERROR');
export const CHANGE_PASSWORD_EMPLOYEE_FORM = Symbol('CHANGE_PASSWORD_EMPLOYEE_FORM');
export const CHANGE_PASSWORD_EMPLOYEE_FORM_REQUEST = Symbol('CHANGE_PASSWORD_EMPLOYEE_FORM_REQUEST');
export const CHANGE_PASSWORD_EMPLOYEE_FORM_SUCCESS = Symbol('CHANGE_PASSWORD_EMPLOYEE_FORM_SUCCESS');
export const CHANGE_PASSWORD_EMPLOYEE_FORM_ERROR = Symbol('CHANGE_PASSWORD_EMPLOYEE_FORM_ERROR');
export const OPEN_EMPLOYEE_FORM = Symbol('OPEN_EMPLOYEE_FORM');
export const CLOSE_EMPLOYEE_FORM = Symbol('CLOSE_EMPLOYEE_FORM');

