import {
    LOGIN_ERROR,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    LOGOUT_SUCCESS,
    CHANGE_PASSWORD_USER_REQUEST,
    CHANGE_PASSWORD_USER_SUCCESS,
    CHANGE_PASSWORD_USER_ERROR
} from "../constants/auth";
import {IActionAuth} from "../actions/auth";
import {IAuth} from "../../models/Auth";


export interface IAuthState {
    loading: boolean
    changeLoading: boolean
    error: string
    changeError: string
    user: IAuth | null
    isAuthenticated: boolean
}

const initialState: IAuthState = {
    user: null,
    changeLoading: false,
    loading: false,
    error: '',
    changeError: '',
    isAuthenticated: !!localStorage.getItem('auth') || false
};

export default function auth(state = initialState, action: IActionAuth): IAuthState {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                user: null,
                loading: true,
                error: '',
                isAuthenticated: false,
                changeLoading: false,
                changeError: ''
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: '',
                isAuthenticated: true
            };
        case LOGIN_ERROR:
            return {
                ...state,
                user: null,
                loading: false,
                error: action.payload,
                isAuthenticated: false
            };
        case GET_USER_REQUEST:
            return {
                ...state,
                user: null,
                loading: true,
                error: ''
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: ''
            };
        case GET_USER_ERROR:
            return {
                ...state,
                user: null,
                loading: false,
                error: action.payload
            };
        case CHANGE_PASSWORD_USER_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case CHANGE_PASSWORD_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case CHANGE_PASSWORD_USER_ERROR:
            return {
                ...state,
                changeLoading: true,
                changeError: action.payload
            };
        case LOGOUT_SUCCESS:
            return {
                user: null,
                loading: false,
                error: '',
                isAuthenticated: false,
                changeLoading: false,
                changeError: ''
            };
        default:
            return state;
    }
}
