import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {Alert, Button, Spinner} from "reactstrap";
import {deleteEmployeeForm} from "../store/actions/employeeForm";


type CityDeleteType = {
    deleteEmployeeForm: (id: number) => void
    cancelHandler?: () => void
    id: number
    changeLoading: boolean
    changeError: string
}

class EmployeeDeleteContainer extends Component<CityDeleteType> {

    render() {
        const {id, changeLoading, changeError, cancelHandler, deleteEmployeeForm} = this.props;

        return (
            <>
                {changeError &&
                (<Alert className={'mt-3'} color={'danger'}>
                    {changeError}
                </Alert>)}
                <div className={'text-right'}>
                    {cancelHandler &&
                    <Button onClick={cancelHandler} color={'primary'} className={'mr-3'}>Не удалять</Button>}
                    <Button onClick={() => deleteEmployeeForm(id)} color={'danger'} disabled={changeLoading}>
                        {changeLoading &&
                        (<Spinner
                            size="sm"
                        />)}
                        Да, удалить
                    </Button>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.employeeForm;

    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    deleteEmployeeForm
};


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDeleteContainer);
