import React, {Component} from "react";
import {IPoint, IPointZone} from "../models/Point";
import DeliveryZones from "../components/DeliveryZones";

type DeliveryZonesType = {
    data: IPointZone[]
}

class DeliveryZonesContainer extends Component<DeliveryZonesType>{
    render() {

        const {data} = this.props;

        return <DeliveryZones data={data}/>;
    }
}

export default (DeliveryZonesContainer);