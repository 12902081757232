import {IActionSalary} from "../actions/salary";
import {PAY_SALARY_ERROR, PAY_SALARY_REQUEST, PAY_SALARY_SUCCESS} from "../constants/salary";
import {ISalaryResponse} from "../../models/Salary";

export interface ISalaryPayState {
    loading: boolean
    data: ISalaryResponse | null
    error: string
}

const initialState: ISalaryPayState = {
    loading: false,
    data: null,
    error: ''
}

export default function salaryPay(state = initialState, action: IActionSalary): ISalaryPayState {
    switch (action.type) {
        case PAY_SALARY_REQUEST:
            return {
                ...state,
                loading: true,
                data: null,
                error: ''
            };
        case PAY_SALARY_SUCCESS:
            const {data} = action.payload;
            return {
                ...state,
                loading: false,
                data,
                error: ''
            };
        case PAY_SALARY_ERROR:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };
        default:
            return state;
    }
}