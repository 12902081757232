import {IAction} from "./index";
import * as types from "../constants/couriersShifts"
import {ISlotFilter, ISlotResponse} from "../../models/Slot";

export interface IActionCouriersShifts extends IAction<symbol, any> {

}

export const getCouriersShiftsRequest = () => {
    return {
        type: types.GET_COURIERS_SHIFTS_REQUEST
    }
}

export const getCouriersShiftsSuccess = (data: ISlotResponse[], total: number, by: number) => {
    return {
        type: types.GET_COURIERS_SHIFTS_SUCCESS,
        payload: {
            data,
            total,
            by
        }
    }
}

export const getCouriersShiftsError = (payload: string) => {
    return {
        type: types.GET_COURIERS_SHIFTS_SUCCESS,
        payload
    }
}

export const getCouriersShifts = (page?: number, params?: { expand?: string, form?: ISlotFilter }) => {
    return {
        type: types.GET_COURIERS_SHIFTS,
        payload: {
            page,
            params
        }
    }
}