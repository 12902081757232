import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addOrderFormRequest,
    addOrderFormSuccess,
    addOrderFormError,
    editOrderFormRequest,
    editOrderFormSuccess,
    editOrderFormError,
    getOrderFormRequest,
    getOrderFormSuccess,
    getOrderFormError,
    changeCoordinateOrderFormRequest,
    changeCoordinateOrderFormSuccess,
    changeCoordinateOrderFormError, closeOrderForm
} from "../actions/orderForm";
import {ADD_ORDER_FORM, CHANGE_COORDINATE_ORDER_FORM, EDIT_ORDER_FORM, GET_ORDER_FORM} from "../constants/orderForm";
import {IOrder, IOrderAddFormRequest, IOrderChangeCoordinateRequest, IOrderEditFormRequest} from '../../models/Order';
import {OrderService} from "../../services/OrderService";
import {customHistory} from "../../utils/history";
import {getDictionaryCompanyBranches} from "../actions/dictionaryCompanyBranches";
import { getOrder } from '../actions/order';


function* addOrderFormSaga({payload}: { type: typeof ADD_ORDER_FORM, payload: IOrderAddFormRequest }) {
    try {
        yield put(addOrderFormRequest());

        const service = new OrderService();
        const promise = yield call(service.addOrder, payload);

        const response: IOrder = promise.data;

        yield put(addOrderFormSuccess(response));

        customHistory.goBack()

    } catch (error) {
        yield put(addOrderFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddOrderForm() {
    yield takeLatest(ADD_ORDER_FORM, addOrderFormSaga);
}


type editOrderFormType = {
    type: typeof EDIT_ORDER_FORM,
    payload: {
        id: string,
        form: IOrderEditFormRequest
    }
}

function* editOrderFormSaga({payload}: editOrderFormType) {
    try {
        yield put(editOrderFormRequest());

        const service = new OrderService();
        const promise = yield call(service.editOrder, payload.id, payload.form);

        const response: IOrder = promise.data;

        yield put(editOrderFormSuccess(response));

        customHistory.goBack()

    } catch (error) {
        yield put(editOrderFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditOrderForm() {
    yield takeLatest(EDIT_ORDER_FORM, editOrderFormSaga);
}

function* getOrderFormSaga({payload}: { type: typeof GET_ORDER_FORM, payload: string }) {
    try {
        yield put(getOrderFormRequest());

        const service = new OrderService();
        const promise = yield call(service.getOrder, payload);

        const response: IOrder = promise.data;

        if (response.branchId) {
            yield put(getDictionaryCompanyBranches(response.companyId, response.cityId))
        }
        yield put(getOrderFormSuccess(response));

    } catch (error) {
        yield put(getOrderFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetOrderForm() {
    yield takeLatest(GET_ORDER_FORM, getOrderFormSaga);
}


type changeCoordinateOrderFormType = {
    type: typeof CHANGE_COORDINATE_ORDER_FORM,
    payload: {
        id: string,
        form: IOrderChangeCoordinateRequest
    }
};

function* changeCoordinateOrderFormSaga({payload}: changeCoordinateOrderFormType) {
    try {
        yield put(changeCoordinateOrderFormRequest());

        const service = new OrderService();

        const promise = yield call(service.changeCoordinateOrder, payload.id, payload.form);

        const response: IOrder = promise.data;

        yield put(changeCoordinateOrderFormSuccess(response));

        yield put(getOrder(payload.id));
        yield put(closeOrderForm());

    } catch (error) {
        yield put(changeCoordinateOrderFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchChangeCoordinateOrderForm() {
    yield takeLatest(CHANGE_COORDINATE_ORDER_FORM, changeCoordinateOrderFormSaga);
}
