import {Api} from "./Api";
import {INotificationFilterRequest} from "../models/Notifications";


export class NotificationsService {
    getNotifications(page: number = 1, form?: INotificationFilterRequest) {
        return new Api().call(`/v1/manage/notification/search?page=${page}`, {method: 'POST', data: form});
    }
    deleteNotification(id: number) {
        return new Api().call(`/v1/manage/notification/${id}`, {method: 'POST'});
    }
}
