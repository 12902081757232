import {IActionSalary} from "../actions/salary";
import {PAY_GROUP_SALARY_ERROR, PAY_GROUP_SALARY_REQUEST, PAY_GROUP_SALARY_SUCCESS} from "../constants/salary";

export interface ISalaryPayGroupState {
  loading: boolean;
  success: boolean;
  error: string;
}

const initialState: ISalaryPayGroupState = {
  loading: false,
  success: false,
  error: '',
}

export default function salaryPayGroup(
  state = initialState,
  action: IActionSalary,
) {
  switch (action.type) {
    case PAY_GROUP_SALARY_REQUEST:
      return {
        loading: true,
        success: false,
        error: '',
      }
    case PAY_GROUP_SALARY_SUCCESS:
      return {
        loading: false,
        success: true,
        error: '',
      }
    case PAY_GROUP_SALARY_ERROR:
      return {
        loading: false,
        success: false,
        error: action.payload,
      }
    default:
      return state;
  }
}
