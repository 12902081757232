import React, {Component} from "react";
import {AppState} from "../store/reducers";
import {Redirect, RouteComponentProps} from "react-router-dom";
import {compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import PickCity from "./PickCity";
import {changeRatioRate, deleteRatioRate, getRatioRate} from "../store/actions/ratioRate";
import {ICourierRatioRateConditionForm, ICourierRatioRateConditionResponse} from "../models/Courier";
import Loader from "../components/UI/Loader";
import TableGrid from "../components/UI/TableGrid";
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import {employeeTypeOptions} from "../utils/employees";
import Icon from "../components/UI/Icon";
import Error from "../components/UI/Error";
import Empty from "../components/UI/Empty";
import RatioRateModal from "../containers/RatioRateModal";
import _ from "lodash";
import {IAuth} from "../models/Auth";
import {IDictionary} from "../models/Dictionary";
import {getDictionaryCityEagers} from "../store/actions/dictionaryCityEagers";
import {ratioRateCityIdReSelector} from "../store/reselectors/ratioRate";

type RatioRateType = {
    getRatioRate: (cityId: number) => void
    changeRatioRate: (form: ICourierRatioRateConditionForm, id?: number) => void
    data: ICourierRatioRateConditionResponse[]
    loading: boolean
    error: string
    cityId: number
    deleteRatioRate: (id: number) => void
    getDictionaryCityEagers: () => void
    dictionaryCityEagers: IDictionary[]
    dictionaryCityEagersLoading: boolean
    dictionaryCityEagersError: string
    changeLoading: boolean
    changeError: string
    deleteError: string
}

type RatioRateStateType = {
    isModalOpen: boolean
    modalTitle: string
    modalData: ICourierRatioRateConditionResponse | null
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class RatioRateContainer extends Component<RatioRateType & RatioRateStateType & CitiesType> {

    state = {
        isModalOpen: false,
        modalTitle: '',
        modalData: null
    }

    componentDidMount() {
        const {getRatioRate, cityId, getDictionaryCityEagers} = this.props;
        getDictionaryCityEagers();
        cityId && getRatioRate(cityId);
    }

    componentDidUpdate(prevProps: Readonly<RatioRateType>, prevState: Readonly<any>) {
        const {getRatioRate, cityId} = this.props;

        if (prevProps.cityId !== cityId) {
            cityId && getRatioRate(cityId);
        }
    }

    closeModalHandler = () => {
        const {cityId, getRatioRate} = this.props;

        this.setState({
            isModalOpen: false,
            modalTitle: '',
            modalData: null
        });

        setTimeout(function () {
            getRatioRate(cityId)
        }, 400);
    }

    render() {
        const {
            getRatioRate,
            cityId,
            data,
            loading,
            error,
            deleteRatioRate,
            allowedCities,
            dictionaryCityEagers,
            dictionaryCityEagersLoading,
            dictionaryCityEagersError,
            getDictionaryCityEagers,
            changeRatioRate,
            changeLoading,
            changeError,
            deleteError
        } = this.props;
        const {isModalOpen, modalTitle, modalData} = this.state;

        if (!cityId) {
            return <Redirect to={`/settings/ratio-rate?cityId=${allowedCities[0].id}`}/>
        }

        if (loading || dictionaryCityEagersLoading) {
            return <Loader/>
        }

        if (error || changeError || deleteError) {
            return <Error error={error} refresh={() => getRatioRate(cityId)}/>
        }

        if (dictionaryCityEagersError) {
            return <Error error={dictionaryCityEagersError} refresh={getDictionaryCityEagers}/>
        }

        return (
            <>
                <div className={"mb-2"}>
                    <PickCity/>
                </div>
                <div className={"mb-2"}>
                    <Button color={'primary'} onClick={() => {
                        this.setState({
                            isModalOpen: true,
                            modalTitle: 'Создать условие'
                        })
                    }}>
                        Создать условие
                    </Button>
                </div>
                <TableGrid sourceData={data} columns={[
                    {
                        key: 'typeId',
                        label: 'Тип курьера',
                        render: (item: ICourierRatioRateConditionResponse) => {
                            return employeeTypeOptions.find(x => x.value === item.typeId)?.label || "Все типы";
                        }
                    },
                    {
                        key: 'timeStart',
                        label: 'Период дат',
                        render: (item: ICourierRatioRateConditionResponse) => {
                            return (
                                <>
                                    {(item.dateStart && item.dateEnd) ? `${item.dateStart} - ${item.dateEnd}` : "Без ограничений"}
                                </>
                            )
                        }
                    },
                    {
                        key: 'timeStart',
                        label: 'Время',
                        render: (item: ICourierRatioRateConditionResponse) => {
                            return (
                                <>
                                    {(item.timeStart && item.timeEnd) ? `${item.timeStart.slice(0, -3)} - ${item.timeEnd.slice(0, -3)}` : "Без ограничений"}
                                </>
                            )
                        }
                    },
                    {
                        key: 'days',
                        label: 'День недели',
                        render: (item: ICourierRatioRateConditionResponse) => {
                            const days: any = [];

                            item.days?.forEach((day: number) => {
                                switch (day) {
                                    case 1:
                                        days.push('ПН');
                                        break;
                                    case 2:
                                        days.push('ВТ');
                                        break;
                                    case 3:
                                        days.push('СР');
                                        break;
                                    case 4:
                                        days.push('ЧТ');
                                        break;
                                    case 5:
                                        days.push('ПТ')
                                        break;
                                    case 6:
                                        days.push('СБ');
                                        break;
                                    case 0:
                                        days.push('ВС');
                                        break;
                                }
                            })

                            return (
                                <>
                                    {item.days ? days.join(', ') : "Без ограничений"}
                                </>
                            )
                        }
                    },
                    {
                        key: 'ratio',
                        label: 'Коэффициент'
                    },
                    {
                        key: 'priority',
                        label: 'Приоритет'
                    },
                    {
                        key: 'status',
                        label: 'Статус',
                        render: (item: ICourierRatioRateConditionResponse) => {
                            return (item.status ? 'Включен' : 'Выключен')
                        }
                    },
                    {
                        key: 'options',
                        label: '',
                        width: 100,
                        render: (item: ICourierRatioRateConditionResponse) => {
                            return (
                                <ButtonGroup>
                                    <Button
                                        color={'outline-light'}
                                        title={'Редактировать'}
                                        size={'sm'}
                                        onClick={() => {
                                            this.setState({
                                                isModalOpen: true,
                                                modalTitle: 'Редактировать условие',
                                                modalData: item
                                            })
                                        }}
                                    >
                                        <Icon name={'edit'} color={'gray-500'}></Icon>
                                    </Button>
                                    <Button
                                        color={'outline-light'}
                                        title={'Удалить'}
                                        size={'sm'}
                                        onClick={() => {
                                            deleteRatioRate(item.id);
                                            setTimeout(function () {
                                                getRatioRate(cityId)
                                            }, 300);
                                        }}
                                    >
                                        <Icon name={'delete'} color={'gray-500'}></Icon>
                                    </Button>
                                </ButtonGroup>
                            )
                        }
                    }
                ]}/>
                {!data.length && !loading && !error &&
                <Empty>
                    <h5 className={'mb-0'}>Список пуст</h5>
                </Empty>}
                <Modal isOpen={isModalOpen} size={'md'} toggle={this.closeModalHandler}>
                    <ModalHeader toggle={this.closeModalHandler}>
                        {modalTitle}
                    </ModalHeader>
                    <ModalBody>
                        {!!dictionaryCityEagers.length &&
                        <RatioRateModal
                            changeLoading={changeLoading}
                            changeRatioRate={changeRatioRate}
                            allowedCities={allowedCities}
                            data={modalData}
                            dictionaryCityEagers={dictionaryCityEagers}
                            closeModalHandler={this.closeModalHandler}
                        />}
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = ({dictionaryCityEagers, ratioRate, ratioRateChange, ratioRateDelete, auth}: AppState, props: RouteComponentProps) => {
    const {data, loading, error} = ratioRate;
    const changeLoading = ratioRateChange.loading;
    const changeError = ratioRateChange.error;
    const deleteError = ratioRateDelete.error;

    return {
        data,
        loading,
        error,
        cityId: ratioRateCityIdReSelector(props),
        allowedCities: _.get(auth.user, 'allowedCities', []),
        dictionaryCityEagers: dictionaryCityEagers.data,
        dictionaryCityEagersLoading: dictionaryCityEagers.loading,
        dictionaryCityEagersError: dictionaryCityEagers.error,
        changeLoading,
        changeError,
        deleteError
    }
}

const mapDispatchToProps = {
    getRatioRate,
    deleteRatioRate,
    changeRatioRate,
    getDictionaryCityEagers
}

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(RatioRateContainer);