export const GET_COURIER = Symbol('GET_COURIER');
export const GET_COURIER_REQUEST = Symbol('GET_COURIER_REQUEST');
export const GET_COURIER_SUCCESS = Symbol('GET_COURIER_SUCCESS');
export const GET_COURIER_ERROR = Symbol('GET_COURIER_ERROR');

export const ENABLE_STATUS_COURIER_SUCCESS = Symbol('ENABLE_STATUS_COURIER_SUCCESS');
export const ENABLE_STATUS_COURIER = Symbol('ENABLE_STATUS_COURIER');

export const DISABLE_STATUS_COURIER_SUCCESS = Symbol('DISABLE_STATUS_COURIER_SUCCESS');
export const DISABLE_STATUS_COURIER = Symbol('DISABLE_STATUS_COURIER');
