import {
    GET_DICTIONARY_PUBLISHED_SCHEDULES_ERROR,
    GET_DICTIONARY_PUBLISHED_SCHEDULES_REQUEST,
    GET_DICTIONARY_PUBLISHED_SCHEDULES_SUCCESS
} from "../constants/dictionaryPublishedSchedules";
import {IActionDictionaryPublishedSchedules} from "../actions/dictionaryPublishedSchedules";
import {ISchedule} from "../../models/Schedule";


export interface IDictionaryPublishedSchedulesState {
    loading: boolean
    error: string
    data: ISchedule[]
}

const initialState: IDictionaryPublishedSchedulesState = {
    data: [],
    loading: false,
    error: ''
};

export default function dictionaryPublishedSchedules(state = initialState, action: IActionDictionaryPublishedSchedules): IDictionaryPublishedSchedulesState {
    switch (action.type) {
        case GET_DICTIONARY_PUBLISHED_SCHEDULES_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_DICTIONARY_PUBLISHED_SCHEDULES_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_DICTIONARY_PUBLISHED_SCHEDULES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
