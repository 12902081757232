import {
    GET_ARCHIVE_ORDERS,
    GET_ARCHIVE_ORDERS_REQUEST,
    GET_ARCHIVE_ORDERS_SUCCESS,
    GET_ARCHIVE_ORDERS_ERROR,
    CHANGE_STATUS_ARCHIVE_ORDERS,
    CHANGE_STATUS_ARCHIVE_ORDERS_SUCCESS,
    TOGGLE_ACTIVE_ARCHIVE_ORDERS,
    GET_ARCHIVE_ORDERS_SUMMARY,
    GET_ARCHIVE_ORDERS_SUMMARY_REQUEST,
    GET_ARCHIVE_ORDERS_SUMMARY_SUCCESS,
    GET_ARCHIVE_ORDERS_SUMMARY_ERROR, REPLACE_IN_ARCHIVE_ORDERS
} from '../constants/archiveOrders'
import {IAction} from "./index";
import {IOrderArchiveSummary, IOrderRequest, IOrderShort} from "../../models/Order";



export interface IActionArchiveOrders extends IAction<symbol, any> {

}

export interface IActionArchiveOrdersSummary extends IAction<symbol, any>{

}

export const getArchiveOrdersRequest = () => {
    return {
        type: GET_ARCHIVE_ORDERS_REQUEST
    }
};

export const getArchiveOrdersSuccess = (data: IOrderShort[], total:number, by:number) => {
    return {
        type: GET_ARCHIVE_ORDERS_SUCCESS,
        payload : {
            data,
            total,
            by
        }
    }
};

export const getArchiveOrdersError = (payload: string) => {
    return {
        type: GET_ARCHIVE_ORDERS_ERROR,
        payload
    }
};

export const getArchiveOrdersSummaryRequest = () => {
    return {
        type: GET_ARCHIVE_ORDERS_SUMMARY_REQUEST
    }
}


export const getArchiveOrdersSummarySuccess = (data?:IOrderArchiveSummary[]) => {
    return {
        type: GET_ARCHIVE_ORDERS_SUMMARY_SUCCESS,
        payload: {
            data
        }
    }
}

export const getArchiveOrdersSummaryError = (payload: string) => {
    return {
        type: GET_ARCHIVE_ORDERS_SUMMARY_ERROR,
        payload
    }
}

export const getArchiveOrdersSummary = (form?: IOrderRequest) => {
    return {
        type: GET_ARCHIVE_ORDERS_SUMMARY,
        payload: {
            form
        }
    }
}

export const getArchiveOrders = (page?: number, form?: IOrderRequest) => {
    return {
        type: GET_ARCHIVE_ORDERS,
        payload:{
            page,
            form
        }
    };
};

export const changeStatusArchiveOrdersSuccess = (payload: IOrderShort) => {
    return {
        type: CHANGE_STATUS_ARCHIVE_ORDERS_SUCCESS,
        payload
    }
};

export const changeStatusArchiveOrders = (id: string, status: number) => {
    return {
        type: CHANGE_STATUS_ARCHIVE_ORDERS,
        payload:{
            id,
            status
        }
    };
};

export const toggleActiveIdArchiveOrders = (payload: string) => {
    return {
        type: TOGGLE_ACTIVE_ARCHIVE_ORDERS,
        payload
    };
};

export const replaceInArchiveOrders = (payload: IOrderShort) => {
    return {
        type: REPLACE_IN_ARCHIVE_ORDERS,
        payload,
    }
}
