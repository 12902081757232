import {
    GET_FILTERED_COURIERS_LIST,
    GET_FILTERED_COURIERS_LIST_ERROR,
    GET_FILTERED_COURIERS_LIST_REQUEST,
    GET_FILTERED_COURIERS_LIST_SUCCESS
} from "../constants/courierChange";
import {ICourier, ICourierFilter} from "../../models/Courier";
import {IAction} from "./index";

export interface IActionCouriersFilteredList extends IAction<symbol, any>{

}

export const getFilteredCouriersListRequest = () => {
    return {
        type: GET_FILTERED_COURIERS_LIST_REQUEST
    }
}

export const getFilteredCouriersListSuccess = (payload: ICourier) => {
    return {
        type: GET_FILTERED_COURIERS_LIST_SUCCESS,
        payload
    }
}

export const getFilteredCouriersListError = (payload: string) => {
    return {
        type: GET_FILTERED_COURIERS_LIST_ERROR,
        payload
    }
}

export const getFilteredCouriersList = (payload?: {expand?: string, body?: ICourierFilter}) => {
    return {
        type: GET_FILTERED_COURIERS_LIST,
        payload
    }
}