import {
    ADD_COMPANY_BRANCH_FORM,
    ADD_COMPANY_BRANCH_FORM_REQUEST,
    ADD_COMPANY_BRANCH_FORM_SUCCESS,
    ADD_COMPANY_BRANCH_FORM_ERROR,
    EDIT_COMPANY_BRANCH_FORM_REQUEST,
    EDIT_COMPANY_BRANCH_FORM_SUCCESS,
    EDIT_COMPANY_BRANCH_FORM_ERROR,
    EDIT_COMPANY_BRANCH_FORM,
    GET_COMPANY_BRANCH_FORM,
    GET_COMPANY_BRANCH_FORM_REQUEST,
    GET_COMPANY_BRANCH_FORM_SUCCESS,
    GET_COMPANY_BRANCH_FORM_ERROR,
    DELETE_COMPANY_BRANCH_FORM_REQUEST,
    DELETE_COMPANY_BRANCH_FORM_SUCCESS,
    DELETE_COMPANY_BRANCH_FORM_ERROR,
    DELETE_COMPANY_BRANCH_FORM,
    OPEN_COMPANY_BRANCH_FORM,
    CLOSE_COMPANY_BRANCH_FORM
} from '../constants/companyBranchForm'
import {IAction} from "./index";
import {ICompanyBranch, ICompanyBranchAddFormRequest, ICompanyBranchEditFormRequest} from "../../models/Company";



export interface IActionCompanyBranchForm extends IAction<symbol, any> {

}


export const addCompanyBranchFormRequest = () => {
    return {
        type: ADD_COMPANY_BRANCH_FORM_REQUEST
    }
};

export const addCompanyBranchFormSuccess = (payload: ICompanyBranch) => {
    return {
        type: ADD_COMPANY_BRANCH_FORM_SUCCESS,
        payload
    }
};

export const addCompanyBranchFormError = (payload: string) => {
    return {
        type: ADD_COMPANY_BRANCH_FORM_ERROR,
        payload
    }
};

export const addCompanyBranchForm = (id:number, form: ICompanyBranchAddFormRequest) => {
    return {
        type: ADD_COMPANY_BRANCH_FORM,
        payload:{
            id,
            form
        }
    };
};

export const editCompanyBranchFormRequest = () => {
    return {
        type: EDIT_COMPANY_BRANCH_FORM_REQUEST
    }
};

export const editCompanyBranchFormSuccess = (payload: ICompanyBranch) => {
    return {
        type: EDIT_COMPANY_BRANCH_FORM_SUCCESS,
        payload
    }
};

export const editCompanyBranchFormError = (payload: string) => {
    return {
        type: EDIT_COMPANY_BRANCH_FORM_ERROR,
        payload
    }
};

export const editCompanyBranchForm = (id: number, form: ICompanyBranchEditFormRequest) => {
    return {
        type: EDIT_COMPANY_BRANCH_FORM,
        payload: {id, form}
    };
};

export const getCompanyBranchFormRequest = () => {
    return {
        type: GET_COMPANY_BRANCH_FORM_REQUEST
    }
};

export const getCompanyBranchFormSuccess = (payload: ICompanyBranch) => {
    return {
        type: GET_COMPANY_BRANCH_FORM_SUCCESS,
        payload
    }
};

export const getCompanyBranchFormError = (payload: string) => {
    return {
        type: GET_COMPANY_BRANCH_FORM_ERROR,
        payload
    }
};

export const getCompanyBranchForm = (payload: number) => {
    return {
        type: GET_COMPANY_BRANCH_FORM,
        payload
    };
};

export const deleteCompanyBranchFormRequest = () => {
    return {
        type: DELETE_COMPANY_BRANCH_FORM_REQUEST
    }
};

export const deleteCompanyBranchFormSuccess = () => {
    return {
        type: DELETE_COMPANY_BRANCH_FORM_SUCCESS
    }
};

export const deleteCompanyBranchFormError = (payload: string) => {
    return {
        type: DELETE_COMPANY_BRANCH_FORM_ERROR,
        payload
    }
};

export const deleteCompanyBranchForm = (sectionId:number,id: number) => {
    return {
        type: DELETE_COMPANY_BRANCH_FORM,
        payload:{
            sectionId,
            id
        }
    };
};

export const openCompanyBranchForm = (mode: 'add' | 'edit' | 'delete', modeId?: number) => {
    return {
        type: OPEN_COMPANY_BRANCH_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closeCompanyBranchForm = () => {
    return {
        type: CLOSE_COMPANY_BRANCH_FORM
    };
};

