import {
    GET_COURIER_PENALTIES,
    GET_COURIER_PENALTIES_REQUEST,
    GET_COURIER_PENALTIES_SUCCESS,
    GET_COURIER_PENALTIES_ERROR
} from '../constants/courierPenalties'
import {IAction} from "./index";
import {ICourierPenaltiesFilter, ICourierPenaltiesFilterRequest, ICourierPenalty} from "../../models/Courier";


export interface IActionCourierPenalties extends IAction<symbol, any> {

}

export const getCourierPenaltiesRequest = () => {
    return {
        type: GET_COURIER_PENALTIES_REQUEST
    }
};

export const getCourierPenaltiesSuccess = (data: ICourierPenalty[], filter:ICourierPenaltiesFilter) => {
    return {
        type: GET_COURIER_PENALTIES_SUCCESS,
        payload:{
            data,
            filter
        }
    }
};

export const getCourierPenaltiesError = (payload: string) => {
    return {
        type: GET_COURIER_PENALTIES_ERROR,
        payload
    }
};

export const getCourierPenalties = (id: number, form: ICourierPenaltiesFilterRequest) => {
    return {
        type: GET_COURIER_PENALTIES,
        payload: {
            id,
            form
        }
    };
};
