import {call, put, takeLatest} from 'redux-saga/effects';
import {PointService} from '../../services/PointService';
import {
    addPointFormRequest,
    addPointFormSuccess,
    addPointFormError,
    editPointFormRequest,
    editPointFormSuccess,
    editPointFormError,
    getPointFormRequest,
    getPointFormSuccess,
    getPointFormError,
    deletePointFormSuccess,
    deletePointFormRequest,
    deletePointFormError,
    closePointForm
} from "../actions/pointForm";
import {ADD_POINT_FORM, DELETE_POINT_FORM, EDIT_POINT_FORM, GET_POINT_FORM} from "../constants/pointForm";
import {IPoint, IPointAddFormRequest, IPointEditFormRequest} from '../../models/Point';
import {getPoints} from '../actions/points';
import { getUrlParams } from '../../utils/history';



function* addPointFormSaga({payload}: { type: typeof ADD_POINT_FORM, payload: IPointAddFormRequest }) {
    try {
        yield put(addPointFormRequest());

        const service = new PointService();
        const promise = yield call(service.addPoint, payload);

        const response:IPoint = promise.data;

        yield put(addPointFormSuccess(response));

        yield put(closePointForm());

        const params = getUrlParams();
        const cityId = params.cityId? +params.cityId: undefined;
        yield put(getPoints(cityId));


    } catch (error) {
        yield put(addPointFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddPointForm() {
    yield takeLatest(ADD_POINT_FORM, addPointFormSaga);
}


type editPointFormType = {
    type: typeof EDIT_POINT_FORM,
    payload: {
        id: number,
        form: IPointEditFormRequest
    }
}

function* editPointFormSaga({payload}: editPointFormType) {
    try {
        yield put(editPointFormRequest());

        const service = new PointService();
        const promise = yield call(service.editPoint, payload.id, payload.form);

        const response:IPoint = promise.data;

        yield put(editPointFormSuccess(response));

        yield put(closePointForm());

        const params = getUrlParams();
        const cityId = params.cityId? +params.cityId: undefined;
        yield put(getPoints(cityId));

    } catch (error) {
        yield put(editPointFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditPointForm() {
    yield takeLatest(EDIT_POINT_FORM, editPointFormSaga);
}

function* getPointFormSaga({payload}: { type: typeof GET_POINT_FORM, payload: number }) {
    try {
        yield put(getPointFormRequest());

        const service = new PointService();
        const promise = yield call(service.getPoint, payload);

        const response:IPoint = promise.data;

        yield put(getPointFormSuccess(response));

    } catch (error) {
        yield put(getPointFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetPointForm() {
    yield takeLatest(GET_POINT_FORM, getPointFormSaga);
}

function* deletePointFormSaga({payload}: { type: typeof DELETE_POINT_FORM, payload: number }) {
    try {
        yield put(deletePointFormRequest());

        const service = new PointService();
        yield call(service.deletePoint, payload);
        yield put(deletePointFormSuccess());

        yield put(closePointForm());

        const params = getUrlParams();
        const cityId = params.cityId? +params.cityId: undefined;
        yield put(getPoints(cityId));

    } catch (error) {
        yield put(deletePointFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeletePointForm() {
    yield takeLatest(DELETE_POINT_FORM, deletePointFormSaga);
}
