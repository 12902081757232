import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {deleteCompanyForm} from "../store/actions/companyForm";
import {Alert, Button, Spinner} from "reactstrap";


type CompanyDeleteType = {
    deleteCompanyForm: (id: number) => void
    cancelHandler?: () => void
    id: number
    changeLoading: boolean
    changeError: string
}

class CompanyDeleteContainer extends Component<CompanyDeleteType> {

    deleteHandler = () =>{
        const {id, deleteCompanyForm} = this.props;
        deleteCompanyForm(id)
    };

    render() {
        const {changeLoading, changeError, cancelHandler} = this.props;

        return (
            <>
                {changeError &&
                (<Alert className={'mt-3'} color={'danger'}>
                    {changeError}
                </Alert>)}
                <div className={'text-right'}>
                    {cancelHandler && <Button onClick={cancelHandler} color={'primary'} className={'mr-3'}>Не удалять</Button>}
                    <Button onClick={this.deleteHandler} color={'danger'} disabled={changeLoading}>
                        {changeLoading &&
                        (<Spinner
                            size="sm"
                        />)}
                        Да, удалить
                    </Button>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.companyForm;

    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    deleteCompanyForm
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyDeleteContainer);
