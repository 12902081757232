import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import { accessScheduleCreate} from "../utils/user-accesses";
import PickCity from "./PickCity";
import ScheduleNavbar from "./ScheduleNavbar";
import Schedules from "./Schedules";



type ScheduleOutputType = {
    accesses?: string[]
};
class ScheduleOutput extends Component<ScheduleOutputType> {

    render() {
        const {accesses} = this.props;

        return (
            <>
                <div className={'mb-3'}>
                    <PickCity/>
                </div>
                {Array.isArray(accesses) && accesses.includes(accessScheduleCreate) &&
                <div className={'mb-3'}>
                    <ScheduleNavbar/>
                </div>}
                <Schedules/>
            </>
        );
    }
}


const mapStateToProps = (state: AppState) => {
    const {user} = state.auth;
    return {
        accesses: user !== null ? user.accesses : undefined
    }
};

export default connect(mapStateToProps, null)(ScheduleOutput);
