import {CHANGE_RATIO_RATE, DELETE_RATIO_RATE, GET_RATIO_RATE} from "../constants/ratioRate";
import {put, call, takeLatest} from "redux-saga/effects";
import {
    changeRatioRateError,
    changeRatioRateRequest,
    changeRatioRateSuccess,
    deleteRatioRateError,
    deleteRatioRateRequest,
    deleteRatioRateSuccess,
    getRatioRateError,
    getRatioRateRequest,
    getRatioRateSuccess
} from "../actions/ratioRate";
import {CourierService} from "../../services/CourierService";
import {ICourierRatioRateConditionForm, ICourierRatioRateConditionResponse} from "../../models/Courier";

type getRatioRateSagaType = {
    type: typeof GET_RATIO_RATE,
    payload: {
        cityId: number
    }
}

function* getRatioRateSaga({payload}: getRatioRateSagaType) {
    try {
        yield put(getRatioRateRequest());

        const service = new CourierService();

        const promise = yield call(service.getCourierRatioRate, payload.cityId);

        const data: ICourierRatioRateConditionResponse[] = promise.data || [];

        yield put(getRatioRateSuccess(data));
    } catch (error) {
        yield put(getRatioRateError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetRatioRate() {
    yield takeLatest(GET_RATIO_RATE, getRatioRateSaga);
}

type changeRatioRateSagaType = {
    type: typeof CHANGE_RATIO_RATE,
    payload: {
        id?: number
        form: ICourierRatioRateConditionForm
    }
}

function* changeRatioRateSaga({payload}: changeRatioRateSagaType) {
    try {
        yield put(changeRatioRateRequest());

        const service = new CourierService();

        const promise = yield call(service.changeCourierRatioRate, payload.form, payload.id);

        const data: ICourierRatioRateConditionResponse = promise.data || null;
        yield put(changeRatioRateSuccess(data));
    } catch (error) {
        yield put(changeRatioRateError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchChangeRatioRate() {
    yield takeLatest(CHANGE_RATIO_RATE, changeRatioRateSaga);
}

type deleteRatioRateSagaType = {
    type: typeof DELETE_RATIO_RATE,
    payload: {
        id: number
    }
}

function* deleteRatioRateSaga({payload}: deleteRatioRateSagaType) {
    try {
        yield put(deleteRatioRateRequest());

        const service = new CourierService();

        const promise = yield call(service.deleteCourierRatioRate, payload.id);

        yield put(deleteRatioRateSuccess());
    } catch (error) {
        yield put(deleteRatioRateError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeleteRatioRate() {
    yield takeLatest(DELETE_RATIO_RATE, deleteRatioRateSaga);
}