import {call, put, takeLatest} from "redux-saga/effects";
import {ADD_EMPLOYEE_PHOTOS, DELETE_EMPLOYEE_PHOTOS} from "../constants/employeePhotos";
import {EmployeeService} from "../../services/EmployeeService";
import {
  addEmployeePhotosError,
  addEmployeePhotosRequest,
  addEmployeePhotoSuccess,
  deleteEmployeePhotosError, deleteEmployeePhotosRequest, deleteEmployeePhotosSuccess
} from "../actions/employeePhotos";
import {customHistory, getUrlParams} from "../../utils/history";
import {getEmployees} from "../actions/employees";
import {employeesFilterCombiner, employeesSortCombiner} from "../reselectors/employees";
import {getEmployeesRequestForm} from "../../utils/employees";

type addEmployeePhotosSagaType = {
  type: typeof ADD_EMPLOYEE_PHOTOS;
  payload: {
    id: number;
    data: FormData;
  };
}

function* addEmployeePhotosSaga({payload}: addEmployeePhotosSagaType) {
  try {
    yield put(addEmployeePhotosRequest());
  
    const service = new EmployeeService();
  
    yield call(service.uploadEmployeePhoto, payload.id, payload.data);
    
    yield put(addEmployeePhotoSuccess());
  
    const params = getUrlParams();
    const page = params.page
      ? Number(params.page)
      : 1;
    
    const filter = employeesFilterCombiner(customHistory.location.search);
    const sort = employeesSortCombiner(customHistory.location.search);
    
    yield put(getEmployees(page, getEmployeesRequestForm(filter, sort)));
  } catch (error) {
    yield put(addEmployeePhotosError(error.response.data.message || 'Ошибка'))
  }
}

export function* watchAddEmployeePhotos() {
  yield takeLatest(ADD_EMPLOYEE_PHOTOS, addEmployeePhotosSaga)
}

type deleteEmployeePhotosSagaType = {
  type: typeof DELETE_EMPLOYEE_PHOTOS;
  payload: {
    id: number;
  }
}

function* deleteEmployeePhotosSaga({payload}: deleteEmployeePhotosSagaType) {
  try {
    yield put(deleteEmployeePhotosRequest())
    
    const service = new EmployeeService()
  
    yield call(service.deleteEmployeePhoto, payload.id)
    
    yield put(deleteEmployeePhotosSuccess())
  
    const params = getUrlParams();
    const page = params.page ? +params.page : 1;
  
    const filter = employeesFilterCombiner(customHistory.location.search);
    const sort = employeesSortCombiner(customHistory.location.search);
  
    yield put(getEmployees(page, getEmployeesRequestForm(filter, sort)));
  } catch (error) {
    yield put(deleteEmployeePhotosError(error.response.data.message || 'Ошибка'))
  }
}

export function* watchDeleteEmployeePhotos() {
  yield takeLatest(DELETE_EMPLOYEE_PHOTOS, deleteEmployeePhotosSaga)
}
