import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {ICourier} from "../models/Courier";
import {getCourier} from "../store/actions/courier";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import _ from "lodash";
import {getLocation} from "../store/actions/location";
import {ILocationState} from "../store/reducers/location";
import {courierTypeYMapsIcons} from "../utils/couriers";

type CourierLocationType = {
    getCourier: typeof getCourier;
    data: ICourier | null;
    getLoading: boolean;
    getError: string;
    id: number;
    getLocation: typeof getLocation;
    externalData: ILocationState['data'];
    externalGetLoading: ILocationState['getLoading'];
    externalGetError: ILocationState['getError'];
    /** если задан, локация получается альтернативным запросом с помощью id заказа */
    orderIdForExternalGet?: string;
}

class CourierLocationContainer extends Component<CourierLocationType> {

    componentDidMount() {
        const {id, getCourier, orderIdForExternalGet, getLocation} = this.props;

        if (orderIdForExternalGet) {
            getLocation(orderIdForExternalGet);
            return;
        }
        
        getCourier(id, "location")
    }

    render() {
        const {
            data,
            getLoading,
            getError,
            id,
            getCourier,
            orderIdForExternalGet,
            getLocation,
            externalData,
            externalGetLoading,
            externalGetError,
        } = this.props;

        if (getLoading || externalGetLoading) {
            return <Loader/>
        }

        if (!orderIdForExternalGet && getError) {
            return <Error error={getError} refresh={() => getCourier(id, "location")}/>
        }
        
        if (orderIdForExternalGet && externalGetError) {
            return <Error error={externalGetError} refresh={() => getLocation(orderIdForExternalGet)}/>
        }

        if (data !== null || (orderIdForExternalGet && externalData)) {
            let center: number[];
            
            if (!orderIdForExternalGet) {
                center = data && _.has(data, 'location.lat') && _.has(data, 'location.long')
                  ? [_.get(data, 'location.lat'), _.get(data, 'location.long')]
                  : [];
            } else {
                center = externalData
                  ? [externalData.latitude, externalData.longitude]
                  : [];
            }
            
            const courierIcon = typeof data?.courierTypeId === 'number' ? courierTypeYMapsIcons[data.courierTypeId] : '';
            
            return (
                <div className={'map'}>
                    <YMaps>
                        <Map
                            state={{
                                center,
                                zoom: 17
                            }}
                            className={'map'}
                        >
                            {!!center.length && <Placemark
                              geometry={center}
                              options={{
                                  preset: `islands#orange${courierIcon}Icon`,
                              }}
                            />}
                        </Map>
                    </YMaps>
                </div>
            )
        }
        
        return null
    }
}

const mapStateToProps = ({courier, location}: AppState) => {
    return {
        data: courier.data,
        getLoading: courier.getLoading,
        getError: courier.getError,
        externalData: location.data,
        externalGetLoading: location.getLoading,
        externalGetError: location.getError,
    }
};

const mapDispatchToProps = {
    getCourier,
    getLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourierLocationContainer);
