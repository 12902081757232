import {Api} from "./Api";


export class DictionaryService {
    getDictionaryRoles() {
        return new Api().call(`/v1/dictionary/roles`, {method: 'GET'});
    }

    getDictionaryContactTypes() {
        return new Api().call(`/v1/dictionary/contact-type`, {method: 'GET'});
    }

    getDictionaryPermissions() {
        return new Api().call(`/v1/dictionary/permissions`, {method: 'GET'});
    }

    getDictionaryPoints(cityId: number) {
        return new Api().call(`/v1/dictionary/point/${cityId}`, {method: 'GET'});
    }

    getDictionaryCouriers(cityId?: number) {
        const url = cityId?`/v1/dictionary/courier?cityId=${cityId}`: `/v1/dictionary/courier`;
        return new Api().call(url, {method: 'GET'});
    }

    getDictionaryOrderStatuses(){
        return new Api().call(`/v1/dictionary/order-status`, {method: 'GET'});
    }

    getDictionaryCityEagers(){
        return new Api().call(`/v1/dictionary/city-eager`, {method: 'GET'});
    }

    getDictionaryCompanies(cityId?: number){
        const url = cityId?`/v1/dictionary/company?cityId=${cityId}`: `/v1/dictionary/company`;
        return new Api().call(url, {method: 'GET'});
    }

    getDictionaryCompanyBranches(companyId:number, cityId:number){
        return new Api().call(`/v1/dictionary/company/${companyId}/branches/${cityId}`, {method: 'GET'});
    }

    getDictionaryReportCompanyPeriod(){
        return new Api().call(`/v1/dictionary/report-partner-period`, {method: 'GET'});
    }

    getDictionaryReportCourierPeriod(){
        return new Api().call(`/v1/dictionary/report-courier-period`, {method: 'GET'});
    }
}
