import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {editEmployeeForm, getEmployeeForm} from "../store/actions/employeeForm";
import {IEmployee, IEmployeeEditFormRequest} from "../models/Employee";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import EmployeeEdit from "../components/EmployeeEdit";
import {IDictionary} from "../models/Dictionary";
import {IAuth} from "../models/Auth";
import _ from "lodash";
import {getDictionaryCompanies} from "../store/actions/dictionaryCompanies";


type EmployeeEditType = {
    getDictionaryCompanies: (cityId: number) => void
    getEmployeeForm: (id: number) => void
    editEmployeeForm: (id: number, form: IEmployeeEditFormRequest) => void
    cancelHandler?: () => void
    data: IEmployee | null
    id: number
    changeLoading: boolean
    changeError: string
    getLoading: boolean
    getError: string
    roles: IDictionary[]
    dictionaryCompanies: IDictionary[]
    dictionaryCompaniesLoading: boolean
    dictionaryCompaniesError: string
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class EmployeeEditContainer extends Component<EmployeeEditType & CitiesType> {

    componentDidMount() {
        const {id, getEmployeeForm} = this.props;
        getEmployeeForm(id);
    }


    render() {
        const {data, getLoading, getError, changeLoading, changeError, editEmployeeForm, getEmployeeForm, id,
            cancelHandler, roles, allowedCities, getDictionaryCompanies,
            dictionaryCompanies, dictionaryCompaniesLoading, dictionaryCompaniesError} = this.props;

        if (getLoading) {
            return <Loader/>
        }

        if (getError) {
            return <Error error={getError} refresh={() => getEmployeeForm(id)}/>
        }
        if (data !== null) {
            return (
                <EmployeeEdit
                    editEmployeeForm={editEmployeeForm}
                    allowedCities={allowedCities}
                    data={data}
                    roles={roles}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    cancelHandler={cancelHandler}
                    getDictionaryCompanies={getDictionaryCompanies}
                    dictionaryCompanies={dictionaryCompanies}
                    dictionaryCompaniesLoading={dictionaryCompaniesLoading}
                    dictionaryCompaniesError={dictionaryCompaniesError}
                />
            )
        }
        return null;
    }
}

const mapStateToProps = (state: AppState) => {
    const {data, getLoading, getError, changeLoading, changeError} = state.employeeForm;
    const {user} = state.auth;
    const dictionaryCompanies = state.dictionaryCompanies;

    return {
        data,
        getLoading,
        getError,
        changeLoading,
        changeError,
        allowedCities: _.get(user, 'allowedCities', []),
        dictionaryCompanies: dictionaryCompanies.data,
        dictionaryCompaniesLoading: dictionaryCompanies.loading,
        dictionaryCompaniesError: dictionaryCompanies.error
    }
};

const mapDispatchToProps = {
    getEmployeeForm,
    editEmployeeForm,
    getDictionaryCompanies
};


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEditContainer);
