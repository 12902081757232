import React from "react";
import {Component} from "react";
import Layout from "../../containers/Layout";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import CourierShiftOutput from "../../containers/CouriersShiftsOutput"

class CouriersShiftsPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Смены курьеров</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Смены курьеров', url: '/couriers-shifts'}
                ]}/>
                <CourierShiftOutput/>
            </Layout>
        )
    }
}

export default CouriersShiftsPage;