import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addCompanyBranchFormError,
    addCompanyBranchFormRequest,
    addCompanyBranchFormSuccess,
    deleteCompanyBranchFormRequest,
    editCompanyBranchFormError,
    editCompanyBranchFormRequest,
    editCompanyBranchFormSuccess,
    getCompanyBranchFormError,
    getCompanyBranchFormRequest,
    getCompanyBranchFormSuccess,
    deleteCompanyBranchFormSuccess,
    closeCompanyBranchForm,
    deleteCompanyBranchFormError
} from "../actions/companyBranchForm";
import {ICompanyBranch, ICompanyBranchAddFormRequest, ICompanyBranchEditFormRequest} from '../../models/Company';
import {CompanyService} from "../../services/CompanyService";
import {customHistory, removeUrlParams} from "../../utils/history";
import {
    ADD_COMPANY_BRANCH_FORM, DELETE_COMPANY_BRANCH_FORM,
    EDIT_COMPANY_BRANCH_FORM,
    GET_COMPANY_BRANCH_FORM
} from '../constants/companyBranchForm';
import {getCompanyBranches} from "../actions/companyBranches";


type addCompanyBranchFormType = {
    type: typeof ADD_COMPANY_BRANCH_FORM,
    payload: {
        id: number,
        form: ICompanyBranchAddFormRequest
    }
}

function* addCompanyBranchFormSaga({payload}: addCompanyBranchFormType) {
    try {
        yield put(addCompanyBranchFormRequest());

        const service = new CompanyService();
        const promise = yield call(service.addCompanyBranch, payload.id, payload.form);

        const response: ICompanyBranch = promise.data;

        yield put(addCompanyBranchFormSuccess(response));

        customHistory.goBack()

    } catch (error) {
        yield put(addCompanyBranchFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddCompanyBranchForm() {
    yield takeLatest(ADD_COMPANY_BRANCH_FORM, addCompanyBranchFormSaga);
}

type editCompanyBranchFormType = {
    type: typeof EDIT_COMPANY_BRANCH_FORM,
    payload: {
        id: number,
        form: ICompanyBranchEditFormRequest
    }
}

function* editCompanyBranchFormSaga({payload}: editCompanyBranchFormType) {
    try {
        yield put(editCompanyBranchFormRequest());

        const service = new CompanyService();
        const promise = yield call(service.editCompanyBranch, payload.id, payload.form);

        const response: ICompanyBranch = promise.data;

        yield put(editCompanyBranchFormSuccess(response));

        customHistory.goBack()

    } catch (error) {
        yield put(editCompanyBranchFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditCompanyBranchForm() {
    yield takeLatest(EDIT_COMPANY_BRANCH_FORM, editCompanyBranchFormSaga);
}

type getCompanyBranchFormType = {
    type: typeof GET_COMPANY_BRANCH_FORM,
    payload: number
}

function* getCompanyBranchFormSaga({payload}: getCompanyBranchFormType) {
    try {
        yield put(getCompanyBranchFormRequest());

        const service = new CompanyService();
        const promise = yield call(service.getCompanyBranch, payload);

        const response: ICompanyBranch = promise.data;

        yield put(getCompanyBranchFormSuccess(response));

    } catch (error) {
        yield put(getCompanyBranchFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCompanyBranchForm() {
    yield takeLatest(GET_COMPANY_BRANCH_FORM, getCompanyBranchFormSaga);
}


type deleteCompanyBranchFormType = {
    type: typeof DELETE_COMPANY_BRANCH_FORM,
    payload: {
        sectionId: number,
        id: number
    }
}

function* deleteCompanyBranchFormSaga({payload}: deleteCompanyBranchFormType) {
    try {

        yield put(deleteCompanyBranchFormRequest());

        const service = new CompanyService();
        yield call(service.deleteCompanyBranch, payload.id);
        yield put(deleteCompanyBranchFormSuccess());

        yield put(closeCompanyBranchForm());

        yield put(getCompanyBranches(payload.sectionId));

        removeUrlParams(['page'])

    } catch (error) {
        yield put(deleteCompanyBranchFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeleteCompanyBranchForm() {
    yield takeLatest(DELETE_COMPANY_BRANCH_FORM, deleteCompanyBranchFormSaga);
}
