import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {editPenaltyForm, getPenaltyForm} from "../store/actions/penaltyForm";
import {IPenalty, IPenaltyEditFormRequest} from "../models/Penalty";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import PenaltyEdit from "../components/PenaltyEdit";


type PenaltyEditType = {
    getPenaltyForm: (id: number) => void
    editPenaltyForm: (id: number, form: IPenaltyEditFormRequest) => void
    cancelHandler?: () => void
    data: IPenalty | null
    id: number
    changeLoading:boolean
    changeError:string
    getLoading:boolean
    getError:string
}


class PenaltyEditContainer extends Component<PenaltyEditType> {

    componentDidMount() {
        const {id, getPenaltyForm} = this.props;
        getPenaltyForm(id);
    }


    render() {
        const {data, getLoading, getError, changeLoading, changeError, editPenaltyForm, getPenaltyForm, id, cancelHandler} = this.props;

        if(getLoading){
            return <Loader/>
        }

        if(getError){
            return <Error error={getError} refresh={() => getPenaltyForm(id)}/>
        }
        if(data !== null) {
            return (
                <PenaltyEdit
                    editPenaltyForm={editPenaltyForm}
                    data={data}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    cancelHandler={cancelHandler}/>
            )
        }
        return null;
    }
}

const mapStateToProps = (state: AppState) => {
    const {data, getLoading, getError, changeLoading, changeError} = state.penaltyForm;

    return {
        data,
        getLoading,
        getError,
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    getPenaltyForm,
    editPenaltyForm
};


export default connect(mapStateToProps, mapDispatchToProps)(PenaltyEditContainer);
