import {call, put, takeLatest} from 'redux-saga/effects';
import {PointService} from '../../services/PointService';
import {IPointZone, IPointZoneAddFormRequest, IPointZoneEditFormRequest} from '../../models/Point';
import {
    editPointZoneFormSuccess,
    editPointZoneFormRequest,
    editPointZoneFormError,
    getPointZoneFormRequest,
    getPointZoneFormSuccess,
    getPointZoneFormError,
    deletePointZoneFormRequest,
    deletePointZoneFormSuccess,
    deletePointZoneFormError,
    addPointZoneFormSuccess,
    addPointZoneFormError,
    addPointZoneFormRequest,
    closePointZoneForm, deleteJsonPointZoneForm
} from "../actions/pointZoneForm";
import {
    DELETE_POINT_ZONE_FORM,
    EDIT_POINT_ZONE_FORM,
    GET_POINT_ZONE_FORM,
    ADD_POINT_ZONE_FORM
} from "../constants/pointZoneForm";
import {getPointZones} from "../actions/pointZones";


function* getPointZoneFormSaga({payload}: { type: typeof GET_POINT_ZONE_FORM, payload: number }) {
    try {
        yield put(getPointZoneFormRequest());

        const service = new PointService();
        const promise = yield call(service.getPointZone, payload);

        const response: IPointZone = promise.data;

        yield put(getPointZoneFormSuccess(response));

    } catch (error) {
        yield put(getPointZoneFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetPointZoneForm() {
    yield takeLatest(GET_POINT_ZONE_FORM, getPointZoneFormSaga);
}


type addPointFormType = {
    type: typeof ADD_POINT_ZONE_FORM,
    payload: {
        id: number,
        form: IPointZoneAddFormRequest
    }
}

function* addPointZoneFormSaga({payload}: addPointFormType) {
    try {
        yield put(addPointZoneFormRequest());


        const service = new PointService();
        const promise = yield call(service.addPointZone, payload.id, payload.form);

        const response: IPointZone = promise.data;

        yield put(addPointZoneFormSuccess(response));

        yield put(closePointZoneForm());
        yield put(deleteJsonPointZoneForm());

    } catch (error) {
        yield put(addPointZoneFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddPointZoneForm() {
    yield takeLatest(ADD_POINT_ZONE_FORM, addPointZoneFormSaga);
}

type editPointFormType = {
    type: typeof EDIT_POINT_ZONE_FORM,
    payload: {
        id: number,
        formId: number,
        form: IPointZoneEditFormRequest
    }
}

function* editPointZoneFormSaga({payload}: editPointFormType) {
    try {
        yield put(editPointZoneFormRequest());

        const service = new PointService();

        const promise = yield call(service.editPointZone, payload.formId, payload.form);

        const response: IPointZone = promise.data;

        yield put(editPointZoneFormSuccess(response));

        yield put(closePointZoneForm());
        yield put(deleteJsonPointZoneForm());

        yield put(getPointZones(payload.id));


    } catch (error) {
        yield put(editPointZoneFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditPointZoneForm() {
    yield takeLatest(EDIT_POINT_ZONE_FORM, editPointZoneFormSaga);
}

type deletePointFormType = {
    type: typeof DELETE_POINT_ZONE_FORM,
    payload: {
        id: number,
        formId: number
    }
}

function* deletePointZoneFormSaga({payload}: deletePointFormType) {
    try {
        yield put(deletePointZoneFormRequest());

        const service = new PointService();
        yield call(service.deletePointZone, payload.formId);
        yield put(deletePointZoneFormSuccess());

        yield put(closePointZoneForm());

        yield put(getPointZones(payload.id));

    } catch (error) {
        yield put(deletePointZoneFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeletePointZoneForm() {
    yield takeLatest(DELETE_POINT_ZONE_FORM, deletePointZoneFormSaga);
}

