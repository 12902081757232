import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import EmployeeOutput from "../../containers/EmployeeOutput";


class EmployeesPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Сотрудники</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Сотрудники', url: '/employees'}
                ]}/>
                <EmployeeOutput/>
            </Layout>
        );
    }
}

export default EmployeesPage;
