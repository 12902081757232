export const ADD_COURIER_PENALTY_FORM = Symbol('ADD_COURIER_PENALTY_FORM');
export const ADD_COURIER_PENALTY_FORM_REQUEST = Symbol('ADD_COURIER_PENALTY_FORM_REQUEST');
export const ADD_COURIER_PENALTY_FORM_SUCCESS = Symbol('ADD_COURIER_PENALTY_FORM_SUCCESS');
export const ADD_COURIER_PENALTY_FORM_ERROR = Symbol('ADD_COURIER_PENALTY_FORM_ERROR');

export const DELETE_COURIER_PENALTY_FORM = Symbol('DELETE_COURIER_PENALTY_FORM');
export const DELETE_COURIER_PENALTY_FORM_REQUEST = Symbol('DELETE_COURIER_PENALTY_FORM_REQUEST');
export const DELETE_COURIER_PENALTY_FORM_SUCCESS = Symbol('DELETE_COURIER_PENALTY_FORM_SUCCESS');
export const DELETE_COURIER_PENALTY_FORM_ERROR = Symbol('DELETE_COURIER_PENALTY_FORM_ERROR');

export const OPEN_COURIER_PENALTY_FORM = Symbol('OPEN_COURIER_PENALTY_FORM');
export const CLOSE_COURIER_PENALTY_FORM = Symbol('CLOSE_COURIER_PENALTY_FORM');


