import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import CompanyContactAdd from "./CompanyContactAdd";
import {closeCompanyContactForm, openCompanyContactForm} from "../store/actions/companyContactForm";
import {IDictionary} from "../models/Dictionary";


type CompanyContactNavbarType = {
    openCompanyContactForm: (mode: 'add') => void
    closeCompanyContactForm: () => void
    id: number
    mode: 'add' | 'edit' | 'delete' | null
    contactTypes:IDictionary[]
}


class CompanyContactNavbarContainer extends Component<CompanyContactNavbarType> {

    render() {
        const {
            id, openCompanyContactForm, closeCompanyContactForm, mode, contactTypes
        } = this.props;

        return (
            <div className={'mb-2'}>
                <Button
                    color={'primary'}
                    onClick={() => openCompanyContactForm('add')}
                >
                    Добавить контакт
                </Button>
                <Modal isOpen={mode==='add'} toggle={closeCompanyContactForm}>
                    <ModalHeader toggle={closeCompanyContactForm}>
                        Новый контакт
                    </ModalHeader>
                    <ModalBody>
                        <CompanyContactAdd id={id} contactTypes={contactTypes} cancelHandler={closeCompanyContactForm}/>
                    </ModalBody>
                </Modal>
            </div>
        )

    }
}


const mapStateToProps = (state: AppState) => {
    const {mode} = state.companyContactForm;

    return {
        mode
    }
};

const mapDispatchToProps = {
    openCompanyContactForm,
    closeCompanyContactForm
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContactNavbarContainer);
