import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import ProfileChangePassword from "../../containers/ProfileChangePassword";


class ProfileChangePasswordPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Изменение пароля</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Профиль', url: `/profile`},
                    {name: 'Изменение пароля', url: `/profile/change-password`}
                ]}/>
                <ProfileChangePassword/>
            </Layout>
        );
    }
}

export default ProfileChangePasswordPage;
