import React, {PureComponent} from 'react';
import {Alert, Button, Form, Spinner, FormGroup, Label, Input, FormFeedback, CustomInput, Col, Row} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage, typeMessage} from "../../utils/form-validation";
import Dropdown from "../UI/Dropdown";
import {sanitizeForm} from "../../utils/sanitize-form";
import {IEmployee, IEmployeeEditFormRequest} from "../../models/Employee";
import {IAuth} from "../../models/Auth";
import {getCityOptions} from "../../utils/cities";
import {IDictionary} from "../../models/Dictionary";
import {getDictionaryOptions} from "../../utils/dictionaries";
import NumberFormat from "react-number-format";
import {
    employeeTypeOptions,
    employeeStatusOptions,
    employeeRateTypeOptions,
    employeeLegalFormIdOptions, EMPLOYEE_ROLE_COURIER, EMPLOYEE_ROLE_PARTNER
} from "../../utils/employees";
import Loader from "../UI/Loader";
import Error from "../UI/Error";
import _ from "lodash";
import {COURIER_TYPE_AUTO} from "../../utils/couriers";


const schema = yup.object({
    status: yup.number(),
    lastName: yup.string().required(requiredMessage()),
    firstName: yup.string().required(requiredMessage()),
    middleName: yup.string(),
    phone: yup.string().required(requiredMessage()),
    email: yup.string().email(typeMessage()),
    role: yup.string().nullable().required(requiredMessage()),
    cities: yup.string().nullable().when('role', {
        is: (role) => role === EMPLOYEE_ROLE_PARTNER,
        then: yup.string().nullable().required(requiredMessage())
    }),
    companyId: yup.number().nullable().when('role', {
        is: (role) => role === EMPLOYEE_ROLE_PARTNER,
        then: yup.number().nullable().required(requiredMessage())
    }),
    inventory: yup.string(),
    courierTypeId: yup.number().nullable().when('role', {
        is: (role) => role === EMPLOYEE_ROLE_COURIER,
        then: yup.number().nullable().required(requiredMessage())
    }),
    rateTypeId: yup.number().nullable().when('role', {
        is: (role) => role === EMPLOYEE_ROLE_COURIER,
        then: yup.number().nullable().required(requiredMessage())
    }),
    legalFormId: yup.number().nullable().when('role', {
        is: (role) => role === EMPLOYEE_ROLE_COURIER,
        then: yup.number().nullable().required(requiredMessage())
    }),
    isSticker: yup.boolean()
});

type EmployeeEditType = {
    getDictionaryCompanies: (cityId: number) => void
    editEmployeeForm: (id:number, form: IEmployeeEditFormRequest) => void
    changeLoading: boolean
    changeError: string
    data: IEmployee
    cancelHandler?: () => void
    roles: IDictionary[]
    dictionaryCompanies: IDictionary[]
    dictionaryCompaniesLoading: boolean
    dictionaryCompaniesError: string
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class EmployeeEdit extends PureComponent<EmployeeEditType & CitiesType> {
    render() {
        const {data, editEmployeeForm, changeLoading, changeError, cancelHandler, allowedCities, roles,
            getDictionaryCompanies,
            dictionaryCompanies, dictionaryCompaniesLoading, dictionaryCompaniesError} = this.props;

        const cityOptions = getCityOptions(allowedCities);
        const dictionaryRoleOptions = getDictionaryOptions(roles);
        const dictionaryCompanyOptions = getDictionaryOptions(dictionaryCompanies);

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const status = sanitizeForm(values.status);
                    const phone = sanitizeForm(values.phone);
                    const email = sanitizeForm(values.email);
                    const role = sanitizeForm(values.role);
                    const cities = sanitizeForm(values.cities);
                    const companyId = sanitizeForm(values.companyId);
                    const inventory = sanitizeForm(values.inventory);
                    const courierTypeId = sanitizeForm(values.courierTypeId);
                    const rateTypeId = role === EMPLOYEE_ROLE_COURIER
                      ? sanitizeForm(values.rateTypeId)
                      : null;
                    const legalFormId = sanitizeForm(values.legalFormId);
                    const isSticker = courierTypeId === COURIER_TYPE_AUTO ? sanitizeForm(values.isSticker) : null;


                    const form = {
                        status,
                        lastName: sanitizeForm(values.lastName),
                        firstName: sanitizeForm(values.firstName),
                        middleName: sanitizeForm(values.middleName),
                        phone,
                        email,
                        role,
                        cities,
                        companyId,
                        inventory,
                        courierTypeId,
                        rateTypeId,
                        legalFormId,
                        isSticker
                    };
                    editEmployeeForm(data.id, form)
                }}
                initialValues={{
                    firstName: data.firstName || '',
                    lastName: data.lastName || '',
                    middleName: data.middleName || '',
                    phone: data.phone || '',
                    email: data.email || '',
                    role: data.role || null,
                    cities: data.cities || null,
                    companyId: data.companyId || null,
                    inventory: data.inventory || '',
                    courierTypeId: data.courierTypeId || null,
                    rateTypeId: data.rateTypeId || null,
                    status: data.status || 0,
                    legalFormId: data.legalFormId || null,
                    isSticker: data.isSticker || false
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      dirty
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>Статус</Label>
                            <Dropdown
                                name={'status'}
                                value={values.status}
                                options={employeeStatusOptions}
                                onChange={(value) => setFieldValue('status', value)}
                            />
                        </FormGroup>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Фамилия <span className={'required'}>*</span></Label>
                                    <Input
                                      type="text"
                                      name="lastName"
                                      value={values.lastName}
                                      onChange={handleChange}
                                      invalid={touched.lastName && !!errors.lastName}
                                    />
                                    <FormFeedback>{errors.lastName}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Имя <span className={'required'}>*</span></Label>
                                    <Input
                                      type="text"
                                      name="firstName"
                                      value={values.firstName}
                                      onChange={handleChange}
                                      invalid={touched.firstName && !!errors.firstName}
                                    />
                                    <FormFeedback>{errors.firstName}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Отчество</Label>
                                    <Input
                                      type="text"
                                      name="middleName"
                                      value={values.middleName}
                                      onChange={handleChange}
                                      invalid={touched.middleName && !!errors.middleName}
                                    />
                                    <FormFeedback>{errors.middleName}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label>Телефон <span className={'required'}>*</span></Label>
                            <NumberFormat
                                name={`phone`}
                                format={'+7 (###) ###-##-##'}
                                mask={'_'}
                                allowNegative={false}
                                decimalSeparator={false}
                                value={values.phone.substr(1)}
                                onValueChange={({value}) => setFieldValue('phone', '7' + value)}
                                invalid={touched.phone && !!errors.phone}
                                customInput={Input}
                            />
                            <FormFeedback>{errors.phone}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                type="text"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                invalid={touched.email && !!errors.email}
                            />
                            <FormFeedback>{errors.email}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Роль <span className={'required'}>*</span></Label>
                            <Dropdown
                                name={'role'}
                                value={values.role}
                                options={dictionaryRoleOptions}
                                onChange={(value) => {
                                    setFieldValue('courierTypeId', null);
                                    setFieldValue('inventory', '');
                                    setFieldValue('companyId', null);
                                    setFieldValue('cities', null);

                                    setFieldValue('role', value);
                                }}
                                invalid={touched.role && !!errors.role}
                            />
                            <FormFeedback>{errors.role}</FormFeedback>
                        </FormGroup>
                        {values.role && <FormGroup>
                            <Label>Город {(values.role === EMPLOYEE_ROLE_PARTNER) &&
                            <span className={'required'}>*</span>}</Label>
                            <Dropdown
                                name={'cities'}
                                value={values.cities}
                                options={cityOptions}
                                isMulti={values.role !== EMPLOYEE_ROLE_COURIER && values.role !== EMPLOYEE_ROLE_PARTNER}
                                onChange={(value) => {
                                    const cityId = !Array.isArray(value) ? [value] : value;

                                    setFieldValue('cities', cityId);
                                    getDictionaryCompanies(cityId[0]);
                                }}
                                invalid={touched.cities && !!errors.cities}
                            />
                            <FormFeedback>{errors.cities}</FormFeedback>
                        </FormGroup>}
                        {values.role === EMPLOYEE_ROLE_COURIER && (
                            <>
                                <FormGroup>
                                    <Label>Тип курьера <span className={'required'}>*</span></Label>
                                    <Dropdown
                                        name={'courierTypeId'}
                                        value={values.courierTypeId}
                                        options={employeeTypeOptions}
                                        invalid={touched.courierTypeId && !!errors.courierTypeId}
                                        onChange={(value) => setFieldValue('courierTypeId', value)}
                                    />
                                    <FormFeedback>{errors.courierTypeId}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Тип тарифа <span className={'required'}>*</span></Label>
                                    <Dropdown
                                      name={'rateTypeId'}
                                      value={values.rateTypeId}
                                      options={employeeRateTypeOptions}
                                      invalid={touched.rateTypeId && !!errors.rateTypeId}
                                      onChange={(value) => setFieldValue('rateTypeId', value)}
                                    />
                                    <FormFeedback>{errors.rateTypeId}</FormFeedback>
                                </FormGroup>
                                {values.courierTypeId ===  COURIER_TYPE_AUTO && (
                                    <FormGroup>
                                        <Label>АвтоПлюс</Label>
                                        <CustomInput
                                            type="switch"
                                            id="isSticker"
                                            label={values.isSticker ? 'Включен' : 'Выключен'}
                                            checked={values.isSticker}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                )}
                                <FormGroup>
                                    <Label>Инвентарный номер</Label>
                                    <Input
                                        type="text"
                                        name="inventory"
                                        value={values.inventory}
                                        onChange={handleChange}
                                        invalid={touched.inventory && !!errors.inventory}
                                    />
                                    <FormFeedback>{errors.inventory}</FormFeedback>
                                </FormGroup>
                            </>
                        )}
                        {values.role === EMPLOYEE_ROLE_COURIER && (
                            <>
                                <FormGroup>
                                    <Label>Форма договора <span className={'required'}>*</span></Label>
                                    <Dropdown
                                        name={'legalFormId'}
                                        value={values.legalFormId}
                                        options={employeeLegalFormIdOptions}
                                        invalid={touched.legalFormId && !!errors.legalFormId}
                                        onChange={(value) => setFieldValue('legalFormId', value)}
                                    />
                                    <FormFeedback>{errors.legalFormId}</FormFeedback>
                                </FormGroup>
                            </>
                        )}
                        {values.role === EMPLOYEE_ROLE_PARTNER && values.cities !== null && (
                            <>
                                {dictionaryCompaniesLoading &&
                                <Loader/>}

                                {dictionaryCompaniesError && <Error
                                    error={dictionaryCompaniesError}
                                    refresh={() => getDictionaryCompanies(_.get(values, `cities[0]`))}
                                />}

                                {!!dictionaryCompanies.length && (
                                    <FormGroup>
                                        <Label>ID компании</Label>
                                        <Dropdown
                                            name={'companyId'}
                                            value={values.companyId}
                                            options={dictionaryCompanyOptions}
                                            invalid={touched.companyId && !!errors.companyId}
                                            onChange={(value) => setFieldValue('companyId', value)}
                                        />
                                        <FormFeedback>{errors.companyId}</FormFeedback>
                                    </FormGroup>
                                )}
                            </>
                        )}
                        {changeError &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {changeError}
                        </Alert>)}
                        <div className={'text-right'}>
                            {cancelHandler &&
                            <Button color="light" onClick={cancelHandler} className={'mr-3'}>Отменить</Button>}
                            <Button type="submit" color={'success'} disabled={changeLoading || !dirty}>
                                {changeLoading &&
                                (<Spinner
                                    size="sm"
                                />)}
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default EmployeeEdit;
