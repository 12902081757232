import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addScheduleFormRequest,
    addScheduleFormSuccess,
    addScheduleFormError,
    getScheduleFormRequest,
    getScheduleFormSuccess,
    getScheduleFormError, copyScheduleFormError, copyScheduleFormSuccess, copyScheduleFormRequest
} from "../actions/scheduleForm";
import {
    ADD_SCHEDULE_FORM, COPY_SCHEDULE_FORM,
    GET_SCHEDULE_FORM
} from "../constants/scheduleForm";
import {ISchedule, IScheduleAddFormRequest, IScheduleCopyFormRequest} from '../../models/Schedule';
import {ScheduleService} from "../../services/ScheduleService";
import {customHistory} from "../../utils/history";


type addScheduleFormType = {
    type: typeof ADD_SCHEDULE_FORM
    payload: IScheduleAddFormRequest
}

function* addScheduleFormSaga({payload}: addScheduleFormType) {
    try {
        yield put(addScheduleFormRequest());

        const service = new ScheduleService();
        const promise = yield call(service.addSchedule, payload);

        const response: ISchedule = promise.data;

        yield put(addScheduleFormSuccess(response));

        customHistory.push(`/schedules/${response.id}`)

    } catch (error) {
        yield put(addScheduleFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddScheduleForm() {
    yield takeLatest(ADD_SCHEDULE_FORM, addScheduleFormSaga);
}

type getScheduleFormType = {
    type: typeof GET_SCHEDULE_FORM
    payload: number
}
function* getScheduleFormSaga({payload}: getScheduleFormType) {
    try {
        yield put(getScheduleFormRequest());

        const service = new ScheduleService();
        const promise = yield call(service.getSchedule, payload);

        const response: ISchedule = promise.data;

        yield put(getScheduleFormSuccess(response));

    } catch (error) {
        yield put(getScheduleFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetScheduleForm() {
    yield takeLatest(GET_SCHEDULE_FORM, getScheduleFormSaga);
}

type copyScheduleFormType = {
    type: typeof COPY_SCHEDULE_FORM
    payload: {
        id:number,
        form:IScheduleCopyFormRequest
    }
}
function* copyScheduleFormSaga({payload}: copyScheduleFormType) {
    try {
        yield put(copyScheduleFormRequest());

        const service = new ScheduleService();
        const promise = yield call(service.copySchedule, payload.id, payload.form);

        const response: ISchedule = promise.data;

        yield put(copyScheduleFormSuccess(response));

        customHistory.push(`/schedules/${response.id}`)

    } catch (error) {
        yield put(copyScheduleFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchCopyScheduleForm() {
    yield takeLatest(COPY_SCHEDULE_FORM, copyScheduleFormSaga);
}
