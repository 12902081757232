import {GET_COMPANY_BRANCHES, GET_COMPANY_BRANCHES_REQUEST, GET_COMPANY_BRANCHES_SUCCESS, GET_COMPANY_BRANCHES_ERROR} from '../constants/companyBranches'
import {IAction} from "./index";
import {ICompanyBranch, ICompanyBranchSortRequest, ICompanyBranchFilterRequest} from "../../models/Company";


export interface IActionCompanyBranches extends IAction<symbol, any> {

}

export const getCompanyBranchesRequest = () => {
    return {
        type: GET_COMPANY_BRANCHES_REQUEST
    }
};

export const getCompanyBranchesSuccess = (data: ICompanyBranch[], total:number, by:number) => {
    return {
        type: GET_COMPANY_BRANCHES_SUCCESS,
        payload:{
            data,
            total,
            by
        }
    }
};

export const getCompanyBranchesError = (payload: string) => {
    return {
        type: GET_COMPANY_BRANCHES_ERROR,
        payload
    }
};

export const getCompanyBranches = (id:number, page?:number, form?: ICompanyBranchFilterRequest & ICompanyBranchSortRequest) => {
    return {
        type: GET_COMPANY_BRANCHES,
        payload:{
            id,
            page,
            form
        }
    };
};

