import {IActionSalary} from "../actions/salary";
import {GET_SALARY_ERROR, GET_SALARY_REQUEST, GET_SALARY_SUCCESS} from "../constants/salary";
import {ISalaryGroupResponse, ISalaryResponse} from "../../models/Salary";

export interface ISalaryState {
    data: ISalaryResponse[] | ISalaryGroupResponse[];
    loading: boolean;
    error: string;
    total: number;
    by: number;
}

const initialState: ISalaryState = {
    data: [],
    loading: false,
    error: '',
    total: 0,
    by: 0
}

export default function salary(state = initialState, action: IActionSalary): ISalaryState {
    switch (action.type) {
        case GET_SALARY_REQUEST:
            return {
                ...state,
                data: [],
                loading: true,
                error: '',
                total: 0,
                by: 0
            };
        case GET_SALARY_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                ...state,
                data,
                loading: false,
                error: '',
                total,
                by
            };
        case GET_SALARY_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload,
                total: 0,
                by: 0
            };
        default:
            return state;
    }
}
