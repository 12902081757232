import React, {Component} from "react";
import styles from "./LoginPage.module.scss";
import logo from './logo.svg'
import classNames from 'classnames'
import Login from "../../containers/Login";
import {Card, CardBody} from "reactstrap";


class LoginPage extends Component {
    render() {
        return (
            <div className={styles.login}>
                <div className={styles['login-content']}>
                    <div className={classNames(styles['login-content__logo'], 'mb-5')}>
                        <img src={logo} alt="Логотип" width={200} />
                    </div>
                    <Card className={classNames(styles['login-content__form'], 'mb-5')}>
                        <CardBody>
                            <div className={'text-center'}>
                                <h3>Вход</h3>
                                <p>Войдите для работы с панелью управления</p>
                            </div>
                            <Login/>
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

export default LoginPage;
