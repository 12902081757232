export const ADD_PAYMENT_FORM = Symbol('ADD_PAYMENT_FORM');
export const ADD_PAYMENT_FORM_REQUEST = Symbol('ADD_PAYMENT_FORM_REQUEST');
export const ADD_PAYMENT_FORM_SUCCESS = Symbol('ADD_PAYMENT_FORM_SUCCESS');
export const ADD_PAYMENT_FORM_ERROR = Symbol('ADD_PAYMENT_FORM_ERROR');
export const EDIT_PAYMENT_FORM = Symbol('EDIT_PAYMENT_FORM');
export const EDIT_PAYMENT_FORM_REQUEST = Symbol('EDIT_PAYMENT_FORM_REQUEST');
export const EDIT_PAYMENT_FORM_SUCCESS = Symbol('EDIT_PAYMENT_FORM_SUCCESS');
export const EDIT_PAYMENT_FORM_ERROR = Symbol('EDIT_PAYMENT_FORM_ERROR');
export const GET_PAYMENT_FORM = Symbol('GET_PAYMENT_FORM');
export const GET_PAYMENT_FORM_REQUEST = Symbol('GET_PAYMENT_FORM_REQUEST');
export const GET_PAYMENT_FORM_SUCCESS = Symbol('GET_PAYMENT_FORM_SUCCESS');
export const GET_PAYMENT_FORM_ERROR = Symbol('GET_PAYMENT_FORM_ERROR');
export const DELETE_PAYMENT_FORM = Symbol('DELETE_PAYMENT_FORM');
export const DELETE_PAYMENT_FORM_REQUEST = Symbol('DELETE_PAYMENT_FORM_REQUEST');
export const DELETE_PAYMENT_FORM_SUCCESS = Symbol('DELETE_PAYMENT_FORM_SUCCESS');
export const DELETE_PAYMENT_FORM_ERROR = Symbol('DELETE_PAYMENT_FORM_ERROR');
export const OPEN_PAYMENT_FORM = Symbol('OPEN_PAYMENT_FORM');
export const CLOSE_PAYMENT_FORM = Symbol('CLOSE_PAYMENT_FORM');

