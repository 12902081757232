import React, {Component} from "react";
import {getCouriersShifts} from "../store/actions/couriersShifts";
import {RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {compose} from "redux";
import {AppState} from "../store/reducers";
import _ from "lodash";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import Empty from "../components/UI/Empty";
import TableGrid from "../components/UI/TableGrid";
import {
    Button,
    ButtonGroup,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    FormGroup,
    Label
} from "reactstrap";
import {couriersShiftsFilterReSelector, couriersShiftsPageReSelector} from "../store/reselectors/couriersShifts";
import {Link} from "react-router-dom";
import Pagination from "../components/UI/Pagination";
import queryString from "query-string";
import {ISlotChange, ISlotFilter, ISlotResponse, ISlotResponseHistory} from "../models/Slot";
import {format, parseISO} from "date-fns";
import Icon from "../components/UI/Icon";
import {Form, Formik} from "formik";
import {changeCourierSlot} from "../store/actions/courierShift";
import CourierTrack from "./CourierTrack";
import DateTimePicker from "../components/UI/DateTimePicker";
import {dateConvert} from "../utils/date-convert";
import Avatar from "../components/UI/Avatar";
import {courierTypeIcons} from "../utils/couriers";
import {ICityShortResponse} from "../models/City";

type CouriersShiftsType = {
    getCouriersShifts: (page?: number, form?: { expand?: string, form?: ISlotFilter }) => void
    changeCourierSlot: (id: number, form: ISlotChange) => void
    data: ISlotResponse[]
    page: number
    total: number
    by: number
    loading: boolean
    error: string
    changeLoading: boolean
    changeSuccess: boolean
    filter?: ISlotFilter
    allowedCities: ICityShortResponse[];
    accesses?: string[]
}

type MapStateType = {
    data: ISlotResponse[];
    isCourierTrackDialog: boolean;
    courierId: number;
    history?: { sessionStart: string[], sessionEnd: string[] } | null;
    isOpenCourierHistoryDialog: boolean;
    isOpenCourierSlotChangeDialog: boolean;
    slotParams: {
        id: number;
        sessionEnd?: string;
        breakTimeStart?: string;
        breakTimeEnd?: string;
    } | null;
}

class CouriersShiftsContainer extends Component<CouriersShiftsType & RouteComponentProps> {

    state: MapStateType = {
        data: [],
        isCourierTrackDialog: false,
        courierId: null!,
        history: null,
        isOpenCourierHistoryDialog: false,
        isOpenCourierSlotChangeDialog: false,
        slotParams: null
    }

    componentDidMount() {
        this.fetchHandler();
    }

    componentDidUpdate(prevProps: Readonly<CouriersShiftsType & RouteComponentProps>) {
        const {page, filter, changeSuccess, data} = this.props;
        const {isCourierTrackDialog} = this.state;

        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter)) {
            this.fetchHandler();
        }

        if (prevProps.changeSuccess !== changeSuccess && changeSuccess) {
            this.closeCourierSlotChangeDialog();
            this.fetchHandler();
        }

        if (prevProps.data !== data && !isCourierTrackDialog){
            this.setState({data})
        }
    }

    fetchHandler = () => {
        const {getCouriersShifts, page, filter} = this.props;

        const expand = "courier,ordersCounter,distance,trackInfo,history,salary"

        getCouriersShifts(page, !_.isEmpty({...filter}) ? {form: filter, expand} : {expand});
    }

    openCourierTrackDialog = (id?: number) => {
        if (id) {
            this.setState({
                isCourierTrackDialog: true,
                slotParams: {
                    id
                }
            });
        }
    }

    closeCourierTrackDialog = () => {
        this.setState({isCourierTrackDialog: false, track: null, slotParams: null})
    }

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    openCourierHistoryDialog = (data?: ISlotResponseHistory[]) => {

        const sortedData: { sessionStart: string[], sessionEnd: string[] } = {
            sessionStart: [],
            sessionEnd: []
        }

        data?.forEach((props: ISlotResponseHistory) => {
            if (props.action === "start") {
                sortedData.sessionStart = [...sortedData.sessionStart, props.dateTime]
            } else {
                sortedData.sessionEnd = [...sortedData.sessionEnd, props.dateTime]
            }
        })

        this.setState({isOpenCourierHistoryDialog: true, history: sortedData})
    }

    closeCourierHistoryDialog = () => {
        this.setState({isOpenCourierHistoryDialog: false, history: []})
    }
    
    openCourierSlotChangeDialog = (slot: ISlotResponse) => {
        this.setState({
            isOpenCourierSlotChangeDialog: true,
            slotParams: {
                id: slot.id,
                sessionEnd: slot.sessionEnd,
                breakTimeStart: slot.breakTimeStart,
                breakTimeEnd: slot.breakTimeEnd,
            }});
    }

    closeCourierSlotChangeDialog = () => {
        this.setState({isOpenCourierSlotChangeDialog: false, slotParams: null});
    }

    render() {
        const {loading, error, page, by, total, changeCourierSlot, changeLoading} = this.props;
        const {data, isCourierTrackDialog, isOpenCourierHistoryDialog, history, isOpenCourierSlotChangeDialog, slotParams} = this.state;

        if (!isCourierTrackDialog && loading) {
            return <Loader/>
        }

        if (error) {
            return <Error error={error} refresh={this.fetchHandler}/>
        }

        if (data.length) {
            return (
                <div className={'mb-3'}>
                    <TableGrid
                        sourceData={data}
                        columns={[
                            {
                                label: "Дата",
                                key: "dateSlot",
                                render: (params: ISlotResponse) => {
                                    const {dateSlot} = params;
                                    return dateSlot && format(parseISO(dateSlot), 'dd-MM-yyyy')
                                }
                            },
                            {
                                label: "ФИО",
                                render: (params: ISlotResponse) => {

                                    const icon = typeof params.courier?.courierTypeId === "number"
                                        ? courierTypeIcons[params.courier?.courierTypeId]
                                        : undefined;

                                    return <Row form className="align-items-center">
                                        {params.courier?.courierTypeId && <Col md={'auto'}>
                                            <Avatar
                                                icon={icon}
                                            />
                                        </Col>}
                                        <Col md={'auto'}>
                                            <Link to={`/couriers/${params.courier!.id}`} target={"_blank"} className={"semi-bold"}>{params.courier?.fio}</Link>
                                        </Col>
                                    </Row>
                                }
                            },
                            {
                                label: "Время смены",
                                render: (params: ISlotResponse) => {
                                    return <span>
                                        {format(parseISO(params!.createdAt!), 'HH:mm')}
                                        <span> - </span>
                                        {params.finishedAt ? format(parseISO(params!.finishedAt!), 'HH:mm') : '...'}
                                    </span>
                                }
                            },
                            {
                                label: "Кол-во заказов",
                                key: "countOrder"
                            },
                            {
                                label: "Заработок",
                                render: (params: ISlotResponse) => {
                                    return params.salary?.orderAmount ?? '-';
                                }
                            },
                            {
                                label: "Бонус",
                                render: (params: ISlotResponse) => {
                                    return params.salary?.dailyBonus ?? '-';
                                }
                            },
                            {
                                label: "Итого",
                                render: (params: ISlotResponse) => {
                                    if (typeof params.salary?.orderAmount !== "number" && typeof params.salary?.dailyBonus !== "number") {
                                        return '-';
                                    }
                                    
                                    return (params.salary?.orderAmount ?? 0) + (params.salary?.dailyBonus ?? 0);
                                }
                            },
                            {
                                label: "Платная доставка",
                                render: (params: ISlotResponse) => {
                                    return params.sumDelivery ?? '-';
                                }
                            },
                            {
                                label: "Дистанция",
                                render: (params: ISlotResponse) => {
                                    const km = (params.distance || 0) / 1000;
                                    return km.toFixed(1) + " км"
                                }
                            },
                            {
                                label: "Действие",
                                width: 124,
                                render: (params: ISlotResponse) =>
                                    <>
                                        <ButtonGroup>
                                            <Button
                                                size={'sm'}
                                                color={'outline-light'}
                                                onClick={() => this.openCourierSlotChangeDialog(params)}
                                                title={"Редактирование"}
                                            >
                                                <Icon name={'edit'} color={'gray-500'}/>
                                            </Button>
                                            {!!params?.history?.length &&
                                            <Button
                                                size={'sm'}
                                                color={'outline-light'}
                                                onClick={() => this.openCourierHistoryDialog(params.history)}
                                                title={"История смен курьера"}
                                            >
                                                <Icon name={'schedule'} color={'gray-500'}/>
                                            </Button>
                                            }
                                            {!!params?.trackInfo?.length &&
                                            <Button
                                                size={'sm'}
                                                color={'outline-light'}
                                                onClick={() => this.openCourierTrackDialog(params.id)}
                                                title={"Местоположение курьера"}
                                            >
                                                <Icon name={'map'} color={'gray-500'}/>
                                            </Button>}
                                        </ButtonGroup>
                                    </>
                            }
                        ]}
                    />
                    <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>
                    <Modal
                        isOpen={isCourierTrackDialog}
                        size={'xl'}
                        toggle={this.closeCourierTrackDialog}>
                        <ModalHeader toggle={this.closeCourierTrackDialog}>
                            Местоположение курьера
                        </ModalHeader>
                        {slotParams && <ModalBody>
                            <CourierTrack slotId={slotParams.id} />
                        </ModalBody> }
                    </Modal>
                    <Modal
                        isOpen={isOpenCourierHistoryDialog}
                        size={'md'}
                        toggle={this.closeCourierHistoryDialog}>
                        <ModalHeader toggle={this.closeCourierHistoryDialog}>
                            История смен курьера
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={4}>
                                    <span className={"pl-2"}>Начало:</span>
                                    {history?.sessionStart && history.sessionStart.map((props: string, index: number) => (
                                        <div key={index} className={"pl-2 pt-1 pb-1"}
                                             style={{backgroundColor: index & 1 ? "#FFFFFF" : "#F2F2F2"}}>
                                            {format(parseISO(props), 'HH:mm')}
                                        </div>
                                    ))}
                                </Col>
                                <Col md={4}>
                                    <span className={"pl-2"}>Конец:</span>
                                    {history?.sessionEnd && history.sessionEnd.map((props: string, index: number) => (
                                        <div key={index} className={"pl-2 pt-1 pb-1"}
                                             style={{backgroundColor: index & 1 ? "#FFFFFF" : "#F2F2F2"}}>
                                            {format(parseISO(props), 'HH:mm')}
                                        </div>
                                    ))}
                                </Col>
                                <Col md={4}>
                                    <span className={"pl-2"}>Длительность:</span>
                                    {history?.sessionEnd && history.sessionEnd.map((props: string, index: number) => (
                                        <div key={index} className={"pl-2 pt-1 pb-1"}
                                             style={{backgroundColor: index & 1 ? "#FFFFFF" : "#F2F2F2"}}>
                                            {Math.round((parseISO(props).valueOf() - parseISO(history.sessionStart[index]).valueOf()) / 60 / 1000)} мин.
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                    <Modal
                        isOpen={isOpenCourierSlotChangeDialog}
                        size={'md'}
                        toggle={this.closeCourierSlotChangeDialog}>
                        <ModalHeader toggle={this.closeCourierSlotChangeDialog}>
                            Редактирование смены курьера
                        </ModalHeader>
                        {(slotParams && slotParams.sessionEnd) && <ModalBody>
                            <Formik
                                initialValues={{
                                    date: format(parseISO(slotParams.sessionEnd), 'dd-MM-yyyy'),
                                    time: format(parseISO(slotParams.sessionEnd), 'HH:mm'),
                                    breakStartDate: slotParams.breakTimeStart
                                      ? format(parseISO(slotParams.breakTimeStart), 'dd-MM-yyyy')
                                      : '',
                                    breakStartTime: slotParams.breakTimeStart
                                      ? format(parseISO(slotParams.breakTimeStart), 'HH:mm')
                                      : '',
                                    breakEndDate: slotParams.breakTimeEnd
                                      ? format(parseISO(slotParams.breakTimeEnd), 'dd-MM-yyyy')
                                      : '',
                                    breakEndTime: slotParams.breakTimeEnd
                                      ? format(parseISO(slotParams.breakTimeEnd), 'HH:mm')
                                      : '',
                                }}
                                onSubmit={(values) => {
                                    if (!values.date || !values.time) {
                                        return;
                                    }
                                    
                                    const sessionEndDateISO = dateConvert(values.date, 'dd-MM-yyyy', 'yyyy-MM-dd');
                                    const breakStartDateISO = values.breakStartDate
                                      ? dateConvert(values.breakStartDate, 'dd-MM-yyyy', 'yyyy-MM-dd')
                                      : '';
                                    const breakEndDateISO = values.breakEndDate
                                      ? dateConvert(values.breakEndDate, 'dd-MM-yyyy', 'yyyy-MM-dd')
                                      : '';
                                    
                                    const form = {
                                        sessionEnd: `${sessionEndDateISO} ${values.time}:00`,
                                        breakTimeStart: values.breakStartTime && breakStartDateISO
                                          ? `${breakStartDateISO} ${values.breakStartTime}:00`
                                          : undefined,
                                        breakTimeEnd: values.breakEndTime && breakEndDateISO
                                          ? `${breakEndDateISO} ${values.breakEndTime}:00`
                                          : undefined,
                                    }
                                    
                                    changeCourierSlot(slotParams.id, form);
                                }}>
                                {({
                                  values,
                                  touched,
                                  errors,
                                  handleSubmit,
                                  setFieldValue,
                                }) => {
                                    return (
                                        <Form onSubmit={handleSubmit}>
                                            <FormGroup>
                                                <Label>Время конца смены курьера</Label>
                                                <Row>
                                                    <Col md={6}>
                                                        <DateTimePicker
                                                          name={"date"}
                                                          value={values.date}
                                                          dateFormat={'dd-MM-yyyy'}
                                                          onChange={date => setFieldValue('date', date)}
                                                          invalid={(touched.date && !!errors.date) || !values.date}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <DateTimePicker
                                                          name={"time"}
                                                          value={values.time}
                                                          dateFormat={'HH:mm'}
                                                          onChange={time => setFieldValue('time', time)}
                                                          showTimeSelect
                                                          showTimeSelectOnly
                                                          invalid={(touched.time && !!errors.time) || !values.time}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Время начала перерыва курьера</Label>
                                                <Row>
                                                    <Col md={6}>
                                                        <DateTimePicker
                                                            name={"breakStartDate"}
                                                            value={values.breakStartDate}
                                                            dateFormat={'dd-MM-yyyy'}
                                                            onChange={breakStartDate => setFieldValue('breakStartDate', breakStartDate)}
                                                            invalid={touched.breakStartDate && !!errors.breakStartDate}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <DateTimePicker
                                                            name={`breakStartTime`}
                                                            value={values.breakStartTime}
                                                            onChange={breakStartTime => setFieldValue('breakStartTime', breakStartTime)}
                                                            dateFormat={'HH:mm'}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            invalid={touched.breakStartTime && !!errors.breakStartTime}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Время конца перерыва курьера</Label>
                                                <Row>
                                                    <Col md={6}>
                                                        <DateTimePicker
                                                            name={"breakEndDate"}
                                                            value={values.breakEndDate}
                                                            dateFormat={'dd-MM-yyyy'}
                                                            onChange={breakEndDate => setFieldValue('breakEndDate', breakEndDate)}
                                                            invalid={touched.breakEndDate && !!errors.breakEndDate}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <DateTimePicker
                                                            name={`breakEndTime`}
                                                            value={values.breakEndTime}
                                                            onChange={breakEndTime => setFieldValue('breakEndTime', breakEndTime)}
                                                            dateFormat={'HH:mm'}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            invalid={touched.breakEndTime && !!errors.breakEndTime}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <div className={"text-right mt-3"}>
                                                <Button
                                                    size={"md"}
                                                    color={"light"}
                                                    onClick={() => this.closeCourierSlotChangeDialog()}
                                                >
                                                    Отмена
                                                </Button>
                                                <Button
                                                    type={"submit"}
                                                    className={"ml-2"}
                                                    size={"md"}
                                                    color={"primary"}
                                                    disabled={changeLoading}>
                                                    {changeLoading && <Spinner size="sm"/>}
                                                    Сохранить
                                                </Button>
                                            </div>
                                        </Form>)
                                }}
                            </Formik>
                        </ModalBody>}
                    </Modal>
                </div>
            )
        }

        return (
            <Empty>
                <h3>Список смен курьеров пуст</h3>
                <p className={'mb-0'}>Измените фильтр</p>
            </Empty>
        );
    }
}

const mapStateToProps = ({couriersShifts, courierSlot, auth}: AppState, props: RouteComponentProps) => {
    const {data, total, by, loading, error} = couriersShifts;
    const changeLoading = courierSlot.loading;
    const changeSuccess = courierSlot.success;

    return {
        page: couriersShiftsPageReSelector(props),
        data,
        total,
        by,
        loading,
        error,
        changeLoading,
        changeSuccess,
        filter: couriersShiftsFilterReSelector(props),
        accesses: auth.user !== null ? auth.user.accesses : undefined
    }
}

const mapDispatchToProps = {
    getCouriersShifts,
    changeCourierSlot
}

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(CouriersShiftsContainer)
