import React, {Component} from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {IDictionary} from "../models/Dictionary";
import EmployeeAdd from "./EmployeeAdd";
import {AppState} from "../store/reducers";
import {closeEmployeeForm, openEmployeeForm} from "../store/actions/employeeForm";


type EmployeeNavbarType = {
    openEmployeeForm: (mode:'add') => void
    closeEmployeeForm: () => void
    roles: IDictionary[]
    mode: 'add' | 'edit' | 'delete' | 'changePassword' | null
};

class EmployeeNavbar extends Component<EmployeeNavbarType> {

    render() {
        const {mode, openEmployeeForm, closeEmployeeForm, roles} = this.props;
            return (
                    <div className={'mb-2'}>
                        <Button
                            color={'primary'}
                            onClick={() => openEmployeeForm('add')}>
                            Добавить сотрудника
                        </Button>
                        <Modal isOpen={mode === 'add'} size={'lg'} toggle={closeEmployeeForm}>
                            <ModalHeader toggle={closeEmployeeForm}>
                                Новый сотрудник
                            </ModalHeader>
                            <ModalBody>
                                <EmployeeAdd roles={roles} cancelHandler={closeEmployeeForm}/>
                            </ModalBody>
                        </Modal>
                    </div>
            );
    }
}


const mapStateToProps = (state: AppState) => {
    const {mode} = state.employeeForm;
    return {
        mode
    }
};

const mapDispatchToProps = {
    openEmployeeForm,
    closeEmployeeForm
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeNavbar);
