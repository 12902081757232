import {Api} from "./Api";
import {ISalaryFilterForm} from "../models/Salary";

export class SalaryService {
    getSalary(cityId: number, page: number = 1, form?: ISalaryFilterForm, group = false) {
        return new Api().call(`/v1/manage/salary/search?page=${page}&cityId=${cityId}${group ? '&group=true' : ''}`, {method: 'POST', data: form});
    }

    getSalaryTotal(cityId: number, form?: ISalaryFilterForm) {
        return new Api().call(`/v1/manage/salary/summary?cityId=${cityId}`, {method: 'POST', data: form});
    }

    paySalary(id: number) {
        return new Api().call(`v1/manage/salary/${id}/pay`, {method: 'GET'});
    }
    
    payGroupSalary(ids: number[]) {
        return new Api().call(`v1/manage/salary/pay-bulk`, {method: 'POST', data: ids})
    }
}
