import {ISlotChange} from "../../models/Slot";
import {call, put, takeLatest} from "redux-saga/effects";
import {changeCourierSlotError, changeCourierSlotRequest, changeCourierSlotSuccess} from "../actions/courierShift";
import {CourierService} from "../../services/CourierService";
import {CHANGE_COURIER_SLOT} from "../constants/courierSlot";

type changeCourierSlotSagaType = {
    type: typeof CHANGE_COURIER_SLOT
    payload: {
        id: number,
        form: ISlotChange
    }
}

function* changeCourierSlotSaga({payload}: changeCourierSlotSagaType) {
    try {
        yield put(changeCourierSlotRequest());

        const service = new CourierService();

        const promise = yield call(service.changeCourierSlot, payload.id, payload.form);

        yield put(changeCourierSlotSuccess());

    } catch (error) {
        yield put(changeCourierSlotError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchChangeCourierSlot() {
    yield takeLatest(CHANGE_COURIER_SLOT, changeCourierSlotSaga);
}