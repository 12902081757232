import {
    ADD_SCHEDULE_FORM,
    ADD_SCHEDULE_FORM_REQUEST,
    ADD_SCHEDULE_FORM_SUCCESS,
    ADD_SCHEDULE_FORM_ERROR,
    GET_SCHEDULE_FORM,
    GET_SCHEDULE_FORM_REQUEST,
    GET_SCHEDULE_FORM_SUCCESS,
    GET_SCHEDULE_FORM_ERROR,
    COPY_SCHEDULE_FORM_REQUEST,
    COPY_SCHEDULE_FORM_SUCCESS,
    COPY_SCHEDULE_FORM_ERROR,
    COPY_SCHEDULE_FORM
} from '../constants/scheduleForm'
import {IAction} from "./index";
import {ISchedule, IScheduleAddFormRequest, IScheduleCopyFormRequest} from '../../models/Schedule';



export interface IActionScheduleForm extends IAction<symbol, any> {

}


export const addScheduleFormRequest = () => {
    return {
        type: ADD_SCHEDULE_FORM_REQUEST
    }
};

export const addScheduleFormSuccess = (payload: ISchedule) => {
    return {
        type: ADD_SCHEDULE_FORM_SUCCESS,
        payload
    }
};

export const addScheduleFormError = (payload: string) => {
    return {
        type: ADD_SCHEDULE_FORM_ERROR,
        payload
    }
};

export const addScheduleForm = (payload: IScheduleAddFormRequest) => {
    return {
        type: ADD_SCHEDULE_FORM,
        payload
    };
};

export const getScheduleFormRequest = () => {
    return {
        type: GET_SCHEDULE_FORM_REQUEST
    }
};

export const getScheduleFormSuccess = (payload: ISchedule) => {
    return {
        type: GET_SCHEDULE_FORM_SUCCESS,
        payload
    }
};

export const getScheduleFormError = (payload: string) => {
    return {
        type: GET_SCHEDULE_FORM_ERROR,
        payload
    }
};

export const getScheduleForm = (payload: number) => {
    return {
        type: GET_SCHEDULE_FORM,
        payload
    };
};

export const copyScheduleFormRequest = () => {
    return {
        type: COPY_SCHEDULE_FORM_REQUEST
    }
};

export const copyScheduleFormSuccess = (payload: ISchedule) => {
    return {
        type: COPY_SCHEDULE_FORM_SUCCESS,
        payload
    }
};

export const copyScheduleFormError = (payload: string) => {
    return {
        type: COPY_SCHEDULE_FORM_ERROR,
        payload
    }
};

export const copyScheduleForm = (id:number, form: IScheduleCopyFormRequest) => {
    return {
        type: COPY_SCHEDULE_FORM,
        payload:{
            id,
            form
        }
    };
};

