import {GET_COURIER_RATE_ERROR, GET_COURIER_RATE_REQUEST, GET_COURIER_RATE_SUCCESS} from "../constants/courierRate";
import {ICourierRate} from "../../models/Courier";
import {IActionCourierRate} from "../actions/courierRate";

export interface ICourierRateState {
    data: ICourierRate[],
    loading: boolean,
    error: string
}

const initialState: ICourierRateState = {
    data: [],
    loading: false,
    error: ''
}

export default function courierRate(state = initialState, action: IActionCourierRate): ICourierRateState {
    switch (action.type) {
        case GET_COURIER_RATE_REQUEST:
            return {
                ...state,
                data: [],
                loading: true,
                error: ''
            };
        case GET_COURIER_RATE_SUCCESS:
            const {data} = action.payload;
            return {
                ...state,
                data,
                loading: false,
                error: ''
            }
        case GET_COURIER_RATE_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}