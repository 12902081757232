import React, {PureComponent} from 'react';
import Icon from '../UI/Icon';
import {ButtonGroup, Button} from "reactstrap";
import {IPointZone} from "../../models/Point";
import FormatPrice from '../UI/FormatPrice';
import Table from '../UI/Table';
import {accessPointEdit} from "../../utils/user-accesses";
import {
  pointZoneTypeIcons
} from "../../utils/point-zones";


type PointZonesType = {
    editHandler: (id: number) => void
    deleteHandler: (id: number) => void
    data: IPointZone[]
    accesses?:string[]
}

class PointZones extends PureComponent<PointZonesType> {
    render() {
        const {data, editHandler, deleteHandler, accesses} = this.props;
        return (
            <Table>
                <tbody>
                {data.map((data: IPointZone) => (
                        <PointZonesItem
                            key={data.id}
                            data={data}
                            editHandler={editHandler}
                            deleteHandler={deleteHandler}
                            accesses={accesses}
                        />
                    )
                )}
                </tbody>
            </Table>
        )
    }
}

type PointZonesItemType = {
    data: IPointZone
    editHandler: (id: number) => void
    deleteHandler: (id: number) => void
    accesses?:string[]
}

class PointZonesItem extends PureComponent<PointZonesItemType> {

    editHandler = () =>{
        const {data, editHandler} = this.props;
        const {id} = data;
        editHandler(id)
    };

    deleteHandler = () =>{
        const {data, deleteHandler} = this.props;
        const {id} = data;
        deleteHandler(id)
    };


    render() {
        const {data, accesses} = this.props;
        const {name, description, typeId, deliveryTime, deliveryPrice, courierBonus, status} = data;

        const buttonWidth = Array.isArray(accesses) && accesses.includes(accessPointEdit) ? 84 : 0;

        const typeIcon = pointZoneTypeIcons[typeId];
        
        return (
            <tr>
                <td style={{width: 40}}>
                    <Icon name={typeIcon} color={'gray-500'}/>
                </td>
                <td>
                    <b className="d-block">{name}</b>
                    {description}
                </td>
                <td>
                    <div className={'gray-500'}>
                        Максимальное время доставки
                    </div>
                    <div className={'semi-bold'}>{deliveryTime} минут</div>
                </td>
                <td>
                    <div className={'gray-500'}>
                        Сумма доставки
                    </div>
                    <div className={'semi-bold'}><FormatPrice value={deliveryPrice}/></div>
                </td>
                <td>
                    <div className={'gray-500'}>
                        Надбавка курьеру
                    </div>
                    <div className={'semi-bold'}><FormatPrice value={courierBonus ?? 0}/></div>
                </td>
                <td>
                    <div className={'gray-500'}>
                        Статус
                    </div>
                    {status ? <div className={'semi-bold green'}>Включен</div> :
                        <div className={'semi-bold red'}>Отключен</div>}
                </td>
                <td style={{width: 24 + buttonWidth}}>
                    <ButtonGroup>
                        {Array.isArray(accesses) && accesses.includes(accessPointEdit) &&
                        <Button size={'sm'} color={'outline-light'} onClick={this.editHandler}>
                            <Icon name={'edit'} color={'gray-500'}/>
                        </Button>}
                        {Array.isArray(accesses) && accesses.includes(accessPointEdit) &&
                        <Button size={'sm'} color={'outline-light'} onClick={this.deleteHandler}>
                            <Icon name={'delete'} color={'gray-500'}/>
                        </Button>}
                    </ButtonGroup>
                </td>
            </tr>
        );
    }
}

export default PointZones;
