import {GET_PENALTIES_ERROR, GET_PENALTIES_REQUEST, GET_PENALTIES_SUCCESS} from "../constants/penalties";
import { IPenalty } from "../../models/Penalty";
import { IActionPenalties } from "../actions/penalties";


export interface IPenaltiesState {
    loading: boolean
    error: string
    data: IPenalty[]
}

const initialState: IPenaltiesState = {
    data: [],
    loading: false,
    error: ''
};

export default function penalties(state = initialState, action: IActionPenalties): IPenaltiesState {
    switch (action.type) {
        case GET_PENALTIES_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_PENALTIES_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_PENALTIES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
