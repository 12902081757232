export const ADD_COMPANY_CONTACT_FORM = Symbol('ADD_COMPANY_CONTACT_FORM');
export const ADD_COMPANY_CONTACT_FORM_REQUEST = Symbol('ADD_COMPANY_CONTACT_FORM_REQUEST');
export const ADD_COMPANY_CONTACT_FORM_SUCCESS = Symbol('ADD_COMPANY_CONTACT_FORM_SUCCESS');
export const ADD_COMPANY_CONTACT_FORM_ERROR = Symbol('ADD_COMPANY_CONTACT_FORM_ERROR');
export const EDIT_COMPANY_CONTACT_FORM = Symbol('EDIT_COMPANY_CONTACT_FORM');
export const EDIT_COMPANY_CONTACT_FORM_REQUEST = Symbol('EDIT_COMPANY_CONTACT_FORM_REQUEST');
export const EDIT_COMPANY_CONTACT_FORM_SUCCESS = Symbol('EDIT_COMPANY_CONTACT_FORM_SUCCESS');
export const EDIT_COMPANY_CONTACT_FORM_ERROR = Symbol('EDIT_COMPANY_CONTACT_FORM_ERROR');
export const GET_COMPANY_CONTACT_FORM = Symbol('GET_COMPANY_CONTACT_FORM');
export const GET_COMPANY_CONTACT_FORM_REQUEST = Symbol('GET_COMPANY_CONTACT_FORM_REQUEST');
export const GET_COMPANY_CONTACT_FORM_SUCCESS = Symbol('GET_COMPANY_CONTACT_FORM_SUCCESS');
export const GET_COMPANY_CONTACT_FORM_ERROR = Symbol('GET_COMPANY_CONTACT_FORM_ERROR');
export const DELETE_COMPANY_CONTACT_FORM = Symbol('DELETE_COMPANY_CONTACT_FORM');
export const DELETE_COMPANY_CONTACT_FORM_REQUEST = Symbol('DELETE_COMPANY_CONTACT_FORM_REQUEST');
export const DELETE_COMPANY_CONTACT_FORM_SUCCESS = Symbol('DELETE_COMPANY_CONTACT_FORM_SUCCESS');
export const DELETE_COMPANY_CONTACT_FORM_ERROR = Symbol('DELETE_COMPANY_CONTACT_FORM_ERROR');
export const OPEN_COMPANY_CONTACT_FORM = Symbol('OPEN_COMPANY_CONTACT_FORM');
export const CLOSE_COMPANY_CONTACT_FORM = Symbol('CLOSE_COMPANY_CONTACT_FORM');
