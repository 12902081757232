import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import ProfileFull from "../components/ProfileFull";
import {compose} from "redux";
import {IAuth} from "../models/Auth";
import {logout} from "../store/actions/auth";


type ProfileFullType = {
    user: IAuth
    logout: () => void
}

class ProfileFullContainer extends Component<ProfileFullType> {
    render() {
        const {user, logout} = this.props;
        return <ProfileFull user={user} logout={logout}/>
    }
}


const mapStateToProps = ({auth}: AppState) => {
    const {user} = auth;
    return {
        user
    }
};

const mapDispatchToProps = {
    logout
};


export default compose<React.ComponentClass>(connect(mapStateToProps, mapDispatchToProps))(ProfileFullContainer);
