import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {IPointZone, IPointZoneEditFormRequest} from "../models/Point";
import {editPointZoneForm, getPointZoneForm} from "../store/actions/pointZoneForm";
import PointZoneEdit from "../components/PointZoneEdit";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";


type PointZoneEditType = {
    getPointZoneForm: (id: number) => void
    editPointZoneForm: (id: number, formId: number, form: IPointZoneEditFormRequest) => void
    cancelHandler?: () => void
    id: number
    formId: number
    data: IPointZone | null
    getLoading:boolean
    getError:string
    changeLoading:boolean
    changeError:string
}


class PointZoneEditContainer extends Component<PointZoneEditType> {
    componentDidMount() {
        const {formId, getPointZoneForm} = this.props;
        getPointZoneForm(formId);
    }

    render() {
        const {id, formId, data, getLoading, getError, editPointZoneForm, changeLoading, changeError, cancelHandler, getPointZoneForm} = this.props;

        if (getLoading) {
            return <Loader/>
        }
        if (getError) {
            return <Error error={getError} refresh={() => getPointZoneForm(formId)}/>
        }
        if (data !== null) {
            return <PointZoneEdit
                editPointZoneForm={(form: IPointZoneEditFormRequest) => editPointZoneForm(id,formId, form)}
                data={data}
                changeLoading={changeLoading}
                changeError={changeError}
                cancelHandler={cancelHandler}/>
        }

        return null;
    }

}

const mapStateToProps = (state: AppState) => {
    const {data,getLoading, getError, changeLoading, changeError} = state.pointZoneForm;

    return {
        data,
        getLoading,
        getError,
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    editPointZoneForm,
    getPointZoneForm
};


export default connect(mapStateToProps, mapDispatchToProps)(PointZoneEditContainer);
