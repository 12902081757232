import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import Empty from "../components/UI/Empty";
import {getScheduleDate} from "../store/actions/scheduleDate";
import {ISchedule} from "../models/Schedule";
import ScheduleDate from "../components/ScheduleDate";
import {Button, Card, Row, Col} from "reactstrap";
import {
    copyScheduleDateForm,
    openScheduleDateForm
} from "../store/actions/scheduleDateForm";
import {getDictionaryPoints} from "../store/actions/dictionaryPoints";
import {scheduleDateReSelector} from "../store/reselectors/scheduleDate";
import _ from "lodash";
import ScheduleDateCopy from "../components/ScheduleDateCopy";


type ScheduleDateType = {
    id: number
    cityId: number
    date: string
    isActive: boolean
    scheduleWeek: {
        [key: string]: string
    }
} & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type confirmCouriersType = Pick<ISchedule, 'confirmCouriers'>;

class ScheduleDateContainer extends Component<ScheduleDateType & confirmCouriersType> {

    componentDidMount() {
        const {id, getScheduleDate, date, isActive, getDictionaryPoints, cityId} = this.props;
        getDictionaryPoints(cityId);
        getScheduleDate(id, date, isActive)
    }

    componentDidUpdate(prevProps: Readonly<ScheduleDateType & confirmCouriersType>) {
        const {id, getScheduleDate, date, isActive} = this.props;
        if (prevProps.isActive !== this.props.isActive) {
            getScheduleDate(id, date, isActive)
        }
    }
    render() {
        const {
            data,
            loading,
            error,
            getScheduleDate,
            id,
            date,
            isActive,
            cityId,
            points,
            pointsLoading,
            pointsError,
            getDictionaryPoints,
            confirmCouriers,
            openScheduleDateForm,
            scheduleWeek,
            copyScheduleDateForm,
            changeLoading,
            changeError,
        } = this.props;

        if (loading || pointsLoading) {
            return <Loader/>
        }
        if (error) {
            return <Error error={error} refresh={() => getScheduleDate(id, date, isActive)}/>
        }

        if (pointsError) {
            return <Error error={pointsError} refresh={() => getDictionaryPoints(cityId)}/>
        }

        if (!_.isEmpty(data) && !!points.length) {
            return (
                <div>
                    {!isActive &&
                    <Button
                        color={'light'}
                        className={'mb-4'}
                        onClick={() => openScheduleDateForm('edit')}
                    >
                        Редактировать
                    </Button>}
                    <ScheduleDate
                        data={data}
                        points={points}
                        confirmCouriers={confirmCouriers}
                        showSlot={isActive}
                    />
                </div>
            )
        }

        return (
            <>
                {isActive ?
                    <Empty>
                        <h3>Не запланировано ни одного графика работы</h3>
                        <p className={'mb-0'}>Чтобы добавить график работы, переключитесь на режим «Черновик»</p>
                    </Empty>
                    :
                    <div className={'text-center'}>
                        <h4 className={'mb-3'}>Не запланировано ни одного графика работы</h4>
                        <Row>
                            <Col md={'6'}>
                                <Card body>
                                    <div className={'text-center'}>
                                        <p className={'mb-2'}>Создайте новый график работы</p>
                                        <Button color={'info'} onClick={() => openScheduleDateForm('add')}>
                                            Добавить новый график
                                        </Button>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={'6'}>
                                <Card body>
                                    <div className={'text-center'}>
                                        <p className={'mb-2'}>Выберите день недели и скопируйте существующий график</p>
                                        <Row className="justify-content-center">
                                            <Col md={'8'}>
                                                <ScheduleDateCopy
                                                    scheduleWeek={scheduleWeek}
                                                    dateTo={date}
                                                    changeLoading={changeLoading}
                                                    changeError={changeError}
                                                    copyScheduleDateForm={(form) => copyScheduleDateForm(id, form)}/>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </>
        )
    }
}


const mapStateToProps = (state: AppState) => {
    const {scheduleDate, scheduleDateForm, dictionaryPoints} = state;

    return {
        data: scheduleDateReSelector(state),
        loading: scheduleDate.loading,
        error: scheduleDate.error,
        points: dictionaryPoints.data,
        pointsLoading: dictionaryPoints.loading,
        pointsError: dictionaryPoints.error,
        changeLoading: scheduleDateForm.changeLoading,
        changeError: scheduleDateForm.changeError
    }
};

const mapDispatchToProps = {
    getScheduleDate,
    copyScheduleDateForm,
    openScheduleDateForm,
    getDictionaryPoints,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDateContainer);
