/**
 * Константа добавления уведомления
 * @type {symbol}
 */
export const ADD_NOTIFY = Symbol('notify/ADD_NOTIFY');

/**
 * Константа удаления уведомления
 * @type {symbol}
 */
export const REMOVE_NOTIFY = Symbol('notify/REMOVE_NOTIFY');
