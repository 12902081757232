import {CHANGE_BONUS_RATE, GET_BONUS_RATE} from "../constants/bonusRate";
import {
    changeBonusRateError,
    changeBonusRateRequest, changeBonusRateSuccess,
    getBonusRateError,
    getBonusRateRequest,
    getBonusRateSuccess
} from "../actions/bonusRate";
import {call, put, takeLatest} from 'redux-saga/effects';
import {CourierService} from "../../services/CourierService";
import {ICourierBonusRate} from "../../models/Courier";

type getBonusRateType = {
    type: typeof GET_BONUS_RATE
    payload: {
        cityId: number
    }
}

function* getBonusRateSaga({payload}: getBonusRateType) {
    try {
        yield put(getBonusRateRequest());

        const service = new CourierService();

        const promise = yield call(service.getCourierBonusRate, payload.cityId);

        const data: ICourierBonusRate[] = promise.data || [];

        yield put(getBonusRateSuccess(data));

    } catch (error) {
        yield put(getBonusRateError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetBonusRate() {
    yield takeLatest(GET_BONUS_RATE, getBonusRateSaga);
}

type changeBonusRateType = {
    type: typeof CHANGE_BONUS_RATE
    payload: {
        cityId: number
        typeId: number
        rates: [
            {
                minQuantityOrder: number
                price: number
            }
        ]
    }
}

function* changeBonusRateSaga({payload}: changeBonusRateType) {
    try {
        yield put(changeBonusRateRequest(payload.typeId));

        const service = new CourierService();

        const promise = yield call(service.changeCourierBonusRate, payload.cityId, payload.typeId, payload.rates);

        yield put(changeBonusRateSuccess());
    } catch (error) {
        yield put(changeBonusRateError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchChangeBonusRate() {
    yield takeLatest(CHANGE_BONUS_RATE, changeBonusRateSaga);
}