import {call, put, takeLatest} from 'redux-saga/effects';
import {DELETE_NOTIFICATION, GET_NOTIFICATIONS} from "../constants/notifications";
import {
    deleteNotificationSuccess,
    getNotificationsError,
    getNotificationsRequest,
    getNotificationsSuccess
} from '../actions/notifications';
import {NotificationsService} from "../../services/NotificationsService";
import {INotification, INotificationFilterRequest} from "../../models/Notifications";
import {addNotify} from "../actions/notify";
import {Pagination} from "../../services/Api";
import {removeUrlParams} from "../../utils/history";


type getNotificationsType = {
    type: typeof GET_NOTIFICATIONS,
    payload:{
        page?:number,
        form?: INotificationFilterRequest
    }
}

function* getNotificationsSaga({payload}: getNotificationsType) {
    try {
        yield put(getNotificationsRequest());

        const service = new NotificationsService();

        const promise = yield call(service.getNotifications, payload.page, payload.form);

        const data: INotification[] = promise.data || [];
        const pagination = new Pagination(promise.headers).getData();

        yield put(getNotificationsSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
        yield put(getNotificationsError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetNotifications() {
    yield takeLatest(GET_NOTIFICATIONS, getNotificationsSaga);
}


type deleteNotificationType = {
    type: typeof DELETE_NOTIFICATION,
    payload: number
}

function* deleteNotificationSaga({payload}: deleteNotificationType) {
    try {
        const service = new NotificationsService();
        yield call(service.deleteNotification, payload);

        yield put(deleteNotificationSuccess(payload));

    } catch (error) {
        yield put(addNotify(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeleteNotification() {
    yield takeLatest(DELETE_NOTIFICATION, deleteNotificationSaga);
}

