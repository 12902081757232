import React, {Component} from "react";
import {connect} from "react-redux";
import PointZonesSettingsForm from "../components/PointZonesSettingsForm";
import PointZonesSettingsList from "../components/PointZonesSettingsList";
import {AppState} from "../store/reducers";
import {getPointZones} from "../store/actions/pointZones";
import {
    editPointZoneForm,
    getJsonPointZoneForm,
    setJsonPointZoneForm,
    openPointZoneForm,
    closePointZoneForm
} from "../store/actions/pointZoneForm";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import PointZoneAdd from "./PointZoneAdd";
import {IPointZone, IPointZoneEditFormRequest} from "../models/Point";


type PointZonesSettingsType = {
    getPointZones: (id: number) => void
    closePointZoneForm: () => void
    openPointZoneForm: ( mode: 'add') => void
    editPointZoneForm: (id: number, formId: number, form: IPointZoneEditFormRequest) => void
    getJsonPointZoneForm: (form: { name: string, polygon: number[][] }[]) => void
    setJsonPointZoneForm: (form: { name: string, polygon: number[][] }) => void
    data: IPointZone[]
    jsonData: { name: string, polygon: number[][] }[]
    jsonActiveData: { name: string, polygon: number[][] } | null
    id: number
    mode: 'add' | 'edit' | 'delete' | null
}

class PointZonesSettingsContainer extends Component<PointZonesSettingsType> {

    componentDidMount() {
        const {id, getPointZones} = this.props;
        getPointZones(id)
    }

    render() {
        const {
            data, id, mode, jsonData, getJsonPointZoneForm,
            setJsonPointZoneForm, jsonActiveData,
            closePointZoneForm, openPointZoneForm, editPointZoneForm
        } = this.props;

        const polygon:number[][] | undefined = jsonActiveData !== null ? jsonActiveData.polygon: undefined;

        return (
            <>
                <PointZonesSettingsForm getData={getJsonPointZoneForm}/>
                {!!jsonData.length && <div className={'mt-4'}>
                    <PointZonesSettingsList
                        jsonData={jsonData}
                        data={data}
                        addHandler={(form: { name: string, polygon: number[][] }) => {
                            setJsonPointZoneForm(form);
                            openPointZoneForm('add')
                        }}
                        editHandler={(formId: number, form: { name: string, polygon: number[][] }) => {
                            setJsonPointZoneForm(form);
                            editPointZoneForm(id, formId, {polygon: form.polygon})
                        }}/>
                </div>}
                <Modal isOpen={mode === 'add' && !!polygon} toggle={closePointZoneForm}>
                    <ModalHeader toggle={closePointZoneForm}>
                      Новая зона доставки
                    </ModalHeader>
                    <ModalBody>
                        {polygon && <PointZoneAdd id={id} polygon={polygon} cancelHandler={closePointZoneForm}/>}
                    </ModalBody>
                </Modal>
            </>
        )
    }
}


const mapStateToProps = ({pointZones, pointZoneForm}: AppState) => {
    const {data} = pointZones;
    const {jsonData, mode, jsonActiveData} = pointZoneForm;
    return {
        data,
        jsonData,
        mode,
        jsonActiveData
    }
};

const mapDispatchToProps = {
    getPointZones,
    setJsonPointZoneForm,
    editPointZoneForm,
    getJsonPointZoneForm,
    openPointZoneForm,
    closePointZoneForm
};


export default connect(mapStateToProps, mapDispatchToProps)(PointZonesSettingsContainer);
