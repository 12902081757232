import {IDictionary} from "../models/Dictionary";

type DictionariesType = {
    [key: string]: string
};

export const getDictionaryOptions = (dictionary: DictionariesType[]) => {
    return !!dictionary.length ? dictionary.map(({key, value}) => ({label: value, value: key})) : []
};

export const sortDictionaryByPeriod = (dictionary: DictionariesType[]) => {
    return !!dictionary.length ? dictionary.sort((a: IDictionary, b: IDictionary) => {
        const regex = /\d+/g;
        const matchesA = parseInt(a.value.match(regex)[0]);
        const matchesB = parseInt(b.value.match(regex)[0]);

        if (matchesA > matchesB) {
            return -1
        }

        if (matchesA == matchesB) {
            return 1
        }

        return 0
    }) : []
}

export const getDictionary = (dictionary: DictionariesType[], key:string | number) => {
    if(!dictionary.length){
        return undefined
    }
    return dictionary.find((o) => o.key.toString() === key.toString());
};
