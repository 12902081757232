import React, {PureComponent} from 'react';
import {
    Alert,
    Button,
    Form,
    Spinner,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Row,
    Col
} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import {sanitizeForm} from "../../utils/sanitize-form";
import {IOrderChangeCoordinateRequest} from "../../models/Order";
import NumberFormat from "react-number-format";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import Avatar from "../UI/Avatar";


const schema = yup.object({
    lat: yup.number().required(requiredMessage()),
    long: yup.number().required(requiredMessage())
});

type OrderChangeCoordinateType = {
    id: string
    cancelHandler?: () => void
    changeCoordinateOrderForm: (id: string, form: IOrderChangeCoordinateRequest) => void
    changeLoading: boolean
    changeError: string
    data: {
        lat?: number
        long?: number
    }
    address: string
}

class OrderChangeCoordinate extends PureComponent<OrderChangeCoordinateType> {
    render() {
        const {id, changeCoordinateOrderForm, changeLoading, changeError, cancelHandler, data, address} = this.props;

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const lat = sanitizeForm(values.lat);
                    const long = sanitizeForm(values.long);

                    const form = {
                        lat,
                        long
                    };

                    changeCoordinateOrderForm(id, form)
                }}
                initialValues={{
                    lat: data.lat,
                    long: data.long
                }}
            >
                {({
                      handleSubmit,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>Широта <span className={'required'}>*</span></Label>
                            <NumberFormat
                                name={`lat`}
                                value={values.lat}
                                onValueChange={({floatValue}) => setFieldValue('lat', floatValue)}
                                invalid={touched.lat && !!errors.lat}
                                customInput={Input}
                            />
                            <FormFeedback>{errors.lat}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Долгота <span className={'required'}>*</span></Label>
                            <NumberFormat
                                name={`long`}
                                value={values.long}
                                onValueChange={({floatValue}) => setFieldValue('long', floatValue)}
                                invalid={touched.long && !!errors.long}
                                customInput={Input}
                            />
                            <FormFeedback>{errors.long}</FormFeedback>
                        </FormGroup>
                        <div className={'mb-3'}>
                            <Row form className="align-items-center">
                                <Col md={'auto'}>
                                    <Avatar icon={'place'}/>
                                </Col>
                                <Col>
                                    <div className={'semi-bold'}>
                                        {address}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <FormGroup>
                            <YMaps>
                                <Map
                                    onClick={((e: any) => {
                                        setFieldValue('lat', e.get("coords")[0]);
                                        setFieldValue('long', e.get("coords")[1])
                                    })}
                                    state={{
                                        center: values.lat && values.long ? [values.lat, values.long]: [],
                                        zoom: 15,
                                    }}
                                    className={'map'}>
                                    {values.lat && values.long && <Placemark geometry={[values.lat, values.long]}/>}
                                </Map>
                            </YMaps>
                        </FormGroup>

                        {changeError &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {changeError}
                        </Alert>)}
                        <div className={'text-right'}>
                            {cancelHandler &&
                            <Button color="light" onClick={cancelHandler} className={'mr-3'}>Отменить</Button>}
                            <Button type="submit" color={'primary'} disabled={changeLoading}>
                                {changeLoading &&
                                (<Spinner
                                    size="sm"
                                />)}
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default OrderChangeCoordinate;
