import {call, put, takeLatest} from 'redux-saga/effects';
import {GET_PENALTIES} from "../constants/penalties";
import {getPenaltiesError, getPenaltiesRequest, getPenaltiesSuccess} from '../actions/penalties';
import {IPenalty, IPenaltyFilterRequest, IPenaltySortRequest} from "../../models/Penalty";
import {PenaltyService} from "../../services/PenaltyService";


type getPenaltiesType = {
    type: typeof GET_PENALTIES,
    payload?: IPenaltyFilterRequest & IPenaltySortRequest
}

function* getPenaltiesSaga({payload}:getPenaltiesType) {
    try {
        yield put(getPenaltiesRequest());

        const service = new PenaltyService();
        const promise = yield call(service.getPenalties, payload);
        const response:IPenalty[] = promise.data || [];

        yield put(getPenaltiesSuccess(response));

    } catch (error) {
        yield put(getPenaltiesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetPenalties() {
    yield takeLatest(GET_PENALTIES, getPenaltiesSaga);
}
