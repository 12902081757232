import {
    GET_NOTIFICATIONS, SSE_NOTIFICATIONS_COUNT,
    GET_NOTIFICATIONS_ERROR,
    GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_REQUEST, DELETE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION
} from '../constants/notifications'
import {IAction} from "./index";
import {INotification, INotificationFilterRequest} from "../../models/Notifications";


export interface IActionNotifications extends IAction<symbol, any> {

}

export const getNotificationsRequest = () => {
    return {
        type: GET_NOTIFICATIONS_REQUEST
    }
};

export const getNotificationsSuccess = (data: INotification[], total: number, by: number) => {
    return {
        type: GET_NOTIFICATIONS_SUCCESS,
        payload:{
            data,
            total,
            by
        }
    }
};

export const getNotificationsError = (payload: string) => {
    return {
        type: GET_NOTIFICATIONS_ERROR,
        payload
    }
};

export const getNotifications = (page? : number, form?:INotificationFilterRequest) => {
    return {
        type: GET_NOTIFICATIONS,
        payload:{
            page,
            form
        }
    };
};

export const sseNotificationsCount = (payload:number) => {
    return {
        type: SSE_NOTIFICATIONS_COUNT,
        payload
    };
};

export const deleteNotificationSuccess = (payload:number) => {
    return {
        type: DELETE_NOTIFICATION_SUCCESS,
        payload
    };
};

export const deleteNotification = (payload:number) => {
    return {
        type: DELETE_NOTIFICATION,
        payload
    };
};
