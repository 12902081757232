import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import {changeStatusArchiveOrders, getArchiveOrders, toggleActiveIdArchiveOrders} from "../store/actions/archiveOrders";
import _ from "lodash";
import {archiveOrdersFilterReSelector} from "../store/reselectors/orders";
import Empty from "../components/UI/Empty";
import {getDictionaryOrderStatuses} from "../store/actions/dictionaryOrderStatuses";
import Orders from "../components/Orders";
import Pagination from "../components/UI/Pagination";
import queryString from "query-string";
import {reportCouriersPageReSelector} from "../store/reselectors/reportCouriers";
import {addOrderReturnCheck, deleteOrderReturnCheck} from "../store/actions/orderReturnCheck";

type ArchiveOrdersType = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class ArchiveOrdersContainer extends Component<ArchiveOrdersType & RouteComponentProps> {
    sse: any;

    componentDidMount() {
        const {getArchiveOrders, page, filter, getDictionaryOrderStatuses} = this.props;
        getDictionaryOrderStatuses()
        getArchiveOrders(page, !_.isEmpty({...filter}) ? {...filter,} : undefined)
    }


    componentDidUpdate(prevProps: Readonly<ArchiveOrdersType & RouteComponentProps>) {
        const {page,filter,getArchiveOrders} = this.props;

        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter)) {
            getArchiveOrders(page, !_.isEmpty({...filter}) ? {...filter,} : undefined)
        }
    }

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    fetchHandler = () => {
        const {page, getArchiveOrders, filter} = this.props;
        getArchiveOrders(page, !_.isEmpty(filter) ? filter : undefined)
    };

    render() {
        const {
            data,
            page,
            total,
            by,
            loading,
            error,
            dictionaryOrderStatuses,
            dictionaryOrderStatusesLoading,
            dictionaryOrderStatusesError,
            getDictionaryOrderStatuses,
            changeStatusArchiveOrders,
            toggleActiveIdArchiveOrders,
            activeId,
            accesses,
            allowedCities,
            addOrderReturnCheck,
            deleteOrderReturnCheck,
        } = this.props;

        if (loading || dictionaryOrderStatusesLoading) {
            return <Loader/>
        }

        if (dictionaryOrderStatusesError) {
            return <Error error={dictionaryOrderStatusesError} refresh={getDictionaryOrderStatuses}/>
        }

        if (error) {
            return <Error error={error} refresh={this.fetchHandler}/>
        }

        if (!!data.length && !!dictionaryOrderStatuses.length) {
            return (
                <div className={'mb-3'}>
                    <Orders
                        isArchive
                        changeStatus={changeStatusArchiveOrders}
                        data={data}
                        activeId={activeId}
                        accesses={accesses}
                        toggleActiveId={toggleActiveIdArchiveOrders}
                        dictionaryOrderStatuses={dictionaryOrderStatuses}
                        allowedCities={allowedCities}
                        addOrderReturnCheck={addOrderReturnCheck}
                        deleteOrderReturnCheck={deleteOrderReturnCheck}
                    />
                    <br />
                    <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>
                </div>
            )
        }
        return (
            <Empty>
                <h3>Список заказов в архиве пуст</h3>
                <p className={'mb-0'}>Измените фильтр</p>
            </Empty>
        )
    }
}


const mapStateToProps = ({archiveOrders, dictionaryOrderStatuses, auth}: AppState, props: RouteComponentProps) => {
    const {data, total, by, loading, error, activeId} = archiveOrders;
    return {
        page: reportCouriersPageReSelector(props),
        data,
        total,
        by,
        loading,
        error,
        activeId,
        filter: archiveOrdersFilterReSelector(props),
        dictionaryOrderStatuses: dictionaryOrderStatuses.data,
        dictionaryOrderStatusesLoading: dictionaryOrderStatuses.loading,
        dictionaryOrderStatusesError: dictionaryOrderStatuses.error,
        accesses: auth.user !== null ? auth.user.accesses : undefined,
        allowedCities: auth.user?.allowedCities || [],
    }
};

const mapDispatchToProps = {
    getArchiveOrders,
    getDictionaryOrderStatuses,
    changeStatusArchiveOrders,
    toggleActiveIdArchiveOrders,
    addOrderReturnCheck,
    deleteOrderReturnCheck,
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(ArchiveOrdersContainer);
