import {
    CHANGE_COURIER_ERROR,
    CHANGE_COURIER_REQUEST,
    CHANGE_COURIER_SUCCESS
} from "../constants/courierChange";
import {ICourier} from "../../models/Courier";
import {IActionCourierChange} from "../actions/courierChange";

export interface ICourierChangeState {
    data: ICourier[]
    loading: boolean
    error: string
}

const initialState: ICourierChangeState = {
    data: [],
    loading: false,
    error: ''
}

export default function courierChange(state = initialState, action: IActionCourierChange): ICourierChangeState {
    switch (action.type) {
        case CHANGE_COURIER_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case CHANGE_COURIER_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case CHANGE_COURIER_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}