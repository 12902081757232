import React, {PureComponent} from 'react';
import Dropdown from "../UI/Dropdown";
import {Col, Row} from "reactstrap";
import {IAuth} from "../../models/Auth";
import {Formik} from 'formik';
import {getCityOptions} from "../../utils/cities";


type CitiesType = Pick<IAuth, 'allowedCities'>;

type PickCityType = {
    setCityHandler: (cityId: number) => void
    cityId?: number
}

class PickCity extends PureComponent<PickCityType & CitiesType> {
    render() {
        const {setCityHandler, cityId, allowedCities} = this.props;

        const cityOptions = getCityOptions(allowedCities);

        return (
            <Formik
                enableReinitialize
                initialValues={{cityId: cityId}}
                onSubmit={(values) => {
                    values.cityId && setCityHandler(values.cityId);
                }}
            >
                {({setFieldValue, submitForm, values}) => (
                    <Row>
                        <Col md={'3'}>
                            <Dropdown
                                name={'cityId'}
                                value={values.cityId}
                                options={cityOptions}
                                placeholder={'Выберите город'}
                                onChange={(value) => {
                                    setFieldValue('cityId', value);
                                    submitForm()
                                }}
                            />
                        </Col>
                    </Row>
                )}
            </Formik>
        )
    }
}

export default PickCity;
