import {GET_COURIERS, GET_COURIERS_REQUEST, GET_COURIERS_SUCCESS, GET_COURIERS_ERROR} from '../constants/couriers'
import {IAction} from "./index";
import {ICourierFull} from "../../models/Courier";


export interface IActionCouriers extends IAction<symbol, any> {

}

export const getCouriersRequest = () => {
    return {
        type: GET_COURIERS_REQUEST
    }
};

export const getCouriersSuccess = (payload: ICourierFull[]) => {
    return {
        type: GET_COURIERS_SUCCESS,
        payload
    }
};

export const getCouriersError = (payload: string) => {
    return {
        type: GET_COURIERS_ERROR,
        payload
    }
};

export const getCouriers = (payload: number) => {
    return {
        type: GET_COURIERS,
        payload
    };
};

