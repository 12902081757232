import {Api} from "./Api";
import {ICityAddFormRequest, ICityEditFormRequest, ICityRequest} from "../models/City";


export class CityService {
    getCities(page: number = 1, form?: ICityRequest) {
        return new Api().call(`/v1/manage/city/search?page=${page}`, {method: 'POST', data: form});
    }
    getCity(id:number) {
        return new Api().call(`/v1/manage/city/${id}`, {method: 'GET'});
    }
    addCity(form: ICityAddFormRequest) {
        return new Api().call(`/v1/manage/city`, {method: 'POST', data: form});
    }
    editCity(id:number, form: ICityEditFormRequest) {
        return new Api().call(`/v1/manage/city/${id}`, {method: 'POST', data: form});
    }
    deleteCity(id:number) {
        return new Api().call(`/v1/manage/city/${id}`, {method: 'DELETE'});
    }
}
