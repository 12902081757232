import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {ISchedule, IScheduleDate} from "../models/Schedule";
import {cancelSchedule, getSchedule, publishSchedule} from "../store/actions/schedule";
import Schedule from "../components/Schedule";
import {closeScheduleDateForm} from "../store/actions/scheduleDateForm";
import _ from "lodash";


type ScheduleType = {
    getSchedule: (id: number) => void
    publishSchedule: (id: number) => void
    cancelSchedule: (id: number) => void
    closeScheduleDateForm: () => void
    id: number
    data: ISchedule | null
    getLoading: boolean
    getError: string
    publishLoading: boolean
    publishError: string
    scheduleData: IScheduleDate[]
    mode: 'add' | 'edit' | null
    cancelLoading: boolean
    cancelError: string
    accesses?: string[]
}

class ScheduleContainer extends Component<ScheduleType> {

    componentDidMount() {
        this.getScheduleHandler();
    }

    componentDidUpdate(prevProps: Readonly<ScheduleType>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.scheduleData.length !== 0 &&  !_.isEqual(prevProps.scheduleData, this.props.scheduleData)){
            this.getScheduleHandler();
        }
    }

    getScheduleHandler = () => {
        const {getSchedule, id} = this.props;
        getSchedule(id)
    };

    render() {
        const {
            data, getLoading, getError, publishLoading, publishError, getSchedule, id,
            closeScheduleDateForm, publishSchedule, mode,
            cancelLoading, cancelError, cancelSchedule, accesses
        } = this.props;

        if (getLoading) {
            return <Loader/>
        }
        if (getError) {
            return <Error error={getError} refresh={() => getSchedule(id)}/>
        }

        if (data !== null) {
            return (
                <Schedule
                    data={data}
                    mode={mode}
                    publishSchedule={publishSchedule}
                    publishLoading={publishLoading}
                    publishError={publishError}
                    cancelSchedule={cancelSchedule}
                    cancelLoading={cancelLoading}
                    cancelError={cancelError}
                    closeScheduleDateForm={closeScheduleDateForm}
                    accesses={accesses}
                />
            )
        }

        return null
    }
}


const mapStateToProps = ({schedule, scheduleDateForm, auth}: AppState) => {
    const {data, getLoading, getError, publishLoading, publishError, cancelLoading, cancelError} = schedule;
    const {user} = auth;

    return {
        data,
        getLoading,
        getError,
        publishLoading,
        publishError,
        mode: scheduleDateForm.mode,
        scheduleData: scheduleDateForm.data,
        cancelLoading,
        cancelError,
        accesses: user !== null ? user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getSchedule,
    publishSchedule,
    cancelSchedule,
    closeScheduleDateForm
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleContainer);
