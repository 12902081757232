import {
    GET_COMPANY_REQUEST,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_ERROR
} from '../constants/company'
import { ICompany } from '../../models/Company';
import {IActionCompany} from "../actions/company";


export interface ICompanyState {
    loading: boolean
    error: string
    data: ICompany | null
}


const initialState: ICompanyState = {
    data: null,
    loading: false,
    error: ''
};

export default function company(state = initialState, action: IActionCompany): ICompanyState {
    switch (action.type) {
        case GET_COMPANY_REQUEST:
            return {
                ...state,
                data: null,
                loading: true,
                error: ''
            };
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_COMPANY_ERROR:
            return {
                ...state,
                data: null,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
