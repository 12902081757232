import React, {PureComponent} from 'react';
import Dropdown from "../UI/Dropdown";
import {Alert, Button, Col, Form, Row, Spinner} from "reactstrap";
import {Formik} from 'formik';
import {sanitizeForm} from "../../utils/sanitize-form";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";

const schema = yup.object({
    dateFrom: yup.string().required(requiredMessage())
});

type ScheduleDateCopyType = {
    copyScheduleDateForm: ({dateFrom, dateTo}:{dateFrom:string, dateTo:string}) => void
    dateTo: string
    scheduleWeek: {
        [key: string]: string
    }
    changeLoading:boolean
    changeError:string
}

class ScheduleDateCopy extends PureComponent<ScheduleDateCopyType> {
    render() {
        const {copyScheduleDateForm, dateTo, scheduleWeek, changeLoading, changeError} = this.props;

        const scheduleWeekOptions = Object.keys(scheduleWeek)
            .map((label) => ({label, value: scheduleWeek[label]}))
            .filter(({value}) => value !== dateTo);

        return (
            <Formik
                validationSchema={schema}
                initialValues={{dateFrom: null}}
                onSubmit={(values) => {
                    const dateFrom = sanitizeForm(values.dateFrom);

                    const form = {
                        dateFrom,
                        dateTo
                    };

                    copyScheduleDateForm(form);
                }}
            >
                {({setFieldValue, values, handleSubmit, touched, errors}) => (
                    <Form onSubmit={handleSubmit}>
                        <Row form>
                            <Col>
                                <Dropdown
                                    name={'dateFrom'}
                                    value={values.dateFrom}
                                    options={scheduleWeekOptions}
                                    placeholder={'Выберите день недели'}
                                    onChange={(value) => setFieldValue('dateFrom', value)}
                                    invalid={touched.dateFrom && !!errors.dateFrom}
                                />
                            </Col>
                            <Col md={'auto'}>
                                <Button type={'submit'} color={'info'} disabled={changeLoading}>
                                    {changeLoading &&
                                    (<Spinner
                                        size="sm"
                                    />)}
                                    Копировать
                                </Button>
                            </Col>
                        </Row>
                        {changeError &&
                        (<Alert color={'danger'} className={'mt-3'}>
                            {changeError}
                        </Alert>)}
                    </Form>
                )}
            </Formik>
        )
    }
}

export default ScheduleDateCopy;
