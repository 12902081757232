import {Api} from "./Api";
import {
    IPointAddFormRequest,
    IPointEditFormRequest,
    IPointZoneAddFormRequest,
    IPointZoneEditFormRequest
} from "../models/Point";


export class PointService {
    getPoints(cityId?: number) {
        return new Api().call(`/v1/manage/point/search`, {method: 'POST', data: cityId && {cityId}});
    }

    getPoint(id: number) {
        return new Api().call(`/v1/manage/point/${id}`, {method: 'GET'});
    }

    addPoint(form: IPointAddFormRequest) {
        return new Api().call(`/v1/manage/point`, {method: 'POST', data: form});
    }

    editPoint(id: number, form: IPointEditFormRequest) {
        return new Api().call(`/v1/manage/point/${id}`, {method: 'POST', data: form});
    }

    deletePoint(id: number) {
        return new Api().call(`/v1/manage/point/${id}`, {method: 'DELETE'});
    }


    getPointZones(id: number) {
        return new Api().call(`/v1/manage/point/${id}`, {method: 'GET'});
    }

    getPointZone(id: number) {
        return new Api().call(`/v1/manage/point/delivery-zone/${id}`, {method: 'GET'});
    }

    addPointZone(id: number, form: IPointZoneAddFormRequest) {
        return new Api().call(`/v1/manage/point/${id}/delivery-zone`, {method: 'POST', data: form});
    }

    editPointZone(id: number, form: IPointZoneEditFormRequest) {
        return new Api().call(`/v1/manage/point/delivery-zone/${id}`, {method: 'POST', data: form});
    }

    deletePointZone(id: number) {
        return new Api().call(`/v1/manage/point/delivery-zone/${id}`, {method: 'DELETE'});
    }
}
