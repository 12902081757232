import React, {Component} from "react";
import {connect} from "react-redux";
import {getCouriers} from "../store/actions/couriers";
import {AppState} from "../store/reducers";
import Couriers from "../components/Couriers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import Empty from "../components/UI/Empty";
import {Redirect} from "react-router-dom";
import {IAuth} from "../models/Auth";
import _ from "lodash";
import {ICourierFull} from "../models/Courier";
import {couriersCityIdReSelector} from "../store/reselectors/couriers";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {closeCourierForm, openCourierForm} from "../store/actions/courierForm";
import CourierChangePoint from "./CourierChangePoint";
import CourierTrack from "./CourierTrack";


type CouriersType = {
    getCouriers: (cityId: number) => void
    openCourierForm: (mode: 'changePoint', modeId: number) => void
    closeCourierForm: () => void
    cityId: number
    data: ICourierFull[]
    loading: boolean
    error: string
    mode: 'changePoint' | null
    modeId: number | null
    accesses?: string[]
    courierId: number
    isCourierTrackDialog: boolean
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class CouriersContainer extends Component<CouriersType & CitiesType & RouteComponentProps> {
    state = {
        activePointId: -1,
        courierId: null!,
        isCourierTrackDialog: false

    };

    componentDidMount() {
        const {getCouriers, cityId, allowedCities} = this.props;
        getCouriers(cityId || allowedCities[0].id)
    }

    componentDidUpdate(prevProps: Readonly<CouriersType & CitiesType & RouteComponentProps>) {
        const {cityId, getCouriers, allowedCities} = this.props;
        if (prevProps.cityId !== cityId) {
            getCouriers(cityId || allowedCities[0].id)
        }
    }

    editHandler = (id: number, pointId: number) => {
        const {openCourierForm} = this.props;
        openCourierForm('changePoint', id);
        this.setState({activePointId: pointId})
    };

    linkHandler = (id: number) => {
        const {history} = this.props;
        history.push(`/couriers/${id}`)
    };

    courierTrackDialogHandler = (id: number) => {
        this.setState({
            isCourierTrackDialog: true,
            courierId: id
        })
    };

    closeCourierTrackDialogHandler = () => {
        this.setState({isCourierTrackDialog: false});
    }

    render() {
        const {activePointId, isCourierTrackDialog, courierId} = this.state;
        const {
            data, loading, error, cityId, allowedCities, getCouriers, modeId, mode, closeCourierForm,accesses
        } = this.props;


        if (loading) {
            return <Loader/>
        }
        if (error) {
            return <Error error={error} refresh={() => getCouriers(cityId || allowedCities[0].id)}/>
        }
        if (!cityId) {
            return <Redirect to={`/couriers?cityId=${allowedCities[0].id}`}/>
        }

        if (!!data.length) {
            return (
                <>
                    <div className={'mb-3'}>
                        <Couriers
                            data={data}
                            linkHandler={this.linkHandler}
                            editHandler={this.editHandler}
                            courierTrackDialogHandler={this.courierTrackDialogHandler}
                            accesses={accesses}
                        />
                    </div>
                    {modeId !== null && activePointId !== -1 &&
                    <Modal isOpen={mode === 'changePoint'} toggle={closeCourierForm}>
                        <ModalHeader toggle={closeCourierForm}>
                            Изменение отправной точки курьера
                        </ModalHeader>
                        <ModalBody>
                            <CourierChangePoint
                                id={modeId}
                                pointId={activePointId}
                                cityId={cityId}
                                cancelHandler={closeCourierForm}
                            />
                        </ModalBody>
                    </Modal>}
                    { isCourierTrackDialog &&
                        <Modal
                            isOpen={isCourierTrackDialog}
                            size={'xl'}
                            toggle={this.closeCourierTrackDialogHandler}>
                            <ModalHeader toggle={this.closeCourierTrackDialogHandler}>
                                Местоположение курьера
                            </ModalHeader>
                            <ModalBody>
                                <CourierTrack courierId={courierId} />
                            </ModalBody>
                        </Modal>
                    }
                </>
            )
        }

        return (
            <Empty>
                <h5 className={'mb-0'}>Список курьеров пуст</h5>
            </Empty>
        )
    }
}


const mapStateToProps = ({couriers, courierForm, auth}: AppState, props: RouteComponentProps) => {
    const {data, loading, error} = couriers;
    const {user} = auth;
    const {mode, modeId} = courierForm;

    return {
        allowedCities: _.get(user, 'allowedCities', []),
        data,
        cityId: couriersCityIdReSelector(props),
        loading,
        error,
        mode,
        modeId,
        accesses: user !== null ? user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getCouriers,
    openCourierForm,
    closeCourierForm
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(CouriersContainer);
