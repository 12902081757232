import {
    CHANGE_STATUS_ARCHIVE_ORDERS_SUCCESS,
    GET_ARCHIVE_ORDERS_ERROR,
    GET_ARCHIVE_ORDERS_REQUEST,
    GET_ARCHIVE_ORDERS_SUCCESS, REPLACE_IN_ARCHIVE_ORDERS,
    TOGGLE_ACTIVE_ARCHIVE_ORDERS
} from "../constants/archiveOrders";
import {IOrderShort} from "../../models/Order";
import {IActionArchiveOrders} from "../actions/archiveOrders";
import {replaceInData} from "../../utils/data-processing";

const changeData = (items: IOrderShort[], item: IOrderShort) => {
    const index = items.findIndex(({id}) => id === item.id);
    
    if (item.status > 0 && item.status < 100) {
        return [
            ...items.slice(0, index),
            ...items.slice(index + 1)
        ]
    }
    
    return [
        ...items.slice(0, index),
        item,
        ...items.slice(index + 1)
    ]
};

export interface IArchiveOrdersState {
    loading: boolean
    error: string
    data: IOrderShort[]
    activeId: string,
    total: number
    by: number
}

const initialState: IArchiveOrdersState = {
    data: [],
    loading: false,
    error: '',
    activeId: '',
    total: 0,
    by: 0
};

export default function archiveOrders(state = initialState, action: IActionArchiveOrders): IArchiveOrdersState {
    switch (action.type) {
        case GET_ARCHIVE_ORDERS_REQUEST:
            return {
                data: [],
                loading: true,
                error: '',
                activeId: '',
                total: 0,
                by: 0
            };
        case GET_ARCHIVE_ORDERS_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                data,
                loading: false,
                error: '',
                activeId: '',
                total,
                by
            };
        case GET_ARCHIVE_ORDERS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload,
                activeId: '',
                total: 0,
                by: 0
            };
        case CHANGE_STATUS_ARCHIVE_ORDERS_SUCCESS:
            return {
                ...state,
                data: changeData(state.data, action.payload),
                activeId: ''
            };
        case TOGGLE_ACTIVE_ARCHIVE_ORDERS:
            return {
                ...state,
                activeId: state.activeId !== action.payload ? action.payload : ''
            };
        case REPLACE_IN_ARCHIVE_ORDERS:
            return {
                ...state,
                data: replaceInData(state.data, action.payload),
            }
        default:
            return state;
    }
}
