import {GET_ROLES, GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GET_ROLES_ERROR} from '../constants/roles'
import {IAction} from "./index";
import {IRole} from "../../models/Role";


export interface IActionRoles extends IAction<symbol, any> {

}

export const getRolesRequest = () => {
    return {
        type: GET_ROLES_REQUEST
    }
};

export const getRolesSuccess = (payload: IRole[]) => {
    return {
        type: GET_ROLES_SUCCESS,
        payload
    }
};

export const getRolesError = (payload: string) => {
    return {
        type: GET_ROLES_ERROR,
        payload
    }
};

export const getRoles = () => {
    return {
        type: GET_ROLES
    };
};

