import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Companies from "./Companies";
import {accessCompanyCreate} from "../utils/user-accesses";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";


type CompanyOutputType = {
    accesses?: string[]
};


class CompanyOutput extends Component<CompanyOutputType> {

    render() {
        const {accesses} = this.props;

        return (
            <>
                {Array.isArray(accesses) && accesses.includes(accessCompanyCreate) &&
                <Link to={'/companies/add'}>
                    <Button color={'primary'} className={'mb-2'}>
                        Добавить контрагента
                    </Button>
                </Link>}
                <Companies/>
            </>
        );
    }
}


const mapStateToProps = (state: AppState) => {
    const {user} = state.auth;
    return {
        accesses: user !== null ? user.accesses : undefined
    }
};

export default connect(mapStateToProps, null)(CompanyOutput);
