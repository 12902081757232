import {GET_COURIERS_ERROR, GET_COURIERS_REQUEST, GET_COURIERS_SUCCESS} from "../constants/couriers";
import {ICourierFull} from "../../models/Courier";
import {IActionCouriers} from "../actions/couriers";


export interface ICouriersState {
    loading: boolean
    error: string
    data: ICourierFull[]
}

const initialState: ICouriersState = {
    data: [],
    loading: false,
    error: ''
};

export default function couriers(state = initialState, action: IActionCouriers): ICouriersState {
    switch (action.type) {
        case GET_COURIERS_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_COURIERS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_COURIERS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
