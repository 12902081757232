import {
    ADD_ROLE_FORM,
    ADD_ROLE_FORM_REQUEST,
    ADD_ROLE_FORM_SUCCESS,
    ADD_ROLE_FORM_ERROR,
    EDIT_ROLE_FORM_REQUEST,
    EDIT_ROLE_FORM_SUCCESS,
    EDIT_ROLE_FORM_ERROR,
    EDIT_ROLE_FORM,
    GET_ROLE_FORM,
    GET_ROLE_FORM_REQUEST,
    GET_ROLE_FORM_SUCCESS,
    GET_ROLE_FORM_ERROR,
    DELETE_ROLE_FORM_REQUEST,
    DELETE_ROLE_FORM_SUCCESS,
    DELETE_ROLE_FORM_ERROR,
    DELETE_ROLE_FORM,
    CLOSE_ROLE_FORM,
    OPEN_ROLE_FORM
} from '../constants/roleForm'
import {IAction} from "./index";
import {IRole, IRoleAddFormRequest, IRoleEditFormRequest} from "../../models/Role";


export interface IActionRoleForm extends IAction<symbol, any> {

}


export const addRoleFormRequest = () => {
    return {
        type: ADD_ROLE_FORM_REQUEST
    }
};

export const addRoleFormSuccess = (payload: IRole) => {
    return {
        type: ADD_ROLE_FORM_SUCCESS,
        payload
    }
};

export const addRoleFormError = (payload: string) => {
    return {
        type: ADD_ROLE_FORM_ERROR,
        payload
    }
};

export const addRoleForm = (payload: IRoleAddFormRequest) => {
    return {
        type: ADD_ROLE_FORM,
        payload
    };
};

export const editRoleFormRequest = () => {
    return {
        type: EDIT_ROLE_FORM_REQUEST
    }
};

export const editRoleFormSuccess = (payload: IRole) => {
    return {
        type: EDIT_ROLE_FORM_SUCCESS,
        payload
    }
};

export const editRoleFormError = (payload: string) => {
    return {
        type: EDIT_ROLE_FORM_ERROR,
        payload
    }
};

export const editRoleForm = (id: string, form: IRoleEditFormRequest) => {
    return {
        type: EDIT_ROLE_FORM,
        payload: {id, form}
    };
};

export const getRoleFormRequest = () => {
    return {
        type: GET_ROLE_FORM_REQUEST
    }
};

export const getRoleFormSuccess = (payload: IRole) => {
    return {
        type: GET_ROLE_FORM_SUCCESS,
        payload
    }
};

export const getRoleFormError = (payload: string) => {
    return {
        type: GET_ROLE_FORM_ERROR,
        payload
    }
};

export const getRoleForm = (payload: string) => {
    return {
        type: GET_ROLE_FORM,
        payload
    };
};

export const deleteRoleFormRequest = () => {
    return {
        type: DELETE_ROLE_FORM_REQUEST
    }
};

export const deleteRoleFormSuccess = () => {
    return {
        type: DELETE_ROLE_FORM_SUCCESS
    }
};

export const deleteRoleFormError = (payload: string) => {
    return {
        type: DELETE_ROLE_FORM_ERROR,
        payload
    }
};

export const deleteRoleForm = (payload: string) => {
    return {
        type: DELETE_ROLE_FORM,
        payload
    };
};

export const openRoleForm = (mode: 'add' | 'edit' | 'delete', modeId?: string) => {
    return {
        type: OPEN_ROLE_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closeRoleForm = () => {
    return {
        type: CLOSE_ROLE_FORM
    };
};
