import {call, put, takeLatest} from "redux-saga/effects";
import {
    getFilteredCouriersListError,
    getFilteredCouriersListRequest,
    getFilteredCouriersListSuccess
} from "../actions/couriersFilteredList";
import {CourierService} from "../../services/CourierService";
import {ICourier, ICourierFilter} from "../../models/Courier";
import {GET_FILTERED_COURIERS_LIST} from "../constants/courierChange";

type getFilteredCouriersListSagaType = {
    type: typeof GET_FILTERED_COURIERS_LIST
    payload?: {
        expand?: string
        body?: ICourierFilter
    }
}

function* getFilteredCouriersListSaga({payload}: getFilteredCouriersListSagaType) {
    try {

        yield put(getFilteredCouriersListRequest())

        const service = new CourierService()

        const promise = yield call(service.getFilteredCouriersList, payload?.expand, payload?.body)

        const response: ICourier = promise.data

        yield put(getFilteredCouriersListSuccess(response))

    } catch (error) {
        yield put(getFilteredCouriersListError(error.response.data.message || 'Ошибка'))
    }
}

export function* watchGetFilteredCouriersListSaga() {
    yield takeLatest(GET_FILTERED_COURIERS_LIST, getFilteredCouriersListSaga)
}