import {
    ADD_PENALTY_FORM,
    ADD_PENALTY_FORM_REQUEST,
    ADD_PENALTY_FORM_SUCCESS,
    ADD_PENALTY_FORM_ERROR,
    EDIT_PENALTY_FORM_REQUEST,
    EDIT_PENALTY_FORM_SUCCESS,
    EDIT_PENALTY_FORM_ERROR,
    EDIT_PENALTY_FORM,
    GET_PENALTY_FORM,
    GET_PENALTY_FORM_REQUEST,
    GET_PENALTY_FORM_SUCCESS,
    GET_PENALTY_FORM_ERROR,
    DELETE_PENALTY_FORM_REQUEST,
    DELETE_PENALTY_FORM_SUCCESS,
    DELETE_PENALTY_FORM_ERROR,
    DELETE_PENALTY_FORM, OPEN_PENALTY_FORM, CLOSE_PENALTY_FORM
} from '../constants/penaltyForm'
import {IAction} from "./index";
import {IPenalty, IPenaltyAddFormRequest, IPenaltyEditFormRequest} from "../../models/Penalty";


export interface IActionPenaltyForm extends IAction<symbol, any> {

}


export const addPenaltyFormRequest = () => {
    return {
        type: ADD_PENALTY_FORM_REQUEST
    }
};

export const addPenaltyFormSuccess = (payload: IPenalty) => {
    return {
        type: ADD_PENALTY_FORM_SUCCESS,
        payload
    }
};

export const addPenaltyFormError = (payload: string) => {
    return {
        type: ADD_PENALTY_FORM_ERROR,
        payload
    }
};

export const addPenaltyForm = (payload: IPenaltyAddFormRequest) => {
    return {
        type: ADD_PENALTY_FORM,
        payload
    };
};

export const editPenaltyFormRequest = () => {
    return {
        type: EDIT_PENALTY_FORM_REQUEST
    }
};

export const editPenaltyFormSuccess = (payload: IPenalty) => {
    return {
        type: EDIT_PENALTY_FORM_SUCCESS,
        payload
    }
};

export const editPenaltyFormError = (payload: string) => {
    return {
        type: EDIT_PENALTY_FORM_ERROR,
        payload
    }
};

export const editPenaltyForm = (id: number, form: IPenaltyEditFormRequest) => {
    return {
        type: EDIT_PENALTY_FORM,
        payload: {id, form}
    };
};

export const getPenaltyFormRequest = () => {
    return {
        type: GET_PENALTY_FORM_REQUEST
    }
};

export const getPenaltyFormSuccess = (payload: IPenalty) => {
    return {
        type: GET_PENALTY_FORM_SUCCESS,
        payload
    }
};

export const getPenaltyFormError = (payload: string) => {
    return {
        type: GET_PENALTY_FORM_ERROR,
        payload
    }
};

export const getPenaltyForm = (payload: number) => {
    return {
        type: GET_PENALTY_FORM,
        payload
    };
};

export const deletePenaltyFormRequest = () => {
    return {
        type: DELETE_PENALTY_FORM_REQUEST
    }
};

export const deletePenaltyFormSuccess = () => {
    return {
        type: DELETE_PENALTY_FORM_SUCCESS
    }
};

export const deletePenaltyFormError = (payload: string) => {
    return {
        type: DELETE_PENALTY_FORM_ERROR,
        payload
    }
};

export const deletePenaltyForm = (payload: number) => {
    return {
        type: DELETE_PENALTY_FORM,
        payload
    };
};

export const openPenaltyForm = (mode: 'add' | 'edit' | 'delete', modeId?: number) => {
    return {
        type: OPEN_PENALTY_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closePenaltyForm = () => {
    return {
        type: CLOSE_PENALTY_FORM
    };
};
