import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import Pagination from "../components/UI/Pagination";
import queryString from "query-string";
import {getReportCouriers} from "../store/actions/reportCouriers";
import {IReportCourier, IReportCourierFilterRequest, IReportCourierSortRequest} from "../models/Report";
import _ from "lodash";
import TableGrid from "../components/UI/TableGrid";
import Empty from "../components/UI/Empty";
import {
    reportCouriersFilterReSelector,
    reportCouriersSortReSelector,
    reportCouriersPageReSelector
} from "../store/reselectors/reportCouriers";
import {getDictionaryReportCourierPeriod} from "../store/actions/dictionaryReportCourierPeriod";
import {IDictionary} from "../models/Dictionary";
import {getDictionaryOptions, getDictionary, sortDictionaryByPeriod} from "../utils/dictionaries";
import {getDictionaryCouriers} from "../store/actions/dictionaryCouriers";
import {Link} from "react-router-dom";


type ReportCouriersType = {
    getReportCouriers: (page?: number, form?: IReportCourierFilterRequest & IReportCourierSortRequest) => void
    getDictionaryReportCourierPeriod: () => void
    getDictionaryCouriers: () => void
    page: number
    data: IReportCourier[]
    total: number
    by: number
    loading: boolean
    error: string
    filter?: IReportCourierFilterRequest,
    sort?: IReportCourierSortRequest
    dictionaryPeriod: IDictionary[]
    dictionaryPeriodLoading: boolean
    dictionaryPeriodError: string
    dictionaryCouriers: IDictionary[]
    dictionaryCouriersLoading: boolean
    dictionaryCouriersError: string
}


class ReportCouriersContainer extends Component<ReportCouriersType & RouteComponentProps> {

    componentDidMount() {
        const {getReportCouriers, page, filter, sort, getDictionaryReportCourierPeriod, getDictionaryCouriers} = this.props;
        getDictionaryReportCourierPeriod();
        getDictionaryCouriers();
        getReportCouriers(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
    }

    componentDidUpdate(prevProps: Readonly<ReportCouriersType & RouteComponentProps>) {
        const {page, filter, sort, getReportCouriers} = this.props;
        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter) || !_.isEqual(prevProps.sort, sort)) {
            getReportCouriers(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
        }
    }

    changeHandler = <T extends any>(form: T) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const params = _.pick(queryParams, ['periodId', 'courierId', 'orderBy']);

        const filter = _.pickBy({...params, ...form}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        const nextQueryParams = queryString.stringify(filter);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    filterHandler = (form: IReportCourierFilterRequest) => {
        this.changeHandler<IReportCourierFilterRequest>(form)
    };

    sortHandler = (form: IReportCourierSortRequest) => {
        const orderBy = form ? Object.keys(form)[0] + '_' + Object.values(form)[0] : undefined;
        this.changeHandler<{ [key: string]: string | undefined }>({orderBy})
    };

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    render() {
        const {
            data, loading, error, page, total, by, getReportCouriers, filter, sort,
            dictionaryPeriod, dictionaryPeriodLoading, dictionaryPeriodError, getDictionaryReportCourierPeriod,
            dictionaryCouriers, dictionaryCouriersLoading, dictionaryCouriersError, getDictionaryCouriers
        } = this.props;

        const filterData = filter;
        const sortData = sort && sort.orderBy;

        if (dictionaryPeriodLoading || dictionaryCouriersLoading) {
            return <Loader/>
        }
        if (dictionaryPeriodError) {
            return <Error
                error={dictionaryPeriodError}
                refresh={getDictionaryReportCourierPeriod}
            />
        }
        if (dictionaryCouriersError) {
            return <Error
                error={dictionaryCouriersError}
                refresh={getDictionaryCouriers}
            />
        }
        if (!!dictionaryPeriod.length && !!dictionaryCouriers.length) {
            const sortedDictionaryPeriod = sortDictionaryByPeriod(dictionaryPeriod);
            const periodOptions = getDictionaryOptions(sortedDictionaryPeriod);
            const courierOptions = getDictionaryOptions(dictionaryCouriers);
            return (
                <>
                    <div className={'mb-3'}>
                        <TableGrid
                            sourceData={data}
                            filterData={filterData}
                            sortData={sortData}
                            columns={[
                                {
                                    label: 'Период',
                                    key: 'periodId',
                                    filter: {
                                        type: 'select',
                                        options: periodOptions,
                                        handler: this.filterHandler
                                    },
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                    render: ({periodId}: IReportCourier) => {
                                        const period = getDictionary(dictionaryPeriod, periodId);
                                        return _.get(period, 'value');
                                    },
                                    width: 120
                                },
                                {
                                    label: 'Имя курьера',
                                    key: 'courierId',
                                    filter: {
                                        type: 'select',
                                        options: courierOptions,
                                        handler: this.filterHandler
                                    },
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                    render: ({courierId, courierName}: IReportCourier) => {
                                        return <Link to={`/couriers/${courierId}`}
                                                     target={'_blank'}>{courierName}</Link>
                                    }
                                },
                                {
                                    label: 'Количество часов',
                                    key: 'workTime',
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                },
                                {
                                    label: 'Количество заказов',
                                    key: 'countOrders',
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                },
                                {
                                    label: 'Штрафы',
                                    key: 'penalty',
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                },
                                {
                                    label: 'Сумма к оплате',
                                    key: 'income',
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                }
                            ]}
                        />
                        {loading && <Loader/>}
                        {error && <Error
                            error={error}
                            refresh={() => getReportCouriers(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)}
                        />}
                        {!data.length && !loading && !error &&
                        <Empty>
                            <h5 className={'mb-0'}>Список пуст</h5>
                        </Empty>}
                    </div>
                    <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>
                </>
            )
        }
        return (
            <Empty>
                <h5 className={'mb-0'}>Список пуст</h5>
            </Empty>
        )
    }
}


const mapStateToProps = ({reportCouriers, dictionaryReportCourierPeriod, dictionaryCouriers}: AppState, props: RouteComponentProps) => {
    const {data, total, by, loading, error} = reportCouriers;

    return {
        data,
        filter: reportCouriersFilterReSelector(props),
        sort: reportCouriersSortReSelector(props),
        page: reportCouriersPageReSelector(props),
        loading,
        error,
        total,
        by,
        dictionaryPeriod: dictionaryReportCourierPeriod.data,
        dictionaryPeriodLoading: dictionaryReportCourierPeriod.loading,
        dictionaryPeriodError: dictionaryReportCourierPeriod.error,
        dictionaryCouriers: dictionaryCouriers.data,
        dictionaryCouriersLoading: dictionaryCouriers.loading,
        dictionaryCouriersError: dictionaryCouriers.error
    }
};

const mapDispatchToProps = {
    getReportCouriers,
    getDictionaryReportCourierPeriod,
    getDictionaryCouriers
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(ReportCouriersContainer);
