import {
    ADD_CITY_FORM,
    ADD_CITY_FORM_REQUEST,
    ADD_CITY_FORM_SUCCESS,
    ADD_CITY_FORM_ERROR,
    EDIT_CITY_FORM_REQUEST,
    EDIT_CITY_FORM_SUCCESS,
    EDIT_CITY_FORM_ERROR,
    EDIT_CITY_FORM,
    GET_CITY_FORM,
    GET_CITY_FORM_REQUEST,
    GET_CITY_FORM_SUCCESS,
    GET_CITY_FORM_ERROR,
    DELETE_CITY_FORM_REQUEST,
    DELETE_CITY_FORM_SUCCESS,
    DELETE_CITY_FORM_ERROR,
    DELETE_CITY_FORM, OPEN_CITY_FORM, CLOSE_CITY_FORM
} from '../constants/cityForm'
import {IAction} from "./index";
import {ICity, ICityAddFormRequest, ICityEditFormRequest} from '../../models/City';


export interface IActionCityForm extends IAction<symbol, any> {

}


export const addCityFormRequest = () => {
    return {
        type: ADD_CITY_FORM_REQUEST
    }
};

export const addCityFormSuccess = (payload: ICity) => {
    return {
        type: ADD_CITY_FORM_SUCCESS,
        payload
    }
};

export const addCityFormError = (payload: string) => {
    return {
        type: ADD_CITY_FORM_ERROR,
        payload
    }
};

export const addCityForm = (payload: ICityAddFormRequest) => {
    return {
        type: ADD_CITY_FORM,
        payload
    };
};

export const editCityFormRequest = () => {
    return {
        type: EDIT_CITY_FORM_REQUEST
    }
};

export const editCityFormSuccess = (payload: ICity) => {
    return {
        type: EDIT_CITY_FORM_SUCCESS,
        payload
    }
};

export const editCityFormError = (payload: string) => {
    return {
        type: EDIT_CITY_FORM_ERROR,
        payload
    }
};

export const editCityForm = (id: number, form: ICityEditFormRequest) => {
    return {
        type: EDIT_CITY_FORM,
        payload: {id, form}
    };
};

export const getCityFormRequest = () => {
    return {
        type: GET_CITY_FORM_REQUEST
    }
};

export const getCityFormSuccess = (payload: ICity) => {
    return {
        type: GET_CITY_FORM_SUCCESS,
        payload
    }
};

export const getCityFormError = (payload: string) => {
    return {
        type: GET_CITY_FORM_ERROR,
        payload
    }
};

export const getCityForm = (payload: number) => {
    return {
        type: GET_CITY_FORM,
        payload
    };
};

export const deleteCityFormRequest = () => {
    return {
        type: DELETE_CITY_FORM_REQUEST
    }
};

export const deleteCityFormSuccess = () => {
    return {
        type: DELETE_CITY_FORM_SUCCESS
    }
};

export const deleteCityFormError = (payload: string) => {
    return {
        type: DELETE_CITY_FORM_ERROR,
        payload
    }
};

export const deleteCityForm = (payload: number) => {
    return {
        type: DELETE_CITY_FORM,
        payload
    };
};

export const openCityForm = (mode: 'add' | 'edit' | 'delete', modeId?: number) => {
    return {
        type: OPEN_CITY_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closeCityForm = () => {
    return {
        type: CLOSE_CITY_FORM
    };
};
