import React, {PureComponent} from 'react';
import {Alert, Button, Form, Spinner, FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import Dropdown from "../UI/Dropdown";
import {sanitizeForm} from "../../utils/sanitize-form";
import {ICourierPenaltyAddFromRequest} from "../../models/Courier";
import {IPenalty} from "../../models/Penalty";


const schema = yup.object({
    typeId: yup.number().nullable().required(requiredMessage()),
    comment: yup.string()
});

type CourierPenaltyAddType = {
    id:number
    addCourierPenaltyForm: (id:number, form: ICourierPenaltyAddFromRequest) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
    penalties:IPenalty[]
}

class CourierPenaltyAdd extends PureComponent<CourierPenaltyAddType> {
    render() {
        const {addCourierPenaltyForm, changeLoading, changeError, cancelHandler, id, penalties} = this.props;

        const penaltyOptions = penalties.map(({id, name})=> ({label:name, value: id}));

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const typeId = sanitizeForm(values.typeId);
                    const comment = sanitizeForm(values.comment);

                    const form = {
                        typeId,
                        comment
                    };

                    addCourierPenaltyForm(id, form)
                }}
                initialValues={{
                    typeId: null,
                    comment: ''
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>Укажите причину <span className={'required'}>*</span></Label>
                            <Dropdown
                                name={'typeId'}
                                value={values.typeId}
                                options={penaltyOptions}
                                onChange={(value) => setFieldValue('typeId', value)}
                                invalid={touched.typeId && !!errors.typeId}
                            />
                            <FormFeedback>{errors.typeId}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Напишите комментарий</Label>
                            <Input
                                as={'textarea'}
                                name="comment"
                                value={values.comment}
                                onChange={handleChange}
                                invalid={touched.comment && !!errors.comment}
                            />
                            <FormFeedback>{errors.comment}</FormFeedback>
                        </FormGroup>
                        {changeError &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {changeError}
                        </Alert>)}
                        <div className={'text-right'}>
                            {cancelHandler &&
                            <Button color="light" onClick={cancelHandler} className={'mr-3'}>Отменить</Button>}
                            <Button type="submit" color={'primary'} disabled={changeLoading}>
                                {changeLoading &&
                                (<Spinner
                                    size="sm"
                                />)}
                                Применить штраф
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default CourierPenaltyAdd;
