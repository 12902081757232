import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {accessCourierLocationShow} from "../utils/user-accesses";
import CouriersLocations from "./CouriersLocations";


type CourierNavbarType = {
    accesses?: string[]
}

class CourierNavbarContainer extends Component<CourierNavbarType> {
    state = {
        isDialog: false
    };

    openDialog = () => {
        this.setState({isDialog: true})
    };

    closeDialog = () => {
        this.setState({isDialog: false})
    };

    render() {
        const {isDialog} = this.state;
        const {
            accesses
        } = this.props;

        if (Array.isArray(accesses) && accesses.includes(accessCourierLocationShow)) {
            return (
                <>
                    <Button
                        color={'primary'}
                        onClick={() => this.openDialog()}>
                        Показать на карте
                    </Button>

                    <Modal isOpen={isDialog} size={'xl'} style={{maxWidth: '1600px', width: '80%'}}>
                        <ModalBody className={'p-0'}>
                            <CouriersLocations
                                cancelHandler={this.closeDialog}
                            />
                        </ModalBody>
                    </Modal>
                </>
            )
        }
        return null
    }
}


const mapStateToProps = ({auth}: AppState) => {
    const {user} = auth;
    return {
        accesses: user !== null ? user.accesses : undefined
    }
};

export default connect(mapStateToProps, null)(CourierNavbarContainer);
