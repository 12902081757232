import {call, put, takeLatest} from 'redux-saga/effects';
import {removeUrlParams} from "../../utils/history";
import {GET_CITIES} from "../constants/cities";
import {CityService} from '../../services/CityService';
import {getCitiesError, getCitiesRequest, getCitiesSuccess} from '../actions/cities';
import {ICity, ICityRequest} from "../../models/City";
import {Pagination} from "../../services/Api";



type getCitiesType = {
    type: typeof GET_CITIES,
    payload:{
        page?:number,
        form?: ICityRequest
    }
}

function* getCitiesSaga({payload}:getCitiesType) {
    try {
        yield put(getCitiesRequest());

        const service = new CityService();

        const promise = yield call(service.getCities, payload.page, payload.form);

        const data:ICity[] = promise.data || [];
        const pagination = new Pagination(promise.headers).getData();

        yield put(getCitiesSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
        yield put(getCitiesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCities() {
    yield takeLatest(GET_CITIES, getCitiesSaga);
}
