import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {addCompanyBranchForm} from "../store/actions/companyBranchForm";
import {ICompanyBranchAddFormRequest} from "../models/Company";
import {IAuth} from "../models/Auth";
import CompanyBranchAdd from "../components/CompanyBranchAdd";
import {IPoint} from "../models/Point";
import {getPoints} from "../store/actions/points";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import Empty from "../components/UI/Empty";
import _ from "lodash";


type CompanyBranchAddType = {
    addCompanyBranchForm: (id: number, form: ICompanyBranchAddFormRequest) => void
    getPoints: () => void
    changeLoading: boolean
    changeError: string
    id: number
    points: IPoint[]
    pointsLoading: boolean
    pointsError: string
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class CompanyBranchAddContainer extends Component<CompanyBranchAddType & CitiesType> {

    componentDidMount() {
        const {getPoints} = this.props;

        getPoints()
    }

    render() {
        const {id, changeLoading, changeError, addCompanyBranchForm, allowedCities, getPoints, points, pointsLoading, pointsError} = this.props;

        if (pointsLoading) {
            return <Loader/>
        }
        if (pointsError) {
            return <Error error={pointsError} refresh={() => getPoints()}/>
        }

        if(!!points.length) {
            return (
                <CompanyBranchAdd
                    addCompanyBranchForm={(form) => addCompanyBranchForm(id, form)}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    points={points}
                    allowedCities={allowedCities}
                />
            )
        }
        return(
            <Empty>
                <h3>Список отправных точек пуст</h3>
                <p className={'mb-0'}>Добавьте отправные точки</p>
            </Empty>
        )
    }
}

const mapStateToProps = ({points, auth, companyBranchForm}: AppState) => {

    return {
        points: points.data,
        pointsLoading: points.loading,
        pointsError: points.error,
        changeLoading: companyBranchForm.changeLoading,
        changeError: companyBranchForm.changeError,
        allowedCities: _.get(auth.user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    addCompanyBranchForm,
    getPoints
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyBranchAddContainer);
