import {createSelector} from "reselect";
import queryString from "query-string";
import _ from "lodash";
import {format} from "date-fns";
import {IReportEmenuFilterForm} from "../../models/Report";

const reportEmenuUrlSelector = (props: any) => {
    return props.location.search
};

export const reportEmenuFilterReSelector = createSelector(
    [reportEmenuUrlSelector],
    (queryParams): IReportEmenuFilterForm & {cityId?: number | null;} | undefined => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['month', 'year', 'cityId']);

        const month = params.month as string;
        const year: any = params.year as string;
        const cityId = typeof params.cityId === "string"
          ? Number(params.cityId)
          : null;
        
        const filter: IReportEmenuFilterForm & {cityId?: number | null;} = {};
        
        if (cityId) {
          filter.cityId = cityId;
        }

        if (month !== undefined && year !== undefined) {
          filter.dateStart = format(new Date(year, parseInt(month) - 1, 1), "yyyy-MM-dd");
          filter.dateEnd = format(new Date(year, parseInt(month), 0), "yyyy-MM-dd");
        }
  
        return !_.isEmpty(filter) ? filter : undefined;
    }
)
