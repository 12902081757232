import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {IRoleAddFormRequest} from "../models/Role";
import {addRoleForm} from "../store/actions/roleForm";
import RoleAdd from "../components/RoleAdd";
import {getDictionaryPermissions} from "../store/actions/dictionaryPermissions";
import {IDictionary} from "../models/Dictionary";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";


type RoleAddType = {
    addRoleForm: (form: IRoleAddFormRequest) => void
    getDictionaryPermissions: () => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
    permissions:IDictionary[]
    permissionsLoading: boolean
    permissionsError: string
}


class RoleAddContainer extends Component<RoleAddType> {

    componentDidMount() {
        const {getDictionaryPermissions} = this.props;
        getDictionaryPermissions()
    }

    render() {
        const {changeLoading, changeError, addRoleForm, cancelHandler, permissions, permissionsLoading, permissionsError, getDictionaryPermissions} = this.props;

        if(permissionsLoading){
            return <Loader/>
        }

        if(permissionsError){
            return <Error error={permissionsError} refresh={getDictionaryPermissions}/>
        }

        if(!!permissions.length) {
            return (
                <RoleAdd
                    addRoleForm={addRoleForm}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    permissions={permissions}
                    cancelHandler={cancelHandler}
                />
            )
        }
        return null
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.roleForm;
    return {
        changeLoading,
        changeError,
        permissions: state.dictionaryPermissions.data,
        permissionsLoading: state.dictionaryPermissions.loading,
        permissionsError: state.dictionaryPermissions.error
    }
};

const mapDispatchToProps = {
    addRoleForm,
    getDictionaryPermissions
};


export default connect(mapStateToProps, mapDispatchToProps)(RoleAddContainer);
