import {
    ADD_COMPANY_CONTACT_FORM,
    ADD_COMPANY_CONTACT_FORM_REQUEST,
    ADD_COMPANY_CONTACT_FORM_SUCCESS,
    ADD_COMPANY_CONTACT_FORM_ERROR,
    OPEN_COMPANY_CONTACT_FORM,
    CLOSE_COMPANY_CONTACT_FORM,
    DELETE_COMPANY_CONTACT_FORM,
    DELETE_COMPANY_CONTACT_FORM_ERROR,
    DELETE_COMPANY_CONTACT_FORM_REQUEST,
    DELETE_COMPANY_CONTACT_FORM_SUCCESS,
    GET_COMPANY_CONTACT_FORM,
    GET_COMPANY_CONTACT_FORM_ERROR,
    GET_COMPANY_CONTACT_FORM_SUCCESS,
    GET_COMPANY_CONTACT_FORM_REQUEST,
    EDIT_COMPANY_CONTACT_FORM,
    EDIT_COMPANY_CONTACT_FORM_REQUEST,
    EDIT_COMPANY_CONTACT_FORM_SUCCESS,
    EDIT_COMPANY_CONTACT_FORM_ERROR
} from '../constants/companyContactForm'
import {IAction} from "./index";
import {ICompanyContact, ICompanyContactAddFormRequest, ICompanyContactEditFormRequest} from "../../models/Company";


export interface IActionCompanyContactForm extends IAction<symbol, any> {

}

export const addCompanyContactFormRequest = () => {
    return {
        type: ADD_COMPANY_CONTACT_FORM_REQUEST
    }
};

export const addCompanyContactFormSuccess = (payload: ICompanyContact) => {
    return {
        type: ADD_COMPANY_CONTACT_FORM_SUCCESS,
        payload
    }
};

export const addCompanyContactFormError = (payload: string) => {
    return {
        type: ADD_COMPANY_CONTACT_FORM_ERROR,
        payload
    }
};

export const addCompanyContactForm = (id: number, form: ICompanyContactAddFormRequest) => {
    return {
        type: ADD_COMPANY_CONTACT_FORM,
        payload: {id, form}
    };
};

export const editCompanyContactFormRequest = () => {
    return {
        type: EDIT_COMPANY_CONTACT_FORM_REQUEST
    }
};

export const editCompanyContactFormSuccess = (payload: ICompanyContact) => {
    return {
        type: EDIT_COMPANY_CONTACT_FORM_SUCCESS,
        payload
    }
};

export const editCompanyContactFormError = (payload: string) => {
    return {
        type: EDIT_COMPANY_CONTACT_FORM_ERROR,
        payload
    }
};

export const editCompanyContactForm = (sectionId: number, id:number, form: ICompanyContactEditFormRequest) => {
    return {
        type: EDIT_COMPANY_CONTACT_FORM,
        payload: {sectionId, id, form}
    };
};

export const getCompanyContactFormRequest = () => {
    return {
        type: GET_COMPANY_CONTACT_FORM_REQUEST
    }
};

export const getCompanyContactFormSuccess = (payload: ICompanyContact) => {
    return {
        type: GET_COMPANY_CONTACT_FORM_SUCCESS,
        payload
    }
};

export const getCompanyContactFormError = (payload: string) => {
    return {
        type: GET_COMPANY_CONTACT_FORM_ERROR,
        payload
    }
};

export const getCompanyContactForm = (payload: number) => {
    return {
        type: GET_COMPANY_CONTACT_FORM,
        payload
    };
};


export const deleteCompanyContactFormRequest = () => {
    return {
        type: DELETE_COMPANY_CONTACT_FORM_REQUEST
    }
};

export const deleteCompanyContactFormSuccess = () => {
    return {
        type: DELETE_COMPANY_CONTACT_FORM_SUCCESS
    }
};

export const deleteCompanyContactFormError = (payload: string) => {
    return {
        type: DELETE_COMPANY_CONTACT_FORM_ERROR,
        payload
    }
};

export const deleteCompanyContactForm = (sectionId:number, id: number) => {
    return {
        type: DELETE_COMPANY_CONTACT_FORM,
        payload:{
            sectionId,
            id
        }
    };
};


export const openCompanyContactForm = (mode: 'add' | 'edit' | 'delete', modeId?: number) => {
    return {
        type: OPEN_COMPANY_CONTACT_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closeCompanyContactForm = () => {
    return {
        type: CLOSE_COMPANY_CONTACT_FORM
    };
};
