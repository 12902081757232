import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addPaymentFormRequest,
    addPaymentFormSuccess,
    addPaymentFormError,
    editPaymentFormRequest,
    editPaymentFormSuccess,
    editPaymentFormError,
    getPaymentFormRequest,
    getPaymentFormSuccess,
    getPaymentFormError,
    deletePaymentFormSuccess,
    deletePaymentFormRequest,
    deletePaymentFormError,
    closePaymentForm
} from "../actions/paymentForm";
import {ADD_PAYMENT_FORM, DELETE_PAYMENT_FORM, EDIT_PAYMENT_FORM, GET_PAYMENT_FORM} from "../constants/paymentForm";
import {IPayment, IPaymentAddFormRequest, IPaymentEditFormRequest} from "../../models/Payment";
import {PaymentService} from "../../services/PaymentService";
import {getPayments} from "../actions/payments";
import {getUrlParams, removeUrlParams} from "../../utils/history";
import _ from 'lodash';

type addPaymentFormType = {
    type: typeof ADD_PAYMENT_FORM,
    payload: {
        id: number,
        form: IPaymentAddFormRequest
    }
}

function* addPaymentFormSaga({payload}: addPaymentFormType) {
    try {
        yield put(addPaymentFormRequest());

        const service = new PaymentService();
        const promise = yield call(service.addPayment, payload.id, payload.form);

        const response: IPayment = promise.data;

        yield put(addPaymentFormSuccess(response));

        yield put(closePaymentForm());

        _.isEmpty(getUrlParams()) ? yield put(getPayments()): removeUrlParams();

    } catch (error) {
        yield put(addPaymentFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddPaymentForm() {
    yield takeLatest(ADD_PAYMENT_FORM, addPaymentFormSaga);
}


type editPaymentFormType = {
    type: typeof EDIT_PAYMENT_FORM,
    payload: {
        id: number,
        form: IPaymentEditFormRequest
    }
}

function* editPaymentFormSaga({payload}: editPaymentFormType) {
    try {
        yield put(editPaymentFormRequest());

        const service = new PaymentService();
        const promise = yield call(service.editPayment, payload.id, payload.form);

        const response: IPayment = promise.data;

        yield put(editPaymentFormSuccess(response));

        yield put(closePaymentForm());

        _.isEmpty(getUrlParams()) ? yield put(getPayments()): removeUrlParams();

    } catch (error) {
        yield put(editPaymentFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditPaymentForm() {
    yield takeLatest(EDIT_PAYMENT_FORM, editPaymentFormSaga);
}

function* getPaymentFormSaga({payload}: { type: typeof GET_PAYMENT_FORM, payload: number }) {
    try {
        yield put(getPaymentFormRequest());

        const service = new PaymentService();
        const promise = yield call(service.getPayment, payload);

        const response: IPayment = promise.data;

        yield put(getPaymentFormSuccess(response));

    } catch (error) {
        yield put(getPaymentFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetPaymentForm() {
    yield takeLatest(GET_PAYMENT_FORM, getPaymentFormSaga);
}

function* deletePaymentFormSaga({payload}: { type: typeof DELETE_PAYMENT_FORM, payload: number }) {
    try {
        yield put(deletePaymentFormRequest());

        const service = new PaymentService();
        yield call(service.deletePayment, payload);
        yield put(deletePaymentFormSuccess());

        yield put(closePaymentForm());

        _.isEmpty(getUrlParams()) ? yield put(getPayments()): removeUrlParams();

    } catch (error) {
        yield put(deletePaymentFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeletePaymentForm() {
    yield takeLatest(DELETE_PAYMENT_FORM, deletePaymentFormSaga);
}
