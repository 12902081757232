import {
    COURIER_TYPE_AUTO,
    COURIER_TYPE_BIKE,
    COURIER_TYPE_MOTORCYCLE,
    COURIER_TYPE_WALK,
    courierTypeIcons
} from "./couriers";

export const POINT_ZONE_TYPE_WALK = COURIER_TYPE_WALK;
export const POINT_ZONE_TYPE_BIKE = COURIER_TYPE_BIKE;
export const POINT_ZONE_TYPE_MOTORCYCLE = COURIER_TYPE_MOTORCYCLE;
export const POINT_ZONE_TYPE_AUTO = COURIER_TYPE_AUTO;
export const POINT_ZONE_TYPE_EXTERNAL = 20;

export const pointZoneTypeOptions = [
    {label: 'Для пеших курьеров', value: POINT_ZONE_TYPE_WALK},
    {label: 'Для велокурьеров', value: POINT_ZONE_TYPE_BIKE},
    {label: 'Для мотокурьеров', value: POINT_ZONE_TYPE_MOTORCYCLE},
    {label: 'Для автокурьеров', value: POINT_ZONE_TYPE_AUTO},
    {label: 'Для внешних курьеров', value: POINT_ZONE_TYPE_EXTERNAL}
];

export const pointZoneTypeIcons: Record<number, string> = {
  ...courierTypeIcons,
    [POINT_ZONE_TYPE_EXTERNAL]: 'workspaces',
}
