import {
    GET_SCHEDULE_ERROR,
    GET_SCHEDULE_REQUEST,
    GET_SCHEDULE_SUCCESS,
    PUBLISH_SCHEDULE_REQUEST,
    PUBLISH_SCHEDULE_SUCCESS,
    PUBLISH_SCHEDULE_ERROR,
    CANCEL_SCHEDULE_REQUEST,
    CANCEL_SCHEDULE_SUCCESS, CANCEL_SCHEDULE_ERROR
} from "../constants/schedule";
import {ISchedule} from "../../models/Schedule";
import {IActionSchedule} from "../actions/schedule";


export interface IScheduleState {
    getLoading: boolean
    getError: string
    publishLoading: boolean
    publishError: string
    data: ISchedule | null
    cancelLoading: boolean
    cancelError: string
}

const initialState: IScheduleState = {
    data: null,
    getLoading: false,
    getError: '',
    publishLoading: false,
    publishError: '',
    cancelLoading: false,
    cancelError: ''
};

export default function schedule(state = initialState, action: IActionSchedule): IScheduleState {
    switch (action.type) {
        case GET_SCHEDULE_REQUEST:
            return {
                ...state,
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_SCHEDULE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_SCHEDULE_ERROR:
            return {
                ...state,
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case PUBLISH_SCHEDULE_REQUEST:
            return {
                ...state,
                publishLoading: true,
                publishError: ''
            };
        case PUBLISH_SCHEDULE_SUCCESS:
            return {
                ...state,
                publishLoading: false,
                publishError: ''
            };
        case PUBLISH_SCHEDULE_ERROR:
            return {
                ...state,
                publishLoading: false,
                publishError: action.payload
            };
        case CANCEL_SCHEDULE_REQUEST:
            return {
                ...state,
                cancelLoading: true,
                cancelError: ''
            };
        case CANCEL_SCHEDULE_SUCCESS:
            return {
                ...state,
                cancelLoading: false,
                cancelError: ''
            };
        case CANCEL_SCHEDULE_ERROR:
            return {
                ...state,
                cancelLoading: false,
                cancelError: action.payload
            };
        default:
            return state;
    }
}
