export interface IPoint {
    id: number
    name: string
    description?: string
    cityId: number
    status: boolean,
    lat:number,
    long:number,
    deliveryZones?: IPointZone[]
}

export interface IPointShort {
    id?: number;
    /** Название района */
    name?: string;
    /** Описание района */
    description?: string;
    /** id города */
    cityId?: number;
    /** Широта */
    lat?: number;
    /** Долгота */
    long?: number;
    /** Активна ли точка */
    status?: boolean;
}

export interface IPointAddFormRequest {
    name: string
    description: string | null
    cityId: number
    status: boolean
    lat:number,
    long:number
}

export interface IPointEditFormRequest {
    name: string
    description: string | null
    cityId: number
    status: boolean
    lat:number,
    long:number
}

export interface IPointZone {
    id: number;
    name: string;
    description?: string;
    typeId: number;
    status: boolean;
    /** Разрешение на использование внешних курьеров */
    allowExternalCourier?: boolean;
    deliveryPrice: number;
    deliveryTime: number;
    /** Надбавка курьеру */
    courierBonus?: number;
    polygon: number[][];
}

export interface IPointZoneEditFormRequest {
    name?: string;
    description?: string | null;
    typeId?: number;
    status?: boolean;
    /** Разрешение на использование внешних курьеров */
    allowExternalCourier?: boolean;
    deliveryPrice?: number;
    /** Надбавка курьеру */
    courierBonus?: number;
    deliveryTime?: number;
    polygon?: number[][];
}

export interface IPointZoneAddFormRequest {
    name: string;
    description: string | null;
    typeId: number;
    status: boolean;
    deliveryPrice: number;
    /** Надбавка курьеру */
    courierBonus?: number;
    deliveryTime: number;
    polygon: number[][];
}

export class Point implements IPoint {
    id: number;
    name: string;
    description?: string;
    cityId: number;
    status: boolean;
    lat: number;
    long: number;
    deliveryZones?: IPointZone[];

    constructor({id, name, description, cityId, status, lat, long, deliveryZones}: IPoint) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.cityId = cityId;
        this.status = status;
        this.lat = lat;
        this.long = long;
        this.deliveryZones = deliveryZones;
    }

    getZones(){
        return this.deliveryZones || [];
    }
}
