import React, {PureComponent} from 'react';
import {Spinner} from "reactstrap";


class Loader extends PureComponent {
    render() {
        return (
            <div className="text-center mt-2 mb-2">
                <Spinner color={'secondary'}/>
            </div>
        )
    }
}


export default Loader;
