import {call, put, takeLatest} from 'redux-saga/effects';
import {getCouriersError, getCouriersRequest, getCouriersSuccess} from "../actions/couriers";
import {ICourier, ICourierFull, ICourierFullResponse} from "../../models/Courier";
import {GET_COURIERS} from "../constants/couriers";
import {CourierService} from "../../services/CourierService";


type getCouriersType = {
    type: typeof GET_COURIERS,
    payload: number
};

function* getCouriersSaga({payload}: getCouriersType) {
    try {
        yield put(getCouriersRequest());

        const service = new CourierService();

        const promise = yield call(service.getCouriers, payload);

        const response: ICourierFullResponse[] = promise.data || [];

        const transform = response.map((response: ICourierFullResponse) => {
            const point = response.point.lat && response.point.long ? {...response.point, lat: +response.point.lat, long: +response.point.long}: response.point;
            const couriers = response.couriers.map((courier)=> {
                return courier.location ? {
                    ...courier,
                    location: {...courier.location, lat: +courier.location.lat, long: +courier.location.long}
                } : courier;
            }) as ICourier[];

            return {point,couriers}
        });

        yield put(getCouriersSuccess(transform as ICourierFull[]));

    } catch (error) {
        yield put(getCouriersError(error.response.data.message || 'Ошибка'));
    }
}


export function* watchGetCouriers() {
    yield takeLatest(GET_COURIERS, getCouriersSaga);
}
