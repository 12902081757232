import {createSelector} from "reselect";
import queryString from "query-string";
import _ from "lodash";
import {ISalaryFilterFormExtended} from "../../models/Salary";

const salaryUrlSelector = (props: any) => {
    return props.location.search
};

export const salaryFilterReSelector = createSelector(
    [salaryUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ["dateStart", "dateEnd", "courierId", "isPaidOut", "cityId"]);

        const dateStart = params.dateStart;
        const dateEnd = params.dateEnd;
        const courierId = params.courierId ? +params.courierId : undefined;
        const isPaidOut = params.isPaidOut === "true" ? true : params.isPaidOut === "false" ? false : undefined;
        const cityId = params.cityId ? +params.cityId : undefined;

        const filter: ISalaryFilterFormExtended = _.pickBy({courierId, dateStart, dateEnd, isPaidOut, cityId}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(filter) ? filter : undefined
    }
)

export const salaryPageReSelector = createSelector(
    [salaryUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.page ? +getParams.page : 1
    }
);

export const salaryCityIdReSelector = createSelector(
    [salaryUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.cityId ? +getParams.cityId : undefined
    }
);

export const salaryIsGroupReSelector = createSelector(
    [salaryUrlSelector],
    (queryParams) => {
        return queryString.parse(queryParams).group === 'true'
    }
)
