/**
 * Замена объекта в массиве по id
 * @returns новый массив (исходный, если не нашел id)
 * @param items - все записи
 * @param item - запись, которую нужно обновить
 * @param pathToId - путь к id объекта
 */
export const replaceInData:
  <T extends {id?: string} & Record<string, unknown> = Record<string, unknown>>(
    items: T[], item: T, pathToId?: ((item: T) => string)
  ) => T[] =
  (items, item, pathToId) => {
    /**
     * Ранний выход, если некорректные типы аргументов
     */
    if (!Array.isArray(items) || item.constructor !== Object) {
      return items;
    }
    
    const index = pathToId
      ? items.findIndex((arrayItem) => pathToId(arrayItem) === pathToId(item))
      : item.id
        ? items.findIndex(({id}) => id === item.id)
        : -1;
    
    return index === -1
      ? items
      : [
        ...items.slice(0, index),
        item,
        ...items.slice(index + 1)
      ];
  };

/**
 * Удаление объекта из массива по id
 * @returns новый массив (исходный, если не нашел id)
 * @param items - все записи
 * @param itemId - id удаляемой записи
 * @param pathToId - путь к id объекта
 */
export const removeFromData:
  <T extends {id?: string} & Record<string, unknown> = Record<string, unknown>>(
    items: T[], itemId: string, pathToId?: ((item: T) => string)
  ) => T[] =
  (items, itemId, pathToId) => {
    /**
     * Ранний выход, если некорректные типы аргументов
     */
    if (!Array.isArray(items)) {
      return items;
    }
    /**
     * Нахождение индекса элемента
     */
    const index = pathToId
      ? items.findIndex((arrayItem) => pathToId(arrayItem) === itemId)
      : items[0]?.id
        ? items.findIndex(({id}) => id === itemId)
        : -1;
    
    return index === -1
      ? items
      : [
        ...items.slice(0, index),
        ...items.slice(index + 1)
      ];
  }
