import {
    GET_COURIER_SCHEDULE_BY_ID,
    GET_COURIER_SCHEDULE_BY_ID_REQUEST,
    GET_COURIER_SCHEDULE_BY_ID_SUCCESS,
    GET_COURIER_SCHEDULE_BY_ID_ERROR
} from '../constants/courierScheduleById'
import {IAction} from "./index";
import {ICourierScheduleByID} from "../../models/Courier";


export interface IActionCourierScheduleById extends IAction<symbol, any> {

}

export const getCourierScheduleByIdRequest = () => {
    return {
        type: GET_COURIER_SCHEDULE_BY_ID_REQUEST
    }
};

export const getCourierScheduleByIdSuccess = (payload: ICourierScheduleByID[]) => {
    return {
        type: GET_COURIER_SCHEDULE_BY_ID_SUCCESS,
        payload
    }
};

export const getCourierScheduleByIdError = (payload: string) => {
    return {
        type: GET_COURIER_SCHEDULE_BY_ID_ERROR,
        payload
    }
};

export const getCourierScheduleById = (sectionId: number, id:number) => {
    return {
        type: GET_COURIER_SCHEDULE_BY_ID,
        payload:{
            sectionId,
            id
        }
    };
};
