import {createSelector} from "reselect";
import queryString from "query-string";
import _ from "lodash";

const couriersShiftsUrlSelector = (props: any) => {
    return props.location.search
}

export const couriersShiftsFilterReSelector = createSelector(
    [couriersShiftsUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['cityId', "dateStart", "dateEnd", "courierId", "payTypeId"]);

        const cityId = params.cityId ? +params.cityId : undefined;
        const dateStart = params.dateStart;
        const dateEnd = params.dateEnd;
        const courierId = params.courierId ? +params.courierId : undefined;
        const payTypeId = params.payTypeId ? +params.payTypeId : undefined;

        const filter = _.pickBy({cityId, courierId, dateStart, dateEnd, payTypeId}, function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(filter) ? filter : undefined
    }
)

export const couriersShiftsPageReSelector = createSelector(
    [couriersShiftsUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.page ? +getParams.page : 1
    }
);
