import React, {PureComponent} from 'react';
import classNames from 'classnames';
import styles from './Icon.module.scss';


type IconType = {
    color?: string
    size?: string
    name: string
}

class Icon extends PureComponent<IconType & React.HTMLAttributes<HTMLDivElement>> {
    render() {
        const {color, size, name, className} = this.props;
        return <i className={classNames(`material-icons-outlined`,
            className,
            styles.icon,
            size && styles['icon--' + size],
            color && styles['icon--' + color]
        )}
        >
            {name}
        </i>;
    }
}


export default Icon;
