import {
    GET_COURIERS_LOCATIONS,
    GET_COURIERS_LOCATIONS_REQUEST,
    GET_COURIERS_LOCATIONS_SUCCESS,
    GET_COURIERS_LOCATIONS_ERROR
} from '../constants/couriersLocations'
import {IAction} from "./index";
import {ICourier} from "../../models/Courier";


export interface IActionCouriersLocations extends IAction<symbol, any> {

}

export const getCouriersLocationsRequest = () => {
    return {
        type: GET_COURIERS_LOCATIONS_REQUEST
    }
};

export const getCouriersLocationsSuccess = (payload: ICourier[]) => {
    return {
        type: GET_COURIERS_LOCATIONS_SUCCESS,
        payload
    }
};

export const getCouriersLocationsError = (payload: string) => {
    return {
        type: GET_COURIERS_LOCATIONS_ERROR,
        payload
    }
};

export const getCouriersLocations = (payload: number) => {
    return {
        type: GET_COURIERS_LOCATIONS,
        payload
    };
};

