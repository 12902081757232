import {call, put, takeLatest} from 'redux-saga/effects';
import {AuthService} from "../../services/AuthService";
import {
    getUserRequest,
    loginError,
    loginRequest,
    loginSuccess,
    getUserError,
    getUserSuccess,
    logoutSuccess, changePasswordUserRequest, changePasswordUserSuccess, changePasswordUserError
} from '../actions/auth';
import {LOGIN, GET_USER, GET_USER_SILENT, LOGOUT, CHANGE_PASSWORD_USER} from '../constants/auth';
import {IAuthRequest, IAuthResponse, IAuth, IAuthChangePasswordRequest} from "../../models/Auth";
import _ from 'lodash';
import {customHistory} from "../../utils/history";


const fields:string[] = ['authKey', 'id', 'fio', 'phone', 'role', 'accesses', 'allowedCities', 'companyId', 'lastLogin', 'email'];
const clearFields = fields.slice(1);

function* loginSaga({payload}: { type: typeof LOGIN, payload: IAuthRequest }) {
    try {
        yield put(loginRequest());
        const service = new AuthService();
        const promise = yield call(service.login, payload);
        const response = _.pick(promise.data, fields) as IAuthResponse;
        const {authKey, ...result} = response;
        localStorage.setItem('auth', authKey);
        yield put(loginSuccess(result as IAuth));

    } catch (error) {
        localStorage.removeItem('auth');
        yield put(loginError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchLogin() {
    yield takeLatest(LOGIN, loginSaga);
}

function* getUserSaga() {
    try {
        yield put(getUserRequest());
        const service = new AuthService();
        const promise = yield call(service.getUser);
        const response = _.pick(promise.data, clearFields) as IAuth;

        yield put(getUserSuccess(response));

    } catch (error) {
        yield put(getUserError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetUser() {
    yield takeLatest(GET_USER, getUserSaga);
}

function* getUserSilentSaga() {
    try {
        const service = new AuthService();
        const promise = yield call(service.getUser);
        const response = _.pick(promise.data, clearFields) as IAuth;
        yield put(getUserSuccess(response));

    } catch (error) {
    }
}

export function* watchGetUserSilent() {
    yield takeLatest(GET_USER_SILENT, getUserSilentSaga);
}


function* logoutSaga() {
    localStorage.removeItem('hash');
    localStorage.removeItem('auth');
    yield put(logoutSuccess());
}

export function* watchLogout() {
    yield takeLatest(LOGOUT, logoutSaga);
}


function* changePasswordUserSaga({payload}: { type: typeof CHANGE_PASSWORD_USER, payload: IAuthChangePasswordRequest }) {
    try {
        yield put(changePasswordUserRequest());
        const service = new AuthService();
        const promise = yield call(service.changePasswordUser, payload);
        const response = _.pick(promise.data, fields) as IAuthResponse;
        const {authKey, ...result} = response;
        localStorage.setItem('auth', authKey);
        yield put(changePasswordUserSuccess(result as IAuth));
        customHistory.push('/profile')

    } catch (error) {
        yield put(changePasswordUserError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchChangePasswordUser() {
    yield takeLatest(CHANGE_PASSWORD_USER, changePasswordUserSaga);
}
