import {call, put, takeLatest} from "redux-saga/effects";
import {
    addCourierDocumentsError,
    addCourierDocumentsRequest,
    addCourierDocumentsSuccess, deleteCourierDocumentsError,
    deleteCourierDocumentsRequest, deleteCourierDocumentsSuccess,
    getCourierDocumentsError,
    getCourierDocumentsRequest,
    getCourierDocumentsSuccess
} from "../actions/courierDocuments";
import {EmployeeService} from "../../services/EmployeeService";
import {ADD_COURIER_DOCUMENTS, DELETE_COURIER_DOCUMENTS, GET_COURIER_DOCUMENTS} from "../constants/courierDocuments";
import {IEmployee} from "../../models/Employee";

type addCourierDocumentsSagaType = {
    type: typeof ADD_COURIER_DOCUMENTS
    payload: {
        id: number,
        data: FormData
    }
}

function* addCourierDocumentsSaga({payload}: addCourierDocumentsSagaType) {
    try {
        yield put(addCourierDocumentsRequest())

        const service = new EmployeeService()

        yield call(service.uploadEmployeeDocument, payload.id, payload.data)

        yield put(addCourierDocumentsSuccess())

    } catch (error) {
        yield put(addCourierDocumentsError(error.response.data.message || 'Ошибка'))
    }
}

export function* watchAddCourierDocuments() {
    yield takeLatest(ADD_COURIER_DOCUMENTS, addCourierDocumentsSaga)
}

type getCourierDocumentsSagaType = {
    type: typeof GET_COURIER_DOCUMENTS
    payload: {
        id: number,
        expand: string
    }
}

function* getCourierDocumentsSaga({payload}: getCourierDocumentsSagaType) {
    try {
        yield put(getCourierDocumentsRequest())

        const service = new EmployeeService()

        const promise = yield call(service.getEmployee, payload.id, payload.expand)

        const response: IEmployee = promise.data;

        yield put(getCourierDocumentsSuccess(response))
    } catch (error) {
        yield put(getCourierDocumentsError(error.response.data.message || 'Ошибка'))
    }
}

export function* watchGetCourierDocuments() {
    yield takeLatest(GET_COURIER_DOCUMENTS, getCourierDocumentsSaga)
}

type deleteCourierDocumentsSagaType = {
    type: typeof DELETE_COURIER_DOCUMENTS
    payload: {
        id: number,
        documentId: number
    }
}

function* deleteCourierDocumentsSaga({payload}: deleteCourierDocumentsSagaType) {
    try {

        yield put(deleteCourierDocumentsRequest())

        const service = new EmployeeService()

        yield call(service.deleteEmployeeDocument, payload.id, payload.documentId)

        yield put(deleteCourierDocumentsSuccess())

    } catch (error) {
        yield put(deleteCourierDocumentsError(error.response.data.message || 'Ошибка'))
    }
}

export function* watchDeleteCourierDocuments() {
    yield takeLatest(DELETE_COURIER_DOCUMENTS, deleteCourierDocumentsSaga)
}