import {IActionBonusRate} from "../actions/bonusRate";
import {CHANGE_BONUS_RATE_ERROR, CHANGE_BONUS_RATE_REQUEST, CHANGE_BONUS_RATE_SUCCESS} from "../constants/bonusRate";

export interface IBonusRateChangeState {
    loading: boolean
    error: string
    data: {
        typeId: number
    } | null
}

const initialState: IBonusRateChangeState = {
    loading: false,
    error: '',
    data: null
}

export default function bonusRateChange(state = initialState, action: IActionBonusRate): IBonusRateChangeState {
    switch (action.type) {
        case CHANGE_BONUS_RATE_REQUEST:
            const {typeId} = action.payload;
            return {
                ...state,
                loading: true,
                data: typeId,
                error: ''
            };
        case CHANGE_BONUS_RATE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: null,
                error: ''
            };
        case CHANGE_BONUS_RATE_ERROR:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };
        default:
            return state;
    }
}