import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import CompanyOutput from "../../containers/CompanyOutput";


class CompaniesPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Контрагенты</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Контрагенты', url: '/companies'}
                ]}/>
                <CompanyOutput/>
            </Layout>
        );
    }
}

export default CompaniesPage;
