import {IActionBonusRate} from "../actions/bonusRate";
import {GET_BONUS_RATE_ERROR, GET_BONUS_RATE_REQUEST, GET_BONUS_RATE_SUCCESS} from "../constants/bonusRate";
import {ICourierBonusRate} from "../../models/Courier";

export interface IBonusRateState {
    data: ICourierBonusRate[]
    loading: boolean
    error: string
}

const initialState: IBonusRateState = {
    data: [],
    loading: false,
    error: ''
}

export default function bonusRate(state = initialState, action: IActionBonusRate): IBonusRateState {
    switch (action.type) {
        case GET_BONUS_RATE_REQUEST:
            return {
                ...state,
                data: [],
                loading: true,
                error: ''
            };
        case GET_BONUS_RATE_SUCCESS:
            const data = action.payload.data;
            return {
                ...state,
                data,
                loading: false,
                error: ''
            };
        case GET_BONUS_RATE_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}