import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
    addCourierPenaltyFormRequest,
    addCourierPenaltyFormSuccess,
    addCourierPenaltyFormError,
    closeCourierPenaltyForm,
    deleteCourierPenaltyFormError, deleteCourierPenaltyFormSuccess, deleteCourierPenaltyFormRequest
} from "../actions/courierPenaltyForm";
import {CourierService} from "../../services/CourierService";
import {ICourierPenalty, ICourierPenaltyAddFromRequest} from "../../models/Courier";
import {getCourierPenalties} from "../actions/courierPenalties";
import {ADD_COURIER_PENALTY_FORM, DELETE_COURIER_PENALTY_FORM} from "../constants/courierPenaltyForm";



type addCourierPenaltyFormType = {
    type: typeof ADD_COURIER_PENALTY_FORM,
    payload: {
        id: number,
        form: ICourierPenaltyAddFromRequest
    }
}

function* addCourierPenaltyFormSaga({payload}: addCourierPenaltyFormType) {
    try {
        yield put(addCourierPenaltyFormRequest());

        const service = new CourierService();
        const promise = yield call(service.addCourierPenalty, payload.id, payload.form);
        const response: ICourierPenalty = promise.data;

        yield put(addCourierPenaltyFormSuccess(response));

        yield put(closeCourierPenaltyForm());

        const {courierPenalties} = yield select();
        yield put(getCourierPenalties(payload.id, courierPenalties.filter));

    } catch (error) {
        yield put(addCourierPenaltyFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddCourierPenaltyForm() {
    yield takeLatest(ADD_COURIER_PENALTY_FORM, addCourierPenaltyFormSaga);
}


type deleteCourierPenaltyFormType = {
    type: typeof DELETE_COURIER_PENALTY_FORM,
    payload: {
        sectionId:number
        id:number
    }
}
function* deleteCourierPenaltyFormSaga({payload}: deleteCourierPenaltyFormType) {
    try {
        yield put(deleteCourierPenaltyFormRequest());

        const service = new CourierService();

        yield call(service.deleteCourierPenalty, payload.id);

        yield put(deleteCourierPenaltyFormSuccess());

        yield put(closeCourierPenaltyForm());

        const {courierPenalties} = yield select();
        yield put(getCourierPenalties(payload.sectionId, courierPenalties.filter));

    } catch (error) {
        yield put(deleteCourierPenaltyFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeleteCourierPenaltyForm() {
    yield takeLatest(DELETE_COURIER_PENALTY_FORM, deleteCourierPenaltyFormSaga);
}
