import {
    GET_COMPANY_CONTACTS,
    GET_COMPANY_CONTACTS_REQUEST,
    GET_COMPANY_CONTACTS_SUCCESS,
    GET_COMPANY_CONTACTS_ERROR
} from '../constants/companyContacts'
import {IAction} from "./index";
import {ICompanyContact} from "../../models/Company";


export interface IActionCompanyContacts extends IAction<symbol, any> {

}

export const getCompanyContactsRequest = () => {
    return {
        type: GET_COMPANY_CONTACTS_REQUEST
    }
};

export const getCompanyContactsSuccess = (payload: ICompanyContact[]) => {
    return {
        type: GET_COMPANY_CONTACTS_SUCCESS,
        payload
    }
};

export const getCompanyContactsError = (payload: string) => {
    return {
        type: GET_COMPANY_CONTACTS_ERROR,
        payload
    }
};

export const getCompanyContacts = (payload:number) => {
    return {
        type: GET_COMPANY_CONTACTS,
        payload
    };
};
