import React, {PureComponent} from 'react';
import Icon from '../UI/Icon';
import Table from '../UI/Table';
import {ISchedule} from "../../models/Schedule";
import {Col, Row} from "reactstrap";
import {format, parse} from "date-fns";
import {ru} from "date-fns/locale";
import classNames from 'classnames';



interface SchedulesType {
    linkHandler: (id: number) => void
    data: ISchedule[]
}

class Schedules extends PureComponent<SchedulesType> {
    render() {
        const {data, linkHandler} = this.props;

        return (
            <Table>
                <tbody>
                {data.map((data) => {
                        return (
                            <SchedulesItem
                                key={data.id}
                                data={data}
                                linkHandler={linkHandler}
                            />
                        )
                    }
                )}
                </tbody>
            </Table>
        )
    }
}

type SchedulesItemType = {
    data: ISchedule
    linkHandler: (id: number) => void
}

class SchedulesItem extends PureComponent<SchedulesItemType> {

    linkHandler = () => {
        const {data, linkHandler} = this.props;
        const {id} = data;
        linkHandler(id)
    };

    render() {
        const {data} = this.props;
        const {countCourier, countConfirmCourier, dateStart, dateEnd, isPublished, isModified} = data;

        const isExpired = parse(dateEnd, 'yyyy-MM-dd', new Date()).getTime() < new Date().setHours(0, 0, 0, 0);
        const isConfirmed = countCourier === countConfirmCourier;

        const formatDate = (date: string) => {
            return format(parse(date, 'yyyy-MM-dd', new Date()), 'd MMMM', {locale: ru})
        };

        return (
            <tr onClick={this.linkHandler} className={'pointer'}>
                <td style={{width: 40}}>
                    <Icon
                        name={'calendar_today'}
                        color={isExpired ? 'gray-400' : isConfirmed && isPublished ? 'success' : 'gray-500'}
                    />
                </td>
                <td>
                    <div className={isExpired ? 'gray-400' : 'black'}>
                        <b>
                            {formatDate(dateStart)} - {formatDate(dateEnd)}
                        </b>
                    </div>
                    {!isExpired ? <>
                        {isPublished ?
                            <span className={'green'}>Опубликовано</span> :
                            <span className={'gray-500'}>Не опубликовано</span>}
                        {isPublished && isModified && <span className={'gray-400'}> • Неопубликованные изменения</span>}
                    </> : <span className={'gray-400'}>Истекло</span>}
                </td>
                <td>
                    {isPublished && !isExpired &&
                    <Row form className="align-items-center">
                        <Col md={'auto'}>
                            <Icon name={'how_to_reg'} color={isConfirmed ? 'success' : 'info'}/>
                        </Col>
                        <Col>
                            <div className={'gray-500'}>
                                Курьеров подтвердило
                            </div>
                            <div className={classNames('semi bold', isConfirmed ? 'green' : 'blue')}>
                                {countConfirmCourier} из {countCourier} курьеров
                            </div>
                        </Col>
                    </Row>}
                </td>
                <td style={{width: 40}}>
                    <Icon name={'chevron_right'} color={'gray-500'}/>
                </td>
            </tr>
        );
    }
}

export default Schedules;
