import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import CourierChangePoint from "../components/CourierChangePoint";
import {changePointCourierForm} from "../store/actions/courierForm";
import {getDictionaryPoints} from "../store/actions/dictionaryPoints";
import {IDictionary} from "../models/Dictionary";


type CourierChangePointType = {
    changePointCourierForm: (id: number, form: any) => void
    cancelHandler?: () => void
    cityId: number
    id: number
    pointId:number
    changeLoading: boolean
    changeError: string
    getDictionaryPoints: (cityId: number) => void
    points: IDictionary[]
    pointsLoading: boolean
    pointsError: string
}


class CourierChangePointContainer extends Component<CourierChangePointType> {

    componentDidMount() {
        const {cityId, getDictionaryPoints} = this.props;
        getDictionaryPoints(cityId)
    }

    render() {
        const {
            points, pointsLoading, pointsError, changeLoading, changeError, changePointCourierForm,
            id, cancelHandler, getDictionaryPoints, cityId, pointId
        } = this.props;


        if (pointsLoading) {
            return <Loader/>
        }

        if (pointsError) {
            return <Error error={pointsError} refresh={() => getDictionaryPoints(cityId)}/>
        }

        if (points !== null) {
            return (
                <CourierChangePoint
                    changePointCourierForm={changePointCourierForm}
                    id={id}
                    pointId={pointId}
                    points={points}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    cancelHandler={cancelHandler}/>
            )
        }
        return null;
    }
}

const mapStateToProps = ({courierForm, dictionaryPoints}: AppState) => {

    return {
        changeLoading: courierForm.changeLoading,
        changeError: courierForm.changeError,
        points: dictionaryPoints.data,
        pointsLoading: dictionaryPoints.loading,
        pointsError: dictionaryPoints.error
    }
};

const mapDispatchToProps = {
    changePointCourierForm,
    getDictionaryPoints
};


export default connect(mapStateToProps, mapDispatchToProps)(CourierChangePointContainer);
