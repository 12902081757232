import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import queryString from "query-string";
import {getPenalties} from "../store/actions/penalties";
import {IPenalty, IPenaltyFilterRequest, IPenaltySortRequest} from "../models/Penalty";
import _ from "lodash";
import TableGrid from "../components/UI/TableGrid";
import Empty from "../components/UI/Empty";
import {penaltiesSortReSelector, penaltiesFilterReSelector} from "../store/reselectors/penalties";
import {closePenaltyForm, openPenaltyForm} from "../store/actions/penaltyForm";
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import PenaltyEdit from "./PenaltyEdit";
import Icon from "../components/UI/Icon";
import PenaltyDelete from "./PenaltyDelete";



type PenaltiesType = {
    getPenalties: (form?: IPenaltyFilterRequest & IPenaltySortRequest) => void
    data: IPenalty[]
    loading: boolean
    error: string
    filter?: IPenaltyFilterRequest
    sort?: IPenaltySortRequest
    mode: 'add' | 'edit' | 'delete' | null,
    modeId: number | null
    closePenaltyForm: () => void
    openPenaltyForm: (mode: 'edit' | 'delete', modeId: number) => void
}


class PenaltiesContainer extends Component<PenaltiesType & RouteComponentProps> {

    componentDidMount() {
        const {getPenalties, filter, sort} = this.props;
        getPenalties(!_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
    }

    componentDidUpdate(prevProps: Readonly<PenaltiesType & RouteComponentProps>) {
        const {filter, sort, getPenalties} = this.props;
        if (!_.isEqual(prevProps.filter, filter) || !_.isEqual(prevProps.sort, sort)) {
            getPenalties(!_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
        }
    }

    changeHandler = <T extends any>(form: T) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const params = _.pick(queryParams, ['id', 'name', 'description', 'price', 'orderBy']);

        const filter = _.pickBy({...params, ...form}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        const nextQueryParams = queryString.stringify(filter);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    filterHandler = (form: IPenaltyFilterRequest) => {
        this.changeHandler<IPenaltyFilterRequest>(form)
    };

    sortHandler = (form: IPenaltySortRequest) => {
        const orderBy = form ? Object.keys(form)[0] + '_' + Object.values(form)[0] : undefined;
        this.changeHandler<{ [key: string]: string | undefined }>({orderBy})
    };

    render() {
        const {
            data, loading, error, getPenalties, filter, sort, mode, modeId, openPenaltyForm, closePenaltyForm
        } = this.props;

        const filterData = filter;
        const sortData = sort && sort.orderBy;

        return (
            <>
                <TableGrid
                    sourceData={data}
                    filterData={filterData}
                    sortData={sortData}
                    columns={[
                        {
                            label: 'ID',
                            key: 'id',
                            filter: {
                                type: 'number',
                                handler: this.filterHandler
                            },
                            sort: {
                                handler: this.sortHandler
                            },
                            width: 100,
                        },
                        {
                            label: 'Название',
                            key: 'name',
                            filter: {
                                type: 'text',
                                handler: this.filterHandler
                            },
                            sort: {
                                handler: this.sortHandler
                            },
                        },
                        {
                            label: 'Описание',
                            key: 'description',
                            filter: {
                                type: 'text',
                                handler: this.filterHandler
                            },
                            sort: {
                                handler: this.sortHandler
                            },
                        },
                        {
                            label: 'Цена',
                            key: 'price',
                            filter: {
                                type: 'number',
                                handler: this.filterHandler
                            },
                            sort: {
                                handler: this.sortHandler
                            },
                            width: 100
                        },
                        {
                            label: 'Действия',
                            render: ({id}: IPenalty) => {
                                return (
                                    <ButtonGroup>
                                        <Button onClick={() => openPenaltyForm('edit', id)}
                                                size={'sm'} color={'outline-light'}>
                                            <Icon name={'edit'} color={'gray-500'}/>
                                        </Button>
                                        <Button onClick={() => openPenaltyForm('delete',id)}
                                                size={'sm'} color={'outline-light'}>
                                            <Icon name={'delete'} color={'gray-500'}/>
                                        </Button>
                                    </ButtonGroup>
                                )
                            },
                            width: 100
                        }
                    ]}
                />
                {loading && <Loader/>}
                {error && <Error
                    error={error}
                    refresh={() => getPenalties(!_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)}
                />}
                {!data.length && !loading && !error &&
                <Empty>
                    <h3>Список штрафов пуст</h3>
                    <p className={'mb-0'}>Чтобы добавить штраф, нажмите кнопку «Добавить штраф»</p>
                </Empty>}

                {modeId !== null && <Modal isOpen={mode === 'edit'} toggle={closePenaltyForm}>
                    <ModalHeader toggle={closePenaltyForm}>
                        Редактирование штрафа
                    </ModalHeader>
                    <ModalBody>
                        <PenaltyEdit id={modeId} cancelHandler={closePenaltyForm}/>
                    </ModalBody>
                </Modal>}
                {modeId !== null && <Modal isOpen={mode === 'delete'} toggle={closePenaltyForm}>
                    <ModalHeader toggle={closePenaltyForm}>
                        Удалить штраф?
                    </ModalHeader>
                    <ModalBody>
                        <PenaltyDelete id={modeId} cancelHandler={closePenaltyForm}/>
                    </ModalBody>
                </Modal>}
            </>
        )
    }
}


const mapStateToProps = (state: AppState, props: RouteComponentProps) => {
    const {data, loading, error} = state.penalties;
    const {mode, modeId} = state.penaltyForm;

    return {
        data,
        filter: penaltiesFilterReSelector(props),
        sort: penaltiesSortReSelector(props),
        loading,
        error,
        mode,
        modeId
    }
};

const mapDispatchToProps = {
    getPenalties,
    openPenaltyForm,
    closePenaltyForm
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(PenaltiesContainer);
