export const GET_ORDERS = Symbol('GET_ORDERS');
export const GET_ORDERS_REQUEST = Symbol('GET_ORDERS_REQUEST');
export const GET_ORDERS_SUCCESS = Symbol('GET_ORDERS_SUCCESS');
export const GET_ORDERS_ERROR = Symbol('GET_ORDERS_ERROR');

export const CHANGE_STATUS_ORDERS = Symbol('CHANGE_STATUS_ORDERS');
export const CHANGE_STATUS_ORDERS_SUCCESS = Symbol('CHANGE_STATUS_ORDERS_SUCCESS');

export const SSE_ORDERS = Symbol('SSE_ORDERS');

export const TOGGLE_ACTIVE_ORDERS = Symbol('TOGGLE_ACTIVE_ORDERS');

