import {
    ADD_POINT_FORM_REQUEST,
    ADD_POINT_FORM_SUCCESS,
    ADD_POINT_FORM_ERROR,
    EDIT_POINT_FORM_REQUEST,
    EDIT_POINT_FORM_SUCCESS,
    EDIT_POINT_FORM_ERROR,
    GET_POINT_FORM_REQUEST,
    GET_POINT_FORM_SUCCESS,
    GET_POINT_FORM_ERROR,
    DELETE_POINT_FORM_ERROR,
    DELETE_POINT_FORM_SUCCESS,
    DELETE_POINT_FORM_REQUEST, OPEN_POINT_FORM, CLOSE_POINT_FORM
} from '../constants/pointForm'
import {IActionPointForm} from "../actions/pointForm";
import {IPoint} from "../../models/Point";



export interface IPointFormState {
    getLoading: boolean
    getError: string
    data: IPoint | null
    changeLoading: boolean
    changeError: string
    mode: 'add' | 'edit' | 'delete' | null
    modeId: number | null
}


const initialState: IPointFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null,
    modeId: null
};

export default function pointForm(state = initialState, action: IActionPointForm): IPointFormState {
    switch (action.type) {
        case GET_POINT_FORM_REQUEST:
            return {
                ...state,
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_POINT_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_POINT_FORM_ERROR:
            return {
                ...state,
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case ADD_POINT_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_POINT_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_POINT_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case EDIT_POINT_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case EDIT_POINT_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case EDIT_POINT_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case DELETE_POINT_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case DELETE_POINT_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case DELETE_POINT_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_POINT_FORM:
            const {mode, modeId = null} = action.payload;
            return {
                ...initialState,
                mode,
                modeId
            };
        case CLOSE_POINT_FORM:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
