import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import {RouteComponentProps} from "react-router";
import CompanyBranchEdit from "../../containers/CompanyBranchEdit";

type RouteParamsType = {sectionId:string, id:string }

class CompanyBranchEditPage extends Component<RouteComponentProps<RouteParamsType>> {
    render() {
        const {match} = this.props;
        const id = +match.params.id;
        const sectionId = +match.params.sectionId;

        return (
            <Layout>
                <Title>Новый филиал</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Контрагенты', url: '/companies'},
                    {name: 'Контрагент', url: `/companies/${sectionId}`},
                    {name: 'Редактировать филиал', url: `/companies/${sectionId}/branches/edit/${id}`}
                ]}/>
                <CompanyBranchEdit id={id}/>
            </Layout>
        );
    }
}

export default CompanyBranchEditPage;
