import {Api} from "./Api";
import {
    IPaymentFilterRequest,
    IPaymentSortRequest,
    IPaymentAddFormRequest,
    IPaymentEditFormRequest
} from "../models/Payment";


export class PaymentService {
    getPayments(form?: IPaymentFilterRequest & IPaymentSortRequest) {
        return new Api().call(`/v1/manage/pay/search`, {method: 'POST', data: form});
    }
    addPayment(companyId:number, form: IPaymentAddFormRequest) {
        return new Api().call(`/v1/manage/pay?companyId=${companyId}`, {method: 'POST', data: form});
    }
    editPayment(id:number, form: IPaymentEditFormRequest) {
        return new Api().call(`/v1/manage/pay/${id}`, {method: 'POST', data: form});
    }
    getPayment(id:number) {
        return new Api().call(`/v1/manage/pay/${id}`, {method: 'GET'});
    }
    deletePayment(id:number) {
        return new Api().call(`/v1/manage/pay/${id}`, {method: 'DELETE'});
    }
}
