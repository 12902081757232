import {Api} from "./Api";
import {
    IEmployeeAddFormRequest,
    IEmployeeChangePasswordFormRequest,
    IEmployeeEditFormRequest,
    IEmployeeRequest
} from "../models/Employee";

export class EmployeeService {
    getEmployees(page: number = 1, form?: IEmployeeRequest) {
        return new Api().call(`/v1/manage/employee/search?page=${page}`, {method: 'POST', data: form});
    }

    addEmployee(form: IEmployeeAddFormRequest) {
        return new Api().call(`/v1/manage/employee`, {method: 'POST', data: form});
    }

    editEmployee(id: number, form: IEmployeeEditFormRequest) {
        return new Api().call(`/v1/manage/employee/${id}`, {method: 'POST', data: form});
    }

    getEmployee(id: number, expand?: string) {
        const params = expand ? `?expand=${expand}` : ``;
        return new Api().call(`/v1/manage/employee/${id}${params}`, {method: 'GET'});
    }

    deleteEmployee(id: number) {
        return new Api().call(`/v1/manage/employee/${id}`, {method: 'DELETE'});
    }

    changePasswordEmployee(id: number, form: IEmployeeChangePasswordFormRequest) {
        return new Api().call(`/v1/manage/employee/${id}/change-password`, {method: 'POST', data: form});
    }

    uploadEmployeeDocument(id: number, data: FormData) {

        const token = localStorage.getItem('auth');

        let authorization = {};

        if (!!localStorage.getItem('auth')) {
            authorization = {'Authorization': `Bearer ${token}`}
        }

        return new Api().call(`/v1/manage/employee/${id}/upload-file`,
            {
                method: 'POST',
                headers: {
                    'Content-type': 'multipart/form-data',
                    ...authorization
                },
                data
            })
    }

    deleteEmployeeDocument(id: number, documentId : number) {
        return new Api().call(`/v1/manage/employee/${id}/delete-file/${documentId }`, {method: 'DELETE'})
    }
    
    uploadEmployeePhoto(id: number, data: FormData) {
        
        const token = localStorage.getItem('auth');
        
        let authorization = {};
        
        if (!!localStorage.getItem('auth')) {
            authorization = {'Authorization': `Bearer ${token}`}
        }
        
        return new Api().call(`/v1/manage/employee/${id}/upload-photo`,
          {
              method: 'POST',
              headers: {
                  'Content-type': 'multipart/form-data',
                  ...authorization
              },
              data
          })
    }
    
    deleteEmployeePhoto(id: number) {
        return new Api().call(`/v1/manage/employee/${id}/delete-photo`, {method: 'DELETE'})
    }
}
