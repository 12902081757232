import {
    ADD_PAYMENT_FORM,
    ADD_PAYMENT_FORM_REQUEST,
    ADD_PAYMENT_FORM_SUCCESS,
    ADD_PAYMENT_FORM_ERROR,
    EDIT_PAYMENT_FORM_REQUEST,
    EDIT_PAYMENT_FORM_SUCCESS,
    EDIT_PAYMENT_FORM_ERROR,
    EDIT_PAYMENT_FORM,
    GET_PAYMENT_FORM,
    GET_PAYMENT_FORM_REQUEST,
    GET_PAYMENT_FORM_SUCCESS,
    GET_PAYMENT_FORM_ERROR,
    DELETE_PAYMENT_FORM_REQUEST,
    DELETE_PAYMENT_FORM_SUCCESS,
    DELETE_PAYMENT_FORM_ERROR,
    DELETE_PAYMENT_FORM, OPEN_PAYMENT_FORM, CLOSE_PAYMENT_FORM
} from '../constants/paymentForm'
import {IAction} from "./index";
import {IPayment, IPaymentAddFormRequest, IPaymentEditFormRequest} from "../../models/Payment";


export interface IActionPaymentForm extends IAction<symbol, any> {

}


export const addPaymentFormRequest = () => {
    return {
        type: ADD_PAYMENT_FORM_REQUEST
    }
};

export const addPaymentFormSuccess = (payload: IPayment) => {
    return {
        type: ADD_PAYMENT_FORM_SUCCESS,
        payload
    }
};

export const addPaymentFormError = (payload: string) => {
    return {
        type: ADD_PAYMENT_FORM_ERROR,
        payload
    }
};

export const addPaymentForm = (id:number, form: IPaymentAddFormRequest) => {
    return {
        type: ADD_PAYMENT_FORM,
        payload:{
            id,
            form
        }
    };
};

export const editPaymentFormRequest = () => {
    return {
        type: EDIT_PAYMENT_FORM_REQUEST
    }
};

export const editPaymentFormSuccess = (payload: IPayment) => {
    return {
        type: EDIT_PAYMENT_FORM_SUCCESS,
        payload
    }
};

export const editPaymentFormError = (payload: string) => {
    return {
        type: EDIT_PAYMENT_FORM_ERROR,
        payload
    }
};

export const editPaymentForm = (id: number, form: IPaymentEditFormRequest) => {
    return {
        type: EDIT_PAYMENT_FORM,
        payload: {id, form}
    };
};

export const getPaymentFormRequest = () => {
    return {
        type: GET_PAYMENT_FORM_REQUEST
    }
};

export const getPaymentFormSuccess = (payload: IPayment) => {
    return {
        type: GET_PAYMENT_FORM_SUCCESS,
        payload
    }
};

export const getPaymentFormError = (payload: string) => {
    return {
        type: GET_PAYMENT_FORM_ERROR,
        payload
    }
};

export const getPaymentForm = (payload: number) => {
    return {
        type: GET_PAYMENT_FORM,
        payload
    };
};

export const deletePaymentFormRequest = () => {
    return {
        type: DELETE_PAYMENT_FORM_REQUEST
    }
};

export const deletePaymentFormSuccess = () => {
    return {
        type: DELETE_PAYMENT_FORM_SUCCESS
    }
};

export const deletePaymentFormError = (payload: string) => {
    return {
        type: DELETE_PAYMENT_FORM_ERROR,
        payload
    }
};

export const deletePaymentForm = (payload: number) => {
    return {
        type: DELETE_PAYMENT_FORM,
        payload
    };
};

export const openPaymentForm = (mode: 'add' | 'edit' | 'delete', modeId?: number) => {
    return {
        type: OPEN_PAYMENT_FORM,
        payload:{
            mode,
            modeId
        }
    };
};

export const closePaymentForm = () => {
    return {
        type: CLOSE_PAYMENT_FORM
    };
};
