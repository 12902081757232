import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getCouriersLocationsError,
    getCouriersLocationsRequest,
    getCouriersLocationsSuccess
} from "../actions/couriersLocations";
import {ICourier, ICourierResponse} from "../../models/Courier";
import {CourierService} from "../../services/CourierService";
import {GET_COURIERS_LOCATIONS} from "../constants/couriersLocations";


type getCouriersLocationsType = {
    type: typeof GET_COURIERS_LOCATIONS,
    payload: number
};

function* getCouriersLocationsSaga({payload}: getCouriersLocationsType) {
    try {
        yield put(getCouriersLocationsRequest());

        const service = new CourierService();

        const promise = yield call(service.getCouriersLocations, payload);
        const response: ICourierResponse[] = promise.data || [];

        const transform = response.map((response)=>{
            return response.location ? {...response, location: {...response.location, lat: +response.location.lat, long: +response.location.long}}: response;
        });

        yield put(getCouriersLocationsSuccess(transform as ICourier[]));

    } catch (error) {
        yield put(getCouriersLocationsError(error.response.data.message || 'Ошибка'));
    }
}


export function* watchGetCouriersLocations() {
    yield takeLatest(GET_COURIERS_LOCATIONS, getCouriersLocationsSaga);
}
