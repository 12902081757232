import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {ICourierPenaltyAddFromRequest} from "../models/Courier";
import {addCourierPenaltyForm, closeCourierPenaltyForm} from "../store/actions/courierPenaltyForm";
import CourierPenaltyAdd from "../components/CourierPenaltyAdd";
import {getPenalties} from "../store/actions/penalties";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {IPenalty} from "../models/Penalty";
import Empty from "../components/UI/Empty";


type CourierPenaltyAddType = {
    id: number
    getPenalties: () => void
    addCourierPenaltyForm: (id: number, form: ICourierPenaltyAddFromRequest) => void
    closeCourierPenaltyForm: () => void
    changeLoading: boolean
    changeError: string
    penalties:IPenalty[]
    penaltiesLoading:boolean
    penaltiesError:string
}

class CourierPenaltyAddContainer extends Component<CourierPenaltyAddType> {
    componentDidMount() {
        const {getPenalties} = this.props;
        getPenalties()
    }

    render() {
        const {id, changeLoading, changeError, addCourierPenaltyForm, closeCourierPenaltyForm, getPenalties,
            penalties, penaltiesLoading, penaltiesError} = this.props;

        if (penaltiesLoading) {
            return <Loader/>
        }

        if (penaltiesError) {
            return <Error error={penaltiesError} refresh={getPenalties}/>
        }

        if(!!penalties.length) {
            return (
                <CourierPenaltyAdd
                    id={id}
                    penalties={penalties}
                    addCourierPenaltyForm={addCourierPenaltyForm}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    cancelHandler={closeCourierPenaltyForm}
                />
            )
        }
        return (
            <Empty>
                <h3>Список видов штрафов пуст</h3>
                <p className={'mb-0'}>Чтобы добавить штраф курьеру, добавьте виды штрафов</p>
            </Empty>
        )
    }
}

const mapStateToProps = ({courierPenaltyForm, penalties}: AppState) => {
    const {changeLoading, changeError} = courierPenaltyForm;
    return {
        changeLoading,
        changeError,
        penalties: penalties.data,
        penaltiesLoading: penalties.loading,
        penaltiesError: penalties.error
    }
};

const mapDispatchToProps = {
    addCourierPenaltyForm,
    closeCourierPenaltyForm,
    getPenalties
};


export default connect(mapStateToProps, mapDispatchToProps)(CourierPenaltyAddContainer);
