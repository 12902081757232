import _ from "lodash";

export const sanitizeForm = (field: any) => {

    if (field === null || typeof field === 'undefined') {
        return null
    }

    if (typeof field === 'string') {
        return field.length > 0 ? field : null
    }

    if (typeof field === 'boolean') {
        return field
    }
    if (typeof field === 'number') {
        return field
    }

    if (_.isObjectLike(field)) {
        return !_.isEmpty(field) ? field : null
    }
};
