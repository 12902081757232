import {ILocation} from "../../models/Order";
import {
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_ERROR,
} from "../constants/location";
import {IActionLocation} from "../actions/location";

export interface ILocationState {
  data: ILocation | null;
  getLoading: boolean;
  getError: string;
}

const initialState: ILocationState = {
  data: null,
  getLoading: false,
  getError: '',
}

export default function location(
  state = initialState,
  action: IActionLocation,
): ILocationState {
  switch (action.type) {
    case GET_LOCATION_REQUEST:
      return {
        data: null,
        getLoading: true,
        getError: '',
      };
    case GET_LOCATION_SUCCESS:
      return {
        data: action.payload,
        getLoading: false,
        getError: ''
      };
    case GET_LOCATION_ERROR:
      return {
        data: null,
        getLoading: false,
        getError: action.payload
      };
    default:
      return state;
  }
}
