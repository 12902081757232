import React, {PureComponent} from 'react';
import {NavLink} from 'react-router-dom';
import classNames from 'classnames';
import Icon from '../Icon';
import styles from './Menu.module.scss';


type MenuElementItemType = {
    title: string
    url: string
}
const MenuElementItem: React.FC<MenuElementItemType> = ({title, url}) => (
    <li>
        <NavLink className={classNames(styles['menu-item'])} to={url} activeClassName={styles['menu-item--active']}>
            <div className={styles['menu-item__name']}>
                {title}
            </div>
        </NavLink>
    </li>
);

type MenuElementType = {
    title: string
    url: string
    icon?: string
    Item?: typeof MenuElementItem
}

class MenuElement extends PureComponent<MenuElementType> {
    static Item = MenuElementItem;

    state = {
        active: false
    };

    componentDidMount() {
        const {url} = this.props;
        const currentUrl = window.location.pathname;
        if (currentUrl.substr(1).split('/')[0].includes(url.substr(1))) {
            this.toggleSubmenu();
        }
    }

    toggleSubmenu = () => {
        this.setState(({active}: { active: boolean }) => ({
            active: !active
        }))
    };

    render() {
        const {title, url, icon, children} = this.props;
        const {active} = this.state;

        if (children) {
            return (
                <li>
                    <div className={classNames(styles['menu-item'], active && styles['menu-item--active'])} onClick={this.toggleSubmenu}>
                        {icon && <div className={styles['menu-item__icon']}>
                            <Icon name={icon}/>
                        </div>}
                        <div className={styles['menu-item__name']}>
                            {title}
                        </div>
                    </div>
                    <ul className={classNames(
                        styles['menu-submenu'], active && styles['menu-submenu--open']
                    )}>
                        {children}
                    </ul>
                </li>
            )
        }
        return (
            <li>
                <NavLink className={classNames(styles['menu-item'])} to={url}
                         activeClassName={styles['menu-item--active']}>
                    {icon && <div className={styles['menu-item__icon']}>
                        <Icon name={icon}/>
                    </div>}
                    <div className={styles['menu-item__name']}>
                        {title}
                    </div>
                </NavLink>
            </li>
        );
    }
}


type MenuType = React.FC & {
    Element: typeof MenuElement
}
const Menu: MenuType = ({children}) => {
    return (
        <ul className={classNames(styles.menu)}>
            {children}
        </ul>
    );
};


Menu.Element = MenuElement;


export default Menu;
