import * as types from "../constants/employeePhotos"
import {IAction} from "../actions";

export interface addEmployeePhotosState {
  isLoaded: boolean;
  loading: boolean;
  error: string;
}

const initialState: addEmployeePhotosState = {
  isLoaded: false,
  loading: false,
  error: '',
}

export default function employeePhotosAdd(
  state = initialState,
  action: IAction<symbol, any>,
): addEmployeePhotosState {
  switch (action.type) {
    case types.ADD_EMPLOYEE_PHOTOS_REQUEST:
      return {
        loading: true,
        error: '',
        isLoaded: false
      }
    case types.ADD_EMPLOYEE_PHOTOS_SUCCESS:
      return {
        loading: false,
        error: '',
        isLoaded: true
      }
    case types.ADD_EMPLOYEE_PHOTOS_ERROR:
      return {
        loading: false,
        error: action.payload,
        isLoaded: false
      }
    default:
      return state;
  }
}
