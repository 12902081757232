import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {getOrder, clearOrder} from "../store/actions/order";
import {IOrder} from "../models/Order";
import Order from "../components/Order";
import {closeOrderForm, openOrderForm} from "../store/actions/orderForm";


type OrderType = {
    openOrderForm: (mode: 'coordinate') => void
    closeOrderForm: () => void
    getOrder: (id: string) => void
    clearOrder: () => void
    data: IOrder | null
    getLoading: boolean
    getError: string
    id: string
    mode: 'coordinate' | null
    accesses?: string[]
    isArchive?:boolean
}

class OrderContainer extends Component<OrderType> {

    componentDidMount() {
        const {id, getOrder} = this.props;

        getOrder(id)
    }

    componentWillUnmount() {
        const {clearOrder} = this.props;
        clearOrder()
    }

    render() {
        const {
            data, getLoading, getError, id, getOrder, openOrderForm, closeOrderForm, mode, accesses, isArchive
        } = this.props;

        if (getLoading) {
            return <Loader/>
        }

        if (getError) {
            return <Error error={getError} refresh={() => getOrder(id)}/>
        }

        if (data !== null) {
            return (
                <Order
                    mode={mode}
                    closeOrderForm={closeOrderForm}
                    openOrderForm={openOrderForm}
                    data={data}
                    accesses={accesses}
                    isArchive={isArchive}
                />
            )
        }
        return null
    }
}


const mapStateToProps = ({order, orderForm, auth}: AppState) => {
    return {
        data: order.data,
        getLoading: order.getLoading,
        getError: order.getError,
        mode: orderForm.mode,
        accesses: auth.user !== null ? auth.user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getOrder,
    clearOrder,
    openOrderForm,
    closeOrderForm
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);
