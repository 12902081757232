export const GET_RATIO_RATE = Symbol('GET_RATIO_RATE');
export const GET_RATIO_RATE_REQUEST = Symbol('GET_RATIO_RATE_REQUEST');
export const GET_RATIO_RATE_SUCCESS = Symbol('GET_RATIO_RATE_SUCCESS');
export const GET_RATIO_RATE_ERROR = Symbol('GET_RATIO_RATE_ERROR');
export const CHANGE_RATIO_RATE = Symbol('CHANGE_RATIO_RATE');
export const CHANGE_RATIO_RATE_REQUEST = Symbol('CHANGE_RATIO_RATE_REQUEST');
export const CHANGE_RATIO_RATE_SUCCESS = Symbol('CHANGE_RATIO_RATE_SUCCESS');
export const CHANGE_RATIO_RATE_ERROR = Symbol('CHANGE_RATIO_RATE_ERROR');
export const DELETE_RATIO_RATE = Symbol('DELETE_RATIO_RATE');
export const DELETE_RATIO_RATE_REQUEST = Symbol('DELETE_RATIO_RATE_REQUEST');
export const DELETE_RATIO_RATE_SUCCESS = Symbol('DELETE_RATIO_RATE_SUCCESS');
export const DELETE_RATIO_RATE_ERROR = Symbol('DELETE_RATIO_RATE_ERROR');