import React, {PureComponent} from 'react';
import {ISchedule, IScheduleDate} from "../../models/Schedule";
import Table from '../UI/Table';
import {IDictionary} from "../../models/Dictionary";
import {getDictionary} from "../../utils/dictionaries";
import {parseISO, format} from 'date-fns';
import {Link} from "react-router-dom";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import Avatar from "../UI/Avatar";
import {courierTypeIcons} from "../../utils/couriers";
import CourierLocation from "../../containers/CourierLocation";
import Icon from "../UI/Icon";
import {shiftOptions} from "../../utils/schedules";
import styles from "./ScheduleDate.module.scss";
import classNames from "classnames";

type ScheduleDateType = {
    data: {
        [key: string]: IScheduleDate[];
    };
    points: IDictionary[];
    /** показывать ли колонку "Смена" */
    showSlot: boolean;
}

type confirmCouriersType = Pick<ISchedule, 'confirmCouriers'>;

type ScheduleDateState = {
    courierId: number | null;
}

class ScheduleDate extends PureComponent<ScheduleDateType & confirmCouriersType, ScheduleDateState> {
    
    state: ScheduleDateState = {
        courierId: null,
    }
    
    openCourierLocationDialog = (courierId: number) => {
        this.setState({courierId})
    };
    
    closeCourierLocationDialog = () => {
        this.setState({courierId: null})
    };
    
    render() {
        const {data, confirmCouriers, points, showSlot} = this.props;
        const {courierId} = this.state;
        
        const confirmCourierIds = Array.isArray(confirmCouriers) ? confirmCouriers.map(({courierId}) => courierId) : [];
        
        const iconColWidth = 66;
        
        return (
          <>
              {Object.keys(data).map((key, index) => {
                  const dictionaryPoint = getDictionary(points, key);
                  const name = dictionaryPoint ? dictionaryPoint.value : undefined;
                  
                  const allCount = data[key].length;
                  const confirmedCount = confirmCouriers
                    ? data[key].filter(({courierId}) => confirmCouriers.find((o) => o.courierId === courierId)?.isConfirmed).length
                    : 0;
                  const notConfirmedCount = allCount - confirmedCount;
                  
                  return (
                    <React.Fragment key={key}>
                        <div className={index + 1 !== Object.keys(data).length ? 'mb-4' : ''}>
                            <h5 className={'mb-1'}>
                                {name}
                                <span className='ml-3'>{allCount} / <span className='green'>{confirmedCount}</span> / <span className='red'>{notConfirmedCount}</span></span>
                            </h5>
                            
                            <Table noBorder size={'large'}>
                                <thead>
                                    <tr className={styles['row']}>
                                        <th className={styles['row-cell--small']}>
                                            Время работы
                                        </th>
                                        <th className={styles['row-cell--small']}>
                                            Время перерыва
                                        </th>
                                        {showSlot && <th className={styles['row-cell--small']}>
                                            Смена
                                        </th>}
                                        <th>
                                            Курьер
                                        </th>
                                        <th className={styles['row-cell--medium']}>
                                            Подтверждение
                                        </th>
                                        <th style={{width: iconColWidth, minWidth: iconColWidth}}></th>
                                    </tr>
                                    </thead>
                                <tbody>
                                {shiftOptions.map(({value, label}) => {
                                    
                                    const thisShiftData = data[key].filter(({shiftId}) => shiftId === value);
    
                                    const allCount = thisShiftData.length;
                                    const confirmedCount = confirmCouriers
                                      ? thisShiftData.filter(({courierId}) => confirmCouriers.find((o) => o.courierId === courierId)?.isConfirmed).length
                                      : 0;
                                    const notConfirmedCount = allCount - confirmedCount;
                                    
                                    return <React.Fragment key={value}>
                                        <tr>
                                            <td className={classNames('semi-bold', styles['text-bigger'])}>
                                                <span>{label}</span>
                                                <span className='ml-3'>{allCount} / <span className='green'>{confirmedCount}</span> / <span className='red'>{notConfirmedCount}</span></span>
                                            </td>
                                        </tr>
                                        {thisShiftData.map((data: IScheduleDate) => {
                                            const key = JSON.stringify(data);
                                            return (
                                              <ScheduleDateItem
                                                key={key}
                                                data={data}
                                                confirmCouriers={confirmCouriers}
                                                confirmCourierIds={confirmCourierIds}
                                                showSlot={showSlot}
                                                openCourierLocationDialog={this.openCourierLocationDialog}
                                              />
                                            )
                                        })}
                                        {!thisShiftData.length && <tr>
                                            <td className='bg-white semi-bold'>-</td>
                                        </tr>}
                                    </React.Fragment>
                                })}
                                </tbody>
                            </Table>
                        </div>
                        <Modal isOpen={!!courierId} size={'lg'} toggle={this.closeCourierLocationDialog}>
                            <ModalHeader toggle={this.closeCourierLocationDialog}>
                                Местоположение курьера
                            </ModalHeader>
                            <ModalBody>
                                {typeof courierId === "number" && <CourierLocation
                                  id={courierId}
                                />}
                            </ModalBody>
                        </Modal>
                    </React.Fragment>
                  )
              })
              }
          </>
        )
    }
}


type ScheduleDateItemType = {
    data: IScheduleDate;
    confirmCourierIds: number[];
    openCourierLocationDialog: (id: number) => void;
} & Pick<ScheduleDateType, 'showSlot'>;

class ScheduleDateItem extends PureComponent<ScheduleDateItemType & confirmCouriersType> {
    render() {
        const {
            data,
            confirmCouriers,
            confirmCourierIds,
            showSlot,
            openCourierLocationDialog,
        } = this.props;
        
        const {
            courierId,
            timeStart,
            timeEnd,
            breakTimeStart,
            breakTimeEnd,
            slot,
            courier,
        } = data;

        const confirmCourier = confirmCourierIds.includes(courierId) && Array.isArray(confirmCouriers)
          ? confirmCouriers.find((o) => o.courierId === courierId)
          : undefined;

        const courierName = courier?.fio;
        const courierTypeIcon = typeof courier?.courierTypeId === "number"
          ? courierTypeIcons[courier.courierTypeId]
          : undefined;

        const confirmedAt = confirmCourier && confirmCourier.isConfirmed ? format(parseISO(confirmCourier.confirmedAt), 'yyyy-MM-dd HH:mm') : undefined;
        
        const breakTime = !breakTimeStart && !breakTimeEnd
          ? `-`
          : `${breakTimeStart || '...'} - ${breakTimeEnd || '...'}`;
    
        /**
         * Если смена началась позже начала времени работы, показать опоздание.
         * Для времени формата HH:mm в 24ч формате простое сравнение строк работает для такой проверки.
         */
        const isSlotLate = slot?.createdAt && slot.createdAt > timeStart;
        
        const slotTime = slot
          ? <><span className={isSlotLate ? 'red' : undefined}>{slot.createdAt || '...'}</span> - {slot.finishedAt || '...'}</>
          : '-';
        
        return (
            <tr className={styles['striped']}>
                <td>{timeStart} - {timeEnd}</td>
                <td>{breakTime}</td>
                {showSlot && <td>{slotTime}</td>}
                <td>
                    <Row form className="align-items-center">
                        {!!courierTypeIcon && <Col md={'auto'}>
                            <Avatar
                              icon={courierTypeIcon}
                            />
                        </Col>}
                        <Col md={'auto'}>
                            <Link to={`/couriers/${courierId}`} target={"_blank"} className={"semi-bold"}>
                                {courierName}
                            </Link>
                        </Col>
                    </Row>
                </td>
                <td>
                    {confirmCourier && confirmCourier.isConfirmed ?
                        <span className={'green'}>
                            Подтвержден ({confirmedAt})
                        </span>
                        :
                        <span className={'red'}>Не подтвержден</span>}
                </td>
                <td>
                    <Button
                      size={'sm'}
                      color={'outline-light'}
                      onClick={() => openCourierLocationDialog(courierId)}>
                        <Icon name={'location_on'} color={'gray-500'}/>
                    </Button>
                </td>
            </tr>
        );
    }
}

export default ScheduleDate;
