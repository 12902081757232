import React from 'react';
import styles from './Tile.module.scss';
import classNames from 'classnames';

type TileType = {
    label: string
    value: number
    color: string
}

const Tile: React.FC<TileType> = ({label, value, color}) => (
    <div className={classNames(styles['tile'], color && styles['tile--' + color])}>
        <div className={styles['tile__value']}>{value}</div>
        <div className={styles['tile__label']}>{label}</div>
    </div>
)

export default Tile;