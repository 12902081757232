import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {RouteComponentProps, withRouter} from "react-router";
import {compose} from "redux";
import OrderFilter from "../components/OrderFilter";
import {IDictionary} from "../models/Dictionary";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {getDictionaryCityEagers} from "../store/actions/dictionaryCityEagers";
import queryString from "query-string";
import {IOrderRequest} from "../models/Order";
import {getDictionaryOrderStatuses} from "../store/actions/dictionaryOrderStatuses";
import {ordersFilterReSelector} from "../store/reselectors/orders";


type OrderFilterType = {
    filter?:IOrderRequest
    getDictionaryCityEagers: () => void
    getDictionaryOrderStatuses: () => void
    dictionaryCityEagers: IDictionary[]
    dictionaryCityEagersLoading: boolean
    dictionaryCityEagersError: string
    dictionaryOrderStatuses: IDictionary[]
    dictionaryOrderStatusesLoading: boolean
    dictionaryOrderStatusesError: string
}

class OrderFilterContainer extends Component<OrderFilterType & RouteComponentProps> {

    componentDidMount() {
        const {getDictionaryCityEagers, getDictionaryOrderStatuses} = this.props;
        getDictionaryCityEagers();
        getDictionaryOrderStatuses()
    }

    setFilterHandler = (form:IOrderRequest) => {
        const {history} = this.props;
        const nextQueryParams = queryString.stringify(form);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    render() {
        const {filter, dictionaryCityEagers, dictionaryCityEagersLoading, dictionaryCityEagersError, getDictionaryCityEagers,
            dictionaryOrderStatuses, dictionaryOrderStatusesLoading,dictionaryOrderStatusesError, getDictionaryOrderStatuses} = this.props;

        if (dictionaryCityEagersLoading || dictionaryOrderStatusesLoading) {
            return <Loader/>
        }

        if (dictionaryCityEagersError) {
            return <Error error={dictionaryCityEagersError} refresh={getDictionaryCityEagers}/>
        }

        if (dictionaryOrderStatusesError) {
            return <Error error={dictionaryOrderStatusesError} refresh={getDictionaryOrderStatuses}/>
        }

        if (!!dictionaryCityEagers.length && !!dictionaryOrderStatuses.length) {
            return <OrderFilter
                filter={filter}
                dictionaryCityEagers={dictionaryCityEagers}
                dictionaryOrderStatuses={dictionaryOrderStatuses}
                setFilterHandler={this.setFilterHandler}
            />
        }
        return null
    }
}

const mapStateToProps = ({dictionaryCityEagers, dictionaryOrderStatuses}: AppState, props: RouteComponentProps) => {
    return {
        filter:ordersFilterReSelector(props),
        dictionaryCityEagers: dictionaryCityEagers.data,
        dictionaryCityEagersLoading: dictionaryCityEagers.loading,
        dictionaryCityEagersError: dictionaryCityEagers.error,
        dictionaryOrderStatuses: dictionaryOrderStatuses.data,
        dictionaryOrderStatusesLoading: dictionaryOrderStatuses.loading,
        dictionaryOrderStatusesError: dictionaryOrderStatuses.error
    }
};

const mapDispatchToProps = {
    getDictionaryCityEagers,
    getDictionaryOrderStatuses
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrderFilterContainer);
