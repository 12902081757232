import React, {Component} from "react";
import Login from "../components/Login";
import {connect} from "react-redux";
import {login} from "../store/actions/auth";
import {IAuthRequest} from "../models/Auth";
import {AppState} from "../store/reducers";


type LoginType = {
    login: (props:IAuthRequest) => void,
    loading:boolean,
    error:string,
    isAuthenticated:boolean
}

class LoginContainer extends Component<LoginType> {
    render() {
        const {login, loading, error, isAuthenticated} = this.props;
        return (
            <Login login={login} loading={loading} error={error} isAuthenticated={isAuthenticated}/>
        );
    }
}


const mapStateToProps = ({auth}: AppState) => {
    const {loading, error, isAuthenticated} = auth;
    return {
        loading,
        error,
        isAuthenticated
    }
};

const mapDispatchToProps = {
    login
};


export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
