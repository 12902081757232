import React, {Component} from 'react';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import {customHistory} from "../utils/history";
import PrivateRoute from '../containers/PrivateRoute';
import PointsPage from "../pages/PointsPage";
import LoginPage from '../pages/LoginPage';
import PointZonesPage from '../pages/PointZonesPage';
import PointZonesSettingsPage from '../pages/PointZonesSettingsPage';
import {
    accessPointEdit,
    accessPointShow,
    accessDirectoryCities,
    accessCompanyShow,
    accessCompanyCreate,
    accessCompanyEdit,
    accessCompanyBranchShow,
    accessCompanyContactShow,
    accessCompanyBranchCreate,
    accessCompanyBranchEdit,
    accessEmployeeShow,
    accessDirectoryRoles,
    accessScheduleShow,
    accessOrderShow,
    accessOrderEdit,
    accessOrderCreate,
    accessCourierShow,
    accessNotificationManage,
    accessDirectoryPenalties,
    accessReportCompanyManage, accessReportCourierManage, accessPaymentShow,
    accessReportEmenuView, accessOrderChangeCourier, accessCourierSlotManage, courierRateManage, accessSalaryManage
} from "../utils/user-accesses";
import DirectoryCitiesPage from "../pages/DirectoryCitiesPage";
import CompaniesPage from '../pages/CompaniesPage';
import CompanyAddPage from "../pages/CompanyAddPage";
import CompanyEditPage from '../pages/CompanyEditPage';
import CompanyViewPage from "../pages/CompanyViewPage";
import CompanyBranchAddPage from "../pages/CompanyBranchAddPage";
import CompanyBranchEditPage from "../pages/CompanyBranchEditPage";
import EmployeesPage from "../pages/EmployeesPage";
import DirectoriesPage from "../pages/DirectoriesPage";
import DirectoryRolesPage from "../pages/DirectoryRolesPage";
import SchedulesPage from "../pages/SchedulesPage";
import SchedulePage from "../pages/SchedulePage";
import OrdersPage from "../pages/OrdersPage";
import OrderAddPage from "../pages/OrderAddPage";
import OrderEditPage from "../pages/OrderEditPage";
import ArchiveOrdersPage from "../pages/ArchiveOrdersPage";
import CouriersPage from "../pages/CouriersPage";
import CourierPage from "../pages/CourierPage";
import ProfilePage from "../pages/ProfilePage";
import ProfileChangePasswordPage from '../pages/ProfileChangePasswordPage';
import NotificationsPage from "../pages/NotificationsPage";
import DirectoryPenaltiesPage from "../pages/DirectoryPenaltiesPage";
import ReportsPage from "../pages/ReportsPage";
import ReportCompaniesPage from "../pages/ReportCompaniesPage";
import ReportCouriersPage from "../pages/ReportCouriersPage";
import ReportEmenuPage from "../pages/ReportEmenuPage"
import PaymentsPage from "../pages/PaymentsPage";
import CouriersShiftsPage from "../pages/CouriersShiftsPage";
import CourierRatePage from "../pages/CourierRatePage";
import BonusRatePage from "../pages/BonusRatePage";
import RatioRatePage from "../pages/RatioRatePage";
import SalaryPage from '../pages/SalaryPage';

type AppType = {
    defaultPath: string;
}

class App extends Component<AppType> {
    render() {
        const {defaultPath} = this.props;
        
        return (
            <Router history={customHistory}>
                <Switch>
                    <Route path="/login" component={LoginPage}/>

                    <PrivateRoute path="/profile/change-password" component={ProfileChangePasswordPage}/>
                    <PrivateRoute path="/profile" component={ProfilePage}/>

                    <PrivateRoute path="/orders/edit/:id" component={OrderEditPage}
                                  rules={{accesses: [accessOrderEdit, accessOrderChangeCourier]}}/>
                    <PrivateRoute path="/orders/add" component={OrderAddPage}
                                  rules={{accesses: [accessOrderCreate]}}/>
                    <PrivateRoute path="/orders" component={OrdersPage}
                                  rules={{accesses: [accessOrderShow]}}/>
                    <PrivateRoute path="/archive-orders" component={ArchiveOrdersPage}
                                  rules={{accesses: [accessOrderShow]}}/>

                    <PrivateRoute path="/schedules/:id" component={SchedulePage}
                                  rules={{accesses: [accessScheduleShow]}}/>
                    <PrivateRoute path="/schedules" component={SchedulesPage} rules={{accesses: [accessScheduleShow]}}/>
                    <PrivateRoute path="/employees" component={EmployeesPage}
                                  rules={{accesses: [accessEmployeeShow]}}/>
                    <PrivateRoute path="/companies/:sectionId/branches/edit/:id" component={CompanyBranchEditPage}
                                  rules={{accesses: [accessCompanyBranchEdit]}}/>
                    <PrivateRoute path="/companies/:id/branches/add" component={CompanyBranchAddPage}
                                  rules={{accesses: [accessCompanyBranchCreate]}}/>
                    <PrivateRoute path="/companies/edit/:id" component={CompanyEditPage}
                                  rules={{accesses: [accessCompanyEdit]}}/>
                    <PrivateRoute path="/companies/add" component={CompanyAddPage}
                                  rules={{accesses: [accessCompanyCreate]}}/>
                    <PrivateRoute path="/companies/:id" component={CompanyViewPage}
                                  rules={{
                                      accesses: [accessCompanyBranchShow, accessCompanyContactShow],
                                      type: 'some'
                                  }}/>
                    <PrivateRoute path="/companies" component={CompaniesPage} rules={{accesses: [accessCompanyShow]}}/>
                    <PrivateRoute path="/directories/cities" component={DirectoryCitiesPage}
                                  rules={{accesses: [accessDirectoryCities]}}/>
                    <PrivateRoute path="/directories/roles" component={DirectoryRolesPage}
                                  rules={{accesses: [accessDirectoryRoles]}}/>
                    <PrivateRoute path="/directories/penalties" component={DirectoryPenaltiesPage}
                                  rules={{accesses: [accessDirectoryPenalties]}}/>
                    <PrivateRoute path="/directories" component={DirectoriesPage}/>
                    <PrivateRoute path="/points/:id/settings" component={PointZonesSettingsPage}
                                  rules={{accesses: [accessPointShow, accessPointEdit]}}/>
                    <PrivateRoute path="/points/:id" component={PointZonesPage} rules={{accesses: [accessPointShow]}}/>
                    <PrivateRoute path="/points" component={PointsPage} rules={{accesses: [accessPointShow]}}/>

                    <PrivateRoute path="/couriers/:id" component={CourierPage}
                                  rules={{accesses: [accessCourierShow]}}/>
                    <PrivateRoute path="/couriers" component={CouriersPage}
                                  rules={{accesses: [accessCourierShow]}}/>
                    <PrivateRoute path={"/couriers-shifts"} component={CouriersShiftsPage}
                                  rules={{accesses: [accessCourierSlotManage]}}/>

                    <PrivateRoute path="/reports/emenu" component={ReportEmenuPage}
                                  rules={{accesses: [accessReportEmenuView]}}
                    />
                    <PrivateRoute path="/reports/companies" component={ReportCompaniesPage}
                                  rules={{accesses: [accessReportCompanyManage]}}/>
                    <PrivateRoute path="/reports/couriers" component={ReportCouriersPage}
                                  rules={{accesses: [accessReportCourierManage]}}/>
                    <PrivateRoute path="/reports" component={ReportsPage}/>

                    <PrivateRoute path="/payments" component={PaymentsPage}
                                  rules={{accesses: [accessPaymentShow]}}/>

                    <PrivateRoute path="/notifications" component={NotificationsPage}
                                  rules={{accesses: [accessNotificationManage]}}/>

                    <PrivateRoute path="/settings/courier-rate" component={CourierRatePage}
                                  rules={{accesses: [courierRateManage]}}/>

                    <PrivateRoute path="/settings/bonus-rate" component={BonusRatePage}
                                  rules={{accesses: [courierRateManage]}}/>

                    <PrivateRoute path="/settings/ratio-rate" component={RatioRatePage}
                                  rules={{accesses: [courierRateManage]}}/>

                    <PrivateRoute path="/salary" component={SalaryPage}
                                  rules={{accesses: [accessSalaryManage]}}/>

                    <Redirect to={defaultPath}/>

                </Switch>
            </Router>
        );
    }
}

export default App;
