import {Api} from "./Api";
import {
    ICompanyAddFormRequest,
    ICompanyContactAddFormRequest,
    ICompanyEditFormRequest,
    ICompanyFilterRequest,
    ICompanySortRequest,
    ICompanyContactEditFormRequest,
    ICompanyBranchFilterRequest,
    ICompanyBranchSortRequest,
    ICompanyBranchAddFormRequest,
    ICompanyBranchEditFormRequest
} from "../models/Company";


export class CompanyService {
    getCompanies(page: number = 1, form?: ICompanyFilterRequest & ICompanySortRequest) {
        return new Api().call(`/v1/manage/company/search?page=${page}`, {method: 'POST', data: form});
    }

    addCompany(form: ICompanyAddFormRequest) {
        return new Api().call(`/v1/manage/company`, {method: 'POST', data: form});
    }

    editCompany(id: number, form: ICompanyEditFormRequest) {
        return new Api().call(`/v1/manage/company/${id}`, {method: 'POST', data: form});
    }

    getCompany(id: number, expand?:string) {
        const params = expand? `?expand=points`: ``;
        return new Api().call(`/v1/manage/company/${id}${params}`, {method: 'GET'});
    }

    deleteCompany(id: number) {
        return new Api().call(`/v1/manage/company/${id}`, {method: 'DELETE'});
    }


    getCompanyContacts(id: number) {
        return new Api().call(`/v1/manage/company/${id}/contact`, {method: 'GET'});
    }

    addCompanyContact(id: number, form: ICompanyContactAddFormRequest) {
        return new Api().call(`/v1/manage/company/${id}/contact`, {method: 'POST', data: form});
    }

    editCompanyContact(id: number, form: ICompanyContactEditFormRequest) {
        return new Api().call(`/v1/manage/company/contact/${id}`, {method: 'POST', data: form});
    }

    getCompanyContact(id: number) {
        return new Api().call(`/v1/manage/company/contact/${id}`, {method: 'GET'});
    }

    deleteCompanyContact(id: number) {
        return new Api().call(`/v1/manage/company/contact/${id}`, {method: 'DELETE'});
    }


    getCompanyBranches(id: number, page: number = 1, form?: ICompanyBranchFilterRequest & ICompanyBranchSortRequest) {
        return new Api().call(`/v1/manage/company/${id}/branch/search?page=${page}`, {method: 'POST', data:form});
    }

    addCompanyBranch(id: number, form: ICompanyBranchAddFormRequest) {
        return new Api().call(`/v1/manage/company/${id}/branch`, {method: 'POST', data: form});
    }

    editCompanyBranch(id: number, form: ICompanyBranchEditFormRequest) {
        return new Api().call(`/v1/manage/company/branch/${id}`, {method: 'POST', data: form});
    }

    getCompanyBranch(id: number) {
        return new Api().call(`/v1/manage/company/branch/${id}`, {method: 'GET'});
    }

    deleteCompanyBranch(id: number) {
        return new Api().call(`/v1/manage/company/branch/${id}`, {method: 'DELETE'});
    }
}
