import React, {Component} from "react";
import {Formik} from "formik";
import {Button, Col, Form, FormGroup, Label, Row} from "reactstrap";
import DateTimePicker from "../UI/DateTimePicker";
import Dropdown from "../UI/Dropdown";
import _ from "lodash";
import {IDictionary} from "../../models/Dictionary";
import {ISlotFilter} from "../../models/Slot";
import {dateConvert} from "../../utils/date-convert";
import {getDictionaryOptions} from "../../utils/dictionaries";

type CouriersShiftsFilterType = {
    dictionaryCouriers: IDictionary[]
    dictionaryCityEagers: IDictionary[]
    setFilterHandler: (form: ISlotFilter) => void
    filter: ISlotFilter
}

class CouriersShiftsFilter extends Component<CouriersShiftsFilterType> {

    render() {

        const {dictionaryCouriers, dictionaryCityEagers, setFilterHandler, filter} = this.props

        const dictionaryCouriersList = dictionaryCouriers && dictionaryCouriers.map((props: any) => {
            return ({
                label: props.value,
                value: props.key
            })
        })

        const resetValues = {
            cityId: null,
            dateStart: null,
            dateEnd: null,
            courierId: null,
            payTypeId: null
        }

        const initialValues = {...resetValues, ...filter,
            dateStart: filter?.dateStart &&
                dateConvert(filter.dateStart, 'yyyy-MM-dd', 'dd-MM-yyyy'),
            dateEnd: filter?.dateEnd &&
                dateConvert(filter.dateEnd, 'yyyy-MM-dd', 'dd-MM-yyyy'),
        }

        const cityOptions = getDictionaryOptions(dictionaryCityEagers);

        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => {
                    const {cityId, dateStart, dateEnd, courierId, payTypeId} = values;

                    const filter = {
                        cityId,
                        dateStart: dateStart && dateConvert(dateStart, 'dd-MM-yyyy', 'yyyy-MM-dd'),
                        dateEnd: dateEnd && dateConvert(dateEnd, 'dd-MM-yyyy', 'yyyy-MM-dd'),
                        courierId,
                        payTypeId
                    }

                    const form = _.pickBy(filter, function (value) {
                        return !(value === undefined || value === null || value === '');
                    });

                    setFilterHandler(form)
                }}
            >
                {({setFieldValue, values, handleChange, dirty, handleSubmit, resetForm}) => {

                    const isReset = _.isEmpty(_.pickBy(initialValues, function (value) {
                        return !(value === undefined || value === null || value === '');
                    }));

                    const resetFormHandler = () => {
                        resetForm();
                        setFilterHandler({})
                    };

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Дата</Label>
                                        <Row form>
                                            <Col>
                                                <DateTimePicker
                                                    name={`dateStart`}
                                                    placeholder={'с'}
                                                    value={values.dateStart}
                                                    onChange={(value) => {
                                                        setFieldValue('dateStart', value)
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <DateTimePicker
                                                    name={`dateEnd`}
                                                    placeholder={'по'}
                                                    value={values.dateEnd}
                                                    onChange={(value) => {
                                                        setFieldValue('dateEnd', value)
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Курьер</Label>
                                        <Dropdown
                                            name={'courierId'}
                                            value={values.courierId}
                                            options={dictionaryCouriersList}
                                            onChange={(value) => setFieldValue('courierId', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Тип оплаты</Label>
                                        <Dropdown
                                            name={'payTypeId'}
                                            value={values.payTypeId}
                                            options={[
                                                {
                                                    label: "Наличными",
                                                    value: 1
                                                },
                                                {
                                                    label: "POS терминал",
                                                    value: 2
                                                },
                                                {
                                                    label: "Оплаченный заказ",
                                                    value: 3
                                                }
                                            ]}
                                            onChange={(value) => setFieldValue('payTypeId', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Город</Label>
                                        <Dropdown
                                            name={'cityId'}
                                            value={values.cityId}
                                            options={cityOptions}
                                            onChange={(value) => {
                                                setFieldValue('pointId', null);
                                                setFieldValue('companyId', null);
                                                setFieldValue('cityId', value);
                                            }}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form className="justify-content-center">
                                <Col md={'auto'}>
                                    <Button
                                        type={'submit'}
                                        color={'primary'}
                                        disabled={!dirty}
                                    >
                                        Применить
                                    </Button>
                                </Col>
                                <Col md={'auto'}>
                                    <Button
                                        onClick={resetFormHandler}
                                        disabled={isReset}
                                        color={'light'}
                                    >
                                        Сбросить
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default CouriersShiftsFilter;