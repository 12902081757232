import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import Pagination from "../components/UI/Pagination";
import queryString from "query-string";
import {getReportCompanies} from "../store/actions/reportCompanies";
import {IReportCompany, IReportCompanyFilterRequest, IReportCompanySortRequest} from "../models/Report";
import _ from "lodash";
import TableGrid from "../components/UI/TableGrid";
import Empty from "../components/UI/Empty";
import {
    reportCompaniesFilterReSelector,
    reportCompaniesSortReSelector,
    reportCompaniesPageReSelector
} from "../store/reselectors/reportCompanies";
import {getDictionaryReportCompanyPeriod} from "../store/actions/dictionaryReportCompanyPeriod";
import {IDictionary} from "../models/Dictionary";
import {getDictionaryOptions, getDictionary, sortDictionaryByPeriod} from "../utils/dictionaries";
import {getDictionaryCompanies} from "../store/actions/dictionaryCompanies";
import {IPayment} from "../models/Payment";
import {Button} from "reactstrap";
import Icon from "../components/UI/Icon";
import {Link} from "react-router-dom";


type ReportCompaniesType = {
    getReportCompanies: (page?: number, form?: IReportCompanyFilterRequest & IReportCompanySortRequest) => void
    getDictionaryReportCompanyPeriod: () => void
    getDictionaryCompanies: () => void
    page: number
    data: IReportCompany[]
    total: number
    by: number
    loading: boolean
    error: string
    filter?: IReportCompanyFilterRequest,
    sort?: IReportCompanySortRequest
    dictionaryPeriod: IDictionary[]
    dictionaryPeriodLoading: boolean
    dictionaryPeriodError: string
    dictionaryCompanies: IDictionary[]
    dictionaryCompaniesLoading: boolean
    dictionaryCompaniesError: string
}


class ReportCompaniesContainer extends Component<ReportCompaniesType & RouteComponentProps> {

    componentDidMount() {
        const {getReportCompanies, page, filter, sort, getDictionaryReportCompanyPeriod, getDictionaryCompanies} = this.props;
        getDictionaryReportCompanyPeriod();
        getDictionaryCompanies();
        getReportCompanies(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
    }

    componentDidUpdate(prevProps: Readonly<ReportCompaniesType & RouteComponentProps>) {
        const {page, filter, sort, getReportCompanies} = this.props;
        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter) || !_.isEqual(prevProps.sort, sort)) {
            getReportCompanies(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
        }
    }

    changeHandler = <T extends any>(form: T) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const params = _.pick(queryParams, ['periodId', 'companyId', 'calcTypeId', 'orderBy']);

        const filter = _.pickBy({...params, ...form}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        const nextQueryParams = queryString.stringify(filter);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    filterHandler = (form: IReportCompanyFilterRequest) => {
        this.changeHandler<IReportCompanyFilterRequest>(form)
    };

    sortHandler = (form: IReportCompanySortRequest) => {
        const orderBy = form ? Object.keys(form)[0] + '_' + Object.values(form)[0] : undefined;
        this.changeHandler<{ [key: string]: string | undefined }>({orderBy})
    };

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    render() {
        const {
            data, loading, error, page, total, by, getReportCompanies, filter, sort,
            dictionaryPeriod, dictionaryPeriodLoading, dictionaryPeriodError, getDictionaryReportCompanyPeriod,
            dictionaryCompanies, dictionaryCompaniesLoading, dictionaryCompaniesError, getDictionaryCompanies
        } = this.props;

        const filterData = filter;
        const sortData = sort && sort.orderBy;

        if (dictionaryPeriodLoading || dictionaryCompaniesLoading) {
            return <Loader/>
        }
        if (dictionaryPeriodError) {
            return <Error
                error={dictionaryPeriodError}
                refresh={getDictionaryReportCompanyPeriod}
            />
        }
        if (dictionaryCompaniesError) {
            return <Error
                error={dictionaryCompaniesError}
                refresh={getDictionaryCompanies}
            />
        }
        if (!!dictionaryPeriod.length && !!dictionaryCompanies.length) {
            const sortedDictionaryPeriod = sortDictionaryByPeriod(dictionaryPeriod);
            const periodOptions = getDictionaryOptions(sortedDictionaryPeriod);
            const companyOptions = getDictionaryOptions(dictionaryCompanies);
            return (
                <>
                    <div className={'mb-3'}>
                        <TableGrid
                            sourceData={data}
                            filterData={filterData}
                            sortData={sortData}
                            columns={[
                                {
                                    label: 'Период',
                                    key: 'periodId',
                                    filter: {
                                        type: 'select',
                                        options: periodOptions,
                                        handler: this.filterHandler
                                    },
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                    render: ({periodId}: IReportCompany) => {
                                        const period = getDictionary(dictionaryPeriod, periodId);
                                        return _.get(period, 'value');
                                    },
                                    width:120
                                },
                                {
                                    label: 'Название',
                                    key: 'companyId',
                                    filter: {
                                        type: 'select',
                                        options: companyOptions,
                                        handler: this.filterHandler
                                    },
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                    render: ({companyName}: IReportCompany) => {
                                        return companyName
                                    },
                                    width:180
                                },
                                {
                                    label: 'Сумма заказов',
                                    key: 'totalSum',
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                },
                                {
                                    label: 'Сумма доставки',
                                    key: 'deliverySum',
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                },
                                {
                                    label: 'Кол-во заказов',
                                    key: 'countOrders',
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                },
                                {
                                    label: 'Метод расчета',
                                    key: 'calcTypeId',
                                    filter: {
                                        type: 'select',
                                        options: [
                                            {label: 'Процент от суммы заказов', value: 1},
                                            {label: 'По зонам доставки', value: 2}
                                        ],
                                        handler: this.filterHandler
                                    },
                                    sort: {
                                        handler: this.sortHandler
                                    },
                                    render: ({calcTypeId}: IReportCompany) => {
                                        return calcTypeId === 1 ? 'Процент от суммы заказов' : 'По зонам доставки'
                                    },
                                    width:240
                                },
                                {
                                    label: 'Сумма к оплате',
                                    key: 'income',
                                    sort: {
                                        handler: this.sortHandler
                                    }
                                },
                                {
                                    label: 'Задолженность',
                                    key: 'balance'
                                },
                                {
                                    label: '',
                                    render: ({companyId}: IPayment) => {
                                        return <Link to={`/payments?companyId=${companyId}`}>
                                            <Button size={'sm'} color={'outline-light'}>
                                                <Icon name={'chevron_right'} color={'gray-500'}/>
                                            </Button>
                                        </Link>
                                    },
                                    width: 58
                                }
                            ]}
                        />
                        {loading && <Loader/>}
                        {error && <Error
                            error={error}
                            refresh={() => getReportCompanies(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)}
                        />}
                        {!data.length && !loading && !error &&
                        <Empty>
                            <h5 className={'mb-0'}>Список пуст</h5>
                        </Empty>}
                    </div>
                    <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>
                </>
            )
        }
        return (
            <Empty>
                <h5 className={'mb-0'}>Список пуст</h5>
            </Empty>
        )
    }
}


const mapStateToProps = ({reportCompanies, dictionaryReportCompanyPeriod, dictionaryCompanies}: AppState, props: RouteComponentProps) => {
    const {data, total, by, loading, error} = reportCompanies;

    return {
        data,
        filter: reportCompaniesFilterReSelector(props),
        sort: reportCompaniesSortReSelector(props),
        page: reportCompaniesPageReSelector(props),
        loading,
        error,
        total,
        by,
        dictionaryPeriod: dictionaryReportCompanyPeriod.data,
        dictionaryPeriodLoading: dictionaryReportCompanyPeriod.loading,
        dictionaryPeriodError: dictionaryReportCompanyPeriod.error,
        dictionaryCompanies: dictionaryCompanies.data,
        dictionaryCompaniesLoading: dictionaryCompanies.loading,
        dictionaryCompaniesError: dictionaryCompanies.error
    }
};

const mapDispatchToProps = {
    getReportCompanies,
    getDictionaryReportCompanyPeriod,
    getDictionaryCompanies
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(ReportCompaniesContainer);
