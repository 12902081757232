import {
    GET_SCHEDULE_DATE,
    GET_SCHEDULE_DATE_REQUEST,
    GET_SCHEDULE_DATE_SUCCESS,
    GET_SCHEDULE_DATE_ERROR
} from '../constants/scheduleDate'
import {IAction} from "./index";
import {IScheduleDate} from "../../models/Schedule";


export interface IActionScheduleDate extends IAction<symbol, any> {

}

export const getScheduleDateRequest = () => {
    return {
        type: GET_SCHEDULE_DATE_REQUEST
    }
};

export const getScheduleDateSuccess = (payload: IScheduleDate[]) => {
    return {
        type: GET_SCHEDULE_DATE_SUCCESS,
        payload
    }
};

export const getScheduleDateError = (payload: string) => {
    return {
        type: GET_SCHEDULE_DATE_ERROR,
        payload
    }
};

export const getScheduleDate = (id:number, date:string, isActive:boolean) => {
    return {
        type: GET_SCHEDULE_DATE,
        payload:{
            id,
            date,
            isActive
        }
    };
};
