import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getOrderError,
    getOrderRequest,
    getOrderSuccess
} from "../actions/order";
import {IOrder} from "../../models/Order";
import {OrderService} from "../../services/OrderService";
import {GET_ORDER} from "../constants/order";


type getOrderType = {
    type: typeof GET_ORDER,
    payload: string
};

function* getOrderSaga({payload}: getOrderType) {
    try {
        yield put(getOrderRequest());

        const service = new OrderService();

        const promise = yield call(service.getOrder, payload);
        const response: IOrder = promise.data;
    
        /**
         * Не знаю, зачем здесь приведение к числам, но оно тут уже было
         */
        const addressTo = response.addressTo.lat && response.addressTo.long
            ? {...response.addressTo, lat:+response.addressTo.lat, long:+response.addressTo.long}
            : response.addressTo;

        const addressFrom = response.addressFrom.lat && response.addressFrom.long
            ? {...response.addressFrom, lat:+response.addressFrom.lat, long:+response.addressFrom.long}
            : response.addressFrom;

        const transform = {...response, addressTo, addressFrom};

        yield put(getOrderSuccess(transform as IOrder));

    } catch (error) {
        yield put(getOrderError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetOrder() {
    yield takeLatest(GET_ORDER, getOrderSaga);
}

