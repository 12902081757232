import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Modal, ModalHeader, ModalBody} from "reactstrap";
import PointAdd from "./PointAdd";
import {pointsCityIdReSelector} from "../store/reselectors/points";
import {closePointForm, openPointForm} from "../store/actions/pointForm";
import {compose} from "redux";


type PointNavbarType = {
    closePointForm: () => void
    openPointForm: (mode: 'add') => void
    mode: 'add' | 'edit' | 'delete' | null
    cityId?: number
}


class PointNavbarContainer extends Component<PointNavbarType & RouteComponentProps> {

    render() {
        const {
            mode,
            closePointForm,
            openPointForm,
            cityId
        } = this.props;

        return (
            <div className={'mb-2'}>
                <Button
                    color={'primary'}
                    onClick={() => openPointForm('add')}>
                    Добавить отправную точку
                </Button>

                <Modal isOpen={mode === 'add'} size={'lg'} toggle={closePointForm}>
                    <ModalHeader toggle={closePointForm}>
                        Создание отправной точки
                    </ModalHeader>
                    <ModalBody>
                        <PointAdd cityId={cityId} cancelHandler={closePointForm}/>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = ({points, pointForm, auth}: AppState, props: RouteComponentProps) => {
    const {mode} = pointForm;

    return {
        cityId: pointsCityIdReSelector(props),
        mode
    }
};

const mapDispatchToProps = {
    closePointForm,
    openPointForm
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(PointNavbarContainer);
