import {GET_PAYMENTS_ERROR, GET_PAYMENTS_REQUEST, GET_PAYMENTS_SUCCESS} from "../constants/payments";
import {IPayment} from "../../models/Payment";
import {IActionPayments} from "../actions/payments";


export interface IPaymentsState {
    loading: boolean
    error: string
    data: IPayment[]
}

const initialState: IPaymentsState = {
    data: [],
    loading: false,
    error: ''
};

export default function payments(state = initialState, action: IActionPayments): IPaymentsState {
    switch (action.type) {
        case GET_PAYMENTS_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_PAYMENTS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_PAYMENTS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
