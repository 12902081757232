import React, {PureComponent} from 'react';
import styles from './Sidebar.module.scss';
import Navigation from "../../containers/Navigation";
import Profile from "../../containers/Profile";
import Icon from "../UI/Icon";
import classNames from 'classnames';


type SidebarType = {
    isSidebar: boolean
    toggleSidebar: () => void
}

class Sidebar extends PureComponent<SidebarType> {
    render() {
        const {isSidebar, toggleSidebar} = this.props;
        return (
            <nav className={classNames(styles.sidebar, !isSidebar && styles['sidebar--short'])}>
                <div className={classNames(styles['sidebar-toggle'], !isSidebar && styles['sidebar-toggle--reverse'])}
                     id={'sidebar-toggle'}
                     onClick={toggleSidebar}>
                    <Icon size={'sm'} name={'arrow_back_ios'}/>
                </div>
                <div className={'pl-4 pr-4 mb-3'}>
                    <div className={!isSidebar ? 'invisible' : 'visible'}>
                        <Profile/>
                    </div>
                </div>
                <Navigation isSidebar={isSidebar}/>
            </nav>
        )
    }
}


export default Sidebar;
