import React, {Component} from "react";
import { Redirect } from "react-router-dom";


class DirectoriesPage extends Component {
    render() {
        return (
            <Redirect to={'/directories/cities'}/>
        );
    }
}

export default DirectoriesPage;
