import React, {PureComponent} from 'react';
import {IAuth} from "../../models/Auth";
import {Button, Card, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import _ from 'lodash';
import {format, parseISO} from "date-fns";
import {Link} from "react-router-dom";

type ProfileFullType = {
    user: IAuth
    logout:()=>void
}

class ProfileFull extends PureComponent<ProfileFullType> {
    render() {
        const {user, logout} = this.props;
        const {fio, allowedCities, role, lastLogin, email} = user;
        return (
            <>
                <div className={'mb-2'}>
                    <Row form>
                        <Col md={'auto'}>
                            <Link to={'/profile/change-password'}><Button color={'light'}>Сменить пароль</Button></Link>
                        </Col>
                        <Col md={'auto'}>
                            <Button color={'light'} onClick={logout}>Выйти из аккаунта</Button>
                        </Col>
                    </Row>
                </div>
                <Card body>
                    <div className={'mb-3'}>
                        <Row>
                            <Col md={'3'}>
                                <div className={'gray-500'}>
                                    ФИО
                                </div>
                                <b>{fio}</b>
                            </Col>
                            <Col md={'3'}>
                                <div className={'gray-500'}>
                                    Роль
                                </div>
                                <b>{_.get(role, 'description')}</b>
                            </Col>
                            {email && <Col md={'3'}>
                                <div className={'gray-500'}>
                                    Email
                                </div>
                                <b>{email}</b>
                            </Col>}
                            {lastLogin && <Col md={'3'}>
                                <div className={'gray-500'}>
                                    Дата последнего входа
                                </div>
                                <b>{format(parseISO(lastLogin), 'yyyy-MM-dd HH:mm')}</b>
                            </Col>}
                        </Row>
                    </div>
                    <p className={'mb-1'}>Доступные города</p>
                    <Row>
                        <Col md={'6'}>
                            <ListGroup>
                                {allowedCities.map(({id, name}) => (
                                    <ListGroupItem key={id}>{name}</ListGroupItem>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                </Card>
            </>
        );
    }
}

export default ProfileFull;
