import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import {RouteComponentProps} from "react-router";
import Courier from "../../containers/Courier";

type RouteParamsType = {id:string }

class CourierPage extends Component<RouteComponentProps<RouteParamsType>> {
    render() {
        const {match} = this.props;
        const id = +match.params.id;

        return (
            <Layout>
                <Title>Курьер</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Курьеры', url: `/couriers`},
                    {name: 'Курьер', url: `/courier/${id}`}
                ]}/>
                <Courier id={id}/>
            </Layout>
        );
    }
}

export default CourierPage;
