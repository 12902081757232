import {
    ADD_PAYMENT_FORM_REQUEST,
    ADD_PAYMENT_FORM_SUCCESS,
    ADD_PAYMENT_FORM_ERROR,
    EDIT_PAYMENT_FORM_REQUEST,
    EDIT_PAYMENT_FORM_SUCCESS,
    EDIT_PAYMENT_FORM_ERROR,
    GET_PAYMENT_FORM_REQUEST,
    GET_PAYMENT_FORM_SUCCESS,
    GET_PAYMENT_FORM_ERROR,
    DELETE_PAYMENT_FORM_ERROR,
    DELETE_PAYMENT_FORM_SUCCESS,
    DELETE_PAYMENT_FORM_REQUEST,
    OPEN_PAYMENT_FORM,
    CLOSE_PAYMENT_FORM
} from '../constants/paymentForm'
import {IPayment} from '../../models/Payment';
import {IActionPaymentForm} from '../actions/paymentForm';


export interface IPaymentFormState {
    getLoading: boolean
    getError: string
    data: IPayment | null
    changeLoading: boolean
    changeError: string
    mode: 'add' | 'edit' | 'delete' | null
    modeId: number | null
}

const initialState: IPaymentFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null,
    modeId: null
};

export default function paymentForm(state = initialState, action: IActionPaymentForm): IPaymentFormState {
    switch (action.type) {
        case GET_PAYMENT_FORM_REQUEST:
            return {
                ...state,
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_PAYMENT_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_PAYMENT_FORM_ERROR:
            return {
                ...state,
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case ADD_PAYMENT_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_PAYMENT_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_PAYMENT_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case EDIT_PAYMENT_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case EDIT_PAYMENT_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case EDIT_PAYMENT_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case DELETE_PAYMENT_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case DELETE_PAYMENT_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case DELETE_PAYMENT_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_PAYMENT_FORM:
            const {mode, modeId = null} = action.payload;
            return {
                ...initialState,
                mode,
                modeId
            };
        case CLOSE_PAYMENT_FORM:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
