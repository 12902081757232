import {
    SSE_NOTIFICATIONS_COUNT, GET_NOTIFICATIONS_ERROR,
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS, DELETE_NOTIFICATION_SUCCESS
} from '../constants/notifications';
import {IActionNotifications} from "../actions/notifications";
import {INotification} from "../../models/Notifications";


export interface INotificationsState {
    count: number
    data: INotification[]
    loading: boolean
    error: string
    total: number
    by: number
}

const initialState: INotificationsState = {
    count: 0,
    data: [],
    loading: false,
    error:'',
    total: 0,
    by: 0
};

export default function notifications(state = initialState, action: IActionNotifications): INotificationsState {
    switch (action.type) {
        case GET_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                data: [],
                loading: true,
                error:'',
                total: 0,
                by: 0
            };
        case GET_NOTIFICATIONS_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                ...state,
                data,
                loading: false,
                error:'',
                total,
                by
            };
        case GET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            };
        case SSE_NOTIFICATIONS_COUNT:
            return {
                ...state,
                count: action.payload
            };
        case DELETE_NOTIFICATION_SUCCESS:
            const prevData = state.data;
            const index = prevData.findIndex(({id}) => id === action.payload);
            const item = prevData.find(({id}) => id === action.payload) as INotification;
            return {
                ...state,
                data: [
                    ...prevData.slice(0, index),
                    {...item, status: false},
                    ...prevData.slice(index + 1)
                ],
                count: state.count - 1
            };
        default:
            return state;
    }
}
