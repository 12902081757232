import {
    GET_DICTIONARY_COURIERS,
    GET_DICTIONARY_COURIERS_REQUEST,
    GET_DICTIONARY_COURIERS_SUCCESS,
    GET_DICTIONARY_COURIERS_ERROR
} from '../constants/dictionaryCouriers'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryCouriers extends IAction<symbol, any> {

}

export const getDictionaryCouriersRequest = () => {
    return {
        type: GET_DICTIONARY_COURIERS_REQUEST
    }
};

export const getDictionaryCouriersSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_COURIERS_SUCCESS,
        payload
    }
};

export const getDictionaryCouriersError = (payload: string) => {
    return {
        type: GET_DICTIONARY_COURIERS_ERROR,
        payload
    }
};

export const getDictionaryCouriers = (payload?:number) => {
    return {
        type: GET_DICTIONARY_COURIERS,
        payload
    };
};

