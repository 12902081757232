import {
    CHANGE_COURIER_ERROR,
    CHANGE_COURIER_REQUEST,
    CHANGE_COURIER_SUCCESS,
    CHANGE_COURIER
} from "../constants/courierChange";
import {IAction} from "./index";

export interface IActionCourierChange extends IAction<symbol, any>{

}

export const changeCourierRequest = () => {
    return {
        type: CHANGE_COURIER_REQUEST
    }
}

export const changeCourierSuccess = () => {
    return {
        type: CHANGE_COURIER_SUCCESS
    }
}

export const changeCourierError = (payload: string) => {
    return {
        type: CHANGE_COURIER_ERROR,
        payload
    }
}

export const changeCourier = (payload: {courierId: string, id: string, reason: string}) => {
    return {
        type: CHANGE_COURIER,
        payload
    }
}