import React, {ChangeEvent, PureComponent} from "react";
import {getFilteredCouriersList} from "../../store/actions/couriersFilteredList";
import {connect} from "react-redux";
import {MapState} from "react-yandex-maps";
import Loader from "../UI/Loader";
import Error from "../UI/Error";
import {Col, Input, Row} from "reactstrap";
import styles from "./CourierChange.module.scss";
import {ICourier, ICourierFilter} from "../../models/Courier";

type ChangeCourierType = {
    getFilteredCouriersList: (props?: { expand?: string, body?: ICourierFilter }) => void;
    data: ICourier[];
    loading: boolean;
    error: string;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    hasErrorMessage?: boolean;
    cityId: number;
}

class CourierChange extends PureComponent<ChangeCourierType> {
    componentDidMount() {
        const {getFilteredCouriersList, cityId} = this.props

        getFilteredCouriersList({
            expand: 'slot,lastActiveOrder',
            body: {
                activeSlot: true,
                cityId,
            }
        })
    }

    render() {
        const {data, loading, error, hasErrorMessage, getFilteredCouriersList, handleChange} = this.props

        if (loading)
            return <Loader/>

        if (error)
            return <Error error={error} refresh={() => getFilteredCouriersList({
                expand: 'slot,lastActiveOrder',
                body: {
                    activeSlot: true
                }
            })}/>

        return (
            <div>
                {data.length > 0 ?
                    <>
                        {data.map((courier: ICourier, index: number) => {
                            return (
                                <Row key={index} className={styles.row}>
                                    <Col md={6}>
                                        <label>
                                            <Input
                                                type="radio"
                                                name="courierId"
                                                value={courier.id}
                                                onChange={handleChange}
                                            />
                                            <div style={{position: "relative", top: 2}}>{courier.fio}</div>
                                        </label>
                                    </Col>
                                    <Col md={6}>
                                        {courier.orderStatusName}
                                    </Col>
                                </Row>
                            )
                        })}
                        <Input
                            type={'textarea'}
                            name="reason"
                            placeholder={'Причина смены курьера'}
                            onChange={handleChange}
                            style={{height: 120, resize: "none"}}
                            invalid={hasErrorMessage}
                        />
                    </> :
                    <div>
                        Курьеры отсутствуют
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({couriersFilteredList}: MapState) => {
    return {
        data: couriersFilteredList.data,
        loading: couriersFilteredList.loading,
        error: couriersFilteredList.error
    }
}

const mapDispatchToProps = {
    getFilteredCouriersList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CourierChange);
