import React, {PureComponent} from 'react';
import {Alert, Button, Form, Spinner, FormFeedback, FormGroup, Label, Input, CustomInput, Row, Col} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import {IPointEditFormRequest, IPoint} from '../../models/Point';
import Dropdown from "../UI/Dropdown";
import {IAuth} from "../../models/Auth";
import {sanitizeForm} from "../../utils/sanitize-form";
import {getCityOptions} from "../../utils/cities";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import NumberFormat from "react-number-format";
import {MAP_LAT_DEFAULT, MAP_LONG_DEFAULT} from "../../utils/constants";


const schema = yup.object({
    name: yup.string().required(requiredMessage()),
    description: yup.string(),
    cityId: yup.number().nullable().required(requiredMessage()),
    status: yup.boolean().required(requiredMessage()),
    lat: yup.number().required(requiredMessage()),
    long: yup.number().required(requiredMessage())
});

type PointEditType = {
    editPointForm: (id: number, form: IPointEditFormRequest) => void
    changeLoading: boolean
    changeError: string
    cancelHandler?: () => void
    data: IPoint
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class PointEdit extends PureComponent<PointEditType & CitiesType> {
    render() {
        const {editPointForm, data, changeLoading, changeError, allowedCities, cancelHandler} = this.props;

        const cityOptions = getCityOptions(allowedCities);

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const name = sanitizeForm(values.name);
                    const description = sanitizeForm(values.description);
                    const cityId = sanitizeForm(values.cityId);
                    const status = sanitizeForm(values.status);
                    const lat = sanitizeForm(values.lat);
                    const long = sanitizeForm(values.long);

                    const form = {
                        name,
                        description,
                        cityId,
                        status,
                        lat,
                        long
                    };

                    editPointForm(data.id, form)
                }}
                initialValues={{
                    name: data.name || '',
                    description: data.description || '',
                    status: data.status || false,
                    cityId: data.cityId || null,
                    lat: data.lat || MAP_LAT_DEFAULT,
                    long: data.long || MAP_LONG_DEFAULT
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      dirty,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <CustomInput
                                type="switch"
                                id="status"
                                label={values.status ? "Включен" : "Выключен"}
                                onChange={handleChange}
                                name={'status'}
                                checked={values.status}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Название <span className={'required'}>*</span></Label>
                            <Input
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                invalid={touched.name && !!errors.name}
                            />
                            <FormFeedback>{errors.name}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Город <span className={'required'}>*</span></Label>
                            <Dropdown
                                name={'cityId'}
                                value={values.cityId}
                                options={cityOptions}
                                onChange={(value) => setFieldValue('cityId', value)}
                                invalid={touched.cityId && !!errors.cityId}
                            />
                            <FormFeedback>{errors.cityId}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Описание</Label>
                            <Input
                                type="textarea"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                invalid={touched.description && !!errors.description}
                            />
                            <FormFeedback>{errors.description}</FormFeedback>
                        </FormGroup>
                        <Row>
                            <Col md={'6'}>
                                <FormGroup>
                                    <Label>Широта <span className={'required'}>*</span></Label>
                                    <NumberFormat
                                        name={`lat`}
                                        value={values.lat}
                                        onValueChange={({floatValue}) => setFieldValue('lat', floatValue)}
                                        invalid={touched.lat && !!errors.lat}
                                        customInput={Input}
                                    />
                                    <FormFeedback>{errors.lat}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={'6'}>
                                <FormGroup>
                                    <Label>Долгота <span className={'required'}>*</span></Label>
                                    <NumberFormat
                                        name={`long`}
                                        value={values.long}
                                        onValueChange={({floatValue}) => setFieldValue('long', floatValue)}
                                        invalid={touched.long && !!errors.long}
                                        customInput={Input}
                                    />
                                    <FormFeedback>{errors.long}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <YMaps>
                                <Map
                                    onClick={((e: any) => {
                                        setFieldValue('lat', e.get("coords")[0]);
                                        setFieldValue('long', e.get("coords")[1])
                                    })}
                                    state={{
                                        center: [values.lat, values.long],
                                        zoom: 15,
                                    }}
                                    className={'map'}>
                                    <Placemark geometry={[values.lat, values.long]}/>
                                </Map>
                            </YMaps>
                        </FormGroup>
                        {changeError &&
                        (<Alert className={'mt-3'} color={'danger'}>
                            {changeError}
                        </Alert>)}
                        <div className="text-right">
                            {cancelHandler &&
                            <Button color="light" className={'mr-3'} onClick={cancelHandler}>Отменить</Button>}
                            <Button type="submit" color={'success'} disabled={changeLoading || !dirty}>
                                {changeLoading &&
                                (<Spinner
                                    size="sm"
                                />)}
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default PointEdit;
