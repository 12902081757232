import {Form, Formik} from "formik";
import React, {PureComponent} from "react";
import Dropdown from "../components/UI/Dropdown";
import {monthOptions, yearsOptions} from "../store/constants/reportEmenu"
import {format} from "date-fns";
import {Button, Col, Row} from "reactstrap";
import {ICityShortResponse} from "../models/City";
import {getCityOptions} from "../utils/cities";

export interface ParsedQuery<T = string> {
    [key: string]: T | T[] | null;
}

type ReportEmenuFilterType = {
    handler: (value: { [key: string]: any }) => void;
    params: {
        year?: ParsedQuery;
        month?: ParsedQuery;
        cityId?: ParsedQuery;
    }
    allowedCities: ICityShortResponse[];
}

class ReportEmenuFilter extends PureComponent<ReportEmenuFilterType> {
    render() {
        const {handler, params, allowedCities} = this.props;
    
        const cityOptions = getCityOptions(allowedCities);

        const initialValues = {
            month: Number(params.month || format(new Date(), "MM")) - 1,
            year: Number(params.year || format(new Date(), "yyyy")),
            cityId: params.cityId ? Number(params.cityId) : null,
        }

        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => {
                    handler(values)
                }}
            >
                {({setFieldValue, values, handleSubmit}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row form className={"mb-2"}>
                                <Col md={'auto'} style={{minWidth: "160px", width: "160px"}}>
                                    <Dropdown
                                      name={'cityId'}
                                      value={values.cityId}
                                      options={cityOptions}
                                      isMulti={false}
                                      onChange={(value) => {
                                          setFieldValue('cityId', value);
                                      }}
                                      isClearable
                                      placeholder={"Все города"}
                                    />
                                </Col>
                                <Col md={'auto'} style={{minWidth: "160px", width: "160px"}}>
                                    <Dropdown
                                        name={"month"}
                                        value={values.month}
                                        options={monthOptions}
                                        isMulti={false}
                                        onChange={(value) => {
                                            setFieldValue("month", value);
                                        }}
                                    />
                                </Col>
                                <Col md={'auto'} style={{minWidth: "160px", width: "160px"}}>
                                    <Dropdown
                                        name={"year"}
                                        value={values.year}
                                        options={yearsOptions}
                                        isMulti={false}
                                        onChange={(value) => {
                                            setFieldValue("year", value);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Button type={"submit"} color={'primary'}> Применить фильтр </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default ReportEmenuFilter;
