export const GET_JSON_POINT_ZONE_FORM = Symbol('GET_JSON_POINT_ZONE_FORM');
export const SET_JSON_POINT_ZONE_FORM = Symbol('SET_JSON_POINT_ZONE_FORM');
export const DELETE_JSON_POINT_ZONE_FORM = Symbol('DELETE_JSON_POINT_ZONE_FORM');
export const ADD_POINT_ZONE_FORM = Symbol('ADD_POINT_ZONE_FORM');
export const ADD_POINT_ZONE_FORM_REQUEST = Symbol('ADD_POINT_ZONE_FORM_REQUEST');
export const ADD_POINT_ZONE_FORM_SUCCESS = Symbol('ADD_POINT_ZONE_FORM_SUCCESS');
export const ADD_POINT_ZONE_FORM_ERROR = Symbol('ADD_POINT_ZONE_FORM_ERROR');
export const EDIT_POINT_ZONE_FORM = Symbol('EDIT_POINT_ZONE_FORM');
export const EDIT_POINT_ZONE_FORM_REQUEST = Symbol('EDIT_POINT_ZONE_FORM_REQUEST');
export const EDIT_POINT_ZONE_FORM_SUCCESS = Symbol('EDIT_POINT_ZONE_FORM_SUCCESS');
export const EDIT_POINT_ZONE_FORM_ERROR = Symbol('EDIT_POINT_ZONE_FORM_ERROR');
export const GET_POINT_ZONE_FORM = Symbol('GET_POINT_ZONE_FORM');
export const GET_POINT_ZONE_FORM_REQUEST = Symbol('GET_POINT_ZONE_FORM_REQUEST');
export const GET_POINT_ZONE_FORM_SUCCESS = Symbol('GET_POINT_ZONE_FORM_SUCCESS');
export const GET_POINT_ZONE_FORM_ERROR = Symbol('GET_POINT_ZONE_FORM_ERROR');
export const DELETE_POINT_ZONE_FORM = Symbol('DELETE_POINT_ZONE_FORM');
export const DELETE_POINT_ZONE_FORM_REQUEST = Symbol('DELETE_POINT_ZONE_FORM_REQUEST');
export const DELETE_POINT_ZONE_FORM_SUCCESS = Symbol('DELETE_POINT_ZONE_FORM_SUCCESS');
export const DELETE_POINT_ZONE_FORM_ERROR = Symbol('DELETE_POINT_ZONE_FORM_ERROR');
export const OPEN_POINT_ZONE_FORM = Symbol('OPEN_POINT_ZONE_FORM');
export const CLOSE_POINT_ZONE_FORM = Symbol('CLOSE_POINT_ZONE_FORM');

