import {GET_POINTS, GET_POINTS_REQUEST, GET_POINTS_SUCCESS, GET_POINTS_ERROR} from '../constants/points'
import {IAction} from "./index";
import {IPoint} from "../../models/Point";


export interface IActionPoints extends IAction<symbol, any> {

}

export const getPointsRequest = () => {
    return {
        type: GET_POINTS_REQUEST
    }
};

export const getPointsSuccess = (payload: IPoint[]) => {
    return {
        type: GET_POINTS_SUCCESS,
        payload
    }
};

export const getPointsError = (payload: string) => {
    return {
        type: GET_POINTS_ERROR,
        payload
    }
};

export const getPoints = (payload?: number) => {
    return {
        type: GET_POINTS,
        payload
    };
};

