import {GET_ROLES_ERROR, GET_ROLES_REQUEST, GET_ROLES_SUCCESS} from "../constants/roles";
import {IRole} from "../../models/Role";
import {IActionRoles} from "../actions/roles";



export interface IRolesState {
    loading: boolean
    error: string
    data: IRole[]
}

const initialState: IRolesState = {
    data: [],
    loading: false,
    error: ''
};

export default function roles(state = initialState, action: IActionRoles): IRolesState {
    switch (action.type) {
        case GET_ROLES_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_ROLES_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_ROLES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
