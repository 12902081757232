import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {editOrderForm, getOrderForm} from "../store/actions/orderForm";
import {IOrder, IOrderEditFormRequest} from "../models/Order";
import OrderEdit from "../components/OrderEdit";
import {IAuth} from "../models/Auth";
import _ from "lodash";
import {getDictionaryCompanyBranches} from "../store/actions/dictionaryCompanyBranches";
import {IDictionary} from "../models/Dictionary";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";


type OrderEditType = {
    id:string
    getOrderForm: (id:string) => void
    getDictionaryCompanyBranches: (companyId:number, cityId:number) => void
    editOrderForm: (id:string, form: IOrderEditFormRequest) => void
    changeLoading: boolean
    changeError: string
    dictionaryCompanyBranches:IDictionary[]
    dictionaryCompanyBranchesLoading:boolean
    dictionaryCompanyBranchesError:string
    data:IOrder | null
    getLoading:boolean
    getError:string
}
type CitiesType = Pick<IAuth, 'allowedCities'>;

class OrderEditContainer extends Component<OrderEditType & CitiesType> {

    componentDidMount(): void {
      const {getOrderForm, id} = this.props;
        getOrderForm(id)
    }

    render() {
        const {
            changeLoading, changeError, editOrderForm, allowedCities, id, getOrderForm, data, getLoading, getError,
            getDictionaryCompanyBranches, dictionaryCompanyBranches, dictionaryCompanyBranchesLoading,
            dictionaryCompanyBranchesError
        } = this.props;

        if (getLoading) {
            return <Loader/>
        }

        if (getError) {
            return <Error error={getError} refresh={() => getOrderForm(id)}/>
        }

        if(data !== null) {
            return (
                <OrderEdit
                    data={data}
                    editOrderForm={editOrderForm}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    allowedCities={allowedCities}
                    getDictionaryCompanyBranches={getDictionaryCompanyBranches}
                    dictionaryCompanyBranches={dictionaryCompanyBranches}
                    dictionaryCompanyBranchesLoading={dictionaryCompanyBranchesLoading}
                    dictionaryCompanyBranchesError={dictionaryCompanyBranchesError}
                />
            )
        }
        return null
    }
}


const mapStateToProps = ({orderForm, auth, dictionaryCompanyBranches}: AppState) => {
    const {user} = auth;
    return {
        data: orderForm.data,
        getLoading: orderForm.getLoading,
        getError: orderForm.getError,
        dictionaryCompanyBranches: dictionaryCompanyBranches.data,
        dictionaryCompanyBranchesLoading: dictionaryCompanyBranches.loading,
        dictionaryCompanyBranchesError: dictionaryCompanyBranches.error,
        changeLoading: orderForm.changeLoading,
        changeError: orderForm.changeError,
        allowedCities: _.get(user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    getOrderForm,
    editOrderForm,
    getDictionaryCompanyBranches
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderEditContainer);
