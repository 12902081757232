import React, {PureComponent} from 'react';
import {
    Alert,
    Button,
    Form,
    Spinner,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Row,
    Col,
    Card,
    CardBody, CustomInput, ButtonGroup
} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import Dropdown from "../UI/Dropdown";
import {ICompanyBranch, ICompanyBranchEditFormRequest} from "../../models/Company";
import {Link} from "react-router-dom";
import Table from '../UI/Table';
import NumberFormat from "react-number-format";
import {IAuth} from "../../models/Auth";
import _ from "lodash";
import {statusOptions} from "../../utils/statuses";
import {YMaps, Map, Placemark} from "react-yandex-maps";
import {sanitizeForm} from "../../utils/sanitize-form";
import {IPoint} from "../../models/Point";
import {getCityOptions} from "../../utils/cities";
import {MAP_LAT_DEFAULT, MAP_LONG_DEFAULT} from "../../utils/constants";
import Icon from "../UI/Icon";



const schema = yup.object({
    cityId: yup.number().nullable().required(requiredMessage()),
    pointId: yup.number().nullable().required(requiredMessage()),
    address: yup.string().required(requiredMessage()),
    lat: yup.number().required(requiredMessage()),
    long: yup.number().required(requiredMessage()),
    phone: yup.string().required(requiredMessage()),
    status: yup.boolean().nullable().required(requiredMessage()),
    deliveryZones: yup.array().of(
        yup.object().shape({
            deliveryZoneId: yup.number().nullable().required(requiredMessage()),
            price: yup.number(),
            status: yup.boolean().nullable().required(requiredMessage()),
        })
    ),
});



type CompanyBranchEditType = {
    editCompanyBranchForm: (id: number, form: ICompanyBranchEditFormRequest) => void
    data: ICompanyBranch
    changeLoading: boolean
    changeError: string
    points: IPoint[]
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

type DeliveryZoneType = { deliveryZoneId: number | null, price?: number, status: boolean}

class CompanyBranchEdit extends PureComponent<CompanyBranchEditType & CitiesType> {

    render() {
        const {data, editCompanyBranchForm, changeLoading, changeError, allowedCities, points} = this.props;

        const cityOptions = getCityOptions(allowedCities);

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    const cityId = sanitizeForm(values.cityId);
                    const pointId = sanitizeForm(values.pointId);
                    const address = sanitizeForm(values.address);
                    const lat = sanitizeForm(values.lat);
                    const long = sanitizeForm(values.long);
                    const phone = sanitizeForm(values.phone);
                    const status = sanitizeForm(values.status);
                    const deliveryZones = sanitizeForm(values.deliveryZones.map((o: DeliveryZoneType) => {
                        const deliveryZoneId = sanitizeForm(o.deliveryZoneId);
                        const price = sanitizeForm(o.price);
                        const status = sanitizeForm(o.status);
                        return {deliveryZoneId, price, status}
                    }));

                    const form = {
                        cityId,
                        pointId,
                        address,
                        lat,
                        long,
                        phone,
                        status,
                        deliveryZones
                    };

                    editCompanyBranchForm(data.id, form)
                }}
                initialValues={{
                    cityId: data.cityId || null,
                    pointId: data.pointId || null,
                    address: data.address || '',
                    lat: data.lat || MAP_LAT_DEFAULT,
                    long: data.long || MAP_LONG_DEFAULT,
                    phone: data.phone || '',
                    status: data.status || false,
                    deliveryZones: data.deliveryZones || []
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      dirty
                  }) => {

                    const pointOptions = Array.isArray(points) && values.cityId !== null ? points
                        .filter(({cityId})=> values.cityId === cityId)
                        .map(({id, name}) => ({
                            label: name,
                            value: id
                        })) : [];


                    const addDeliveryZones = () => {
                        setFieldValue('deliveryZones', [...values.deliveryZones, {
                            deliveryZoneId: null,
                            status: true
                        }])
                    };
                    const changeDeliveryZones = (index: number, setting: DeliveryZoneType) => {
                        setFieldValue('deliveryZones', [
                            ...values.deliveryZones.slice(0, index),
                            setting,
                            ...values.deliveryZones.slice(index + 1)
                        ])
                    };
                    const removeDeliveryZones = (index: number) => {
                        setFieldValue('deliveryZones', [
                            ...values.deliveryZones.slice(0, index),
                            ...values.deliveryZones.slice(index + 1)
                        ]);
                    };

                    const getPointZoneOptions = () => {
                        const point = Array.isArray(points) ? points.find(({id}) => id === values.pointId) : undefined;

                        const pointZones = point && point.deliveryZones ? point.deliveryZones : [];

                        return pointZones.map(({id, name}) => ({
                            label: name,
                            value: id
                        }));

                    };


                    return (
                        <Form onSubmit={handleSubmit}>
                            <ButtonGroup className={'mb-2'}>
                                <Link to={`/companies`}><Button color={'light'}>Отменить</Button></Link>
                                <Button
                                    type="submit"
                                    color={'success'}
                                    disabled={changeLoading || !dirty}>
                                    {changeLoading &&
                                    (<Spinner
                                        size="sm"
                                    />)}
                                    Сохранить
                                </Button>
                            </ButtonGroup>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={'4'}>
                                            <FormGroup>
                                                <CustomInput
                                                    type="switch"
                                                    id="status"
                                                    label={values.status ? "Включен" : "Выключен"}
                                                    onChange={handleChange}
                                                    name={'status'}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={'4'}>
                                            <FormGroup>
                                                <Label>Город <span className={'required'}>*</span></Label>
                                                <Dropdown
                                                    name={'cityId'}
                                                    value={values.cityId}
                                                    options={cityOptions}
                                                    onChange={(value) => {
                                                        setFieldValue('cityId', value);
                                                        setFieldValue('pointId', null);
                                                        setFieldValue('deliveryZones', [{deliveryZoneId:null, status:false}]);
                                                    }}
                                                    invalid={touched.cityId && !!errors.cityId}
                                                />
                                                <FormFeedback>{errors.cityId}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={'4'}>
                                            <FormGroup>
                                                <Label>Отправная точка <span className={'required'}>*</span></Label>
                                                        <Dropdown
                                                            name={'pointId'}
                                                            value={values.pointId}
                                                            isDisabled={!pointOptions.length}
                                                            options={pointOptions}
                                                            onChange={(value) => setFieldValue('pointId', value)}
                                                            invalid={touched.pointId && !!errors.pointId}
                                                        />
                                                <FormFeedback>{errors.pointId}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={'4'}>
                                            <FormGroup>
                                                <Label>Адрес</Label>
                                                <Input
                                                    type="text"
                                                    name="address"
                                                    value={values.address}
                                                    onChange={handleChange}
                                                    invalid={touched.address && !!errors.address}
                                                />
                                                <FormFeedback>{errors.address}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={'4'}>
                                            <FormGroup>
                                                <Label>Телефон для связи по заказам <span className={'required'}>*</span></Label>
                                                <NumberFormat
                                                    name={`phone`}
                                                    format={'+7 (###) ###-##-##'}
                                                    mask={'_'}
                                                    allowNegative={false}
                                                    decimalSeparator={false}
                                                    value={values.phone.substr(1)}
                                                    onValueChange={({value}) => setFieldValue('phone', '7' + value)}
                                                    invalid={touched.phone && !!errors.phone}
                                                    customInput={Input}
                                                />
                                                <FormFeedback>{errors.phone}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                            <Table className={'mb-2'}>
                                                <thead>
                                                <tr>
                                                    <th>
                                                        Зона доставки <span className={'required'}>*</span>
                                                    </th>
                                                    <th>
                                                        Стоимость доставки
                                                    </th>
                                                    <th>
                                                        Статус доставки <span className={'required'}>*</span>
                                                    </th>
                                                    <th>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {values.deliveryZones.map((item: DeliveryZoneType, index:number) => {
                                                    const pointZones = getPointZoneOptions();
                                                    const currentPointZones = values.deliveryZones.map(({deliveryZoneId}: DeliveryZoneType) => deliveryZoneId);
                                                    const restPointZones = pointZones.filter(({value}) => !currentPointZones.includes(value) || item.deliveryZoneId === value);

                                                    return (
                                                        <tr key={index}>
                                                            <td style={{width: '33%'}}>
                                                                <Dropdown
                                                                    name={`deliveryZones[${index}].deliveryZoneId`}
                                                                    value={_.get(values, `deliveryZones[${index}].deliveryZoneId`)}
                                                                    options={restPointZones}
                                                                    onChange={(deliveryZoneId) => changeDeliveryZones(index, {...item, deliveryZoneId})}
                                                                    invalid={_.has(touched, `deliveryZones[${index}].deliveryZoneId`) && _.has(errors, `deliveryZones[${index}].deliveryZoneId`)}
                                                                />
                                                            </td>
                                                            <td style={{width: '33%'}}>
                                                                <NumberFormat
                                                                    name={`deliveryZones[${index}].price`}
                                                                    allowNegative={false}
                                                                    decimalSeparator={false}
                                                                    value={_.get(values, `deliveryZones[${index}].price`)}
                                                                    onValueChange={({floatValue}) => changeDeliveryZones(index, {...item, price:floatValue})}
                                                                    invalid={_.has(touched, `deliveryZones[${index}].price`) && _.has(errors, `deliveryZones[${index}].price`)}
                                                                    customInput={Input}
                                                                />
                                                            </td>
                                                            <td style={{width: '33%'}}>
                                                                <Dropdown
                                                                    name={`deliveryZones[${index}].status`}
                                                                    value={_.get(values, `deliveryZones[${index}].status`)}
                                                                    options={statusOptions}
                                                                    onChange={(status) => changeDeliveryZones(index, {...item, status})}
                                                                    invalid={_.has(touched, `deliveryZones[${index}].status`) && _.has(errors, `deliveryZones[${index}].status`)}
                                                                />
                                                            </td>
                                                            <td style={{width: 58}}>
                                                                    <Button
                                                                        color={'outline-light'}
                                                                        size={'sm'}
                                                                        onClick={() => removeDeliveryZones(index)}>
                                                                        <Icon name={'delete'} color={'gray-500'}/>
                                                                    </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </Table>
                                        <Button
                                            color={'light'}
                                            disabled={!values.pointId}
                                            onClick={addDeliveryZones}>
                                            Добавить зону доставки
                                        </Button>
                                    </FormGroup>
                                    <Row>
                                        <Col md={'4'}>
                                            <FormGroup>
                                                <Label>Широта <span className={'required'}>*</span></Label>
                                                <NumberFormat
                                                    name={`lat`}
                                                    value={values.lat}
                                                    onValueChange={({floatValue}) => setFieldValue('lat', floatValue)}
                                                    invalid={touched.lat && !!errors.lat}
                                                    customInput={Input}
                                                />
                                                <FormFeedback>{errors.lat}</FormFeedback>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Долгота <span className={'required'}>*</span></Label>
                                                <NumberFormat
                                                    name={`long`}
                                                    value={values.long}
                                                    onValueChange={({floatValue}) => setFieldValue('long', floatValue)}
                                                    invalid={touched.long && !!errors.long}
                                                    customInput={Input}
                                                />
                                                <FormFeedback>{errors.long}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={'8'}>
                                            <FormGroup>
                                                <YMaps>
                                                    <Map
                                                        onClick={((e: any) => {
                                                            setFieldValue('lat', e.get("coords")[0]);
                                                            setFieldValue('long', e.get("coords")[1])
                                                        })}
                                                        state={{
                                                            center: [values.lat, values.long],
                                                            zoom: 15,
                                                        }}
                                                      className={'map'}>
                                                        <Placemark geometry={[values.lat, values.long]}/>
                                                    </Map>
                                                </YMaps>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {changeError &&
                                    (<Alert color={'danger'}>
                                        {changeError}
                                    </Alert>)}
                                </CardBody>
                            </Card>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default CompanyBranchEdit;
