import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Navigation from "../components/Navigation";


type NavigationsType = {
    accesses?: string[]
    isSidebar: boolean
}

class NavigationContainer extends Component<NavigationsType> {
    render() {
        const {accesses, isSidebar} = this.props;
        return <Navigation isSidebar={isSidebar} accesses={accesses}/>
    }
}


const mapStateToProps = ({auth}: AppState) => {
    const {user} = auth;
    return {
        accesses: user !== null ? user.accesses : undefined
    }
};


export default connect(mapStateToProps, null)(NavigationContainer);
