import {IAction} from "./index";
import {GET_LOCATION, GET_LOCATION_ERROR, GET_LOCATION_REQUEST, GET_LOCATION_SUCCESS} from "../constants/location";
import {ILocation} from "../../models/Order";

export interface IActionLocation extends IAction<symbol, any> {}

export const getLocationRequest = () => {
  return {
    type: GET_LOCATION_REQUEST,
  }
}

export const getLocationSuccess = (payload: ILocation) => {
  return {
    type: GET_LOCATION_SUCCESS,
    payload,
  }
}

export const getLocationError = (payload: string) => {
  return {
    type: GET_LOCATION_ERROR,
    payload,
  }
}

export const getLocation = (id: string) => {
  return {
    type: GET_LOCATION,
    payload: {
      id,
    }
  }
}
