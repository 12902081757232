import {GET_DICTIONARY_ORDER_STATUSES, GET_DICTIONARY_ORDER_STATUSES_REQUEST, GET_DICTIONARY_ORDER_STATUSES_SUCCESS, GET_DICTIONARY_ORDER_STATUSES_ERROR} from '../constants/dictionaryOrderStatuses'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryOrderStatuses extends IAction<symbol, any> {

}

export const getDictionaryOrderStatusesRequest = () => {
    return {
        type: GET_DICTIONARY_ORDER_STATUSES_REQUEST
    }
};

export const getDictionaryOrderStatusesSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_ORDER_STATUSES_SUCCESS,
        payload
    }
};

export const getDictionaryOrderStatusesError = (payload: string) => {
    return {
        type: GET_DICTIONARY_ORDER_STATUSES_ERROR,
        payload
    }
};

export const getDictionaryOrderStatuses = () => {
    return {
        type: GET_DICTIONARY_ORDER_STATUSES
    };
};

