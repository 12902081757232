import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import Courier from "../components/Courier";
import {ICourier} from "../models/Courier";
import {disableStatusCourier, enableStatusCourier, getCourier} from "../store/actions/courier";
import {closeCourierPenaltyForm, openCourierPenaltyForm} from "../store/actions/courierPenaltyForm";


type CourierType = {
    disableStatusCourier: (id: number) => void
    enableStatusCourier: (id: number) => void
    getCourier: (id: number) => void
    data: ICourier | null
    id: number
    getLoading: boolean
    getError: string
    openCourierPenaltyForm: (mode: 'add') => void
    closeCourierPenaltyForm: () => void
    mode: 'add' | 'delete' | null
    accesses?:string[]
}


class CourierContainer extends Component<CourierType> {

    componentDidMount() {
        const {id, getCourier} = this.props;
        getCourier(id);
    }


    render() {
        const {data, getLoading, getError, getCourier, id, mode, openCourierPenaltyForm,
            closeCourierPenaltyForm, enableStatusCourier, disableStatusCourier, accesses} = this.props;

        if (getLoading) {
            return <Loader/>
        }

        if (getError) {
            return <Error error={getError} refresh={() => getCourier(id)}/>
        }
        if (data !== null) {
            return (
                <Courier
                    data={data}
                    mode={mode}
                    accesses={accesses}
                    enableStatusCourier={enableStatusCourier}
                    disableStatusCourier={disableStatusCourier}
                    openCourierPenaltyForm={openCourierPenaltyForm}
                    closeCourierPenaltyForm={closeCourierPenaltyForm}
                />
            )
        }
        return null;
    }
}

const mapStateToProps = ({courier, courierPenaltyForm, auth}: AppState) => {
    const {data, getLoading, getError} = courier;
    const {mode} = courierPenaltyForm;
    const {user} = auth;
    return {
        data,
        getLoading,
        getError,
        mode,
        accesses: user !== null ? user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getCourier,
    openCourierPenaltyForm,
    closeCourierPenaltyForm,
    enableStatusCourier,
    disableStatusCourier
};


export default connect(mapStateToProps, mapDispatchToProps)(CourierContainer);
