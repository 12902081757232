import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {addOrderForm} from "../store/actions/orderForm";
import {IOrderAddFormRequest} from "../models/Order";
import OrderAdd from "../components/OrderAdd";
import {IAuth} from "../models/Auth";
import _ from "lodash";
import {getDictionaryCompanyBranches} from "../store/actions/dictionaryCompanyBranches";
import {IDictionary} from "../models/Dictionary";
import Empty from "../components/UI/Empty";


type OrderAddType = {
    getDictionaryCompanyBranches: (companyId:number, cityId:number) => void
    addOrderForm: (form: IOrderAddFormRequest) => void
    changeLoading: boolean
    changeError: string
    dictionaryCompanyBranches:IDictionary[]
    dictionaryCompanyBranchesLoading:boolean
    dictionaryCompanyBranchesError:string
    companyId?:number
}
type CitiesType = Pick<IAuth, 'allowedCities'>;

class OrderAddContainer extends Component<OrderAddType & CitiesType> {
    render() {
        const {
            changeLoading, changeError, addOrderForm, allowedCities, getDictionaryCompanyBranches,
            dictionaryCompanyBranches, dictionaryCompanyBranchesLoading, dictionaryCompanyBranchesError, companyId
        } = this.props;

        if(companyId) {
            return (
                <OrderAdd
                    addOrderForm={addOrderForm}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    allowedCities={allowedCities}
                    getDictionaryCompanyBranches={getDictionaryCompanyBranches}
                    dictionaryCompanyBranches={dictionaryCompanyBranches}
                    dictionaryCompanyBranchesLoading={dictionaryCompanyBranchesLoading}
                    dictionaryCompanyBranchesError={dictionaryCompanyBranchesError}
                    companyId={companyId}
                />
            )
        }
        return (
            <Empty>
                <h5 className={'mb-0'}>Добавлять заказы могут только партнеры</h5>
            </Empty>
        )
    }
}


const mapStateToProps = ({orderForm, auth, dictionaryCompanyBranches}: AppState) => {
    const {user} = auth;
    return {
        dictionaryCompanyBranches: dictionaryCompanyBranches.data,
        dictionaryCompanyBranchesLoading: dictionaryCompanyBranches.loading,
        dictionaryCompanyBranchesError: dictionaryCompanyBranches.error,
        changeLoading: orderForm.changeLoading,
        changeError: orderForm.changeError,
        allowedCities: _.get(user, 'allowedCities', []),
        companyId: _.get(user, 'companyId')
    }
};

const mapDispatchToProps = {
    addOrderForm,
    getDictionaryCompanyBranches
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderAddContainer);
