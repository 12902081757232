import React, {Component} from "react";
import Layout from "../../containers/Layout";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import ReportEmenuContainer from "../../containers/ReportEmenu";

class ReportEmenuPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Сводка - Заказы</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Сводка - заказы', url: '/reports/emenu'}
                ]}/>
                <ReportEmenuContainer/>
            </Layout>
        )
    }
}

export default ReportEmenuPage