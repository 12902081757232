import {call, put, takeLatest} from 'redux-saga/effects';
import {GET_ROLES} from "../constants/roles";
import {getRolesError, getRolesRequest, getRolesSuccess} from '../actions/roles';
import {IRole} from "../../models/Role";
import { RoleService } from '../../services/RoleService';



function* getRolesSaga() {
    try {
        yield put(getRolesRequest());

        const service = new RoleService();

        const promise = yield call(service.getRoles);

        const response:IRole[] = promise.data || [];

        yield put(getRolesSuccess(response));

    } catch (error) {
        yield put(getRolesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetRoles() {
    yield takeLatest(GET_ROLES, getRolesSaga);
}
