import React, {Component} from "react";
import {IDictionary} from "../../models/Dictionary";
import _ from "lodash";
import {Button, Col, CustomInput, Form, FormGroup, Label, Row} from "reactstrap";
import DateTimePicker from "../UI/DateTimePicker";
import Dropdown from "../UI/Dropdown";
import {Formik} from "formik";
import {ISalaryFilterForm} from "../../models/Salary";
import {dateConvert} from "../../utils/date-convert";

type SalaryFilterType = {
    dictionaryCouriers: IDictionary[];
    filter: ISalaryFilterForm;
    group: boolean;
    setFilterHandler: (form: ISalaryFilterForm) => void;
}

class SalaryFilter extends Component<SalaryFilterType>{
    render() {
        const {dictionaryCouriers, setFilterHandler, filter, group} = this.props;

        const dictionaryCouriersList = dictionaryCouriers && dictionaryCouriers.map((props) => {
            return ({
                label: props.value,
                value: props.key
            })
        })

        const resetValues = {
            dateStart: null,
            dateEnd: null,
            courierId: null,
            isPaidOut: null,
            group: false,
        }

        const initialValues = {
            ...resetValues,
            ...filter,
            dateStart: filter?.dateStart &&
                dateConvert(filter.dateStart, 'yyyy-MM-dd', 'dd-MM-yyyy'),
            dateEnd: filter?.dateEnd &&
                dateConvert(filter.dateEnd, 'yyyy-MM-dd', 'dd-MM-yyyy'),
            group,
        }

        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => {
                    const {dateStart, dateEnd} = values;

                    const filter = {
                        ...values,
                        dateStart: dateStart && dateConvert(dateStart, 'dd-MM-yyyy', 'yyyy-MM-dd'),
                        dateEnd: dateEnd && dateConvert(dateEnd, 'dd-MM-yyyy', 'yyyy-MM-dd'),
                    }

                    const form = _.pickBy(filter, function (value) {
                        return !(value === undefined || value === null || value === '');
                    });

                    setFilterHandler(form)
                }}
            >
                {({setFieldValue, values, dirty, handleSubmit, resetForm}) => {

                    const cannotReset = _.isEmpty(
                      _.pickBy(
                        {...initialValues, group: group || null, cityId: null},
                        value => !(value === undefined || value === null || value === '')
                      )
                    );
                    
                    const resetFormHandler = () => {
                        resetForm();
                        setFilterHandler({})
                    };

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Дата</Label>
                                        <Row form>
                                            <Col>
                                                <DateTimePicker
                                                    name={`dateStart`}
                                                    placeholder={'с'}
                                                    value={values.dateStart}
                                                    onChange={(value) => {
                                                        setFieldValue('dateStart', value)
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <DateTimePicker
                                                    name={`dateEnd`}
                                                    placeholder={'по'}
                                                    value={values.dateEnd}
                                                    onChange={(value) => {
                                                        setFieldValue('dateEnd', value)
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Курьер</Label>
                                        <Dropdown
                                            name={'courierId'}
                                            value={values.courierId}
                                            options={dictionaryCouriersList}
                                            onChange={(value) => setFieldValue('courierId', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Выплата начислена</Label>
                                        <Dropdown
                                            name={'isPaidOut'}
                                            value={values.isPaidOut}
                                            options={[
                                                {
                                                    label: "Начислена",
                                                    value: true
                                                },
                                                {
                                                    label: "Не начислена",
                                                    value: false
                                                }
                                            ]}
                                            onChange={(value) => setFieldValue('isPaidOut', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
    
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Группировать</Label>
                                        <CustomInput
                                          type="switch"
                                          id="group"
                                          checked={values.group}
                                          onChange={() => setFieldValue('group', !values.group)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form className="justify-content-center">
                                <Col md={'auto'}>
                                    <Button
                                        type={'submit'}
                                        color={'primary'}
                                        disabled={!dirty}
                                    >
                                        Применить
                                    </Button>
                                </Col>
                                <Col md={'auto'}>
                                    <Button
                                        onClick={resetFormHandler}
                                        disabled={cannotReset}
                                        color={'light'}
                                    >
                                        Сбросить
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                }}
            </Formik>
        );
    }
}

export default SalaryFilter;
