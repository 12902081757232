import {call, put, takeLatest} from 'redux-saga/effects';
import {getDictionaryCompaniesError, getDictionaryCompaniesRequest, getDictionaryCompaniesSuccess} from '../actions/dictionaryCompanies';
import {GET_DICTIONARY_COMPANIES} from "../constants/dictionaryCompanies";
import {IDictionary} from "../../models/Dictionary";
import { DictionaryService } from '../../services/DictionaryService';



function* getDictionaryCompaniesSaga({payload}:{type: typeof GET_DICTIONARY_COMPANIES, payload?:number}) {
    try {
        yield put(getDictionaryCompaniesRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryCompanies, payload);

        const response:IDictionary[] = promise.data || [];

        yield put(getDictionaryCompaniesSuccess(response));


    } catch (error) {
        yield put(getDictionaryCompaniesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryCompanies() {
    yield takeLatest(GET_DICTIONARY_COMPANIES, getDictionaryCompaniesSaga);
}
