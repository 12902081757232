import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {getCourierSchedule} from "../store/actions/courierSchedule";
import Empty from "../components/UI/Empty";
import Dropdown from "../components/UI/Dropdown";
import {format, parse} from "date-fns";
import {ru} from "date-fns/locale";
import {ICourierSchedule} from "../models/Courier";
import {Col, FormGroup, Row} from "reactstrap";
import CourierScheduleById from "./CourierScheduleById";


type CourierScheduleType = {
    getCourierSchedule: (id: number) => void
    data: ICourierSchedule[]
    id: number
    loading: boolean
    error: string
}


class CourierScheduleContainer extends Component<CourierScheduleType> {

    state = {
        scheduleId: null
    };

    changeScheduleHandler = (scheduleId: number) => {
        this.setState({scheduleId})
    };

    componentDidMount() {
        const {id, getCourierSchedule} = this.props;
        getCourierSchedule(id);
    }

    render() {
        const {scheduleId} = this.state;
        const {data, loading, error, getCourierSchedule, id} = this.props;

        if (loading) {
            return <Loader/>
        }

        if (error) {
            return <Error error={error} refresh={() => getCourierSchedule(id)}/>
        }
        if (!!data.length) {
            const courierScheduleOptions = data.map(({id, dateStart, dateEnd}) => {
                const label = format(parse(dateStart, 'yyyy-MM-dd', new Date()), 'd MMMM', {locale: ru}) + ' - ' + format(parse(dateEnd, 'yyyy-MM-dd', new Date()), 'd MMMM', {locale: ru});
                return {
                    label,
                    value: id
                }
            });

            return (
                <>
                    <FormGroup>
                        <Row>
                            <Col md={'3'}>
                                <Dropdown
                                    name={'scheduleId'}
                                    value={scheduleId || courierScheduleOptions[0].value}
                                    options={courierScheduleOptions}
                                    onChange={(value) => this.changeScheduleHandler(value)}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <CourierScheduleById
                        sectionId={id}
                        id={scheduleId || courierScheduleOptions[0].value}
                    />
                </>
            )
        }
        return (
            <Empty>
                <h5 className={'mb-0'}>Список расписаний пуст</h5>
            </Empty>
        )
    }
}

const mapStateToProps = ({courierSchedule}: AppState) => {
    const {data, loading, error} = courierSchedule;
    return {
        data,
        loading,
        error
    }
};

const mapDispatchToProps = {
    getCourierSchedule
};


export default connect(mapStateToProps, mapDispatchToProps)(CourierScheduleContainer);
