import * as types from "../constants/courierDocuments";
import {IActionCourierDocumentsGet} from "../actions/courierDocuments";
import {IEmployee} from "../../models/Employee";

export interface getCourierDocumentsState {
    data: IEmployee | null
    loading: boolean
    error: string
}

const initialState: getCourierDocumentsState = {
    data: null,
    loading: false,
    error: ''
}

export default function courierDocumentsGet(state = initialState, action: IActionCourierDocumentsGet): getCourierDocumentsState {
    switch (action.type) {
        case types.GET_COURIER_DOCUMENTS_REQUEST:
            return {
                data: null,
                loading: true,
                error: ''
            }
        case types.GET_COURIER_DOCUMENTS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            }
        case types.GET_COURIER_DOCUMENTS_ERROR:
            return {
                data: null,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }

}