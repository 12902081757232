import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import {RouteComponentProps} from "react-router";
import OrderEdit from "../../containers/OrderEdit";


type RouteParamsType = { id: string }

class OrderEditPage extends Component<RouteComponentProps<RouteParamsType>> {
    render() {
        const {match} = this.props;
        const id = match.params.id;

        return (
            <Layout>
                <Title>Редактирование заказа</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Заказы', url: '/orders'},
                    {name: 'Редактирование заказа', url: `/orders/edit/${id}`}
                ]}/>
                <OrderEdit id={id}/>
            </Layout>
        );
    }
}

export default OrderEditPage;
