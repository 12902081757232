import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {AppState} from "../store/reducers";
import {getCouriersShifts} from "../store/actions/couriersShifts";
import {connect} from "react-redux";
import {ISlotFilter, ISlotResponse} from "../models/Slot";
import {GeoObject, Map, Placemark, YMaps} from "react-yandex-maps";
import Loader from "../components/UI/Loader";
import {RouteComponentProps} from "react-router";
import Error from "../components/UI/Error";

type TrackType = {
    orderIds?: number[]
    track: number[][]
    color?: string
    visible?: boolean
}

type CourierTrackType = {
    getCouriersShifts: (page?: number, form?: { expand?: string, form?: ISlotFilter }) => void
    data: ISlotResponse[]
    error: string
    courierId?: number
    slotId?: number
}

type MapStateType = {
    track?: [{
        orderIds?: number[]
        track: number[][]
        color?: string
        visible?: boolean
    }]
}

class CourierTrack extends Component<CourierTrackType> {

    state: MapStateType = {
      track: null!
    }

    /**
     * Запрос информации о смене курьера при монтировании
     */

    componentDidMount() {
        this.fetchHandler();
    }

    /**
     * Запись в стейт информации о треке
     * @param prevProps
     */

    componentDidUpdate(prevProps: Readonly<CourierTrackType & RouteComponentProps>) {
        const {data, courierId, slotId} = this.props;

        if (prevProps.data !== data && data.length){

            const trackInfo = !slotId ?
                data.find(o => o.courierId === courierId)?.trackInfo
                :
                data.find(o => o.id === slotId)?.trackInfo;

            const track = trackInfo!.map((props) => {
                const color = (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6)
                return {
                    ...props,
                    track: props.track?.map((coordinates: number[]) => coordinates.reverse()),
                    color: props.orderIds ? color : "4B93FC",
                    visible: !props.orderIds
                }
            });

            this.setState({track});
        }
    }

    /**
     * Хендлер получение информации о смене курьера
     */

    fetchHandler() {
        const {getCouriersShifts, courierId, slotId} = this.props;

        if (slotId){
            getCouriersShifts(1, {form: {slotId}, expand: "trackInfo"});
        } else {
            getCouriersShifts(1, {form: {courierId}, expand: "trackInfo"});
        }
    }

    /**
     * Хендлер скрытия трека курьера на карте
     * @param index
     */

    hideTrackHandler = (index: number) => {
        if (this.state.track) {
            this.setState((prevState: MapStateType) => {
                if (prevState.track) {
                    const track = prevState.track.slice();
                    track[index] = {...track[index], visible: !track[index].visible}
                    return {track}
                }
            })
        }
    }

    render() {
        const {error} = this.props;
        const {track} = this.state;

        if (error) {
            return <Error error={error} refresh={this.fetchHandler}/>
        }

        if (track){
            const getPoints = (value: number) => {
                return track[0].track.map((props: number[]) => {
                    return props[value]
                })
            }

            const bounds: number[][] = [
                [Math.min.apply(Math, getPoints(0)), Math.min.apply(Math, getPoints(1))],
                [Math.max.apply(Math, getPoints(0)), Math.max.apply(Math, getPoints(1))]
            ]

            return (
                <Row>
                    <Col md={9}>
                        <YMaps>
                            <Map
                                state={{
                                    bounds
                                }}
                                className={'map'}>
                                {
                                    track.map((params: TrackType, index: number) => {
                                        if (params.visible) {
                                            return (
                                                <div key={index}>
                                                    <>
                                                        <Placemark
                                                            geometry={params.track[0]}
                                                            properties={{
                                                                iconContent: '<div style="padding-left: 2px">A</div>'
                                                            }}
                                                            options={{
                                                                iconColor: "#ED4543"
                                                            }}
                                                        />
                                                        <Placemark
                                                            geometry={params.track[params.track.length - 1]}
                                                            properties={{
                                                                iconContent: '<div style="padding-left: 2px">В</div>'
                                                            }}
                                                            options={{
                                                                iconColor: "#ED4543"
                                                            }}
                                                        />
                                                    </>
                                                    <GeoObject
                                                        geometry={{
                                                            type: 'LineString',
                                                            coordinates: params.track,
                                                        }}
                                                        options={{
                                                            geodesic: true,
                                                            strokeWidth: 5,
                                                            opacity: 0.9,
                                                            strokeColor: params.color
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                    })}
                            </Map>
                        </YMaps>
                    </Col>
                    <Col md={3}>
                        {
                            track?.map((props: any, index: number) => {
                                return (
                                    <div
                                        style={{
                                            userSelect: "none",
                                            marginBottom: 10,
                                            cursor: "pointer",
                                            opacity: props.visible ? 1 : 0.3,
                                            fontWeight: props.visible ? 400 : 900
                                        }}
                                        key={index}
                                        onClick={() => this.hideTrackHandler(index)}
                                    >
                                        <Row>
                                            <div style={{
                                                backgroundColor: `#${props.color}`,
                                                height: 20,
                                                marginRight: 5,
                                                width: 20
                                            }}/>
                                            {props.orderIds ? props.orderIds.map((id: number, index: number) => {
                                                if (props.orderIds && props.orderIds.length > 1) {
                                                    const symbol: string = index === props.orderIds.length - 1 ? "" : ","
                                                    return (
                                                        `Заказ №${id}${symbol} `
                                                    )
                                                } else {
                                                    return (
                                                        `Заказ №${id}`
                                                    )
                                                }
                                            }) : "Весь маршрут"}
                                        </Row>
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
            )
        }
        return  <Loader/>
    }
}

const mapStateToProps = ({couriersShifts}: AppState) => {
    const {data, error} = couriersShifts;

    return {
        data,
        error
    }
}

const mapDispatchToProps = {
    getCouriersShifts
}

export default connect(mapStateToProps, mapDispatchToProps)(CourierTrack);