import {GET_REPORT_COMPANIES, GET_REPORT_COMPANIES_REQUEST, GET_REPORT_COMPANIES_SUCCESS, GET_REPORT_COMPANIES_ERROR} from '../constants/reportCompanies'
import {IAction} from "./index";
import {IReportCompany, IReportCompanyFilterRequest, IReportCompanySortRequest} from "../../models/Report";


export interface IActionReportCompanies extends IAction<symbol, any> {

}

export const getReportCompaniesRequest = () => {
    return {
        type: GET_REPORT_COMPANIES_REQUEST
    }
};

export const getReportCompaniesSuccess = (data: IReportCompany[], total:number, by:number) => {
    return {
        type: GET_REPORT_COMPANIES_SUCCESS,
        payload:{
            data,
            total,
            by
        }
    }
};

export const getReportCompaniesError = (payload: string) => {
    return {
        type: GET_REPORT_COMPANIES_ERROR,
        payload
    }
};

export const getReportCompanies = (page?:number, form?: IReportCompanyFilterRequest & IReportCompanySortRequest) => {
    return {
        type: GET_REPORT_COMPANIES,
        payload:{
            page,
            form
        }
    };
};
