import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {addPointZoneForm} from "../store/actions/pointZoneForm";
import PointZoneAdd from "../components/PointZoneAdd";
import {IPointZoneAddFormRequest} from "../models/Point";



type PointZoneAddType = {
    addPointZoneForm: (id: number, form: IPointZoneAddFormRequest) => void
    cancelHandler?: () => void
    id: number
    changeLoading: boolean
    changeError: string
    polygon:number[][]
}

class PointZoneAddContainer extends Component<PointZoneAddType> {

    render() {
        const {id, changeLoading, changeError, cancelHandler, addPointZoneForm, polygon} = this.props;

        return (
            <PointZoneAdd
                polygon={polygon}
                addPointZoneForm={(form:IPointZoneAddFormRequest)=> addPointZoneForm(id,form)}
                changeLoading={changeLoading}
                changeError={changeError}
                cancelHandler={cancelHandler}/>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.pointZoneForm;

    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    addPointZoneForm
};


export default connect(mapStateToProps, mapDispatchToProps)(PointZoneAddContainer);
