import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {changePasswordEmployeeForm} from "../store/actions/employeeForm";
import {IEmployeeChangePasswordFormRequest} from "../models/Employee";
import EmployeeChangePassword from "../components/EmployeeChangePassword";


type EmployeeChangePasswordType = {
    changePasswordEmployeeForm: (id: number, form: IEmployeeChangePasswordFormRequest) => void
    cancelHandler?: () => void
    id: number
    changeLoading: boolean
    changeError: string
}

class EmployeeChangePasswordContainer extends Component<EmployeeChangePasswordType> {

    render() {
        const {changeLoading, changeError, changePasswordEmployeeForm, id, cancelHandler} = this.props;


            return (
                <EmployeeChangePassword
                    changePasswordEmployeeForm={(form)=>changePasswordEmployeeForm(id,form)}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    cancelHandler={cancelHandler}/>
            )
    }
}

const mapStateToProps = (state: AppState) => {
    const {changeLoading, changeError} = state.employeeForm;

    return {
        changeLoading,
        changeError
    }
};

const mapDispatchToProps = {
    changePasswordEmployeeForm
};


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeChangePasswordContainer);
