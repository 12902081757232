import React, {PureComponent} from 'react';
import styles from './Profile.module.scss';
import Icon from "../UI/Icon";
import {Link} from "react-router-dom";

type ProfileType = {
    fio: string
    role?: {
        name: string,
        description: string
    }
}

class Profile extends PureComponent<ProfileType> {
    render() {
        const {fio, role} = this.props;
        return (
            <div className={styles.profile}>
                <Link to={'/profile'}>
                    <div className={styles['profile-img']}>
                        <Icon name={'account_circle'}/>
                    </div>
                </Link>
                <div className={styles['profile-name']}>
                    <Link to={'/profile'}>{fio}</Link>
                </div>
                {role && role.description && <div className={styles['profile-position']}>
                    {role.description}
                </div>}

            </div>
        );
    }
}

export default Profile;
