import {getCouriersShiftsError, getCouriersShiftsRequest, getCouriersShiftsSuccess} from "../actions/couriersShifts";
import {put, takeLatest, call} from "redux-saga/effects";
import {GET_COURIERS_SHIFTS} from "../constants/couriersShifts";
import {CourierService} from "../../services/CourierService";
import {Pagination} from "../../services/Api";
import {removeUrlParams} from "../../utils/history";
import {ISlotFilter, ISlotResponse} from "../../models/Slot";

type getCouriersShiftsSagaType = {
    type: typeof GET_COURIERS_SHIFTS
    payload: {
        page: number,
        params: {
            expand?: string,
            form?: ISlotFilter
        }
    }
}

function* getCouriersShiftsSaga({payload}: getCouriersShiftsSagaType) {
    try {
        yield put(getCouriersShiftsRequest());

        const service = new CourierService();

        const promise = yield call(service.getCouriersSlot, payload.page, payload.params);

        const data: ISlotResponse[] = promise.data || [];

        const pagination = new Pagination(promise.headers).getData();

        yield put(getCouriersShiftsSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page']);
        }

    } catch (error) {
        yield put(getCouriersShiftsError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCouriersShifts() {
    yield takeLatest(GET_COURIERS_SHIFTS, getCouriersShiftsSaga);
}