import React, {PureComponent} from 'react';
import {Alert, Button, Form, FormGroup, Spinner} from "reactstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {requiredMessage} from "../../utils/form-validation";
import {IScheduleDate, IScheduleDateEditFormRequest} from "../../models/Schedule";
import Table from "../UI/Table";
import Icon from "../UI/Icon";
import {IDictionary} from "../../models/Dictionary";
import DateTimePicker from "../UI/DateTimePicker";
import Dropdown from "../UI/Dropdown";
import _ from "lodash";
import {getDictionaryOptions} from "../../utils/dictionaries";
import {sanitizeForm} from "../../utils/sanitize-form";
import ConfirmDialog from "../ConfirmDialog";
import {shiftOptions} from "../../utils/schedules";


const schema = yup.object({
    items: yup.array().required(requiredMessage()).of(
        yup.object().required(requiredMessage()).shape({
            pointId: yup.number().nullable().required(requiredMessage()),
            shiftId: yup.number().nullable().required(requiredMessage()),
            courierId: yup.number().nullable().required(requiredMessage()),
            timeStart: yup.string().required(requiredMessage()),
            timeEnd: yup.string().required(requiredMessage()),
            breakTimeStart: yup.string().nullable(),
            breakTimeEnd: yup.string().nullable(),
        })
    ),
});

type ScheduleDateEditType = {
    data: IScheduleDate[]
    editScheduleDateForm: (form: IScheduleDateEditFormRequest[]) => void
    changeLoading: boolean
    changeError: string
    points: IDictionary[]
    couriers: IDictionary[]
    cancelHandler?: () => void
}

/**
 * Тип формы на основе формы изменения
 * @see IScheduleDateEditFormRequest
 */
type ItemType = {
    pointId: number | null;
    /** id смены указан как undefined для совместимости с старыми типами, но вообще обязателен */
    shiftId?: number | null;
    courierId: number | null;
    timeStart: string | null;
    timeEnd: string | null;
    breakTimeStart?: string | null;
    breakTimeEnd?: string | null;
};

class ScheduleDateEdit extends PureComponent<ScheduleDateEditType> {

    state = {
        isCancelDialog: false
    };

    openCancelDialogHandler = () => {
        this.setState({isCancelDialog: true})
    };

    closeCancelDialogHandler = () => {
        this.setState({isCancelDialog: false})
    };

    render() {
        const {editScheduleDateForm, changeLoading, changeError, points, couriers, cancelHandler, data} = this.props;
        const {isCancelDialog} = this.state;

        const pointOptions = getDictionaryOptions(points);
        const courierOptions = getDictionaryOptions(couriers);

        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {

                    const items = sanitizeForm(values.items.map((o: ItemType) => {
                        const pointId = sanitizeForm(o.pointId);
                        const shiftId = sanitizeForm(o.shiftId);
                        const courierId = sanitizeForm(o.courierId);
                        const timeStart = sanitizeForm(o.timeStart);
                        const timeEnd = sanitizeForm(o.timeEnd);
                        const breakTimeStart = sanitizeForm(o.breakTimeStart);
                        const breakTimeEnd = sanitizeForm(o.breakTimeEnd);
                        
                        return {pointId, shiftId, courierId, timeStart, timeEnd, breakTimeStart, breakTimeEnd}
                    }));

                    editScheduleDateForm(items)
                }}
                initialValues={{
                    items: data || [{
                        pointId: null,
                        shiftId: null,
                        courierId: null,
                        timeStart: null,
                        timeEnd: null,
                        breakTimeStart: null,
                        breakTimeEnd: null,
                    }]
                }}
            >
                {({
                      handleSubmit,
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      dirty
                  }) => {
                    const addItems = () => {
                        setFieldValue('items', [...values.items, {
                            pointId: null,
                            shiftId: null,
                            courierId: null,
                            timeStart: null,
                            timeEnd: null,
                            breakTimeStart: null,
                            breakTimeEnd: null,
                        }])
                    };
                    
                    const changeItems = (index: number, item: ItemType) => {
                        setFieldValue('items', [
                            ...values.items.slice(0, index),
                            item,
                            ...values.items.slice(index + 1)
                        ])
                    };
                    
                    const removeItems = (index: number) => {
                        setFieldValue('items', [
                            ...values.items.slice(0, index),
                            ...values.items.slice(index + 1)
                        ]);
                    };
                    
                    const inputFieldStyle = {width: 140};
                    const dropdownFieldStyle = {minWidth: 160};
                    
                    return (
                        <Form onSubmit={handleSubmit}>
                            {cancelHandler && (
                                <>
                                    <Button
                                        color={'light'}
                                        className={'mb-3'}
                                        onClick={() => dirty ? this.openCancelDialogHandler() : cancelHandler()}>
                                        Назад
                                    </Button>
                                    <ConfirmDialog
                                        isOpen={isCancelDialog}
                                        closeHandler={this.closeCancelDialogHandler}
                                        actionHandler={cancelHandler}
                                        title={'Отменить изменения и покинуть страницу?'}
                                        actionButtonText={'Да, покинуть'}
                                        cancelButtonText={'Нет, остаться'}
                                    />
                                </>
                            )}
                            <FormGroup>
                                <Table className={'mb-2'}>
                                    <thead>
                                    <tr>
                                        <th style={inputFieldStyle}>Время начала</th>
                                        <th style={inputFieldStyle}>Время окончания</th>
                                        <th style={inputFieldStyle}>Время начала перерыва</th>
                                        <th style={inputFieldStyle}>Время окончания перерыва</th>
                                        <th style={dropdownFieldStyle}>Отправная точка</th>
                                        <th style={dropdownFieldStyle}>Смена</th>
                                        <th style={dropdownFieldStyle}>Курьер</th>
                                        <th style={{width: 58}}></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {values.items.map((item: ItemType, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={inputFieldStyle}>
                                                    <DateTimePicker
                                                        name={`items[${index}].timeStart`}
                                                        value={_.get(values, `items[${index}].timeStart`)}
                                                        onChange={timeStart => changeItems(index, {...item, timeStart})}
                                                        dateFormat={'HH:mm'}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        invalid={_.has(touched, `items[${index}].timeStart`) && _.has(errors, `items[${index}].timeStart`)}
                                                    />
                                                </td>
                                                <td style={inputFieldStyle}>
                                                    <DateTimePicker
                                                        name={`items[${index}].timeEnd`}
                                                        value={_.get(values, `items[${index}].timeEnd`)}
                                                        onChange={timeEnd => changeItems(index, {...item, timeEnd})}
                                                        dateFormat={'HH:mm'}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        invalid={_.has(touched, `items[${index}].timeEnd`) && _.has(errors, `items[${index}].timeEnd`)}
                                                    />
                                                </td>
                                                <td style={inputFieldStyle}>
                                                    <DateTimePicker
                                                      name={`items[${index}].breakTimeStart`}
                                                      value={_.get(values, `items[${index}].breakTimeStart`)}
                                                      onChange={breakTimeStart => changeItems(index, {...item, breakTimeStart})}
                                                      dateFormat={'HH:mm'}
                                                      showTimeSelect
                                                      showTimeSelectOnly
                                                      invalid={_.has(touched, `items[${index}].breakTimeStart`) && _.has(errors, `items[${index}].breakTimeStart`)}
                                                    />
                                                </td>
                                                <td style={inputFieldStyle}>
                                                    <DateTimePicker
                                                      name={`items[${index}].breakTimeEnd`}
                                                      value={_.get(values, `items[${index}].breakTimeEnd`)}
                                                      onChange={breakTimeEnd => changeItems(index, {...item, breakTimeEnd})}
                                                      dateFormat={'HH:mm'}
                                                      showTimeSelect
                                                      showTimeSelectOnly
                                                      invalid={_.has(touched, `items[${index}].breakTimeEnd`) && _.has(errors, `items[${index}].breakTimeEnd`)}
                                                    />
                                                </td>
                                                <td>
                                                    <Dropdown
                                                        name={`items[${index}].pointId`}
                                                        value={_.get(values, `items[${index}].pointId`)}
                                                        options={pointOptions}
                                                        placeholder={'Отправные точки'}
                                                        onChange={pointId => changeItems(index, {...item, pointId})}
                                                        invalid={_.has(touched, `items[${index}].pointId`) && _.has(errors, `items[${index}].pointId`)}
                                                    />
                                                </td>
                                                <td>
                                                    <Dropdown
                                                      name={`items[${index}].shiftId`}
                                                      value={_.get(values, `items[${index}].shiftId`)}
                                                      options={shiftOptions}
                                                      placeholder={'Смена'}
                                                      onChange={shiftId => changeItems(index, {...item, shiftId})}
                                                      invalid={_.has(touched, `items[${index}].shiftId`) && _.has(errors, `items[${index}].shiftId`)}
                                                    />
                                                </td>
                                                <td>
                                                    <Dropdown
                                                        name={`items[${index}].courierId`}
                                                        value={_.get(values, `items[${index}].courierId`)}
                                                        options={courierOptions}
                                                        placeholder={'Курьеры'}
                                                        onChange={courierId => changeItems(index, {...item, courierId})}
                                                        invalid={_.has(touched, `items[${index}].courierId`) && _.has(errors, `items[${index}].courierId`)}
                                                    />
                                                </td>
                                                <td style={{width: 58}}>
                                                    <Button
                                                        color={'outline-light'}
                                                        size={'sm'}
                                                        disabled={values.items.length === 1}
                                                        onClick={() => removeItems(index)}>
                                                        <Icon name={'delete'} color={'gray-500'}/>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                                <Button color={'light'} onClick={addItems}>Добавить</Button>
                            </FormGroup>
                            {changeError &&
                            (<Alert className={'mt-3'} color={'danger'}>
                                {changeError}
                            </Alert>)}

                            <Button type="submit" color={'success'} disabled={changeLoading || !dirty}>
                                {changeLoading &&
                                (<Spinner
                                    size="sm"
                                />)}
                                Сохранить
                            </Button>

                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default ScheduleDateEdit;
