import {
    CHANGE_POINT_COURIER_FORM_REQUEST,
    CHANGE_POINT_COURIER_FORM_SUCCESS,
    CHANGE_POINT_COURIER_FORM_ERROR, CLOSE_COURIER_FORM, OPEN_COURIER_FORM
} from '../constants/courierForm'
import {IActionCourierForm} from "../actions/courierForm";
import {ICourier} from "../../models/Courier";


export interface ICourierFormState {
    getLoading: boolean
    getError: string
    data: ICourier | null
    changeLoading: boolean
    changeError: string
    mode: 'changePoint' | null
    modeId: number | null
}

const initialState: ICourierFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null,
    modeId: null
};

export default function courierForm(state = initialState, action: IActionCourierForm): ICourierFormState {
    switch (action.type) {
        case CHANGE_POINT_COURIER_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case CHANGE_POINT_COURIER_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case CHANGE_POINT_COURIER_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_COURIER_FORM:
            const {mode, modeId = null} = action.payload;
            return {
                ...initialState,
                mode,
                modeId
            };
        case CLOSE_COURIER_FORM:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
