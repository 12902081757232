import {call, put, takeLatest} from 'redux-saga/effects';
import {removeUrlParams} from "../../utils/history";
import {CompanyService} from "../../services/CompanyService";
import {
    ICompanyBranch,
    ICompanyBranchFilterRequest,
    ICompanyBranchSortRequest
} from "../../models/Company";
import {Pagination} from "../../services/Api";
import {getCompanyBranchesRequest, getCompanyBranchesSuccess, getCompanyBranchesError} from "../actions/companyBranches";
import {GET_COMPANY_BRANCHES} from "../constants/companyBranches";


type getCompanyBranchesType = {
    type: typeof GET_COMPANY_BRANCHES
    payload:{
        id:number,
        page?:number,
        form?: ICompanyBranchFilterRequest & ICompanyBranchSortRequest
    }
};

function* getCompanyBranchesSaga({payload}: getCompanyBranchesType) {
    try {
        yield put(getCompanyBranchesRequest());

        const service = new CompanyService();
        const promise = yield call(service.getCompanyBranches, payload.id, payload.page, payload.form);

        const data:ICompanyBranch[] = promise.data;
        const pagination = new Pagination(promise.headers).getData();

        yield put(getCompanyBranchesSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
       yield put(getCompanyBranchesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCompanyBranches() {
    yield takeLatest(GET_COMPANY_BRANCHES, getCompanyBranchesSaga);
}
