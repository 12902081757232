import React, {PureComponent} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";


type ConfirmDialogType = {
    isOpen: boolean
    closeHandler: () => void,
    actionHandler: () => void,
    title: string,
    desc?: string,
    actionButtonColor?: string,
    actionButtonText: string,
    cancelButtonText: string
}

class ConfirmDialog extends PureComponent<ConfirmDialogType> {

    render() {
        let {isOpen, closeHandler, actionHandler, title, desc, actionButtonColor, actionButtonText, cancelButtonText} = this.props;
        return (
            <Modal isOpen={isOpen} toggle={closeHandler} unmountOnClose={true}>

                <ModalHeader toggle={closeHandler}>
                    {title}
                </ModalHeader>
                {desc &&
                <ModalBody>
                    {desc}
                </ModalBody>}
                <ModalFooter style={!desc ? { borderTop:'none'}: {}}>
                    <Row form className="justify-content-end">
                        <Col md={'auto'}>
                            <Button
                                color={actionButtonColor ? actionButtonColor : 'primary'}
                                onClick={actionHandler}>
                                {actionButtonText}
                            </Button>
                        </Col>
                        <Col md={'auto'}>
                            <Button
                                onClick={closeHandler}
                                color={'light'}
                            >
                                {cancelButtonText}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}


export default ConfirmDialog;
