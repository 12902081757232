import {
    LOGIN,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    GET_USER,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    GET_USER_REQUEST,
    LOGOUT,
    LOGOUT_SUCCESS,
    CHANGE_PASSWORD_USER_SUCCESS,
    CHANGE_PASSWORD_USER_REQUEST,
    CHANGE_PASSWORD_USER_ERROR,
    CHANGE_PASSWORD_USER
} from '../constants/auth'
import {IAuthChangePasswordRequest, IAuthRequest} from "../../models/Auth";
import {IAuth} from "../../models/Auth";
import {IAction} from "./index";


export interface IActionAuth extends IAction<symbol, any> {

}

export const loginRequest = () => {
    return {
        type: LOGIN_REQUEST
    }
};

export const loginSuccess = (payload: IAuth) => {
    return {
        type: LOGIN_SUCCESS,
        payload
    }
};

export const loginError = (payload: string) => {
    return {
        type: LOGIN_ERROR,
        payload
    }
};

export const login = (payload: IAuthRequest) => {
    return {
        type: LOGIN,
        payload
    };
};

export const getUserRequest = () => {
    return {
        type: GET_USER_REQUEST
    }
};

export const getUserSuccess = (payload: IAuth) => {
    return {
        type: GET_USER_SUCCESS,
        payload
    }
};

export const getUserError = (payload: string) => {
    return {
        type: GET_USER_ERROR,
        payload
    }
};

export const getUser = () => {
    return {
        type: GET_USER
    };
};

export const logoutSuccess = () => {
    return {
        type: LOGOUT_SUCCESS
    };
};

export const logout = () => {
    return {
        type: LOGOUT
    };
};

export const changePasswordUserRequest = () => {
    return {
            type: CHANGE_PASSWORD_USER_REQUEST
    }
};

export const changePasswordUserSuccess = (payload: IAuth) => {
    return {
        type: CHANGE_PASSWORD_USER_SUCCESS,
        payload
    }
};

export const changePasswordUserError = (payload: string) => {
    return {
        type: CHANGE_PASSWORD_USER_ERROR,
        payload
    }
};

export const changePasswordUser = (payload: IAuthChangePasswordRequest) => {
    return {
        type: CHANGE_PASSWORD_USER,
        payload
    };
};
