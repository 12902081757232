import {call, put, takeLatest} from 'redux-saga/effects';
import {GET_SCHEDULES} from "../constants/schedules";
import {getSchedulesError, getSchedulesRequest, getSchedulesSuccess} from "../actions/schedules";
import {removeUrlParams} from "../../utils/history";
import {Pagination} from "../../services/Api";
import {ISchedule} from "../../models/Schedule";
import {ScheduleService} from "../../services/ScheduleService";


type getSchedulesType = {
    type: typeof GET_SCHEDULES,
    payload: {
        page?:number,
        cityId?:number
    }
};

function* getSchedulesSaga({payload}:getSchedulesType) {
    try {
        yield put(getSchedulesRequest());

        const service = new ScheduleService();

        const promise = yield call(service.getSchedules,payload.page, payload.cityId);

        const data: ISchedule[] = promise.data || [];
        const pagination = new Pagination(promise.headers).getData();

        yield put(getSchedulesSuccess(data, pagination.total, pagination.by));

        if (payload.page && (payload.page > pagination.page)) {
            removeUrlParams(['page'])
        }

    } catch (error) {
        yield put(getSchedulesError(error.response.data.message || 'Ошибка'));
    }
}



export function* watchGetSchedules() {
    yield takeLatest(GET_SCHEDULES, getSchedulesSaga);
}
