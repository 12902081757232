import {
    GET_SCHEDULE,
    GET_SCHEDULE_REQUEST,
    GET_SCHEDULE_SUCCESS,
    GET_SCHEDULE_ERROR,
    PUBLISH_SCHEDULE,
    PUBLISH_SCHEDULE_SUCCESS,
    PUBLISH_SCHEDULE_REQUEST,
    PUBLISH_SCHEDULE_ERROR,
    CANCEL_SCHEDULE_REQUEST, CANCEL_SCHEDULE_SUCCESS, CANCEL_SCHEDULE_ERROR, CANCEL_SCHEDULE
} from '../constants/schedule'
import {IAction} from "./index";
import {ISchedule} from "../../models/Schedule";


export interface IActionSchedule extends IAction<symbol, any> {

}

export const getScheduleRequest = () => {
    return {
        type: GET_SCHEDULE_REQUEST
    }
};

export const getScheduleSuccess = (payload: ISchedule) => {
    return {
        type: GET_SCHEDULE_SUCCESS,
        payload
    }
};

export const getScheduleError = (payload: string) => {
    return {
        type: GET_SCHEDULE_ERROR,
        payload
    }
};

export const getSchedule = (payload: number) => {
    return {
        type: GET_SCHEDULE,
        payload
    };
};

export const publishScheduleRequest = () => {
    return {
        type: PUBLISH_SCHEDULE_REQUEST
    }
};

export const publishScheduleSuccess = () => {
    return {
        type: PUBLISH_SCHEDULE_SUCCESS
    }
};

export const publishScheduleError = (payload: string) => {
    return {
        type: PUBLISH_SCHEDULE_ERROR,
        payload
    }
};

export const publishSchedule = (payload: number) => {
    return {
        type: PUBLISH_SCHEDULE,
        payload
    };
};

export const cancelScheduleRequest = () => {
    return {
        type: CANCEL_SCHEDULE_REQUEST
    }
};

export const cancelScheduleSuccess = () => {
    return {
        type: CANCEL_SCHEDULE_SUCCESS
    }
};

export const cancelScheduleError = (payload: string) => {
    return {
        type: CANCEL_SCHEDULE_ERROR,
        payload
    }
};

export const cancelSchedule = (payload: number) => {
    return {
        type: CANCEL_SCHEDULE,
        payload
    };
};

