import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import Pagination from "../components/UI/Pagination";
import queryString from "query-string";
import {getCities} from "../store/actions/cities";
import {ICity, ICityFilterRequest, ICitySortRequest} from "../models/City";
import {citiesFilterReSelector, citiesPageReSelector, citiesSortReSelector} from "../store/reselectors/cities";
import _ from "lodash";
import TableGrid from "../components/UI/TableGrid";
import {timezoneOptions} from "../utils/timezones";
import {statusOptions} from "../utils/statuses";
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import Icon from "../components/UI/Icon";
import {
    openCityForm,
    closeCityForm
} from "../store/actions/cityForm";
import CityEdit from "./CityEdit";
import CityDelete from "./CityDelete";
import Empty from "../components/UI/Empty";
import {externalDeliveryServiceNames, externalDeliveryServiceOptions} from "../utils/order";


type CitiesType = {
    getCities: (page?: number, form?: ICityFilterRequest & ICitySortRequest) => void
    closeCityForm: () => void
    openCityForm: (mode: 'edit' | 'delete', modeId: number) => void
    page: number
    data: ICity[]
    total: number
    by: number
    loading: boolean
    error: string
    filter?: ICityFilterRequest,
    sort?: ICitySortRequest,
    mode: 'add' | 'edit' | 'delete' | null,
    modeId: number | null
}


class CitiesContainer extends Component<CitiesType & RouteComponentProps> {

    componentDidMount() {
        const {getCities, page, filter, sort} = this.props;
        getCities(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
    }

    componentDidUpdate(prevProps: Readonly<CitiesType & RouteComponentProps>) {
        const {page, filter, sort, getCities} = this.props;
        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter) || !_.isEqual(prevProps.sort, sort)) {
            getCities(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)
        }
    }

    changeHandler = <T extends any>(form: T) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const params = _.pick(queryParams, ['id', 'name', 'timezone', 'status', 'deliveryService', 'orderBy']);

        const filter = _.pickBy({...params, ...form}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        const nextQueryParams = queryString.stringify(filter);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    filterHandler = (form: ICityFilterRequest) => {
        this.changeHandler<ICityFilterRequest>(form)
    };

    sortHandler = (form: ICitySortRequest) => {
        const orderBy = form ? Object.keys(form)[0] + '_' + Object.values(form)[0] : undefined;
        this.changeHandler<{ [key: string]: string | undefined }>({orderBy})
    };

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    render() {
        const {
            data, loading, error, page, total, by, getCities, filter,
            mode, modeId,
            closeCityForm,
            openCityForm,
            sort
        } = this.props;

        const filterData = filter;
        const sortData = sort && sort.orderBy;

        return (
            <>
                <div className={'mb-3'}>
                    <TableGrid
                        sourceData={data}
                        filterData={filterData}
                        sortData={sortData}
                        columns={[
                            {
                                label: 'ID',
                                key: 'id',
                                filter: {
                                    type: 'number',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                width: 100,
                            },
                            {
                                label: 'Название',
                                key: 'name',
                                filter: {
                                    type: 'text',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                            },
                            {
                                label: 'Таймзона',
                                key: 'timezone',
                                filter: {
                                    type: 'select',
                                    options: timezoneOptions,
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                width: 280
                            },
                            {
                                label: 'Статус',
                                key: 'status',
                                filter: {
                                    type: 'select',
                                    options: statusOptions,
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                render: (item: ICity) => {
                                    return item.status ? <div className={'semi-bold green'}>Включен</div> :
                                        <div className={'semi-bold red'}>Отключен</div>
                                },
                                width: 280
                            },
                            {
                                label: 'Внешняя доставка',
                                key: 'deliveryService',
                                filter: {
                                    type: 'select',
                                    options: externalDeliveryServiceOptions,
                                    handler: this.filterHandler
                                },
                                render: (item: ICity) =>
                                  item.deliveryService
                                    ? externalDeliveryServiceNames[item.deliveryService]
                                    : '',
                                width: 280
                            },
                            {
                                label: 'Действия',
                                render: (item: ICity) => {
                                    return (
                                        <ButtonGroup>
                                            <Button onClick={() => item && item.id && openCityForm('edit', item.id)}
                                                    size={'sm'} color={'outline-light'}>
                                                <Icon name={'edit'} color={'gray-500'}/>
                                            </Button>
                                            <Button onClick={() => item && item.id && openCityForm('delete', item.id)}
                                                    size={'sm'} color={'outline-light'}>
                                                <Icon name={'delete'} color={'gray-500'}/>
                                            </Button>
                                        </ButtonGroup>
                                    )
                                },
                                width: 100
                            }
                        ]}
                    />
                    {loading && <Loader/>}
                    {error && <Error
                        error={error}
                        refresh={() => getCities(page, !_.isEmpty({...filter, ...sort}) ? {...filter, ...sort} : undefined)}
                    />}
                    {!data.length && !loading && !error &&
                    <Empty>
                        <h3>Список городов пуст</h3>
                        <p className={'mb-0'}>Чтобы добавить город, нажмите кнопку «Добавить город»</p>
                    </Empty>}
                </div>
                <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>

                {modeId !== null && <Modal isOpen={mode === 'edit'} toggle={closeCityForm}>
                    <ModalHeader toggle={closeCityForm}>
                        Редактирование города
                    </ModalHeader>
                    <ModalBody>
                        <CityEdit id={modeId} cancelHandler={closeCityForm}/>
                    </ModalBody>
                </Modal>}
                {modeId !== null && <Modal isOpen={mode === 'delete'} toggle={closeCityForm}>
                    <ModalHeader toggle={closeCityForm}>
                        Удалить отправную точку?
                    </ModalHeader>
                    <ModalBody>
                        {modeId !== null &&
                        <CityDelete id={modeId} cancelHandler={closeCityForm}/>}
                    </ModalBody>
                </Modal>}
            </>
        )
    }
}


const mapStateToProps = (state: AppState, props: RouteComponentProps) => {
    const {data, total, by, loading, error} = state.cities;
    const {mode, modeId} = state.cityForm;

    return {
        data,
        filter: citiesFilterReSelector(props),
        sort: citiesSortReSelector(props),
        page: citiesPageReSelector(props),
        loading,
        error,
        total,
        by,
        mode,
        modeId
    }
};

const mapDispatchToProps = {
    getCities,
    closeCityForm,
    openCityForm
};

export default compose<React.ComponentClass>(withRouter, connect(mapStateToProps, mapDispatchToProps))(CitiesContainer);
