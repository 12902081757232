import React, {Component} from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';
import {connect} from 'react-redux';
import {AppState} from "../store/reducers";
import {IAuth} from "../models/Auth";


type PrivateRouteType = {
    isAuthenticated: boolean
    loading: boolean
    rules?: {
        accesses: string[]
        type?: string
    }
    user?: IAuth | null
    accesses?: string[]
    component: any
}

class PrivateRoute extends Component<PrivateRouteType & RouteProps> {

    render() {
        const {component, isAuthenticated, loading, rules, accesses, user, ...rest} = this.props;

        const Component = component;

        if (!isAuthenticated) {
            return (
                <Redirect to='/login'/>
            )
        }

        if (rules && Array.isArray(accesses)) {
            /**
             * Проверка "Хотя бы одна указанная роль имеется"
             */
            if (rules.type === 'some') {
                if (!rules.accesses.some((rule) => accesses.includes(rule))) {
                    /**
                     * Логика определения адреса, на который нужно перенаправить пользователя
                     * при отсутствии доступа, находится в containers/App
                     * @see determineDefaultPath
                     */
                    return <Redirect to='/'/>
                }
            }
    
            /**
             * Проверка "Все указанные роли имеются"
             */
            if (!rules.type || rules.type === 'every') {
                if (!rules.accesses.every((rule) => accesses.includes(rule))) {
                    return <Redirect to='/'/>
                }
            }
        }
        
        return (
            <Route {...rest} render={props => (<Component {...props} />)}/>
        )
    }
}

const mapStateToProps = ({auth}: AppState) => {
    const {isAuthenticated, loading, user} = auth;
    return {
        isAuthenticated,
        loading,
        user,
        accesses: user !== null ? user.accesses : undefined,
    }
};

export default connect(mapStateToProps)
(
    PrivateRoute
);
