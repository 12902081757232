import {GET_DICTIONARY_ROLES, GET_DICTIONARY_ROLES_REQUEST, GET_DICTIONARY_ROLES_SUCCESS, GET_DICTIONARY_ROLES_ERROR} from '../constants/dictionaryRoles'
import {IAction} from "./index";
import {IDictionary} from "../../models/Dictionary";


export interface IActionDictionaryRoles extends IAction<symbol, any> {

}

export const getDictionaryRolesRequest = () => {
    return {
        type: GET_DICTIONARY_ROLES_REQUEST
    }
};

export const getDictionaryRolesSuccess = (payload: IDictionary[]) => {
    return {
        type: GET_DICTIONARY_ROLES_SUCCESS,
        payload
    }
};

export const getDictionaryRolesError = (payload: string) => {
    return {
        type: GET_DICTIONARY_ROLES_ERROR,
        payload
    }
};

export const getDictionaryRoles = () => {
    return {
        type: GET_DICTIONARY_ROLES
    };
};

