import {call, put, takeLatest} from 'redux-saga/effects';
import {getDictionaryRolesError, getDictionaryRolesRequest, getDictionaryRolesSuccess} from '../actions/dictionaryRoles';
import {GET_DICTIONARY_ROLES} from "../constants/dictionaryRoles";
import {IDictionary} from "../../models/Dictionary";
import { DictionaryService } from '../../services/DictionaryService';



function* getDictionaryRolesSaga() {
    try {
        yield put(getDictionaryRolesRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryRoles);

        const response:IDictionary[] = promise.data || [];

        yield put(getDictionaryRolesSuccess(response));


    } catch (error) {
        yield put(getDictionaryRolesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryRoles() {
    yield takeLatest(GET_DICTIONARY_ROLES, getDictionaryRolesSaga);
}
