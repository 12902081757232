import React, {ChangeEvent, Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import Pagination from "../components/UI/Pagination";
import queryString from "query-string";
import {getEmployees} from "../store/actions/employees";
import _ from "lodash";
import TableGrid from "../components/UI/TableGrid";
import {Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Icon from "../components/UI/Icon";
import Empty from "../components/UI/Empty";
import {
    employeeStatusOptions,
    EMPLOYEE_STATUS_BLOCKED,
    EMPLOYEE_STATUS_ACTIVE,
    EmployeeStatuses, getEmployeesRequestForm, EMPLOYEE_ROLE_COURIER
} from "../utils/employees";
import {IEmployee, IEmployeeFilterRequest, IEmployeeSortRequest} from "../models/Employee";
import {IDictionary} from "../models/Dictionary";
import {getDictionary, getDictionaryOptions} from "../utils/dictionaries";
import {IAuth} from "../models/Auth";
import {getCityOptions, getCityValue} from "../utils/cities";
import {
    employeesFilterReSelector,
    employeesPageReSelector,
    employeesSortReSelector
} from "../store/reselectors/employees";


import EmployeeEdit from "./EmployeeEdit";
import EmployeeDelete from "./EmployeeDelete";
import EmployeeChangePassword from "./EmployeeChangePassword";
import {
    accessEmployeeDelete, accessEmployeeEdit, accessEmployeeChangePassword
} from "../utils/user-accesses";
import {closeEmployeeForm, openEmployeeForm} from "../store/actions/employeeForm";
import {customHistory} from "../utils/history";
import {Link} from "react-router-dom";
import {format, parseISO} from "date-fns";
import Avatar from "../components/UI/Avatar";
import {addEmployeePhotos, deleteEmployeePhotos} from "../store/actions/employeePhotos";
import {courierTypeIcons} from "../utils/couriers";


type EmployeesType = {
    getEmployees: (page?: number, form?: IEmployeeFilterRequest & IEmployeeSortRequest) => void
    openEmployeeForm: (mode: 'edit' | 'delete' | 'changePassword', modeId: number) => void
    closeEmployeeForm: () => void
    addEmployeePhotos: typeof addEmployeePhotos;
    deleteEmployeePhotos: typeof deleteEmployeePhotos;
    page: number
    data: IEmployee[]
    total: number
    by: number
    loading: boolean
    error: string
    filter?: IEmployeeFilterRequest
    sort?: IEmployeeSortRequest
    roles: IDictionary[]
    mode: 'add' | 'edit' | 'delete' | 'changePassword' | null
    modeId: number | null
    accesses?: string[]
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

type RolesType = {
    roles: IDictionary[]
}

type allEmployeesContainerProps = EmployeesType & CitiesType & RolesType & RouteComponentProps

type employeesContainerState = {
    employeeIdForPhotoUpload: number | null;
    employeeIdForPhotoDelete: number | null;
}

class EmployeesContainer extends Component<allEmployeesContainerProps, employeesContainerState> {
    private readonly photoInputRef: React.RefObject<HTMLInputElement>;
    
    constructor(props: allEmployeesContainerProps) {
        super(props);
        this.photoInputRef = React.createRef();
    }
    
    state: employeesContainerState = {
        employeeIdForPhotoUpload: null,
        employeeIdForPhotoDelete: null,
    }
    
    componentDidMount() {
        const {filter} = this.props

        if (!filter) {
            const nextQueryParams = queryString.stringify({
                status: 10
            });
            customHistory.push(`${customHistory.location.pathname}?${nextQueryParams}`);
        }

        this.getData()
    }

    componentDidUpdate(prevProps: Readonly<EmployeesType & CitiesType & RolesType & RouteComponentProps>) {
        const {page, filter, sort} = this.props;

        if (prevProps.page !== page || !_.isEqual(prevProps.filter, filter) || !_.isEqual(prevProps.sort, sort)) {
            this.getData()
        }
    }

    getData = () => {
        const {getEmployees, page, filter, sort} = this.props;

        getEmployees(page, getEmployeesRequestForm(filter, sort));
    };

    changeHandler = <T extends any>(form: T) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const params = _.pick(queryParams, ['id', 'fio', 'phone', 'email', 'role', 'cities', 'status', 'orderBy', 'createdAtStart', 'createdAtFinish']);

        const filter = _.pickBy({...params, ...form}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        const nextQueryParams = queryString.stringify(filter);
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };

    filterHandler = (form: IEmployeeFilterRequest) => {
        this.changeHandler<IEmployeeFilterRequest>(form)
    };

    sortHandler = (form: IEmployeeSortRequest) => {
        const orderBy = form ? Object.keys(form)[0] + '_' + Object.values(form)[0] : undefined;
        this.changeHandler<{ [key: string]: string | undefined }>({orderBy})
    };

    paginateHandler = (page: number) => {
        const {history} = this.props;
        const queryParams = queryString.parse(history.location.search);
        const nextQueryParams = queryString.stringify({...queryParams, page});
        history.push(`${history.location.pathname}?${nextQueryParams}`);
    };
    
    openPhotoFileDialog = (employeeId: number) => {
        if (this.photoInputRef.current) {
            this.setState({
                employeeIdForPhotoUpload: employeeId,
            })
            this.photoInputRef.current.click();
        }
    };
    
    addPhotoHandler = (event: ChangeEvent<HTMLInputElement>) => {
        
        const {addEmployeePhotos} = this.props;
        const {employeeIdForPhotoUpload} = this.state;
        
        if (employeeIdForPhotoUpload && event.target.files?.[0]) {
            const data = new FormData();
            data.append('file', event.target.files?.[0]);
            
            addEmployeePhotos(employeeIdForPhotoUpload, data);
            this.setState({
                employeeIdForPhotoUpload: null,
            })
        }
    }
    
    openDeletePhotoDialog = (employeeId: number) => {
        this.setState({
            employeeIdForPhotoDelete: employeeId,
        })
    }
    
    closeDeletePhotoDialog = () => {
        this.setState({
            employeeIdForPhotoDelete: null,
        })
    }
    
    deletePhotoHandler = () => {
        
        const {deleteEmployeePhotos} = this.props;
        const {employeeIdForPhotoDelete} = this.state;
        
        if (employeeIdForPhotoDelete) {
            deleteEmployeePhotos(employeeIdForPhotoDelete);
            this.setState({
                employeeIdForPhotoDelete: null,
            })
        }
    }

    render() {
        const {
            data, loading, error, page, total, by, filter,
            sort, roles, allowedCities, accesses, mode, modeId, closeEmployeeForm, openEmployeeForm
        } = this.props;
        
        const {
            employeeIdForPhotoDelete,
        } = this.state;

        const filterData = filter;
        const sortData = sort && sort.orderBy;

        const dictionaryOptions = getDictionaryOptions(roles);
        const cityOptions = getCityOptions(allowedCities);
    
        const canEditEmployees = Array.isArray(accesses) && accesses.includes(accessEmployeeEdit);
        
        const editButtonWidth = canEditEmployees ? 42 : 0;
        const deleteButtonWidth = Array.isArray(accesses) && accesses.includes(accessEmployeeDelete) ? 42 : 0;
        const changePasswordButtonWidth = Array.isArray(accesses) && accesses.includes(accessEmployeeChangePassword) ? 42 : 0;
        
        return (
            <>
                <div className={'mb-3'}>
                    <TableGrid
                        sourceData={data}
                        filterData={filterData}
                        sortData={sortData}
                        columns={[
                            {
                                label: 'ID',
                                key: 'id',
                                filter: {
                                    type: 'number',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                width: 100,
                            },
                            {
                                label: 'Фото',
                                key: 'photoUrl',
                                render: (item: IEmployee) =>
                                  <div className="d-flex align-items-end">
                                      <Avatar
                                          alt={item.fio?.slice(0, 1) || '-'}
                                          onClick={canEditEmployees
                                            ? () => this.openPhotoFileDialog(item.id)
                                            : undefined}
                                          src={item.photoUrl ?? ''}
                                          className={canEditEmployees ? "pointer" : undefined}
                                      />
                                      {!!item.photoUrl && canEditEmployees && <div className="pointer" onClick={() => this.openDeletePhotoDialog(item.id)}>
                                          <Icon name={'delete'} color={'gray-500'}/>
                                      </div>}
                                  </div>
                            },
                            {
                                label: 'ФИО',
                                key: 'fio',
                                filter: {
                                    type: 'text',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                render: (item: IEmployee) => {
                                    if (item.role && item.role === EMPLOYEE_ROLE_COURIER) {
                                        return <div className='d-flex align-items-center'>
                                            {item.courierTypeId && <Avatar icon={courierTypeIcons[item.courierTypeId]} className='flex-shrink-0 mr-2'/>}
                                            <Link to={`/couriers/${item.id}`} target={"_blank"}>{item.fio}</Link>
                                        </div>
                                    } else {
                                        return item.fio || '-'
                                    }
                                },
                            },
                            {
                                label: 'Мобильный',
                                key: 'phone',
                                filter: {
                                    type: 'text',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                width: 180
                            },
                            {
                                label: 'Email',
                                key: 'email',
                                filter: {
                                    type: 'text',
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                width: 180
                            },
                            {
                                label: 'Роль',
                                key: 'role',
                                filter: {
                                    type: 'select',
                                    options: dictionaryOptions,
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                render: (item: IEmployee) => {
                                    const dictionaryRole = item.role && getDictionary(roles, item.role);
                                    const roleName = dictionaryRole ? dictionaryRole.value : undefined;
                                    return item.role && roleName
                                },
                                width: 180
                            },
                            {
                                label: 'Города',
                                key: 'cities',
                                filter: {
                                    type: 'select',
                                    options: cityOptions,
                                    handler: this.filterHandler
                                },
                                render: (item: IEmployee) => {
                                    return item.cities && getCityValue(allowedCities, item.cities)
                                },
                                width: 180
                            },
                            {
                                label: 'Дата регистрации',
                                key: 'createdAt',
                                filter: {
                                    type: 'date',
                                    isDoubleField: true,
                                    startPostFix: 'Start',
                                    endPostFix: 'Finish',
                                    handler: this.filterHandler
                                },
                                render: ({createdAt}: IEmployee) => {
                                    return createdAt && format(parseISO(createdAt), 'dd-MM-yyyy')
                                },
                                width: 250
                            },
                            {
                                label: 'Статус',
                                key: 'status',
                                filter: {
                                    type: 'select',
                                    options: employeeStatusOptions,
                                    handler: this.filterHandler
                                },
                                sort: {
                                    handler: this.sortHandler
                                },
                                render: (item: IEmployee) => {
                                    switch (item.status) {
                                        case EMPLOYEE_STATUS_BLOCKED:
                                            return (
                                                <div
                                                    className={'semi-bold red'}>{EmployeeStatuses[EMPLOYEE_STATUS_BLOCKED]}</div>
                                            );
                                        case EMPLOYEE_STATUS_ACTIVE:
                                            return (
                                                <div
                                                    className={'semi-bold green'}>{EmployeeStatuses[EMPLOYEE_STATUS_ACTIVE]}</div>
                                            );

                                    }
                                },
                                width: 180
                            },
                            {
                                label: 'Действия',
                                render: (item: IEmployee) => {
                                    return (
                                        <ButtonGroup>
                                            {canEditEmployees &&
                                            <Button
                                                onClick={() => openEmployeeForm('edit', item.id)}
                                                size={'sm'} color={'outline-light'}>
                                                <Icon name={'edit'} color={'gray-500'}/>
                                            </Button>}
                                            {Array.isArray(accesses) && accesses.includes(accessEmployeeChangePassword) &&
                                            <Button
                                                onClick={() => openEmployeeForm('changePassword', item.id)}
                                                size={'sm'} color={'outline-light'}>
                                                <Icon name={'vpn_key'} color={'gray-500'}/>
                                            </Button>}
                                            {Array.isArray(accesses) && accesses.includes(accessEmployeeDelete) &&
                                            <Button
                                                onClick={() => openEmployeeForm('delete', item.id)}
                                                size={'sm'} color={'outline-light'}>
                                                <Icon name={'delete'} color={'gray-500'}/>
                                            </Button>}
                                        </ButtonGroup>
                                    )
                                },
                                width: 24 + editButtonWidth + deleteButtonWidth + changePasswordButtonWidth
                            }
                        ]}
                    />
                    {loading && <Loader/>}
                    {error && <Error
                        error={error}
                        refresh={() => this.getData()}
                    />}
                    {!data.length && !loading && !error &&
                    <Empty>
                        <h3>Список сотрудников пуст</h3>
                        <p className={'mb-0'}>Чтобы добавить сотрудника, нажмите кнопку «Добавить сотрудника»</p>
                    </Empty>}
                </div>
                <Pagination active={page} by={by} total={total} paginateHandler={this.paginateHandler}/>

                <input
                  type="file"
                  accept="image/x-png,image/jpeg"
                  ref={this.photoInputRef}
                  style={{display: "none"}}
                  onChange={this.addPhotoHandler}
                />
                
                {modeId !== null && <Modal isOpen={mode === 'edit'} size={'lg'} toggle={closeEmployeeForm}>
                    <ModalHeader toggle={closeEmployeeForm}>
                        Редактирование сотрудника
                    </ModalHeader>
                    <ModalBody>
                        <EmployeeEdit id={modeId} roles={roles} cancelHandler={closeEmployeeForm}/>
                    </ModalBody>
                </Modal>}

                {modeId !== null && <Modal isOpen={mode === 'delete'} toggle={closeEmployeeForm}>
                    <ModalHeader toggle={closeEmployeeForm}>
                        Удалить сотрудника?
                    </ModalHeader>
                    <ModalBody>
                        <EmployeeDelete id={modeId} cancelHandler={closeEmployeeForm}/>
                    </ModalBody>
                </Modal>}

                {modeId !== null && <Modal isOpen={mode === 'changePassword'} toggle={closeEmployeeForm}>
                    <ModalHeader toggle={closeEmployeeForm}>
                        Изменение пароля
                    </ModalHeader>
                    <ModalBody>
                        <EmployeeChangePassword id={modeId} cancelHandler={closeEmployeeForm}/>
                    </ModalBody>
                </Modal>}
    
                {canEditEmployees && <Modal size={'md'} isOpen={!!employeeIdForPhotoDelete} toggle={this.closeDeletePhotoDialog}>
                    <ModalHeader toggle={this.closeDeletePhotoDialog}>
                        Удалить фото?
                    </ModalHeader>
                    <ModalFooter style={{borderTop: 'none'}}>
                        <ButtonGroup>
                            <Button
                              onClick={this.closeDeletePhotoDialog}
                              color={"light"}>
                                Отменить
                            </Button>
                            <Button
                              color={'primary'}
                              onClick={() => this.deletePhotoHandler()}>
                                Удалить
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>}
            </>
        )

    }
}


const mapStateToProps = ({employees, employeeForm, auth}: AppState, props: RouteComponentProps) => {
    return {
        data: employees.data,
        filter: employeesFilterReSelector(props),
        sort: employeesSortReSelector(props),
        page: employeesPageReSelector(props),
        loading: employees.loading,
        error: employees.error,
        total: employees.total,
        by: employees.by,
        allowedCities: _.get(auth.user, 'allowedCities', []),
        mode: employeeForm.mode,
        modeId: employeeForm.modeId,
        accesses: auth.user !== null ? auth.user.accesses : undefined
    }
};

const mapDispatchToProps = {
    getEmployees,
    openEmployeeForm,
    closeEmployeeForm,
    addEmployeePhotos,
    deleteEmployeePhotos,
};

export default compose<React.ComponentClass<RolesType>>(withRouter, connect(mapStateToProps, mapDispatchToProps))(EmployeesContainer);
