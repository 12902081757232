import React, {PureComponent} from 'react';
import classNames from 'classnames';
import styles from './Content.module.scss';

/**
 * Компонент - контент сайта
 * @param {Object} children дочерние элементы компонента
 * @returns {*}
 * @constructor
 */


type ContentType = {
  isSidebar:boolean
}

class Content extends PureComponent<ContentType> {
  render() {
    const {children, isSidebar} = this.props;
    return (
        <div className={classNames(styles.content, !isSidebar && styles['content--full'])}>
          {children}
        </div>
    );
  }
}

export default Content;
