import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import CourierStatistics from "../components/CourierStatistics";
import {ICourierStatistics, ICourierStatisticsFilterRequest} from "../models/Courier";
import {getCourierStatistics} from "../store/actions/courierStatistics";
import _ from "lodash";


type CourierStatisticsType = {
    getCourierStatistics: (id: number, form: ICourierStatisticsFilterRequest) => void
    data: ICourierStatistics | null
    id: number
    loading: boolean
    error: string,
    filter: ICourierStatisticsFilterRequest
}

class CourierStatisticsContainer extends Component<CourierStatisticsType> {

    componentDidMount() {
        const {id, getCourierStatistics, filter} = this.props;
        getCourierStatistics(id, filter);
    }

    componentDidUpdate(prevProps: Readonly<CourierStatisticsType>) {
        const {id, getCourierStatistics, filter} = this.props;
        if (!_.isEqual(prevProps.filter, filter)) {
            getCourierStatistics(id, filter);
        }
    }

    render() {
        const {data, loading, error, getCourierStatistics, id, filter} = this.props;

        if (loading) {
            return <Loader/>
        }

        if (error) {
            return <Error error={error} refresh={() => getCourierStatistics(id, filter)}/>
        }
        if (data !== null) {
            return (
                <CourierStatistics
                    data={data}
                />
            )
        }
        return null;
    }
}

const mapStateToProps = ({courierStatistics}: AppState) => {
    const {data, loading, error} = courierStatistics;
    return {
        data,
        loading,
        error
    }
};

const mapDispatchToProps = {
    getCourierStatistics
};


export default connect(mapStateToProps, mapDispatchToProps)(CourierStatisticsContainer);
