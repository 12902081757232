import {call, put, takeLatest} from 'redux-saga/effects';
import {
    getDictionaryCompanyBranchesError,
    getDictionaryCompanyBranchesRequest,
    getDictionaryCompanyBranchesSuccess
} from '../actions/dictionaryCompanyBranches';
import {GET_DICTIONARY_COMPANY_BRANCHES} from "../constants/dictionaryCompanyBranches";
import {IDictionary} from "../../models/Dictionary";
import {DictionaryService} from '../../services/DictionaryService';


function* getDictionaryCompanyBranchesSaga({payload}: { type: typeof GET_DICTIONARY_COMPANY_BRANCHES, payload: {companyId:number, cityId:number} }) {
    try {
        yield put(getDictionaryCompanyBranchesRequest());

        const service = new DictionaryService();

        const promise = yield call(service.getDictionaryCompanyBranches, payload.companyId, payload.cityId);

        const response: IDictionary[] = promise.data || [];

        yield put(getDictionaryCompanyBranchesSuccess(response));


    } catch (error) {
        yield put(getDictionaryCompanyBranchesError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetDictionaryCompanyBranches() {
    yield takeLatest(GET_DICTIONARY_COMPANY_BRANCHES, getDictionaryCompanyBranchesSaga);
}
