import React, {PureComponent} from 'react';
import styles from './Table.module.scss'
import classNames from 'classnames';


type TableType = {
    fixed?: boolean,
    striped?: boolean
    noBorder?: boolean
    size?: string
}

class Table extends PureComponent<TableType & React.HTMLAttributes<HTMLDivElement>> {
    static defaultProps = {fixed: false, striped: false, noBorder: false};

    render() {
        const {children, className, fixed, striped, noBorder, size} = this.props;
        return (
            <table
                className={classNames(styles.table,
                    className,
                    fixed && styles['table--fixed'],
                    striped && styles['table--striped'],
                    noBorder && styles['table--noBorder'],
                    size && styles[`table--${size}`]
                )}>
                {children}
            </table>
        );
    }
}

export default Table;
