import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import Error from "../components/UI/Error";
import Loader from "../components/UI/Loader";
import {getRoles} from "../store/actions/roles";
import TableGrid from "../components/UI/TableGrid";
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import Icon from "../components/UI/Icon";
import Empty from "../components/UI/Empty";
import {IRole} from "../models/Role";
import {
    closeRoleForm,
    openRoleForm
} from "../store/actions/roleForm";
import RoleEdit from "./RoleEdit";
import RoleDelete from "./RoleDelete";


type RolesType = {
    getRoles: () => void
    closeRoleForm: () => void
    openRoleForm: (mode: 'edit' | 'delete', modeId: string) => void
    mode: 'add' | 'edit' | 'delete' | null,
    modeId: string | null
    data: IRole[]
    loading: boolean
    error: string
}

class RolesContainer extends Component<RolesType> {

    componentDidMount() {
        const {getRoles} = this.props;
        getRoles()
    }

    render() {
        const {
            data, loading, error, getRoles, modeId, mode, openRoleForm, closeRoleForm
        } = this.props;

        return (
            <>
                <div className={'mb-3'}>
                    <TableGrid
                        sourceData={data}
                        columns={[
                            {
                                label: 'Название',
                                key: 'name'
                            },
                            {
                                label: 'Описание',
                                key: 'description'
                            },
                            {
                                label: 'Действия',
                                render: (item: IRole) => {
                                    return (
                                        <ButtonGroup>
                                            <Button
                                                onClick={() => openRoleForm('edit', item.name)}
                                                size={'sm'} color={'outline-light'}>
                                                <Icon name={'edit'} color={'gray-500'}/>
                                            </Button>
                                            <Button
                                                onClick={() => openRoleForm('delete', item.name)}
                                                size={'sm'} color={'outline-light'}>
                                                <Icon name={'delete'} color={'gray-500'}/>
                                            </Button>
                                        </ButtonGroup>
                                    )
                                },
                                width: 100
                            }
                        ]}
                    />
                    {loading && <Loader/>}
                    {error && <Error
                        error={error}
                        refresh={() => getRoles()}
                    />}
                    {!data.length && !loading && !error &&
                    <Empty>
                        <h3>Список ролей пуст</h3>
                        <p className={'mb-0'}>Чтобы добавить роль, нажмите кнопку «Добавить роль»</p>
                    </Empty>}
                </div>
                {modeId !== null && <Modal isOpen={mode === 'edit'} toggle={closeRoleForm}>
                    <ModalHeader toggle={closeRoleForm}>
                        Редактирование роли
                    </ModalHeader>
                    <ModalBody>
                        <RoleEdit id={modeId} cancelHandler={closeRoleForm}/>
                    </ModalBody>
                </Modal>}
                {modeId !== null && <Modal isOpen={mode === 'delete'} toggle={closeRoleForm}>
                    <ModalHeader toggle={closeRoleForm}>
                        Удалить роль?
                    </ModalHeader>
                    <ModalBody>
                        <RoleDelete id={modeId} cancelHandler={closeRoleForm}/>
                    </ModalBody>
                </Modal>}
            </>
        )
    }
}


const mapStateToProps = (state: AppState) => {
    const {data, loading, error} = state.roles;
    const {mode, modeId} = state.roleForm;

    return {
        data,
        loading,
        error,
        mode,
        modeId
    }
};

const mapDispatchToProps = {
    getRoles,
    closeRoleForm,
    openRoleForm
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesContainer);
