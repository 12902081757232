import {
    GET_ORDER,
    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    CLEAR_ORDER
} from '../constants/order'
import {IAction} from "./index";
import {IOrder} from "../../models/Order";


export interface IActionOrder extends IAction<symbol, any> {

}

export const getOrderRequest = () => {
    return {
        type: GET_ORDER_REQUEST
    }
};

export const getOrderSuccess = (payload: IOrder) => {
    return {
        type: GET_ORDER_SUCCESS,
        payload
    }
};

export const getOrderError = (payload: string) => {
    return {
        type: GET_ORDER_ERROR,
        payload
    }
};

export const getOrder = (payload: string) => {
    return {
        type: GET_ORDER,
        payload
    };
};

export const clearOrder = () => {
    return {
        type: CLEAR_ORDER
    };
};
