import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addCityFormRequest,
    addCityFormSuccess,
    addCityFormError,
    editCityFormRequest,
    editCityFormSuccess,
    editCityFormError,
    getCityFormRequest,
    getCityFormSuccess,
    getCityFormError,
    deleteCityFormSuccess,
    deleteCityFormRequest,
    deleteCityFormError,
    closeCityForm
} from "../actions/cityForm";
import {ADD_CITY_FORM, DELETE_CITY_FORM, EDIT_CITY_FORM, GET_CITY_FORM} from "../constants/cityForm";
import {ICity, ICityAddFormRequest, ICityEditFormRequest} from '../../models/City';
import {CityService} from "../../services/CityService";
import {getCities} from "../actions/cities";
import {getUrlParams, removeUrlParams} from "../../utils/history";
import _ from 'lodash';



function* addCityFormSaga({payload}: { type: typeof ADD_CITY_FORM, payload: ICityAddFormRequest }) {
    try {
        yield put(addCityFormRequest());

        const service = new CityService();
        const promise = yield call(service.addCity, payload);

        const response:ICity = promise.data;

        yield put(addCityFormSuccess(response));

        yield put(closeCityForm());

        _.isEmpty(getUrlParams()) ? yield put(getCities()): removeUrlParams();

    } catch (error) {
        yield put(addCityFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddCityForm() {
    yield takeLatest(ADD_CITY_FORM, addCityFormSaga);
}


type editCityFormType = {
    type: typeof EDIT_CITY_FORM,
    payload: {
        id: number,
        form: ICityEditFormRequest
    }
}

function* editCityFormSaga({payload}: editCityFormType) {
    try {
        yield put(editCityFormRequest());

        const service = new CityService();
        const promise = yield call(service.editCity, payload.id, payload.form);

        const response:ICity = promise.data;

        yield put(editCityFormSuccess(response));

        yield put(closeCityForm());

        _.isEmpty(getUrlParams()) ? yield put(getCities()): removeUrlParams();

    } catch (error) {
        yield put(editCityFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditCityForm() {
    yield takeLatest(EDIT_CITY_FORM, editCityFormSaga);
}

function* getCityFormSaga({payload}: { type: typeof GET_CITY_FORM, payload: number }) {
    try {
        yield put(getCityFormRequest());

        const service = new CityService();
        const promise = yield call(service.getCity, payload);

        const response:ICity = promise.data;

        yield put(getCityFormSuccess(response));

    } catch (error) {
        yield put(getCityFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCityForm() {
    yield takeLatest(GET_CITY_FORM, getCityFormSaga);
}

function* deleteCityFormSaga({payload}: { type: typeof DELETE_CITY_FORM, payload: number }) {
    try {
        yield put(deleteCityFormRequest());

        const service = new CityService();
        yield call(service.deleteCity, payload);
        yield put(deleteCityFormSuccess());

        yield put(closeCityForm());

        _.isEmpty(getUrlParams()) ? yield put(getCities()): removeUrlParams();

    } catch (error) {
        yield put(deleteCityFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeleteCityForm() {
    yield takeLatest(DELETE_CITY_FORM, deleteCityFormSaga);
}
