import {createSelector} from "reselect";
import queryString from 'query-string';
import _ from 'lodash';


const paymentsUrlSelector = (props: any) => {
    return props.location.search
};

export const paymentsSortReSelector = createSelector(
    [paymentsUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['orderBy']);

        let orderBy = {} as { [key: string]: string };
        if (params.orderBy && typeof params.orderBy === "string") {
            const orderByArray = params.orderBy.split('_');
            orderBy[orderByArray[0]] = orderByArray[1] + '_' + orderByArray[2];
        }

        const sort = _.pickBy(orderBy, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(sort) ? {orderBy: sort} : undefined
    }
);

export const paymentsFilterReSelector = createSelector(
    [paymentsUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['companyId', 'methodId', 'payedAt']);

        const companyId = params.companyId ? +params.companyId : undefined;
        const methodId = params.methodId ? +params.methodId : undefined;
        const payedAt = params.payedAt;

        const filter = _.pickBy({companyId, methodId, payedAt}, function (value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(filter) ? filter : undefined
    }
);

