import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import CompanyBranchAdd from "../../containers/CompanyBranchAdd";
import {RouteComponentProps} from "react-router";

type RouteParamsType = { id: string }

class CompanyBranchAddPage extends Component<RouteComponentProps<RouteParamsType>> {
    render() {
        const {match} = this.props;
        const id = +match.params.id;

        return (
            <Layout>
                <Title>Новый филиал</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Контрагенты', url: '/companies'},
                    {name: 'Контрагент', url: `/companies/${id}`},
                    {name: 'Новый филиал', url: `/companies/${id}/branches/add`}
                ]}/>
                <CompanyBranchAdd id={id}/>
            </Layout>
        );
    }
}

export default CompanyBranchAddPage;
