import {IActionSalary} from "../actions/salary";
import {GET_SALARY_TOTAL_ERROR, GET_SALARY_TOTAL_REQUEST, GET_SALARY_TOTAL_SUCCESS} from "../constants/salary";
import {ISalarySummaryResponse} from "../../models/Salary";

export interface ISalaryTotalState {
    data: ISalarySummaryResponse | null
    loading: boolean
    error: string
}

const initialState: ISalaryTotalState = {
    data: null,
    loading: false,
    error: ''
}

export default function salaryTotal(state = initialState, action: IActionSalary): ISalaryTotalState {
    switch (action.type) {
        case GET_SALARY_TOTAL_REQUEST:
            return {
                ...state,
                data: null,
                loading: true,
                error: ''
            };
        case GET_SALARY_TOTAL_SUCCESS:
            const {data} = action.payload;
            return {
                ...state,
                data,
                loading: false,
                error: ''
            };
        case GET_SALARY_TOTAL_ERROR:
            return {
                ...state,
                data: null,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}