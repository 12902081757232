import React, {PureComponent} from 'react';
import {ICourierPenalty} from "../../models/Courier";
import Table from '../UI/Table';
import FormatPrice from "../UI/FormatPrice";
import {format, parseISO} from "date-fns";
import {Button} from "reactstrap";
import {accessCourierPenaltyManage} from "../../utils/user-accesses";

type CourierPenaltiesType = {
    data: ICourierPenalty[]
    deleteHandler: (id: number) => void
    accesses?: string[]
}

class CourierPenalties extends PureComponent<CourierPenaltiesType> {
    render() {
        const {data, deleteHandler, accesses} = this.props;
        return (
            <Table striped noBorder size={'large'}>
                <tbody>
                <tr>
                    <th style={{width: '20%'}}>Название</th>
                    <th style={{width: '20%'}}>Сумма</th>
                    <th style={{width: '20%'}}>Время и дата</th>
                    <th style={{width: '20%'}}>Лицо, применившее штраф</th>
                    <th style={{width: '20%'}}>Описание</th>
                    {Array.isArray(accesses) && accesses.includes(accessCourierPenaltyManage) &&
                    <th style={{width: 114}}>

                    </th>}
                </tr>
                {data.map((data) => {
                        return (
                            <CourierPenaltiesItem
                                key={data.id}
                                data={data}
                                accesses={accesses}
                                deleteHandler={deleteHandler}
                            />
                        )
                    }
                )}
                </tbody>
            </Table>
        );
    }
}

type CourierPenaltiesItemType = {
    data: ICourierPenalty
    deleteHandler: (id: number) => void
    accesses?: string[]
}

class CourierPenaltiesItem extends PureComponent<CourierPenaltiesItemType> {
    render() {
        const {data, deleteHandler, accesses} = this.props;
        const {id, name, price, createdAt, refereeName, comment} = data;
        return (
            <tr>
                <td style={{width: '20%'}}>
                    {name}
                </td>
                <td style={{width: '20%'}}>
                    <FormatPrice value={price}/>
                </td>
                <td style={{width: '20%'}}>
                    {format(parseISO(createdAt), 'yyyy-MM-dd HH:mm')}
                </td>
                <td style={{width: '20%'}}>
                    {refereeName}
                </td>
                <td style={{width: '20%'}}>
                    {comment}
                </td>
                {Array.isArray(accesses) && accesses.includes(accessCourierPenaltyManage) &&
                <td style={{width: 114}} onClick={() => deleteHandler(id)}>
                    <Button color={'light'}>Отменить</Button>
                </td>}
            </tr>
        );
    }
}

export default CourierPenalties;
