export const ADD_ROLE_FORM = Symbol('ADD_ROLE_FORM');
export const ADD_ROLE_FORM_REQUEST = Symbol('ADD_ROLE_FORM_REQUEST');
export const ADD_ROLE_FORM_SUCCESS = Symbol('ADD_ROLE_FORM_SUCCESS');
export const ADD_ROLE_FORM_ERROR = Symbol('ADD_ROLE_FORM_ERROR');
export const EDIT_ROLE_FORM = Symbol('EDIT_ROLE_FORM');
export const EDIT_ROLE_FORM_REQUEST = Symbol('EDIT_ROLE_FORM_REQUEST');
export const EDIT_ROLE_FORM_SUCCESS = Symbol('EDIT_ROLE_FORM_SUCCESS');
export const EDIT_ROLE_FORM_ERROR = Symbol('EDIT_ROLE_FORM_ERROR');
export const GET_ROLE_FORM = Symbol('GET_ROLE_FORM');
export const GET_ROLE_FORM_REQUEST = Symbol('GET_ROLE_FORM_REQUEST');
export const GET_ROLE_FORM_SUCCESS = Symbol('GET_ROLE_FORM_SUCCESS');
export const GET_ROLE_FORM_ERROR = Symbol('GET_ROLE_FORM_ERROR');
export const DELETE_ROLE_FORM = Symbol('DELETE_ROLE_FORM');
export const DELETE_ROLE_FORM_REQUEST = Symbol('DELETE_ROLE_FORM_REQUEST');
export const DELETE_ROLE_FORM_SUCCESS = Symbol('DELETE_ROLE_FORM_SUCCESS');
export const DELETE_ROLE_FORM_ERROR = Symbol('DELETE_ROLE_FORM_ERROR');
export const OPEN_ROLE_FORM = Symbol('OPEN_ROLE_FORM');
export const CLOSE_ROLE_FORM = Symbol('CLOSE_ROLE_FORM');

