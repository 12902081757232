import {GET_PAYMENTS, GET_PAYMENTS_REQUEST, GET_PAYMENTS_SUCCESS, GET_PAYMENTS_ERROR} from '../constants/payments'
import {IAction} from "./index";
import {IPayment, IPaymentFilterRequest, IPaymentSortRequest} from "../../models/Payment";

export interface IActionPayments extends IAction<symbol, any> {

}

export const getPaymentsRequest = () => {
    return {
        type: GET_PAYMENTS_REQUEST
    }
};

export const getPaymentsSuccess = (payload: IPayment[]) => {
    return {
        type: GET_PAYMENTS_SUCCESS,
        payload
    }
};

export const getPaymentsError = (payload: string) => {
    return {
        type: GET_PAYMENTS_ERROR,
        payload
    }
};

export const getPayments = (payload?: IPaymentFilterRequest & IPaymentSortRequest) => {
    return {
        type: GET_PAYMENTS,
        payload
    };
};
