import {call, put, takeLatest} from 'redux-saga/effects';
import {
    addCompanyContactFormError,
    addCompanyContactFormRequest,
    addCompanyContactFormSuccess,
    closeCompanyContactForm, deleteCompanyContactFormRequest,
    editCompanyContactFormError,
    editCompanyContactFormRequest,
    editCompanyContactFormSuccess,
    getCompanyContactFormError,
    getCompanyContactFormRequest,
    getCompanyContactFormSuccess,
    deleteCompanyContactFormSuccess, deleteCompanyContactFormError
} from "../actions/companyContactForm";
import {
    ADD_COMPANY_CONTACT_FORM, DELETE_COMPANY_CONTACT_FORM,
    EDIT_COMPANY_CONTACT_FORM,
    GET_COMPANY_CONTACT_FORM
} from "../constants/companyContactForm";
import {ICompanyContactAddFormRequest, ICompanyContact, ICompanyContactEditFormRequest
} from '../../models/Company';
import {CompanyService} from "../../services/CompanyService";
import {getCompanyContacts} from "../actions/companyContacts";
import {removeUrlParams} from "../../utils/history";



type addCompanyContactFormType = {
    type: typeof ADD_COMPANY_CONTACT_FORM,
    payload: {
        id: number,
        form: ICompanyContactAddFormRequest
    }
}
function* addCompanyContactForm({payload}: addCompanyContactFormType) {
    try {
        yield put(addCompanyContactFormRequest());

        const service = new CompanyService();
        const promise = yield call(service.addCompanyContact, payload.id, payload.form);

        const response: ICompanyContact = promise.data;

        yield put(addCompanyContactFormSuccess(response));

        yield put(closeCompanyContactForm());

        yield put(getCompanyContacts(payload.id));

    } catch (error) {
        yield put(addCompanyContactFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchAddCompanyContactForm() {
    yield takeLatest(ADD_COMPANY_CONTACT_FORM, addCompanyContactForm);
}

type editCompanyContactFormType = {
    type: typeof EDIT_COMPANY_CONTACT_FORM,
    payload: {
        sectionId: number,
        id: number,
        form: ICompanyContactEditFormRequest
    }
}

function* editCompanyContactForm({payload}: editCompanyContactFormType) {
    try {
        yield put(editCompanyContactFormRequest());

        const service = new CompanyService();
        const promise = yield call(service.editCompanyContact, payload.id, payload.form);

        const response:ICompanyContact = promise.data;

        yield put(editCompanyContactFormSuccess(response));

        yield put(closeCompanyContactForm());

        yield put(getCompanyContacts(payload.sectionId));

    } catch (error) {
        yield put(editCompanyContactFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchEditCompanyContactForm() {
    yield takeLatest(EDIT_COMPANY_CONTACT_FORM, editCompanyContactForm);
}

function* getCompanyContactFormSaga({payload}: { type: typeof GET_COMPANY_CONTACT_FORM, payload: number }) {
    try {
        yield put(getCompanyContactFormRequest());

        const service = new CompanyService();
        const promise = yield call(service.getCompanyContact, payload);

        const response:ICompanyContact = promise.data;

        yield put(getCompanyContactFormSuccess(response));

    } catch (error) {
        yield put(getCompanyContactFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchGetCompanyContactForm() {
    yield takeLatest(GET_COMPANY_CONTACT_FORM, getCompanyContactFormSaga);
}


type deleteCompanyContactFormType = {
    type: typeof DELETE_COMPANY_CONTACT_FORM,
    payload: {
        sectionId:number
        id: number
    }
}
function* deleteCompanyContactFormSaga({payload}: deleteCompanyContactFormType) {
    try {
        yield put(deleteCompanyContactFormRequest());

        const service = new CompanyService();
        yield call(service.deleteCompanyContact, payload.id);
        yield put(deleteCompanyContactFormSuccess());

        yield put(closeCompanyContactForm());

        yield put(getCompanyContacts(payload.sectionId));

        removeUrlParams(['page'])

    } catch (error) {
        yield put(deleteCompanyContactFormError(error.response.data.message || 'Ошибка'));
    }
}

export function* watchDeleteCompanyContactForm() {
    yield takeLatest(DELETE_COMPANY_CONTACT_FORM, deleteCompanyContactFormSaga);
}
