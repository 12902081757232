import {
    GET_COURIER_SCHEDULE,
    GET_COURIER_SCHEDULE_REQUEST,
    GET_COURIER_SCHEDULE_SUCCESS,
    GET_COURIER_SCHEDULE_ERROR
} from '../constants/courierSchedule'
import {IAction} from "./index";
import {ICourierSchedule} from "../../models/Courier";


export interface IActionCourierSchedule extends IAction<symbol, any> {

}

export const getCourierScheduleRequest = () => {
    return {
        type: GET_COURIER_SCHEDULE_REQUEST
    }
};

export const getCourierScheduleSuccess = (payload: ICourierSchedule[]) => {
    return {
        type: GET_COURIER_SCHEDULE_SUCCESS,
        payload
    }
};

export const getCourierScheduleError = (payload: string) => {
    return {
        type: GET_COURIER_SCHEDULE_ERROR,
        payload
    }
};

export const getCourierSchedule = (payload: number) => {
    return {
        type: GET_COURIER_SCHEDULE,
        payload
    };
};
