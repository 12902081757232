import React, {Component} from "react";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Layout from "../../containers/Layout";
import Roles from "../../containers/Roles";
import RoleNavbar from "../../containers/RoleNavbar";


class DirectoryRolesPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Справочник - Роли</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Справочник - роли', url: '/directories/roles'}
                ]}/>
                <RoleNavbar/>
                <Roles/>
            </Layout>
        );
    }
}

export default DirectoryRolesPage;
