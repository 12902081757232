import {
    GET_DICTIONARY_CONTACT_TYPES_ERROR,
    GET_DICTIONARY_CONTACT_TYPES_REQUEST,
    GET_DICTIONARY_CONTACT_TYPES_SUCCESS
} from "../constants/dictionaryContactTypes";
import {IDictionary} from "../../models/Dictionary";
import {IActionDictionaryContactTypes} from "../actions/dictionaryContactTypes";


export interface IDictionaryContactTypeState {
    loading: boolean
    error: string
    data: IDictionary[]
}

const initialState: IDictionaryContactTypeState = {
    data: [],
    loading: false,
    error: ''
};

export default function dictionaryContactTypes(state = initialState, action: IActionDictionaryContactTypes): IDictionaryContactTypeState {
    switch (action.type) {
        case GET_DICTIONARY_CONTACT_TYPES_REQUEST:
            return {
                data: [],
                loading: true,
                error: ''
            };
        case GET_DICTIONARY_CONTACT_TYPES_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_DICTIONARY_CONTACT_TYPES_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
