import {
    PAY_SALARY,
    PAY_SALARY_ERROR,
    PAY_SALARY_REQUEST,
    PAY_SALARY_SUCCESS,
    GET_SALARY,
    GET_SALARY_ERROR,
    GET_SALARY_REQUEST,
    GET_SALARY_SUCCESS,
    GET_SALARY_TOTAL,
    GET_SALARY_TOTAL_ERROR,
    GET_SALARY_TOTAL_REQUEST,
    GET_SALARY_TOTAL_SUCCESS,
    PAY_GROUP_SALARY,
    PAY_GROUP_SALARY_REQUEST,
    PAY_GROUP_SALARY_SUCCESS,
    PAY_GROUP_SALARY_ERROR
} from "../constants/salary";
import {IAction} from "./index";
import {
    ISalaryFilterForm, ISalaryGroupResponse,
    ISalaryResponse,
    ISalarySummaryResponse
} from "../../models/Salary";

export interface IActionSalary extends IAction<symbol, any> {

}

export const getSalary = (page: number, cityId?: number, form?: ISalaryFilterForm, group = false) => {
    return {
        type: GET_SALARY,
        payload: {
            page,
            cityId,
            form,
            group,
        }
    }
}

export const getSalaryRequest = () => {
    return {
        type: GET_SALARY_REQUEST
    }
}

export const getSalarySuccess = (data: ISalaryResponse[] | ISalaryGroupResponse[], total:number, by:number) => {
    return {
        type: GET_SALARY_SUCCESS,
        payload: {
            data,
            total,
            by
        }
    }
}

export const getSalaryError = (payload: string) => {
    return {
        type: GET_SALARY_ERROR,
        payload
    }
}

export const getSalaryTotal = (cityId?: number, form?: ISalaryFilterForm) => {
    return {
        type: GET_SALARY_TOTAL,
        payload: {
            cityId,
            form
        }
    }
}

export const getSalaryTotalRequest = () => {
    return {
        type: GET_SALARY_TOTAL_REQUEST
    }
}

export const getSalaryTotalSuccess = (data: ISalarySummaryResponse) => {
    return {
        type: GET_SALARY_TOTAL_SUCCESS,
        payload: {
            data
        }
    }
}

export const getSalaryTotalError = (payload: string) => {
    return {
        type: GET_SALARY_TOTAL_ERROR,
        payload
    }
}

export const paySalary = (id: number) => {
    return {
        type: PAY_SALARY,
        payload: {
            id
        }
    }
}

export const paySalaryRequest = () => {
    return {
        type: PAY_SALARY_REQUEST
    }
}

export const paySalarySuccess = (data: ISalaryResponse) => {
    return {
        type: PAY_SALARY_SUCCESS,
        payload: {
            data
        }
    }
}

export const paySalaryError = (payload: string) => {
    return {
        type: PAY_SALARY_ERROR,
        payload
    }
}

export const payGroupSalary = (ids: number[]) => {
    return {
        type: PAY_GROUP_SALARY,
        payload: {
            ids,
        }
    }
}

export const payGroupSalaryRequest = () => {
    return {
        type: PAY_GROUP_SALARY_REQUEST,
    }
}

export const payGroupSalarySuccess = () => {
    return {
        type: PAY_GROUP_SALARY_SUCCESS,
    }
}

export const payGroupSalaryError = (payload: string) => {
    return {
        type: PAY_GROUP_SALARY_ERROR,
        payload,
    }
}
