import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../store/reducers";
import {editPointForm, getPointForm} from "../store/actions/pointForm";
import {IPoint, IPointEditFormRequest} from "../models/Point";
import PointEdit from "../components/PointEdit";
import Loader from "../components/UI/Loader";
import Error from "../components/UI/Error";
import {IAuth} from "../models/Auth";
import _ from "lodash";


type PointEditType = {
    getPointForm: (id: number) => void
    editPointForm: (id: number, form: IPointEditFormRequest) => void
    cancelHandler?: () => void
    data: IPoint | null
    id: number
    changeLoading:boolean
    changeError:string
    getLoading:boolean
    getError:string
}

type CitiesType = Pick<IAuth, 'allowedCities'>;

class PointEditContainer extends Component<PointEditType & CitiesType> {

    componentDidMount() {
        const {id, getPointForm} = this.props;
        getPointForm(id);
    }


    render() {
        const {data, getLoading, getError, changeLoading, changeError, editPointForm, getPointForm, id, allowedCities, cancelHandler} = this.props;

        if(getLoading){
            return <Loader/>
        }

        if(getError){
            return <Error error={getError} refresh={() => getPointForm(id)}/>
        }
        if(data !== null) {
            return (
                <PointEdit
                    editPointForm={editPointForm}
                    data={data}
                    changeLoading={changeLoading}
                    changeError={changeError}
                    allowedCities={allowedCities}
                    cancelHandler={cancelHandler}/>
            )
        }
        return null;
    }
}

const mapStateToProps = (state: AppState) => {
    const {data, getLoading, getError, changeLoading, changeError} = state.pointForm;
    const {user} = state.auth;

    return {
        data,
        getLoading,
        getError,
        changeLoading,
        changeError,
        allowedCities: _.get(user, 'allowedCities', [])
    }
};

const mapDispatchToProps = {
    getPointForm,
    editPointForm
};


export default connect(mapStateToProps, mapDispatchToProps)(PointEditContainer);
