import React, {PureComponent} from 'react';
import Menu from '../UI/Menu';
import {
    accessCompanyShow, accessCourierShow,
    accessDirectoryCities, accessDirectoryPenalties,
    accessDirectoryRoles,
    accessEmployeeShow, accessNotificationManage, accessOrderShow, accessPaymentShow,
    accessPointShow, accessReportCompanyManage, accessReportCourierManage, accessScheduleShow,
    accessReportEmenuView, accessCourierSlotManage, courierRateManage, accessSalaryManage
} from "../../utils/user-accesses";

type NavigationsType = {
    accesses?: string[]
    isSidebar: boolean
}

class Navigation extends PureComponent<NavigationsType> {
    render() {
        const {accesses, isSidebar} = this.props;
        if(Array.isArray(accesses)) {
            return (
                <Menu>
                    {accesses.includes(accessOrderShow) &&
                    <Menu.Element title="Активные заказы" icon={"list_alt"} url="/orders"/>}
                    {accesses.includes(accessOrderShow) &&
                    <Menu.Element title="Архив" icon={"archive"} url="/archive-orders"/>}
                    {accesses.includes(accessCompanyShow) &&
                    <Menu.Element title="Контрагенты" icon={"business_center"} url="/companies"/>}
                    {accesses.includes(accessScheduleShow) &&
                    <Menu.Element title="Расписание" icon={"schedule"} url="/schedules"/>}
                    {accesses.includes(accessCourierShow) &&
                    <Menu.Element title="Курьеры онлайн" icon={"directions_run"} url="/couriers"/>}
                    {accesses.includes(accessCourierSlotManage) &&
                    <Menu.Element title="Смены курьеров" icon={"date_range"} url="/couriers-shifts"/>}
                    {accesses.includes(accessPointShow) &&
                    <Menu.Element title="Отправные точки" icon={"my_location"} url="/points"/>}
                    {accesses.includes(accessEmployeeShow) &&
                    <Menu.Element title="Сотрудники" icon={"group"} url="/employees"/>}
                    {(accesses.includes(accessDirectoryCities) || accesses.includes(accessDirectoryRoles) || accesses.includes(accessDirectoryPenalties)) && (
                        <>
                            {isSidebar ?
                                <Menu.Element title="Справочники" icon={"import_contacts"} url={'/directories'}>
                                    {accesses.includes(accessDirectoryCities) &&
                                    <Menu.Element.Item title="Города" url="/directories/cities"/>}
                                    {accesses.includes(accessDirectoryRoles) &&
                                    <Menu.Element.Item title="Роли" url="/directories/roles"/>}
                                    {accesses.includes(accessDirectoryPenalties) &&
                                    <Menu.Element.Item title="Штрафы" url="/directories/penalties"/>}
                                </Menu.Element>
                                :
                                <Menu.Element title="Справочники" icon={"import_contacts"} url={'/directories'}/>}
                        </>
                    )}
                    {(accesses.includes(accessReportCompanyManage) || accesses.includes(accessReportCourierManage) || accesses.includes(accessReportEmenuView)) && (
                        <>
                            {isSidebar ?
                                <Menu.Element title="Сводки" icon={"assessment"} url={'/reports'}>
                                    {accesses.includes(accessReportCompanyManage) &&
                                    <Menu.Element.Item title="Контрагенты" url="/reports/companies"/>}
                                    {accesses.includes(accessReportCourierManage) &&
                                    <Menu.Element.Item title="Курьеры" url="/reports/couriers"/>}
                                    {accesses.includes(accessReportEmenuView) &&
                                    <Menu.Element.Item title={"Отчет по заказам"} url={'/reports/emenu'} />}
                                </Menu.Element>
                                :
                                <Menu.Element title="Сводка и оплаты" icon={"assessment"} url={'/reports'}/>}
                        </>
                    )}
                    {accesses.includes(accessPaymentShow) &&
                    <Menu.Element title="Оплаты" icon={"attach_money"} url="/payments"/>}

                    {accesses.includes(accessSalaryManage) &&
                    <Menu.Element title="Выплаты" icon={"payments"} url={'/salary'}/>}

                    {accesses.includes(courierRateManage) &&
                    <>
                        {isSidebar ?
                            <Menu.Element title="Настройки" icon={"settings"} url={'/settings'}>
                                <Menu.Element.Item title="Ставки" url={'/settings/courier-rate'}/>
                                <Menu.Element.Item title="Бонусная сетка" url={'/settings/bonus-rate'}/>
                                <Menu.Element.Item title="Повышенные ставки" url={'/settings/ratio-rate'}/>
                            </Menu.Element>
                            :
                            <Menu.Element title="Настройки" icon={"settings"} url={'/settings'}>
                            </Menu.Element>
                        }
                    </>}

                    {accesses.includes(accessNotificationManage) &&
                    <Menu.Element title="Уведомления" icon={"notifications"} url="/notifications"/>}

                </Menu>
            )
        }
        return null
    }
}

export default Navigation;
