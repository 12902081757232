import React from "react";
import {ISalaryGroupResponse, ISalarySummaryResponse} from "../../models/Salary";
import TableGrid from "../UI/TableGrid";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import Icon from "../UI/Icon";
import {format, parseISO} from "date-fns";
import ConfirmDialog from "../ConfirmDialog";

type SalaryGroupListType = {
  data: ISalaryGroupResponse[];
  totalData: ISalarySummaryResponse;
  payGroupSalary: (ids: number[]) => void;
}

type SalaryGroupListState = {
  isSalaryPayConfirm: boolean;
  salaryIds: number[] | null;
}

class SalaryGroupList extends React.Component<SalaryGroupListType, SalaryGroupListState> {
  
  state: SalaryGroupListState = {
    isSalaryPayConfirm: false,
    salaryIds: null,
  }
  
  openPaySalaryConfirm = (salaryIds: number[]) => {
    this.setState({isSalaryPayConfirm: true, salaryIds});
  }
  
  closePaySalaryConfirm = () => {
    this.setState({isSalaryPayConfirm: false});
  }
  
  paySalaryHandler = () => {
    const {payGroupSalary} = this.props;
    const {salaryIds} = this.state;
    
    setTimeout(function () {
      if (salaryIds) {
        payGroupSalary(salaryIds);
      }
    }, 400)
    
    this.closePaySalaryConfirm();
  }
  
  render() {
    const {data, totalData} = this.props;
    const {isSalaryPayConfirm} = this.state;
    
    return <>
      <TableGrid
        hasBorder
        className={'border-bottom-0'}
        sourceData={data}
        footerData={totalData}
        columns={[
          {
            label: 'Дата',
            key: 'workDateStart',
            width: 100,
            render: (item: ISalaryGroupResponse) => {
              const {workDateStart, workDateEnd} = item;
              
              const start = workDateStart ? format(parseISO(workDateStart), 'dd-MM-yyyy') : '...';
              const end = workDateEnd ? format(parseISO(workDateEnd), 'dd-MM-yyyy') : '...';
              
              return <>{start}<br />{end}</>
            }
          },
          {
            label: 'Курьер',
            key: 'courier',
            render: (item: ISalaryGroupResponse) => {
              return <Link to={`/couriers/${item.courier.id}`}
                           target={'_blank'}>{item.courier.fio}</Link>
            },
            width: 150
          },
          {
            label: 'Часы',
            key: 'hoursAmount',
            width: 80
          },
          {
            label: 'Заказы',
            key: 'orderAmount',
            width: 80
          },
          {
            label: 'Ежедневные бонусы',
            key: 'dailyBonus',
            width: 120
          },
          {
            label: 'Штрафы',
            key: 'penalty',
            width: 80
          },
          {
            label: 'Итого (дневной заработок курьера)',
            key: 'totalAmount',
            width: 200
          },
          {
            label: 'Удержание',
            key: 'holding',
            width: 100
          },
          {
            label: 'Платная доставка',
            key: 'paidDelivery',
            width: 100
          },
          {
            label: 'Выплата',
            key: 'payouts',
            width: 100
          },
          {
            label: '',
            key: 'isPaidOut',
            render: (item: ISalaryGroupResponse) => {
              return (
                <div className={"d-flex"}>
                  <Button className={"ml-auto mr-auto"}
                          size={'sm'}
                          disabled={item.isPaidOut}
                          color={item.isPaidOut ? 'success' : 'danger'}
                          title={item.isPaidOut ? 'Деньги выданы' : 'Выдача денег курьеру'}
                          onClick={() => this.openPaySalaryConfirm(item.ids)}
                  >
                    <Icon name={'done'} color={'white'}/>
                  </Button>
                </div>
              )
            },
            width: 20
          }
        ]}
        footerColumns={[
          {
            key: 'workDate',
            value: 'Итого'
          },
          {
            key: 'courier',
            hidden: true
          },
          {
            key: 'hoursAmount'
          },
          {
            key: 'orderAmount'
          },
          {
            key: 'dailyBonus'
          },
          {
            key: 'penalty'
          },
          {
            key: 'totalAmount'
          },
          {
            key: 'holding'
          },
          {
            key: 'paidDelivery'
          },
          {
            key: 'payouts'
          },
          {
            key: 'isPaidOut',
            hidden: true
          }
        ]}
      />

      <ConfirmDialog
        isOpen={isSalaryPayConfirm}
        closeHandler={this.closePaySalaryConfirm}
        actionHandler={this.paySalaryHandler}
        title={"Выплатить деньги курьеру?"}
        actionButtonText={"Выплатить"}
        cancelButtonText={"Отмена"}
      />
    </>;
  }
}

export default SalaryGroupList;
