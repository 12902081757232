import React, {Component} from "react";
import Layout from "../../containers/Layout";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import SalaryOutput from "../../containers/SalaryOutput";

class SalaryPage extends Component {
    render() {
        return (
            <Layout>
                <Title>Выплаты</Title>
                <Breadcrumbs links={[
                    {name: 'Главная', url: '/'},
                    {name: 'Выплаты', url: '/salary'}
                ]}/>
                <SalaryOutput/>
            </Layout>
        );
    }
}

export default SalaryPage;