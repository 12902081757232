import React, {PureComponent} from 'react';
import NumberFormat from "react-number-format";


type FormatPriceType = {
    value: string | number
};

class FormatPrice extends PureComponent<FormatPriceType> {
    render() {
        const {value} = this.props;
        return (
            <>
                <NumberFormat value={value} displayType={'text'} thousandSeparator={' '}/> ₸
            </>
        );
    }
}

export default FormatPrice;
