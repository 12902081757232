import React, {PureComponent} from 'react';
import {Button, ButtonGroup, Card, Col, Row} from "reactstrap";
import {ICompanyContact} from "../../models/Company";
import Icon from "../UI/Icon";
import Avatar from "../UI/Avatar";
import {accessCompanyContactDelete, accessCompanyContactEdit} from "../../utils/user-accesses";
import {COMPANY_CONTACT_EMAIL, COMPANY_CONTACT_MOBILE} from "../../utils/companies";
import {IDictionary} from "../../models/Dictionary";
import {getDictionary} from "../../utils/dictionaries";


interface CompanyContactsType {
    editHandler: (id: number) => void
    deleteHandler: (id: number) => void
    data: ICompanyContact[]
    accesses?: string[]
    contactTypes: IDictionary[]
}

class CompanyContacts extends PureComponent<CompanyContactsType> {
    render() {
        const {data, editHandler, deleteHandler, accesses, contactTypes} = this.props;
        return (
            <>
                {data.map((data) => (
                        <CompanyContactsItem
                            key={data.id}
                            item={data}
                            editHandler={editHandler}
                            deleteHandler={deleteHandler}
                            accesses={accesses}
                            contactTypes={contactTypes}
                        />
                    )
                )}
            </>
        )
    }
}

type PointsItemType = {
    item: ICompanyContact
    editHandler: (id: number) => void
    deleteHandler: (id: number) => void
    accesses?: string[]
    contactTypes: IDictionary[]
}

class CompanyContactsItem extends PureComponent<PointsItemType> {
    render() {
        const {item, editHandler, deleteHandler, accesses, contactTypes} = this.props;
        const {id, name, typeId, data} = item;

        const dictionaryContactType = getDictionary(contactTypes, typeId);
        const contactTypeName = dictionaryContactType ? dictionaryContactType.value : undefined;

        return (
            <Card body style={{marginBottom: -1}}>
                <div className={'mb-3'}>
                    <Row>
                        <Col>
                            <Row form className="align-items-center">
                                <Col md={'auto'}>
                                    <Avatar icon={'account_circle'}/>
                                </Col>
                                <Col>
                                    <div className={'gray-500'}>{contactTypeName}</div>
                                    <div className={'semi-bold'}>{name}</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={'auto'}>
                            <ButtonGroup>
                                {Array.isArray(accesses) && accesses.includes(accessCompanyContactEdit) &&
                                <Button size={'sm'} color={'outline-light'} onClick={() => editHandler(id)}>
                                    <Icon name={'edit'} color={'gray-500'}/>
                                </Button>}
                                {Array.isArray(accesses) && accesses.includes(accessCompanyContactDelete) &&
                                <Button size={'sm'} color={'outline-light'} onClick={() => deleteHandler(id)}>
                                    <Icon name={'delete'} color={'gray-500'}/>
                                </Button>}
                            </ButtonGroup>
                        </Col>
                    </Row>
                </div>
                <Row>
                    {data.map(({typeId, value}) => {
                        return (
                            <Col key={typeId + value} md={'3'}>
                                <Row form>
                                    <Col md={'auto'}>
                                        <Avatar icon={'call'}/>
                                    </Col>
                                    <Col>
                                        <div
                                            className={'gray-500'}>{typeId === COMPANY_CONTACT_MOBILE ? 'Телефон' : typeId === COMPANY_CONTACT_EMAIL ? 'E-mail' : ''}</div>
                                        <div className={'semi-bold'}>{value}</div>
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })}
                </Row>
            </Card>
        );
    }
}

export default CompanyContacts;
