import * as types from "../constants/orderReturnCheck";
import {IAction} from "../actions";

export interface deleteOrderReturnCheckState {
  loading: boolean;
  error: string;
}

const initialState: deleteOrderReturnCheckState = {
  loading: false,
  error: '',
}

export default function orderReturnCheckDelete(
  state = initialState,
  action: IAction<symbol, any>,
): deleteOrderReturnCheckState {
  switch (action.type) {
    case types.DELETE_ORDER_RETURN_CHECK_REQUEST:
      return {
        loading: true,
        error: '',
      }
    case types.DELETE_ORDER_RETURN_CHECK_SUCCESS:
      return {
        loading: false,
        error: '',
      }
    case types.DELETE_ORDER_RETURN_CHECK_ERROR:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state;
  }
}
