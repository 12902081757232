import {createSelector} from "reselect";
import queryString from 'query-string';
import _ from 'lodash';


const employeesUrlSelector = (props: any) => {
    return props.location.search
};

export const employeesSortCombiner = (queryParams: string) => {
  const getParams = queryString.parse(queryParams);
  const params = _.pick(getParams, ['orderBy']);
  
  let orderBy = {} as {[key:string]: string};
  if(params.orderBy &&  typeof params.orderBy === "string") {
    const orderByArray = params.orderBy.split('_');
    orderBy[orderByArray[0]] = orderByArray[1] + '_' + orderByArray[2];
  }
  
  const sort = _.pickBy(orderBy, function(value) {
    return !(value === undefined || value === null || value === '');
  });
  
  return !_.isEmpty(sort) ? {orderBy:sort} : undefined
}

export const employeesSortReSelector = createSelector(
    [employeesUrlSelector],
  employeesSortCombiner
);

/**
 * Получить объект фильтра сотрудников из строки query-параметров
 */
export const employeesFilterCombiner = (queryParams: string) => {
  const getParams = queryString.parse(queryParams);
  const params = _.pick(getParams, ['id', 'fio', 'phone', 'email', 'role', 'cities', 'status', 'createdAtStart', 'createdAtFinish']);
  
  const id = params.id ? +params.id : undefined;
  const fio = params.fio ? params.fio : undefined;
  const phone = params.phone ? params.phone : undefined;
  const email = params.email ? params.email : undefined;
  const role = params.role ? params.role : undefined;
  const cities = params.cities ? +params.cities : undefined;
  const status = params.status ? +params.status : undefined;
  const createdAtStart = params.createdAtStart ? params.createdAtStart : undefined;
  const createdAtFinish = params.createdAtFinish ? params.createdAtFinish : undefined;
  
  const filter = _.pickBy({id, fio, phone,email, role, status, cities, createdAtStart, createdAtFinish}, function(value) {
    return !(value === undefined || value === null || value === '');
  });
  
  return !_.isEmpty(filter) ? filter : undefined
}

export const employeesFilterReSelector = createSelector(
    [employeesUrlSelector],
    employeesFilterCombiner,
);

export const employeesPageReSelector = createSelector(
    [employeesUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        return getParams.page ? +getParams.page : 1
    }
);

