import {
    GET_REPORT_COURIERS_ERROR,
    GET_REPORT_COURIERS_REQUEST,
    GET_REPORT_COURIERS_SUCCESS
} from "../constants/reportCouriers";
import {IReportCourier} from "../../models/Report";
import { IActionReportCouriers } from "../actions/reportCouriers";


export interface IReportCouriersState {
    loading: boolean
    error: string
    data: IReportCourier[]
    total: number
    by: number
}

const initialState: IReportCouriersState = {
    data: [],
    loading: false,
    error: '',
    total: 0,
    by: 0
};

export default function reportCouriers(state = initialState, action: IActionReportCouriers): IReportCouriersState {
    switch (action.type) {
        case GET_REPORT_COURIERS_REQUEST:
            return {
                data: [],
                loading: true,
                error: '',
                total: 0,
                by: 0
            };
        case GET_REPORT_COURIERS_SUCCESS:
            const {data, total, by} = action.payload;
            return {
                data,
                loading: false,
                error: '',
                total,
                by
            };
        case GET_REPORT_COURIERS_ERROR:
            return {
                data: [],
                loading: false,
                error: action.payload,
                total: 0,
                by: 0
            };
        default:
            return state;
    }
}
