import {
    ADD_ROLE_FORM_REQUEST,
    ADD_ROLE_FORM_SUCCESS,
    ADD_ROLE_FORM_ERROR,
    EDIT_ROLE_FORM_REQUEST,
    EDIT_ROLE_FORM_SUCCESS,
    EDIT_ROLE_FORM_ERROR,
    GET_ROLE_FORM_REQUEST,
    GET_ROLE_FORM_SUCCESS,
    GET_ROLE_FORM_ERROR,
    DELETE_ROLE_FORM_ERROR,
    DELETE_ROLE_FORM_SUCCESS,
    DELETE_ROLE_FORM_REQUEST,
    OPEN_ROLE_FORM,
    CLOSE_ROLE_FORM
} from '../constants/roleForm'
import {IRole} from '../../models/Role';
import {IActionRoleForm} from '../actions/roleForm';


export interface IRoleFormState {
    getLoading: boolean
    getError: string
    data: IRole | null
    changeLoading: boolean
    changeError: string
    mode: 'add' | 'edit' | 'delete' | null
    modeId: string | null
}

const initialState: IRoleFormState = {
    data: null,
    getLoading: false,
    getError: '',
    changeLoading: false,
    changeError: '',
    mode: null,
    modeId: null
};

export default function cityForm(state = initialState, action: IActionRoleForm): IRoleFormState {
    switch (action.type) {
        case GET_ROLE_FORM_REQUEST:
            return {
                ...state,
                data: null,
                getLoading: true,
                getError: ''
            };
        case GET_ROLE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                getLoading: false,
                getError: ''
            };
        case GET_ROLE_FORM_ERROR:
            return {
                ...state,
                data: null,
                getLoading: false,
                getError: action.payload
            };
        case ADD_ROLE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case ADD_ROLE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case ADD_ROLE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case EDIT_ROLE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case EDIT_ROLE_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                changeLoading: false,
                changeError: ''
            };
        case EDIT_ROLE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case DELETE_ROLE_FORM_REQUEST:
            return {
                ...state,
                changeLoading: true,
                changeError: ''
            };
        case DELETE_ROLE_FORM_SUCCESS:
            return {
                ...state,
                changeLoading: false,
                changeError: ''
            };
        case DELETE_ROLE_FORM_ERROR:
            return {
                ...state,
                changeLoading: false,
                changeError: action.payload
            };
        case OPEN_ROLE_FORM:
            const {mode, modeId = null} = action.payload;
            return {
                ...initialState,
                mode,
                modeId
            };
        case CLOSE_ROLE_FORM:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
