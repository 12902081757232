import {GET_EMPLOYEES, GET_EMPLOYEES_REQUEST, GET_EMPLOYEES_SUCCESS, GET_EMPLOYEES_ERROR} from '../constants/employees'
import {IAction} from "./index";
import {IEmployee, IEmployeeRequest} from '../../models/Employee';


export interface IActionEmployees extends IAction<symbol, any> {

}

export const getEmployeesRequest = () => {
    return {
        type: GET_EMPLOYEES_REQUEST
    }
};

export const getEmployeesSuccess = (data: IEmployee[], total:number, by:number) => {
    return {
        type: GET_EMPLOYEES_SUCCESS,
        payload:{
            data,
            total,
            by
        }
    }
};

export const getEmployeesError = (payload: string) => {
    return {
        type: GET_EMPLOYEES_ERROR,
        payload
    }
};

export const getEmployees = (page?:number, form?:IEmployeeRequest) => {
    return {
        type: GET_EMPLOYEES,
        payload:{
            page,
            form
        }
    };
};
